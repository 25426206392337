import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';

// Utils
import { sendUserInteraction } from 'utils/tagManager.utils';

// Components
import ContactCard from '../ContactCard/ContactCard';
import { Icon } from 'components/common/Icon/Icon';
import { Flag } from 'components/common/Flag/Flag';

/**
 * Renders a list of contact cards
 */
const Contacts = () => {
  // Hooks
  const { t } = useTranslation();

  const contacts = [
    {
      icon: <Flag className="help-center-flag" lang={{ code: 'en-gb' }} />,
      title: t('common:support.ukNumber'),
      onClick: () => {
        sendUserInteraction('UK phone contact click');
      },
      href: 'tel:+442039661940',
      linkText: '+44-20-3966-1940',
    },
    {
      icon: <Flag className="help-center-flag" lang={{ code: 'lang-eu' }} />,
      title: t('common:support.euNumber'),
      onClick: () => {
        sendUserInteraction('EU phone contact click');
      },
      href: 'tel:+38670795790',
      linkText: '+386-70-795-790',
    },
    {
      icon: <Icon name="envelope" size="large" />,
      title: t('common:support.email'),
      onClick: () => {
        sendUserInteraction('email contact click');
      },
      href: 'mailto:support@taia.io',
      linkText: 'support@taia.io',
    },
  ];

  return (
    <ul>
      {contacts.map((contact) => (
        <ContactCard key={`${contact.title}`} {...contact} />
      ))}
    </ul>
  );
};

export default Contacts;
