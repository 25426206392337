import Table from '../../common/Table/Table';
import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const LoadCell = styled(Table.Cell)`
  color: #3f7bb8;
  cursor: pointer;
  &:hover {
    color: #4d9ef0 !important;
  }
`;
