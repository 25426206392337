import PropTypes from 'prop-types';
import React from 'react';

import './style.scss';

import { COLORS } from 'constants/colors';

// Primary call to action button
const CallToActionButton = ({
  color = 'blue',
  href = null,
  onClick = () => {},
  target = null,
  text = '',
  children,
  ...rest
}) => {
  return (
    <a
      className={`call-to-action ${color}`}
      href={href}
      target={target}
      onClick={onClick}
      rel="noopener noreferrer"
      {...rest}
    >
      {children || text}
    </a>
  );
};

CallToActionButton.propTypes = {
  color: PropTypes.oneOf(COLORS),
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  text: PropTypes.string,
};

export default CallToActionButton;
