import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Dropdown } from 'semantic-ui-react';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import Table from '../../../common/Table/Table';
import { Icon } from '../../../common/Icon/Icon';
import { postMemberUpdate } from '../../../../services/teams';
import { TEAM_TRANSLATIONS } from '../../../../constants/roles';
import { checkRoles } from '../../../../utils/user.utils';
import Button from '../../../common/Button/Button';
import { Popup } from '../../../common/Popup/Popup';
import RemoveMemberModal from './RemoveMemberModal';
import { StyledLabel } from '../../Team.style';
import { ButtonsWrapper } from '../TeamMembers.style';
import UserCell from '../UserCell';

import { formatAPIDateString } from 'utils/date.utils';
import { TEAM_ROLES } from '../../../../constants/roles';

const TeamMemberRow = ({ t, member, onSubmit, user }) => {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [memberEditing, setMemberEditing] = useState({ ...member });
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const roleOptions = TEAM_ROLES.map((role) => {
    return {
      key: role.value,
      text: t(role.translation),
      value: role.value,
    };
  });

  const handleRoleChange = (member) => {
    return (e, { value }) => {
      const newMember = {
        ...memberEditing,
        pivot: {
          ...member.pivot,
          role_id: value,
        },
      };

      setMemberEditing(newMember);
    };
  };

  const handleContactChange = (e, { checked }) => {
    const newMember = {
      ...memberEditing,
      pivot: {
        ...memberEditing.pivot,
        is_contact: checked ? 1 : 0,
      },
    };

    setMemberEditing(newMember);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await postMemberUpdate({
        team_id: id,
        user_id: memberEditing.id,
        is_contact: memberEditing.pivot.is_contact,
        role_id: memberEditing.pivot.role_id,
      });
      toast.success(t('common:toasts.teamMemberUpdated.success'));
    } catch (e) {
      toast.error(t('common:toasts.teamMemberUpdated.error'));
      throw e;
    } finally {
      setIsSubmitting(false);
      setEditing(false);
    }
  };

  const handleCancel = () => {
    setEditing(false);
    setMemberEditing(member);
  };

  return (
    <Table.Row>
      <Table.Cell>
        <UserCell user={memberEditing} />
      </Table.Cell>
      <Table.Cell>
        {editing ? (
          <Dropdown
            selection
            options={roleOptions}
            value={memberEditing.pivot.role_id}
            onChange={handleRoleChange(member)}
          />
        ) : (
          <StyledLabel>{t(TEAM_TRANSLATIONS[memberEditing.pivot.role_id])}</StyledLabel>
        )}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {editing ? (
          <Checkbox checked={memberEditing.pivot.is_contact === 1} onChange={handleContactChange} />
        ) : memberEditing.pivot.is_contact ? (
          <Icon name="check" color="#1caac5" size={40} />
        ) : (
          <Icon name="times" color="#999999" size={34} />
        )}
      </Table.Cell>
      <Table.Cell>
        {formatAPIDateString({ dateString: memberEditing.pivot.created_at, showTime: false })}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <ButtonsWrapper>
          {editing ? (
            <>
              <Button icon="times" onClick={handleCancel} disabled={isSubmitting} />
              <Button icon="check" className="check" onClick={handleSubmit} loading={isSubmitting} />
            </>
          ) : (
            checkRoles({
              user: user,
              teamId: id,
              allowedUserRoles: [29, 40],
              allowedTeamRoles: [1],
            }) && (
              <>
                <Popup trigger={<Button icon="edit-alt" onClick={() => setEditing(true)} />} dark>
                  {t('common:team.members.editUser')}
                </Popup>
                <Button icon="trash-alt" onClick={() => setShowRemoveModal(true)} />
              </>
            )
          )}
        </ButtonsWrapper>
      </Table.Cell>
      {showRemoveModal && (
        <RemoveMemberModal
          onCancel={() => setShowRemoveModal(false)}
          onSubmit={onSubmit}
          team_id={id}
          user={member}
        />
      )}
    </Table.Row>
  );
};

const mapStateToProps = (state) => ({
  user: state.userStore.user,
});

export default connect(mapStateToProps)(withTranslation('common')(TeamMemberRow));
