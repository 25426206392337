import React from 'react';

import { InputLabel, InputLabelAsterix } from './Inputs.styles';

const InputLabelComponent = ({ isRequired, text }) => (
  <InputLabel>
    {`${text}:`}
    {isRequired && <InputLabelAsterix>*</InputLabelAsterix>}
  </InputLabel>
);

export default InputLabelComponent;
