import styled from 'styled-components';

export const CreditCardContainer = styled.div`
  width: 375px;
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  &:focus-within {
    border: 1px solid #85b7d9;
  }
`;
