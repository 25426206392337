import React from 'react';
import { classnames } from 'utils/base.utils';

// Styles
import './style.scss';

const ProgressBar = ({ progress = 0, width = '100px', color }) => {
  const fixedProgress = progress.toFixed(0);
  const className = classnames('progress-bar', color);

  return (
    <div className={className} style={{ '--progress-wrapper-width': width }}>
      <div style={{ '--progress-width': `${fixedProgress}%` }}></div>
      <span>{`${fixedProgress} %`}</span>
    </div>
  );
};

export default ProgressBar;
