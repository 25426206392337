import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalPopup from '../../../common/ModalPopup/ModalPopup';
import { Header, List } from 'semantic-ui-react';
import { HelpText, HelpPopupContainer, StyledIcon } from './HelpPopup.styles';

// team roles translations
import { TEAM_TRANSLATIONS } from '../../../../constants/roles';
// team roles description translations
const roleDescriptions = {
  0: 'common:admin.companies.invite.managerDescription',
  1: 'common:admin.companies.invite.teamManagerDescription',
  2: 'common:admin.companies.invite.fullMemberDescription',
  3: 'common:admin.companies.invite.limitedMemberDescription',
  4: 'common:admin.companies.invite.billingManagerDescription',
};

const HelpList = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header>{`${t('common:admin.companies.invite.userRoles')}:`}</Header>
      <List>
        {[0, 1, 2, 3, 4].map((role) => (
          <List.Item key={role}>
            <List.Header>{t(TEAM_TRANSLATIONS[role])}</List.Header>
            {t(roleDescriptions[role])}
          </List.Item>
        ))}
      </List>
    </>
  );
};

const HelpPopup = () => {
  const { t } = useTranslation();

  return (
    <ModalPopup
      content={<HelpList />}
      flowing
      trigger={
        <HelpPopupContainer>
          <HelpText>{t('common:admin.companies.invite.roleInfo')}</HelpText>
          <StyledIcon name="question-circle" />
        </HelpPopupContainer>
      }
    />
  );
};

export default HelpPopup;
