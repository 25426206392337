import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectDropdown } from '../../common/Dropdown/Dropdown';
import Button from '../../common/Button/Button';

import { PROJECT_SORT_PARAMS } from './ProjectFilter.constants';
import { SortContainer, StyledHeader } from './ProjectsFilter.styles';

const SortInput = ({ sortParam, sortOrder, updateSearchParams }) => {
  const { t } = useTranslation();

  const dropdownOptions = PROJECT_SORT_PARAMS.map((param) => {
    const { value, translationKey } = param;
    return {
      key: value,
      value,
      text: t(translationKey),
    };
  });

  const handleDropdownChange = (e, { value }) => {
    const sort_by = value;
    updateSearchParams({ sort_by });
  };

  const handleDirectionChange = (newDirection) => {
    if (newDirection === sortOrder) {
      return;
    }
    updateSearchParams({ sort_order: newDirection });
  };

  return (
    <>
      <StyledHeader>{`${t('common:projects.filters.sortBy')}:`}</StyledHeader>
      <SortContainer>
        <SelectDropdown
          onChange={handleDropdownChange}
          options={dropdownOptions}
          search
          style={{
            marginRight: '.7rem',
          }}
          value={sortParam}
          size="small"
          className="select-dropdown"
        />
        <div className="desc-asc-wrapper">
          <Button
            circular
            color={sortOrder === 'DESC' ? 'blue' : 'grey'}
            icon="angle-down"
            onClick={() => handleDirectionChange('DESC')}
            link
            inverted
          />
          <Button
            circular
            color={sortOrder === 'ASC' ? 'blue' : 'grey'}
            inverted
            icon="angle-up"
            onClick={() => handleDirectionChange('ASC')}
            link
          />
        </div>
      </SortContainer>
    </>
  );
};

export default SortInput;
