import styled from 'styled-components';

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ui.icon.button {
    margin: 0 !important;
    margin-left: 0.5em !important;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
`;

export const Name = styled.span`
  padding: 0 0;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  line-height: 1.28571429em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 2.14285714em;
  white-space: nowrap;
  margin-right: 0.5em;
  height: 45px;
`;

export const StyledLabel = styled.label`
  opacity: 0.6;
  white-space: nowrap;
  margin-bottom: 0.2em;
  font-size: 0.9em;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const RowContent = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'DM Sans', sans-serif;
`;

export const ButtonsContainer = styled.div`
  width: 110px;
  display: flex;
  margin-left: 5px;
`;

export const NameInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NormalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  h2 {
    font-family: 'DM Sans', sans-serif;
    font-size: 30px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 4px;
  }
  h3 {
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 16px;
    color: #999;
  }
  .button {
    max-height: 52px;
  }
`;
