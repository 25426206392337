import React, { createContext, useContext } from 'react';
import { classnames } from 'utils/base.utils';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import { Icon } from '../Icon/Icon';
import RequiredSign from '../RequiredSign/RequiredSign';
import { StyledContainer } from './SelectDropdown.styles';
import { useDropdown } from './useDropdown';
import { getOptionFromValue, includes } from './utils';

const dropdownContext = createContext(null);
const useDropdownContext = () => useContext(dropdownContext);

export const DropdownItem = ({ children, item, index, ...rest }) => {
  const { getItemProps, highlightedIndex, selectedItem } = useDropdownContext();

  return (
    <div
      className={`dropdown-item ${index === highlightedIndex ? 'highlighted' : ''}`}
      {...getItemProps({ ...rest, item, index })}
      data-value={item?.value}
      data-text={item?.text}
      data-selected={item?.value === selectedItem?.value}
      data-cy="dropdown-item"
    >
      {item.icon && item.icon}
      {children}
    </div>
  );
};

export const DropdownMenu = ({ children, ...rest }) => {
  const { getMenuProps } = useDropdownContext();
  return (
    <div className="dropdown-menu" {...getMenuProps(rest)}>
      {children}
    </div>
  );
};

export const SelectDropdown = React.forwardRef(
  (
    {
      wholeObj = false,
      options,
      placeholder = '',
      fluid,
      search,
      label,
      required,
      disabled,
      value,
      onChange = () => {},
      className,
      emptyState = 'No results found',
      children,
      size,
      error,
      selectedShowIcon,
      name,
      canReset = true,
      ...rest
    },
    ref,
  ) => {
    const state = useDropdown({
      items: options,
      initialState: { selectedItem: null },
      selectedItem: getOptionFromValue(value, options),
      onSelectedItemChange: wholeObj ? handleSelectedItemChangeObject : handleSelectedItemChange,
      filterFunction: includes,
    });
    const { isOpen, selectedItem, getToggleProps, getInputProps, items, inputValue, expandUpward } = state;

    function handleSelectedItemChange(newState) {
      onChange(null, { value: newState.selectedItem.value, name });
    }

    function handleSelectedItemChangeObject(newState) {
      // handleSelectedItemChange returns only value, use this, if you need whole object
      onChange(null, { value: newState.selectedItem, name });
    }

    const containerClassnames = classnames(className, 'select-dropdown', {
      open: isOpen,
      upward: expandUpward,
      'selected-icon-show': selectedShowIcon,
      empty: !selectedItem?.text,
      fluid,
      label,
      disabled,
      size,
      error,
    });

    return (
      <dropdownContext.Provider value={state}>
        <StyledContainer className={containerClassnames} {...rest}>
          <div
            className="dropdown-header"
            data-selected={selectedItem?.value}
            data-selected-text={selectedItem?.text}
            data-cy="dropdown-header"
            ref={ref}
            {...getToggleProps({ disabled })}
          >
            {search && (
              <>
                {selectedShowIcon && selectedItem?.icon && (
                  <div className="selected-item-icon">{selectedItem.icon}</div>
                )}
                <input
                  disabled={disabled}
                  className={`search-input ${selectedItem && !inputValue ? 'selected' : ''}`}
                  placeholder={selectedItem?.text || placeholder}
                  data-value={selectedItem?.value}
                  {...getInputProps()}
                />
              </>
            )}
            {!search && <span data-cy="dropdown-selected-text">{selectedItem?.text || placeholder}</span>}
            {value && canReset ? (
              <Icon
                className="reset"
                name="times"
                onClick={
                  () =>
                    wholeObj ?
                      handleSelectedItemChangeObject({ selectedItem: { value: null } }) :
                      handleSelectedItemChange({ selectedItem: { value: null } })
                }
              />
            ) : (
              <Icon className="arrow" name="angle-down" />
            )}
            {label && (
              <label>
                {label}
                {required ? <RequiredSign /> : null}
              </label>
            )}
          </div>

          <DropdownMenu data-cy="dropdown-menu">
            {children
              ? children
              : items.length > 0
              ? items.map((item, index) => (
                  <DropdownItem key={`${item.value}${index}`} item={item} index={index}>
                    <span data-cy="dropdown-item-text">{item.text}</span>
                  </DropdownItem>
                ))
              : search && (
                  <DropdownItem index={0} item={{}}>
                    {emptyState}
                  </DropdownItem>
                )}
          </DropdownMenu>
        </StyledContainer>
        <FullscreenOverlay className="hide-on-desktop" open={isOpen} />
      </dropdownContext.Provider>
    );
  },
);
