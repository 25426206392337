import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Components
import OriginalFilesDownload from 'components/common/OriginalFilesDownload/OriginalFilesDownload';
import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import DeleteProjectModal from 'components/common/DeleteProjectModal/DeleteProjectModal';

// Assets
import img from 'assets/taia-graphic-list-checkmark.png';

// Styles
import './style.scss';

const ProjectFailed = ({ project }) => {
  const { t } = useTranslation();
  const history = useHistory();

  // Functions
  const handleModalLoading = (loading) => {
    if (!loading) history.push('/projects');
  };

  // Texts
  const title = t('common:projects.failed.title');
  const description = t('common:projects.failed.description');

  const possibleReasons = ['includesImages', 'trackedChanges', 'unreadablePDF', 'password', 'zip'];

  return (
    <section className="project-failed-screen">
      <img src={img} alt="" />
      <h1>{title}</h1>
      <p>{description}</p>
      <ul>
        {possibleReasons.map((reason, idx) => (
          <li key={`reason-${idx}`}>
            <strong>{t(`common:projects.failed.possibleReasons.${reason}.title`)}</strong>
            <p>{t(`common:projects.failed.possibleReasons.${reason}.description`)}</p>
          </li>
        ))}
      </ul>
      <footer>
        <OriginalFilesDownload projectId={project?.id} />
        <DeleteProjectModal
          trigger={
            <Button actiontype="delete-confirm" labelPosition="right">
              Delete project
              <Icon name="trash-alt" />
            </Button>
          }
          projectId={project?.id}
          setLoading={handleModalLoading}
        />
      </footer>
    </section>
  );
};

export default ProjectFailed;
