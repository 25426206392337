import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../Button/Button';
import { ControlsContainer } from './Inputs.styles';

const EditFormControls = ({ canSubmit = false, isSubmitting, onCancel, onConfirm, showCancel = true }) => {
  const { t } = useTranslation();

  return (
    <ControlsContainer>
      {showCancel && (
        <Button disabled={isSubmitting} actiontype="cancel" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
      )}
      <Button
        fluid={!showCancel}
        disabled={!canSubmit}
        loading={isSubmitting}
        actiontype="primary"
        onClick={onConfirm}
      >
        {t('common:confirm')}
      </Button>
    </ControlsContainer>
  );
};

export default EditFormControls;
