import React, { useState } from 'react';

import { ContentContainer, FullscreenContainer } from './PrivateWrapper.styles';

import Sidebar from './Sidebar/Sidebar';

import logo from '../PublicWrapper/Navbar/assets/logo.png';
import { Link } from 'react-router-dom';
import EmailBanner from 'components/EmailBanner/EmailBanner';

import { sendUserInteraction } from 'utils/tagManager.utils';
import { classnames } from 'utils/base.utils';

const getCollapsed = () => {
  const collapsed = localStorage.getItem('sidebar-collapsed');
  if (collapsed) {
    return collapsed === 'true';
  } else {
    localStorage.setItem('sidebar-collapsed', false);
    return false;
  }
};

export default ({ children, isFullscreen = false, pageTitle, noWrapper = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(getCollapsed());

  const setCollapsed = (collapsed) => {
    sendUserInteraction('collapse or expand sidebar');
    localStorage.setItem('sidebar-collapsed', collapsed);
    setIsCollapsed(collapsed);
  };

  const pageTitleClean = pageTitle.toLowerCase().split(' ').join('-');

  const contentContainerClassnames = classnames('main-content-container', {
    ['main-content-container--' + pageTitleClean]: pageTitleClean,
  });

  const fullscreenContainerClassnames = classnames('fullscreen-container', {
    ['fullscreen-container--' + pageTitleClean]: pageTitleClean,
  });

  // Nothing wrapping the content
  if (noWrapper) {
    return children;
  }

  if (isFullscreen) {
    return (
      <FullscreenContainer>
        <div className={fullscreenContainerClassnames}>
          <div className="navigation">
            <Link
              data-cy="home-link"
              onClick={() => sendUserInteraction('fullscreen private route taia logo click')}
              to="/"
            >
              <img className="navbar-img" alt="" src={logo} />
            </Link>
          </div>
          <div className="content">{children}</div>
        </div>
      </FullscreenContainer>
    );
  }

  return (
    <>
      <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setCollapsed} pageTitle={pageTitle} />
      <ContentContainer className={contentContainerClassnames}>
        {children}
        <EmailBanner isCollapsed={isCollapsed} />
      </ContentContainer>
    </>
  );
};
