import React, { createContext } from 'react';

// PropTypes
import PropTypes from 'prop-types';
import { nullable } from 'utils/propTypes.utils';

// Components
import SelectGroupOption from './SelectGroupOption';

// Styles
import './style.scss';

// Context
export const SelectGroupContext = createContext(null);

/**
 * Render main select group element
 */
export const SelectGroup = ({
  selected,
  onChange,
  fluid,
  size = 'normal',
  children,
  className,
  style,
  id,
  border,
  flat,
}) => {
  // Ref
  const getClasses = () => {
    const classes = ['select-group', className];

    if (fluid) classes.push('fluid');
    if (border) classes.push('border');
    if (flat) classes.push('flat');

    return classes.join(' ');
  };

  // Functions
  const handleClick = (value) => {
    if (value !== selected) {
      onChange(value);
    }
  };

  return (
    <SelectGroupContext.Provider value={{ handleClick, selected, fluid, size }}>
      <div id={id} className={getClasses()} style={style}>
        {children}
      </div>
    </SelectGroupContext.Provider>
  );
};

SelectGroup.Option = SelectGroupOption;

// Prop types for select group component
SelectGroup.propTypes = {
  // Required
  selected: nullable(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])).isRequired,
  onChange: PropTypes.func.isRequired,

  // Optional
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,

  // Style modifiers
  border: PropTypes.bool,
  flat: PropTypes.bool,
  fluid: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small']),
};
