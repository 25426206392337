import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'semantic-ui-react';
import Button from '../../common/Button/Button';

export const UploadConfirmation = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal closeOnDimmerClick={false} closeOnDocumentClick={false} open size="small">
      <Modal.Header>{t('common:admin.project.uploadTranslated')}</Modal.Header>
      <Modal.Content>{t('common:admin.project.uploadWillOverWrite')}</Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="submit" onClick={onConfirm}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
