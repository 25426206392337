import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import lottie from 'lottie-web';

import { LoaderContainer, Container } from './Loader.styles';

import animationData from './taia_loader.json';

const Loader = ({ children = null, ...rest }) => {
  const container = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {},
    });
  }, []);
  // if you don't set them to null they will duplicate on LoaderContainer

  return (
    <Container {...rest}>
      <LoaderContainer ref={container} {...rest} />
      {!!children ? children : t('common:loading')}
    </Container>
  );
};

Loader.defaultProps = {
  active: true,
  inline: false,
};

export default Loader;
