// order of elements should be preserved since it determines the order of the languages flags
export const LANGUAGES = ['hr', 'de', 'it', 'sl', 'sr', 'en'];

export const LANGUAGE_CODES = {
  de: 'de-DE',
  en: 'en-GB',
  hr: 'hr-HR',
  it: 'it-iT',
  sl: 'sl-SI',
  sr: 'sr-BA',
};

export const LANGUAGES_ENUM = {
  en: { id: 1, flag: 'uk' },
  de: { id: 2, flag: 'de' },
  sl: { id: 3, flag: 'si' },
  it: { id: 4, flag: 'it' },
  hr: { id: 6, flag: 'hr' },
  sr: { id: 7, flag: 'rs' },
};
