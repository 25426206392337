import styled from 'styled-components';

export const TeamBillingWrapper = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 2em;
  .select-billing {
    display: grid;
    justify-content: center;
    padding: 30px;
    background: #ffffff;
    max-width: 400px;

    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);

    .select-header {
      margin-bottom: 10px;
    }

    .select-description {
      text-align: justify;
      margin-bottom: 20px;
    }

    .select-dropdown {
      margin-bottom: 10px;
    }

    .select-loader {
      display: grid;
      place-content: center;
      height: 300px;
      width: 100%;
    }

    .select-actions {
      margin-top: 20px;
      display: flex;
      justify-content: stretch;
    }
  }
`;
