import React from 'react';
import { Loader } from 'semantic-ui-react';

// Styles
import './style.scss';
import { taiaColors } from '../../../style/theme';
import { classnames } from 'utils/base.utils';

const Label = ({
  color = '#ffffff',
  backgroundColor = taiaColors.greyDark,
  text,
  children,
  loading,
  style = {},
  className,
  ...rest
}) => {
  const props = {
    className: classnames('taia-label', className, {
      loading,
    }),
    style: {
      ...style,
      '--bg-color': backgroundColor,
      '--text-color': color,
    },
    ...rest,
  };
  return (
    <div {...props}>
      {text || children}
      <div className="overlay">
        <Loader active size="tiny" />
      </div>
    </div>
  );
};

export default Label;
