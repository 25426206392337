import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '../common/Icon/Icon';
import Table from '../common/Table/Table';
import { IconContainer, StyledLabel } from './AdminSubscriptionPackages.style';
import { SUBSCRIPTION_INTERVALS } from '../../constants/subscriptions';
import { sendUserInteraction } from 'utils/tagManager.utils';

const PackageRow = ({ pack }) => {
  const { t } = useTranslation();

  const currencies = useSelector((state) => state.classifiersStore.currencies);
  return (
    <Table.Row>
      <Table.Cell>
        <Link
          onClick={() => {
            sendUserInteraction('admin click package id');
          }}
          to={`/admin/packages/${pack.id}`}
        >
          {pack.id}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <Link
          onClick={() => {
            sendUserInteraction('admin click package name');
          }}
          to={`/admin/packages/${pack.id}`}
        >
          {pack.name}
        </Link>
      </Table.Cell>
      <Table.Cell>{pack.word_limit}</Table.Cell>
      <Table.Cell>
        <StyledLabel active={pack.active}>
          {pack.active ? t('common:admin.package.active') : t('common:admin.package.inactive')}
        </StyledLabel>
      </Table.Cell>
      <Table.Cell>
        {pack.private ? (
          <IconContainer>
            <Icon color="black" name="eye-slash" /> {t('common:admin.packages.privatePlan')}{' '}
          </IconContainer>
        ) : (
          <IconContainer>
            <Icon color="#1CAAC5" name="eye" /> {t('common:admin.packages.publicPlan')}{' '}
          </IconContainer>
        )}
      </Table.Cell>
      <Table.Cell>
        {pack.plans.length === currencies.length * SUBSCRIPTION_INTERVALS.length ? (
          <IconContainer>
            <Icon color="#32bf73" name="check-circle" /> {t('common:admin.packages.pricingSet')}
          </IconContainer>
        ) : (
          <IconContainer>
            <Icon color="#ffb102" name="exclamation-triangle" />
            {t('common:admin.packages.missingPricing')}
          </IconContainer>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const PackagesTable = ({ packages }) => {
  const { t } = useTranslation();

  const packageRows = packages.map((pack) => <PackageRow key={pack.id} pack={pack} />);

  return (
    <Table width="100%">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('common:admin.packages.headers.id')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.packages.headers.name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.packages.headers.wordLimit')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.packages.headers.active')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.packages.headers.private')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.packages.headers.pricing')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{packageRows}</Table.Body>
    </Table>
  );
};

export default PackagesTable;
