import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Observable } from 'rxjs';
import { toast } from 'react-toastify';
import { useDebounce } from 'hoc/debouncer';
import { ukTimezone } from 'utils/date.utils';

import { Accordion } from './Accordion/Accordion';
import AdminSidebar from 'components/common/AdminSidebar/OrderProject';
import CatapultToggle from '../OrderProject/CatapultToggle/CatapultToggle';
import DeliveryDate from './DeliveryDate';
import GraphicalEditing from './GraphicalEditing';
import { Icon } from 'components/common/Icon/Icon';
import OrderProjectHeader from './OrderProjectHeader';
import OrderSummary from './OrderSummary';
import { Popup } from 'components/common/Popup/Popup';
import TranslationType from './TranslationType';

import {
  Container,
  MainContentContainer,
  GridContainer,
  ReturnLink,
  StyledHeader,
  OrderSummaryColumn,
} from './OrderProject.styles';

import { updateProject, updateStepsLength } from '../../../store/projectsSlice';

import { getBillingDetails } from 'services/billing';
import {
  getDeliveryDates,
  getProjectPrices,
  postUpdateDelivery,
  postUpdateProjectById,
  postProjectPrices,
} from 'services/project';

import { canToggleCatapult } from 'utils/catapult.utils';
import { generateProformaPdf } from '../OrderProject/ProjectDetails/Proforma';
import { hasAdminPermissions } from 'utils/user.utils';
import { hasDTP } from 'utils/jobs.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { SidebarWrapper } from 'components/common/AdminSidebar/AdminSidebar.styles';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { theme } from 'style/theme';
import GenerateQuoteButton from './GenerateQuoteButton/GenerateQuoteButton';
import { checkRoles } from 'utils/user.utils';
import MobileStepsControls from 'components/common/Steps/MobileStepsControls';

const OrderProject = ({ billingDetails, pricesData, project, setPricesData, refreshProject }) => {
  const [step, setStep] = useState(1);
  const [generatingQuote, setGeneratingQuote] = useState(false);
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const [couponAccepted, setCuponAccepted] = useState(false);
  const [deliveryId, setDeliveryId] = useState(null);
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [isLoadingDelivery, setIsLoadingDelivery] = useState(true);
  const [priceSubscrber, setPriceSubscriber] = useState(null);
  const dispatch = useDispatch();

  const { jobs } = project;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { debounce } = useDebounce();
  const user = useSelector((state) => state.userStore.user);
  const countries = useSelector((state) => state.classifiersStore.countries);
  const subscriptions = useSelector((state) => state.subscriptionsStore.subscriptions);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  const stickyElement = useRef(null);
  const stickyRoot = useRef(null);
  const stickyIntersectionObserver = useRef(null);

  const isLimitedMember = checkRoles({
    user,
    teamId: project.team_id,
    allowedTeamRoles: [3],
  });

  // Run only on mount/unmount
  useEffect(() => {
    return () => {
      dispatch(updateStepsLength({ length: null }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!stickyRoot.current && !stickyElement.current && !stickyIntersectionObserver.current) return;

    let stickyElementRef = null;

    stickyIntersectionObserver.current = new IntersectionObserver(
      ([e]) => {
        // parent element is the order summary column, initially the sticky element is
        // intersecting so toggle not stuck attribute, when sticky element gets sticky
        // it is no longer intersecting with the parent thus removing the not stuck attr
        e.target.toggleAttribute('not-stuck', e.intersectionRatio < 1);
      },
      {
        root: stickyRoot.current.parentNode,
        rootMargin: '-21px 0px 0px 0px',
        threshold: [1],
      },
    );

    stickyIntersectionObserver.current.observe(stickyElement.current);
    stickyElementRef = stickyElement.current;

    return () => {
      if (stickyIntersectionObserver.current) stickyIntersectionObserver.current.unobserve(stickyElementRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyRoot.current, stickyElement.current, stickyIntersectionObserver.current]);

  // On first call only get prices
  const onMount = useRef(true);

  useEffect(() => {
    const priceFetchObservable = new Observable(async (subscriber) => {
      const fetchPrices = async () => {
        try {
          setIsLoadingPrices(true);
          let pricesData = null;
          if (onMount.current) {
            pricesData = await getProjectPrices({
              ...project,
            });
            onMount.current = false;
          } else {
            pricesData = await postProjectPrices({
              ...project,
            });
          }

          return pricesData;
        } catch (e) {
          if (e?.response.status === 422) {
            const deliveryDates = e?.response.data?.data;
            if (deliveryDates) {
              const day = deliveryDates.find((date) => date.selected === 1);

              toast.error(t('common:projects.deliveryDatesExpired'));

              subscriber.next({ delivery: deliveryDates, deliveryId: day ? day.id : null });
              subscriber.complete();
            }
          } else {
            subscriber.error(e);
          }
        }
      };

      const fetchDeliveryDates = async () => {
        try {
          const delivery = await getDeliveryDates(project);
          return delivery;
        } catch (e) {
          subscriber.error(e);
        }
      };

      debounce(
        'fetchProjectPrices',
        async () => {
          setIsLoadingDelivery(true);
          setIsLoadingPrices(true);

          const prices = await fetchPrices();
          const delivery = await fetchDeliveryDates();

          subscriber.next({ prices, delivery });
          subscriber.complete();
        },
        500,
      );
    });

    if (priceSubscrber) {
      priceSubscrber.unsubscribe();
    }

    const sub = priceFetchObservable.subscribe({
      next: async ({ prices, delivery, deliveryId }) => {
        if (prices) {
          setPricesData(prices);
        }
        if (delivery) {
          setDeliveryDates(delivery);
        }
        if (deliveryId) {
          setDeliveryId(deliveryId);
        }
      },
      error: async (e) => {
        console.error("Couldn't load project prices", e);
      },
      complete: () => {
        setIsLoadingDelivery(false);
        setIsLoadingPrices(false);
      },
    });

    setPriceSubscriber(sub);

    return () => {
      sub.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.jobs, project.is_delivery, project.id, couponAccepted]);

  const skipMount = useRef(true);
  //After changing delivery ID change store data and retrieve new prices
  useEffect(() => {
    const fetchPricesAfterDelivery = async () => {
      try {
        setIsLoadingPrices(true);
        const pricesData = await postUpdateDelivery(project.id, deliveryId);
        setPricesData(pricesData);
      } catch (e) {
        if (e?.response.status === 422) {
          const deliveryDates = e?.response.data?.data;
          if (deliveryDates) {
            const day = deliveryDates.find((date) => date.selected === 1);

            if (day) {
              setDeliveryId(day.id);
            }
          }

          setDeliveryDates(deliveryDates);
          toast.error(t('common:projects.deliveryDatesExpired'));
        } else {
          console.error("Couldn't retrieve project prices", e);
        }
      } finally {
        setIsLoadingPrices(false);
      }
    };

    // Skip call of prices on mount
    if (skipMount.current) {
      skipMount.current = false;
    } else {
      debounce(
        'updatingDeliveryDate',
        () => {
          fetchPricesAfterDelivery();
        },
        500,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryId]);

  const steps = useMemo(() => {
    const updateJob = (job) => {
      const newJobs = jobs.map((oldJob) => {
        if (oldJob.id === job.id) {
          return job;
        }
        return oldJob;
      });

      const newProject = { ...project, is_delivery: project.is_delivery, jobs: [...newJobs] };

      dispatch(
        updateProject({
          project: newProject,
        }),
      );
    };

    const updateJobs = (newJobs) => {
      const newProject = { ...project, jobs: [...newJobs] };

      dispatch(
        updateProject({
          project: newProject,
        }),
      );
    };

    const stps = [
      {
        title: <span>1. {t('common:projects.orderSteps.step1')} </span>,
        content: <TranslationType project={project} setStep={setStep} jobs={jobs} updateJob={updateJob} />,
        id: 'order-project-accordion-item-translation-type',
      },
    ];

    let currentStep = 1;

    if (hasDTP(jobs)) {
      currentStep++;
      stps.push({
        title: (
          <span>
            {currentStep}. {t('common:projects.orderSteps.step2')}{' '}
          </span>
        ),
        content: <GraphicalEditing project={project} setStep={setStep} jobs={jobs} updateJobs={updateJobs} />,
        id: 'order-project-accordion-item-graphical-editing',
      });
    }

    const handleDateChange = (dateId) => {
      setDeliveryId(dateId);
    };

    currentStep++;
    stps.push({
      title: (
        <span>
          {currentStep}. {t('common:projects.orderSteps.step3')}{' '}
          <Popup
            trigger={<Icon link inline name="info-circle"></Icon>}
            content={
              <div>{`${t('common:projects.orderSteps.step3Description1')} ${ukTimezone()} ${t(
                'common:projects.orderSteps.step3Description2',
              )}`}</div>
            }
          />
        </span>
      ),
      content: (
        <DeliveryDate
          deliveryDates={deliveryDates}
          loading={isLoadingDelivery}
          project={project}
          onChange={handleDateChange}
        />
      ),
      id: 'order-project-accordion-item-delivery-date',
    });

    return stps;
  }, [deliveryDates, dispatch, isLoadingDelivery, jobs, project, t]);

  /**
   * On steps change dispatch redux steps length update
   * on mobile +1 because order summary is an extra step
   */
  useEffect(() => {
    if (isMobile !== null)
      dispatch(updateStepsLength({ length: isMobile ? steps.length + 1 : steps.length }));
  }, [steps, dispatch, isMobile]);

  const handleDownloadQuote = async (e) => {
    sendUserInteraction('orderProject: quote download click');
    e.preventDefault();
    setGeneratingQuote(true);
    try {
      // ghetto fix for missing billing details
      const billing_details = await getBillingDetails(project.billing_detail_id);

      // TODO move this to BE, please ???
      const { is_delivery, id, name } = project;
      const {
        coupon_discount_value,
        delivery_price,
        disc_coupon,
        delivery_time,
        gross_price,
        jobs,
        net_price,
        reg_disc_ratio,
        reg_discount_value,
        spec_disc_ratio,
        special_discount_value,
        vat_rate,
        vat_total,
      } = pricesData;

      generateProformaPdf({
        billingDetails: billing_details,
        countries,
        currencyData: project.currency,
        deliveryOption: is_delivery || 50,
        deliveryPrice: delivery_price,
        deliveryDate: delivery_time,
        discountCouponPercentage: disc_coupon,
        discountCouponTotal: coupon_discount_value,
        grossPrice: gross_price,
        i18n,
        jobs,
        netPrice: net_price,
        projectId: id,
        projectName: name,
        regionalDiscountRatio: reg_disc_ratio,
        regionalDiscountTotal: reg_discount_value,
        specialDiscountRatio: spec_disc_ratio,
        specialDiscountTotal: special_discount_value,
        t,
        userRole: user.role,
        vatRate: vat_rate,
        vatTotal: vat_total,
      });
    } catch (e) {
      toast.error(t('common:toasts.generateQuote.error'));
    } finally {
      setGeneratingQuote(false);
    }
  };

  const setIsDIY = async (isDiy) => {
    try {
      await postUpdateProjectById({
        category_id: project.category_id,
        comment: project.comment,
        diy: isDiy,
        id: project.id,
      });
      if (!!isDiy) {
        history.push(`/checkout?project=${project.id}`);
        return;
      }
      await refreshProject();
    } catch (e) {
      toast.error(t('common:toasts.updateDiy.error'));
    }
  };

  const handleLinkBackClick = () => {
    sendUserInteraction(`order project: clicked back to translation type`);
    setIsDIY(null);
  };

  const handleMobileControls = (direction) => {
    const newStep = step + direction;

    // Check if new step is valid
    if (newStep === 0) return;
    if (newStep > steps.length + 1) return;

    setStep(newStep);
  };

  const showCatapultToggle = useMemo(() => {
    return canToggleCatapult({ project, user, subscriptions });
  }, [project, user, subscriptions]);

  const canCreateQuote = useMemo(() => {
    // check if project has billing details here
    return !!project.billing_detail_id && (!generatingQuote || !isLoadingPrices);
  }, [generatingQuote, isLoadingPrices, project]);

  const isAdmin = useMemo(() => {
    return hasAdminPermissions(user.role);
  }, [user]);

  /**
   * Generate an array of steps texts
   */
  const mobileStepsTexts = () => {
    const r = [];

    for (let i = 0; i < steps.length; i++) {
      r.push(t('common:projects.nextStep'));
    }

    r.push(t('common:checkout.orderSummary.continueToCheckout'));

    return r;
  };

  /**
   * Runs on active step change, sets step query in url
   */
  useEffect(() => {
    // If on toggle screen
    if (showCatapultToggle) return;

    if (project.diy === null) {
      history.push({
        pathname: location.pathname,
        search: '?step=toggle',
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: '?step=' + step,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, project.diy, showCatapultToggle]);

  /**
   * On steps change dispatch redux steps length update
   * on mobile +1 because order summary is an extra step
   */
  useEffect(() => {
    if (isMobile !== null)
      dispatch(updateStepsLength({ length: isMobile ? steps.length + 1 : steps.length }));
  }, [steps, dispatch, isMobile]);

  return showCatapultToggle ? (
    <CatapultToggle project={project} pricesData={pricesData} updateProjectDiy={setIsDIY} />
  ) : project.diy ? (
    <Redirect to={`/checkout?project=${project.id}`} />
  ) : (
    <SidebarWrapper isAdmin={isAdmin} overflow="false">
      <Container className="order-project-container" isAdmin={isAdmin}>
        <OrderProjectHeader project={project} />
        <MainContentContainer className="order-project-content-container" id="order-main-content-wrapper">
          <StyledHeader className="hide-on-mobile">
            {t('common:projects.customisePackageHeader')}
          </StyledHeader>
          <GridContainer>
            <Accordion
              items={steps}
              openIndexes={[step - 1]}
              setOpenIndexes={(openIndexes) => setStep(openIndexes[0] + 1)}
              preventClose
              single
              step={step}
              stepsLength={steps.length + 1}
            />
            <OrderSummaryColumn ref={stickyRoot} step={step} stepsLength={steps.length + 1}>
              <div ref={stickyElement} className="sticky-container">
                <OrderSummary
                  billingDetails={billingDetails}
                  project={project}
                  price={pricesData}
                  loadingPrices={isLoadingPrices}
                  nextStep={() => setStep(step + 1)}
                  onLastStep={isMobile ? step === steps.length + 1 : step === steps.length}
                  onCoupon={() => setCuponAccepted(true)}
                  isLimitedMember={isLimitedMember}
                  isMobile={isMobile}
                >
                  {pricesData.can_order_diy && (
                    <ReturnLink onClick={handleLinkBackClick} to={`/project/${project.id}`}>
                      <Icon name="arrow-left" />
                      {t('common:projects.analyse.back')}
                    </ReturnLink>
                  )}
                </OrderSummary>
                <GenerateQuoteButton
                  billingDetailID={project?.billing_detail_id}
                  loading={generatingQuote || isLoadingPrices}
                  canCreateQuote={canCreateQuote}
                  step={step}
                  stepsLength={isMobile ? steps.length + 1 : steps.length}
                  isMobile={isMobile}
                  handleClick={handleDownloadQuote}
                />
              </div>
            </OrderSummaryColumn>
          </GridContainer>
        </MainContentContainer>
        <MobileStepsControls
          className="hide-on-desktop"
          handleStepChange={handleMobileControls}
          handleLastStep={() => {
            if (!isLimitedMember) {
              history.push(`/checkout?project=${project.id}`);
            }
          }}
          step={step}
          stepsLength={steps.length + 1}
          stepsTexts={mobileStepsTexts()}
          loading={generatingQuote || isLoadingPrices}
        />
      </Container>
      {isAdmin ? <AdminSidebar project={project} refreshProject={refreshProject} /> : null}
    </SidebarWrapper>
  );
};

export default OrderProject;
