import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './style.scss';

/**
 * Render a small contact card
 */
const ContactCard = ({ icon, title = '', onClick, href = '', linkText = '' }) => {
  return (
    <div className="contact-card">
      <div className="contact-card__title">
        {icon && icon}
        {title}
      </div>
      <a onClick={onClick} href={href}>
        {linkText}
      </a>
    </div>
  );
};

// Prop types
ContactCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  linkText: PropTypes.string,
};

export default ContactCard;
