import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const { useCallback } = require('react');

const wait1Second = new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, 1000);
});

export const useRecaptchaV3 = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const generateToken = useCallback(async () => {
    while (!executeRecaptcha) {
      await wait1Second();
    }
    const token = await executeRecaptcha('submit');

    return token;
  }, [executeRecaptcha]);

  return { generateToken };
};
