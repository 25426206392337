import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import CardPaymentMethod from '../CardPaymentMethod/CardPaymentMethod';

// Style
import './style.scss';

const NewPaymentMethod = ({ selected = false, onClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <button
      className="add-new-payment-method"
      title={t('common:checkout.payment.addNewCreditCard')}
      onClick={onClick}
    >
      <div className={`new-payment-icon ${selected ? 'selected' : ''}`}>
        <Icon name="plus" size="big" fitted></Icon>
      </div>
    </button>
  );
};

const PaymentMethodsSelect = ({
  selected,
  paymentMethods = [],
  onSelectChange = () => {},
  canAddPayment = true,
}) => {
  const onChange = (idx) => {
    return () => {
      onSelectChange(idx);
    };
  };

  const paymentMethodCards = paymentMethods.map((paymentMethod, idx) => (
    <CardPaymentMethod
      onClick={onChange(idx)}
      key={paymentMethod.id}
      paymentMethod={paymentMethod}
      selected={idx === selected}
    />
  ));

  return (
    <div className="payment-methods-wrapper">
      {canAddPayment && <NewPaymentMethod onClick={onChange(-1)} selected={selected === -1} />}
      {paymentMethodCards}
    </div>
  );
};

export default PaymentMethodsSelect;
