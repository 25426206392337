import styled from 'styled-components';
import React from 'react';
import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';

export const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6em;
`;

export const TeamLabel = styled.span`
  line-height: 1.285714em;
  color: #999999;
`;

export const StyledHeader = styled.h4`
  margin-bottom: 0 !important;
  white-space: nowrap;
  width: 212px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.5714285em;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.black};
  font-family: 'DM Sans', sans-serif;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: space-between;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    height: auto;
  `}
`;

export const StyledButton = styled(Button)`
  margin: 0 !important;
`;

export const StyledMobileProjectButton = styled(Button)`
  &.button.flex {
    margin-top: 8px;
    margin-right: 0;
    width: 100%;
  }

  .icon {
    margin-left: 7px;
    color: ${({ theme }) => theme.colors.grey};
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
    &.button.flex {
      display: none;
    }
  `}
`;

export const AdminButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  padding: 10px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 18px;
`;

export const PriceFooter = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.greyLight};
  display: flex;
  align-items: center;
  margin-top: auto;
  font-size: 1.14286em;
`;

const StyledProgress = styled.div`
  width: ${({ percent }) => (percent || 0) + '%'};
  height: 4px;
  background: ${({ color }) => color};
`;

export const Progress = ({ percent, width, style, color, ...rest }) => (
  <div style={{ ...style, width, background: '#ededed' }} {...rest}>
    <StyledProgress percent={percent} color={color} />
  </div>
);

export const StyledDivider = styled.hr`
  background-color: #e1e1e1;
  height: 1px;
  border: 0;
  width: 100%;
  margin: 0.6em 0 1em;
`;

export const DateContainer = styled.div`
  font-size: 1.14286em;
  line-height: 23px;
`;

export const PriceContainer = styled.div`
  padding: 16px 10px 17px 30px;
`;

export const AdminPrice = styled.div`
  font-size: 1.1428571em;
  margin: auto 0 0 auto;
`;

export const StyledIcon = styled(Icon)`
  padding: 8px;
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #e9e9e9;
  }
`;
