import React from 'react';

import { StepsContainer, Step } from './Steps.styles';
import { AnimatedCheckmark } from './AnimatedCheckmark';

const Steps = ({ activeStep = 1, validSteps = [], steps = [], setActiveStep, ...rest }) => {
  const isValid = (stepNumber) => {
    return validSteps.includes(stepNumber);
  };

  const isClickable = (stepNumber) => {
    return (
      stepNumber < activeStep || (stepNumber === activeStep + 1 && isValid(activeStep)) || isValid(stepNumber)
    );
  };

  const handleStepClick = (stepNumber) => {
    if (isClickable(stepNumber)) {
      setActiveStep(stepNumber);
    }
  };

  const getStepClasses = (stepNumber) => {
    const classes = [];
    if (stepNumber === activeStep) classes.push('active');
    if (isValid(stepNumber)) classes.push('enabled');
    if (isClickable(stepNumber)) classes.push('clickable');
    if (stepNumber > 1) classes.push('step-padding');
    return classes.join(' ');
  };

  return (
    <StepsContainer data-cy="steps" {...rest}>
      {steps.map(({ number, text }) => (
        <Step
          data-cy={`steps-step-${number}`}
          key={number}
          onClick={() => handleStepClick(number)}
          className={getStepClasses(number)}
        >
          <div className="step-content">
            <div className="step-number">
              {isValid(number) ? <AnimatedCheckmark color="white" /> : number}
            </div>
            {text}
          </div>
          {number < steps[steps.length - 1].number && (
            <svg className="step-arrow" height="72" width="24">
              <polygon points="0,0 23,36 0,72" />
              <line x1="0" y1="0" x2="0" y2="72" />
            </svg>
          )}
        </Step>
      ))}
    </StepsContainer>
  );
};

export default Steps;
