import { Segment } from 'semantic-ui-react';
import { Icon } from '../common/Icon/Icon';
import styled from 'styled-components';

export const TeamsLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TeamItem = styled(Segment)`
  display: grid;
  align-items: baseline;
  width: 280px;
  cursor: pointer;
  margin: 20px !important;
  padding: 20px !important;
  color: #fff;
  background-color: #2980b9 !important;
  .item-content {
    display: grid;
    justify-items: center;
    grid-template-rows: 100px auto;
    text-align: center;
  }

  &:hover {
    background-color: #3498db !important;
  }
`;
export const TeamItemIcon = styled(Icon)`
  svg {
    height: 5em;
    width: auto;
  }
`;

export const TeamItemHeader = styled.h1`
  margin: 0px !important;
`;
