import { StepControls } from 'components/AdminAddVendor/AddSteps';
import { useAddVendorContext } from 'components/AdminAddVendor/AddVendorProvider';
import VendorUserSelect from 'components/AdminAddVendor/VendorUserSelect';
import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import { Input } from 'components/common/Input/Input';
import SectionDescription from 'components/common/SectionDescription/SectionDescription';
import UserAvatar from 'components/common/UserAvatar/UserAvatar';
import VendorSettingsForm from 'components/common/Vendor/Forms/VendorSettingsForm/VendorSettingsForm';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';

const VendorInfo = () => {
  const { useVendorData } = useAddVendorContext();
  const [formData, setFormData] = useVendorData('vendor-info');

  const { t } = useTranslation();

  const handleInputChange = (_, data) => {
    // If value and name exists in data, we are looking at single input
    setFormData({
      ...formData,
      [data.name]: data.value,
    });
  };

  const handleFormDataChange = (_, data) => {
    setFormData({
      ...formData,
      ...data,
    });
  };

  const initialVendorFormData = {
    native_lang_id: formData.native_lang_id,
    description: formData.description,
    timezone: formData.timezone,
    country_id: formData.country_id,
  };

  const handleUploadPhoto = async (pic) => {
    if (pic.length > 0)
      setFormData({
        ...formData,
        avatar: pic[0],
      });
  };

  const handleUserSelect = (user) => {
    if (user) {
      setFormData({
        ...formData,
        userID: user.id,
        name: user.name,
        phone: user.phone_number,
        email: user.email,
      });
    } else {
      setFormData({ ...formData, userID: null });
    }
  };

  const avatarSource =
    formData.avatar instanceof File ? URL.createObjectURL(formData.avatar) : formData.avatar;

  return (
    <section className="form-step basic-info-step">
      <header>
        <h2>{t('common:admin.vendorOnboarding.stepOne.title', 'Vendor information')}</h2>
        <p>{(t('common:admin.vendorOnboarding.stepOne.description'), 'Fill out basic vendor information')}</p>
      </header>
      <div className="form">
        <SectionDescription
          title={t(`common:serviceProvider.forms.inputs.user.title`, 'User')}
          description={t(
            `common:serviceProvider.forms.inputs.user.description`,
            'Select the user to attach this vendor profile to. If left empty, the vendor profile will be created without a user.',
          )}
          content={<VendorUserSelect onUserSelect={handleUserSelect} />}
        />

        <Divider />

        <SectionDescription
          title={t('common:admin.singleVendor.tabs.adminSettings.inputs.email.title', 'Email')}
          description={t(
            'common:admin.singleVendor.tabs.adminSettings.inputs.email.description',
            'Email address with which this vendor can be contacted',
          )}
          content={
            <Input
              name="email"
              onChange={handleInputChange}
              value={formData.email}
              disabled={formData.userID}
              placeholder={t(
                'common:admin.singleVendor.tabs.adminSettings.inputs.email.placeholder',
                'Email',
              )}
            />
          }
        />

        <Divider />

        <SectionDescription
          title={`${t(`common:serviceProvider.forms.inputs.name.title`)} *`}
          description={t(`common:serviceProvider.forms.inputs.name.description`)}
          content={
            <Input
              name="name"
              onChange={handleInputChange}
              value={formData.name}
              placeholder={t(`common:serviceProvider.forms.inputs.name.placeholder`)}
            />
          }
        />

        <Divider />

        <SectionDescription
          title={t(`common:serviceProvider.forms.inputs.profilePhoto.title`)}
          description={t(`common:serviceProvider.forms.inputs.profilePhoto.description`)}
          content={
            <div className="profile-photo-input">
              <UserAvatar src={avatarSource} />
              <Dropzone
                accept={['image/jpeg', 'image/png', 'image/bmp', 'image/gif']}
                onDrop={handleUploadPhoto}
                maxSize={1_000_000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button labelPosition="left">
                      <Icon name="upload" />
                      <span>{t(`common:serviceProvider.forms.inputs.profilePhoto.placeholder`)}</span>
                    </Button>
                  </div>
                )}
              </Dropzone>
            </div>
          }
        />

        <Divider />

        <SectionDescription
          title={t(`common:serviceProvider.forms.inputs.phone.title`)}
          description={t(`common:serviceProvider.forms.inputs.phone.description`)}
          content={
            <Input
              name="phone"
              onChange={handleInputChange}
              value={formData.phone}
              placeholder={t(`common:serviceProvider.forms.inputs.phone.placeholder`)}
              type="tel"
            />
          }
        />

        <Divider />
        <VendorSettingsForm onChange={handleFormDataChange} initialFormData={initialVendorFormData} />
        <StepControls></StepControls>
      </div>
    </section>
  );
};

export default VendorInfo;
