/* eslint-disable no-unused-vars */
import AssignPerson from 'components/common/AssignPerson/AssignPerson';
import { CustomerSuggestionsContainer } from 'components/common/CustomerSuggestions/CustomerSuggestions.style';
import { Icon } from 'components/common/Icon/Icon';
import { VENDOR_ROLES } from 'constants/roles';
import { useDebounce } from 'hoc/debouncer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { getAllUsers } from 'services/users';

const VendorUserSelect = ({ initialUser = null, onUserSelect = () => {} }) => {
  const [userText, setUserText] = useState(initialUser ? `${initialUser.name} (${initialUser.email})` : '');
  const [user, setUser] = useState(initialUser);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { debounce } = useDebounce();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const data = await getAllUsers({ search: userText, roles: [...VENDOR_ROLES] });
        setUsers(data.data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (!user) {
      debounce(
        'find-vendor-user',
        () => {
          fetchUsers();
        },
        400,
      );
    }
  }, [userText, user, debounce]);

  const handleUserClick = (option) => () => {
    setUserText(`${option.name} (${option.email})`);
    onUserSelect(option);
    setUser(option);
  };

  const suggestions = users.slice(0, 5).map((option) => {
    return (
      <div className="suggestion" onClick={handleUserClick(option)} key={option.id}>
        {option.name} ({option.email})
      </div>
    );
  });

  const handleInputChange = (e) => {
    if (user) {
      setUser(null);
      onUserSelect(null);
    }
    setUserText(e.target.value);
  };

  return (
    <div className="vendor-onboarding-user">
      <CustomerSuggestionsContainer className={`${userText.length > 0 && !user ? 'autocomplete-open' : ''}`}>
        <div className="start-field">
          {isLoading ? (
            <Loader active inline size="tiny"></Loader>
          ) : (
            <Icon name={user ? 'check' : 'search'} />
          )}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder={t(`common:serviceProvider.forms.inputs.user.title`, 'User')}
            value={userText}
            onChange={handleInputChange}
          />
        </div>

        <div className="autocomplete-field">
          {suggestions.length > 0 ? (
            suggestions
          ) : (
            <div className="suggestion">{t('common:autocomplete.noResults')}</div>
          )}
        </div>
      </CustomerSuggestionsContainer>
    </div>
  );
};

export default VendorUserSelect;
