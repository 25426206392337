import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import '../Subscription/SubscriptionDetails/style.scss';

import Button from '../../../common/Button/Button';
import Loader from 'components/common/Loader/Loader';
import Message from 'components/common/Message/Message';
import ProgressBar from '../../../common/ProgressBar/ProgressBar';

import { isCompanyRole } from 'utils/user.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

import defaultPackageImage from '../../assets/Catapult.svg';
import { getUser } from 'services/auth';

const FreeSubscription = () => {
  const [error, setError] = useState(null);
  const [fetchingData, setFetchingData] = useState(true); // component loads data on mount
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const [subscriptionData, setSubscriptionData] = useState(null);

  const usagePercentage = useMemo(() => {
    if (!!subscriptionData) {
      const { limit_used, word_limit } = subscriptionData;
      return !!limit_used ? (limit_used * 100) / word_limit : 0;
    }
    return 0;
  }, [subscriptionData]);

  const handleChangePackage = (e) => {
    sendUserInteraction('subscription overview free subscription page upgrade subscription click');
    if (user?.billing_detail) {
      history.push(`/catapult/packages?billingId=${user?.billing_detail.id}`);
    } else {
      history.push(`/catapult/packages`);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      setFetchingData(true);
      try {
        // fetch user data and write subscription data into local state
        const userInfo = await getUser();
        setSubscriptionData(userInfo.data.free_subscription);
      } catch (e) {
        setError(e);
      } finally {
        setFetchingData(false);
      }
    };

    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyUser = useMemo(() => {
    return isCompanyRole(user.role);
  }, [user]);

  return (
    <>
      {!!fetchingData ? (
        <div className="subscription-loader-container">
          <Loader active />
        </div>
      ) : !!error ? (
        <Message
          header={t('common:catapult.subscription.loadHeader')}
          text={t('common:catapult.subscription.loadError')}
          type="error"
        />
      ) : (
        <section className="container">
          <img alt="catapult" className="package-image" src={defaultPackageImage} />
          <div className="details-container">
            <label className="label">{`${t('common:catapult.subscription.package')}:`}</label>
            <h2 className="heading-2 product-name">{t('common:catapult.packages.freePlan')}</h2>
            {!!subscriptionData && (
              <label className="usage-label label">{`${t('common:catapult.subscription.currentlyUsing')} 
            (${subscriptionData?.limit_used}/${subscriptionData?.word_limit}):`}</label>
            )}
            <ProgressBar progress={usagePercentage} width="100%" />
            <div className="buttons-container">
              {!companyUser && (
                <Button actiontype="primary" onClick={handleChangePackage}>
                  {t('common:catapult.subscription.upgradeSubscription')}
                </Button>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FreeSubscription;
