/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Dropdown, Header } from 'semantic-ui-react';
import Loader from '../../common/Loader/Loader';
import Message from '../../common/Message/Message';
import { getBillingDetailsTeams, getCompanyBillingDetailsTeams } from '../../../services/billing';
import { postUpdateTeam } from '../../../services/teams';
import { checkRoles } from '../../../utils/user.utils';
import Button from '../../common/Button/Button';
import LoadingPane from '../../common/LoadingPane/LoadingPane';
import BillingCard from '../../common/BillingDetails/BillingCard/BillingCard';
import TeamDropdown from '../../common/BillingDetails/TeamDropdown/TeamDropdown';
import { TeamBillingWrapper } from './TeamBilling.style';
import { sendUserInteraction } from 'utils/tagManager.utils';

import { checkTeamRole } from 'utils/user.utils';
import { getUser } from 'services/auth';
import { getCompanyTeams } from 'services/company';
import { getBillingSubscriptions, getSubscriptionById } from 'services/subscriptions';
import { getTeamsSubscriptions } from 'utils/initialLoad';

import { addSubscriptions, removeAllSubscriptions } from 'store/subscriptionsSlice';
import { setUser } from 'store/userSlice';

const TeamBilling = ({ t, companyId, team, onUpdate, user }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [availableBilling, setAvailableBilling] = useState([]);
  const [teamBilling, setTeamBilling] = useState({});
  const [teamSubscriptions, setTeamSubscriptions] = useState([]);
  const [selectedBilling, setSelectedBilling] = useState(
    team.billing_detail_id ? team.billing_detail_id : null,
  );
  const [changeBilling, setChangeBilling] = useState(false);
  const [billingPreview, setBillingPreview] = useState({});
  const [subscriptionsPreview, setSubscriptionsPreview] = useState([]);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchTeamBilling = async () => {
      try {
        setIsLoadingPreview(true);
        const data = await getBillingDetailsTeams(selectedBilling);
        const subs = (await getBillingSubscriptions({ billing_id: selectedBilling, active: true }))['data'];

        setSubscriptionsPreview(subs);
        setBillingPreview(data);
      } catch (e) {
        setError(t('common:team.billing.teamBillingError'));
        throw e;
      } finally {
        setIsLoadingPreview(false);
      }
    };

    if (selectedBilling !== null) {
      fetchTeamBilling();
    } else {
      setIsLoading(false);
    }
  }, [selectedBilling, t]);

  useEffect(() => {
    const fetchAllBilling = async () => {
      try {
        setIsLoadingAll(true);
        let billing = await getCompanyBillingDetailsTeams(companyId);
        billing = billing.map((bill) => {
          return {
            key: bill.id,
            text: bill.name ? bill.name : t('common:notSet'),
            value: bill.id,
          };
        });
        setAvailableBilling(billing);
      } catch (e) {
        setError(t('common:team.billing.allBillingError'));
        throw e;
      } finally {
        setIsLoadingAll(false);
      }
    };
    //Only comapny managers and owners can assign teams billing details
    if (checkRoles({ user: user, teamId: id, allowedUserRoles: [29, 40] })) {
      fetchAllBilling();
    }
  }, [companyId, t, id, user, team.billing_detail_id]);

  useEffect(() => {
    const fetchTeamBilling = async () => {
      try {
        setIsLoading(true);
        const data = await getBillingDetailsTeams(team.billing_detail_id);
        const subs = (await getBillingSubscriptions({ billing_id: team.billing_detail_id, active: true }))[
          'data'
        ];

        setTeamSubscriptions(subs);
        setTeamBilling(data);
      } catch (e) {
        setError(t('common:team.billing.teamBillingError'));
        throw e;
      } finally {
        setIsLoading(false);
      }
    };

    if (team.billing_detail_id !== null) {
      fetchTeamBilling();
    }
  }, [team.billing_detail_id, t]);

  const updateTeamBilling = async () => {
    sendUserInteraction('updating team billing details');
    try {
      setIsUpdating(true);
      const newTeam = await postUpdateTeam({
        team_id: id,
        billing_detail_id: selectedBilling,
      });
      onUpdate(newTeam);
      setSelectedBilling(null);
      setChangeBilling(false);

      // load user subscriptions again here
      dispatch(removeAllSubscriptions());

      const userInfo = await getUser();
      dispatch(setUser({ user: userInfo.data }));
      if (!!userInfo?.data?.free_subscription) {
        dispatch(addSubscriptions([userInfo?.data?.free_subscription]));
      }
      if (!!userInfo?.data?.legacy_subscription) {
        dispatch(addSubscriptions([userInfo?.data?.legacy_subscription]));
      }

      // if user is a company owner load all teams for that company, load all subscriptions for teams billing
      if (!!userInfo?.data?.company_owner_id) {
        const companyTeams = await getCompanyTeams(userInfo?.data?.company_owner_id);
        const subscriptions = await getTeamsSubscriptions(companyTeams);
        dispatch(addSubscriptions(subscriptions));
      }

      // else if user is a member of any team load all subscriptions for teams that have billing
      if (!userInfo?.data?.company_owner_id) {
        const subscriptions = await getTeamsSubscriptions(userInfo?.data?.teams);

        const subscriptionsDetails = await Promise.all(
          subscriptions.map(async (sub) => {
            const data = await getSubscriptionById(sub.id);
            return data;
          }),
        );

        // filter out subscriptions for which user role 42 has data
        const userSubscriptions = subscriptionsDetails.filter((sub) => {
          // get team related to this subscription
          const subscriptionTeam = userInfo?.data?.teams.find(
            (team) => team.billing_detail_id === sub.billing_detail_id,
          );
          // billing managers and team managers can manage subscriptions with seats, full and limited members can only see seat usage data
          const { role_id } = subscriptionTeam;
          const isManager = checkTeamRole({ allowedRoles: [1, 4], teamRole: role_id });

          // if the user can manage subscription data, return teh data, if the user cannot manage subscription data only return the subscription if he has an active subscription seat
          return !!isManager ? true : !!sub.auth_user_data;
        });

        dispatch(addSubscriptions(userSubscriptions));
      }

      setIsUpdating(false);
      toast.success(t('common:toasts.updateBilling.success'));
    } catch (e) {
      toast.error(t('common:toasts.updateBilling.error'));
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleBillingUpdate = async () => {
    const fetchTeamBilling = async () => {
      try {
        setIsLoading(true);
        const data = await getBillingDetailsTeams(team.billing_detail_id);

        setTeamBilling(data);
      } catch (e) {
        setError(t('common:team.billing.teamBillingError'));
        throw e;
      } finally {
        setIsLoading(false);
      }
    };

    if (team.billing_detail_id !== null) {
      fetchTeamBilling();
    }
  };

  const handleSelectedChange = (e, data) => {
    sendUserInteraction('selecting team billing');
    const { value } = data;
    setSelectedBilling(value);
  };

  return isLoading ? (
    <LoadingPane />
  ) : (
    <TeamBillingWrapper>
      {error.length !== 0 && <Message text={error} type="error" />}
      {changeBilling || team.billing_detail_id === null ? (
        <>
          <div className="select-billing">
            <div className="select-header">
              <Header>
                {team.billing_detail_id === null
                  ? t('common:team.billing.selectHeader')
                  : t('common:team.billing.changeHeader')}
              </Header>
            </div>
            {checkRoles({ user: user, teamId: id, allowedUserRoles: [29, 40] }) ? (
              <>
                <div className="select-description">
                  {team.billing_detail_id === null
                    ? t('common:team.billing.noBilling')
                    : t('common:team.billing.changeBilling')}
                </div>

                {teamSubscriptions.length > 0 ? (
                  <Message type="error" text={t('common:team.billing.currentBillingHasSubscription')} />
                ) : null}

                <Dropdown
                  className="select-dropdown"
                  fluid
                  selection
                  search
                  options={availableBilling}
                  value={selectedBilling}
                  onChange={handleSelectedChange}
                  loading={isLoadingAll}
                />
                {selectedBilling !== null ? (
                  isLoadingPreview ? (
                    <div className="select-loader">
                      <Loader inline />
                    </div>
                  ) : (
                    <BillingCard
                      billingDetails={billingPreview}
                      isCollapsedOnMount={false}
                      isCollapsible={false}
                    />
                  )
                ) : null}

                {subscriptionsPreview.length > 0 ? (
                  <Message text={t('common:team.billing.selectedBillingHasSubscription')} type="warning" />
                ) : null}

                <div className="select-actions">
                  {changeBilling && (
                    <Button
                      actiontype="cancel"
                      fluid
                      disabled={isUpdating}
                      onClick={() => setChangeBilling(false)}
                    >
                      {t('common:cancel')}
                    </Button>
                  )}
                  <Button
                    actiontype="submit"
                    fluid
                    disabled={selectedBilling === null}
                    style={{ margin: '0px' }}
                    onClick={updateTeamBilling}
                    loading={isUpdating}
                  >
                    {t('common:submit')}
                  </Button>
                </div>
              </>
            ) : (
              <div className="select-description">{t('common:team.billing.noBillingContact')}</div>
            )}
          </div>
        </>
      ) : (
        <BillingCard
          billingDetails={teamBilling}
          controlsComponent={() =>
            TeamDropdown({
              billingDetails: teamBilling,
              billingId: teamBilling.id,
              company_id: companyId,
              onAssignClick: () => setChangeBilling(true),
              teamId: id,
              updateBillingDetails: handleBillingUpdate,
            })
          }
          isCollapsedOnMount={false}
          isCollapsible={false}
        />
      )}
    </TeamBillingWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.userStore.user,
});

export default connect(mapStateToProps)(withTranslation('common')(TeamBilling));
