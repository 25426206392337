import React, { Component } from 'react';
import { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import { Icon } from '../Icon/Icon';
import Button from '../Button/Button';
import { StepsWrapper } from './Steps.style';

class Steps extends Component {
  state = {
    activeStep: 0,
    currentInvalid: false,
  };

  changeActiveStep = (idx) => {
    return (e) => {
      const currentStep = this.props.steps[this.state.activeStep];
      //Step is required
      if (currentStep.required) {
        //You can go back
        if (idx < this.state.activeStep) {
          this.setState({ activeStep: idx, currentInvalid: false });
        } else {
          //Check if form is valid
          if (currentStep.valid) {
            let nextRequired = this.state.activeStep + 1;

            //if there is a required step between where you want to go, go to it
            while (nextRequired < idx) {
              if (this.props.steps[nextRequired].required && !this.props.steps[nextRequired].valid) {
                this.setState({ activeStep: nextRequired, currentInvalid: true });
                return;
              }
              nextRequired++;
            }

            this.setState({ activeStep: idx, currentInvalid: false });
          } else {
            this.setState({ currentInvalid: true });
          }
        }
      } else {
        this.setState({ activeStep: idx, currentInvalid: false });
      }
    };
  };

  stepBack = (e) => {
    const { activeStep } = this.state;
    if (activeStep > 0) {
      this.setState({ activeStep: activeStep - 1 });
    }
  };

  stepForward = (e) => {
    const { steps } = this.props;
    const { activeStep } = this.state;

    const currentStep = steps[activeStep];
    if (activeStep < this.props.steps.length - 1) {
      if (currentStep.required) {
        if (currentStep.valid) {
          this.setState({ activeStep: activeStep + 1, currentInvalid: false });
          steps[activeStep].onNext();
        } else {
          this.setState({ currentInvalid: true });
        }
      } else {
        this.setState({ activeStep: activeStep + 1, currentInvalid: false });
        steps[activeStep].onNext();
      }
    }
  };

  skipStep = (e) => {
    const { activeStep } = this.state;
    if (activeStep < this.props.steps.length - 1) {
      this.setState({ activeStep: activeStep + 1 });
    }
  };

  onEnter = (e) => {
    const currentStep = this.props.steps[this.state.activeStep];
    //If step is valid move to next step with 'enter' key
    if (e.keyCode === 13 && currentStep.valid) {
      this.stepForward(e);
    }
  };

  submit = (e) => {
    this.props.onSubmit();
  };

  render() {
    const { steps, submitting, firstStepBack, onBack } = this.props;
    const { activeStep, currentInvalid } = this.state;
    const currentStep = steps[activeStep];
    let content = currentStep.component;

    const stepElements = steps.map((step, idx) => {
      const iconColor = idx === activeStep ? (currentInvalid ? '#db2828' : '#2185d0') : 'grey';
      return (
        <Fragment key={step.key}>
          <div className="step">
            <Icon
              link
              circular
              color={iconColor}
              inverted={activeStep >= idx}
              fitted
              name={step.icon}
              size="big"
              onClick={this.changeActiveStep(idx)}
            />
            <div onClick={this.changeActiveStep(idx)} className="step-text">
              {step.name}
            </div>
          </div>
          {idx < steps.length - 1 ? <div className="between-step"></div> : null}
        </Fragment>
      );
    });

    return (
      <StepsWrapper>
        <div className="steps">{stepElements}</div>
        <div className="content" onKeyDown={this.onEnter} tabIndex="0">
          <Segment>{content}</Segment>
        </div>
        <div className="controls">
          <div className="left">
            {activeStep === 0 && firstStepBack ? <Button onClick={onBack}>Back</Button> : null}
            {activeStep ? <Button onClick={this.stepBack}>Back</Button> : null}
          </div>
          <div className="right">
            {!currentStep.required && activeStep < steps.length - 1 ? (
              <Button onClick={this.skipStep}>Skip for now</Button>
            ) : null}
            {activeStep < steps.length - 1 ? (
              <Button onClick={this.stepForward} disabled={!currentStep.valid} actiontype="primary">
                Next
              </Button>
            ) : (
              <Button onClick={this.submit} actiontype="primary" loading={submitting}>
                Finish
              </Button>
            )}
          </div>
        </div>
      </StepsWrapper>
    );
  }
}

export default withTranslation('common')(Steps);
