import React from 'react';
import { Container, MessageHeader, Text, TextContainer } from './Message.styles';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as ErrorIcon } from './error.svg';
import { ReactComponent as WarningIcon } from './warning.svg';
import { ReactComponent as CloseIcon } from './close.svg';

const ICONS = {
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

/**
 * @param {{
 *   text: string | Array<string>
 *   type: "error" | "warning" | "info"
 *   style: React.CSSProperties
 *   header: string
 *   icon: React.ReactNode
 *   color: string
 *   onDismiss: Function
 * }} props
 * @param props.onDismiss does not automatically close, needs to be removed by parent
 */
const Message = ({
  text,
  type = null,
  header,
  icon = null,
  color = null,
  onDismiss = null,
  style,
  ...rest
}) => {
  let Icon = icon ? icon : ICONS[type];
  return (
    <Container
      hasHeader={!!header}
      type={type}
      color={color}
      style={style}
      {...rest}
      data-cy={type === 'error' ? 'error-message' : 'message'}
    >
      {Icon && <Icon className="main-icon" />}
      <TextContainer hasHeader={!!header}>
        {header && <MessageHeader>{header}</MessageHeader>}
        <Text>{Array.isArray(text) ? text.map((line, index) => <li key={index}>{line}</li>) : text}</Text>
      </TextContainer>
      {onDismiss && <CloseIcon onClick={onDismiss} className="close-icon" />}
    </Container>
  );
};

export default Message;
