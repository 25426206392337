import styled from 'styled-components';

export const NewMemberWrapper = styled.div`
  .member-form {
    display: flex;
    margin-bottom: 10px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
