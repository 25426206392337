import styled from 'styled-components';
import { Icon } from '../../Icon/Icon';

export const StyledIcon = styled(Icon).attrs((props) => ({ ...props, tabIndex: '0' }))`
  margin-left: auto;
  padding: 10px;
  width: fit-content;
  border-radius: 4px;
  transition: background-color 0.1s ease;
  &:hover,
  &:focus {
    background-color: rgb(233, 233, 233);
  }
`;
