import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Modal } from 'semantic-ui-react';
import { ModalHeading } from './CompanyEditForm.styles';
import EditFormControls from './EditFormControls';
import DescriptionInput from './DescriptionInput';

import {
  patchCustomer,
  postBillingCreate,
  postBillingUpdate,
  postCustomer,
} from '../../../../services/billing';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { currencyByIdSelector } from 'store/selectors';

import BillingForm from 'components/Checkout/ReviewBilling/BillingForm';

const CompanyEditForm = ({ company_id, closeModal, initialBillingDetails = null, updateBilling = null }) => {
  const defaultCountry = {
    id: 235,
    name: 'United Kingdom',
    iso_code: 'GB',
    currency_id: 2,
    billing_group_id: 3,
    check_vat: 1,
  };

  // If initialBillingDetails has a country property, use that. Otherwise, default to United Kingdom
  const initialCountry = initialBillingDetails?.country || defaultCountry;

  // Initialize your state with the preselected country
  const [billingDetails, setStateBillingDetails] = useState(
    initialBillingDetails ? initialBillingDetails : { country: initialCountry },
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const {
    company_name,
    company_vat,
    country,
    currency_id,
    euro_balance,
    name,
    user_email,
    line1,
    city,
    postal_code,
  } = billingDetails;

  const getCurrencyById = useSelector(currencyByIdSelector);
  const currencyData = getCurrencyById(currency_id);

  const updateProperty = ({ name, value }) => {
    const newBillingDetails = {
      ...billingDetails,
      [name]: value,
    };
    setStateBillingDetails(newBillingDetails);
  };

  const submitBilling = async () => {
    setIsSubmitting(true);

    const euroBalace = euro_balance ? euro_balance / currencyData.conversion_rate : euro_balance;

    const payloadBillingDetails = {
      ...billingDetails,
      euro_balance: euroBalace,
      company_id,
      verified: billingDetails.verified ? 1 : 0,
    };

    try {
      if (!initialBillingDetails) {
        sendUserInteraction('submit company billing details: new billing');
        const newBillingDetails = await postBillingCreate(payloadBillingDetails);
        updateBilling(newBillingDetails);

        await postCustomer({
          billing_id: newBillingDetails.id,
          line1: newBillingDetails.line1,
          line2: newBillingDetails.line2,
          postal_code: newBillingDetails.postal_code,
          city: newBillingDetails.city,
          state: newBillingDetails.state,
          name: newBillingDetails.company_name,
          tax_exempt:
            newBillingDetails.vat_rate !== null || newBillingDetails.digital_vat_rate !== null
              ? 'none'
              : 'exempt',
        });
      } else {
        sendUserInteraction('submit company billing details: update billing');
        const newBillingDetails = await postBillingUpdate(payloadBillingDetails.id, payloadBillingDetails);
        updateBilling(newBillingDetails);

        if (newBillingDetails.stripe_id) {
          await patchCustomer({
            billing_id: newBillingDetails.id,
            line1: newBillingDetails.line1,
            line2: newBillingDetails.line2,
            postal_code: newBillingDetails.postal_code,
            city: newBillingDetails.city,
            state: newBillingDetails.state,
            name: newBillingDetails.company_name,
            tax_exempt:
              newBillingDetails.vat_rate !== null || newBillingDetails.digital_vat_rate !== null
                ? 'none'
                : 'exempt',
          });
        } else {
          await postCustomer({
            billing_id: newBillingDetails.id,
            line1: newBillingDetails.line1,
            line2: newBillingDetails.line2,
            postal_code: newBillingDetails.postal_code,
            city: newBillingDetails.city,
            state: newBillingDetails.state,
            name: newBillingDetails.company_name,
            tax_exempt:
              newBillingDetails.vat_rate !== null || newBillingDetails.digital_vat_rate !== null
                ? 'none'
                : 'exempt',
          });
        }
      }

      toast.success(t('common:toasts.companyBillingUpdate.success'));
      closeModal();
    } catch (e) {
      console.error(e);
      toast.error('common:toasts.companyBillingUpdate.error');
      setIsSubmitting(false);
    }
  };

  const updateProperties = (newBillingDetails) => {
    const newStateBillingDetails = {
      ...newBillingDetails,
    };
    setStateBillingDetails(newStateBillingDetails);
  };

  const isFormValid = () => {
    return (
      !!company_name &&
      !!line1 &&
      !!city &&
      !!postal_code &&
      !!country &&
      (country.check_vat ? !!company_vat : true) &&
      !!currency_id &&
      !!user_email
    );
  };

  return (
    <Modal closeOnDimmerClick onClose={closeModal} open size="mini">
      <Modal.Content style={{ width: '500px' }}>
        <ModalHeading>
          {!!initialBillingDetails
            ? t('common:company.billing.editBilling')
            : t('common:company.billing.createBilling')}
        </ModalHeading>
        <DescriptionInput name={name} updateProperty={updateProperty} />
        <BillingForm
          loading={isSubmitting}
          billing={billingDetails}
          role={40}
          disabled={false}
          onChange={updateProperties}
        />

        <EditFormControls
          canSubmit={isFormValid()}
          isSubmitting={isSubmitting}
          onCancel={closeModal}
          onConfirm={submitBilling}
        />
      </Modal.Content>
    </Modal>
  );
};

export default CompanyEditForm;
