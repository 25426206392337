import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const ReviewBillingWrapper = styled.div`
  margin-top: 10px;

  .review-billing-header {
    margin-top: 28px;
    margin-bottom: 48px;
    h1 {
      margin-bottom: 10px;
    }
  }

  .change-button {
    margin-top: 28px;
  }

  .already-subscribed {
    margin-top: 32px;
    max-width: 500px;
  }

  .continue-button {
    margin-top: 16px;
  }
`;

export const TeamBillingForm = styled(Form)`
  margin-bottom: 1em;
`;

export const PhoneForm = styled(Form)`
  max-width: 500px !important;
  margin-bottom: 1em;
  .phone-reason {
    margin-top: 0.5em;
    color: ${({ theme }) => theme.colors.grey};
  }
`;
