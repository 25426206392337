/**
 * Returns x and y position of the popup ensuring it stays within the viewport.
 * @param {React.Event} e
 * @param {React.RefObject<HTMLElement>} containerRef
 * @param {string} position
 * @returns {{x: number, y: number}}
 */
export const getPosition = (e, containerRef, position = 'top-center') => {
  const triggerRect = e.currentTarget.getBoundingClientRect();
  const popupRect = containerRef.current.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  let x = triggerRect.left;
  let y = triggerRect.top;

  // eslint-disable-next-line default-case
  switch (position) {
    case 'top-center':
      x = triggerRect.left - popupRect.width / 2 + triggerRect.width / 2;
      y = triggerRect.top - popupRect.height;
      break;
    case 'left-center':
      x = triggerRect.left - popupRect.width;
      y = triggerRect.top + triggerRect.height / 2 - popupRect.height / 2;
      break;
    case 'left-bottom':
      x = triggerRect.right;
      y = triggerRect.bottom - popupRect.height;
      break;
    case 'bottom-center':
      x = triggerRect.left - popupRect.width / 2 + triggerRect.width / 2;
      y = triggerRect.bottom;
      break;
    case 'right-center':
      x = triggerRect.right;
      y = triggerRect.top + triggerRect.height / 2 - popupRect.height / 2;
      break;
    // Add cases for other positions if needed
  }

  // Adjust x to ensure the popup stays within the viewport horizontally
  x = Math.max(0, Math.min(x, viewportWidth - popupRect.width));

  // Adjust y to ensure the popup stays within the viewport vertically
  y = Math.max(0, Math.min(y, viewportHeight - popupRect.height));

  return { x, y };
};
