import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Loader from '../common/Loader/Loader';
import Message from '../common/Message/Message';
import PublicWrapper from '../PublicWrapper/PublicWrapper';
import { StyledHeader } from '../common.styles';
import { StyledCard, InnerContainer } from 'components/SignIn/SignIn.styles';
import { FlexWrapper } from './ActivateUser.styles';

import { getActivateUser } from '../../services/auth';
import { getInitialUserData } from '../../utils/initialLoad';

const ActivateUser = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // component needs to load data in order to function

  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const validateToken = async () => {
      try {
        await getActivateUser(params.token);
        await getInitialUserData();

        toast.success(t('common:toasts.emailVerification.success'));
        history.push('/');
      } catch (e) {
        setIsLoading(false);
        setError(true);
      }
    };
    validateToken();
  }, [history, params.token, t]);

  return (
    <PublicWrapper>
      <StyledCard>
        <InnerContainer>
          <StyledHeader>{t('common:activateUser.activateAccount')}</StyledHeader>
          <FlexWrapper>
            {isLoading ? (
              <Loader inline />
            ) : error ? (
              <Message text={t('common:activateUser.invalidToken')} type="error" style={{ width: '100%' }} />
            ) : (
              <Message
                text={t('common:activateUser.activateSuccessfully')}
                type="info"
                style={{ width: '100%' }}
              />
            )}
          </FlexWrapper>
        </InnerContainer>
      </StyledCard>
    </PublicWrapper>
  );
};

export default ActivateUser;
