import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '../../Checkbox/Checkbox';
import { Container } from './VerifiedInput.styles';
import { InputContainer } from './Inputs.styles';
import { Input } from 'components/common/Input/Input';

const VerifiedInput = ({ project_invoices = null, updateProperty = null, verified = null }) => {
  const { t } = useTranslation();

  const handleVerifiedChange = (e, { checked }) => {
    const newVerified = checked ? 1 : 0;
    updateProperty({ name: 'verified', value: newVerified });
  };

  const handleInvoicesChange = (e, { name, value }) => {
    e.preventDefault();
    const newValue = parseInt(value);
    if (value > -1) {
      updateProperty({ name, value: newValue });
    }
  };

  return (
    <Container>
      <Checkbox
        checked={!!verified}
        label={t('common:userInfo.billing.labels.verified')}
        name="verified"
        onChange={handleVerifiedChange}
        style={{ margin: 'auto 0' }}
      />
      <InputContainer>
        <Input
          label={t('common:userInfo.billing.labels.paymentDue')}
          name="project_invoices"
          onChange={handleInvoicesChange}
          type="number"
          value={project_invoices || ''}
        />
      </InputContainer>
    </Container>
  );
};

export default VerifiedInput;
