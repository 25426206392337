import { clearFilters } from 'providers/FiltersProvider';
import { useCallback, useRef, useState } from 'react';
import { clearLSForVendorOnboarding } from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';

export const useLocalStorage = (key, initialValue) => {
  if (typeof key !== 'string') {
    throw new Error('Local storage key is not defined');
  }
  // Reference to the state value, here to make setValue function stable, like useState API
  const stateRef = useRef(null);
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // If no item, create new key with default values
      if (!item) localStorage.setItem(key, JSON.stringify(initialValue));

      // Parse stored json or if none return initialValue
      stateRef.current = item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      stateRef.current = initialValue;
    }
    return stateRef.current;
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(stateRef.current) : value;
        // Save state
        setStoredValue(valueToStore);
        stateRef.current = valueToStore;
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error);
      }
    },
    [key],
  );
  return [storedValue, setValue];
};

export const clearFilterLocalStorage = () => {
  window.localStorage.removeItem('admin-companies-page');
  window.localStorage.removeItem('admin-companies-search');

  window.localStorage.removeItem('admin-invoices-page');
  window.localStorage.removeItem('admin-invoices-paid-filter');
  window.localStorage.removeItem('admin-invoices-type-filter');
  window.localStorage.removeItem('admin-invoices-search');

  window.localStorage.removeItem('admin-project-page');
  window.localStorage.removeItem('admin-project-search');
  window.localStorage.removeItem('admin-project-paid-filters');
  window.localStorage.removeItem('admin-project-status-filters');
  window.localStorage.removeItem('admin-project-invoice-issued');
  window.localStorage.removeItem('admin-project-project-manager-filters');

  window.localStorage.removeItem('admin-attempted-project-page');

  window.localStorage.removeItem('admin-diy-projects-page');
  window.localStorage.removeItem('admin-diy-project-search');

  window.localStorage.removeItem('admin-users-page');
  window.localStorage.removeItem('admin-users-filters');
  window.localStorage.removeItem('admin-users-text-filter');
  window.localStorage.removeItem('admin-users-sorting');

  window.localStorage.removeItem('free-subscriptions-query');

  window.localStorage.removeItem('legacy-subscriptions-params');

  window.localStorage.removeItem('paid-subscriptions-params');

  window.localStorage.removeItem('payments-history-page');

  window.localStorage.removeItem('subscription-usage-table-page');

  window.localStorage.removeItem('company-invoices-page');
  window.localStorage.removeItem('company-invoices-filters');

  window.localStorage.removeItem('company-members-page');

  window.localStorage.removeItem('company-projects-params');

  window.localStorage.removeItem('projects-view-params');
  window.localStorage.removeItem('view-type');
  window.localStorage.removeItem('projects-view-initial-params');

  window.localStorage.removeItem('service-provider-jobs-page');

  window.localStorage.removeItem('team-invoices-page');
  window.localStorage.removeItem('team-invoices-paid-filter');

  window.localStorage.removeItem('team-members-page');

  clearFilters('admin-vendors');

  // Vendor onboarding steps local storage keys
  clearLSForVendorOnboarding();
};
