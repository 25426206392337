import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { removeAllSubscriptions } from '../../../store/subscriptionsSlice';
import { sendUserInteraction } from 'utils/tagManager.utils';

import {
  setAffiliateToken,
  setIsAuthenticated,
  setIsAuthenticating,
  setUser,
} from '../../../store/userSlice';
import { Icon } from '../../common/Icon/Icon';
import { Popup } from '../../common/Popup/Popup';
import AvatarPlaceHolderSrc from '../../../assets/avatar_placeholder.png';
import { clearFilterLocalStorage } from 'utils/storage.utils';

const HelpCenter = styled(Link)`
  display: flex;
  width: 100%;
  column-gap: 10px;
  padding: 18px;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: ${({ $isCollapsed }) => ($isCollapsed ? 'center' : 'flex-start')};
  background-color: ${({ $isHighlighted }) => ($isHighlighted ? '#e3f3f7' : '#C9E8F0')};
  &:hover {
    background-color: #e3f3f7;
  }
`;

const UserActionsContainer = styled.div`
  transition: height 0.3s ease, opacity 0.5s ease;

  .menu-item {
    display: flex;
    padding: 0.856em 2em 0.856em 0.3971428em;
    column-gap: 0.65em;
    color: black;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .menu-item {
      padding-left: 42px;

      &:hover {
        background-color: #e3f3f7;
      }
    }

    height: ${({ expanded }) => (expanded ? '84px' : '0')};
    opacity: ${({ expanded }) => (expanded ? '1' : '0')};
    pointer-events: ${({ expanded }) => (expanded ? 'all' : 'none')};
  }
`;

export const Support = ({ isCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isHighlighted = useMemo(() => {
    return location.pathname === '/support';
  }, [location]);

  return (
    <HelpCenter
      $isCollapsed={isCollapsed}
      $isHighlighted={isHighlighted}
      onClick={() => sendUserInteraction('Support centre click')}
      to="/support"
      data-cy="sidebar-support-link"
    >
      <Icon name="comment-alt-question" />
      {!isCollapsed ? t('common:sidebar.helpCenter') : null}
    </HelpCenter>
  );
};

const UserActionsMenu = ({ expanded }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const logout = () => {
    sendUserInteraction('navbar logout click');

    localStorage.removeItem('authToken');
    clearFilterLocalStorage();

    dispatch(setAffiliateToken({ affiliateToken: null }));
    dispatch(setIsAuthenticated({ isAuthenticated: false }));
    dispatch(setIsAuthenticating({ isAuthenticating: false }));
    dispatch(setUser({ user: null }));
    dispatch(removeAllSubscriptions());

    history.push('/');
  };

  return (
    <UserActionsContainer expanded={expanded}>
      <Link to="/profile" onClick={() => sendUserInteraction('sidebar user popup menu profile click')}>
        <div className="menu-item">
          <Icon name="user" />
          {t('common:navbar.profile')}
        </div>
      </Link>
      <div data-cy="sidebar-user-logout-button" className="menu-item" onClick={logout}>
        <Icon name="sign-out-alt" />
        {t('common:navbar.logOut')}
      </div>
    </UserActionsContainer>
  );
};

export const UserActions = ({ user, isMobile }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    if (isMobile) setExpanded(!expanded);
    sendUserInteraction('sidebar profile photo click');
  };

  return (
    <>
      <Popup
        trigger={
          <div className="user-container" onClick={handleClick}>
            <img
              className="user-avatar"
              data-cy="user-profile-avatar"
              src={user?.avatar || AvatarPlaceHolderSrc}
              alt=""
            />
            <div className="user-data">
              <div className="username">{user?.name || ''}</div>
              <div className="email">
                {user?.company_ownership ? user.company_ownership.company_name : user?.email || ''}
              </div>
            </div>
          </div>
        }
        position="left-bottom"
        on={['click']}
        open={isMobile ? false : undefined}
      >
        <UserActionsMenu />
      </Popup>
      {isMobile && <UserActionsMenu expanded={expanded} />}
    </>
  );
};
