import React from 'react';

import { Cell } from './SortHeaderCell.styles';
import { Icon } from 'semantic-ui-react';

// component expects "asc" "desc" or null as sort direction
// component will call onSortChange with the new sortDireciton value on click
const SortHeaderCell = ({ onSortChange = null, text = '', sort_order = null }) => {
  const handleHeaderClick = (e) => {
    e.preventDefault();
    // default sort direction should be desc
    if (!sort_order || sort_order === 'asc') {
      onSortChange('desc');
    }
    if (sort_order === 'desc') {
      onSortChange('asc');
    }
  };

  return (
    <Cell hasIcon={sort_order !== null} onClick={handleHeaderClick}>
      {text}
      {!!sort_order ? sort_order === 'asc' ? <Icon name="arrow up" /> : <Icon name="arrow down" /> : null}
    </Cell>
  );
};

export default SortHeaderCell;
