import styled from 'styled-components';

import { PasswordInput } from 'components/common/Input/Input';
import PasswordValidator, { StyledLi } from 'components/common/PasswordValidator/PasswordValidator';

export const StyledPasswordInput = styled(PasswordInput)`
  margin-bottom: 10px;
`;

export const StyledPasswordValidator = styled(PasswordValidator)`
  margin: 0 0 25px;
`;

export const StyledForm = styled.form`
  width: clamp(300px, 85%, 400px);

  @media only screen and (max-width: 1000px) {
    margin-top: 20px;
    width: 100%;
  }
`;

export const StyledLabel = styled(StyledLi)`
  padding-left: 30px;
  margin-bottom: 25px;
`;
