import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import { useResizeObserver } from 'hooks/useResizeObserver';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { setCssVariable, getCssVariable } from 'utils/base.utils';
import { AnimatePresence, motion } from 'framer-motion';

// Styles
const Wrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.softUp};
  width: 100vw;
  padding: ${({ theme }) => theme.paddings.mainContainer.mobile.x};

  .button {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .prev-btn {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    width: 100%;
  }

  .next-btn {
    flex-grow: 1;

    .icon {
      margin-left: 6px;
    }
  }
`;

/**
 * Component used in order project to control steps on mobile screens
 */
const MobileStepsControls = ({
  handleStepChange,
  step,
  stepsLength,
  stepsTexts,
  loading,
  handleLastStep,
  validSteps,
  ...rest
}) => {
  // Variables
  const controlsHeightCssVarName = '--mobile-steps-controls-height';
  const onLastStep = step === stepsLength;

  // Ref
  const ref = useRef(null);

  const setStep = (direction) => {
    handleStepChange(direction);
  };

  const handleNextStep = () => {
    onLastStep ? handleLastStep() : setStep(1);
  };

  const nextStepDisabled = () => {
    if (loading) return true;

    if (validSteps && !validSteps.includes(step)) return true;

    return false;
  };

  useResizeObserver((entries) => {
    for (let entry of entries) {
      setCssVariable(controlsHeightCssVarName, entry.target.getBoundingClientRect().height + 'px');
    }
  }, ref);

  useEffect(() => {
    if (!ref.current) return;

    // Mobile steps controls wrapper ref
    const mscRef = ref.current;

    // Update zendesk bottom position
    const currVal = getCssVariable('--zendesk-chat-bottom', true);
    setCssVariable('--zendesk-chat-bottom', currVal + mscRef.getBoundingClientRect().height + 'px');

    return () => {
      setCssVariable(controlsHeightCssVarName, '0px');

      if (mscRef) {
        const currVal = getCssVariable('--zendesk-chat-bottom', true);
        setCssVariable('--zendesk-chat-bottom', currVal - mscRef.getBoundingClientRect().height + 'px');
      }
    };
  }, []);

  return (
    <Wrapper ref={ref} {...rest}>
      <AnimatePresence>
        {step !== 1 && (
          <motion.div
            className="prev-btn__wrapper"
            initial={{
              width: 0,
              marginRight: 0,
            }}
            animate={{
              width: 83,
              marginRight: 10,
            }}
            exit={{
              marginRight: 0,
              width: 0,
              transition: {
                duration: 0.2,
              },
            }}
            key="prev-btn-order-project-mobile"
          >
            <Button
              className="prev-btn"
              onClick={() => setStep(-1)}
              disabled={loading || step === 1}
              loading={loading}
            >
              <Icon name="arrow-left" size="large" />
            </Button>
          </motion.div>
        )}
        <Button
          className="next-btn"
          actiontype="primary"
          onClick={handleNextStep}
          disabled={nextStepDisabled()}
          loading={loading}
        >
          <span>{stepsTexts[step - 1]}</span>
          {!onLastStep && <Icon name="arrow-right" size="large" />}
        </Button>
      </AnimatePresence>
    </Wrapper>
  );
};

export default MobileStepsControls;
