import styled from 'styled-components';

export const PricingWrapper = styled.div`
  margin-bottom: 4em;
`;

export const AddPricing = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue};
  padding: 15px;
  cursor: pointer;
  .header-text {
    font-size: 1.25rem;
  }

  .header-icon {
    margin-left: auto;
  }
`;

export const PricingTitle = styled.div`
  display: flex;
  align-items: center;
`;
