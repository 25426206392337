import httpClient from './httpclient';

export const getStaticData = async () => {
  try {
    const { data } = await httpClient.get(`/static-data`);
    return data;
  } catch (e) {
    throw e;
  }
};
