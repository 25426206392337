import React, { useState } from 'react';
import { adminBatchDeclineRate } from 'services/vendors';
import { adminBatchDeleteRate } from 'services/vendors';
import { adminBatchConfirmRate } from 'services/vendors';
import { Dropdown } from 'semantic-ui-react';
import { adminConfirmVendorRate } from 'services/vendors';
import { adminDeleteVendorRate } from 'services/vendors';
import { adminDeclineVendorRate } from 'services/vendors';
import { batchPatchLanguagePairs } from 'services/vendors';
import { toast } from 'react-toastify';
import { Icon } from 'components/common/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { patchLanguagePair } from 'services/vendors';

const AdminActions = ({ combination, onUpdate = () => {} }) => {
  const [submitting, setSubmitting] = useState(false);

  // Hooks
  const { t } = useTranslation();

  const startLoading = () => {
    setSubmitting(true);
  };

  const stopLoading = () => {
    setSubmitting(false);
  };

  const confirmRate = async () => {
    try {
      startLoading();

      if (combination.pair_ids.length > 1) {
        await adminBatchConfirmRate(combination.pair_ids);
      } else {
        await adminConfirmVendorRate(combination.id);
      }

      toast.success(t('common:admin.languagePairTableRow.adminActionsDropdown.confirmRate.toast.success'));
    } catch (error) {
      console.error(error);
      toast.error(t('common:admin.languagePairTableRow.adminActionsDropdown.confirmRate.toast.error'));
    } finally {
      onUpdate();
      stopLoading();
    }
  };

  const declineRate = async () => {
    try {
      startLoading();

      // Check if batch decline rate
      if (combination.pair_ids.length > 1) {
        await adminBatchDeclineRate(combination.pair_ids);
      } else {
        await adminDeclineVendorRate(combination.id);
      }

      toast.success(t('common:admin.languagePairTableRow.adminActionsDropdown.declineRate.toast.success'));
    } catch (error) {
      console.error(error);
      toast.error(t('common:admin.languagePairTableRow.adminActionsDropdown.declineRate.toast.error'));
    } finally {
      onUpdate();
      stopLoading();
    }
  };

  const deleteRate = async () => {
    try {
      startLoading();

      // Check if batch decline rate
      if (combination.pair_ids.length > 1) {
        await adminBatchDeleteRate(combination.pair_ids);
      } else {
        await adminDeleteVendorRate(combination.id);
      }

      toast.success(t('common:admin.languagePairTableRow.adminActionsDropdown.deleteRate.toast.success'));
    } catch (error) {
      console.error(error);
      toast.error(t('common:admin.languagePairTableRow.adminActionsDropdown.deleteRate.toast.error'));
    } finally {
      onUpdate();
      stopLoading();
    }
  };

  const confirmTest = async () => {
    try {
      startLoading();

      // Check if batch confirm test
      if (combination.pair_ids.length > 1) {
        await batchPatchLanguagePairs({
          ids: combination.pair_ids,
          test_status: 1,
        });
      } else {
        await patchLanguagePair({
          id: combination.id,
          test_status: 1,
        });
      }

      toast.success(t('common:admin.languagePairTableRow.adminActionsDropdown.confirmTest.toast.success'));
    } catch (error) {
      console.error(error);
      toast.error(t('common:admin.languagePairTableRow.adminActionsDropdown.confirmTest.toast.error'));
    } finally {
      onUpdate();
      stopLoading();
    }
  };

  const declineTest = async () => {
    try {
      startLoading();

      // Check if batch confirm test
      if (combination.pair_ids.length > 1) {
        await batchPatchLanguagePairs({
          ids: combination.pair_ids,
          test_status: 0,
        });
      } else {
        await patchLanguagePair({
          id: combination.id,
          test_status: 0,
        });
      }

      toast.success(t('common:admin.languagePairTableRow.adminActionsDropdown.declineTest.toast.success'));
    } catch (error) {
      console.error(error);
      toast.error(t('common:admin.languagePairTableRow.adminActionsDropdown.declineTest.toast.error'));
    } finally {
      onUpdate();
      stopLoading();
    }
  };

  // Check if rate needs confirming
  const { rate_status, test_status } = combination;

  const dangerClassnames = 'hover:has-bg-red-light-4';
  const confirmClassnames = 'hover:has-bg-green-light-4';

  const showDeclineRate = rate_status !== 0 && rate_status !== 1;

  return (
    <Dropdown
      button
      className="ui circular icon button quick-actions admin-actions"
      closeOnBlur
      closeOnEscape
      direction="left"
      icon="ellipsis vertical"
      color="blue"
      style={{ height: 'min-content' }}
      loading={submitting}
    >
      <Dropdown.Menu>
        {rate_status !== 1 && (
          <Dropdown.Item>
            <button className={confirmClassnames} onClick={confirmRate}>
              <Icon name="check-circle" />
              <span>{t('common:admin.languagePairTableRow.adminActionsDropdown.confirmRate.label')}</span>
            </button>
          </Dropdown.Item>
        )}
        {showDeclineRate && (
          <Dropdown.Item>
            <button className={dangerClassnames} onClick={declineRate}>
              <Icon name="times-circle" />
              <span>{t('common:admin.languagePairTableRow.adminActionsDropdown.declineRate.label')}</span>
            </button>
          </Dropdown.Item>
        )}
        {test_status !== 1 && (
          <Dropdown.Item>
            <button className={confirmClassnames} onClick={confirmTest}>
              <Icon name="file-check-alt" />
              <span>{t('common:admin.languagePairTableRow.adminActionsDropdown.confirmTest.label')}</span>
            </button>
          </Dropdown.Item>
        )}
        {test_status !== 0 && (
          <Dropdown.Item>
            <button className={dangerClassnames} onClick={declineTest}>
              <Icon name="file-times-alt" />
              <span>{t('common:admin.languagePairTableRow.adminActionsDropdown.declineTest.label')}</span>
            </button>
          </Dropdown.Item>
        )}

        <Dropdown.Item>
          <button className={dangerClassnames} onClick={deleteRate}>
            <Icon name="trash-alt" />
            <span>{t('common:admin.languagePairTableRow.adminActionsDropdown.deleteRate.label')}</span>
          </button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdminActions;
