import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardsContainer, Container, MainHeading } from './CatapultToggle.styles';

import CatapultCard from './CatapultCard';
import TaiaCard from './TaiaCard';
import { useDispatch } from 'react-redux';
import { addFreeUsage } from 'store/subscriptionsSlice';
import { sendUserInteraction } from 'utils/tagManager.utils';

const CatapultToggle = ({ project, pricesData, updateProjectDiy }) => {
  const [isUpdatingCatapult, setIsUpdatingCatapult] = useState(false);
  const [isUpdatingTaia, setIsUpdatingTaia] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectCatapult = async () => {
    sendUserInteraction(`order project: selected catapult project card`);
    setIsUpdatingCatapult(true);
    dispatch(addFreeUsage(project.total_words));
    await updateProjectDiy(true);
    setIsUpdatingCatapult(false);
  };

  const selectTaia = async () => {
    sendUserInteraction(`order project: selected taia project card`);
    setIsUpdatingTaia(true);

    await updateProjectDiy(false);
    setIsUpdatingTaia(false);
  };

  return (
    <Container>
      <MainHeading>{t('common:projects.analyse.pickType')}</MainHeading>
      <CardsContainer>
        <CatapultCard
          isUpdatingTaia={isUpdatingTaia}
          isUpdatingCatapult={isUpdatingCatapult}
          prices={pricesData}
          project={project}
          selectCatapult={selectCatapult}
        />
        <TaiaCard
          isUpdatingTaia={isUpdatingTaia}
          isUpdatingCatapult={isUpdatingCatapult}
          prices={pricesData}
          project={project}
          selectTaia={selectTaia}
        />
      </CardsContainer>
    </Container>
  );
};

export default CatapultToggle;
