import { Icon } from 'components/common/Icon/Icon';
import { zindexPopperModifier } from 'constants/general';
import React from 'react';
import { Popup } from 'semantic-ui-react';

const TestStatus = ({ status }) => {
  const text = status === null ? 'Pending' : status === 1 ? 'Test succesful' : 'Test failed';
  const color = status === null ? 'blue' : status === 1 ? 'green' : 'red';
  const icon = status === null ? 'file-edit-alt' : status === 1 ? 'check-circle' : 'times-circle';

  return (
    <Popup
      position="top center"
      inverted
      trigger={
        <div className={`status-field has-text-${color}-primary`}>
          <Icon name={icon} inline />
          <span className="text">{text}</span>
        </div>
      }
      content={<span className="text">{text}</span>}
      popperModifiers={[zindexPopperModifier]}
    />
  );
};

export default TestStatus;
