import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Icon } from '../Icon/Icon';
import Price from '../Price/Price';

const RateField = ({ price = { rate: null, rate_status: null, pending_rate: null } }) => {
  const { rate, rate_status, pending_rate } = price;
  return (
    <>
      {rate ? (
        <Price price={rate} round={false} />
      ) : rate_status === 0 ? (
        'Declined'
      ) : rate_status === null ? (
        'Pending'
      ) : (
        'Unset'
      )}
      {rate_status === null ? (
        <Popup
          position="bottom center"
          trigger={<Icon name="credit-card-search" className="has-text-yellow-primary" inline></Icon>}
          content={
            <div className="pricing-suggestion">
              <div className="pricing-suggestion-title">Rate under review</div>
              {pending_rate ? (
                <div className="pricing-suggestion-price">
                  New rate: <Price price={pending_rate} round={false} />
                </div>
              ) : null}
            </div>
          }
        />
      ) : rate_status === 0 ? (
        <Popup
          position="bottom center"
          trigger={<Icon name="times-circle" className="has-text-red-primary" inline></Icon>}
          content={
            <div className="pricing-suggestion">
              <div className="pricing-suggestion-title">Rate declined</div>
              {pending_rate ? (
                <div className="pricing-suggestion-price">
                  Rate: <Price price={pending_rate} round={false} />
                </div>
              ) : null}
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default RateField;
