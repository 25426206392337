import styled, { css } from 'styled-components';
import { Popup } from '../../../common/Popup/Popup';

export const Container = styled.div`
  font-family: 'DM Sans', sans-serif;
  position: relative;

  .dropdown-header {
    padding: 20px 0 20px 23px;
    display: grid;
    grid-template-columns: 1fr auto;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #dbdbdb;
    background-image: linear-gradient(to bottom, #fff, #f6f6f6);
    transition: background-image 0.25s ease;

    .icon {
      padding: 5px;
      transition: transform 0.25s ease;
    }
  }

  .dropdown-menu {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 3;
    transition: opacity, box-shadow, transform;
    will-change: transform, box-shadow, opacity;
    transition-timing-function: ease;
    transition-duration: 250ms;
    transform-origin: top center;
    transform: scaleY(0);
    opacity: 0.7;
    overflow-y: auto;

    &__mobile-header {
      display: flex;
      background-color: ${({ theme }) => theme.colors.white};
      justify-content: space-between;
      padding: 20px;

      .title {
        h2 {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.56;
          margin-bottom: 0;
        }

        .icon {
          margin-right: 7px;
        }

        span:not(.icon) {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.14;
          letter-spacing: normal;
          color: #999;
        }
      }

      .close-btn {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: flex-start;
      }
    }

    .dropdown-item {
      padding: 20px 40px 20px 23px;
      border-bottom: 1px solid #e1e1e1;
      cursor: pointer;
      background-color: white;
      transition: background-color;
      transition-duration: 0.1s;
      transition-timing-function: ease;

      &.highlighted {
        background-color: #e3f3f7;
        border-left: 4px solid ${({ theme }) => theme.colors.blue};
      }
    }

    .comparison-link {
      display: flex;
      justify-content: center;
      padding: 1.35em 1em;

      background-color: #e9e9e9;
      color: #999999;
      font-size: 18px !important;
      font-weight: 500;
      transition: color 0.2s ease;
      cursor: pointer;

      .icon {
        transition: transform 0.2s ease;
      }

      &:hover {
        color: #666;
        .icon {
          transform: translateX(3px);
        }
      }
    }
  }

  &.open {
    z-index: ${({ theme }) => theme.zIndex.bgOverlay + 1};

    .dropdown-header {
      border-color: transparent;
      background-color: #fff;
      background-image: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 4px 4px 18px -4px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 6;

      .icon {
        transform: rotate(180deg);
      }
    }

    .dropdown-menu {
      transform: scaleY(1);
      opacity: 1;
      box-shadow: 4px 4px 18px -4px rgba(0, 0, 0, 0.25);
    }
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
    .dropdown-menu__mobile-header {
      display: none;
    }
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .dropdown-menu {
      background-color: ${theme.colors.white};
      margin: 16px;
      position: fixed;
      top: 0;
      left: 0;
      width: calc(100vw - 32px);
      height: calc((var(--vh, 1vh) * 100) - 32px);
      z-index: ${theme.zIndex.zendeskChatWidget + 1};
      transform-origin: initial;
      transform: scale(0);

      > .dropdown-item:first-child {
        border-top: 1px solid #e1e1e1;
      }

      .dropdown-item {
        padding: 20px;

        &[data-selected="true"] {
          background-color: #e3f3f7;
        }

        &.highlighted {
          background-color: ${theme.colors.white};
          border-left: none;
        }
      }
    }

    &.open {
      .dropdown-header {
        opacity: 0;
      }

      .dropdown-menu {
        transform: scale(1);
      }
    }
  `}
`;

export const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  .name {
    font-size: 16px;
    font-weight: bold;
    .icon-inline {
      margin-right: 8px;
      margin-left: 8px;
    }
    .featured-badge {
      background-color: ${({ featuredColor, theme }) => theme.colors[featuredColor] || 'defaultColor'};
      color: black;
      padding: 2px 6px;
      margin-left: 8px;
      font-size: 12px;
      border-radius: 4px;
      font-weight: normal;
    }
  }

  .desc {
    grid-column: 1;
    color: #999999;
  }

  .price {
    margin: auto;
    grid-row: 1/3;
    grid-column: 2;
    color: ${({ theme }) => theme.colors.blue};
    font-size: 20px;
    font-weight: bold;
  }

  ${({ header }) =>
    header &&
    css`
      border-right: 1px solid #e1e1e1;
      padding-right: 15px;
    `}

  ${({ theme }) =>
    theme.mediaBetween(
      'desktopMedium',
      'desktop',
      `
    display: flex;
    flex-wrap: wrap;

    .name,
    .desc {
      width: 100%;
    }

    .price {
      margin: initial;
      margin-top: 10px;
      grid-row: initial;
      grid-column: initial;
    }
  `,
    )}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .price { 
      font-size: 16px;
      grid-row: 1/2;
    }
  `}
`;

export const StyledPopup = styled(Popup)`
  transition-duration: 0s;
  z-index: ${({ theme }) => theme.zIndex.bgOverlay + 1};

  &:hover {
    transition-duration: 0.2s;
  }
  & > div {
    max-width: 315px;
    padding: 30px;

    .description {
      margin-bottom: 12px;
    }

    .description-readmore {
      text-align: right;
      a {
        color: #fff;
        text-decoration: underline;
        cursor: pointer !important; // TODO
      }
      a:hover {
        cursor: pointer !important;
      }
    }

    .progress-bars {
      display: grid;
      grid-template-columns: auto 1fr;
      margin-top: 1.5em;
      align-items: center;
      grid-column-gap: 1em;
      font-weight: bold;
    }
  }
`;

export const Progress = styled.div.attrs(() => ({ className: 'progress' }))`
  width: 100%;
  height: 8px;
  border-radius: 7px;
  background-color: #000;
  position: relative;

  &:before {
    content: '';
    border-radius: 7px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${(props) => props.progress}%;
    transform-origin: left;
    animation-name: ${(props) => (props.animate ? 'grow' : 'none')};
    animation-timing-function: ease-out;
  }

  &:nth-of-type(1):before {
    animation-duration: 0.3s;
  }

  &:nth-of-type(2):before {
    animation-duration: 0.4s;
  }

  &:nth-of-type(3):before {
    animation-duration: 0.5s;
  }

  @keyframes grow {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
`;
