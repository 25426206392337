import React from 'react';
import FilterSidebar from 'components/common/FilterSidebar/FilterSidebar';
import { Header } from 'semantic-ui-react';
import { useFiltersContext } from 'providers/FiltersProvider';
import { Input } from 'components/common/Input/Input';
import { Icon } from 'components/common/Icon/Icon';
import Button from 'components/common/Button/Button';
import { useTranslation } from 'react-i18next';
import { MultipleDropdown } from 'components/common/Dropdown/MultipleDropdown';
import { getVendorRiskText } from 'utils/vendors.utils';
import { getVendorExperience } from 'utils/vendors.utils';
import { getVendorStatusText } from 'utils/vendors.utils';
import LanguageDropdown from 'components/common/LanguageDropdown/LanguageDropdown';
import { VENDOR_TASK_TYPE_PROOFREAD_ONLY } from 'constants/vendors';
import { VENDOR_TASK_TYPE_REVISION } from 'constants/vendors';
import { VENDOR_TASK_TYPE_TRANSLATION } from 'constants/vendors';

const riskOptions = [
  { key: 1, text: getVendorRiskText(1), value: 1 },
  { key: 2, text: getVendorRiskText(2), value: 2 },
  { key: 3, text: getVendorRiskText(3), value: 3 },
];

const experienceOptions = [
  { key: 1, text: getVendorExperience(1), value: 1 },
  { key: 2, text: getVendorExperience(2), value: 2 },
  { key: 3, text: getVendorExperience(3), value: 3 },
];

const statusOptions = [
  { key: 1, text: getVendorStatusText(1), value: 1 },
  { key: 2, text: getVendorStatusText(2), value: 2 },
  { key: 3, text: getVendorStatusText(3), value: 3 },
  { key: 4, text: getVendorStatusText(4), value: 4 },
  { key: 5, text: getVendorStatusText(5), value: 5 },
  { key: 6, text: getVendorStatusText(6), value: 6 },
];

const VendorFilters = ({ isLoading = false }) => {
  const { filters, changeFilters, resetFilters } = useFiltersContext();
  const { t } = useTranslation();

  const serviceOptions = [
    {
      key: 1,
      value: VENDOR_TASK_TYPE_TRANSLATION,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.translation'),
    },
    {
      key: 2,
      value: VENDOR_TASK_TYPE_REVISION,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.revision'),
    },
    {
      key: 3,
      value: VENDOR_TASK_TYPE_PROOFREAD_ONLY,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.proofread'),
    },
  ];

  const handleFilterChange = (e) => {
    const newFilters = { ...filters };
    newFilters[e.target.name] = e.target.value;
    changeFilters(newFilters);
  };

  const handleClearFilters = () => {
    resetFilters();
  };

  const handleDropdownFiltersChange = (_, { value, name }) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    changeFilters(newFilters);
  };

  return (
    <FilterSidebar
      className="filters-sidebar"
      trigger={(props) => (
        <Button color="blue" {...props}>
          <Icon name="filter" inline />
        </Button>
      )}
    >
      <Header as="h3">{t('common:admin.vendors.filters.header', 'Filter vendors by')}</Header>
      <div className="sidebar-field-group">
        <p>{t('common:admin.vendors.filters.search', 'Search')}</p>
        <Input
          icon={<Icon name="search" inElement />}
          placeholder={t('common:admin.vendors.filters.searchPlaceholder', 'Search...')}
          name="search"
          value={filters.search}
          onChange={handleFilterChange}
          loading={isLoading}
        />
      </div>

      <div className="sidebar-field-group">
        <p>{t('common:admin.vendors.filters.sourceLanguage', 'Source Language:')}</p>
        <LanguageDropdown
          name="source_lang_id"
          value={filters.source_lang_id}
          usedLangauges={[filters.target_lang_id]}
          onChange={handleDropdownFiltersChange}
        />
      </div>

      <div className="sidebar-field-group">
        <p>{t('common:admin.vendors.filters.targetLanguage', 'Target Language:')}</p>
        <LanguageDropdown
          name="target_lang_id"
          value={filters.target_lang_id}
          usedLangauges={[filters.source_lang_id]}
          onChange={handleDropdownFiltersChange}
        />
      </div>

      <div className="sidebar-field-group">
        <p>{t('common:admin.vendors.filters.taskType', 'Task type:')}</p>
        <MultipleDropdown
          value={filters.task_type_id}
          className="person-filter-dropdown"
          options={serviceOptions}
          onChange={handleDropdownFiltersChange}
          placeholder={t('common:admin.vendors.filters.statusPlaceholder', 'Select one or more task types')}
          name="task_type_id"
          search
        />
      </div>

      <div className="sidebar-field-group">
        <p>{t('common:admin.vendors.filters.status', 'Status:')}</p>
        <MultipleDropdown
          value={filters.status}
          className="person-filter-dropdown"
          options={statusOptions}
          onChange={handleDropdownFiltersChange}
          placeholder={t('common:admin.vendors.filters.statusPlaceholder', 'Select one or more statuses')}
          name="status"
          search
        />
      </div>

      <div className="sidebar-field-group">
        <p>{t('common:admin.vendors.filters.risk', 'Risk level:')}</p>
        <MultipleDropdown
          value={filters.risk}
          className="person-filter-dropdown"
          options={riskOptions}
          onChange={handleDropdownFiltersChange}
          placeholder={t('common:admin.vendors.filters.riskPlaceholder', 'Select risk levels')}
          name="risk"
          search
        />
      </div>

      <div className="sidebar-field-group">
        <p>{t('common:admin.vendors.filters.experience', 'Experience level:')}</p>
        <MultipleDropdown
          value={filters.experience}
          className="person-filter-dropdown"
          options={experienceOptions}
          onChange={handleDropdownFiltersChange}
          placeholder={t('common:admin.vendors.filters.experiencePlaceholder', 'Select Experience levels')}
          name="experience"
          search
        />
      </div>

      <div className="sidebar-field">
        <Button loading={isLoading} onClick={handleClearFilters}>
          {t('common:projects.filters.reset')}
        </Button>
      </div>
    </FilterSidebar>
  );
};

export default VendorFilters;
