import React from 'react';
import { Link } from 'react-router-dom';
import { classnames } from 'utils/base.utils';
import { Icon } from '../Icon/Icon';

const CircleTimelineItem = ({ href, onClick, icons, state, index, to, label }) => {
  /**
   * Figure out which element to render
   *
   * If href is passed, render an a tag
   * If onClick is passed, render a button
   *
   * By default render a div
   */
  const Component = (() => {
    if (href) return 'a';
    if (onClick) return 'button';

    return 'div';
  })();

  /**
   * Get icon name
   */
  const iconName = (() => {
    const defaultIcon = icons?.default ?? null;

    // If step is finished, display finished icon
    // If no finished icon, display default or 'check' icon
    if (state?.finished) {
      return icons?.finished ?? defaultIcon ?? 'check';
    }

    // If no icons passed, return false, render step number instead
    if (!icons) return false;

    if (state?.current) {
      return icons.current ?? defaultIcon;
    }

    if (state?.disabled) {
      return icons.disabled ?? defaultIcon;
    }

    return defaultIcon ?? false;
  })();

  const wrapperProps = {
    className: classnames('circle-wrapper', { ...state }), // spread passed prop state { finished, current, disabled }
  };

  const circleProps = {
    className: classnames('circle-timeline-item'),
    // Conditionally add props
    ...(onClick && { onClick }),
    ...(href && { href }),
    ...(to && { to }),
  };

  // If iconname is generated, try to display icon, else display timeline item index + 1
  const icon = iconName ? <Icon name={iconName} size={icons?.size ?? 'large'} /> : index + 1;

  // If to prop is passed, render <Link /> component from react router dom
  const isRouterLink = to;

  const content = (
    <>
      <div className="circle">{icon}</div>
      {(label?.title || label?.subtitle) && (
        <div className="text">
          {label?.title && <h2>{label?.title}</h2>}
          {label?.subtitle && <p>{label?.subtitle}</p>}
        </div>
      )}
    </>
  );

  return (
    <div {...wrapperProps}>
      {isRouterLink ? (
        <Link {...circleProps}>{content}</Link>
      ) : (
        <Component {...circleProps}>{content}</Component>
      )}
    </div>
  );
};

export default CircleTimelineItem;
