import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Icon, Popup } from 'semantic-ui-react';

const BillingStatus = ({ teamId, billingDetails }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const routeToTeamBilling = () => {
    history.push(`/team/${teamId}/billing`);
  };
  // TODO add support for other billing statuses
  return billingDetails === null ? (
    <Popup
      content={t('common:team.billingStatuses.notAssigned')}
      trigger={<Icon color="red" name="warning sign" link onClick={routeToTeamBilling} />}
    />
  ) : (
    <Popup
      content={t('common:team.billingStatuses.assigned')}
      trigger={<Icon color="green" name="checkmark" />}
    />
  );
};

export default BillingStatus;
