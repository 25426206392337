import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { getVendorData } from 'services/vendors';
import { classnames } from 'utils/base.utils';

import { MainContainer } from 'components/common.styles';
import Tab from 'components/common/Tab/Tab';

import AdminSettings from './tabs/AdminSettings/AdminSettings';
import VendorSettings from './tabs/VendorSettings/VendorSettings';
import AdminVendorHeader from './AdminVendorHeader';
import ProfessionalExperience from './tabs/ProfessionalExperience/ProfessionalExperience';
import LanguageCombinations from './tabs/LanguageCombinations/LanguageCombinations';

// Styles
import './style.scss';
import { useTranslation } from 'react-i18next';

// Constants for admin vendor component
const mapTabIdxToNames = {
  1: 'vendor-settings',
  2: 'language-combinations',
  3: 'professional-experience',
};

/**
 * Render admin vendor settings
 */
const AdminVendor = () => {
  // Grab vendor id from params
  const { id: vendorID } = useParams();
  const { search } = useLocation();

  // Local state
  const [vendorData, setVendorData] = useState({
    name: '',
    email: '',
    status: null,
    prices: [],
  });
  const [initialLoad, setInitalLoad] = useState(true);
  const [tabActiveindex, setActiveTabIndex] = useState(null);

  // Hooks
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * Used for getting tab param
   */
  const query = useMemo(() => new URLSearchParams(search), [search]);

  /**
   * Updates search params when the tab changes
   * If not first index, append the param tab with the tab name
   */
  const handleTabChange = (_, { activeIndex }) => {
    history.push(
      `/admin/vendor/${vendorID}${activeIndex !== 0 ? `?tab=${mapTabIdxToNames[activeIndex]}` : ''}`,
    );
  };

  /**
   * Store panes in useMemo
   */
  const panes = useMemo(() => {
    return [
      {
        title: t('common:admin.singleVendor.tabs.adminSettings.title'),
        render: () => (
          <Tab.Pane>
            <AdminSettings loading={initialLoad} vendorData={vendorData} setVendorData={setVendorData} />
          </Tab.Pane>
        ),
      },
      {
        title: t('common:admin.singleVendor.tabs.vendorSettings.title'),
        render: () => (
          <Tab.Pane>
            <VendorSettings loading={initialLoad} vendorData={vendorData} setVendorData={setVendorData} />
          </Tab.Pane>
        ),
      },
      {
        title: t('common:admin.singleVendor.tabs.languageCombinations.title'),
        render: () => (
          <Tab.Pane>
            <LanguageCombinations vendorData={vendorData} setVendorData={setVendorData} />
          </Tab.Pane>
        ),
      },
      {
        title: t('common:admin.singleVendor.tabs.professionalExperience.title'),
        render: () => (
          <Tab.Pane>
            <ProfessionalExperience vendorData={vendorData} setVendorData={setVendorData} />
          </Tab.Pane>
        ),
      },
    ];
  }, [initialLoad, vendorData, setVendorData, t]);

  // Render variables
  const containerClasses = classnames('admin-vendor-profile', {
    'initial-load': initialLoad,
  });

  /**
   * Mount, only runs once,
   * Used for fetching vendor data
   */
  useEffect(() => {
    const fetchVendorData = async () => {
      const { data } = await getVendorData(vendorID);

      setVendorData(data);
      setInitalLoad(false);
    };

    fetchVendorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Runs on query change
   * Check the tab name and update tab index accordingly
   */
  useEffect(() => {
    const tabName = query.get('tab');
    // If not tabName in url, load first tab
    // Else get the tab name from mapTabIdxToNames
    const tabIndex = !tabName
      ? 0
      : Object.keys(mapTabIdxToNames).find((k) => mapTabIdxToNames[k] === tabName);

    setActiveTabIndex(parseInt(tabIndex));
  }, [query, setActiveTabIndex]);

  return (
    <MainContainer className={containerClasses}>
      <AdminVendorHeader vendorData={vendorData} loading={initialLoad} />

      {/* Vendor settings tabs */}
      <Tab panes={panes} activeIndex={tabActiveindex} onTabChange={handleTabChange} loading={initialLoad} />
    </MainContainer>
  );
};

export default AdminVendor;
