import React, { useState, useRef, useEffect } from 'react';
import { Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { getNameAndExtension } from '../../../utils/filename.utils';
import { Icon } from '../Icon/Icon';
import { FileRowContainer, StyledHeader } from './FileDrop.styles';

const Filename = styled.span`
  outline: none;
  ${({ isEditing, valid, theme }) =>
    isEditing
      ? valid
        ? css`
            background: ${theme.colors.blueLight};
          `
        : css`
            background: ${theme.colors.redLight};
            color: ${theme.colors.red};
          `
      : ''}
`;

const File = ({ onRename, onRemove, file, files }) => {
  const [isEditing, setIsEditing] = useState(false);
  const filenameRef = useRef(null);

  const { name, extension } = getNameAndExtension(file.name);

  const [newName, setNewName] = useState(name);

  useEffect(() => {
    if (!isEditing || newName.length === 0) return;
    const setpos = document.createRange();
    const set = window.getSelection();
    setpos.setStart(filenameRef.current.childNodes[0], newName.length);
    setpos.collapse(true);
    set.removeAllRanges();
    set.addRange(setpos);
    filenameRef.current.focus();
  }, [isEditing, newName]);

  const handleRename = () => {
    const newFilename = `${newName}.${extension}`;
    onRename(file.name, newFilename);
  };

  const handleEdit = () => {
    if (isEditing) {
      if (newName === '' || isDuplicate()) return;
      handleRename();
    }
    setIsEditing((prev) => !prev);
  };

  const handleChange = (e) => {
    setNewName(e.target.textContent);
  };

  const handleEnter = (e) => {
    if (e.key !== 'Enter') return;
    e.preventDefault();
    if (newName === '' || isDuplicate()) return;
    handleRename();
    setIsEditing(false);
  };

  const isDuplicate = () => {
    const newFilename = `${newName}.${extension}`;
    return files
      .map((f) => f.name)
      .filter((fname) => fname !== file.name)
      .includes(newFilename);
  };

  return (
    <FileRowContainer data-cy="file-row" key={file.name}>
      <StyledHeader as="h5">
        <Popup
          content="A file with that name already exists."
          open={isEditing && isDuplicate()}
          position="bottom left"
          trigger={
            <Filename
              contentEditable={isEditing}
              suppressContentEditableWarning
              isEditing={isEditing}
              valid={!isDuplicate()}
              ref={filenameRef}
              onInput={handleChange}
              onKeyDown={handleEnter}
            >
              {newName}
            </Filename>
          }
        />
        .{extension} - {(parseFloat(file.size) / 1024 / 1024).toFixed(2)} MB
      </StyledHeader>
      <Icon
        data-cy={isEditing ? 'save-file-name' : 'edit-file-name'}
        fitted
        style={{ marginLeft: 'auto' }}
        name={isEditing ? 'save' : 'edit-alt'}
        link
        onClick={handleEdit}
      />
      <Icon data-cy="remove-file" fitted name="trash" link onClick={onRemove} />
    </FileRowContainer>
  );
};

export default File;
