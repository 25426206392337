import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';

import Button from '../../common/Button/Button';

import { Container } from './ProjectControls.styles';

import { getTranslatedFiles, getUpdateZip, postUpdateProject } from '../../../services/project';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { Popup, Modal } from 'semantic-ui-react';

const ProjectControls = ({ project, refreshProject }) => {
  // State
  const [downloadingFiles, setDownloadingFiles] = useState(false);
  const [settingProjectState, setSettingProjectState] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const user = useSelector((state) => state.userStore.user);

  // Hooks
  const { t } = useTranslation();

  // Variables
  const disableDownload = !user.active;

  // Functions
  /**
   * Check if all jobs on the project are finished
   */
  const allJobsFinished = () => project.jobs.find((j) => j.progress < 100);

  /**
   * Handles modal logic
   * If confirm button is clicked isConfirm is true then update project status
   *
   * @param {boolean} isConfirm
   */
  const handleModal = async (isConfirm = false) => {
    if (isConfirm) {
      try {
        setSettingProjectState(true);
        await postUpdateProject(project, user, true, true);
        await refreshProject();
      } catch (e) {
        toast.error('Failed to update project status.');
        setSettingProjectState(false);
      }
    }

    setWarningOpen(false);
  };

  const handleDownloadTranslated = async (e) => {
    sendUserInteraction('catapult project download translated files click');
    e.preventDefault();
    setDownloadingFiles(true);
    try {
      // first sync translation files from CAT
      await getUpdateZip(project.id);

      // then download the translation files
      const response = await getTranslatedFiles(project.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setDownloadingFiles(false);
    }
  };

  const popupTrigger = (
    <Button
      actiontype="primary"
      disabled={disableDownload}
      loading={downloadingFiles}
      onClick={handleDownloadTranslated}
      id="catapult-project-download-translated-files"
    >
      {t('common:projects.analyse.downloadTranslatedFiles')}
    </Button>
  );

  return (
    <Container>
      {project.status_id === 10 && (
        <Modal
          closeIcon
          size="mini"
          open={warningOpen}
          onClose={() => handleModal()}
          trigger={
            <Button actiontype="secondary" loading={settingProjectState} onClick={() => setWarningOpen(true)}>
              {t('common:projects.analyse.markFinished')}
            </Button>
          }
        >
          <Modal.Content>
            <p>
              {t(
                `common:projects.analyse.${allJobsFinished() ? 'warningJobsNotFinished' : 'warningFinished'}`,
              )}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button actiontype="cancel" onClick={() => handleModal()}>
              {t('common:cancel')}
            </Button>
            <Button actiontype="primary" onClick={() => handleModal(true)}>
              {t('common:confirm')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {user.active ? (
        popupTrigger
      ) : (
        <Popup trigger={popupTrigger} content={t('common:projects.projectInfo.filesNotice')} />
      )}
    </Container>
  );
};

export default ProjectControls;
