import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../common/Button/Button';
import { checkAllowedRole, isCompanyRole } from '../../../utils/user.utils';
import { sendGaVirtual, sendUserInteraction } from '../../../utils/tagManager.utils';

import { getCompanyMembers } from 'services/company';
import { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';

// Style
import '../NotificationPage.scss';
import './style.scss';

// Assets
import checkmarkImg from 'assets/taia-graphic-checkmark.png';

const OrderSuccessful = ({ type, projectId }) => {
  // State
  const [members, setMembers] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { t } = useTranslation();
  const { id, email, free_subscription, name, role, company_ownership } = useSelector(
    (state) => state.userStore.user,
  );

  const isTypePackage = useMemo(() => {
    return type === 'package';
  }, [type]);

  const isUserCompany = isCompanyRole(role);
  const isUserAloneInCompany = members?.length === 1;

  /**
   * Generate text to be displayed under title
   * If user role is company and company only has 1 member,
   * display text that prompts the user to assign seats
   *
   * @returns {string}
   */
  const description = () => {
    if (isTypePackage) {
      // Order package

      // If user is company
      if (isUserCompany) {
        // If user is alone
        if (isUserAloneInCompany) {
          return t('common:checkout.orderSuccess.subscriptionIndividual');
        } else {
          return t('common:checkout.orderSuccess.subscription');
        }
      } else {
        // User is individual or client
        return t('common:checkout.orderSuccess.subscriptionIndividual');
      }
    } else {
      // Order project
      return t('common:checkout.orderSuccess.project');
    }
  };

  const handleRedirect = () => {
    const redirectRoute = isUserCompany
      ? isUserAloneInCompany
        ? '/projects/create'
        : '/catapult/subscriptions'
      : '/projects/create';

    history.push(isTypePackage ? redirectRoute : `/project/${projectId}`);
    sendUserInteraction(`clicked go to ${isTypePackage ? redirectRoute : 'project page'}`);
  };

  const buttonText = () => {
    if (isTypePackage) {
      // Order package

      // If user is company
      if (isUserCompany) {
        // If user is alone
        if (isUserAloneInCompany) {
          return t('common:checkout.projectCreate');
        } else {
          return t('common:checkout.orderSuccess.goToSubscription');
        }
      } else {
        // User is individual or client
        return t('common:checkout.projectCreate');
      }
    } else {
      // Order project
      return t('common:checkout.projectPage');
    }
  };

  /**
   *
   */
  useEffect(() => {
    // only send this event if user is not upgrading
    if (isTypePackage && !free_subscription) {
      sendGaVirtual({ page: 'catapult-signup-completed', userData: { email, id, name } });
    }
  }, [email, free_subscription, id, isTypePackage, name]);

  /**
   * Only on mount
   */
  useEffect(() => {
    const companyId = company_ownership?.id;

    /**
     * Used for fetching company members
     */
    const fetchCompanyMembers = async () => {
      try {
        if (companyId && checkAllowedRole({ allowedRoles: [40], userRole: role })) {
          const { data } = await getCompanyMembers({ id: companyId });
          setMembers(data);
        }
      } catch (error) {
        console.error(error);
        toast.error(t('common:toasts.generalError'));
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container order-successful-screen">
      <img src={checkmarkImg} alt="" />
      <h1>{t('common:checkout.orderSuccess.success')}</h1>
      {loading ? <Loader inline active size="tiny" /> : <p className="text last-text">{description()}</p>}
      <Button actiontype="primary" onClick={handleRedirect} disabled={loading} loading={loading}>
        {buttonText()}
      </Button>
    </section>
  );
};

export default OrderSuccessful;
