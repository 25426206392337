import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import CallToActionButton from 'components/common/CallToAction/CallToAction';
import { ButtonContainer, Container, Name, Price, PriceContainer, Words } from './ProductCard.styles';

import { sendUserInteraction } from 'utils/tagManager.utils';
import { localisePrice } from 'utils/string.utils';

const FreeProductCard = ({ currencyData, yearly }) => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const { freePlanUnitLimit } = useSelector((state) => state.classifiersStore);

  const handlePackageClick = () => {
    sendUserInteraction('free catapult package click');
    const searchQuery = `?plan=free`;
    // if user is logged in redirect directly to checkout
    if (!!user) {
      history.push(`/checkout${searchQuery}`);
    } else {
      // if user is not logged in redirect to sign up / login screen for catapult
      history.push(`/catapult-landing${searchQuery}`);
    }
  };

  return (
    <Container as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout exit={{ opacity: 0 }}>
      <Name>{t('common:catapult.packages.freePlan')}</Name>
      <PriceContainer>
        <Price $marginTop={yearly ? '52px' : 0}>
          {localisePrice({ currencyData, language: i18n.language, number: 0 })}
        </Price>
      </PriceContainer>
      <Words $marginTop={yearly ? '28px' : 0}>
        <li>{`${freePlanUnitLimit} ${t('common:catapult.packages.freeUnit')}`}</li>
        <li>{`${t('common:catapult.packages.freeLength')}`}</li>
      </Words>

      <ButtonContainer>
        <CallToActionButton
          color="blue"
          onClick={handlePackageClick}
          text={t('common:catapult.packages.getStarted')}
          data-cy="start-trial-btn"
        />
      </ButtonContainer>
    </Container>
  );
};

export default FreeProductCard;
