import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../common/Button/Button';
import { Checkbox } from '../../../common/Checkbox/Checkbox';
import DatepickerRange from '../../../common/Datepicker/DatepickerRange';

import {
  Container,
  DatepickerContainer,
  StatusesContainer,
  StyledInput,
} from './PaidSubscriptionsFilter.styles';

import { INITIAL_REQUEST_PARAMS, SUBSCRIPTION_STATUSES } from './PaidSubscriptions.constants';
import { sendUserInteraction } from 'utils/tagManager.utils';

const PaidSubscriptionsFilter = ({ requestParams = INITIAL_REQUEST_PARAMS, updateRequestParams }) => {
  const { t } = useTranslation();

  const handleSearchParamChange = ({ propertyName, value }) => {
    sendUserInteraction(`paid subscriptions filter param change: ${propertyName}: ${value}`);
    const newRequestParams = {
      ...requestParams,
      [propertyName]: value,
    };
    updateRequestParams(newRequestParams);
  };

  const handleSearchChange = (e, { value }) => {
    e.preventDefault();
    handleSearchParamChange({ propertyName: 'search', value: !!value ? value.trim() : null });
  };

  const handleStatusClick = (statusId) => {
    const isSelected = isStatusSelected(statusId);
    if (isSelected) {
      sendUserInteraction(`paid subscriptions status deselect click ${statusId}`);
      const newStatus = requestParams.status.filter((oldStatusId) => oldStatusId !== statusId);
      const newRequestParams = {
        ...requestParams,
        status: newStatus,
      };
      updateRequestParams(newRequestParams);
    } else {
      sendUserInteraction(`paid subscriptions status select click ${statusId}`);
      const newStatus = [...requestParams.status, statusId];
      const newRequestParams = {
        ...requestParams,
        status: newStatus,
      };
      updateRequestParams(newRequestParams);
    }
  };

  const isStatusSelected = (statusId) => {
    return requestParams.status.includes(statusId);
  };

  const resetParams = () => {
    sendUserInteraction('paid subscriptions filter reset click');
    updateRequestParams({ ...INITIAL_REQUEST_PARAMS });
  };

  const handlePeriodChange = (newDates) => {
    sendUserInteraction('paid subscriptions date filter change');
    const [current_period_end_from, current_period_end_to] = newDates;
    const newParams = { ...requestParams, current_period_end_from, current_period_end_to };
    updateRequestParams(newParams);
  };

  return (
    <Container>
      <p>{`${t('common:catapult.admin.paidSubscriptionsTable.searchField')}:`}</p>
      <StyledInput onChange={handleSearchChange} value={requestParams.search || ''} />
      <p>{`${t('common:catapult.admin.paidSubscriptionsTable.subscriptionStatus')}:`}</p>
      <StatusesContainer>
        {SUBSCRIPTION_STATUSES.map((statusObject) => {
          const { status, translationKey } = statusObject;
          return (
            <Checkbox
              checked={isStatusSelected(status)}
              key={status}
              label={t(translationKey)}
              onClick={() => handleStatusClick(status)}
              value={status}
            />
          );
        })}
      </StatusesContainer>
      <p>{`${t('common:catapult.admin.paidSubscriptionsTable.periodEnd')}:`}</p>
      <DatepickerContainer>
        <DatepickerRange
          startDate={requestParams.current_period_end_from}
          endDate={requestParams.current_period_end_to}
          placeholder="All"
          onChange={handlePeriodChange}
        />
      </DatepickerContainer>
      <Button onClick={resetParams}>{t('common:catapult.admin.paidSubscriptionsTable.resetFilter')}</Button>
    </Container>
  );
};

export default PaidSubscriptionsFilter;
