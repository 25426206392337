import { Input } from 'semantic-ui-react';

const { default: styled } = require('styled-components');

export const CreateCompanyWrapper = styled.div`
  display: grid;
  justify-items: stretch;
  h1.title {
    color: #407fce;
    text-align: center;
    margin-bottom: 5px;
  }
  .sub-title {
    text-align: center;
    font-weight: normal;
    margin-bottom: 30px;
  }
`;

export const StyledHeader = styled.div`
  text-align: center;
  font-size: 2em;
`;

export const CreateStepsWrapper = styled.div`
  .step-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #5279cc;
    margin-bottom: 5px;
    text-align: center;
  }

  .step-description {
    text-align: center;
    margin-bottom: 10px;
  }

  .company-name-form {
    .email-inputs {
      display: flex;
      justify-content: stretch;
      margin-bottom: 5px;
    }
  }
  .emails-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;
