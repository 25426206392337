import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Popup } from 'semantic-ui-react';
import Table from '../../common/Table/Table';
import { Icon } from '../../common/Icon/Icon';
import { getDownloadInvoice } from '../../../services/project';
import { localisePrice } from '../../../utils/string.utils';
import { formatAPIDateString } from 'utils/date.utils';

import Button from '../../common/Button/Button';

import { currencyByIdSelector } from '../../../store/selectors';

const InvoiceTableRow = ({ invoice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const getCurrencyById = useSelector(currencyByIdSelector);

  const currency = getCurrencyById(invoice.currency_id);
  // take currency conversion rate directly from the invoice since it might have been updated afterwards
  const { t } = useTranslation();

  const downloadInvoice = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // get file blob
      const response = await getDownloadInvoice(invoice.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1];
      const decodedFileName = decodeURIComponent(fileName).replace(/"/g, '').replace(/\+/g, ' ');

      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, decodedFileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Table.Row>
      <Table.Cell>
        {invoice.project_id ? (
          <Popup
            trigger={<Icon name="suitcase" color="#2185d0" />}
            content={t('common:team.invoices.project')}
          />
        ) : (
          <Popup
            trigger={<Icon name="credit-card" color="#2185d0" />}
            content={t('common:team.invoices.sub')}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {invoice.project_id ? (
          <Link to={`/admin/project/${invoice.project_id}`}>{invoice.project_id}</Link>
        ) : (
          <Link
            to={`/admin/catapult/subscriptions/${invoice.subscription.billing_detail_id}/${invoice.subscription.id}`}
          >
            {invoice.subscription.id}
          </Link>
        )}
      </Table.Cell>
      <Table.Cell>{invoice.bill_name}</Table.Cell>
      <Table.Cell>{invoice.project_id ? invoice.company_name : invoice.buyer_name}</Table.Cell>
      <Table.Cell>
        {invoice.issued_on === null
          ? t('common:admin.invoices.notIssued')
          : formatAPIDateString({
              dateString: invoice.issued_on,
              dateStringFormat: 'yyyy-MM-dd',
              showTime: false,
            })}
      </Table.Cell>
      <Table.Cell>
        {formatAPIDateString({
          dateString: invoice.payment_due,
          dateStringFormat: 'yyyy-MM-dd',
          showTime: false,
        })}
      </Table.Cell>
      <Table.Cell>
        {invoice?.net_price
          ? localisePrice({ currencyData: { code: currency.code }, number: invoice.net_price })
          : invoice?.net_price}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {invoice?.net_price
          ? localisePrice({
              currencyData: { code: currency.code },
              number: invoice.vat_rate ? invoice.price_with_vat : invoice.net_price,
            })
          : invoice?.vat_rate
          ? invoice?.price_with_vat
          : invoice?.net_price}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {invoice.project_paid || invoice.subscription_id ? (
          <Icon name="check" color="green" />
        ) : (
          <Icon name="times" color="red" />
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          actiontype="primary"
          disabled={invoice.issued_on === null}
          loading={isLoading}
          icon="import"
          onClick={downloadInvoice}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const AdminInvoicesTable = ({ invoices, downloadInvoices = () => {} }) => {
  const { t } = useTranslation();
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  const batchDownloadInvoices = async (e) => {
    try {
      setLoadingInvoices(true);
      await downloadInvoices(e);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingInvoices(false);
    }
  };

  const rows = invoices.map((invoice) => <InvoiceTableRow key={invoice.id} invoice={invoice} />);
  return (
    <Table className="admin-invoices-table" width="100%">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('common:team.invoices.labels.type')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.projectId')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.companyName')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.issued')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.paymentDue')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.netPrice')}</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            {t('common:team.invoices.labels.grossPrice')}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">{t('common:team.invoices.labels.paid')}</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
            <Popup
              trigger={
                <Button
                  color="blue"
                  disabled={invoices.length === 0}
                  loading={loadingInvoices}
                  icon="import"
                  onClick={batchDownloadInvoices}
                />
              }
              content={t('common:team.invoices.batchDownload')}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={10} textAlign="center" style={{ padding: '15px' }}>
              {t('common:admin.invoices.noInvoices')}
            </Table.Cell>
          </Table.Row>
        ) : (
          rows
        )}
      </Table.Body>
    </Table>
  );
};

export default AdminInvoicesTable;
