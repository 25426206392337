import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import Card from 'components/common/Card/Card';
import {
  CardButtonContainer,
  CardContentContainer,
  CardHeading,
  CardImage,
  CardText,
} from './CatapultToggle.styles';

import taiaImgSrc from './assets/taia_project.png';

const TaiaCard = ({ isUpdatingCatapult = false, isUpdatingTaia = false, selectTaia }) => {
  const { t } = useTranslation();

  return (
    <Card className="taia-card" color="yellow" width="330px">
      <CardContentContainer>
        <CardImage alt="taia-project" src={taiaImgSrc} />
        <CardHeading>{t('common:projects.analyse.taia')}</CardHeading>
        <CardText>{t('common:projects.analyse.taiaText')}</CardText>

        <CardButtonContainer>
          <Button
            id="catapult-toggle-select-taia"
            actiontype="primary"
            loading={isUpdatingTaia}
            disabled={isUpdatingCatapult}
            onClick={selectTaia}
          >
            {t('common:projects.analyse.selectTaia')}
          </Button>
        </CardButtonContainer>
      </CardContentContainer>
    </Card>
  );
};

export default TaiaCard;
