import { objectWithDefault } from 'utils/base.utils';

// ia8n translation keys for project status_id
export const STATUS = {
  1: ['common:projects.projectStatus.created', 'Created'],
  2: ['common:projects.projectStatus.draft', 'Draft'],
  3: ['common:projects.projectStatus.open', 'Open'],
  4: ['common:projects.projectStatus.inProgress', 'In Progress'],
  5: ['common:projects.projectStatus.review', 'Review'],
  6: ['common:projects.projectStatus.finished', 'Finished'],
  10: ['common:projects.projectStatus.selfServiceInProgress', 'Self-service - In progress'],
  11: ['common:projects.projectStatus.selfServiceFinished', 'Self-service - Finished'],
  20: ['common:projects.projectStatus.archived', 'Archived'],
  21: ['common:projects.projectStatus.assignedForReview', 'Assigned for review'],
  22: ['common:projects.projectStatus.waiting', 'Waiting for PM'],
  30: ['common:projects.projectStatus.failed', 'Failed'],
  51: ['common:projects.projectStatus.revisionAssigned', 'Revision Assigned'],
  52: ['common:projects.projectStatus.revisionAccepted', 'Revision Accepted'],
  53: ['common:projects.projectStatus.revisionFinished', 'Revision Finished'],
};

// [background color, font color, progress color]
export const STATUS_COLORS = {
  1: ['#ededed', '#666666', '#666666'],
  2: ['#c4c4c4', '#ffffff', '#c4c4c4'],
  3: ['#c8e7ef', '#1caac5', '#1caac5'],
  4: ['#ffebbf', '#cc8d00', '#cc8d00'],
  5: ['#dff7e5', '#32bf73', '#32bf73'],
  6: ['#32bf73', '#ffffff', '#32bf73'],
  10: ['#1caac5', '#ffffff', '#1caac5'],
  11: ['#32bf73', '#ffffff', '#32bf73'],
  20: ['#666666', '#ffffff', '#666666'],
  21: ['#ec5a58', '#ffffff', '#ec5a58'],
  22: ['#ec5a58', '#ffffff', '#ec5a58'],
  30: ['#ec5a58', '#ffffff', '#ec5a58'],
  51: ['#fad5d5', '#ec5a58', '#ec5a58'],
  52: ['#ffc7dc', '#d53974', '#d53974'],
  53: ['#d53974', '#ffffff', '#d53974'],
};

// i18n translations key different job status_id
export const JOB_STATUS = {
  // translation jobs
  1: ['common:projects.projectStatus.created', 'Created'],
  3: ['', 'Ordered'],
  // self-service jobs
  10: ['common:projects.jobStatus.selfServiceInProgress', 'Self-service - In progress'],
  11: ['common:projects.jobStatus.selfServiceFinished', 'Self-service - Finished'],
};

// 4 - assigned, 5 - accepted, 6 - finished
const job_revisions = {
  4: ['common:projects.jobStatus.revision.assigned'],
  5: ['common:projects.jobStatus.revision.accepted'],
  6: ['common:projects.jobStatus.revision.finished'],
};

export const JOB_REVISION_STATUS = objectWithDefault(job_revisions, [
  'common:projects.jobStatus.revision.notAssigned',
]);

// 4 - assigned, 5 - accepted, 6 - finished
const job_translations = {
  4: ['common:projects.jobStatus.translation.assigned'],
  5: ['common:projects.jobStatus.translation.accepted'],
  6: ['common:projects.jobStatus.translation.finished'],
};

export const JOB_TRANSLATION_STATUS = objectWithDefault(job_translations, [
  'common:projects.jobStatus.translation.notAssigned',
]);

const job_statuses = {
  // [background color, font color]
  4: ['rgba(39, 255, 171, 0.35)', '#2C2C2C'],
  5: ['#d53974', '#ffffff'],
  51: 'blue',
  52: 'pink',
  53: 'green',
  // [background color, font color]
  6: ['#21d891', '#ffffff'],
  10: '#2384f2',
  11: '#2eb083',
};

export const JOB_STATUS_COLORS = objectWithDefault(job_statuses, ['#7f7f7f', '#ffffff']);
