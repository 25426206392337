import React from 'react';

// Styles
import './style.scss';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
const CircularProgress = ({ progress = 75 }) => {
  const rotLeft = clamp(Math.round(180 * ((100 - progress) / 50)), 0, 180);
  const rotRight = clamp(Math.round(180 * ((100 - progress - 50) / 50)), 0, 180);

  return (
    <div
      className="circular-progress"
      style={{
        '--circle-right-rotate': `${rotLeft}deg`,
        '--circle-left-rotate': `${rotRight}deg`,
      }}
    >
      <div className="inner"></div>
      <div className="circle">
        <div className="bar left">
          <div className="progress"></div>
        </div>
        <div className="bar right">
          <div className="progress"></div>
        </div>
      </div>
    </div>
  );
};

export default CircularProgress;
