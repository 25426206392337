import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useOuterClick } from '../../../hooks/useOuterClick';
import { getPosition } from './Popup.utils';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { theme } from 'style/theme';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import { Icon } from '../Icon/Icon';
import { setCssVariable } from 'utils/base.utils';
import { debounce, throttle } from 'lodash';
import { classnames } from 'utils/base.utils';

const ContentContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.sidebar + 1};
  left: ${({ pos }) => pos.x}px;
  top: ${({ pos }) => pos.y - 4}px;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.2s ease-in;

  &:hover {
    transition-timing-function: ease-out;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    &.mobile-fullscreen {
      height: calc((var(--vh) * 100) - (${theme.paddings.mainContainer.mobile.x} * 2));
      width: calc(100vw - (${theme.paddings.mainContainer.mobile.x} * 2));
      margin: ${theme.paddings.mainContainer.mobile.x};
      z-index: ${theme.zIndex.bgOverlay + 1};
    }
  `}
`;

const Content = styled.div`
  position: relative;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 4px 18px -4px rgba(0, 0, 0, 0.25);
  font-family: 'DM Sans', sans-serif;

  &::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    background-color: ${({ theme }) => theme.colors.white};
    transform: rotate(45deg);
  }

  &.top-center::after {
    top: auto;
    right: auto;
    bottom: -0.30714286em;
    left: 50%;
    margin-left: -0.30714286em;
  }

  &.bottom-center::after {
    top: -0.30714286em;
    right: auto;
    left: 50%;
    margin-left: -0.30714286em;
  }

  &.left-center::after {
    transform: rotate(45deg) translateY(-50%);
    top: 50%;
    right: -2px;
    margin-top: -1px;
  }

  &.right-center::after {
    transform: rotate(45deg) translateY(-50%);
    top: 50%;
    left: -9px;
    margin-top: -1px;
  }

  &.left-bottom::after {
    transform: rotate(45deg) translate(50%, 50%);
    left: -6px;
    margin-top: -1px;
    bottom: ${({ triggerHeight }) => triggerHeight / 2}px;
  }

  &.dark {
    background-color: #555555;
    color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
    &::after {
      background-color: #555555;
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: ${theme.paddings.mainContainer.mobile.x};

    &::after {
      display: none;
    }

    &.mobile-fullscreen {
      height: 100%;
      overflow-y: auto;
    }
  `}
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .close-btn {
    background-color: transparent;
    border: none;

    .icon {
      margin-right: 0;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export const Popup = ({
  trigger,
  content,
  children,
  position = 'top-center',
  dark = false,
  delay = 0, // how long till popup dissapears after cursor is out of trigger and content (in ms)
  on = ['hover'],
  open,
  fullscreenMobile,
  className,
  style,
  hideOnScroll = false,
  onClose,
}) => {
  // State
  const [visible, setVisible] = useState(false);

  // Refs
  const pos = useRef({ x: 0, y: 0 });
  const timeoutRef = useRef(null);
  const scrollHandlerRef = useRef(null);
  const visibleRef = useRef(visible);

  // dom refs
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const triggerRef = useRef(null);
  const scrollableParentVertical = useRef(null);
  const scrollableParentHorizontal = useRef(null);

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);
  const usingVisibleInnerState = open === undefined;

  // Methods
  const handleTriggerMouseEnter = (e) => {
    clearTimeout(timeoutRef.current);

    // Don't run on mobile
    if (isMobile) return;

    if (on.includes('hover') && usingVisibleInnerState) {
      setVisible(true);
    }

    triggerRef.current = e.target;

    pos.current = getPosition(e, containerRef, position);
  };

  const handleTriggerClick = (e) => {
    clearTimeout(timeoutRef.current);

    if (on.includes('click') || (fullscreenMobile && usingVisibleInnerState)) {
      setVisible(true);
    }

    pos.current = isMobile && fullscreenMobile ? { x: 0, y: 0 } : getPosition(e, containerRef, position);

    if (trigger.props.onClick) {
      trigger.props.onClick(e);
    }
  };

  const handleTriggerMouseLeave = (e) => {
    const { relatedTarget } = e;

    if (relatedTarget !== window && relatedTarget.isEqualNode(contentRef.current)) {
      return;
    }
    if (!on.includes('hover')) return;

    timeoutRef.current = setTimeout(() => {
      if (usingVisibleInnerState) setVisible(false);
    }, delay);

    if (trigger.props.onMouseLeave) {
      trigger.props.onMouseLeave(e);
    }
  };

  const handleContentMouseEnter = (e) => {
    clearTimeout(timeoutRef.current);

    if (trigger.props.onMouseEnter) {
      trigger.props.onMouseEnter(e);
    }
  };

  const handleContentMouseLeave = (e) => {
    const { relatedTarget } = e;
    if (relatedTarget !== window && relatedTarget.isEqualNode(triggerRef.current)) {
      return;
    }
    if (!on.includes('hover')) return;

    timeoutRef.current = setTimeout(() => {
      if (usingVisibleInnerState) setVisible(false);
    }, delay);
  };

  /**
   * Find parent that is scrollable, so the popup closes on their scroll events
   */
  const findScrollableParent = () => {
    // If no trigger ref
    if (!triggerRef.current) return;

    // Check if scrollable parent vertical already found ?
    if (scrollableParentVertical.current) {
      // Remove scroll listener
      scrollableParentVertical.current.removeEventListener('scroll', scrollHandlerRef.current);
    }

    // Check if scrollable parent vertical already found ?
    if (scrollableParentHorizontal.current) {
      // Remove scroll listener
      scrollableParentHorizontal.current.removeEventListener('scroll', scrollHandlerRef.current);
    }

    scrollableParentVertical.current = null;
    let currElement = triggerRef.current.parentNode;

    while (true) {
      // go up till <html>
      // Check if current element is top of document
      if (currElement === document) break;

      // Check parent node, if it is scrollable
      if (currElement.scrollHeight > currElement.offsetHeight) {
        // Test if actually scrollable
        currElement.scrollTop = 1;

        if (currElement.scrollTop === 1) {
          scrollableParentVertical.current = currElement;
          break;
        }
      }

      currElement = currElement.parentNode;
    }

    // If found add scroll handler function
    if (scrollableParentVertical.current)
      scrollableParentVertical.current.addEventListener('scroll', scrollHandlerRef.current);

    // Do same for horizontal scroll parent
    scrollableParentHorizontal.current = null;
    currElement = triggerRef.current.parentNode;

    while (true) {
      // go up till <html>
      // Check if current element is top of document
      if (currElement === document) break;

      // Check parent node, if it is scrollable
      if (currElement.scrollWidth > currElement.offsetWidth) {
        currElement.scrollLeft = 1;

        if (currElement.scrollLeft === 1) {
          scrollableParentHorizontal.current = currElement;
          break;
        }
      }

      currElement = currElement.parentNode;
    }

    // If found add scroll handler function
    if (scrollableParentHorizontal.current)
      scrollableParentHorizontal.current.addEventListener('scroll', scrollHandlerRef.current);
  };

  // Hooks
  useOuterClick((e) => {
    if (!on.includes('click') && usingVisibleInnerState) return;
    if (triggerRef.current && triggerRef.current.contains(e.target)) return;
    setVisible(false);
  }, containerRef);

  /**
   * Runs on visible and isMobile updates
   */
  useEffect(() => {
    visibleRef.current = visible;

    // Fire onClose callback
    if (!visible && onClose) {
      onClose();
    }

    if (!isMobile) return;

    setCssVariable('--zendesk-chat-z-index', visible ? '-1' : theme.zIndex.zendeskChatWidget);
  }, [visible, isMobile, onClose]);

  // Run only on mount
  useEffect(() => {
    if (!hideOnScroll) return;

    // Store scroll handler in ref so it can be later removed on unmount
    scrollHandlerRef.current = throttle(() => {
      // If popup is not visible, don't do anything
      if (!visibleRef.current) return;
      setVisible(false);
    }, 100);

    /**
     * Find scrollable parent on mount
     * also find scrollable parent on window resize, it might change on that event
     */
    // Find scrollable parent
    findScrollableParent();

    const refFindScrollableParent = debounce(findScrollableParent, 200);

    window.addEventListener('resize', refFindScrollableParent);

    return () => {
      window.removeEventListener('resize', refFindScrollableParent);

      if (!scrollHandlerRef.current) return;

      // Remove scroll event listeners on unmount
      if (scrollableParentVertical.current)
        scrollableParentVertical.current.removeEventListener('scroll', scrollHandlerRef.current);
      if (scrollableParentHorizontal.current)
        scrollableParentHorizontal.current.removeEventListener('scroll', scrollHandlerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Run on open prop change
  useEffect(() => {
    // Not using internal state for visible
    if (open !== undefined) setVisible(!!open);
  }, [open]);

  // Render variables
  const getTrigger = () => {
    return React.cloneElement(trigger, {
      onClick: handleTriggerClick,
      onMouseLeave: handleTriggerMouseLeave,
      onMouseEnter: handleTriggerMouseEnter,
      ref: triggerRef,
    });
  };

  const contentClassnames = classnames(position, { dark, 'mobile-fullscreen': fullscreenMobile });
  const contentContainerClasses = classnames(className, { 'mobile-fullscreen': fullscreenMobile });

  const getContentProps = () => {
    let triggerHeight = 0;

    if (triggerRef.current?.getBoundingClientRect) {
      triggerHeight = triggerRef.current.getBoundingClientRect().height;
    }

    return {
      ref: contentRef,
      className: contentClassnames,
      triggerHeight,
      onMouseLeave: handleContentMouseLeave,
      onMouseEnter: handleContentMouseEnter,
    };
  };

  const props = {
    visible,
    pos: pos.current,
    ref: containerRef,
    className: contentContainerClasses,
    style,
  };

  return (
    <>
      {getTrigger()}
      {ReactDOM.createPortal(
        <ContentContainer {...props}>
          <Content {...getContentProps()}>
            {fullscreenMobile && isMobile && (
              <MobileHeader>
                <button
                  className="close-btn"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  <Icon name="times" size="big" />
                </button>
              </MobileHeader>
            )}
            {children}
            {content && content}
          </Content>
        </ContentContainer>,
        document.getElementById('root'),
      )}
      {fullscreenMobile && isMobile && <FullscreenOverlay open={visible || open} />}
    </>
  );
};

Popup.prototypes = {
  position: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
    'right-center',
    'left-center',
    'left-bottom',
  ]),
  content: PropTypes.node,
  trigger: PropTypes.node,
  open: PropTypes.bool,
  dark: PropTypes.bool,
  delay: PropTypes.number,
  on: PropTypes.arrayOf(PropTypes.oneOf(['click', 'hover'])),
};
