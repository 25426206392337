import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AnimatePresence, motion } from 'framer-motion';
import { buildFileUploadError, renameDuplicateFiles } from '../FileUpload.utils';
import { classnames } from 'utils/base.utils';

// Components
import { Icon } from '../../Icon/Icon';
import FileUploadPreview from '../FileUploadPreview/FileUploadPreview';
import Message from '../../Message/Message';

// Style
import './style.scss';

const FileUploadMultiple = ({
  options,
  onChange = () => {},
  selectedFiles,
  showPreviews = true,
  name = 'multiple-files-upload',
}) => {
  const [files, setFiles] = useState(selectedFiles ? selectedFiles : []);
  const [rejectedError, setRejectedError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  // Methods
  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChange(newFiles);
    setFiles(newFiles);
  };

  const handleFileRename = (file, index) => {
    setFiles((c) => {
      const newFiles = [...c];
      newFiles[index] = file;

      return newFiles;
    });
  };

  const onDragEnter = () => setIsDragging(true);
  const onDragLeave = () => setIsDragging(false);

  // Dropzone hook
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    ...options,
    onDropAccepted: (f) => {
      const newFiles = renameDuplicateFiles([...files, ...f]);
      setFiles(newFiles);

      // Event and field value
      onChange(null, { name, value: newFiles });
    },
    onDragEnter,
    onDragLeave,
  });

  // Effects
  useEffect(() => {
    setRejectedError(fileRejections.length > 0 ? buildFileUploadError(fileRejections) : null);
  }, [fileRejections]);

  useEffect(() => {
    if (Array.isArray(selectedFiles)) {
      setFiles([...selectedFiles]);
    }
  }, [selectedFiles]);

  // Render variables
  const presenceProps = {
    initial: {
      height: 0,
      opacity: 0,
    },
    animate: {
      height: 'auto',
      opacity: 1,
    },
    exit: {
      height: 0,
      opacity: 0,
    },
    transition: {
      duration: 0.3,
    },
  };

  const rootProps = {
    ...getRootProps({
      className: classnames('upload', { dragging: isDragging }),
    }),
  };

  const showFilePreviews = showPreviews && files.length > 0;

  return (
    <div className="multiple-file-upload">
      <div {...rootProps}>
        <input {...getInputProps()} />
        <div className="dropzone">
          <Icon name="upload" size="big" />
          <p>
            Drag and drop your files into this field to upload or <span>browse</span>
          </p>
        </div>
      </div>
      <div className="msg-wrapper current-files">
        {showFilePreviews &&
          files.map((file, idx) => (
            <FileUploadPreview
              key={`file-display-${file.name}`}
              file={file}
              files={files}
              onRemove={() => removeFile(idx)}
              onChange={(file) => handleFileRename(file, idx)}
            />
          ))}

        <AnimatePresence exitBeforeEnter>
          {!!rejectedError && (
            <Message
              as={motion.div}
              className="error-message"
              text={rejectedError}
              type="error"
              {...presenceProps}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default FileUploadMultiple;
