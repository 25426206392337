const { useRef, useEffect, useState } = require('react');

export const useValidation = (value, validFn) => {
  // Valid should be initial null (not valid nor invalid)
  const [valid, setValid] = useState(null);
  // Helper to not call valdiation on initial value
  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    } else {
      setValid(validFn(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return valid;
};

export const allValid = (validators) =>
  validators.keys().reduce((acc, validator) => acc && validators[validator], true);
