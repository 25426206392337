import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  column-gap: 4px;
  width: min-content;
  border-radius: 3px;
  overflow: hidden;
`;

const PageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;

  width: 52px;
  height: 52px;
  border-radius: 3px;
  border: 2px solid transparent;
  background-color: #e9e9e9;
  color: ${({ theme }) => theme.colors.greyDark};
  transition: background-color 0.25s ease-out, border 0.25s ease-out, color 0.25s ease-out;

  font-size: 18px;
  font-family: 'DM Sans', sans-serif;

  &:hover {
    background-color: #e3e3e3;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const PageNumber = styled(PageButton)`
  &.active {
    color: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.blue};
    background-color: #f1f9fb;
  }
`;

export const Arrow = styled(PageButton)`
  &.disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.grey};
  }

  &:first-child {
    margin-right: 12px;
  }
  &:last-child {
    margin-left: 12px;
  }
`;
