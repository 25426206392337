import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Message from '../common/Message/Message';
import { Input } from '../common/Input/Input';
import { StyledButton } from '../common.styles';

import { getInitialUserData } from '../../utils/initialLoad';
import { postLogin } from '../../services/auth';
import { setIsAuthenticated, setIsAuthenticating, setUser } from '../../store/userSlice';
import { StyledForm } from './SignIn.styles';

import { isValidEmail } from 'utils/string.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useRecaptchaV3 } from 'hooks/useRecaptcha';

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitError, setSubmitError] = useState(null);
  const { generateToken } = useRecaptchaV3();

  const isAuthenticating = useSelector((state) => state.userStore.isAuthenticating);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const canSubmit = () => {
    return isValidEmail(email) && password.trim() !== '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    sendUserInteraction('clicked Log in');

    setSubmitError(null);

    try {
      dispatch(setIsAuthenticating({ isAuthenticating: true }));
      const captcha = await generateToken();
      // first, post login to get the auth token
      await postLogin({ email, password, captcha });
      // get user data
      await getInitialUserData();
    } catch (e) {
      dispatch(setUser({ user: null }));
      dispatch(setIsAuthenticated({ isAuthenticated: false }));
      dispatch(setIsAuthenticating({ isAuthenticating: false }));
      console.error(e);

      if (!e?.response) {
        setSubmitError(t('common:errors.noConnection'));
      } else if (e.response.status === 401) {
        setSubmitError(t('common:signIn.errors.invalidCredentials'));
      } else if (e.response.status === 422) {
        setSubmitError(t('common:signIn.errors.recaptchaInvalid'));
      } else {
        throw e;
      }
    }
  };

  return (
    <>
      {submitError && <Message text={submitError} type="error" />}
      <StyledForm onSubmit={handleSubmit}>
        <Input
          name="email"
          type="email"
          label={t('common:signIn.labels.email')}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          name="password"
          type="password"
          label={t('common:signIn.labels.password')}
          onChange={(e) => setPassword(e.target.value)}
        />
        <StyledButton
          disabled={!canSubmit() || isAuthenticating}
          loading={isAuthenticating}
          big
          type="submit"
          actiontype="submit"
          marginBottom="8px"
        >
          {t('common:signIn.logIn')}
        </StyledButton>
      </StyledForm>
    </>
  );
};

export default SignInForm;
