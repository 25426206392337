import styled from 'styled-components';

export const FileUploadWrapper = styled.div`
  width: 100%;
  font-size: 1em;

  ${({ theme }) => theme.inputStyle}

  &.error {
    border-color: ${({ theme }) => theme.colors.red};
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.blue};
  }

  .file-input {
    min-height: 130px;
    position: relative;
    &:focus-within {
      .background-icon {
        display: none;
      }

      .text-file-notice {
        display: block;
      }
    }

    .background-icon {
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: absolute;
      display: grid;
      place-items: center;
      color: ${({ theme }) => theme.colors.grey};
    }

    .text-file-notice {
      display: none;
      position: absolute;
      bottom: 7px;
      right: 10px;
      color: ${({ theme }) => theme.colors.grey};

      font-size: 13px;
    }

    .upload-textarea {
      position: relative;
      width: 100%;

      .placeholder {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        user-select: none;

        color: ${({ theme }) => theme.colors.grey};
        cursor: text;

        &.hidden {
          display: none;
        }

        .upload-link {
          color: ${({ theme }) => theme.colors.blue};
          cursor: pointer;

          &:hover {
            color: ${({ theme }) => theme.colors.blueLight};
          }
        }
      }

      &:focus-within {
        .placeholder {
          display: none;
        }
      }
      textarea {
        border: none;
        outline: none;
        padding: 0px !important;
        background-color: rgba(255, 255, 255, 0.2);
        min-height: 130px;
        height: 130px;
        resize: vertical;
        width: 100%;
      }
    }
  }

  .files-section {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const FileLabelWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue};

  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;

  border-radius: 4px;

  display: flex;
  align-items: center;

  .file-type {
    font-weight: bold;
  }

  .file-name {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .remove-icon {
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;
