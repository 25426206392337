import styled from 'styled-components';

export const CheckoutWrapper = styled.div`
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 250px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin-bottom: 2rem;
  `}
`;
