import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SubHeading } from './CatapultProjectOrdered.styles';

const Container = styled.div`
  margin-top: 32px;
`;

const Paragraph = styled.p`
  margin-top: 0.5em;
  max-width: 60ch;
`;

const Comment = ({ comment }) => {
  const { t } = useTranslation();

  return !!comment ? (
    <Container>
      <SubHeading>{t('common:projects.analyse.comments')}</SubHeading>
      <Paragraph>{comment}</Paragraph>
    </Container>
  ) : null;
};

export default Comment;
