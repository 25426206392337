import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TaiaStyledButton } from './Button.styles';
import { Loader } from 'semantic-ui-react';
import { Icon } from '../Icon/Icon';

const Button = ({
  className,
  circular,
  compact,
  color,
  children,
  disabled,
  fluid,
  floated,
  icon,
  inverted,
  loading = false,
  labelPosition,
  onClick,
  style,
  actiontype,
  big,
  basic,
  flex,
  basicLight,
  ...rest
}) => {
  const [renderClassName, invertLoader] = useMemo(() => {
    const classes = ['button'];
    let invertLoader = false;
    if (className) classes.push(className);

    if (fluid) classes.push('fluid');
    if (floated) classes.push(`floated-${floated}`);
    if (circular) classes.push('circular');
    if (disabled) classes.push('disabled');
    if (compact) classes.push('compact');
    if (big) classes.push('big');
    if (basic) classes.push('basic');
    if (color) classes.push(color);
    if (inverted) classes.push('outlined');
    if (basic) classes.push('basic');
    if (basicLight) classes.push('basic-light');
    if (flex) classes.push('flex');

    if (icon) classes.push('icon');
    if (labelPosition === 'left') classes.push('labeled left');
    if (labelPosition === 'right') classes.push('labeled right');

    if (['primary', 'submit', 'create'].includes(actiontype)) {
      classes.push('blue');
      classes.push('basic');
      invertLoader = true;
    }

    if (actiontype === 'cancel' || actiontype === 'secondary') {
      classes.push('grey');
      classes.push('outlined');
    }
    if (actiontype === 'delete') {
      classes.push('red');
      classes.push('outlined');
    }
    if (actiontype === 'delete-confirm') {
      classes.push('red');
      classes.push('basic');
      invertLoader = true;
    }

    if (actiontype === 'success') {
      classes.push('green');
      classes.push('basic');
      invertLoader = true;
    }

    return [classes.join(' '), invertLoader];
  }, [
    className,
    fluid,
    circular,
    color,
    inverted,
    disabled,
    actiontype,
    labelPosition,
    icon,
    compact,
    floated,
    big,
    basic,
    basicLight,
    flex,
  ]);

  const props = { className: renderClassName, style, onClick, disabled, $loading: loading, ...rest };

  const getChildren = () => {
    if (typeof icon === 'boolean') return <>{children}</>;
    if (typeof icon === 'string') return <Icon name={icon} />;
    return (
      <>
        {icon && icon}
        {children}
      </>
    );
  };

  return (
    <TaiaStyledButton data-is-loading={loading} {...props}>
      {loading && <Loader active size="tiny" inverted={invertLoader} />}
      {getChildren()}
      <div className="overlay" />
    </TaiaStyledButton>
  );
};

Button.propTypes = {
  actiontype: PropTypes.oneOf([
    'primary',
    'secondary',
    'submit',
    'create',
    'delete',
    'delete-confirm',
    'cancel',
    'success',
  ]),
  big: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  circular: PropTypes.bool,
  compact: PropTypes.bool,
  color: PropTypes.oneOf(['blue', 'green', 'red', 'yellow', 'primary', 'light-grey', 'grey']),
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  floated: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
  labelPosition: PropTypes.oneOf(['left', 'right']),
  inverted: PropTypes.bool,
  loading: PropTypes.bool,
  flex: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Button;
