import { TabContainer } from 'components/UserProfile/UserProfile.styles';
import React, { useCallback } from 'react';
import CombinationsTable from 'components/UserProfile/Vendor/VendorLanguageCombinations/CombinationsTable/CombinationsTable';

/**
 * Renders language combinations tab
 */
const LanguageCombinations = ({ vendorData, setVendorData }) => {
  const handleChange = useCallback(
    (newPrices) => {
      if (JSON.stringify(newPrices) !== JSON.stringify(vendorData.prices)) {
        setVendorData({ ...vendorData, prices: newPrices });
      }
    },
    [vendorData, setVendorData],
  );

  return (
    <TabContainer className="admin-vendor-lang-combinations-tab">
      <CombinationsTable
        initialCombinations={vendorData.prices}
        vendorId={vendorData.id}
        onChange={handleChange}
      />
    </TabContainer>
  );
};

export default LanguageCombinations;
