import Button from 'components/common/Button/Button';
import { MultipleDropdown } from 'components/common/Dropdown/MultipleDropdown';
import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';
import { Flag } from 'components/common/Flag/Flag';
import { Icon } from 'components/common/Icon/Icon';
import RateInput from 'components/common/PriceInput/RateInput';
import RateField from 'components/common/RateField/RateField';
import Table from 'components/common/Table/Table';
import { VENDOR_TASK_TYPE_REVISION } from 'constants/vendors';
import { VENDOR_TASK_TYPE_PROOFREAD_ONLY } from 'constants/vendors';
import { VENDOR_TASK_TYPE_TRANSLATION } from 'constants/vendors';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getVendorData } from 'services/vendors';
import { deleteLanguagePair, patchLanguagePair, postLanguagePair } from 'services/vendors';
import { hasAdminPermissions } from 'utils/user.utils';
import LanguageLabel from './LanguageLabel/LanguageLabel';
import SubjectMatters from './SubjectMatters/SubjectMatters';
import TestStatus from './TestStatus/TestStatus';
import TruncatedHeaderCell from './TruncatedHeaderCell/TruncatedHeaderCell';
import { batchPatchLanguagePairs } from 'services/vendors';

import AdminActions from './AdminActions/AdminActions';
import './style.scss';
import { batchDeleteLanguagePair } from 'services/vendors';

// Utils
const groupCombinations = (combinations) => [
  ...combinations
    .reduce((r, o) => {
      const key = `${o.source_lang_id}-${o.target_lang_id}-${o.task_type_id}-${o.test_status}-${o.pending_rate}-${o.rate}-${o.rate_status}`;

      // Check if the lang combination with those ids already exists
      const combination = r.get(key);

      // If combination is already in the Map
      // We take the Map combination categories otherwise create a new empty array
      const categories = combination ? [...combination.category_id] : [];

      const otherPairIDs = combination ? [...combination.pair_ids] : [];

      // Store extra language pairs ids on each pair

      // Push combination category
      categories.push(o.category_id);
      otherPairIDs.push(o.id);

      // Create new combination with categories as array
      const newCombination = {
        // If Map already has a combination with those ids,
        // spread that otherwise spread new
        ...(combination ? combination : o),
        // Categories array
        category_id: [...categories],
        pair_ids: [...otherPairIDs],
      };

      // Else add new item to map
      return r.set(key, Object.assign({}, newCombination));
    }, new Map())
    .values(),
];

/**
 * Single combination row
 */
const CombinationRow = ({ combination = {}, refetchVendorData = () => {} }) => {
  const { languages, categories } = useSelector((state) => state.classifiersStore);
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isChangingRate, setIsChangingRate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newRate, setNewRate] = useState(combination.rate ?? '');

  const user = useSelector((state) => state.userStore.user);

  const {
    id,
    source_lang_id,
    target_lang_id,
    category_id,
    test_status,
    pending_rate,
    rate,
    rate_status,
    task_type_id,
  } = combination;

  const sourceLanguage = languages.find((lang) => lang.id === source_lang_id);
  const targetLanguage = languages.find((lang) => lang.id === target_lang_id);

  const handleDeleteCombination = async () => {
    try {
      setIsLoading(true);

      if (combination.pair_ids.length > 1) {
        await batchDeleteLanguagePair(combination.pair_ids);
      } else {
        await deleteLanguagePair(id);
      }

      refetchVendorData();
      toast.success(t('common:toasts.deletePair.success', 'Language pair successfully deleted'));
    } catch (e) {
      toast.error(t('common:toasts.deletePair.error', 'An error occured while deleting the language pair'));
      console.error(e);
    } finally {
      setIsLoading(false);
      setIsDeleting(false);
    }
  };

  const handleRateSubmit = async () => {
    try {
      setIsLoading(true);

      if (combination.pair_ids.length > 1) {
        await batchPatchLanguagePairs({ ids: combination.pair_ids, rate: newRate });
      } else {
        await patchLanguagePair({ id: combination.id, rate: newRate });
      }

      toast.success(t('common:toasts.submitRate.success', 'New rate submitted'));
    } catch (e) {
      toast.error(t('common:toasts.submitRate.error', 'An error occured while submitting rate'));
      console.error(e);
    } finally {
      setIsLoading(false);
      setIsChangingRate(false);
      refetchVendorData();
    }
  };

  const handleRateChange = (_, { value }) => {
    setNewRate(value);
  };

  const isUserAdmin = hasAdminPermissions(user.role);
  const isRateValid =
    newRate !== '' ? (isUserAdmin ? parseFloat(newRate) >= 0 : parseFloat(newRate) < 0) : false;

  return (
    <Table.Row className="combination-row">
      <Table.Cell className="body-test-status-cell">
        <TestStatus status={test_status} />
      </Table.Cell>
      <Table.Cell>
        <LanguageLabel lang={sourceLanguage} />
      </Table.Cell>
      <Table.Cell>
        <LanguageLabel lang={targetLanguage ? targetLanguage : sourceLanguage} />
      </Table.Cell>
      <Table.Cell>
        <SubjectMatters subjectMatters={category_id} staticCategories={categories} />
      </Table.Cell>
      <Table.Cell>{t(`tasks:${task_type_id}`)}</Table.Cell>
      <Table.Cell className="tbody-rate-cell">
        {isChangingRate ? (
          <RateInput
            name="rate"
            initialAmount={newRate}
            onPriceChange={handleRateChange}
            taskType={combination.task_type_id}
            disabled={isLoading}
          ></RateInput>
        ) : (
          <div className="status-field">
            <RateField price={{ rate, rate_status, pending_rate }} />
          </div>
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {isDeleting ? (
          <div className="table-buttons">
            <Button icon="times" color="grey" disabled={isLoading} onClick={() => setIsDeleting(false)} />
            <Button
              icon="check"
              color="red"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleDeleteCombination}
            />
          </div>
        ) : isChangingRate ? (
          <div className="table-buttons">
            <Button icon="times" color="grey" disabled={isLoading} onClick={() => setIsChangingRate(false)} />
            <Button
              icon="check"
              color="blue"
              loading={isLoading}
              disabled={!isRateValid || isLoading}
              onClick={handleRateSubmit}
            />
          </div>
        ) : (
          <div className="table-buttons">
            <Button icon="card-atm" color="blue" onClick={() => setIsChangingRate(true)} />
            {isUserAdmin ? (
              <AdminActions combination={combination} onUpdate={refetchVendorData} />
            ) : (
              <Button icon="trash-alt" color="red" onClick={() => setIsDeleting(true)} />
            )}
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const AddCombinationRow = ({ vendorId, refetchVendorData = () => {} }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const { languages, categories } = useSelector((state) => state.classifiersStore);
  const [combination, setCombination] = useState({
    source_lang_id: null,
    target_lang_id: null,
    category_id: [],
    task_type_id: null,
    rate: '',
  });

  const { t } = useTranslation();

  // Render variables
  const isProofreadTask = combination.task_type_id === VENDOR_TASK_TYPE_PROOFREAD_ONLY;

  const languageOptions = useMemo(() => {
    const langs = [...languages];

    return langs.map((lang) => ({
      key: lang.id,
      value: lang.id,
      text: lang.name,
      icon: <Flag lang={{ code: lang.code }} />,
    }));
  }, [languages]);

  const subjectMatterOptions = useMemo(() => {
    return categories.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
  }, [categories]);

  const serviceOptions = [
    {
      key: 1,
      value: VENDOR_TASK_TYPE_TRANSLATION,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.translation'),
    },
    {
      key: 2,
      value: VENDOR_TASK_TYPE_REVISION,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.revision'),
    },
    {
      key: 3,
      value: VENDOR_TASK_TYPE_PROOFREAD_ONLY,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.proofread'),
    },
  ];

  const languageOptionsWithoutSource = languageOptions.filter(
    (lang) => lang.value !== combination.source_lang_id,
  );
  const languageOptionsWithoutTarget = languageOptions.filter((lang) => {
    // Is proofread
    if (isProofreadTask) return true;

    return lang.value !== combination.target_lang_id;
  });

  const handleInputChange = (_, { value, name }) => {
    setCombination({
      ...combination,
      [name]: value,
    });
  };

  const handleAddCombination = async () => {
    try {
      setLoadingAdd(true);

      const { target_lang_id, ...comb } = combination;

      await postLanguagePair({
        ...comb,
        ...(comb.task_type_id === 6 ? {} : { target_lang_id }),
        test_status: null,
        rate_status: null,
        vendor_id: vendorId,
      });

      setCombination({
        source_lang_id: null,
        target_lang_id: null,
        category_id: [],
        task_type_id: null,
        rate: '',
      });

      toast.success(t('common:toasts.createPair.success', 'Language pair successfully created'));
    } catch (e) {
      if (e?.response?.data?.error?.message) {
        toast.error(e.response.data.error.message);
      } else {
        toast.error(t('common:toasts.createPair.error', 'An error occured while creating the language pair'));
      }

      console.error(e);
    } finally {
      // Update vendor data
      refetchVendorData();
      setLoadingAdd(false);
      setIsAdding(false);
    }
  };

  const formValid = useMemo(
    () =>
      combination.source_lang_id !== null &&
      (combination.task_type_id === VENDOR_TASK_TYPE_PROOFREAD_ONLY
        ? true
        : combination.target_lang_id !== null) &&
      combination.category_id.length !== 0 &&
      combination.category_id !== null &&
      combination.task_type_id !== null &&
      // Validate rate, parse to float and check if bigger than 0
      parseFloat(combination.rate).toFixed(3) > 0,
    [combination],
  );

  return isAdding ? (
    <Table.Row className="add-combination-row">
      <Table.Cell>
        <div className="status-field has-text-blue-primary">
          <Icon name="plus-circle" inline />
        </div>
      </Table.Cell>
      <Table.Cell>
        <SelectDropdown
          name="source_lang_id"
          value={combination.source_lang_id}
          onChange={handleInputChange}
          placeholder={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.srcLang.placeholder',
          )}
          options={languageOptionsWithoutTarget}
          selection
          search
          emptyState={t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.srcLang.empty')}
          fluid
          selectedShowIcon
          disabled={loadingAdd}
        />
      </Table.Cell>
      <Table.Cell className="target-language-cell">
        {!isProofreadTask && (
          <SelectDropdown
            name="target_lang_id"
            value={combination.target_lang_id}
            onChange={handleInputChange}
            placeholder={t(
              'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.targetLang.placeholder',
            )}
            options={languageOptionsWithoutSource}
            selection
            search
            emptyState={t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.srcLang.empty')}
            fluid
            selectedShowIcon
            disabled={loadingAdd}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <MultipleDropdown
          className="subject-matter-dropdown"
          name="category_id"
          value={combination.category_id}
          onChange={handleInputChange}
          placeholder={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.subjectMatter.placeholder',
          )}
          options={subjectMatterOptions}
          multiple
          selection
          search
          emptyState={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.subjectMatter.empty',
          )}
          fluid
          showSelectedInMenu
          optionLabel={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.subjectMatter.single',
          )}
          disabled={loadingAdd}
        />
      </Table.Cell>
      <Table.Cell>
        <SelectDropdown
          name="task_type_id"
          className="service-dropdown"
          value={combination.task_type_id}
          onChange={handleInputChange}
          placeholder={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.placeholder',
          )}
          options={serviceOptions}
          selection
          search
          emptyState={t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.empty')}
          fluid
          disabled={loadingAdd}
        />
      </Table.Cell>
      <Table.Cell className="rate-cell">
        <RateInput
          name="rate"
          initialAmount={combination.rate}
          onPriceChange={handleInputChange}
          taskType={combination.task_type_id}
          disabled={loadingAdd}
        ></RateInput>
      </Table.Cell>
      <Table.Cell textAlign="right">
        <div className="table-buttons">
          <Button icon="times" color="grey" disabled={loadingAdd} onClick={() => setIsAdding(false)} />
          <Button
            icon="check"
            color="blue"
            loading={loadingAdd}
            onClick={handleAddCombination}
            disabled={!formValid || loadingAdd}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  ) : (
    <Table.Row selectable onClick={() => setIsAdding(true)}>
      <Table.Cell colspan="7" textAlign="center">
        {t('common:userProfile.vendors.table.add', '+ Add an additional language pair')}
      </Table.Cell>
    </Table.Row>
  );
};

const CombinationsTable = ({ initialCombinations = [], vendorId, onChange = () => {} }) => {
  const [combinations, setCombinations] = useState(initialCombinations);
  const [groupedCombinations, setGroupedCombinations] = useState(groupCombinations(initialCombinations));

  const { t } = useTranslation();

  // TODO in future, refactor this,
  // get vendor data from response when doing the updates to language pairs
  const refetchVendorData = async () => {
    const { data } = await getVendorData(vendorId);
    setCombinations([...data.prices]);
  };

  /**
   * On combinations update, trigger on change cb and pass new combinations it
   */
  useEffect(() => {
    onChange(combinations);
    setGroupedCombinations(groupCombinations(combinations));
  }, [combinations, onChange]);

  // Displaying grouped combinations
  const rows = groupedCombinations.map((combination) => (
    <CombinationRow key={combination.id} combination={combination} refetchVendorData={refetchVendorData} />
  ));

  return (
    <Table width="100%" className="combinations-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="test-cell">
            <TruncatedHeaderCell text={t('common:userProfile.vendors.tableHeader.status', 'Test Status')} />
          </Table.HeaderCell>
          <Table.HeaderCell className="source-lang-cell">
            <TruncatedHeaderCell
              text={t('common:userProfile.vendors.tableHeader.sourceLanguage', 'Source Language')}
            />
          </Table.HeaderCell>
          <Table.HeaderCell className="target-lang-cell">
            <TruncatedHeaderCell
              text={t('common:userProfile.vendors.tableHeader.targetLanguage', 'Target Language')}
            />
          </Table.HeaderCell>
          <Table.HeaderCell className="subject-matter-cell">
            <TruncatedHeaderCell
              text={t('common:userProfile.vendors.tableHeader.subjectMatter', 'Subject Matter')}
            />
          </Table.HeaderCell>
          <Table.HeaderCell className="service-cell">
            <TruncatedHeaderCell text={t('common:userProfile.vendors.tableHeader.service', 'Service')} />
          </Table.HeaderCell>
          <Table.HeaderCell className="rate-cell">
            <TruncatedHeaderCell text={t('common:userProfile.vendors.tableHeader.rate', 'Rate (per word)')} />
          </Table.HeaderCell>
          <Table.HeaderCell className="edit-cell"></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows}
        <AddCombinationRow vendorId={vendorId} refetchVendorData={refetchVendorData} />
      </Table.Body>
    </Table>
  );
};

export default CombinationsTable;
