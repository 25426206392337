import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CurrenciesDropdown = ({ disabled = false, currency_id = null, updateProperty }) => {
  const currencies = useSelector((state) => state.classifiersStore.currencies);
  const { t } = useTranslation();
  const currenciesOptions = currencies.map((currency) => {
    return {
      key: currency.id,
      text: `${currency.code} (${currency.symbol})`,
      value: currency.id,
    };
  });

  const handleCurrencyChange = (e, { value }) => {
    updateProperty({ name: 'currency_id', value });
  };

  return (
    <SelectDropdown
      label={t('common:currency')}
      placeholder={t('common:currency')}
      name="currency_id"
      disabled={disabled}
      selection
      onChange={handleCurrencyChange}
      options={currenciesOptions}
      value={currency_id}
      required
    />
  );
};

export default CurrenciesDropdown;
