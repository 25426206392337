import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Icon } from '../../../common/Icon/Icon';

export const AccordionContainer = styled.div`
  display: flex;
  row-gap: 13px;
  flex-direction: column;
  height: fit-content;

  ${({ theme, step, stepsLength }) => theme.mediaMaxWidth.mobile`
    height: 100%;

    // Hide whole accordion container if at last step
    ${step === stepsLength && 'display: none;'}
  `}
`;

export const AccordionItem = styled(motion.div)`
  border: 1px solid #e9e9e9;
  transition: background-color 0.2s ease;

  ${({ theme, open }) => theme.mediaMaxWidth.mobile`
    opacity: ${open ? 1 : 0};
    position: ${open ? 'relative' : 'absolute'};
    pointer-events: ${open ? 'all' : 'none'};
    flex-grow: 1;
    background-color: #ffffff !important;
  `}
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 1em 1em 1.75em;
  color: ${({ open, theme }) => (open ? '#000' : theme.colors.greyDark)};
  transition: color 0.2s ease;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.5em;
  cursor: pointer;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: 11px ${theme.paddings.mainContainer.mobile.x};
    position: sticky;
    top: 0;
    background-color: ${theme.colors.greyLight};
    border-bottom: 1px solid ${theme.colors.greyBright};
    z-index: 1;

    span {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
    }

    .icon {
      display: none;
    }
  `}
`;

export const AccordionIcon = styled(Icon)`
  margin-left: auto;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  color: ${({ open, theme }) => (open ? theme.colors.blue : '#000')};
  transition: transform, color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  will-change: transform, color;
`;

export const StyledAccordionContent = styled(motion.div)`
  padding-left: 2.78571428571em;
  padding-right: 2.78571428571em;

  ${({ theme, open }) => theme.mediaMinWidth.mobile`
    pointer-events: ${open ? 'all' : 'none'};
    padding-top: 21px;
    padding-bottom: 21px;
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;

    .next-step-btn {
      display: none !important;
    }
  `}
`;

export const StyledAccordionChildrenWrapper = styled(motion.div)`
  transform-origin: top;
`;
