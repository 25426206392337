import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Table from 'components/common/Table/Table';
import Button from 'components/common/Button/Button';
import { Input, Label, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import AssignPerson from 'components/common/AssignPerson/AssignPerson';

// Utils
import { sendUserInteraction } from 'utils/tagManager.utils';
import { assignPmToCompany, assignKAMToCompany, postCompanyUpdateById } from 'services/company';

const CompaniesTableRow = ({ company }) => {
  const [loading, setLoading] = useState({
    pm: false,
    kam: false,
    company: false,
  });
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [companyData, setCompanyData] = useState(company);
  const [editFormData, setEditFormData] = useState({
    company_name: companyData.company_name,
    // Check for null
    comp_discount: !!companyData.comp_discount ? companyData.comp_discount : 0,
  });

  const [currManagers, setCurrManagers] = useState({
    pm: companyData?.project_manager,
    kam: companyData?.key_account_manager,
  });

  const adminUsers = useSelector((state) => state.userStore.adminUsers);

  // Hooks
  const { t } = useTranslation();

  // Methods
  const handlePmAssign = async (pm_id) => {
    setLoading((c) => ({ ...c, pm: true }));

    try {
      const response = await assignPmToCompany({ company_id: company.id, pm_id });
      setCurrManagers((c) => ({ ...c, pm: response.project_manager }));
      toast.success(
        t('common:toasts.adminCompaniesTableRow.pmAssign.success', { companyName: companyData.company_name }),
      );
    } catch (e) {
      console.error(e);
      toast.error(
        t('common:toasts.adminCompaniesTableRow.pmAssign.error', { companyName: companyData.company_name }),
      );
    } finally {
      setLoading((c) => ({ ...c, pm: false }));
    }
  };

  const handleKAMAssign = async (kam_id) => {
    setLoading((c) => ({ ...c, kam: true }));

    try {
      const response = await assignKAMToCompany({ company_id: company.id, kam_id });
      setCurrManagers((c) => ({ ...c, kam: response.key_account_manager }));
      toast.success(
        t('common:toasts.adminCompaniesTableRow.kamAssign.success', {
          companyName: companyData.company_name,
        }),
      );
    } catch (e) {
      console.error(e);
      toast.error(
        t('common:toasts.adminCompaniesTableRow.kamAssign.error', { companyName: companyData.company_name }),
      );
    } finally {
      setLoading((c) => ({ ...c, kam: false }));
    }
  };

  const handleInputChange = (e) => {
    const newFormData = { ...editFormData };

    newFormData[e.target.name] = e.target.value;

    setEditFormData(newFormData);
  };

  const handleEditCancel = () => {
    if (
      editFormData.company_name !== companyData.company_name ||
      (editFormData.comp_discount !== companyData.comp_discount && editFormData.comp_discount !== 0)
    ) {
      setCancelModalOpen(true);
    } else {
      resetFormData();
      toggleEditing();
    }
  };

  const handleCancelModal = (_, reset = false) => {
    if (reset) {
      resetFormData();
      toggleEditing();
    }

    setCancelModalOpen(false);
  };

  const resetFormData = () => {
    setEditFormData({
      company_name: companyData.company_name,
      comp_discount: !!companyData.comp_discount ? companyData.comp_discount : 0,
    });
  };

  const handleSubmit = async () => {
    const formDiscount = parseInt(editFormData.comp_discount);
    const formCompanyName = editFormData.company_name;
    const companyName = companyData.company_name;
    const companyDiscount = companyData.comp_discount;

    // If nothing is changed don't submit, disable editing
    if (companyName === formCompanyName && companyDiscount === (formDiscount === 0 ? null : formDiscount)) {
      setEditing(false);
      return;
    }

    setLoading((c) => ({ ...c, company: true }));

    try {
      const response = await postCompanyUpdateById({
        companyId: company.id,
        company_name: formCompanyName,
        // If company discount is set to 0, send null as discount
        comp_discount: formDiscount === 0 ? null : formDiscount,
      });

      toast.success(t('common:toasts.adminCompaniesTableRow.success'));
      setCompanyData((c) => ({ ...c, ...response }));

      setEditing(false);
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.adminCompaniesTableRow.error'));
    } finally {
      setLoading((c) => ({ ...c, company: false }));
    }
  };

  const toggleEditing = () => setEditing((c) => !c);

  // Render variables
  const ownerLabels = company.owners.map((owner) => {
    return <Label key={owner.id} content={owner.name} />;
  });

  return (
    <>
      <Table.Row key={companyData.id}>
        {/** Column company id */}
        <Table.Cell>
          {editing ? (
            companyData.id
          ) : (
            <Link
              onClick={() => sendUserInteraction('clicked company id link')}
              to={`/company/${companyData.id}`}
            >
              {companyData.id}
            </Link>
          )}
        </Table.Cell>

        {/** Column company name */}
        <Table.Cell>
          {editing ? (
            <Input
              name="company_name"
              value={editFormData.company_name}
              type="text"
              onChange={handleInputChange}
            />
          ) : (
            <Link
              onClick={() => sendUserInteraction('clicked company name')}
              to={`/company/${companyData.id}`}
            >
              {companyData.company_name}
            </Link>
          )}
        </Table.Cell>

        {/** Column company owners */}
        <Table.Cell>
          <div className="company-owner">{ownerLabels}</div>
        </Table.Cell>

        {/** Column project manager */}
        <Table.Cell>
          <AssignPerson
            displayName
            currentPerson={currManagers.pm}
            choices={adminUsers}
            onAssign={handlePmAssign}
            loading={loading.pm}
          />
        </Table.Cell>

        {/** Column key account manager */}
        <Table.Cell>
          <AssignPerson
            displayName
            currentPerson={currManagers.kam}
            choices={adminUsers}
            onAssign={handleKAMAssign}
            loading={loading.kam}
            labels={{
              noPerson: t('common:assignPerson.keyAccountManager.noPerson'),
              title: t('common:assignPerson.keyAccountManager.title'),
              placeholder: t('common:assignPerson.keyAccountManager.placeholder'),
              unassignHoverTitle: t('common:assignPerson.keyAccountManager.unassignHoverTitle'),
            }}
          />
        </Table.Cell>

        {/** Column company discount */}
        <Table.Cell>
          {editing ? (
            <Input
              value={editFormData.comp_discount}
              name="comp_discount"
              type="number"
              min={0}
              max={100}
              onChange={handleInputChange}
            />
          ) : (
            companyData.comp_discount ?? 0
          )}
        </Table.Cell>

        {/** Column edit buttons */}
        <Table.Cell textAlign="right">
          {editing ? (
            <>
              <Button
                icon="check"
                actiontype="submit"
                onClick={() => {
                  sendUserInteraction('submit edited company data');
                  handleSubmit();
                }}
                loading={loading.company}
                disabled={loading.company}
              />
              <Button
                actiontype="cancel"
                icon="times"
                onClick={() => {
                  sendUserInteraction('clicked edit company cancel');
                  handleEditCancel();
                }}
                loading={loading.company}
                disabled={loading.company}
              />
            </>
          ) : (
            <Button
              icon="edit-alt"
              actiontype="secondary"
              onClick={() => {
                sendUserInteraction('clicked edit company');
                toggleEditing();
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
      <Modal size="mini" closeIcon open={cancelModalOpen}>
        <Modal.Content>
          <p>{t('common:unsavedChanges')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button actiontype="cancel" onClick={handleCancelModal}>
            {t('common:cancel')}
          </Button>
          <Button actiontype="delete-confirm" onClick={() => handleCancelModal(null, true)}>
            {t('common:discard')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CompaniesTableRow;
