import React from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';

import { Dropdown } from 'semantic-ui-react';

import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  white-space: nowrap;
`;

const UserDropdown = ({ user, handleUserChange, userOptions = [] }) => {
  const { t } = useTranslation();

  const getDropdownOptions = () => {
    // empty option for reseting seat to null
    const EMPTY_USER_OPTION = {
      key: 0,
      text: t('common:catapult.subscription.usersTable.notAssigned'),
      value: 0,
    };

    // dropdown options from available users
    const dropdownOptions = userOptions.map((availableUser) => {
      return {
        key: availableUser.id,
        text: availableUser.name,
        value: availableUser.id,
      };
    });

    const options = [EMPTY_USER_OPTION, ...dropdownOptions];

    // currently assigned user is not returned from available-users endpoint but needs to be added when assigned to the seat
    if (!!user) {
      options.push({
        key: user.id,
        text: user.name,
        value: user.id,
      });
    }

    // return only unique users. This is here just in case, we had issues with duplicates before. NEVER TRUST THE BE SERVER BOYS!
    return uniq(options, 'key');
  };

  const dropdownOptions = getDropdownOptions();

  return (
    <StyledDropdown onChange={handleUserChange} options={dropdownOptions} value={!!user ? user.id : 0} />
  );
};

export default UserDropdown;
