import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Modal } from 'semantic-ui-react';
import Table from '../../../common/Table/Table';
import Message from '../../../common/Message/Message';
import { isValidEmail } from '../../../../utils/string.utils';

import HelpPopup from './HelpPopup';
import { HeaderContainer, NewMemberWrapper } from './InviteUsersModal.style';
import { postMemberInvite } from '../../../../services/teams';
import { toast } from 'react-toastify';
import Button from '../../../common/Button/Button';
import { TEAM_ROLES, TEAM_TRANSLATIONS } from '../../../../constants/roles';
import { postCompanyOwner } from '../../../../services/company';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useCompanyDispatch, useCompanyState } from '../../Company.state';
import { Icon } from '../../../common/Icon/Icon';
import { SelectDropdown } from '../../../common/Dropdown/SelectDropdown';
import { Input } from '../../../common/Input/Input';

const InviteUsersModal = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useCompanyDispatch();

  const [error, setError] = useState('');
  const [role, setRole] = useState(0);
  const [email, setEmail] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const {
    teams: { data: teams },
  } = useCompanyState();

  const [newMembers, setNewMembers] = useState([]);

  const teamOptions = teams.map((team) => {
    return {
      key: team.id,
      text: team.name,
      value: team.id,
    };
  });

  const getTeamName = (teamId) => {
    const teamData = teams.find((team) => team.id === teamId);
    if (teamData) {
      return teamData.name;
    }
    return t('common:admin.companies.invite.allTeams');
  };

  useEffect(() => {
    setError('');
    setRole(0);
    setEmail('');
    setNewMembers([]);
    setSelectedTeam(null);
  }, [open]);

  const roleOptions = TEAM_ROLES.map((role) => {
    return {
      key: role.value,
      text: t(role.translation),
      value: role.value,
    };
  });

  roleOptions.unshift({
    key: 0,
    text: t('roles:companyManager'),
    value: 0,
  });

  const removeMember = (idx) => {
    sendUserInteraction('remove user invitation before submit');
    return (e) => {
      const members = [...newMembers];
      members.splice(idx, 1);
      setNewMembers(members);
    };
  };

  const addMember = () => {
    sendUserInteraction('add new member invite');
    const members = [...newMembers, { role: role, email: email, team_id: role !== 0 ? selectedTeam : null }];
    setEmail('');
    setNewMembers(members);
  };

  const isValid = () => {
    return Number(role) === 0
      ? isValidEmail(email) && role === 0
      : isValidEmail(email) && role && selectedTeam;
  };

  const handleRoleChange = (e, { value }) => {
    setRole(value);
  };

  const submitInvites = async () => {
    sendUserInteraction('submit user invites');
    const invites = [...newMembers];
    let invite = null;
    const newInvites = [];
    try {
      setIsSubmitting(true);
      for (invite of newMembers) {
        if (invite.role === 0) {
          const newInvite = await postCompanyOwner({ id, email: invite.email });
          newInvite.role_id = 0;
          newInvites.push({ ...newInvite });
        } else {
          const newInvite = await postMemberInvite({
            team_id: invite.team_id,
            email: invite.email,
            role_id: invite.role,
          });
          newInvites.push({ ...newInvite });
        }
        invites.shift();
        toast.success(t('common:toasts.inviteMembers.success') + `: ${invite.email}`);
      }
      dispatch({ type: 'ADD_INVITES', payload: newInvites });
      setOpen(false);
    } catch (e) {
      dispatch({ type: 'ADD_INVITES', payload: newInvites });
      toast.error(t('common:toasts.inviteMembers.error'));
      if (e.response.status === 422) {
        setError(t('common:team.members.emailInUse') + `: ${invite.email}`);
      } else {
        setError(t('common:team.members.inviteError') + `: ${invite.email}`);
      }
      setNewMembers(invites);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <Button
            actiontype="primary"
            icon
            labelPosition="left"
            onClick={sendUserInteraction('open invite user modal')}
          >
            <Icon name="plus-square" />
            {t('common:team.members.addMember')}
          </Button>
        }
      >
        <Modal.Header>
          <HeaderContainer>
            {t('common:team.members.addMember')}
            <HelpPopup />
          </HeaderContainer>
        </Modal.Header>
        <Modal.Content>
          {error && <Message text={error} type="error" />}

          <NewMemberWrapper>
            <div className="member-form">
              <Input
                placeholder={t('common:team:members.email')}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                style={{ width: '100%' }}
                value={email}
                size="small"
              />
              <SelectDropdown
                size="small"
                options={roleOptions}
                placeholder={t('common:team.members.role')}
                value={role}
                onChange={handleRoleChange}
              />
              {role !== 0 ? (
                <SelectDropdown
                  size="small"
                  options={teamOptions}
                  placeholder={t('common:team.team')}
                  value={selectedTeam}
                  onChange={(e, { value }) => {
                    setSelectedTeam(value);
                  }}
                />
              ) : null}
              <Button
                actiontype="primary"
                icon="plus"
                disabled={!isValid()}
                onClick={addMember}
                style={{ height: 'unset' }}
              />
            </div>
            {newMembers.length > 0 ? (
              <Table width="100%">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('common:team.members.email')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('common:team.members.role')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('common:team.members.team')}</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {newMembers.map((member, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell>{member.email}</Table.Cell>
                      <Table.Cell>{t(TEAM_TRANSLATIONS[member.role])}</Table.Cell>
                      <Table.Cell>{getTeamName(member.team_id)}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Button actiontype="delete" icon="trash-alt" onClick={removeMember(idx)} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <Message text={t('common:team.members.addReminder')} type="info" />
            )}
          </NewMemberWrapper>
        </Modal.Content>
        <Modal.Actions>
          <Button actiontype="cancel" onClick={() => setOpen(false)} disabled={isSubmitting}>
            {t('common:cancel')}
          </Button>
          <Button
            actiontype="submit"
            disabled={newMembers.length <= 0}
            onClick={submitInvites}
            loading={isSubmitting}
          >
            {t('common:submit')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default InviteUsersModal;
