import React from 'react';

// Components
import { Popup } from 'components/common/Popup/Popup';
import { classnames } from 'utils/base.utils';

const ProgressBarsStackedSingleBar = ({ width, color, left, label, className }) => {
  const triggerProps = {
    className: classnames('bar', className, {
      // Bar is at the left edge
      left: left === 0,
      // Bar is at the right edge
      right: left + width === 100,
      // Bar is full width
      full: width === 100,
    }),
    style: {
      '--bar-width': `${width}%`,
      '--bar-color': color,
      '--bar-left': `${left}%`,
    },
  };

  const popupContent = `${label} ${width}%`;

  return <Popup dark trigger={<div {...triggerProps} />} content={popupContent} />;
};

export default ProgressBarsStackedSingleBar;
