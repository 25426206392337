import { BillingFields } from 'components/Checkout/ReviewBilling/BillingForm';
import { Icon } from 'components/common/Icon/Icon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Segment, TabContainer } from '../UserProfile.styles';
import Button from 'components/common/Button/Button';
import { toast } from 'react-toastify';
import { postBillingUpdate, postBillingCreate } from 'services/billing';
import { isCompanyRole } from 'utils/user.utils';
import { getBillingDetails } from 'services/billing';
import { findBillingId } from 'components/Checkout/Checkout.utils';
import { checkTeamRole } from 'utils/user.utils';
import { getTeamRole } from 'utils/user.utils';
import TeamBillingDropdown from 'components/Checkout/ReviewBilling/TeamBillingDropdown';
import { postUpdateTeam } from 'services/teams';
import { checkAllowedRole } from 'utils/user.utils';
import Message from 'components/common/Message/Message';
import { useSelector } from 'react-redux';
import { hasAdminPermissions } from 'utils/user.utils';
import { AdminBillingFields } from 'components/Checkout/ReviewBilling/BillingForm';

const StyledForm = styled(Form)`
  width: clamp(300px, 85%, 400px);

  @media only screen and (max-width: 1000px) {
    margin-top: 20px;
    width: 100%;
  }

  &.form > .notice {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .update-notice {
    color: ${({ theme }) => theme.colors.yellow};
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px !important;
`;

const BillingDetailsForm = ({ user, FormElement = Form, onChange = () => {} }) => {
  const loggedUser = useSelector((state) => state.userStore.user);
  const isCompanyUser = useMemo(() => isCompanyRole(user.role), [user]);
  const [billing, setBilling] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(isCompanyUser ? user.teams[0] : { role_id: 1 });
  const [selectedBilling, setSelectedBilling] = useState(findBillingId(user));

  const hasEdited = useRef(false);

  const { t } = useTranslation();

  const canEditBilling = useMemo(() => {
    if (isCompanyUser) {
      return !checkTeamRole({
        allowedRoles: [2, 3],
        teamRole: getTeamRole({ user: loggedUser, teamId: selectedTeam.id }),
      });
    } else {
      return true;
    }
  }, [isCompanyUser, selectedTeam.id, loggedUser]);

  const canCreateBilling = useMemo(() => {
    return checkAllowedRole({ allowedRoles: [40, 29, 10], userRole: loggedUser.role });
  }, [loggedUser.role]);

  const formDisabled = useMemo(() => {
    if (billing.id) {
      return !canEditBilling;
    } else {
      return !canCreateBilling;
    }
  }, [billing.id, canCreateBilling, canEditBilling]);

  const isValid = useMemo(() => {
    if (checkAllowedRole({ allowedRoles: [40, 42], userRole: user.role })) {
      return (
        billing.company_name &&
        billing.country_id &&
        billing.currency_id &&
        billing.company_vat &&
        billing.line1 &&
        billing.city &&
        billing.postal_code
      );
    }
    return (
      billing.company_name &&
      billing.country_id &&
      billing.currency_id &&
      billing.line1 &&
      billing.city &&
      billing.postal_code
    );
  }, [
    user.role,
    billing.company_name,
    billing.country_id,
    billing.currency_id,
    billing.line1,
    billing.city,
    billing.postal_code,
    billing.company_vat,
  ]);

  useEffect(() => {
    const fetchBilling = async (billing_detail_id, user) => {
      if (billing_detail_id === null) {
        return {
          id: null,
          country_id: null,
          company_name: '',
          company_vat: '',
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: '',
          currency_id: null,
          user_email: user.email,
        };
      } else {
        try {
          const bill = await getBillingDetails(billing_detail_id);
          if (!bill.user_email) {
            bill.user_email = user.email;
          }
          return bill;
        } catch (e) {
          console.error(e);
          return {
            id: null,
            country_id: null,
            company_name: '',
            company_vat: '',
            line1: '',
            line2: '',
            city: '',
            postal_code: '',
            state: '',
            currency_id: null,
            user_email: user.email,
          };
        }
      }
    };

    const checkoutCheck = async () => {
      setIsLoading(true);
      const bill = await fetchBilling(selectedBilling, user);

      setBilling(bill);
      setIsLoading(false);
    };

    checkoutCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBilling]);

  const handleBillingChange = (bill) => {
    hasEdited.current = true;
    setBilling(bill);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const newBilling = { ...billing };
      let bill = {};

      if (newBilling.id) {
        if (isCompanyUser) {
          if (
            hasAdminPermissions(loggedUser.role) ||
            !checkTeamRole({ allowedRoles: [2, 3], teamRole: selectedTeam.role_id })
          ) {
            bill = await postBillingUpdate(newBilling.id, newBilling);
          } else {
            bill = { ...newBilling };
          }
        } else {
          bill = await postBillingUpdate(newBilling.id, newBilling);
        }
      } else {
        if (checkAllowedRole({ allowedRoles: [40], userRole: user.role })) {
          // Only company managers and admins can create new company billing details and assign them to teams
          bill = await postBillingCreate({
            ...newBilling,
            company_id: selectedTeam.company_id,
            company_street: `${billing.line1}, ${billing.postal_code} ${billing.city}`,
            name: `${selectedTeam.name} billing`,
            user_id: user.id,
          });
          await postUpdateTeam({
            team_id: selectedTeam.id,
            billing_detail_id: bill.id,
          });
        } else {
          bill = await postBillingCreate({ ...newBilling, user_id: user.id });
        }
      }

      toast.success(t('common:toasts.companyBillingUpdate.success'));
      setBilling(bill);
      setSelectedBilling(bill.id);
      hasEdited.current = false;
      onChange();
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.companyBillingUpdate.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormElement loading={isLoading}>
      {user.vendor && (
        <p className="notice">
          <Trans i18nKey="common:userInfo.billing.vendorNotice">
            Set billing information for when you want to <strong>order services</strong> or software licenses
            at Taia. Please note these billing details <strong>do not apply</strong> to jobs you provide as a
            vendor.
          </Trans>
        </p>
      )}
      {isCompanyUser ? (
        <Form.Field>
          <TeamBillingDropdown
            user={user}
            teamId={selectedTeam.id}
            onChange={(id) => {
              const team = user.teams.find((team) => team.id === id);
              setSelectedTeam(team);
              setSelectedBilling(team.billing_detail_id);
            }}
          />
        </Form.Field>
      ) : null}
      {billing.id ? (
        !canEditBilling ? (
          <Message type="warning" text={t('common:userInfo.billing.cannotEdit')} />
        ) : null
      ) : !canCreateBilling ? (
        <Message type="warning" text={t('common:userInfo.billing.cannotCreate')} />
      ) : null}
      <BillingFields
        billing={billing}
        role={user.role}
        disabled={formDisabled}
        onChange={handleBillingChange}
      />
      {hasAdminPermissions(loggedUser.role) && selectedBilling !== null ? (
        <AdminBillingFields role={user.role} billing={billing} onChange={handleBillingChange} />
      ) : null}
      {isCompanyUser && hasEdited.current ? (
        <Message type="warning" text={t('common:userInfo.billing.editingTeam')} />
      ) : null}
      <StyledButton
        big
        actiontype="submit"
        labelPosition="left"
        disabled={!isValid || isSubmitting || formDisabled}
        onClick={handleSubmit}
        loading={isSubmitting}
        className="user-save-changes-button"
      >
        <Icon name="save" />
        {t('common:userInfo.billing.saveDetails')}
      </StyledButton>
    </FormElement>
  );
};

const UserBillingDetails = ({ user = {}, onChange = () => {} }) => {
  const { t } = useTranslation();

  return (
    <TabContainer>
      <Segment>
        <div>
          <h3>{t('common:userInfo.tabs.billingDetails')}</h3>
        </div>
        <BillingDetailsForm user={user} FormElement={StyledForm} onChange={onChange} />
      </Segment>
    </TabContainer>
  );
};

export default UserBillingDetails;
