import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { classnames } from 'utils/base.utils';

// Styles
import './style.scss';

const CardPaymentMethod = ({ selected = false, paymentMethod = {}, onClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <button className={classnames('payment-method', { selected })} onClick={onClick}>
      <div className="payment-icon">
        <Icon name="credit card" size="big" fitted></Icon>
      </div>
      <div className="flex flex-col">
        <div className="payment-method-field payment-method-field-card">
          <p>{t('common:checkout.payment.methods.number')}</p>
          <strong>XXXX - XXXX - XXXX - {paymentMethod.card.last4}</strong>
        </div>
        <div className="justify-between flex">
          <div className="payment-method-field">
            <p>{t('common:checkout.payment.methods.holder')}</p>
            <strong>{paymentMethod.billing_details.name}</strong>
          </div>
          <div className="payment-method-field">
            <p>{t('common:checkout.payment.methods.expires')}</p>
            <strong>
              {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
            </strong>
          </div>
        </div>
      </div>
    </button>
  );
};

export default CardPaymentMethod;
