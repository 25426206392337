import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Message from '../../common/Message/Message';
import DatepickerRange from '../../common/Datepicker/DatepickerRange';
import { TeamInvoicesLayout, Toolbar, FilterLabel } from '../../Team/TeamInvoices/TeamInvoices.style';
import TeamInvoicesTable from '../../Team/TeamInvoices/TeamInvoicesTable/TeamInvoicesTable';
import { getCompanyInvoices } from '../../../services/company';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { SelectGroup } from '../../common/SelectGroup/SelectGroup';
import { StyledPagination } from '../Company.styles';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const CompanyInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useLocalStorage('company-invoices-page', 1);
  const [lastPage, setLastPage] = useState(1);
  const [paidFilter, setPaidFilter] = useLocalStorage('company-invoices-filters', null);
  const [issuedDates, setIssuedDates] = useState([null, null]);
  const [dueDates, setDueDates] = useState([null, null]);

  const { t } = useTranslation();
  const { id } = useParams();

  const formatDate = (date) => {
    if (!!date) {
      return format(zonedTimeToUtc(date, 'Europe/Ljubljana'), 'P');
    }
    return '';
  };

  useEffect(() => {
    const fetchInitialInvoices = async () => {
      try {
        setIsLoading(true);
        const data = await getCompanyInvoices({
          company_id: id,
          page: page,
          project_paid: paidFilter,
          payment_due_from: dueDates[0] !== null ? formatDate(dueDates[0]) : null,
          payment_due_to: dueDates[1] !== null ? formatDate(dueDates[1]) : null,
          issued_on_from: issuedDates[0] !== null ? formatDate(issuedDates[0]) : null,
          issued_on_to: issuedDates[1] !== null ? formatDate(issuedDates[1]) : null,
        });

        setInvoices(data.data);
        if (page > data.last_page) {
          setPage(data.last_page);
        }

        setLastPage(data.last_page);
      } catch (e) {
        setIsError(true);
        throw e;
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialInvoices();
  }, [id, page, paidFilter, issuedDates, dueDates, setPage]);

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('invoices pagination change');
    setPage(activePage);
  };

  const handlePaidChange = (value) => {
    sendUserInteraction('clicked paid/unpaid invoices filter');
    setPaidFilter(value);
  };

  return (
    <TeamInvoicesLayout>
      <Toolbar>
        <FilterLabel>{t('common:company.invoices.filterBy')}: </FilterLabel>
        <DatepickerRange
          onChange={(dates) => {
            sendUserInteraction('clicked date issued filter');
            setIssuedDates(dates);
          }}
          placeholder="Issued date"
        />
        <DatepickerRange
          onChange={(dates) => {
            sendUserInteraction('clicked date due filter');
            setDueDates(dates);
          }}
          placeholder="Payment due"
        />
        <FilterLabel style={{ marginLeft: '1em' }}>{t('common:company.invoices.show')}: </FilterLabel>
        <SelectGroup onChange={handlePaidChange} size="small" selected={paidFilter}>
          <SelectGroup.Option value={null}> {t('common:team.invoices.all')} </SelectGroup.Option>
          <SelectGroup.Option value={1}> {t('common:team.invoices.paid')} </SelectGroup.Option>
          <SelectGroup.Option value={0}> {t('common:team.invoices.notPaid')} </SelectGroup.Option>
        </SelectGroup>
      </Toolbar>

      {isLoading ? (
        <LoadingPane height={'260px'} />
      ) : (
        <TeamInvoicesTable isCompany={true} invoices={invoices} />
      )}
      {isError && <Message text={t('common:team.invoices.error')} type="error" />}
      {lastPage > 1 && (
        <StyledPagination activePage={page} totalPages={lastPage} onPageChange={handlePaginationChange} />
      )}
    </TeamInvoicesLayout>
  );
};

export default CompanyInvoices;
