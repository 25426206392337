import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompanyEditForm from '../../common/BillingDetails/EditForm/CompanyEditForm';

import { sendUserInteraction } from 'utils/tagManager.utils';
import Button from '../../common/Button/Button';
import { Icon } from '../../common/Icon/Icon';
import { useCompanyDispatch } from '../Company.state';
import { toast } from 'react-toastify';

const NewCompanyBillingButton = ({ company_id }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useCompanyDispatch();

  const handleModalClose = () => {
    sendUserInteraction('clicked cancel create billing');
    setShowAddModal(false);
  };

  const handleModalOpen = (e) => {
    sendUserInteraction('clicked add billing details card');
    e.preventDefault();
    setShowAddModal(true);
  };

  const handleSubmit = (newBilling) => {
    dispatch({ type: 'ADD_BILLING', payload: newBilling });
    toast.info(t('common:toasts.companyBillingNew.success'), { autoClose: false });
  };

  return (
    <div>
      <Button actiontype="primary" icon labelPosition="left" onClick={handleModalOpen}>
        <Icon name="plus-square" />
        {t('common:billing.createNewBilling')}
      </Button>
      {showAddModal && (
        <CompanyEditForm company_id={company_id} closeModal={handleModalClose} updateBilling={handleSubmit} />
      )}
    </div>
  );
};
export default NewCompanyBillingButton;
