import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Grid, Header } from 'semantic-ui-react';
import { ACCEPTED_FILES, FILE_NAMES } from '../../../../constants/files';
import { StyledSubtext, StyledIcon } from './FilesUpload.styles';
import { Popup } from 'components/common/Popup/Popup';

const StyledHeader = styled(Header)`
  margin: 0 0 0.5rem 0;
  width: calc(100% - 20px);
`;

const StyledGrid = styled(Grid)`
  ${({ theme }) => theme.mediaMinWidth.mobile`
    &.ui.grid.column {
      padding: 2rem;
      width: 500px;
    }
  `}
`;

const SupportedFiles = ({ t }) => (
  <StyledSubtext>
    {t('common:projects.createProject.supportedFiles')}&nbsp;
    <Popup
      trigger={
        <span style={{ display: 'flex' }}>
          <span className="underlined">
            {ACCEPTED_FILES.length - 4} {t('common:projects.createProject.otherFormats')}
          </span>
          <StyledIcon name="info-circle" />
        </span>
      }
      fullscreenMobile
      on={['hover', 'click']}
    >
      <Grid>
        <StyledGrid columns={6}>
          <StyledHeader as="h4">{`${t('common:projects.createProject.fileTypes.office')}:`}</StyledHeader>
          <Grid.Row>
            {FILE_NAMES.office.map((filetype) => {
              return <Grid.Column key={filetype}>.{filetype}</Grid.Column>;
            })}
          </Grid.Row>
          <StyledHeader as="h4">
            {`${t('common:projects.createProject.fileTypes.localisation')}:`}
          </StyledHeader>
          <Grid.Row>
            {FILE_NAMES.localisation.map((filetype) => {
              return <Grid.Column key={filetype}>.{filetype}</Grid.Column>;
            })}
          </Grid.Row>
          <StyledHeader as="h4">{`${t('common:projects.createProject.fileTypes.image')}:`}</StyledHeader>

          <Grid.Row>
            {FILE_NAMES.image.map((filetype) => {
              return <Grid.Column key={filetype}>.{filetype}</Grid.Column>;
            })}
          </Grid.Row>
          <StyledHeader as="h4">{`${t('common:projects.createProject.fileTypes.graphic')}:`}</StyledHeader>
          <Grid.Row>
            {FILE_NAMES.graphic.map((filetype) => {
              return <Grid.Column key={filetype}>.{filetype}</Grid.Column>;
            })}
          </Grid.Row>
          <Grid.Row>
            <StyledSubtext>
              <span style={{ display: 'flex' }}>
                {`${t('common:projects.createProject.fileTypes.info')}`}
              </span>
            </StyledSubtext>
          </Grid.Row>
        </StyledGrid>
      </Grid>
    </Popup>
  </StyledSubtext>
);

export default withTranslation('common')(SupportedFiles);
