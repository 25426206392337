import React, { createContext, useContext, useRef } from 'react';
import { Icon } from '../Icon/Icon';
import {
  MultipleDropdownMenuFooter,
  Label,
  MultipleDropdownMenuHeader,
  StyledContainer,
} from './SelectDropdown.styles';
import { useDropdown } from './useDropdown';
import { multipleReducer } from './reducers';
import { getOptionFromValue, includes } from './utils';
import RequiredSign from '../RequiredSign/RequiredSign';
import Button from '../Button/Button';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import { classnames } from 'utils/base.utils';

const dropdownContext = createContext(null);
const useDropdownContext = () => useContext(dropdownContext);

export const DropdownItem = ({ children, className, item, index, disabled, ...rest }) => {
  const { getItemProps, highlightedIndex } = useDropdownContext();

  const getClasses = () => {
    const classes = [className, 'dropdown-item'];
    if (highlightedIndex === index) classes.push('highlighted');
    if (disabled) classes.push('disabled');
    return classes.join(' ');
  };

  return (
    <div className={getClasses()} {...getItemProps({ item, index, disabled, ...rest })}>
      {children}
    </div>
  );
};

export const DropdownMenu = ({ children, ...rest }) => {
  const { getMenuProps } = useDropdownContext();
  return (
    <div className="dropdown-menu" {...getMenuProps(rest)}>
      {children}
    </div>
  );
};

const MultipleDropdown = ({
  options,
  placeholder = '',
  fluid,
  search,
  label,
  required,
  value = [],
  onChange = () => {},
  className,
  emptyState = 'No results found',
  renderItem,
  children,
  error,
  preventDeleting = false,
  id,
  name,
  showSelectedInMenu,
  optionLabel = 'items',
  fitPlaceholder,
}) => {
  const state = useDropdown({
    items: options,
    reducer: multipleReducer,
    initialState: {
      selectedItem: [],
    },
    selectedItem: getSelectedItem(),
    onSelectedItemChange: handleSelectedItemChange,
    filterFunction: includes,
  });

  const {
    isOpen,
    selectedItem,
    getToggleProps,
    getInputProps,
    handleRemoveItem,
    items,
    expandUpward,
    handleCloseDropdown,
    handleClearAll,
  } = state;

  const lastAddedRef = useRef(null);

  function handleSelectedItemChange({ selectedItem }) {
    onChange(null, { value: selectedItem.map((item) => item.value), name });
    lastAddedRef.current = selectedItem[selectedItem.length - 1];
  }

  function getSelectedItem() {
    return value.map((v) => getOptionFromValue(v, options)).filter((e) => !!e);
  }

  const containerRef = useRef(null);

  // Variables for render
  const containerClassName = classnames(className, 'multiple', {
    open: isOpen,
    fluid,
    label,
    empty: selectedItem.length === 0,
    upward: expandUpward,
    error,
    'fit-placeholder': fitPlaceholder,
    'show-selected-in-menu': showSelectedInMenu,
  });

  const selectedItems = (
    <div className="multiple-dropdown-selected">
      {selectedItem.map((item) => (
        <Label
          icon={item.icon}
          key={`label-${item.value}`}
          text={item.text}
          animate={lastAddedRef.current?.value === item.value}
          preventDeleting={preventDeleting}
          onRemove={(e) => {
            e.stopPropagation();
            handleRemoveItem(item);
          }}
        />
      ))}
    </div>
  );

  const placeholderText =
    showSelectedInMenu && selectedItem.length
      ? `${selectedItem.length} ${optionLabel}${selectedItem.length > 1 ? 's' : ''} selected`
      : placeholder;

  const inputProps = {
    className: 'search-input',
    placeholder: placeholderText,
    'data-value': selectedItem.map((item) => item.value).join(','),
    ...(fitPlaceholder && { size: placeholderText.length }),
    ...getInputProps(),
  };

  return (
    <dropdownContext.Provider value={state}>
      <StyledContainer data-cy="multi-select" id={id} className={containerClassName} ref={containerRef}>
        <div className="dropdown-header" {...getToggleProps()}>
          {search && <input {...inputProps} />}
          {value.length > 0 ? (
            <Icon
              className="reset"
              name="times"
              onClick={() => handleSelectedItemChange({ selectedItem: [] })}
            />
          ) : (
            <Icon className="arrow" name="angle-down" />
          )}
          {label && (
            <label>
              {label}
              {required ? <RequiredSign /> : null}
            </label>
          )}

          {/** If showSelectedInMenu is false, show selected items here */}
          {!showSelectedInMenu && selectedItems}
        </div>
        <DropdownMenu>
          <MultipleDropdownMenuHeader>
            {search && (
              <input
                className="search-input"
                {...getInputProps()}
                placeholder={selectedItem?.text || placeholder}
              />
            )}
            <button className="close-btn" onClick={handleCloseDropdown}>
              <Icon name="times" size="big" />
            </button>
            {selectedItem.length > 0 && selectedItems}
          </MultipleDropdownMenuHeader>
          {showSelectedInMenu && selectedItems}
          <div className="dropdown-items-wrapper">
            {children
              ? children
              : items.length > 0
              ? items.map((item, index) => {
                  const disabled = selectedItem.some((itm) => itm.value === item.value);
                  const props = { disabled, item, index, key: `${item.value}` };
                  return renderItem ? (
                    renderItem(props)
                  ) : (
                    <DropdownItem {...props}>
                      {item?.icon}
                      {item.text}
                    </DropdownItem>
                  );
                })
              : search && <div className="dropdown-item">{emptyState}</div>}
          </div>

          <MultipleDropdownMenuFooter>
            <Button onClick={handleClearAll} big>
              Clear all
            </Button>
            <Button actiontype="primary" big onClick={handleCloseDropdown}>
              Confirm selection
            </Button>
          </MultipleDropdownMenuFooter>
        </DropdownMenu>
      </StyledContainer>
      <FullscreenOverlay open={isOpen} className="hide-on-desktop" />
    </dropdownContext.Provider>
  );
};

MultipleDropdown.Menu = DropdownMenu;
MultipleDropdown.Item = DropdownItem;
export { MultipleDropdown };
