import { useState } from 'react';

const FIRST_STEP = 0;

function useStep({ steps, initialStep = FIRST_STEP, completedSteps }) {
  const [completed, setCompleted] = useState(completedSteps);
  const [index, setIndex] = useState(initialStep);

  const inRange = (index) => {
    if (index < FIRST_STEP) return FIRST_STEP;
    if (index >= steps.length) return steps.length - 1;
    return index;
  };

  const go = (nextStep) => setIndex(inRange(nextStep));
  const next = () => go(index + 1);
  const prev = () => go(index - 1);

  const complete = (completeStep = index) => {
    const completeStepIndex = inRange(completeStep);

    setCompleted([...new Set([...completed, completeStepIndex])]);
  };

  const uncomplete = (uncompleteStep = index) => {
    const uncompleteStepIndex = inRange(uncompleteStep);

    setCompleted(completed.filter((idx) => idx !== uncompleteStepIndex));
  };

  const reset = (resetStep = initialStep) => {
    setIndex(resetStep);
    setCompleted([]);
  };

  return {
    complete,
    completed,
    index,
    navigation: { next, prev, go },
    uncomplete,
    reset,
    steps,
  };
}

export default useStep;
