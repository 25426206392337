import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { TabContainer, Segment } from '../UserProfile.styles';
import {
  StyledPasswordInput,
  StyledPasswordValidator,
  StyledLabel,
  StyledForm,
} from './PasswordReset.styles';

import { Icon } from 'components/common/Icon/Icon';
import Button from 'components/common/Button/Button';
import Message from 'components/common/Message/Message';

import { postSetPassword } from 'services/auth';
import { setIsAuthenticated, setIsAuthenticating, setUser, setAffiliateToken } from 'store/userSlice';
import { toast } from 'react-toastify';
import { hasMinLength } from 'utils/string.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

const PasswordReset = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [formState, setFormState] = useState({
    isEditing: true,
    isSubmitting: false,
    hasError: false,
    error: '',
  });

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    sendUserInteraction('submit password reset form');

    e.preventDefault();
    try {
      setFormState({
        ...formState,
        isSubmitting: true,
      });
      //Axios call
      await postSetPassword(currentPassword, newPassword, newPasswordConfirm);
      setFormState({
        ...formState,
        isSubmitting: false,
      });
      toast.success(t('common:toasts.resetPassword.success'));
      localStorage.removeItem('authToken');

      dispatch(setAffiliateToken({ affiliateToken: null }));
      dispatch(setIsAuthenticated({ isAuthenticated: false }));
      dispatch(setIsAuthenticating({ isAuthenticating: false }));
      dispatch(setUser({ user: null }));

      history.push('/');
    } catch (e) {
      toast.error(t('common:toasts.resetPassword.error'));
      setFormState({
        ...formState,
        isSubmitting: false,
        hasError: true,
        error: t('common:userInfo.resetPassword.current_doesnt_match'),
      });
      throw e;
    }
  };

  const isFormValid = () =>
    hasMinLength(currentPassword, 8) && isNewPasswordValid && newPassword === newPasswordConfirm;

  return (
    <TabContainer>
      <Segment>
        <div>
          <h3>{t('common:userInfo.resetPassword.changePassword')}</h3>
        </div>
        <StyledForm>
          <StyledPasswordInput
            label={t('common:userInfo.resetPassword.current_password')}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            disabled={formState.isSubmitting}
            error={formState.hasError}
            style={{ marginBottom: '30px' }}
            onFocus={() => sendUserInteraction('password reset form current password input focus')}
          />
          <StyledPasswordInput
            label={t('common:userInfo.resetPassword.new_password')}
            value={newPassword}
            disabled={formState.isSubmitting}
            onChange={(e) => setNewPassword(e.target.value)}
            onFocus={() => sendUserInteraction('password reset form new password input focus')}
          />
          <StyledPasswordValidator
            password={newPassword}
            setIsValid={setIsNewPasswordValid}
            extraChecks={[['common:userInfo.resetPassword.doesntMatchOld', newPassword !== currentPassword]]}
          />
          <StyledPasswordInput
            label={t('common:userInfo.resetPassword.confirm_password')}
            value={newPasswordConfirm}
            disabled={formState.isSubmitting}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
            onFocus={() => sendUserInteraction('password reset form new password confirm input focus')}
          />
          <StyledLabel valid={newPassword === newPasswordConfirm && newPassword.length > 0}>
            {t('common:userInfo.resetPassword.passwordsMatch')}
          </StyledLabel>
          {formState.hasError && <Message text={formState.error} type="error" />}
          <Button
            big
            actiontype="submit"
            labelPosition="left"
            disabled={!isFormValid() || formState.isSubmitting}
            onClick={handleSubmit}
            loading={formState.isSubmitting}
            className="user-save-changes-button"
          >
            <Icon name="save" />
            {t('common:userInfo.resetPassword.updatePassword')}
          </Button>
        </StyledForm>
      </Segment>
    </TabContainer>
  );
};

export default PasswordReset;
