import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getProject, getProjectJobs, postUpdateProjectById } from '../../services/project';
import { CheckoutWrapper } from './Checkout.style';
import OrderSummary from './OrderSummary/OrderSummary';
import Payment from './Payment/Payment';
import ReviewBilling from './ReviewBilling/ReviewBilling';
import OrderNotFound from './OrderNotFound/OrderNotFound';
import LoadingPane from '../common/LoadingPane/LoadingPane';
import ProjectPaid from './ProjectPaid/ProjectPaid';
import { getBillingDetails, getCustomer, getPaymentMethods } from '../../services/billing';
import { getBillingSubscriptions, getPlan, getProduct } from '../../services/subscriptions';
import { fetchSubscriptions, findBillingId, findCurrencyIdForCode } from './Checkout.utils';
import OrderSuccessful from './OrderSuccessful/OrderSuccessful';
import { checkTeamRole, getTeamRole, hasAdminPermissions } from '../../utils/user.utils';
import OrderProject from './OrderProject/OrderProject';
import ActivateFreePlan from './ActivateFreePlan/ActivateFreePlan';
import WaitingReview from './WaitingReview/WaitingReview';
import MissingPayment from './MissingPayment/MissingPayment';
import CannotOrder from './CannotOrder/CannotOrder';
import { getUser } from 'services/users';
import { getTeam } from 'services/teams';

const Checkout = () => {
  const currentUser = useSelector((state) => state.userStore.user);
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const [user, setUser] = useState({ ...currentUser });
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState({});
  const [project, setProject] = useState({});
  const [billing, setBilling] = useState({
    id: null,
    country_id: 1,
    company_name: '',
    company_vat: '',
    line1: '',
    line2: '',
    city: '',
    postal_code: '',
    state: '',
    currency_id: 1,
  });
  const [customer, setCustomer] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({
    type: 'card',
    usingBilling: true,
    name: billing.company_name || '',
    address: billing.line1 || '',
    address2: billing.line2 || '',
    city: billing.city || '',
    postalCode: billing.postal_code || '',
    countryId: billing.country_id || 1,
    state: billing.state || '',
  });

  const [quantity, setQuantity] = useState(query.get('quantity') || 1);
  const [canAddPayment, setCanAddPayment] = useState(true);
  const [team, setTeam] = useState(user?.teams.length > 0 ? user.teams[0] : { role_id: 1 });

  const [step, setStep] = useState('billing');

  const planId = query.get('plan');
  const projectId = query.get('project');
  const billingId = query.get('billingId');
  // If no step is provided default to first step

  const type = useMemo(() => {
    if (projectId) {
      return 'project';
    } else if (planId) {
      return 'package';
    } else {
      return null;
    }
  }, [planId, projectId]);

  useEffect(() => {
    const stp = query.get('step');
    setStep(stp ? stp : 'billing');
  }, [query]);

  useEffect(() => {
    setPaymentInfo({
      type: 'card',
      usingBilling: true,
      name: billing.company_name || '',
      address: billing.line1 || '',
      address2: billing.line2 || '',
      city: billing.city || '',
      postalCode: billing.postal_code || '',
      countryId: billing.country_id || 1,
      state: billing.state || '',
    });
  }, [billing]);

  useEffect(() => {
    const fetchBilling = async (billing_detail_id, defaultCurrency) => {
      if (billing_detail_id === null) {
        return {
          id: null,
          country_id: null,
          company_name: '',
          company_vat: '',
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: '',
          currency_id: defaultCurrency ? defaultCurrency : 1,
        };
      } else {
        try {
          const bill = await getBillingDetails(billing_detail_id);
          return bill;
        } catch (e) {
          console.error(e);
          return {
            id: null,
            country_id: null,
            company_name: '',
            company_vat: '',
            line1: '',
            line2: '',
            city: '',
            postal_code: '',
            state: '',
            currency_id: defaultCurrency ? findCurrencyIdForCode(defaultCurrency) : 1,
          };
        }
      }
    };

    const fetchCustomer = async (billing_detail_id) => {
      try {
        if (billing_detail_id === null) {
          return null;
        } else {
          const customer = await getCustomer(billing_detail_id);
          return customer;
        }
      } catch (e) {
        if (e.response.status === 404) {
          return null;
        } else {
          console.error(e);
        }
      }
    };

    const fetchPaymentMethods = async (billing_detail_id) => {
      try {
        if (billing_detail_id === null) {
          return [];
        } else {
          const { data } = await getPaymentMethods({ billing_id: billing_detail_id });
          return data;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const fetchProject = async (projectId) => {
      try {
        const { data } = await getProject(projectId);
        return data;
      } catch (e) {
        if (e.response.status === 404) {
          return null;
        } else {
          console.error(e);
        }
      }
    };

    const fetchUser = async (project) => {
      try {
        let user = currentUser;

        if (hasAdminPermissions(currentUser.role)) {
          user = await getUser(project.user_id);
        }

        return user;
      } catch (e) {
        if (e.response.status === 404) {
          return null;
        } else {
          console.error(e);
        }
      }
    };

    const fetchPlan = async (planId) => {
      try {
        const plan = await getPlan(planId);
        const product = await getProduct(plan.product_id);
        return { ...plan, name: product.name, wordLimit: product.word_limit };
      } catch (e) {
        if (e.response.status === 404) {
          return null;
        } else {
          console.error(e);
        }
      }
    };

    const checkoutCheck = async () => {
      setIsLoading(true);
      //Checkout product wasn't defined
      if (projectId === null && planId === null) {
        setStep('notFound');
        setIsLoading(false);
      } else if (planId === 'free') {
        setStep('activateFree');
        setIsLoading(false);
      } else {
        if (projectId) {
          let stp = query.get('step');
          //Prevent direct URL navigation to orderProject page so users can't order projects without paying
          stp = stp === 'orderProject' ? 'billing' : stp;

          let proj = await fetchProject(projectId);
          // Check if project exists
          if (proj) {
            if (proj.user_id) {
              const projUser = await fetchUser(proj);
              setUser(projUser);
            } else {
              const team = await getTeam(proj.team_id);
              team.role_id = 1;
              setTeam(team);

              const newUser = { role: 40, teams: [{ ...team }] };
              setUser(newUser);
            }

            const jobs = await getProjectJobs(proj.id);
            proj = { ...proj, jobs };
            setProject(proj);

            //If user is admin or project is DIY order the project without paying
            if (proj.diy === 1) {
              stp = 'orderProject';
              //Check if project is assigned for reviews
            } else if (
              proj.status_id === 21 &&
              checkTeamRole({
                allowedRoles: [2, 3, 4],
                teamRole: getTeamRole({ user: user, teamId: proj.team_id }),
              })
            ) {
              stp = 'waitingForReview';
              // Check if project is already paid
            } else if (proj.status_id > 2 && proj.status_id !== 21) {
              stp = 'projectPaid';
            } else {
              // If billing isn't yet retrieved, fetch billing
              if (!billing.id) {
                const bill = await fetchBilling(proj.billing_detail_id);
                setBilling(bill);

                // Also fetch billing dependencies (customer, payment methods)
                const custom = await fetchCustomer(proj.billing_detail_id);
                setCustomer(custom);

                const paymentMeths = await fetchPaymentMethods(proj.billing_detail_id);
                setPaymentMethods(paymentMeths);

                if (paymentMeths.length > 0) {
                  setPaymentMethodId(paymentMeths[0].id);
                }
              }
            }

            if (
              checkTeamRole({
                allowedRoles: [2, 3],
                teamRole: getTeamRole({ user: user, teamId: proj.team_id }),
              })
            ) {
              setCanAddPayment(false);
            }

            // Default step to "billing" if it isn't yet set
            setStep(stp ? stp : 'billing');
            setIsLoading(false);
          } else {
            setStep('notFound');
            setIsLoading(false);
          }
        } else if (planId) {
          const newPlan = await fetchPlan(planId);

          // Check if package exists
          if (newPlan) {
            // If billing isn't yet retrieved, fetch billing
            if (!billing.id) {
              const billId = !!billingId ? parseInt(billingId) : findBillingId(user);
              const bill = await fetchBilling(billId, newPlan.currency_id);
              // If this user has billing defined
              if (bill.id) {
                // Also fetch billing dependencies
                const custom = await fetchCustomer(bill.id);
                setCustomer(custom);

                const paymentMeths = await fetchPaymentMethods(bill.id);
                setPaymentMethods(paymentMeths);
                if (paymentMeths.length > 0) {
                  setPaymentMethodId(paymentMeths[0].id);
                }
                const subs = (await getBillingSubscriptions({ billing_id: bill.id }))['data'];
                setSubscriptions(subs);
              }

              setBilling(bill);
            }

            if (user.teams.length > 0) {
              if (
                checkTeamRole({
                  allowedRoles: [2, 3],
                  teamRole: getTeamRole({ user: user, teamId: team.id }),
                })
              ) {
                setCanAddPayment(false);
              } else {
                setCanAddPayment(true);
              }
            }
            setPlan(newPlan);
            const stp = query.get('step');
            // Default step to billing
            setStep(stp ? stp : 'billing');

            setIsLoading(false);
          } else {
            setStep('notFound');
            setIsLoading(false);
          }
        }
      }
    };

    checkoutCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId, projectId]);

  const changeStep = (newStep) => {
    query.set('step', newStep);
    history.push('/checkout?' + query.toString());
    setStep(newStep);
  };

  const onBillingContinue = async ({ billing, customer, user, payment, team, subscriptions }) => {
    if (type === 'project') {
      if (project.billing_detail_id === null) {
        try {
          await postUpdateProjectById({ id: project.id, billing_detail_id: billing.id });
        } catch (e) {
          console.error(e);
        }
      }
    }

    let canOrderSubscription = true;
    if (checkTeamRole({ allowedRoles: [2, 3], teamRole: team.role_id })) {
      setCanAddPayment(false);
      canOrderSubscription = false;
    } else {
      setCanAddPayment(true);
    }

    setPaymentMethods(payment);
    if (payment.length > 0) {
      setPaymentMethodId(payment[0].id);
    } else {
      setPaymentMethodId(null);
    }

    setBilling({ ...billing });
    setCustomer({ ...customer });
    setUser({ ...user });
    setTeam(team);
    setSubscriptions(subscriptions);

    if (type === 'package' && !canOrderSubscription) {
      changeStep('cannotOrder');
    } else {
      changeStep('payment');
    }
  };

  const onPaymentContinue = async ({ paymentType = 'card', payment, paymentMethod, newPaymentMethod }) => {
    if (paymentType === 'card') {
      const fetchPaymentMethods = async (billing_detail_id) => {
        try {
          const { data } = await getPaymentMethods({ billing_id: billing_detail_id });
          return data;
        } catch (e) {
          console.error(e);
        }
      };

      if (newPaymentMethod) {
        const paymentMeths = await fetchPaymentMethods(billing.id);
        setPaymentMethods(paymentMeths);
      }

      setPaymentMethodId(paymentMethod);
    }

    setPaymentInfo({ ...payment });

    changeStep('summary');
  };

  const onOrderContinue = async () => {
    if (type === 'package') {
      await fetchSubscriptions(true);
    }
    changeStep('success');
  };

  const onOrderError = (token) => {
    history.push(`/payment/${token}`);
  };

  return isLoading ? (
    <LoadingPane />
  ) : (
    <CheckoutWrapper>
      {step === 'projectPaid' ? <ProjectPaid projectId={projectId} /> : null}
      {step === 'notFound' ? <OrderNotFound /> : null}
      {step === 'billing' ? (
        <ReviewBilling
          type={type}
          initialBilling={billing}
          currentUser={currentUser}
          user={user}
          team={team}
          initialCustomer={customer}
          initialSubscriptions={subscriptions}
          onContinue={onBillingContinue}
          plan={plan}
        />
      ) : null}
      {step === 'payment' ? (
        <Payment
          type={type}
          billing={billing}
          paymentInfo={paymentInfo}
          onContinue={onPaymentContinue}
          paymentMethods={paymentMethods}
          paymentMethodId={paymentMethodId}
          canAddPayment={canAddPayment}
        />
      ) : null}
      {step === 'summary' ? (
        <OrderSummary
          customer={customer}
          paymentType={paymentInfo.type}
          subscriptions={subscriptions}
          type={type}
          billing={billing}
          project={project}
          plan={plan}
          quantity={quantity}
          onQuantityChange={(quantity) => {
            setQuantity(quantity);
          }}
          paymentMethod={paymentMethodId}
          onContinue={onOrderContinue}
          onPaymentError={onOrderError}
        />
      ) : null}
      {step === 'cannotOrder' ? <CannotOrder /> : null}
      {step === 'success' ? <OrderSuccessful projectId={projectId} type={type} /> : null}
      {step === 'orderProject' ? <OrderProject project={project} /> : null}
      {step === 'activateFree' ? <ActivateFreePlan user={user} /> : null}
      {step === 'waitingForReview' ? <WaitingReview projectId={projectId} /> : null}
      {step === 'missingPayment' ? <MissingPayment /> : null}
    </CheckoutWrapper>
  );
};

export default Checkout;
