import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, InputContainer, InputsContainer } from './Inputs.styles';

import { Icon } from '../../Icon/Icon';
import { withTheme } from 'styled-components';
import { Input } from 'components/common/Input/Input';

const EmailInput = ({ user_email, updateProperty, theme }) => {
  const { t } = useTranslation();

  const handleTextInputChange = (e, { name, value }) => {
    e.preventDefault();
    updateProperty({ name, value });
  };

  return (
    <Container>
      <Icon name="envelope" color={theme.colors.blue} size="large" style={{ display: 'block' }} />
      <InputsContainer>
        <InputContainer>
          <Input
            label={t('common:userInfo.billing.labels.email')}
            name="user_email"
            onChange={handleTextInputChange}
            value={user_email || ''}
          />
        </InputContainer>
      </InputsContainer>
    </Container>
  );
};

export default withTheme(EmailInput);
