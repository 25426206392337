import i18n from '../../../i18n';

/**
 * LocalStorage key under which the vendor onboarding started state is kept
 */
export const vendorOnboardingStartedLocalStorageKey = 'vendor-onboarding-started';

/**
 * LocalStorageKey and requiredFields for each step
 */
export const localStorageKeyAndFields = [
  // Step 1
  {
    localStorageKey: 'vendor-onboarding-step-one',
    requiredFields: ['name', 'native_lang_id', 'country_id'],
  },
  // Step 2
  {
    localStorageKey: 'vendor-onboarding-step-two',
  },
  // Step 3
  {
    localStorageKey: 'vendor-onboarding-step-three',
    requiredFields: ['education', 'experience'],
  },
];

export const stepsLabels = {
  0: {
    label: '',
    icon: 'user',
  },
  1: {
    label: '',
    icon: 'english-to-chinese',
  },
  2: {
    label: '',
    icon: 'upload',
  },
};

const updateStepsLabels = () => {
  stepsLabels[0].label = i18n.t('common:serviceProvider.onboarding.steps.stepOne.label');
  stepsLabels[1].label = i18n.t('common:serviceProvider.onboarding.steps.stepTwo.label');
  stepsLabels[2].label = i18n.t('common:serviceProvider.onboarding.steps.stepThree.label');
};

updateStepsLabels();

// Future-proof, on language change update steps labels
i18n.on('languageChanged init', () => {
  updateStepsLabels();
});

/**
 * Loop over array of language pairs and validate that each field has a value
 * Values can be a string or arrays
 */
export const validateLanguagePairs = (lngPairs) => {
  for (const data of lngPairs) {
    const requiredFields = Object.keys(data);

    // Row is not valid
    if (!validateFieldsByName(requiredFields, data)) {
      return false;
    }
  }

  return true;
};

/**
 * Take in an array of required fields and an object of data
 * Loop over required fields and check each field against data
 */
export const validateFieldsByName = (reqFields, data) => {
  // Loop over required fields and check value in fields
  for (const fieldName of reqFields) {
    const currValue = data[fieldName];

    if (fieldName === 'rate') {
      return parseFloat(currValue) > 0;
    }

    // Checks for empty string, null, undefined, empty array
    if (currValue?.length === 0 || currValue === null) return false;
  }

  return true;
};

export const isVendorOnboardStepOneValid = () => {
  const { localStorageKey, requiredFields } = localStorageKeyAndFields[0];
  const lsData = JSON.parse(localStorage.getItem(localStorageKey));

  if (!lsData) return false;

  return validateFieldsByName(requiredFields, lsData);
};

/**
 * Loop over array of localStorage keys and required fields, validate required fields
 */
export const validateStepsInLocalStorage = () => {
  const completedSteps = [];

  for (let i = 0; i < localStorageKeyAndFields.length; i++) {
    const { localStorageKey, requiredFields } = localStorageKeyAndFields[i];
    const lsData = JSON.parse(localStorage.getItem(localStorageKey));

    if (!lsData) continue;

    // Index 0 ~ step 1 ~ normal
    // All required fields are strings
    if (i === 0 && requiredFields && validateFieldsByName(requiredFields, lsData)) completedSteps.push(0);

    // Index 1 ~ step 2 ~ array of rows
    // In localStorage the data is stored in an array
    // Required fields are strings and array (dropdown multiselect)
    if (i === 1 && validateLanguagePairs(lsData)) completedSteps.push(1);
  }

  return completedSteps;
};

/**
 * Loop over vendor local storage keys and return their data
 */
export const getVendorDataFromLocalStorage = () => {
  let final = {};

  for (const { localStorageKey } of localStorageKeyAndFields) {
    // Grab data from localStorage
    const data = JSON.parse(window.localStorage.getItem(localStorageKey));

    // If stored data in LS is array it is language pairs
    final = { ...final, ...(Array.isArray(data) ? { combination: data } : data) };
  }

  return final;
};

/**
 * Helper function to redirect to certain step
 * Uses navigation from useSteps hook
 * Uses history from react router useHistory hook
 */
export const redirectToStep = ({ navigation, history, idx }) => {
  if (idx === 0) {
    navigation.go(0);
    history.push('/vendor-onboarding');
  } else {
    navigation.go(idx);
    history.push(`/vendor-onboarding?step=${idx + 1}`);
  }
};

// Get value under vendorOnboardingStartedLocalStorageKey key
export const userStartedVendorOnboarding = () => localStorage.getItem(vendorOnboardingStartedLocalStorageKey);

/**
 * Clear all keys of vendor onboarding
 */
export const clearLSForVendorOnboarding = () => {
  localStorageKeyAndFields.forEach(({ localStorageKey }) => localStorage.removeItem(localStorageKey));
  localStorage.removeItem(vendorOnboardingStartedLocalStorageKey);
};
