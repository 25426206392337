import React, { useState, useMemo } from 'react';
import { StyledButton } from './OrderProject.styles';
import ServiceTypeDropdown from './ServiceTypeDropdown/ServiceTypeDropdown';
import { Comment } from '../../common/Comment/Comment';
import { CommentSection, LanguagesContainer, TargetLanguage } from './TranslationType.styles';
import { Popup } from 'semantic-ui-react';
import { Flag } from '../../common/Flag/Flag';
import { Icon } from '../../common/Icon/Icon';

import { sendUserInteraction } from '../../../utils/tagManager.utils';
import { isProjectProofread, resolveSelectedService } from '../../../utils/jobs.utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hasAdminPermissions } from 'utils/user.utils';
import { zindexPopperModifier } from 'constants/general';

const Job = ({ job, updateJob, project }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const handleChange = (e, item) => {
    sendUserInteraction('order project: job slider change');

    const newJob = { ...job };
    // first reset all selected services to 0
    newJob.service_1_selected = 0;
    newJob.service_2_selected = 0;
    newJob.service_3_selected = 0;
    newJob.service_4_selected = 0;
    newJob.service_5_selected = 0;
    // selected the new selected service
    newJob[`service_${item.id}_selected`] = 1;

    // if selected service is MT only, disable DTP for this job
    if (newJob.service_1_selected) {
      newJob.dtp_selected = 0;
    }
    // update job
    // setUpdatingJob(true);
    updateJob(newJob);
  };

  const selectedService = useMemo(() => {
    return resolveSelectedService(job);
  }, [job]);

  const jobName = hasAdminPermissions(user.role) ? (
    <a href={job.job_url} target="_blank" rel="noopener noreferrer">
      {job.target_language.name} <Icon name="external-link-alt" inline />
    </a>
  ) : (
    <span>{job.target_language.name}</span>
  );
  return (
    <div className="job">
      <TargetLanguage>
        <div className="flag__wrapper">
          <Flag className="flag" lang={job.target_language} />
        </div>
        <div className="language-details">
          <div className="lang-name">{jobName}</div>
          <div className="payable-words">
            {job.payable_words < 250 ? '250*' : job.payable_words} &nbsp;
            {t('common:projects.projectJobs.payableWords')}
            {selectedService !== 6 ? (
              <Popup trigger={<Icon name="info-circle" />} popperModifiers={[zindexPopperModifier]}>
                {`${t('common:projects.analyse.payableWordsDescription')} ${
                  job.payable_words < 250 ? t('common:projects.analyse.below250') : null
                }`}
              </Popup>
            ) : null}
          </div>
        </div>
      </TargetLanguage>
      <ServiceTypeDropdown
        key={job.id}
        onChange={handleChange}
        value={selectedService}
        job={job}
        currency={project.currency}
        isProofread={isProjectProofread(project)}
      />
    </div>
  );
};

const Jobs = ({ jobs, updateJob, project }) => {
  return (
    <LanguagesContainer id="order-project-jobs">
      {jobs.map((job, idx) => (
        <Job job={job} key={job.id} updateJob={updateJob} project={project} />
      ))}
    </LanguagesContainer>
  );
};

const TranslationType = ({ jobs, setStep, updateJob, project }) => {
  const [commentFiles, setCommentFiles] = useState([]);
  const [commentText, setCommentText] = useState('');
  const { t } = useTranslation();

  return (
    <div>
      <Jobs jobs={jobs} updateJob={updateJob} project={project} />
      <CommentSection>
        <h3> {t('common:projects.anythingToAdd')} </h3>
        <Comment
          project={project}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder={t('common:projects.commentPlaceholder')}
          files={commentFiles}
          onFilesChange={setCommentFiles}
          id="order-project-jobs-comment-input"
        />
      </CommentSection>
      <StyledButton
        data-cy="step-next-step-button"
        actiontype="primary"
        className="next-step-btn"
        onClick={() => setStep((prev) => prev + 1)}
      >
        {t('common:projects.nextStep')}
      </StyledButton>
    </div>
  );
};

export default TranslationType;
