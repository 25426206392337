import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Dropdown, Header, Label } from 'semantic-ui-react';
import Message from '../common/Message/Message';
import { Icon } from '../common/Icon/Icon';
import { SUBSCRIPTION_INTERVALS } from '../../constants/subscriptions';
import { getProduct, updateProduct } from '../../services/subscriptions';
import Button from '../common/Button/Button';
import LinkBack from '../common/LinkBack/LinkBack';
import LoadingPane from '../common/LoadingPane/LoadingPane';
import {
  AddPricingModal,
  EditPackageModal,
  PricingRow,
  SubscriptionDeletePrompt,
} from './AdminSubscriptionPackage.components';
import { SubscriptionPackageWrapper } from './AdminSubscriptionPackage.style';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { StyledLabel } from '../AdminSubscriptionPackages/AdminSubscriptionPackages.style';

const AdminSubscriptionPackage = () => {
  const [pack, setPackage] = useState({ plans: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const currencies = useSelector((state) => state.classifiersStore.currencies);
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        setIsLoading(true);
        const data = await getProduct(id);

        const newPlans = data.plans.map((plan) => {
          return { ...plan, amount: plan.amount * plan.conversion_rate.conversion_rate };
        });

        data.plans = [...newPlans];
        setPackage(data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPackage();
  }, [id, currencies]);

  const updatePack = (p) => {
    const newPack = { ...pack };
    newPack.word_limit = p.word_limit;
    newPack.private = p.private;
    newPack.name = p.name;

    setPackage(newPack);
  };

  const activatePackage = async (acitve) => {
    try {
      setIsSubmiting(true);
      await updateProduct({ id, active: acitve, name: pack.name, privatePlan: pack.private });
      const newPack = { ...pack };
      newPack.active = acitve;
      setPackage(newPack);
      toast.success(t('common:toasts.updatePackage.success'));
    } catch (e) {
      toast.error(t('common:toasts.updatePackage.error'));
      console.error(e);
    } finally {
      setIsSubmiting(false);
    }
  };

  const removePricing = (idx) => {
    sendUserInteraction('remove package pricing click');
    const newPack = { ...pack };
    newPack.plans = [...pack.plans];
    newPack.plans.splice(idx, 1);

    setPackage(newPack);
  };

  const addPricing = (pricing) => {
    sendUserInteraction('add package pricing click');
    const newPack = { ...pack };
    const newPlan = { ...pricing, amount: pricing.amount * pricing.conversion_rate.conversion_rate };
    newPack.plans = [...pack.plans, newPlan];

    setPackage(newPack);
  };

  const pricingRows = pack.plans.map((plan, idx) => {
    return (
      <PricingRow key={plan.id} active={pack.active} pricing={plan} onDelete={() => removePricing(idx)} />
    );
  });

  return isLoading ? (
    <LoadingPane />
  ) : (
    <SubscriptionPackageWrapper>
      <LinkBack
        onClick={() => {
          sendUserInteraction('admin packages link click');
        }}
        text="Packages"
        to="/admin/packages"
      ></LinkBack>
      <div className="subscription-header">
        <Header as="h1">{pack.name}</Header>
        <div className="subscription-labels">
          <StyledLabel active={pack.active}>
            {pack.active ? t('common:admin.package.active') : t('common:admin.package.inactive')}
          </StyledLabel>
          {pack.private ? <Label circular>{t('common:admin.package.private')}</Label> : null}
        </div>
        {pack.active === 0 ? (
          <div className="subscription-menu">
            <Dropdown direction="left" icon={<Icon name="ellipsis-v" inElement />}>
              <Dropdown.Menu>
                <EditPackageModal pack={pack} updatePack={updatePack} />
                <SubscriptionDeletePrompt pack={pack} />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
      </div>
      <div className="plan-information">
        <Header as="h2">{t('common:admin.package.planInformation')}</Header>
        <div className="plan-field">
          <div className="field-label">{t('common:admin.package.wordLimit')}</div>
          <div className="field-content">
            <span>
              {pack.word_limit} {t('common:admin.package.words')}
            </span>
          </div>
        </div>
        <div className="plan-field">
          <div className="field-label">{t('common:admin.package.activeSubscribers')}</div>
          <div className="field-content">
            <span>
              {pack.subscribers ? pack.subscribers : 0} {t('common:admin.package.subscribers')}
            </span>
          </div>
        </div>
      </div>
      <div className="pricing">
        <Header as="h2">{t('common:admin.package.pricing')}</Header>
        {pack.plans.length < currencies.length * SUBSCRIPTION_INTERVALS.length && (
          <Message text={t('common:admin.package.pricingWarning')} type="warning" />
        )}
        {pricingRows}
        {pack.plans.length < currencies.length * SUBSCRIPTION_INTERVALS.length && (
          <AddPricingModal
            productId={pack.id}
            pricings={pack.plans}
            updatePricing={addPricing}
          ></AddPricingModal>
        )}
      </div>
      {pack.active ? (
        <Button
          actiontype="cancel"
          onClick={() => {
            sendUserInteraction('deactivate package click');
            activatePackage(0);
          }}
          disabled={isSubmiting}
          loading={isSubmiting}
        >
          {t('common:admin.package.deactivatePackage')}
        </Button>
      ) : (
        <Button
          actiontype="primary"
          onClick={() => {
            sendUserInteraction('activate package click');
            activatePackage(1);
          }}
          disabled={isSubmiting}
          loading={isSubmiting}
        >
          {t('common:admin.package.activatePackage')}
        </Button>
      )}
    </SubscriptionPackageWrapper>
  );
};

export default AdminSubscriptionPackage;
