import React, { Component } from 'react';
import { Embed, Container, Grid, Header } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { sendUserInteraction } from 'utils/tagManager.utils';

import { MainContainer } from 'components/common.styles';

class Tutorial extends Component {
  render() {
    const { t } = this.props;
    return (
      <MainContainer>
        <Container>
          <Grid padded="vertically" stackable columns="equal" verticalAlign="middle" centered>
            <Grid.Column>
              <Header as="h2">{t('common:videoTutorial')}</Header>
              <Embed
                active={true}
                id="XdlTlArW9X8"
                hd={true}
                icon="university"
                onClick={() => sendUserInteraction('tutorial page embedded video click')}
                source="youtube"
              />
            </Grid.Column>
          </Grid>
        </Container>
      </MainContainer>
    );
  }
}

export default withTranslation('common')(Tutorial);
