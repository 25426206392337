import React from 'react';
import ProgressBarsStackedSingleBar from './ProgressBarsStackedSingleBar';

// Styles
import './style.scss';

const ProgressBarsStacked = ({ values = [], total = { amount: 100, label: 'No total provided' } }) => {
  const calcRenderValues = () => {
    // Go over values and subtract from total.
    // Store previous amount to set the bar's left position
    let offsetLeft = 0;
    let currentTotal = total.amount;

    const renderVals = [];

    for (let i = 0; i < values.length; i++) {
      const value = values[i];

      const width = Math.round((value.amount / total.amount) * 100);

      // Check if width can be displayed, if it cant skip the value
      currentTotal = currentTotal - value.amount;

      if (currentTotal < 0) {
        continue;
      }

      const final = {
        ...value,
        left: offsetLeft,
        width,
      };

      offsetLeft = offsetLeft + width;

      renderVals.push(final);
    }

    // Check if total should be shown as remaining
    if (currentTotal > 0) {
      renderVals.push({
        ...total,
        left: offsetLeft,
        width: Math.round((currentTotal / total.amount) * 100),
      });
    }

    return renderVals;
  };

  const renderValues = calcRenderValues();

  return (
    <div className="progress-bar-stacked">
      <div className="progress-bar">
        <div className="bars-wrapper">
          {renderValues.map((value, idx) => (
            <ProgressBarsStackedSingleBar key={`progress-bar-stacked-${idx}`} {...value} />
          ))}
        </div>
      </div>
      <div className="legend">
        {renderValues
          // First filter out the values that have 0 as amount,
          // don't display those in the legend, those won't be shown as a bar, no need for the user
          .filter((value) => value.amount !== 0)
          // Render remaining labels for bars
          .map(({ color, label }, idx) => (
            <div key={`color-label-${idx}`} className="color-label">
              <span style={{ '--box-bg-color': color }}></span>
              <p>{label}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProgressBarsStacked;
