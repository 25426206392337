import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { sizes } from 'style/theme';

const sidebarVariants = {
  collapsed: {
    width: sizes.sidebar.collapsed,
  },
  expanded: {
    width: sizes.sidebar.expanded,
  },
};

export const SidebarContainer = styled(motion.div).attrs((props) => ({
  initial: props.$isCollapsed ? 'collapsed' : 'expanded',
  variants: sidebarVariants,
}))`
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background-color: ${({ theme }) => theme.colors.blueLight};
  color: black;
  line-height: 1.25em;
  font-family: 'DM Sans', sans-serif;

  a {
    width: 100%;
    color: black;
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaMaxWidth.HD`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    width: unset !important;
    position: initial;
    height: initial;
  `}
`;

export const SidebarHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.blueLight};

  .page-title__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: 12px;

    .page-title {
      width: 100%;
    }
  }

  .page-title {
    margin-left: 0;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .menu-icon {
      padding: 0 16px;
      height: 100%;
    }

    > a {
      width: fit-content;
    }
  `}
`;

export const Brand = styled.img`
  height: 100%;
`;

export const BrandMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 13px ${({ theme }) => theme.paddings.mainContainer.mobile.x};

  img {
    width: 38px;
    height: 43px;
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
    height: 63px;
    padding: 1em;
    padding-right: 0;

    img {
      width: auto;
      height: 100%;
    }

    :hover {
      background-color: #e3f3f7;
    }
  `}
`;

export const textVariants = {
  hidden: { opacity: 0 },
  shown: { opacity: 1 },
};

export const HidingText = styled(motion.div).attrs((props) => ({
  initial: props.$isCollapsed ? 'hidden' : 'shown',
  variants: textVariants,
}))`
  margin-left: calc(18px + 1em);
  position: absolute;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: 1;
  min-height: 0;
  z-index: -1;
  transition: transform 0s;
  will-change: transform;

  ${({ theme, isMobileCollapsed }) => theme.mediaMaxWidth.mobile`
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    transform: ${isMobileCollapsed ? 'translateY(-100%)' : 'translateY(0)'};
    transition: transform 0.5s ease;
    max-height: calc(100% - 63px);
  `}
`;

export const MenuContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export const MenuItem = styled.div`
  background-color: ${({ highlighted, theme }) => (highlighted ? '#e3f3f7' : theme.colors.blueLight)};
  text-decoration: none;
  cursor: pointer;
  padding: 1em 0.5em 1em 1em;
  font-size: 16px;

  :hover {
    background-color: #e3f3f7;
  }
`;

export const MainMenuText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  svg {
    font-size: 22px;
  }
`;

export const SubMenuItem = styled.div`
  background-color: ${({ highlighted, theme }) => (highlighted ? '#e3f3f7' : theme.colors.blueLight)};
  color: ${(props) => (props.active ? props.theme.colors.blue : 'black')};
  padding: 11px 1em 11px calc(16px + 15px);
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: #e3f3f7;
  }

  ${({ isTeam, active }) =>
    isTeam &&
    css`
      .icon {
        opacity: ${active ? '1' : '0'};
        transition: opacity 0.2s ease;
      }
      &:hover {
        background-color: ${({ theme }) => theme.colors.blueLight};
        .icon {
          opacity: 1;
        }
      }
    `}
`;

export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.$isCollapsed ? 'center' : 'flex-start')};
  background-color: ${({ theme }) => theme.colors.blueLight};

  .user-container {
    display: flex;
    column-gap: 12px;
    padding: 22px;
    width: 100%;
    transition: padding 0.2s ease, background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: #e3f3f7;
    }

    .user-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      transition: transform 0.2s ease;
      object-fit: cover;
    }

    .user-data {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .username,
      .email {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .username {
        max-width: 10ch;
        font-size: 16px;
      }
      .email {
        max-width: 13ch;
        font-size: 13px;
        opacity: 0.5;
      }

      ${({ theme }) => theme.mediaMaxWidth.mobile`
        .email,
        .username {
          max-width: unset;
        }
      `}
    }
  }

  .support-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .icon {
      opacity: 0;
    }
  }

  &.collapsed {
    .user-container {
      padding: 0;
      .user-avatar {
        transform: scale(0.5);
      }

      .user-data {
        opacity: 0;
        pointer-events: none;
      }
    }

    .support-text {
      opacity: 0;
    }
  }
`;

export const CollapseButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blueLight};
  position: absolute;
  bottom: -15px;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #e4f4f8;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    visibility: hidden;
  `}
`;

export const Divider = styled.div`
  position: relative;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    width: 80%;
    margin: 0 10%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

export const Background = styled.div`
  position: absolute;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
  opacity: ${({ show }) => (show ? '0.2' : '0')};
  transition: opacity 0.3s ease;
  will-change: opacity;
`;
