import React, { memo } from 'react';
import { useCompanyDispatch, useCompanyState } from '../Company.state';

import { InvitesLayout } from './CompanyInvites.style';
import InvitesTable from './InvitesTable/InvitesTable';

import LoadingPane from 'components/common/LoadingPane/LoadingPane';

const CompanyInvites = () => {
  const dispatch = useCompanyDispatch();
  const {
    invites: { loading, data: invites },
  } = useCompanyState();

  const deleteInvite = (inviteId) => {
    const newInvites = invites.filter((stateInvite) => stateInvite.id !== inviteId);
    dispatch({ type: 'SET_INVITES', payload: newInvites });
  };

  return (
    <InvitesLayout>
      {loading ? (
        <LoadingPane height={'260px'} />
      ) : (
        <InvitesTable deleteInvite={deleteInvite} invites={invites} />
      )}
    </InvitesLayout>
  );
};

export default memo(CompanyInvites);
