import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledLabel = styled(Label)`
  margin: 0 calc(0.14285714em * 2) calc(0.14285714em * 2) 0 !important;
`;

const TargetLanguages = ({ languages }) => {
  const { t } = useTranslation();

  return languages ? (
    languages.length > 0 ? (
      languages.map((lang) => (
        <StyledLabel key={lang.code} size="small">
          {t(`languages:${lang.code}`, lang.name)}
        </StyledLabel>
      ))
    ) : (
      <StyledLabel key={languages.code} size="small">
        {t(`languages:${languages.code}`, languages.name)}
      </StyledLabel>
    )
  ) : null;
};

export default TargetLanguages;
