import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Input, PasswordInput } from '../common/Input/Input';
import Loader from '../common/Loader/Loader';
import Message from '../common/Message/Message';
import { StyledButton, StyledHeader } from '../common.styles';
import { StyledCard, InnerContainer, StyledForm } from 'components/SignIn/SignIn.styles';
import PublicWrapper from '../PublicWrapper/PublicWrapper';

import { getPasswordResetToken, postPasswordReset } from '../../services/auth';
import { toast } from 'react-toastify';
import PasswordValidator, { StyledLi } from '../common/PasswordValidator/PasswordValidator';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useRecaptchaV3 } from 'hooks/useRecaptcha';

const PasswordReset = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingToken, setIsCheckingToken] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [retypePassword, setRetypePassword] = useState('');
  const [showInputs, setShowInputs] = useState(true);

  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();

  const { generateToken } = useRecaptchaV3();

  const checkResetToken = useCallback(async () => {
    try {
      const { data } = await getPasswordResetToken(params.token);
      setEmail(data);
    } catch (e) {
      setError(t('common:passwordReset.errors.invalidToken'));
      setShowInputs(false);
    } finally {
      setIsCheckingToken(false);
    }
  }, [params.token, t]);

  useEffect(() => {
    checkResetToken();
  }, [checkResetToken]);

  const handleSubmit = async (e) => {
    sendUserInteraction('sending password reset');
    e.preventDefault();

    try {
      setIsLoading(true);
      const token = params.token;
      const captcha = await generateToken();
      await postPasswordReset({ email, password, token, captcha });
      //await postLogin({ email, password, captcha });
      toast.success(t('common:toasts.resetPassword.success'));
      history.push('/');
    } catch (error) {
      if (!error.response) {
        setError(t('common:errors.noConnection'));
      } else if (error.response.status === 401) {
        setError(t('common:passwordReset.errors.invalidEmail'));
      } else if (error.response.status === 422) {
        setError(t('common:signIn.errors.recaptchaInvalid'));
      } else {
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const canSubmit = () => {
    return !error && isPasswordValid && !isLoading && !isCheckingToken && password === retypePassword;
  };

  return (
    <PublicWrapper>
      <StyledCard>
        <InnerContainer>
          <StyledHeader>{t('common:passwordReset.resetPassword')}</StyledHeader>
          {error && <Message text={error} type="error" />}
          {isCheckingToken ? (
            <Loader inline />
          ) : (
            showInputs && (
              <StyledForm onSubmit={handleSubmit}>
                <Input
                  disabled
                  value={email || ''}
                  label={t('common:signUp.labels.yourEmail')}
                  type="email"
                />
                <div>
                  <PasswordInput
                    disabled={!!error}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password || ''}
                    label={t('common:signUp.labels.password')}
                  />
                  <PasswordValidator password={password} setIsValid={setIsPasswordValid} />
                </div>
                <div>
                  <PasswordInput
                    disabled={!!error}
                    onChange={(e) => setRetypePassword(e.target.value)}
                    value={retypePassword || ''}
                    label={t('common:signUp.labels.confirmPassword')}
                  />
                  <StyledLi
                    valid={password === retypePassword && password.length > 0}
                    style={{ padding: '8px 0 0 30px' }}
                  >
                    {t('common:signUp.errors.password.passwordsMatch')}
                  </StyledLi>
                </div>
                <StyledButton
                  marginBottom="0px"
                  disabled={!canSubmit()}
                  actiontype="submit"
                  loading={isLoading}
                  big
                  type="submit"
                >
                  {t('common:passwordReset.resetPassword')}
                </StyledButton>
              </StyledForm>
            )
          )}
        </InnerContainer>
      </StyledCard>
    </PublicWrapper>
  );
};

export default PasswordReset;
