import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../common/Button/Button';
import { Container, StyledInput } from './PaidSubscriptionsFilter.styles';

import { INITIAL_REQUEST_PARAMS } from './PaidSubscriptions.constants';

const FreeSubscriptionsFilter = ({ requestParams = INITIAL_REQUEST_PARAMS, updateRequestParams }) => {
  const { t } = useTranslation();

  const handleSearchParamChange = ({ propertyName, value }) => {
    const newRequestParams = {
      ...requestParams,
      [propertyName]: value,
    };
    updateRequestParams(newRequestParams);
  };

  const handleSearchChange = (e, { value }) => {
    e.preventDefault();
    handleSearchParamChange({ propertyName: 'search', value: !!value ? value.trim() : null });
  };

  const resetParams = () => {
    updateRequestParams({ ...INITIAL_REQUEST_PARAMS });
  };

  return (
    <Container>
      <p>{`${t('common:catapult.admin.paidSubscriptionsTable.searchField')}:`}</p>
      <StyledInput onChange={handleSearchChange} value={requestParams.search || ''} />
      <Button onClick={resetParams}>{t('common:catapult.admin.paidSubscriptionsTable.resetFilter')}</Button>
    </Container>
  );
};

export default FreeSubscriptionsFilter;
