import React from 'react';
import { withTranslation } from 'react-i18next';
import Table from '../../../common/Table/Table';
import TeamInviteRow from './TeamInviteRow';

const TeamInvitesTable = ({ t, invites, deleteInvite }) => {
  const tableRows =
    invites.length === 0 ? (
      <Table.Row>
        <Table.Cell colSpan="4" textAlign="center">
          {t('common:team.invites.noInvites')}
        </Table.Cell>
      </Table.Row>
    ) : (
      invites.map((invite, idx) => {
        return <TeamInviteRow deleteInvite={(id) => deleteInvite(id, idx)} key={invite.id} invite={invite} />;
      })
    );

  return (
    <Table width="100%" marginTop="0">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('common:team.invites.user')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invites.role')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invites.invitedOn')}</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{tableRows}</Table.Body>
    </Table>
  );
};

export default withTranslation('common')(TeamInvitesTable);
