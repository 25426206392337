import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Header } from 'semantic-ui-react';
import { sendUserInteraction } from 'utils/tagManager.utils';
import Button from '../../common/Button/Button';
import { NotFoundContainer } from './OrderNotFound.style';

const OrderNotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <NotFoundContainer>
      <Header as="h1">{t('common:checkout.notFound')}</Header>
      <div className="sub-header">{t('common:checkout.notFoundDecription')}</div>
      <Button
        actiontype="primary"
        onClick={() => {
          sendUserInteraction('clicked back to homepage on order not found');
          history.push('/');
        }}
      >
        {t('common:checkout.backToHomepage')}
      </Button>
    </NotFoundContainer>
  );
};

export default OrderNotFound;
