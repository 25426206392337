import { updateVendorData } from 'services/vendors';

/**
 * Helper function to submit updated vendor data
 * Compares initialFormData and currentFormData and submits differences
 */
export const handleUpdateVendor = async ({ vendor, formInitial, formCurrent }) => {
  try {
    const differences = {};

    for (const currKey in formCurrent) {
      const initialValue = formInitial[currKey];
      const currValue = formCurrent[currKey];

      if (initialValue !== currValue) {
        differences[currKey] = currValue;
      }
    }

    const response = await updateVendorData({
      id: vendor.id,
      data: differences,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};
