import React, { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

import { Dropdown } from 'semantic-ui-react';
import { Icon } from '../../common/Icon/Icon';

import { refreshJobTmx, getJobTmx, postUploadTmxFile } from '../../../services/project';

const TmxDropdown = ({ jobId, tmxUrl, updateJob }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const { t } = useTranslation();

  const handleRefreshClick = async (e) => {
    setIsUpdating(true);
    try {
      const response = await refreshJobTmx(jobId);
      updateJob(response);
      toast.success(t('common:toasts.refreshTmx.success'));
    } catch (e) {
      toast.error(t('common:toasts.refreshTmx.error'));
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDownloadClick = async (e) => {
    setIsUpdating(true);
    try {
      const response = await getJobTmx(jobId);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setIsUpdating(false);
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    setFilesToUpload(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (filesToUpload.length === 0) {
      return;
    }
    if (filesToUpload.length > 1) {
      toast.error(t('common:toasts.uploadTmx.tooManyFiles'));
      setFilesToUpload([]);
    }
    if (filesToUpload.length === 1) {
      // upload file
      const uploadFile = async () => {
        setIsUpdating(true);
        try {
          await postUploadTmxFile({ jobId, file: filesToUpload[0] });
          toast.success(t('common:toasts.uploadTmx.success'));
        } catch (e) {
          toast.error(t('common:toasts.uploadTmx.error'));
        } finally {
          setIsUpdating(false);
        }
      };
      uploadFile();
    }
  }, [filesToUpload, jobId, t]);

  return (
    <Dropdown
      button
      className="ui circular icon button"
      closeOnBlur
      closeOnChange
      closeOnEscape
      direction="left"
      loading={isUpdating}
      icon={<Icon name="ellipsis-v" inElement />}
    >
      <Dropdown.Menu>
        {tmxUrl && (
          <Dropdown.Item disabled={isUpdating} onClick={handleDownloadClick}>
            {t('common:admin.project.jobs.downloadTmx')}
          </Dropdown.Item>
        )}
        <Dropdown.Item disabled={isUpdating} onClick={handleRefreshClick}>
          {t('common:admin.project.jobs.refreshTmx')}
        </Dropdown.Item>
        <Dropdown.Item {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div onClick={open}>{t('common:admin.project.jobs.uploadTmx')}</div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TmxDropdown;
