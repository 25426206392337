import { getNameAndExtension } from 'utils/filename.utils';
import i18n from '../../../i18n';

const errorTexts = {};

const updateErrorTexts = () => {
  errorTexts['file-invalid-type'] = i18n.t('common:fileUpload.errors.fileInvalidType');
  errorTexts['file-too-large'] = i18n.t('common:fileUpload.errors.fileTooLarge');
  errorTexts['file-too-small'] = i18n.t('common:fileUpload.errors.fileTooSmall');
  errorTexts['too-many-files'] = i18n.t('common:fileUpload.errors.tooManyFiles');
};

updateErrorTexts();

// Future-proof, on language change update error texts
i18n.on('languageChanged init', () => {
  updateErrorTexts();
});

export { errorTexts };

export const buildFileUploadError = (fileRejections) => {
  return (
    fileRejections
      .map((rejected) => {
        const firstpart = 'Error while uploading file: ';
        const fileName = rejected.file.name;
        const reasons = rejected.errors
          .map((error) => errorTexts?.[error.code])
          // Filter out undefined
          .filter(Boolean);

        return firstpart + fileName + (reasons.length > 0 ? ', ' + reasons.join(', ') : '');
      })
      // Join with new lines and space between messages
      .join('\n \n')
  );
};

export const renameDuplicateFiles = (files) => {
  const namesCount = {};
  const nameWithNum = (name, number) => name + '(' + number + ')';

  return files.map(function (file) {
    const { name: filename, extension } = getNameAndExtension(file.name);
    let counter = namesCount[filename] || 0;

    namesCount[filename] = counter + 1;

    if (!counter) return new File([file], `${filename}.${extension}`, { type: file.type });

    while (namesCount[nameWithNum(filename, counter)]) counter++;

    namesCount[nameWithNum(filename, counter)] = 1;

    return new File([file], `${nameWithNum(filename, counter)}.${extension}`, { type: file.type });
  });
};
