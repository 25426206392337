import React, { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'utils/storage.utils';
import { localStorageKeyAndFields, validateFieldsByName } from '../../VendorOnboarding.utils';
import { useDebounce } from 'hoc/debouncer';
import { useTranslation } from 'react-i18next';
import ProfessionalExperienceForm from 'components/common/Vendor/Forms/ProfessionalExperienceForm/ProfessionalExperienceForm';

// Components
import StepsControls from '../../StepsControls/StepsControls';

// Styles
import './style.scss';

const { requiredFields, localStorageKey } = localStorageKeyAndFields[2];

/**
 * Render upload attachments form
 */
const UploadAttachments = ({ stepsContext, userCV, userAdditionalFiles, submitVendorData, loading }) => {
  const { complete, uncomplete, completed } = stepsContext;

  // Form data
  const [formData, setFormData] = useLocalStorage(localStorageKey, {
    linkedin: '',
    education: '',
    experience: '',
  });

  // Hooks
  const { debounce } = useDebounce();
  const { t } = useTranslation();

  // Is step completed
  const isComplete = completed.includes(2);

  /**
   * Validate required fields
   */
  const validateRequiredFields = useCallback(
    (fieldsData) => {
      debounce(
        'validateRequiredFieldsAttachments',
        () => {
          let requiredValid = validateFieldsByName(requiredFields, fieldsData);

          const stepCompleted = completed.includes(2);

          if (requiredValid && !(userCV.get instanceof File)) requiredValid = false;

          if (requiredValid) {
            if (!stepCompleted) complete(2);
          } else if (stepCompleted) uncomplete(2);
        },
        300,
      );
    },
    [completed, complete, uncomplete, debounce, userCV],
  );

  const handleFormChange = useCallback(
    ({ linkedin, education, experience, cv, additionalFiles }) => {
      // Storing files
      if (cv) {
        userCV.set(cv);
      }

      if (additionalFiles) {
        userAdditionalFiles.set(additionalFiles);
      }

      // Store only text fields to local storage
      setFormData({
        linkedin,
        education,
        experience,
      });
    },
    [setFormData, userCV, userAdditionalFiles],
  );

  // Effects
  // Validate form data
  useEffect(() => {
    validateRequiredFields(formData);
  }, [formData, validateRequiredFields]);

  // Render variables
  const title = t('common:serviceProvider.onboarding.steps.stepThree.title');
  const description = t('common:serviceProvider.onboarding.steps.stepThree.description');
  const nextStepLabel = t('common:serviceProvider.onboarding.steps.stepThree.nextStepLabel');

  return (
    <section className="steps-content form-step">
      <header>
        <h2>{title}</h2>
        <p>{description}</p>
      </header>

      <div className="form vendor-onboarding-step-three-form">
        <ProfessionalExperienceForm data={formData} onChange={handleFormChange} />

        <StepsControls
          isComplete={isComplete}
          stepsContext={stepsContext}
          nextStepLabel={nextStepLabel}
          submitVendorData={submitVendorData}
          loading={loading}
        />
      </div>
    </section>
  );
};

export default UploadAttachments;
