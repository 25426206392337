import styled from 'styled-components';
import { StyledHeader } from '../../NewProject.styles';
import { SelectGroup } from '../../../common/SelectGroup/SelectGroup';

export const CategoriesContainer = styled.div`
  margin-bottom: 1rem;
  padding: 25px 30px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 280px;
  overflow-y: auto;
  font-size: 16px;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #cccccc;
  background-color: #f6f6f6;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const IconContainer = styled.div`
  width: 2.1rem;
  height: 2.1rem;
  padding: 0;
  margin: 0;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ color }) => color};
  border-radius: 50%;
`;

export const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  max-width: 250px;
  color: ${({ color }) => color};
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  cursor: pointer;
  min-height: min-content;

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledHeading = styled(StyledHeader)`
  margin-bottom: 4px;
`;

export const StyledSelectGroup = styled(SelectGroup)`
  margin-top: 16px;
  button {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;
