import Card from 'components/common/Card/Card';
import { Icon } from 'components/common/Icon/Icon';
import React from 'react';
import { classnames } from 'utils/base.utils';

const CustomerTypeCard = ({ onSelect, index, selected, icon, text }) => {
  const handleClick = () => {
    onSelect(index);
  };

  const className = classnames('customer-type-card', { selected });

  // Render variables
  const cardProps = {
    offsetBorder: false,
    shadow: true,
    className,
  };

  return (
    <Card {...cardProps}>
      <button className="button-reset" onClick={handleClick}>
        <Icon {...icon} />
        <div className="customer-type-text">{text}</div>
      </button>
    </Card>
  );
};

export default CustomerTypeCard;
