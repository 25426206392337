import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'utils/storage.utils';
import { localStorageKeyAndFields, validateFieldsByName } from '../../VendorOnboarding.utils';
import { useDebounce } from 'hoc/debouncer';

// Components
import { Divider } from 'semantic-ui-react';
import Button from 'components/common/Button/Button';
import SectionDescription from 'components/common/SectionDescription/SectionDescription';
import { Icon } from 'components/common/Icon/Icon';
import { Input } from 'components/common/Input/Input';
import UserAvatar from 'components/common/UserAvatar/UserAvatar';
import StepsControls from '../../StepsControls/StepsControls';
import Dropzone from 'react-dropzone';
import VendorSettingsForm from 'components/common/Vendor/Forms/VendorSettingsForm/VendorSettingsForm';

// Styles
import './style.scss';

// Get required fields and localStorageKey for step 1
const { requiredFields, localStorageKey } = localStorageKeyAndFields[0];

const BasicInfo = ({ stepsContext, vendorProfileImage }) => {
  const { complete, uncomplete, completed } = stepsContext;

  // Global state
  const user = useSelector((state) => state.userStore.user);

  // Form data
  const [formData, setFormData] = useLocalStorage(localStorageKey, {
    name: user.name ?? '',
    phone: user.phone_number ?? '',
    native_lang_id: '',
    description: '',
    timezone: '',
    country_id: '',
  });

  // Hooks
  const { t } = useTranslation();
  const { debounce } = useDebounce();

  // Functions
  /**
   * Validate and update local form state
   * useCallback so it doesn't recreate the method on every render
   */
  const handleInputChange = useCallback(
    (_, data) => {
      // If value and name exists in data, we are looking at single input
      if (data?.name && typeof data?.value === 'string') {
        setFormData((c) => ({
          ...c,
          [data.name]: data.value,
        }));
      } else {
        setFormData((c) => ({
          ...c,
          ...data,
        }));
      }
    },
    [setFormData],
  );

  const handleUploadPhoto = async (pic) => {
    if (pic.length > 0) vendorProfileImage.set(pic[0]);
  };

  /**
   * Validate required fields
   */
  const validateRequiredFields = useCallback(
    (fieldsData) => {
      debounce(
        'validateRequiredFields',
        () => {
          const requiredValid = validateFieldsByName(requiredFields, fieldsData);
          const stepCompleted = completed.includes(0);

          if (requiredValid) {
            if (!stepCompleted) complete(0);
          } else if (stepCompleted) uncomplete(0);
        },
        300,
      );
    },
    [completed, complete, uncomplete, debounce],
  );

  // Effects
  /**
   * Validation of fields on formData change
   */
  useEffect(() => {
    validateRequiredFields(formData);
  }, [formData, validateRequiredFields]);

  // Render variables
  const title = t('common:serviceProvider.onboarding.steps.stepOne.title');
  const description = t('common:serviceProvider.onboarding.steps.stepOne.description');
  const nextStepLabel = t('common:serviceProvider.onboarding.steps.stepOne.nextStepLabel');

  // Inputs texts
  /**
   * Take array of input names (same key is used in i18n under serviceProvider.onboarding.steps.stepOne.inputs)
   * Map over it, for each name create key value pair:
   * [
   *    inputName,
   *    {
   *      title: '',
   *      description: '',
   *      placeholder: '',
   *    }
   * ]
   * and create an object using Object.fromEntries
   */
  const inputsTexts = Object.fromEntries(
    ['name', 'profilePhoto', 'phone'].map((inputName) => [
      inputName,
      {
        title: t(`common:serviceProvider.forms.inputs.${inputName}.title`),
        description: t(`common:serviceProvider.forms.inputs.${inputName}.description`),
        placeholder: t(`common:serviceProvider.forms.inputs.${inputName}.placeholder`),
      },
    ]),
  );

  const isComplete = completed.includes(0);

  const userAvatarSource =
    vendorProfileImage.get instanceof File
      ? URL.createObjectURL(vendorProfileImage.get)
      : vendorProfileImage.get;

  const vendorSettingsFormFields = {
    native_lang_id: formData.native_lang_id,
    description: formData.description,
    timezone: formData.timezone,
    country_id: formData.country_id,
  };

  return (
    <section className="form-step basic-info-step">
      <header>
        <h2>{title}</h2>
        <p>{description}</p>
      </header>

      <div className="form">
        <SectionDescription
          title={`${inputsTexts.name.title} *`}
          description={inputsTexts.name.description}
          content={
            <Input
              name="name"
              onChange={handleInputChange}
              value={formData.name}
              placeholder={inputsTexts.name.placeholder}
            />
          }
        />

        <Divider />

        <SectionDescription
          title={inputsTexts.profilePhoto.title}
          description={inputsTexts.profilePhoto.description}
          content={
            <div className="profile-photo-input">
              <UserAvatar src={userAvatarSource} />
              <Dropzone
                accept={['image/jpeg', 'image/png', 'image/bmp', 'image/gif']}
                onDrop={handleUploadPhoto}
                maxSize={1_000_000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button labelPosition="left">
                      <Icon name="upload" />
                      <span>{inputsTexts.profilePhoto.placeholder}</span>
                    </Button>
                  </div>
                )}
              </Dropzone>
            </div>
          }
        />

        <Divider />

        <SectionDescription
          title={inputsTexts.phone.title}
          description={inputsTexts.phone.description}
          content={
            <Input
              name="phone"
              onChange={handleInputChange}
              value={formData.phone}
              placeholder={inputsTexts.phone.placeholder}
              type="number"
            />
          }
        />

        <Divider />

        <VendorSettingsForm onChange={handleInputChange} initialFormData={vendorSettingsFormFields} />

        <StepsControls isComplete={isComplete} stepsContext={stepsContext} nextStepLabel={nextStepLabel} />
      </div>
    </section>
  );
};

export default BasicInfo;
