import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CountriesDropdown = ({ country = undefined, disabled = false, updateProperty }) => {
  const countries = useSelector((state) => state.classifiersStore.countries);
  const { t } = useTranslation();
  const countriesOptions = countries.map((country) => {
    return {
      key: country.id,
      text: country.name,
      value: country.id,
      check_vat: country['check_vat'],
    };
  });

  const handleCountryChange = (e, { value }) => {
    updateProperty({ name: 'country', value: value }); // returns the whole country object
  };

  return (
    <SelectDropdown
      fluid={true}
      disabled={disabled}
      name="country"
      value={country?.value || country?.id || 0}
      label={t('common:country')}
      onChange={handleCountryChange}
      options={countriesOptions}
      selection
      search
      required
      emptyState={t('common:noCountry')}
      placeholder={t('common:country')}
      wholeObj={true}
    />
  );
};

export default CountriesDropdown;
