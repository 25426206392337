import { Header, Progress, Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledHeader = styled(Header)`
  margin: 0 !important;
`;

export const StyledTableHeaderCell = styled(Table.HeaderCell)`
  &.border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  &.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
`;

export const StyledTableCell = styled(Table.Cell)``;

export const StyledProgress = styled(Progress)`
  margin: 0 !important;
`;
