import React from 'react';
import styled from 'styled-components';

const MenuIconWrapper = styled.div`
  padding: 10px 14px;
  border-inline: ${({ theme }) => theme.paddings.mainContainer.mobile.x} solid transparent;
  border-block: 1.5em solid transparent;
  position: relative;
  cursor: pointer;
  visibility: hidden;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    visibility: visible;

    // Safari, border-inline and border-block don't work in safari 14.x
    margin-right: ${theme.paddings.mainContainer.mobile.x};
    border-inline-end: none;
  `}

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: top, left, width, transform;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }

  &.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }

  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
`;

const MenuIcon = ({ open, className, ...rest }) => (
  <MenuIconWrapper className={open ? ' open' : ''} {...rest}>
    <span />
    <span />
    <span />
    <span />
  </MenuIconWrapper>
);

export default MenuIcon;
