import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-size: 14px;
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const InputGroup = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 16px;
  grid-template-columns: ${(props) => {
    return props.columns || '1fr 1fr';
  }};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 14px;
  width: ${(props) => (props.customwidth ? props.customwidth : '100%')};
  margin-bottom: 1em;
  .input {
    width: 100% !important;
    input {
      box-shadow: none !important;
    }
  }
  .selection {
    width: 100% !important;
  }
  .currency-reason {
    width: 100% !important;
    color: #c4c4c4;
  }
`;

export const InputLabel = styled.label`
  font-size: 13px;
  margin-bottom: 2px;
  color: #000;
`;

export const InputLabelAsterix = styled.span`
  color: ${({ theme }) => theme.colors.red};
`;

export const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .button {
    margin-right: 16px !important;
  }
  .button:last-child {
    margin-right: 0px !important;
  }
`;
