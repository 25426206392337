import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import Message from '../../Message/Message';

import { getUser, postProfileUpdate } from '../../../../services/auth';
import { postTmSettings } from '../../../../services/users';
import { setUser } from '../../../../store/userSlice';
import { toast } from 'react-toastify';
import Button from '../../Button/Button';
import ShareToggle from './ShareToggle';
import { checkAllowedRole } from '../../../../utils/user.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

class UserInfoForm extends Component {
  state = {
    description: this.props.user.description,
    isSubmitting: false,
    isError: false,
    phoneNumber: this.props.user.phone_number,
    userName: this.props.user.name,
    website: this.props.user.website,
    subscription: this.props.user.subscription,
    companyNameError: false,
    websiteError: false,
    descriptionError: false,
    shareTm: {},
  };

  componentDidMount() {
    this.setState({
      companyName: this.props.user.company,
      description: this.props.user.description,
      isSubmitting: false,
      phoneNumber: this.props.user.phone_number,
      userName: this.props.user.name,
      website: this.props.user.website,
      subscription: this.props.user.subscription,
      shareTm: this.props.shareTm,
    });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      companyNameError: false,
      websiteError: false,
      descriptionError: false,
    });

    if (name === 'companyName') {
      if (value.length > 100) {
        this.setState({
          companyNameError: true,
        });
        return;
      }
    }

    if (name === 'website') {
      if (value.length > 100) {
        this.setState({
          websiteError: true,
        });
        return;
      }
    }

    if (name === 'description') {
      if (value.length > 20000) {
        this.setState({
          descriptionError: true,
        });
        return;
      }
    }

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    sendUserInteraction('submit update user info');

    e.preventDefault();
    const { t, onClose } = this.props;
    this.setState({ isSubmitting: true });

    try {
      await this.updateUserProfile();
      if (this.props.isAdmin) {
        if (!checkAllowedRole({ userRole: this.props.user.role, allowedRoles: [40, 42] })) {
          if (!this.state.subscription && this.state.shareTm.share_tm !== this.props.shareTm.share_tm) {
            await postTmSettings(this.state.shareTm);
          }
        }
      }

      this.setState(
        {
          isSubmitting: false,
        },
        () => {
          onClose();
        },
      );
      toast.success(t('common:toasts.updateUser.success'));
    } catch (e) {
      toast.error(t('common:toasts.updateUser.error'));
      this.setState({
        isSubmitting: false,
        isError: true,
      });
      throw e;
    }
  };

  updateUserProfile = async () => {
    const { description, phoneNumber, userName, website } = this.state;
    const { dispatch, user } = this.props;

    const newUserData = {
      user_id: user.id,
      name: userName,
      website: website,
      description,
      phone_number: phoneNumber,
    };

    try {
      await postProfileUpdate(newUserData);
      const { data } = await getUser();
      dispatch(setUser({ user: data }));
    } catch (e) {
      throw e;
    }
  };

  shouldShowCompanyInput = () => {
    const { role } = this.props.user;
    return role === 40 || role === 41;
  };

  toggleSubscription = () => {
    sendUserInteraction('clicked toggle catapult subscription');
    this.setState({ subscription: !this.state.subscription });
  };

  updateShare = (share) => {
    sendUserInteraction('clicked share private TM data');
    this.setState({ shareTm: share });
  };

  render() {
    const {
      description,
      isSubmitting,
      isError,
      phoneNumber,
      website,
      subscription,
      companyNameError,
      websiteError,
      descriptionError,
      shareTm,
    } = this.state;
    const { t } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} error={isError}>
        <Form.Input
          label={`${t('common:userInfo.labels.phoneNumber')}: `}
          name="phoneNumber"
          onChange={this.handleInputChange}
          placeholder={t('common:userInfo.placeholders.phoneNumber')}
          type="tel"
          value={phoneNumber || ''}
        />
        <Form.Input
          label={`${t('common:userInfo.labels.website')}: `}
          name="website"
          onChange={this.handleInputChange}
          placeholder={t('common:userInfo.placeholders.website')}
          type="text"
          value={website || ''}
          error={websiteError && t('common:userInfo.websiteError')}
        />
        <Form.TextArea
          label={`${t('common:userInfo.labels.aboutMe')}: `}
          name="description"
          onChange={this.handleInputChange}
          placeholder={t('common:userInfo.placeholders.description')}
          rows={8}
          value={description || ''}
          error={descriptionError && t('common:userInfo.descriptionError')}
        />
        {this.props.isAdmin &&
        !checkAllowedRole({ userRole: this.props.user.role, allowedRoles: [40, 42] }) ? (
          <ShareToggle shareTm={shareTm} updateShare={this.updateShare} isDiy={subscription} />
        ) : null}
        {isError && <Message text={t('common:userInfo.error')} type="error" />}
        <Button
          disabled={isSubmitting || descriptionError || websiteError || companyNameError}
          loading={isSubmitting}
          floated="right"
          actiontype="submit"
          type="submit"
        >
          {t('common:userInfo.updateUserInfo')}
        </Button>
      </Form>
    );
  }
}

export default connect()(withTranslation('common')(UserInfoForm));
