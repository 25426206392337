import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Message from '../../common/Message/Message';
import { getTeamInvoices } from '../../../services/teams';
import DatepickerRange from '../../common/Datepicker/DatepickerRange';
import LoadingPane from '../../common/LoadingPane/LoadingPane';
import { StyledPagination, TeamInvoicesLayout, Toolbar, FilterLabel } from './TeamInvoices.style';
import TeamInvoicesTable from './TeamInvoicesTable/TeamInvoicesTable';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { SelectGroup } from '../../common/SelectGroup/SelectGroup';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const TeamInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useLocalStorage('team-invoices-page', 1);
  const [lastPage, setLastPage] = useState(1);
  const [paidFilter, setPaidFilter] = useLocalStorage('team-invoices-paid-filter', null);
  const [issuedDates, setIssuedDates] = useState([null, null]);
  const [dueDates, setDueDates] = useState([null, null]);

  const { t } = useTranslation();
  const { id } = useParams();

  const formatDate = (date) => {
    if (!!date) {
      return format(zonedTimeToUtc(date, 'Europe/Ljubljana'), 'P');
    }
    return '';
  };

  useEffect(() => {
    const fetchInitialInvoices = async () => {
      try {
        setIsLoading(true);
        const { data, last_page } = await getTeamInvoices({
          team_id: id,
          page: page,
          project_paid: paidFilter,
          payment_due_from: dueDates[0] !== null ? formatDate(dueDates[0]) : null,
          payment_due_to: dueDates[1] !== null ? formatDate(dueDates[1]) : null,
          issued_on_from: issuedDates[0] !== null ? formatDate(issuedDates[0]) : null,
          issued_on_to: issuedDates[1] !== null ? formatDate(issuedDates[1]) : null,
        });
        setInvoices(data);
        if (page > last_page) {
          setPage(last_page);
        }
        setLastPage(last_page);
      } catch (e) {
        setIsError(true);
        throw e;
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialInvoices();
  }, [id, page, paidFilter, issuedDates, dueDates, setPage]);

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('team invoices page change');
    setPage(activePage);
  };

  const handlePaidChange = (value) => {
    sendUserInteraction('team invoices paid filter change');
    setPaidFilter(value);
  };

  return (
    <TeamInvoicesLayout>
      <Toolbar>
        {invoices.length > 1 && (
          <StyledPagination activePage={page} totalPages={lastPage} onPageChange={handlePaginationChange} />
        )}
        <FilterLabel>Filter by: </FilterLabel>
        <DatepickerRange
          onChange={(dates) => {
            sendUserInteraction('invoices issued date range change');
            setIssuedDates(dates);
          }}
          placeholder="Issued date"
        />
        <DatepickerRange
          onChange={(dates) => {
            sendUserInteraction('invoices due date range change');
            setDueDates(dates);
          }}
          placeholder="Payment due"
        />
        <FilterLabel style={{ marginLeft: '1em' }}>Show: </FilterLabel>
        <SelectGroup onChange={handlePaidChange} size="small" selected={paidFilter}>
          <SelectGroup.Option value={null}> All </SelectGroup.Option>
          <SelectGroup.Option value={1}> {t('common:team.invoices.paid')} </SelectGroup.Option>
          <SelectGroup.Option value={0}> {t('common:team.invoices.notPaid')} </SelectGroup.Option>
        </SelectGroup>
      </Toolbar>

      {isLoading ? <LoadingPane /> : <TeamInvoicesTable invoices={invoices} />}
      {isError && <Message text={t('common:team.invoices.error')} type="error" />}
    </TeamInvoicesLayout>
  );
};

export default TeamInvoices;
