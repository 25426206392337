import styled from 'styled-components';
import { taiaColors } from '../../../style/theme';

export const TaiaStyledButton = styled.button`
  &.button {
    font-size: 1rem;
    cursor: pointer;
    display: inline-block;
    position: relative;
    min-height: 1em;
    z-index: 0;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    min-height: min-content;

    border-radius: 3px;

    transition: color ease-in-out, background-color ease-in-out, box-shadow ease-in-out;
    transition-duration: ${({ $loading }) => ($loading ? '0s' : '0.3s')};

    // Colors
    color: ${({ $loading }) => ($loading ? 'transparent!important' : taiaColors.black)};
    background-color: ${taiaColors.white};
    border: 1px solid ${taiaColors.grey};

    &:hover {
      background: ${taiaColors.greyLight};
    }

    & > .overlay {
      transition: background 0.3s ease-in-out;
      position: absolute;
      border-radius: 4px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent;
      z-index: -1;
    }

    &.blue {
      color: ${taiaColors.blue};
      background-color: ${taiaColors.white};
      border: 1px solid ${taiaColors.blue};

      &:hover {
        background-color: ${taiaColors.blueLight};
      }
    }

    &.yellow {
      color: ${taiaColors.yellow};
      background-color: ${taiaColors.white};
      border: 1px solid ${taiaColors.yellow};

      &:hover {
        background-color: ${taiaColors.yellowLight};
      }
    }

    &.red {
      color: ${taiaColors.red};
      background-color: ${taiaColors.white};
      border: 1px solid ${taiaColors.red};

      &:hover {
        background-color: ${taiaColors.redLight};
      }
    }

    &.green {
      color: ${taiaColors.green};
      background-color: ${taiaColors.white};
      border: 1px solid ${taiaColors.green};

      &:hover {
        background-color: ${taiaColors.greenLight};
      }
    }

    &.black {
      color: ${taiaColors.black};
      background-color: ${taiaColors.white};
      border: 1px solid ${taiaColors.red};

      &:hover {
        background-color: ${taiaColors.redLight};
      }
    }

    &.fluid {
      display: block;
      width: 100%;
    }

    &.basic {
      color: ${taiaColors.black};
      background: ${taiaColors.grey};
      border: none;
      box-shadow: none;

      &:hover {
        & > .overlay {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      &.blue {
        color: ${taiaColors.white};
        background: ${taiaColors.blue};
      }

      &.yellow {
        color: ${taiaColors.white};
        background: ${taiaColors.yellow};
      }

      &.red {
        color: ${taiaColors.white};
        background: ${taiaColors.red};
      }

      &.light-grey {
        color: ${taiaColors.black};
        background: #e8e8e8;
      }

      &.black {
        color: ${taiaColors.white};
        background: ${taiaColors.black};
      }

      &.green {
        color: ${taiaColors.white};
        background: ${taiaColors.green};
      }

      &-light {
        color: ${taiaColors.black};
        background: ${taiaColors.greyWhite};
        border: none;
        box-shadow: none;
      }
    }

    &.outlined {
      color: ${taiaColors.black};
      background: ${taiaColors.white};
      border: 1px solid ${taiaColors.grey};
      box-shadow: none;

      &:hover {
        background: ${taiaColors.grey};
      }
      & > .overlay {
        background: transparent !important;
      }

      &.blue {
        border: 1px solid ${taiaColors.blue};
        color: ${taiaColors.blue};

        &:hover {
          background: ${taiaColors.blueLight};
        }
      }

      &.yellow {
        border: 1px solid ${taiaColors.yellow};
        color: ${taiaColors.yellow};

        &:hover {
          background: ${taiaColors.yellowLight};
        }
      }

      &.red {
        border: 1px solid ${taiaColors.red};
        color: ${taiaColors.red};

        &:hover {
          background: ${taiaColors.redLight};
        }
      }

      &.grey {
        border: 1px solid ${taiaColors.greyDark};
        color: ${taiaColors.greyDark};

        &:hover {
          background: ${taiaColors.greyLight};
        }
      }

      &.black {
        border: 1px solid ${taiaColors.black};

        &:hover {
          background: ${taiaColors.greyDark};
        }
      }
    }

    &.circular {
      border-radius: 10em;
      & > .overlay {
        border-radius: 10em;
      }

      ${({ theme }) => theme.mediaMaxWidth.mobile`
        border-radius: 100%;
        flex-grow: initial;
      `}
    }

    &.compact {
      padding: 0.58928571em 1.125em 0.58928571em;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.icon {
      padding: 8px;
      width: 36px;
      height: 36px;
      & > .icon {
        height: 1em;
      }
    }

    &.labeled {
      position: relative;
      width: unset;
      height: unset;
      padding: 0.78571429em 1.5em;
      & > .icon {
        background-color: rgba(0, 0, 0, 0.05);
        position: absolute;
        top: 0;
        width: 2.57142857em;
        height: 100%;
        display: ${({ $loading }) => ($loading ? 'none!important' : '')};
      }

      &.right {
        padding-right: 4.07142857em !important;
        & > .icon {
          left: auto;
          right: 0;
          border-radius: 0;
          border-top-right-radius: inherit;
          border-bottom-right-radius: inherit;
          box-shadow: 1px 0 0 0 transparent inset;
        }
      }

      &.left {
        padding-left: 4.07142857em !important;
        & > .icon {
          right: auto;
          left: 0;
          border-radius: 0;
          border-top-right-radius: inherit;
          border-bottom-right-radius: inherit;
          box-shadow: 1px 0 0 0 transparent inset;
        }
      }
    }

    &.floated-right {
      float: right;
    }

    &.floated-left {
      float: left;
    }

    &.big {
      font-size: 1.2em;

      ${({ theme }) => theme.mediaMaxWidth.mobile`
        font-size: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
      `}
    }

    &.flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
