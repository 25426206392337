import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendUserInteraction } from 'utils/tagManager.utils';
import Button from '../../common/Button/Button';

import '../NotificationPage.scss';

const MissingPayment = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <section className="container">
      <h1 className="header-1">{t('common:checkout.cannotOrder')}</h1>
      <p className="text last-text">{t('common:checkout.cannotOrderDescription')} </p>
      <Button
        actiontype="primary"
        onClick={() => {
          sendUserInteraction('missing payment back to homepage link');
          history.push('/');
        }}
      >
        {t('common:checkout.backToHomepage')}
      </Button>
    </section>
  );
};

export default MissingPayment;
