import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { localiseAndConvertPrice } from '../../../utils/string.utils';

const Price = ({ i18n, price, currency, className, style, round = true }) => {
  const currencies = useSelector((state) => state.classifiersStore.currencies);
  const eurCurrency = currencies.find((curr) => curr.id === 1);
  const currencyData = currency ? currency : eurCurrency;
  if (price !== undefined && price !== null && i18n) {
    return round ? (
      <span data-cy="price-span" className={className} style={style}>
        {localiseAndConvertPrice({ currencyData, number: price, language: i18n.language })}
      </span>
    ) : (
      <span data-cy="price-span" className={className} style={style}>
        {`${currencyData.symbol} ${Number(price) * currencyData.conversion_rate}`}
      </span>
    );
  }

  return null;
};

export default memo(withTranslation('common')(Price));
