import styled from 'styled-components';

import { Grid } from 'semantic-ui-react';
import Loader from '../../Loader/Loader';

export const StyledSelectText = styled.p`
  color: ${({ theme }) => theme.colors.blue};
  padding-top: 1rem;
`;

export const StyledDropzoneContainer = styled(Grid.Row)`
  min-height: 130px;
  width: 100%;
  min-width: 134px;
  display: flex;
  align-items: center !important;
  justify-content: center;
`;

export const StyledLoader = styled(Loader)`
  :before {
    border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
  }
  :after {
    border-color: #767676 transparent transparent !important;
  }
`;
