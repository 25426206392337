import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDebounce, withDebounce } from '../../../hoc/debouncer';

import Table from '../../common/Table/Table';
import { Icon } from '../../common/Icon/Icon';
import Pagination from '../../common/Pagination/Pagination';
import { TableToolbar } from '../../common.styles';
import { StyledInput } from '../AnalyzedProjects/AnalyzedProjects.style';

import { getAdminProjects } from '../../../services/project';
import ProjectLanguages from '../../common/ProjectLanguages/NewProjectLanguages';
import Price from '../../common/Price/Price';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import { Link } from 'react-router-dom';
import LoadingPane from '../../common/LoadingPane/LoadingPane';
import Message from '../../common/Message/Message';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { formatAPIDateString } from 'utils/date.utils';

const entriesPerPage = 13;

const DIYProjects = () => {
  const [isLoading, setIsloading] = useState(true);
  const [, setError] = useState(false);
  const [activePage, setActivePage] = useLocalStorage('admin-diy-projects-page', 1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterString, setFilterString] = useLocalStorage('admin-diy-project-search', '');
  const [projects, setProjects] = useState([]);

  const { debounce } = useDebounce();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProjects = async (page, search) => {
      debounce(
        'fetch-projects',
        async () => {
          try {
            setError(false);
            setIsloading(true);

            const { data } = await getAdminProjects({
              page,
              per_page: entriesPerPage,
              search,
              status_id: [10, 11],
              project_paid: [],
            });
            setProjects(data.data);
            if (page > data.last_page) {
              setActivePage(data.last_page);
            }
            setTotalPages(data.last_page);
          } catch (e) {
            setError(true);
            console.error(e);
          } finally {
            setIsloading(false);
          }
        },
        400,
      );
    };
    fetchProjects(activePage, filterString);
  }, [activePage, filterString, debounce, setActivePage]);

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction(`diy projects page change: ${activePage}`);
    setActivePage(activePage);
  };

  const handleFilterString = (e, { value }) => {
    sendUserInteraction(`diy projects filter string change`);
    setFilterString(value);
  };

  const projectRows = projects.map((project) => {
    return (
      <Table.Row key={project.id} paddingY="13px">
        <Table.Cell>{project.id}</Table.Cell>
        <Table.Cell>
          <Link className="truncated-text" title={project.name} to={`/admin/project/${project.id}`}>
            {project.name}
          </Link>
        </Table.Cell>
        <Table.Cell>
          <Link to={`/admin/user/${project.user_id}`}>
            {project.username} {project.email}
          </Link>
        </Table.Cell>
        <Table.Cell>{formatAPIDateString({ dateString: project.created_at })}</Table.Cell>
        <Table.Cell>
          <StatusLabel project={project}></StatusLabel>
        </Table.Cell>
        <Table.Cell textAlign="center">{project.total_words}</Table.Cell>
        <Table.Cell>
          <Price
            currency={{ code: project.code, conversion_rate: project.conversion_rate }}
            price={project.price}
          ></Price>
        </Table.Cell>
        <Table.Cell>
          <ProjectLanguages project={project} />
        </Table.Cell>
        <Table.Cell textAlign="center">
          {project.taia_ocr === 1 ? <Icon name="check" color="green" /> : '-'}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      <TableToolbar>
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePaginationChange}
          className="left"
        />
        <StyledInput
          icon={<Icon name="search" inElement />}
          placeholder="Search..."
          value={filterString}
          loading={isLoading}
          onChange={handleFilterString}
        />
      </TableToolbar>
      {isLoading ? (
        <LoadingPane />
      ) : projectRows.length > 0 ? (
        <Table width="100%">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.projectName')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.customer')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.createdAt')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.status')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.words')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.Price')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.langComb')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.ocr')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{projectRows}</Table.Body>
        </Table>
      ) : (
        <Message text={t('common:projects.noProjectsFound')} type="info" />
      )}
    </>
  );
};

export default withDebounce(withTranslation('common')(DIYProjects));
