import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { sendUserInteraction } from 'utils/tagManager.utils';
import LoadingPane from '../common/LoadingPane/LoadingPane';
import { TeamItem, TeamItemHeader, TeamItemIcon, TeamsLayout } from './Teams.style';

class Teams extends Component {
  constructor(props) {
    super(props);
    if (props.user) {
      if (props.user.teams.length === 1) {
        props.history.push(`/team/${props.user.teams[0].id}/members`);
      }
    }
  }

  routeToTeam = (id) => {
    return () => {
      sendUserInteraction('clicked team card');
      this.props.history.push(`/team/${id}/members`);
    };
  };

  render() {
    const { user, t } = this.props;
    const TeamItems = user.teams.map((team) => {
      return (
        <TeamItem key={team.id} onClick={this.routeToTeam(team.id)}>
          <div className="item-content">
            <TeamItemIcon name="users-alt" />
            <TeamItemHeader>{team.name}</TeamItemHeader>
          </div>
        </TeamItem>
      );
    });

    return !this.props.user ? (
      <LoadingPane></LoadingPane>
    ) : (
      <TeamsLayout>{user.teams.length === 0 ? <div>{t('common:team.noTeams')}</div> : TeamItems}</TeamsLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userStore.user,
});

export default withRouter(connect(mapStateToProps)(withTranslation('common')(Teams)));
