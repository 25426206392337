import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useLocalStorage } from 'utils/storage.utils';
import { useDebounce } from '../../../hoc/debouncer';
import { getCompanyMembers } from '../../../services/company';
import { StyledPagination } from '../Company.styles';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import { MembersLayout } from './CompanyMembers.style';
import MembersTable from './MembersTable/MembersTable';

const CompanyMembers = () => {
  const [, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMemebers] = useState([]);
  const [currentPage, setCurrentPage] = useLocalStorage('company-members-page', 1);
  const [numOfPages, setNumOfPages] = useState(1);
  const isFirstTime = useRef(true);

  const { id } = useParams();
  const { debounce } = useDebounce();

  useEffect(() => {
    let debounceDelay = 500;
    if (isFirstTime.current) {
      debounceDelay = 0;
    }

    const fetchMembers = async (page) => {
      debounce(
        'companyMembers',
        async () => {
          try {
            setIsLoading(true);
            const { data, last_page } = await getCompanyMembers({ page: page, id: id });

            if (page > last_page) {
              setCurrentPage(last_page);
            }
            setNumOfPages(last_page);
            setMemebers(data);
          } catch (e) {
            setIsError(true);
            console.error(e);
          } finally {
            setIsLoading(false);
            isFirstTime.current = false;
          }
        },
        debounceDelay,
      );
    };

    fetchMembers(currentPage);
  }, [currentPage, debounce, id, setCurrentPage]);

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  return (
    <MembersLayout>
      {isLoading ? <LoadingPane height={'260px'} /> : <MembersTable members={members} />}

      {numOfPages > 1 && (
        <StyledPagination
          activePage={currentPage}
          totalPages={numOfPages}
          onPageChange={handlePaginationChange}
        />
      )}
    </MembersLayout>
  );
};

export default CompanyMembers;
