import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'components/common/Icon/Icon';
import { Container, TextContainer } from './VendorCell.styles';

import { taiaColors } from 'style/theme';
import { truncateString } from 'utils/string.utils';
import { formatAPIDateString } from 'utils/date.utils';

const VendorCell = ({ endDate, swap = () => {}, vendor = {} }) => {
  const { name, id } = vendor;

  return (
    <Container>
      <TextContainer>
        <Link to={`/admin/vendor/${id}`}>
          {truncateString({
            maxLength: 22,
            string: name,
          })}
        </Link>
        <span>{formatAPIDateString({ dateString: endDate })}</span>
      </TextContainer>
      <Icon
        color={taiaColors.blue}
        name="exchange-alt"
        size="big"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          swap();
        }}
      />
    </Container>
  );
};

export default VendorCell;
