import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CenteredContainer, ContentContainer } from '../../common.styles';
import './style.scss';

import Image from './assets/errorScreen.svg';
import Button from 'components/common/Button/Button';

const ErrorFrame = ({ children }) => {
  return <div className="error-frame">{children}</div>;
};

const ErrorScreen = ({ error = { message: 'Unknown error' } }) => {
  const { t } = useTranslation();

  return (
    <CenteredContainer className="error-screen">
      <img src={Image} alt="maintenance" />
      <ContentContainer>
        <h1>{t('common:errorScreen.title')}</h1>
        <p>{t('common:errorScreen.text')}</p>
        <ErrorFrame>
          <strong>URL:</strong> {document.URL} <br />
          <strong>Error message:</strong> {error.message}
        </ErrorFrame>
        <p>{t('common:errorScreen.text2')}</p>
        <Button color="primary" onClick={() => window.location.reload()}>
          {t('common:errorScreen.reload')}
        </Button>
      </ContentContainer>
    </CenteredContainer>
  );
};

ErrorScreen.propTypes = {
  error: PropTypes.object,
};

export default ErrorScreen;
