import { SUBSCRIPTION_INTERVALS } from '../../../constants/subscriptions';
import store from '../../../store/store';

export const pricingCombinations = () => {
  const intervalOptions = [...SUBSCRIPTION_INTERVALS];
  const currencyOptions = store.getState().classifiersStore.currencies;

  const combinations = {};
  for (const interval of intervalOptions) {
    for (const currency of currencyOptions) {
      combinations['' + interval + currency.code] = { interval, currency };
    }
  }
  return combinations;
};

export const remainingPricingCombinations = (pricing) => {
  const pricingComb = pricingCombinations();

  // Remove existing combinations
  for (const price of pricing) {
    const key = '' + price.interval + price.currency.toUpperCase();
    if (pricingComb.hasOwnProperty(key)) {
      delete pricingComb[key];
    }
  }

  const combinations = [];
  // Convert remaining to array
  for (const comb in pricingComb) {
    combinations.push({ ...pricingComb[comb] });
  }

  return combinations;
};

export const hasSameCombination = (pricing) => {
  const combinations = [];
  for (const price of pricing) {
    const key = '' + price.interval + price.currency;
    if (combinations.includes(key)) {
      return true;
    } else {
      combinations.push(key);
    }
  }

  return false;
};
