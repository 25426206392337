import { LANGUAGE_CODES } from '../constants/languages';

export const hasNumber = (value) => /\d/.test(value);

export const hasUppercase = (value) => /[A-ZČĆŠĐŽŁ]+/.test(value);

export const hasLowercase = (value) => /[a-zčćšđžł]+/.test(value);

export const hasSymbol = (value) => /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/.test(value);

export const hasMinLength = (value, minLength) => {
  if (value.length >= minLength) {
    return true;
  }
  return false;
};

export const passwordValid = (password) => {
  return (
    hasNumber(password) &&
    hasUppercase(password) &&
    hasLowercase(password) &&
    hasSymbol(password) &&
    hasMinLength(password, 8)
  );
};

export const isValidEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

export const getDayName = (date) => {
  return new Date(date).toLocaleString('en-GB', { weekday: 'long' });
};

export const roundNumber = (number, decimals) => {
  let ppw = parseFloat(number);
  ppw = Number(ppw.toFixed(decimals)).toString();

  return ppw;
};

// converts and localises the passed number to a price tag
export const localiseAndConvertPrice = ({ currencyData, number, language }) => {
  const convertedPrice = convertPrice({ currencyData, number });
  const localisedPriced = localisePrice({ currencyData, language, number: convertedPrice });

  return localisedPriced;
};

export const localisePrice = ({ currencyData, language, number }) => {
  if (isNaN(number)) {
    return number;
  }

  const localeCode = LANGUAGE_CODES[language] ? LANGUAGE_CODES[language] : LANGUAGE_CODES.en;
  const localisedPrice = number.toLocaleString(localeCode, {
    style: 'currency',
    currency: currencyData.code,
  });
  return localisedPrice;
};

export const convertPrice = ({ currencyData, number }) => {
  const priceFloat = parseFloat(number);
  // if we have currencyData and price is a number
  if (!!currencyData && !!currencyData.conversion_rate && !isNaN(priceFloat)) {
    const conversionFloat = parseFloat(currencyData.conversion_rate);
    if (!isNaN(conversionFloat)) {
      const finalPrice = priceFloat * conversionFloat;
      // toFixed returns string because javascript
      return finalPrice;
    }
    return number;
  }

  // else return the passed number unformatted
  return number;
};

export const toPascalCase = (string) =>
  string
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(new RegExp(/\s+(.)(\w*)/, 'g'), ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
    .replace(new RegExp(/\s/, 'g'), '')
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());

export const truncateString = ({ maxLength, string }) => {
  return string.length > maxLength ? string.substring(0, maxLength - 3) + '...' : string;
};

// returns an array of segments with text and highlighted properties
export const getHighlightedSegments = (text, highlighted) => {
  const segments = [];
  let string = text;
  let hIdx;

  while (true) {
    hIdx = string.toLowerCase().indexOf(highlighted.toLowerCase());
    if (hIdx === -1) break;

    segments.push(
      {
        text: string.substr(0, hIdx),
        highlighted: false,
      },
      {
        text: string.substr(hIdx, highlighted.length),
        highlighted: true,
      },
    );

    string = string.substr(hIdx + highlighted.length);
  }
  segments.push({ text: string, highlighted: false });

  return segments;
};
