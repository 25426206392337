import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Components
import { Link } from 'react-router-dom';
import ArchiveModal from 'components/common/ArchiveModal/ArchiveModal';
import DeleteProjectModal from 'components/common/DeleteProjectModal/DeleteProjectModal';
import StatusLabel from 'components/common/StatusLabel/StatusLabel';
import Price from 'components/common/Price/Price';
import ProjectLanguages from 'components/common/ProjectLanguages/NewProjectLanguages';
import AssignPerson from 'components/common/AssignPerson/AssignPerson';
import Table from 'components/common/Table/Table';
import { Icon } from 'components/common/Icon/Icon';

// Services, utils, vars
import { assignPmToProject } from 'services/project';
import { canDeleteProject } from 'utils/user.utils';
import { formatAPIDateString } from 'utils/date.utils';

// Styles
import './style.scss';
import { canArchiveProject } from 'utils/user.utils';
import Button from 'components/common/Button/Button';
import { PROJECT_STATUS_ARCHIVED } from 'constants/projectStatus';

const AnalyzedProjectRow = ({ project, refetchProjects = async () => {}, adminUsers }) => {
  // State
  const [loading, setLoading] = useState({
    delete: false,
    archive: false,
    // Initially fetching PM data
    managePM: false,
  });
  const [rowProject, setRowProject] = useState(project);

  const { t } = useTranslation();

  const {
    name,
    id,
    status_id,
    user_id,
    username,
    team,
    created_at,
    delivery_time,
    code,
    conversion_rate,
    project_paid,
    total_words,
    price,
    project_manager,
  } = rowProject;

  // Methods
  /**
   * Handles assigning of PM to project.
   *
   * @param {number} pmID
   */
  const handlePmAssign = async (pmID) => {
    setLoading((c) => ({ ...c, managePM: true }));

    try {
      const response = await assignPmToProject({
        project_id: id,
        pm_id: pmID,
      });

      setRowProject((c) => ({ ...c, project_manager: response.project_manager }));
    } catch (error) {
      console.error(error);
      toast.error(t('common:admin.projects.analyzedProjects.errors.assignPM'));
    } finally {
      setLoading((c) => ({ ...c, managePM: false }));
    }
  };

  const handleArchiveLoading = (loading) => {
    setLoading((c) => ({ ...c, archive: loading }));

    // Loading end => refetch projects
    if (!loading) refetchProjects();
  };

  const handleDeleteLoading = (loading) => {
    setLoading((c) => ({ ...c, delete: loading }));

    // Loading end => refetch projects
    if (!loading) refetchProjects();
  };

  const rowLoading = loading.archive || loading.delete || loading.managePM;
  const canArchive = canArchiveProject(status_id) || status_id === PROJECT_STATUS_ARCHIVED;
  const canDelete = canDeleteProject(status_id);

  return (
    <Table.Row className="analyzed-project-row" aria-busy={rowLoading} key={id} paddingY="13px">
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>
        <Link className="truncated-text" to={`/admin/project/${id}`} title={name}>
          {name}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <div className="project-customer">
          {user_id && (
            <p style={{ marginBottom: '.2em' }}>
              <span>{`${t('common:admin.projects.headers.user')}: `}</span>
              <Link to={`/admin/user/${user_id}`}>{`${username} `}</Link>
            </p>
          )}
          {team && (
            <>
              <p style={{ marginBottom: '.2em' }}>
                <span>{`${t('common:admin.projects.headers.team')}: `}</span>
                <Link to={`/team/${team.id}/members`}>{`${team.name}`}</Link>
              </p>
              <p style={{ marginBottom: '.2em' }}>
                <span>{`${t('common:admin.projects.headers.company')}: `}</span>
                <Link to={`/company/${team.company.id}`}>{`${team.company.company_name}`}</Link>
              </p>
            </>
          )}
        </div>
      </Table.Cell>
      <Table.Cell className="table-cell-project-manager">
        <AssignPerson
          loading={loading.managePM}
          displayName
          currentPerson={project_manager}
          choices={adminUsers}
          onAssign={handlePmAssign}
        />
      </Table.Cell>
      <Table.Cell>{formatAPIDateString({ dateString: created_at })}</Table.Cell>
      <Table.Cell>
        <StatusLabel isJobType={false} project={rowProject}></StatusLabel>
      </Table.Cell>
      <Table.Cell textAlign="center">{total_words}</Table.Cell>
      <Table.Cell>
        <Price currency={{ code: code, conversion_rate: conversion_rate }} price={price} />
      </Table.Cell>
      <Table.Cell>
        <ProjectLanguages project={rowProject} />
      </Table.Cell>
      <Table.Cell>{formatAPIDateString({ dateString: delivery_time })}</Table.Cell>
      <Table.Cell textAlign="center">
        {project_paid ? <Icon name="check" color="green" /> : <Icon name="times" color="red" />}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {canArchive && (
          <ArchiveModal
            project={project}
            trigger={
              <Button
                disabled={loading.delete}
                loading={loading.delete}
                icon={status_id === PROJECT_STATUS_ARCHIVED ? 'redo' : 'archive'}
                actiontype="delete"
              />
            }
            setLoading={handleArchiveLoading}
          />
        )}
        {canDelete && (
          <DeleteProjectModal
            trigger={
              <Button
                disabled={loading.delete}
                loading={loading.delete}
                icon="trash-alt"
                actiontype="delete-confirm"
              />
            }
            projectId={project.id}
            setLoading={handleDeleteLoading}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default AnalyzedProjectRow;
