import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subscriptions: [],
};

const subscriptionsSlice = createSlice({
  initialState: { ...initialState },
  name: 'subscriptionsSlice',
  reducers: {
    addSubscriptions(state, action) {
      const oldSubscriptions = [...state.subscriptions];
      const subscriptionsToAdd = [...action.payload];

      const newSubscriptions = [
        ...oldSubscriptions.filter((sub) => {
          const newSub = subscriptionsToAdd.find((subToAdd) => subToAdd.id === sub.id);
          return !newSub;
        }),
        ...subscriptionsToAdd,
      ];

      state.subscriptions = newSubscriptions;
    },
    addFreeUsage(state, action) {
      const freePlanIdx = state.subscriptions.findIndex((sub) => sub.type === 'free');
      if (freePlanIdx > -1) {
        const freePlan = { ...state.subscriptions[freePlanIdx] };

        freePlan.limit_used += action.payload;

        state.subscriptions[freePlanIdx] = freePlan;
      }
    },
    subtractFreeUsage(state, action) {
      const freePlanIdx = state.subscriptions.findIndex((sub) => sub.type === 'free');
      if (freePlanIdx > -1) {
        const freePlan = { ...state.subscriptions[freePlanIdx] };

        freePlan.limit_used -= action.payload;

        state.subscriptions[freePlanIdx] = freePlan;
      }
    },
    removeSubscription(state, action) {
      const id = action.payload;
      state.subscriptions = state.subscriptions.filter((sub) => sub.id === id);
    },
    removeAllSubscriptions(state, action) {
      state.subscriptions = [];
    },
  },
});

export const {
  addSubscriptions,
  removeSubscription,
  removeAllSubscriptions,
  addFreeUsage,
  subtractFreeUsage,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
