import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import { MainContainer } from 'components/common.styles';
import { getVendors } from 'services/vendors';
import { PaginationContainer } from 'components/Projects/Projects.styles';
import Pagination from 'components/common/Pagination/Pagination';
import VendorTable from './VendorTable/VendorTable';
import { useFiltersContext } from 'providers/FiltersProvider';
import { FiltersProvider } from 'providers/FiltersProvider';
import VendorFilters from './VendorFilters/VendorFilters';
import { useDebounce } from 'hoc/debouncer';
import Button from 'components/common/Button/Button';
import './style.scss';
import { useHistory } from 'react-router';

const initialVendorFilters = {
  search: '',
  experience: [],
  risk: [],
  status: [],
  source_lang_id: null,
  target_lang_id: null,
  pending_price: null,
  task_type_id: [],
};

const AdminVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const { filters, activePage, numOfPages, changePage, sorting } = useFiltersContext();
  const { debounce } = useDebounce();
  const history = useHistory();

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        setIsLoading(true);
        const data = await getVendors({ page: activePage, per_page: 14, ...filters, ...sorting });

        setVendors(data.data);
        changePage({ numOfPages: data.last_page });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    debounce(
      'fetch-vendors',
      () => {
        fetchVendors();
      },
      300,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, activePage, sorting]);

  const updatePage = (e, { activePage }) => {
    changePage({ activePage });
  };
  const handleAddClick = () => {
    history.push(`/admin/vendor-onboarding`);
  };
  return (
    <>
      <MainContainer className="admin-vendors">
        <div className="vendors-header">
          <Header as="h1">{t('common:admin.dashboard.translators')}</Header>
          <Button color="blue" onClick={handleAddClick}>
            {t('common:admin.dashboard.addVendor', '+ Add a vendor')}
          </Button>
          <VendorFilters isLoading={isLoading}></VendorFilters>
        </div>

        <VendorTable vendors={vendors} loading={isLoading} />
        <PaginationContainer>
          {numOfPages > 1 && (
            <Pagination activePage={activePage} onPageChange={updatePage} totalPages={numOfPages} />
          )}
        </PaginationContainer>
      </MainContainer>
    </>
  );
};

const AdminVendorsWrapper = () => {
  return (
    <FiltersProvider name="admin-vendors" initialFilters={initialVendorFilters}>
      <AdminVendors />
    </FiltersProvider>
  );
};

export default AdminVendorsWrapper;
