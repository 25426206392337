import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Avatar from './Avatar';
import Card from '../Card/Card';
import EditUserModal from './EditUserModal/EditUserModal';

import {
  AvatarContainer,
  Container,
  HeadContainer,
  NameContainer,
  LabelContainer,
  StyledFlag,
  UserLabel,
} from './ProfileCard.styles';
import { StyledLabel, StyledHeading } from '../../common.styles';

import { sendUserInteraction } from 'utils/tagManager.utils';
import { USERS_TYPE } from '../../../constants/roles';
import { formatAPIDateString } from 'utils/date.utils';

const LANGUAGES = {
  1: 'uk',
  2: 'de',
  3: 'si',
  4: 'it',
  5: 'tr',
};

const UserProfileCard = ({ user, t, fetchUser, isAdmin, simple, shareTm }) => {
  const {
    id,
    role,
    pref_lang,
    active,
    euro_balance,
    avatar,
    description,
    email,
    name,
    phone_number,
    website,
    created_at,
    updated_at,
    subscription,
  } = user;

  return (
    <Card>
      <Container>
        <AvatarContainer>
          <Avatar url={avatar} />
          <HeadContainer>
            <NameContainer>
              <StyledHeading as="h2">
                {isAdmin ? (
                  <Link
                    onClick={() => sendUserInteraction('admin user name link click')}
                    to={`/admin/user/${id}`}
                  >{`${name} (${USERS_TYPE[role]})`}</Link>
                ) : (
                  name
                )}
              </StyledHeading>
              <div>
                <StyledLabel>{`${t('common:userInfo.labels.email')}: `}</StyledLabel>
                {email}
              </div>
              <div>
                <StyledLabel>{`${t('common:userInfo.labels.phoneNumber')}: `}</StyledLabel>
                {phone_number}
              </div>
              <div>
                <StyledLabel>{`${t('common:userInfo.labels.website')}: `}</StyledLabel>
                <a href={user.website} rel="noopener noreferrer" target="_blank" style={{ color: '#FB4389' }}>
                  {website}
                </a>
              </div>
              {isAdmin && (
                <LabelContainer>
                  <UserLabel>ID {id}</UserLabel>
                  <UserLabel basic>
                    <StyledFlag name={LANGUAGES[pref_lang]} />
                  </UserLabel>
                  <UserLabel color={active ? 'green' : 'red'}>
                    {active ? 'Activated' : 'Not Activated'}
                  </UserLabel>
                  <UserLabel>
                    {' '}
                    {`${t('common:admin.user.labels.balance')}: `}
                    {euro_balance}
                  </UserLabel>
                  <UserLabel>
                    {' '}
                    {`${t('common:admin.user.labels.createdAt')}: `}{' '}
                    {formatAPIDateString({ dateString: created_at })}
                  </UserLabel>
                  <UserLabel>
                    {' '}
                    {`${t('common:admin.user.labels.updatedAt')}: `}{' '}
                    {formatAPIDateString({ dateString: updated_at })}
                  </UserLabel>

                  <UserLabel color={subscription ? 'blue' : 'red'}>
                    {' '}
                    {subscription
                      ? t('common:admin.user.labels.subscriptionActive')
                      : t('common:admin.user.labels.noSubscription')}
                  </UserLabel>
                </LabelContainer>
              )}
            </NameContainer>
            {!simple && (
              <EditUserModal shareTm={shareTm} isAdmin={isAdmin} user={user} fetchUser={fetchUser} />
            )}
          </HeadContainer>
        </AvatarContainer>
        {description && !simple && (
          <>
            <StyledHeading as="h4">{`${t('common:userInfo.labels.aboutMe')}: `}</StyledHeading>
            <p>{description}</p>
          </>
        )}
      </Container>
    </Card>
  );
};

export default withTranslation('common')(UserProfileCard);
