import httpClient from './httpclient';

export const getAllUsers = async ({
  page = 1,
  per_page = 14,
  search = '',
  roles = [],
  sortBy = 'created_at',
  sort_order = 'desc',
}) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/users`, {
      params: {
        page: page,
        per_page: per_page,
        search: search,
        role: roles,
        sort_by: sortBy,
        sort_order: sort_order,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getUser = async (user_id) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/getuser/${user_id}`);
    return data.data;
  } catch (e) {
    throw e;
  }
};

export const getAdminUsers = async () => {
  try {
    // Get total number
    const { data } = await httpClient.get(`/auth/admin/users`, {
      params: {
        role: [29],
        per_page: 1000,
      },
    });

    return data;
  } catch (e) {
    throw e;
  }
};

export const updateSubscription = async (user_id) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/updatesubscription`, { user_id: user_id });
    return data.data;
  } catch (e) {
    throw e;
  }
};

export const updateInvitation = async ({ acceptInvite = 1, invitationId }) => {
  try {
    const { data } = await httpClient.post(`/auth/team-invitation/${invitationId}/update`, {
      accept_invite: acceptInvite,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getUserSuggestions = async ({ searchQuery, userType = 'user' }) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/user-suggestions`, {
      params: {
        search: searchQuery,
        type: userType,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getTranslationMemories = async ({ id, category_id }) => {
  try {
    const { data } = await httpClient.get(`/auth/user/${id}/translation-memories`, {
      params: {
        category_id,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getTmSettings = async (id) => {
  try {
    const { data } = await httpClient.get(`/auth/user/${id}/tm-setting`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postTmSettings = async ({ tm_settings_id, share_tm }) => {
  try {
    const { data } = await httpClient.post(`/auth/tm-setting/${tm_settings_id}/update`, {
      share_tm: share_tm,
    });
    return data;
  } catch (e) {
    throw e;
  }
};
