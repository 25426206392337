import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Loader } from 'semantic-ui-react';
import { Icon } from '../../common/Icon/Icon';
import Table from '../../common/Table/Table';
import Price from '../../common/Price/Price';
import PricePerWord from '../../Project/OrderProject/Jobs/Job/PricePerWord';
import TmxDropdown from './TmxDropdown';
import { hasAdminPermissions } from '../../../utils/user.utils';
import Button from '../../common/Button/Button';
import TargetLanguages from '../../common/ProjectLanguages/TargetLanguages';

import { getJobStatusTranslationKeys } from '../../../utils/jobs.utils';
import { formatAPIDateString } from 'utils/date.utils';
import AssignVendorModal from './AssignVendorModal/AssignVendorModal';
import { VENDOR_TASK_TYPE_TRANSLATION } from 'constants/vendors';
import { VENDOR_TASK_TYPE_REVISION } from 'constants/vendors';
import VendorCell from '../ServicesTable/VendorCell/VendorCell';

const TYPES = {
  proofread: <Trans i18nKey="common:website.project.proofread">Proofread</Trans>,
  service_1: <Trans i18nKey="common:website.project.machine_translation">Machine Translation</Trans>,
  service_2: <Trans i18nKey="common:website.project.mt_lr">Machine translation and Light Review</Trans>,
  service_3: <Trans i18nKey="common:website.project.translation">Translation</Trans>,
  service_4: (
    <Trans i18nKey="common:website.project.translation_with_revision_no_break">
      Translation with Revision
    </Trans>
  ),
  service_5: (
    <Trans i18nKey="common:website.project.translation_revision_proofread">
      Translation with revision and Additional proof-reading
    </Trans>
  ),
};

const JobRow = ({
  job,
  onSubmit = () => {},
  translators,
  user,
  currency,
  status_id,
  updateJob = () => {},
}) => {
  const { revision_end, translation_end, revisioner, translator_id } = job;

  const [translator, setTranslator] = useState(null); //translator_id ? findVendor(translator_id) : null;
  const [reviser, setReviser] = useState(null); //revisioner ? findVendor(revisioner) : null;
  const [reviserOpen, setReviserOpen] = useState(false);
  const [translatorOpen, setTranslatorOpen] = useState(false);
  const [revisionEnd, setRevisionEnd] = useState(revision_end);
  const [translationEnd, setTranslationEnd] = useState(translation_end);

  const { t } = useTranslation();

  const jobPrice = (job) => {
    const servicePrice =
      job.proofread_price !== null
        ? job.proofread_price
        : job.service_1_selected && job.proofread_price === null
        ? job.service_1_price
        : job.service_2_selected && job.proofread_price === null
        ? job.service_2_price
        : job.service_3_selected && job.proofread_price === null
        ? job.service_3_price
        : job.service_4_selected && job.proofread_price === null
        ? job.service_4_price
        : job.service_5_selected && job.proofread_price === null
        ? job.service_5_price
        : 0;
    return servicePrice + (job.dtp_selected === 1 ? job.dtp_price : 0);
  };

  const handleTranslatorSubmit = (vendor, date) => {
    setTranslator(vendor);
    setTranslationEnd(date);
  };

  const handleReviserSubmit = (vendor, date) => {
    setReviser(vendor);
    setRevisionEnd(date);
  };

  const statusTranslationKeys = getJobStatusTranslationKeys(job);
  return (
    <>
      <AssignVendorModal
        swap={!!translator}
        open={translatorOpen}
        onOpen={() => setTranslatorOpen(true)}
        onClose={() => setTranslatorOpen(false)}
        job={job}
        onSubmit={handleTranslatorSubmit}
        taskType={VENDOR_TASK_TYPE_TRANSLATION}
      />
      <AssignVendorModal
        swap={!!reviser}
        open={reviserOpen}
        onOpen={() => setReviserOpen(true)}
        onClose={() => setReviserOpen(false)}
        job={job}
        onSubmit={handleReviserSubmit}
        taskType={VENDOR_TASK_TYPE_REVISION}
      />
      <Table.Row key={job.id}>
        <Table.Cell>
          <TargetLanguages languages={job.target_language} />
        </Table.Cell>
        <Table.Cell>
          {job.translator_payable_words ? job.translator_payable_words : job.total_words}
        </Table.Cell>
        <Table.Cell>{job.total_words}</Table.Cell>
        {status_id !== 10 && status_id !== 11 && (
          <>
            <Table.Cell>
              <PricePerWord currencyData={currency} job={job} />
            </Table.Cell>
            <Table.Cell>
              {job.proofread_price !== null ? TYPES.proofread : null}
              {job.service_1_selected && job.proofread_price === null ? TYPES.service_1 : null}
              {job.service_2_selected && job.proofread_price === null ? TYPES.service_2 : null}
              {job.service_3_selected && job.proofread_price === null ? TYPES.service_3 : null}
              {job.service_4_selected && job.proofread_price === null ? TYPES.service_4 : null}
              {job.service_5_selected && job.proofread_price === null ? TYPES.service_5 : null}
              {job.dtp_selected ? <span>, DTP</span> : <span />}
            </Table.Cell>
            <Table.Cell>
              <Price price={jobPrice(job)} currency={currency}></Price>
            </Table.Cell>
            <Table.Cell>
              {job.delivery_time && <>{formatAPIDateString({ dateString: job.delivery_time })}</>}
            </Table.Cell>
            {hasAdminPermissions(user.role) && (
              <>
                <Table.Cell>
                  {translator ? (
                    <VendorCell
                      endDate={translationEnd}
                      swap={() => {
                        setTranslatorOpen(true);
                      }}
                      vendor={translator}
                    />
                  ) : translator_id ? (
                    <Loader inline active />
                  ) : (
                    <Button
                      actiontype="primary"
                      disabled={status_id < 3}
                      fluid
                      onClick={() => {
                        setTranslatorOpen(true);
                      }}
                    >
                      {t('common:admin.project.jobs.assignTranslator')}
                    </Button>
                  )}
                </Table.Cell>
                {job.proofread !== 1 && (
                  <Table.Cell>
                    {reviser ? (
                      <VendorCell endDate={revisionEnd} swap={() => setReviserOpen(true)} vendor={reviser} />
                    ) : revisioner ? (
                      <Loader inline active />
                    ) : (
                      <Button
                        actiontype="primary"
                        disabled={status_id < 3}
                        fluid
                        onClick={() => setReviserOpen(true)}
                      >
                        {t('common:admin.project.jobs.assignRevisor')}
                      </Button>
                    )}
                  </Table.Cell>
                )}
              </>
            )}
            <Table.Cell>
              {statusTranslationKeys.map((key, index) => {
                const needsSeparator =
                  statusTranslationKeys.length > 1 && index < statusTranslationKeys.length - 1;

                return <span key={key}>{`${t(key)}${needsSeparator ? ', ' : ''}`}</span>;
              })}
            </Table.Cell>
          </>
        )}
        <Table.Cell>
          <TmxDropdown jobId={job.id} tmxUrl={job.tmx_url} updateJob={updateJob} />
        </Table.Cell>
        {hasAdminPermissions(user.role) && (
          <Table.Cell>
            <a href={job.job_url} rel="noopener noreferrer" target="_blank">
              <Icon name="external-link-alt" />
            </a>
          </Table.Cell>
        )}
      </Table.Row>
    </>
  );
};

const JobsTable = ({ jobs, user, currency, status_id, onSubmit, translators = [], updateJob }) => {
  const { t } = useTranslation();
  const tableRows = jobs.map((job) => {
    return (
      <JobRow
        job={job}
        onSubmit={onSubmit}
        translators={translators}
        currency={currency}
        status_id={status_id}
        user={user}
        updateJob={updateJob}
      ></JobRow>
    );
  });
  return (
    <>
      <Table marginTop="0" width="100%">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:admin.project.jobs.targetLanguage')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:admin.project.jobs.payableWords')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:admin.project.jobs.totalWords')}</Table.HeaderCell>
            {status_id !== 10 && status_id !== 11 && (
              <>
                <Table.HeaderCell>{t('common:admin.project.jobs.ppw')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:admin.project.jobs.jobType')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:admin.project.jobs.jobPrice')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:admin.project.jobs.deliveryDate')}</Table.HeaderCell>
                {hasAdminPermissions(user.role) && (
                  <>
                    <Table.HeaderCell>{t('common:admin.project.jobs.assignTranslator')}</Table.HeaderCell>
                    {jobs[0].proofread !== 1 && (
                      <Table.HeaderCell>{t('common:admin.project.jobs.assignRevisor')}</Table.HeaderCell>
                    )}
                  </>
                )}
                <Table.HeaderCell>{t('common:admin.project.jobs.status')}</Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell>{t('common:admin.project.jobs.tmx')}</Table.HeaderCell>
            {hasAdminPermissions(user.role) && <Table.HeaderCell>CAT</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    </>
  );
};

export default JobsTable;
