import styled from 'styled-components';

export const PriceInputContainer = styled.div`
  width: 100%;
  font-size: 1em;

  position: relative;

  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: baseline;
  ${({ theme }) => theme.inputStyle}

  &:focus-within {
    border-color: #96c8da;
    .currency-symbol {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  .currency-symbol {
    transition: color 0.1s ease;
  }

  .price-amount {
    margin-left: 0.67857143em !important;
    margin-right: 0.67857143em !important;
    width: auto;
    position: relative;
    input {
      width: 100%;
      resize: horizontal;
      padding: 0 !important;
      appearance: none;
      line-height: 1.21428571em;
      font-size: 1em;
      outline: none;
      border: none !important;
      min-width: 58px;
    }
  }

  .currency-select {
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const RateInputContainer = styled.div`
  position: relative;

  ${({ theme }) => theme.inputStyle}

  padding: 0;

  .price-amount {
    height: 100%;

    input {
      border: none;
      padding: 11px 39px 11px 29px;
      width: 100%;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  .currency-symbol,
  .currency-select {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .currency-symbol {
    left: 10px;
    width: 14px;
  }

  .currency-select {
    right: 10px;
    font-size: 0.75rem;
    line-height: 0.75rem;
    width: 24px;
  }
`;
