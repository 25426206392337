import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NormalContainer } from './CompanyHeader.styles';

import { checkAllowedRole } from '../../utils/user.utils';
import AddTeam from './Teams/AddTeam';
import InviteUsersModal from './CompanyInvites/InviteUsersModal/InviteUsersModal';
import NewCompanyBillingButton from './CompanyBilling/NewCompanyBillingButton';

const CompanyData = ({ companyData }) => {
  const { tabName = 'teams' } = useParams();
  // react i18n translation utils
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);

  return (
    <NormalContainer>
      <div>
        <h2>{t('common:company.companyManagement')}</h2>
        <h3>{companyData?.company_name}</h3>
      </div>
      {tabName === 'teams' && checkAllowedRole({ allowedRoles: [29, 40], userRole: user.role }) && (
        <AddTeam />
      )}
      {tabName === 'members' && <InviteUsersModal />}
      {tabName === 'invites' && <InviteUsersModal />}
      {tabName === 'billing' && <NewCompanyBillingButton company_id={companyData.id} />}
      {/* 
      not yet supported by backend
      {tabName === 'invoices' && (
        <Button>
          <Icon name="import" />
          {t('common:company.invoices.downloadAll')}
        </Button>
      )} */}
    </NormalContainer>
  );
};

export default CompanyData;
