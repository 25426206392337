import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Utils
import { classnames } from 'utils/base.utils';

// Compontents
import UserAvatar from '../UserAvatar/UserAvatar';
import { SelectDropdown } from '../Dropdown/SelectDropdown';
import { Popup } from '../Popup/Popup';
import { Loader } from 'semantic-ui-react';
import { Icon } from '../Icon/Icon';
import Button from 'components/common/Button/Button';

// Styles
import './style.scss';

/**
 * Render assign person component
 */
const AssignPerson = ({
  displayName,
  choices = [],
  currentPerson,
  onAssign,
  className,
  loading,
  labels = {},
  popupProps = {},
}) => {
  // Local state
  const [selectedUser, setSelectedUser] = useState(currentPerson?.id ? currentPerson.id : null);
  const [popupOpen, setPopupOpen] = useState(false);

  // Global state
  const user = useSelector((state) => state.userStore.user);

  // Hooks
  const { t } = useTranslation();

  // DOM refs
  const triggerRef = useRef(null);
  const unassignBtnRef = useRef(null);

  // Methods
  const handleAssign = (id) => {
    // Close popup if it is open
    if (popupOpen) togglePopup();

    onAssign(id);
  };

  const handleUserChange = (_, { value }) => {
    handleAssign(value);
    setSelectedUser(value);
  };

  const handleAssignToSelf = () => {
    handleAssign(user.id);
  };

  const togglePopup = () => {
    setPopupOpen((c) => !c);
  };

  const handleTriggerClick = (e) => {
    if (e.target.classList.contains('unassign')) return;

    togglePopup();
  };

  const handleUnassign = () => {
    handleAssign(null);
  };

  // Closes popup, useCallback to store function ref on rerender
  const closePopup = useCallback(() => {
    setPopupOpen(false);
  }, [setPopupOpen]);

  // Update selected person on current person prop change
  useEffect(() => {
    setSelectedUser(currentPerson?.id);
  }, [currentPerson]);

  // Render variables
  const texts = {
    title: t('common:assignPerson.defaults.title'),
    noPerson: t('common:assignPerson.defaults.noPerson'),
    assignToSelf: t('common:assignPerson.defaults.assignToSelf'),
    placeholder: t('common:assignPerson.defaults.placeholder'),
    unassignHoverTitle: t('common:assignPerson.defaults.unassignHoverTitle'),
    // Can override default texts by passing labels prop
    ...labels,
  };
  const currentPmName = currentPerson?.name ? currentPerson.name : texts.noPerson;
  const mainCssClasses = classnames('assign-person', className);
  const triggerCssClasses = classnames('trigger button-reset', {
    loading,
    'popup-open': popupOpen,
    'no-person': !currentPerson,
  });
  const showAssignToSelf = currentPerson?.id !== user.id;
  const dropdownOptions = choices.map(({ name, id, avatar }) => ({
    icon: <UserAvatar src={avatar} />,
    text: name,
    value: id,
  }));

  return (
    <div className={mainCssClasses}>
      <Popup
        onClose={closePopup}
        open={popupOpen}
        hideOnScroll
        dark
        trigger={
          <button
            disabled={loading}
            ref={triggerRef}
            onClick={handleTriggerClick}
            className={triggerCssClasses}
          >
            {/** Avatar */}
            <div className="user-icon">
              <UserAvatar src={currentPerson?.avatar} />
            </div>
            {/** Currently selected person name */}
            {displayName && (
              <div className="current">
                {currentPmName}
                {/** Using role button to be able to display title on hover */}
                {!!currentPerson && (
                  <div
                    role="button"
                    title={texts.unassignHoverTitle}
                    className="unassign"
                    onClick={handleUnassign}
                    ref={unassignBtnRef}
                  >
                    <Icon name="times" />
                  </div>
                )}
              </div>
            )}
            {/** Loading overlay */}
            <div className="loading-overlay">
              <Loader size="tiny" active />
            </div>
          </button>
        }
        content={
          <div className="assign-person-popup">
            <p>{texts.title}</p>
            <div className="inputs">
              <SelectDropdown
                value={selectedUser}
                onChange={handleUserChange}
                search
                options={dropdownOptions}
                placeholder={texts.placeholder}
              />
              {showAssignToSelf && (
                <>
                  <span>or</span>
                  <Button onClick={handleAssignToSelf}>{texts.assignToSelf}</Button>
                </>
              )}
            </div>
          </div>
        }
        {...popupProps}
      />
    </div>
  );
};

export default AssignPerson;
