import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 23px;
  font-family: 'DM Sans', sans-serif !important;
  color: #232323;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  width: 100%;
  margin-top: 15px;
`;
