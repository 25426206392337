import React from 'react';

import { useTranslation } from 'react-i18next';
import { CardsContainer } from './ProjectsCards.styles';
import UpdateWrapper from './UpdateWrapper';
import ProjectCard from './ProjectCard/ProjectCard';
import Message from '../../common/Message/Message';

const ProjectsCards = ({ projects, reloadProjects = () => {} }) => {
  const { t } = useTranslation();
  return projects.length > 0 ? (
    <CardsContainer>
      {projects.map((project) => (
        <UpdateWrapper key={project.id} project={project} reloadProjects={reloadProjects}>
          <ProjectCard project={project} reloadProjects={reloadProjects} />
        </UpdateWrapper>
      ))}
    </CardsContainer>
  ) : (
    <Message text={t('common:projects.noProjectsFound')} type="info" />
  );
};

export default ProjectsCards;
