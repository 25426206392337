import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  ListItem,
  ListItemAnnotation,
  ListItemText,
  ListItemTextContainer,
  Title,
} from './Features.styles';
import { Icon } from 'components/common/Icon/Icon';

const LIST_ITEMS_TRANSLATION_PATH = 'common:catapult.packages.packagesContent';

const LIST_ITEMS_TRANSLATION_KEYS = [
  'mt',
  'pm',
  'formatting',
  'languages',
  'storage',
  'progress',
  'autosave',
  'users',
  'tm',
  'fileFormats',
  'translations',
  'collab',
];

const Features = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title>{t(`${LIST_ITEMS_TRANSLATION_PATH}.title`)}</Title>
      <Container>
        {LIST_ITEMS_TRANSLATION_KEYS.map((translationKey) => {
          const path = `${LIST_ITEMS_TRANSLATION_PATH}.${translationKey}`;

          return (
            <ListItem key={translationKey}>
              <Icon color="#FFB202" name="check-circle" size={29} />
              <ListItemTextContainer>
                <ListItemText>{t(`${path}.text`, '')}</ListItemText>
                <ListItemAnnotation>{t(`${path}.annotation`, '')}</ListItemAnnotation>
              </ListItemTextContainer>
            </ListItem>
          );
        })}
      </Container>
    </>
  );
};

export default Features;
