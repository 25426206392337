import styled from 'styled-components';

export const Notice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  margin-bottom: 8px;
`;
