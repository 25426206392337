import styled from 'styled-components';

export const SubscriptionPackageWrapper = styled.div`
  max-width: 800px;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
  margin-right: auto;
  margin-left: auto;
  .subscription-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 20px;

    h1 {
      margin-bottom: 0px !important;
    }
    .subscription-labels {
      margin-left: 16px;

      .label {
        margin-right: 8px;
        padding: 2px;
      }
    }

    .subscription-menu {
      margin-left: auto;
    }
  }

  .plan-information {
    h2 {
      margin-bottom: 18px !important;
    }
    .plan-field {
      margin-bottom: 22px;
      .field-label {
        font-size: 10px;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
  }

  .pricing {
    margin-bottom: 48px;
    h2 {
      margin-bottom: 16px !important;
    }
  }
`;

export const PricingRowWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 15px;
  .header-text {
    font-size: 1.25rem;
  }

  .header-menu {
    margin-left: auto;
  }
`;

export const AddPricing = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue};
  padding: 15px;
  cursor: pointer;
  .header-text {
    font-size: 1.25rem;
  }

  .header-icon {
    margin-left: auto;
  }
`;
