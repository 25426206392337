import httpClient from './httpclient';

export const postCheckVat = async (vat) => {
  try {
    const { data } = await httpClient.post(`/auth/checkvat`, { vat_id: vat });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postBillingCreate = async (payload) => {
  try {
    const { data } = await httpClient.post(`/auth/billing`, payload);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postBillingUpdate = async (billing_id, payload) => {
  try {
    const { data } = await httpClient.post(`/auth/billing/${billing_id}/update`, payload);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getBillingDetails = async (id) => {
  if (id) {
    try {
      const { data } = await httpClient.get(`/auth/billing/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  }
};

// Teams Feature
export const getCompanyBillingDetailsTeams = async (company_id) => {
  if (company_id) {
    try {
      const { data } = await httpClient.get(`/auth/company/${company_id}/billing`);
      return data;
    } catch (e) {
      throw e;
    }
  }
};

export const postCompanyBillingDetailsTeams = async (
  company_id,
  company_name,
  company_vat,
  company_state,
  company_street,
  project_invoices,
  user_email,
  discount,
  verified,
) => {
  try {
    const { data } = await httpClient.post(`/auth/company/${company_id}/billing`, {
      company_name: company_name,
      company_vat: company_vat,
      company_state: company_state,
      company_street: company_street,
      project_invoices: project_invoices,
      user_email: user_email,
      discount: discount,
      verified: verified,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getBillingDetailsTeams = async (billing_id) => {
  if (billing_id) {
    try {
      const { data } = await httpClient.get(`/auth/billing/${billing_id}`);
      return data;
    } catch (e) {
      throw e;
    }
  }
};

export const postBillingDetailsTeamsUpdate = async (payload) => {
  try {
    const { data } = await httpClient.post(`/auth/billing/${payload.id}/update`, payload);
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteBillingDetailsTeams = async (billing_id) => {
  try {
    const { data } = await httpClient.post(`/auth/billing/${billing_id}/delete`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postBillingCoupon = async ({ billing_id, coupon_code }) => {
  try {
    const { data } = await httpClient.post(`/auth/billing/${billing_id}/coupon`, { coupon_code });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPaymentMethods = async ({ billing_id }) => {
  if (billing_id) {
    try {
      const { data } = await httpClient.get(`/auth/billing/${billing_id}/payment-methods`);
      return data;
    } catch (e) {
      throw e;
    }
  }
};

export const getDefaultPaymentMethod = async ({ billing_id }) => {
  if (billing_id) {
    try {
      const { data } = await httpClient.get(`/auth/billing/${billing_id}/payment-methods/default`);
      return data;
    } catch (e) {
      throw e;
    }
  }
};

export const patchPaymentMethod = async ({ billing_id, payment_method }) => {
  try {
    const { data } = await httpClient.patch(`/auth/billing/${billing_id}/payment-methods/default`, {
      payment_method,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postPaymentMethod = async ({ billing_id, payment_method }) => {
  try {
    const { data } = await httpClient.post(`/auth/billing/${billing_id}/payment-methods`, { payment_method });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getSetupIntent = async (billing_id) => {
  if (billing_id) {
    try {
      const { data } = await httpClient.get(`/auth/billing/${billing_id}/stripe-setup-intent`);
      return data.intent;
    } catch (e) {
      throw e;
    }
  }
};

export const getCustomer = async (billing_id) => {
  if (billing_id) {
    try {
      const { data } = await httpClient.get(`/auth/billing/${billing_id}/stripe-customer`);
      return data;
    } catch (e) {
      throw e;
    }
  }
};

export const postCustomer = async ({
  billing_id,
  line1,
  line2,
  postal_code,
  city,
  state,
  name,
  tax_exempt,
  description,
}) => {
  try {
    const { data } = await httpClient.post(`/auth/billing/${billing_id}/stripe-customer`, {
      line1,
      line2,
      postal_code,
      city,
      state,
      name,
      tax_exempt,
      description,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const patchCustomer = async ({
  billing_id,
  line1,
  line2,
  postal_code,
  city,
  state,
  name,
  tax_exempt,
  description,
}) => {
  try {
    const { data } = await httpClient.patch(`/auth/billing/${billing_id}/stripe-customer`, {
      line1,
      line2,
      postal_code,
      city,
      state,
      name,
      tax_exempt,
      description,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postSubscription = async ({ billing_id, plan_id, quantity, payment_method }) => {
  try {
    const { data } = await httpClient.post(`/auth/billing/${billing_id}/subscriptions`, {
      plan_id,
      quantity,
      payment_method,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPaymentIntent = async (token) => {
  try {
    const { data } = await httpClient.get(`/auth/payment-intents/${token}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const patchPaymentIntent = async ({ token, paid }) => {
  try {
    const { data } = await httpClient.get(`/auth/payment-intents/${token}`, { paid });
    return data;
  } catch (e) {
    throw e;
  }
};
