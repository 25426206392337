import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from 'components/common/Table/Table';
import { Popup } from 'semantic-ui-react';
import { convertPrice, localiseAndConvertPrice } from 'utils/string.utils';
import { hasAdminPermissions } from 'utils/user.utils';

const ServicePrice = ({ currency, price, tasks = [] }) => {
  const { i18n, t } = useTranslation();

  const user = useSelector((state) => state.userStore.user);

  const showVendorUnits = useMemo(() => {
    return hasAdminPermissions(user.role);
  }, [user]);

  const servicePrice = localiseAndConvertPrice({
    currencyData: currency,
    language: i18n.language,
    number: price,
  });

  return (
    <Popup flowing hoverable trigger={<span>{servicePrice}</span>}>
      <Table marginTop="0">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:admin.project.services.tasks.task')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:admin.project.services.tasks.unit')}</Table.HeaderCell>
            {showVendorUnits && (
              <Table.HeaderCell>{t('common:admin.project.services.tasks.vendorUnits')}</Table.HeaderCell>
            )}
            <Table.HeaderCell>{t('common:admin.project.services.tasks.chargedUnits')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:admin.project.services.tasks.pricePerUnit')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:admin.project.services.tasks.price')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tasks.map((task) => {
            const { assignment_quantity, id, net_price, price_per_unit, task_type, unit, quantity } = task;

            const localisedNetPrice = localiseAndConvertPrice({
              currencyData: currency,
              language: i18n.language,
              number: net_price,
            });

            const convertedPricePerUnit = convertPrice({
              currencyData: currency,
              number: price_per_unit,
            });

            return (
              <Table.Row key={`service-task-${id}`}>
                <Table.Cell>{task_type.name}</Table.Cell>
                <Table.Cell>{unit.display_name}</Table.Cell>
                {showVendorUnits ? <Table.Cell textAlign="center">{assignment_quantity}</Table.Cell> : null}
                <Table.Cell textAlign="center">{quantity}</Table.Cell>
                <Table.Cell textAlign="center">{convertedPricePerUnit}</Table.Cell>
                <Table.Cell textAlign="right">{localisedNetPrice}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Popup>
  );
};

export default ServicePrice;
