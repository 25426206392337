import { Popup } from 'components/common/Popup/Popup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLabel, QuoteButton } from '../OrderProject.styles';

const GenerateQuoteButton = ({
  billingDetailID,
  loading,
  canCreateQuote,
  step,
  stepsLength,
  handleClick,
}) => {
  const { t } = useTranslation();

  const Button = (
    <QuoteButton
      secondary
      fluid
      loading={loading}
      disabled={!canCreateQuote || step !== stepsLength}
      onClick={handleClick}
    >
      {t('common:checkout.orderSummary.downloadQuote')}
      <StyledLabel>PDF</StyledLabel>
    </QuoteButton>
  );

  const popupText = () => {
    if (step !== stepsLength) return t('common:projects.stepNotice');

    return t('common:projects.quoteNotAvailable');
  };

  if (billingDetailID === null || step !== stepsLength) {
    return (
      <Popup trigger={<span style={{ display: 'block' }}>{Button}</span>} dark style={{ width: '350px' }}>
        {popupText()}
      </Popup>
    );
  }

  return Button;
};

export default GenerateQuoteButton;
