import React from 'react';
import { withTranslation } from 'react-i18next';
import Table from '../../../common/Table/Table';
import TeamMemberRow from './TeamMemberRow';

const TeamMembersTable = ({ t, members, onSubmit }) => {
  const tableRows = members.map((member) => {
    return <TeamMemberRow key={member.id} member={member} onSubmit={onSubmit}></TeamMemberRow>;
  });

  return (
    <Table width="100%" marginTop="0">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('common:team.members.user')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.members.role')}</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">{t('common:team.members.contactPerson')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.members.joinedOn')}</Table.HeaderCell>
          <Table.HeaderCell style={{ minWidth: '110px' }}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {members.length === 0 ? (
          <Table.Row>
            <Table.Cell textAlign="center" colSpan="5">
              {t('common:team.members.noMembers')}
            </Table.Cell>
          </Table.Row>
        ) : (
          tableRows
        )}
      </Table.Body>
    </Table>
  );
};

export default withTranslation('common')(TeamMembersTable);
