import { MainContainer } from 'components/common.styles';
import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 4rem;
`;

export const FilterButtonContainer = styled.div`
  position: fixed;
  right: 1rem;
  top: calc(63px + 1rem);
  z-index: 1;
`;

export const ProjectsContainer = styled(MainContainer)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: ${theme.paddings.mainContainer.mobile.full};
    overflow-x: hidden;
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 54px;
  min-height: min-content;

  .project-filter-sidebar {
    height: initial;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin: 17px 0;
    justify-content: initial;
    position: sticky;
    top: 0;
    width: calc(100% + (${theme.paddings.mainContainer.mobile.x} * 2));
    margin: 0 0 ${theme.paddings.mainContainer.mobile.x} -${theme.paddings.mainContainer.mobile.x};
    padding: 17px ${theme.paddings.mainContainer.mobile.x};
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.sidebar - 1};
    box-shadow: ${theme.shadows.softDown};
    min-height: min-content;

    .button {
      flex-grow: 1;
      font-size: 18px;
    }

    .project-filter-sidebar {
      > .button {
        margin-right: 0;
        width: 46px !important;
      }
    }
  `}
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

export const StyledHeader = styled.h2`
  font-size: 2.14285714em;
  font-family: 'DM Sans', sans-serif;
  font-weight: normal;
  line-height: 1.4em;
  margin: 0;
  margin-right: auto;
`;
