import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAdminInvoices, getInvoices } from '../../services/project';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import {
  AdminInvoicesLayout,
  StyledDropdown,
  StyledInput,
  StyledPagination,
  Toolbar,
} from './AdminInvoices.style';
import DatepickerRange from '../common/Datepicker/DatepickerRange';
import LoadingPane from '../common/LoadingPane/LoadingPane';
import AdminInvoicesTable from './AdminInvoicesTable/AdminInvoicesTable';
import Message from '../common/Message/Message';
import { Dropdown } from 'semantic-ui-react';
import { withDebounce } from '../../hoc/debouncer';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import Button from 'components/common/Button/Button';

// styles
import './style.scss';

// Filter defaults
const filterDefaults = {
  search: '',
  issuedDates: [null, null],
  dueDates: [null, null],
  paidFilter: null,
  typeFilter: 'both',
};

const AdminInvoices = ({ debounce }) => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useLocalStorage('admin-invoices-page', 1);
  const [lastPage, setLastPage] = useState(1);
  const [paidFilter, setPaidFilter] = useLocalStorage(
    'admin-invoices-paid-filter',
    filterDefaults.paidFilter,
  );
  const [typeFilter, setTypeFilter] = useLocalStorage(
    'admin-invoices-type-filter',
    filterDefaults.typeFilter,
  );
  const [issuedDates, setIssuedDates] = useState(filterDefaults.issuedDates);
  const [dueDates, setDueDates] = useState(filterDefaults.dueDates);
  const [search, setSearch] = useLocalStorage('admin-invoices-search', filterDefaults.search);

  const handleClear = () => {
    // Clear all filters
    setSearch(filterDefaults.search);
    setIssuedDates(filterDefaults.issuedDates);
    setDueDates(filterDefaults.dueDates);
    setPaidFilter(filterDefaults.paidFilter);
    setTypeFilter(filterDefaults.typeFilter);
  };

  const { t } = useTranslation();

  const paidOptions = [
    { key: 0, text: t('common:notSet'), value: null },
    { key: 1, text: t('common:team.invoices.paid'), value: 1 },
    { key: 2, text: t('common:team.invoices.notPaid'), value: 0 },
  ];

  const typeOptions = [
    { key: 0, text: t('common:team.invoices.both'), value: 'both' },
    { key: 1, text: t('common:team.invoices.sub'), value: 'subscription' },
    { key: 2, text: t('common:team.invoices.project'), value: 'project' },
  ];

  const formatDate = (date) => {
    if (date === null) return null;

    if (!!date) return format(zonedTimeToUtc(date, 'Europe/Ljubljana'), 'P');

    return '';
  };

  useEffect(() => {
    const fetchInitialInvoices = async () => {
      debounce(
        'adminInvoices',
        async () => {
          try {
            setIsLoading(true);
            const data = await getAdminInvoices({
              page: page,
              project_paid: paidFilter,
              payment_due_from: formatDate(dueDates[0]),
              payment_due_to: formatDate(dueDates[1]),
              issued_on_from: formatDate(issuedDates[0]),
              issued_on_to: formatDate(issuedDates[1]),
              search: search,
              type: typeFilter === 'both' ? null : typeFilter,
            });

            setInvoices(data.data);
            if (page > data.last_page) {
              setPage(data.last_page);
            }

            setLastPage(data.last_page);
          } catch (e) {
            setIsError(true);
            throw e;
          } finally {
            setIsLoading(false);
          }
        },
        500,
      );
    };
    fetchInitialInvoices();
  }, [page, paidFilter, typeFilter, issuedDates, dueDates, search, debounce, setPage]);

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('admin invoices page change');
    setPage(activePage);
  };

  const handlePaidChange = (e, target) => {
    sendUserInteraction('admin invoices filter by paid/unpaid');
    setPaidFilter(target.value);
  };

  const handleTypeChange = (e, target) => {
    sendUserInteraction('admin invoices filter by type');
    setTypeFilter(target.value);
  };

  const onSearchChange = (e, { value }) => {
    sendUserInteraction('admin invoices search');
    setSearch(value);
  };

  const downloadInvoices = async (e) => {
    sendUserInteraction('admin invoices download invoices');
    e.preventDefault();
    setIsLoading(true);

    try {
      // get file blob
      const response = await getInvoices({
        project_paid: paidFilter,
        payment_due_from: dueDates[0] !== null ? formatDate(dueDates[0]) : null,
        payment_due_to: dueDates[1] !== null ? formatDate(dueDates[1]) : null,
        issued_on_from: issuedDates[0] !== null ? formatDate(issuedDates[0]) : null,
        issued_on_to: issuedDates[1] !== null ? formatDate(issuedDates[1]) : null,
        search: search,
        type: typeFilter === 'both' ? null : typeFilter,
      });
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1];
      const decodedFileName = decodeURIComponent(fileName).replace(/"/g, '').replace(/\+/g, ' ');

      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, decodedFileName);
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {};
  return (
    <AdminInvoicesLayout className="admin-invoices">
      <Toolbar>
        {lastPage > 1 && (
          <StyledPagination activePage={page} totalPages={lastPage} onPageChange={handlePaginationChange} />
        )}

        <StyledDropdown selection value={typeFilter} options={typeOptions} onChange={handleTypeChange} />
        <Dropdown selection value={paidFilter} options={paidOptions} onChange={handlePaidChange} />
        <StyledInput
          placeholder={t('common:team.invoices.labels.search')}
          value={search}
          onChange={onSearchChange}
        />
        <DatepickerRange
          startDate={issuedDates[0]}
          endDate={issuedDates[1]}
          onChange={(dates) => {
            sendUserInteraction('admin invoices filter by issued date');
            setIssuedDates(dates);
          }}
          placeholder="Issued date"
        ></DatepickerRange>
        <DatepickerRange
          startDate={dueDates[0]}
          endDate={dueDates[1]}
          onChange={(dates) => {
            sendUserInteraction('admin invoices filter by date due');
            setDueDates(dates);
          }}
          placeholder="Payment due"
        ></DatepickerRange>
        <Button onClick={handleClear}>{t('common:clear')}</Button>
      </Toolbar>

      {isLoading ? (
        <LoadingPane />
      ) : (
        <AdminInvoicesTable invoices={invoices} onSubmit={onSubmit} downloadInvoices={downloadInvoices} />
      )}
      {isError && <Message text={t('common:team.invoices.error')} type={'error'} />}
    </AdminInvoicesLayout>
  );
};

export default withDebounce(AdminInvoices);
