import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Header } from 'semantic-ui-react';
import {
  ProjectCreateWrapper,
  StyledHeader,
  StyledTitle,
  FormGroup,
  StyledCheckbox,
  AnalysisStatus,
} from './AdminProjectCreate.style';
import Message from 'components/common/Message/Message';
import FileUpload from 'components/common/FileUpload/FileUpload';
import Accordion from 'components/common/Accordion/Accordion';
import { Icon } from 'components/common/Icon/Icon';
import Button from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import CustomerSuggestions from 'components/common/CustomerSuggestions/CustomerSuggestions';
import { useSelector } from 'react-redux';
import { postCustomProject } from 'services/project';
import { useHistory } from 'react-router';
import { SelectDropdown, MultipleDropdown } from 'components/common/Dropdown/Dropdown';
import { StyledDropdownItem } from 'components/NewProject/CreateProject/NameAndLanguages/NameAndLanguages.styles';
import { AddLanguage } from 'components/NewProject/CreateProject/NameAndLanguages/NameAndLanguages.styles';
import { useAnalysisStatus } from 'hooks/useAnalysisStatus';
import { postAnalysisFail } from 'services/project';
import { postAnalysisSuccessful } from 'services/project';
import { toast } from 'react-toastify';
import { useValidation } from 'hooks/useValidation';
import RequiredSign from 'components/common/RequiredSign/RequiredSign';

const projectTypeOptions = [
  { key: 1, value: 1, text: 'Translation' },
  { key: 2, value: 2, text: 'Proofread' },
];

const JobField = ({ job, onChange = () => {}, onJobRemove = () => {}, analyze = false, targetLangs }) => {
  const { t } = useTranslation();
  const languages = useSelector((state) => state.classifiersStore.languages);
  const sourceLangs = useMemo(() => {
    const parsedLanguages = languages.map((lang) => {
      return {
        key: lang.id,
        value: lang.id,
        text: t(`languages:${lang.code}`, lang.name),
      };
    });
    return parsedLanguages;
  }, [languages, t]);

  const formValid = {
    payabledWordsValid: useValidation(job.payableWords, () => job.payableWords > 0),

    translatorPayableWordsValid: useValidation(
      job.translatorPayableWords,
      () => job.translatorPayableWords > 0,
    ),

    jobTotalWordsValid: useValidation(job.jobTotalWords, () => job.jobTotalWords > 0),
  };

  const handleRemoveJob = (e) => {
    e.stopPropagation();
    onJobRemove();
  };

  const title = (
    <StyledTitle>
      <div className="remove-job" onClick={handleRemoveJob}>
        <Icon name="times"></Icon>
      </div>
      <div>{job.targetLang.text}</div>
    </StyledTitle>
  );

  return (
    <Accordion title={title} initialOpen={true}>
      <FormGroup>
        <div className="field">
          <SelectDropdown
            label={t('common:admin.createProject.jobs.targetLanguage')}
            required
            name="sourceLanguage"
            value={job.targetLang.value}
            onChange={(e, { value }) => {
              const selectedLang = sourceLangs.find((lang) => lang.value === value);
              const newJob = { ...job, targetLang: selectedLang };
              onChange(newJob);
            }}
            selection
            search
            options={targetLangs}
            placeholder={t('common:admin.createProject.jobs.targetLanguage')}
          ></SelectDropdown>
        </div>
        {!analyze && (
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.jobsTotalWords')}
              required
              error={formValid.jobTotalWordsValid !== null ? !formValid.jobTotalWordsValid : false}
              type="number"
              placeholder={t('common:admin.createProject.jobs.jobsTotalWords')}
              value={job.jobTotalWords}
              onChange={(e) => {
                const newJob = { ...job, jobTotalWords: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        )}
        {!analyze && (
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.payableWords')}
              required
              error={formValid.payabledWordsValid !== null ? !formValid.payabledWordsValid : false}
              type="number"
              placeholder={t('common:admin.createProject.jobs.payableWords')}
              value={job.payableWords}
              onChange={(e) => {
                const newJob = { ...job, payableWords: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        )}
        {!analyze && (
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.translatorPayableWords')}
              required
              error={
                formValid.translatorPayableWordsValid !== null
                  ? !formValid.translatorPayableWordsValid
                  : false
              }
              type="number"
              placeholder={t('common:admin.createProject.jobs.translatorPayableWords')}
              value={job.translatorPayableWords}
              onChange={(e) => {
                const newJob = { ...job, translatorPayableWords: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        )}
      </FormGroup>

      {!analyze && (
        <FormGroup>
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.catURL')}
              type="text"
              placeholder={t('common:admin.createProject.jobs.catURL')}
              value={job.catUrl}
              onChange={(e) => {
                const newJob = { ...job, catUrl: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        </FormGroup>
      )}
      {!analyze && (
        <FormGroup>
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.catId')}
              type="text"
              placeholder={t('common:admin.createProject.jobs.catId')}
              value={job.catId}
              onChange={(e) => {
                const newJob = { ...job, catId: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.catPass')}
              type="text"
              placeholder={t('common:admin.createProject.jobs.catPass')}
              value={job.catPass}
              onChange={(e) => {
                const newJob = { ...job, catPass: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        </FormGroup>
      )}
    </Accordion>
  );
};

const AdminProjectCreate = () => {
  const [customer, setCustomer] = useState(null);
  const [projectType, setProjectType] = useState(1);
  const [projectName, setProjectName] = useState('');
  const [files, setFiles] = useState([]);
  const [sourceLang, setSourceLang] = useState(null);
  const [analyzeProject, setAnalyzeProject] = useState(false);
  // const [pages, setPages] = useState('');
  // const [totalWords, setTotalWords] = useState('');
  const [jobs, setJobs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [category, setCategory] = useState(null);
  const [targetLanguages, setTargetLanguages] = useState([]);
  const { checkProjectStatus, statusText } = useAnalysisStatus();
  const formValid = {
    customerValid: useValidation(customer, () => !!customer),
    projectNameValid: useValidation(projectName, () => !!projectName),
    filesValid: useValidation(files, () => files.length > 0),
    sourceLangValid: useValidation(sourceLang, () => !!sourceLang),
    // totalWordsValid: useValidation(totalWords, () => totalWords > 0),
    jobsValid: useValidation(
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useMemo(() => [...jobs], [analyzeProject, jobs]),
      () =>
        jobs.length > 0 &&
        jobs.reduce(
          (acc, job) =>
            analyzeProject
              ? acc && !!job.targetLang.value
              : acc &&
                !!job.targetLang.value &&
                job.payableWords > 0 &&
                job.translatorPayableWords > 0 &&
                job.jobTotalWords > 0,
          true,
        ),
    ),
    categoryValid: useValidation(category, () => !!category),
  };

  const isValid = useMemo(() => {
    if (projectType === 2) {
      return analyzeProject
        ? formValid.projectNameValid &&
            formValid.customerValid &&
            formValid.filesValid &&
            formValid.sourceLangValid &&
            formValid.categoryValid
        : formValid.projectNameValid &&
            formValid.filesValid &&
            formValid.sourceLangValid &&
            formValid.categoryValid;
      //formValid.totalWordsValid;
    } else {
      return analyzeProject
        ? formValid.projectNameValid &&
            formValid.customerValid &&
            formValid.filesValid &&
            formValid.sourceLangValid &&
            formValid.categoryValid &&
            formValid.jobsValid
        : formValid.projectNameValid &&
            formValid.customerValid &&
            formValid.filesValid &&
            formValid.sourceLangValid &&
            formValid.categoryValid &&
            formValid.jobsValid;
      // formValid.totalWordsValid;
    }
  }, [
    analyzeProject,
    formValid.categoryValid,
    formValid.customerValid,
    formValid.filesValid,
    formValid.jobsValid,
    formValid.projectNameValid,
    formValid.sourceLangValid,
    //formValid.totalWordsValid,
    projectType,
  ]);

  const { t } = useTranslation();
  const history = useHistory();

  const allCategories = useSelector((state) => state.classifiersStore.categories);

  const languages = useSelector((state) => state.classifiersStore.languages);

  const languageOptions = useMemo(() => {
    const parsedLanguages = languages.map((lang) => {
      return {
        key: lang.id,
        value: lang.id,
        text: t(`languages:${lang.code}`, lang.name),
      };
    });
    return parsedLanguages;
  }, [languages, t]);

  const sourceLanguageOptions = useMemo(() => {
    return languageOptions.filter((lang) => {
      if (projectType === 1) {
        return !targetLanguages.includes(lang.value);
      }
      return true;
    });
  }, [languageOptions, targetLanguages, projectType]);

  const targetLanguageOptions = useMemo(() => {
    return languageOptions.filter((lang) => {
      if (projectType === 1) {
        return lang.value !== sourceLang;
      }
      return !targetLanguages.includes(lang.value);
    });
  }, [languageOptions, projectType, sourceLang, targetLanguages]);

  useEffect(() => {
    const newJobs = targetLanguages.map((lang) => {
      const langOption = languageOptions.find((l) => l.value === lang);
      const langJob = jobs.find((job) => job.targetLang.value === lang);

      return langJob
        ? langJob
        : {
            targetLang: langOption,
            payableWords: '',
            catUrl: '',
            catId: '',
            catPass: '',
            translatorPayableWords: '',
            jobTotalWords: '',
          };
    });
    setJobs(newJobs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetLanguages]);

  const handleCategoriesChange = (e, data) => {
    const { value } = data;
    setCategory(value);
  };

  const categoryOptions = allCategories.map(({ id, name, code }) => {
    return {
      key: id,
      text: t(`categories:${code}`, name),
      value: id,
    };
  });

  const handleProjectCreate = async () => {
    try {
      setIsSubmitting(true);
      const reformatedJobs = jobs.map((job) => ({
        target_language_id: job.targetLang.value,
        payable_words: job.payableWords,
        //total_words: totalWords,
        job_url: job.catUrl,
        job_id: job.catId,
        job_pass: job.catPass,
        translator_payable_words: job.translatorPayableWords,
        job_total_words: job.jobTotalWords,
      }));

      if (analyzeProject) {
        const project = await postCustomProject({
          name: projectName,
          user_id: customer.user_id,
          team_id: customer.team_id,
          files,
          source_language_id: sourceLang,
          jobs: reformatedJobs,
          project_type_id: projectType,
          category_id: category,
          analyze: 1,
          //total_words: totalWords,
        });
        try {
          const response = await checkProjectStatus(project);
          await postAnalysisSuccessful({ id: project.id, response: response });
        } catch (er) {
          await postAnalysisFail({ id: project.id });
        }

        history.push(`/project/${project.id}`);
      } else {
        const project = await postCustomProject({
          name: projectName,
          user_id: customer.user_id,
          team_id: customer.team_id,
          files,
          source_language_id: sourceLang,
          jobs: reformatedJobs,
          project_type_id: projectType,
          category_id: category,
          analyze: analyzeProject ? 1 : 0,
          //total_words: totalWords,
        });
        history.push(`/project/${project.id}`);
      }

      toast.success(t('common:toasts.createProject.success'));
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.createProject.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const jobFields = jobs.map((job, i) => (
    <JobField
      key={'job' + job.targetLang.key}
      job={job}
      onChange={(job) => {
        const newJobs = [...jobs];
        newJobs[i] = { ...job };
        setJobs(newJobs);

        const newLangs = [...targetLanguages];
        newLangs[i] = job.targetLang.value;
        setTargetLanguages(newLangs);
      }}
      onJobRemove={() => {
        const newLangs = [...targetLanguages];
        newLangs.splice(i, 1);
        setTargetLanguages(newLangs);
      }}
      analyze={analyzeProject}
      targetLangs={targetLanguageOptions}
    />
  ));

  return (
    <ProjectCreateWrapper>
      <div className="project-create-header">
        <Header as="h1">{t('common:admin.createProject.header')}</Header>
      </div>
      <div className="project-create-form">
        <div className="project-create-settings">
          <Form>
            <Form.Field>
              <CustomerSuggestions
                customer={customer}
                onCustomerChange={(customer) => {
                  setCustomer(customer);
                }}
              ></CustomerSuggestions>
            </Form.Field>
            <Form.Field>
              <SelectDropdown
                label={t('common:admin.createProject.projectType')}
                selection
                placeholder={t('common:admin.createProject.projectType')}
                onChange={(e, { value }) => {
                  setProjectType(value);
                }}
                options={projectTypeOptions}
                value={projectType}
              />
            </Form.Field>
            <Form.Field>
              <Input
                label={t('common:admin.createProject.projectName')}
                placeholder={t('common:admin.createProject.projectName')}
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
                required
                error={formValid.projectNameValid !== null ? !formValid.projectNameValid : false}
              ></Input>
            </Form.Field>
            <Form.Field>
              <StyledHeader>
                {t('common:admin.createProject.files')}
                <RequiredSign />
              </StyledHeader>
              <FileUpload
                files={files}
                onFilesChange={(files) => {
                  setFiles(files);
                }}
                error={formValid.filesValid !== null ? !formValid.filesValid : false}
              ></FileUpload>
            </Form.Field>
            <Form.Field>
              <SelectDropdown
                label={t('common:admin.createProject.sourceLanguage')}
                name="sourceLanguage"
                value={sourceLang}
                onChange={(e, { value }) => {
                  setSourceLang(value);
                }}
                options={sourceLanguageOptions}
                selection
                search
                emptyState={t('common:projects.createProject.noLanguagesFound')}
                placeholder={t('common:projects.createProject.selectALanguage')}
                style={{ width: '50%' }}
                required
                error={formValid.sourceLangValid !== null ? !formValid.sourceLangValid : false}
              />
            </Form.Field>
            <Form.Field>
              <SelectDropdown
                label={t('common:serviceProvider.combinations.categories')}
                name="categories"
                value={category}
                onChange={handleCategoriesChange}
                selection
                search
                options={categoryOptions}
                placeholder={t('common:serviceProvider.combinations.categories')}
                required
                error={formValid.categoryValid !== null ? !formValid.categoryValid : false}
              />
            </Form.Field>
            <Form.Field>
              <StyledCheckbox
                toggle
                name="analyze"
                label={t('common:admin.createProject.analyzeFiles')}
                checked={analyzeProject}
                onChange={(e, { checked }) => {
                  setAnalyzeProject(checked);
                }}
              ></StyledCheckbox>
            </Form.Field>
            {analyzeProject ? (
              <Message
                type="info"
                header={t('common:admin.createProject.analysisMessage.header')}
                text={t('common:admin.createProject.analysisMessage.message')}
              />
            ) : (
              <>
                {/*<Form.Field>
                  <StyledHeader>{t('common:admin.createProject.pages')}</StyledHeader>
                  <Input
                    type="number"
                    placeholder={t('common:admin.createProject.pages')}
                    value={pages}
                    onChange={(e) => {
                      setPages(e.target.value);
                    }}
                  ></Input>
                  </Form.Field>*/}
                {/* <Form.Field>
                  <Input
                    label={t('common:admin.createProject.totalWords')}
                    type="number"
                    placeholder={t('common:admin.createProject.totalWords')}
                    value={totalWords}
                    onChange={(e) => {
                      setTotalWords(e.target.value);
                    }}
                    required
                    error={formValid.totalWordsValid !== null ? !formValid.totalWordsValid : false}
                  ></Input>
                </Form.Field> */}
              </>
            )}
          </Form>
        </div>
        {projectType !== 2 && (
          <div className="project-create-jobs">
            <div>
              <Form>
                <Form.Field>
                  <MultipleDropdown
                    label={t('common:admin.createProject.jobs.header')}
                    required
                    name="targetLanguages"
                    value={targetLanguages}
                    onChange={(e, { value }) => {
                      setTargetLanguages(value);
                    }}
                    placeholder={t('common:admin.createProject.jobs.addPlaceholder')}
                    options={targetLanguageOptions}
                    multiple
                    selection
                    search
                    emptyState={t('common:projects.createProject.noLanguagesFound')}
                    fluid
                    renderItem={(props) => (
                      <StyledDropdownItem {...props}>
                        {props.item.icon}
                        {props.item.text}
                        <AddLanguage>
                          <Icon name="plus" /> {t('common:projects.createProject.addLanguage')}
                        </AddLanguage>
                      </StyledDropdownItem>
                    )}
                  />
                </Form.Field>
              </Form>
            </div>
            {jobFields}
          </div>
        )}
      </div>
      <Button
        actiontype="primary"
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        onClick={handleProjectCreate}
      >
        {t('common:admin.createProject.createProject')}
      </Button>
      {analyzeProject && isSubmitting ? <AnalysisStatus>{statusText}</AnalysisStatus> : null}
    </ProjectCreateWrapper>
  );
};

export default AdminProjectCreate;
