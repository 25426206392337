import Pagination from '../../common/Pagination/Pagination';
import styled from 'styled-components';

export const TeamMembersLayout = styled.div`
  position: relative;
  display: grid;
`;

export const StyledPagination = styled(Pagination)`
  margin: 3em auto;
`;

export const ButtonsWrapper = styled.div`
  min-width: 80px;
  .button {
    border: none;
    margin: 0;
    &.check {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;
