import { TabContainer } from 'components/UserProfile/UserProfile.styles';
import React, { useCallback } from 'react';
import CombinationsTable from './CombinationsTable/CombinationsTable';

const VendorLanguageCombinations = ({ vendorData, onCombinationsChange }) => {
  const handleCombinationsChange = useCallback(
    (newPrices) => {
      // Only update if prices are different
      if (JSON.stringify(newPrices) !== JSON.stringify(vendorData.prices)) {
        const newVendorData = {
          ...vendorData,
          prices: newPrices,
        };
        // Set vendor data
        onCombinationsChange(newVendorData);
      }
    },
    [vendorData, onCombinationsChange],
  );

  return (
    <TabContainer>
      <div className="vendor-language-combinations">
        <CombinationsTable
          initialCombinations={vendorData.prices}
          vendorId={vendorData.id}
          onChange={handleCombinationsChange}
        />
      </div>
    </TabContainer>
  );
};

export default VendorLanguageCombinations;
