import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { UploadConfirmation } from './UploadConfirmation';
import { Divider, Label } from 'semantic-ui-react';
import { Icon } from '../../common/Icon/Icon';

import { ControlsContainer, DropZoneContainer, DropZoneText } from './TranslationUpload.styles';
import { TranslatedUpload } from '../AdminProject.style';

import { getProject, postUploadFile, getTranslatedFiles } from '../../../services/project';
import Button from '../../common/Button/Button';
import { sendUserInteraction } from 'utils/tagManager.utils';

const TranslationUpload = ({ updateProjectState, project, t }) => {
  const [downloadingFiles, setDownloadingFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesUploading, setFilesUploading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { id, translated_file } = project;

  const removeFile = (idx) => {
    return () => {
      sendUserInteraction('admin project file upload remove file click');
      const newFiles = [...files];
      newFiles.splice(idx, 1);
      setFiles(newFiles);
    };
  };

  const addFiles = (acceptedFiles) => {
    sendUserInteraction('admin project add files');
    const newFiles = [...files, ...acceptedFiles];
    setFiles(newFiles);
  };

  const fileLabels = files.map((file, idx) => {
    return (
      <Label as="a" key={idx}>
        {file.name}
        <Icon name="times" onClick={removeFile(idx)} />
      </Label>
    );
  });

  const handleUploadClick = async () => {
    sendUserInteraction('admin project file upload files click');
    if (translated_file) {
      setShowConfirmationModal(true);
      return;
    }
    await handleUpload();
  };

  const handleUpload = async () => {
    try {
      setFilesUploading(true);
      await postUploadFile(id, files);
      const projectResponse = await getProject(id);
      updateProjectState(projectResponse.data);
      setFiles([]);
      toast.success(t('common:toasts.translatedFiles.success'));
    } catch (e) {
      toast.error(t('common:toasts.translatedFiles.error'));
      throw e;
    } finally {
      setFilesUploading(false);
    }
  };

  const handleDownloadClick = async () => {
    sendUserInteraction('admin project download files click');
    setDownloadingFiles(true);
    try {
      // get file blob
      const response = await getTranslatedFiles(id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setDownloadingFiles(false);
    }
  };

  const handleUploadConfirm = async () => {
    sendUserInteraction('admin project file upload confirm click');
    setShowConfirmationModal(false);
    await handleUpload();
  };

  return (
    <>
      {showConfirmationModal && (
        <UploadConfirmation
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={handleUploadConfirm}
        />
      )}
      <DropZoneContainer>
        <Dropzone onDrop={(acceptedFiles) => addFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <TranslatedUpload>
                  <Icon color="#2185d0" name="upload" size="huge" />
                  <DropZoneText>{t('common:admin.project.addTranslationFiles')}</DropZoneText>
                </TranslatedUpload>
              </div>
            </section>
          )}
        </Dropzone>
      </DropZoneContainer>

      {files.length !== 0 && (
        <>
          <Divider />
          <Label.Group>{fileLabels}</Label.Group>
          <Divider />
        </>
      )}

      <ControlsContainer>
        <Button
          actiontype="primary"
          disabled={!translated_file || filesUploading}
          fluid
          loading={downloadingFiles}
          onClick={handleDownloadClick}
        >
          {t('common:admin.project.downloadTranslated')}
        </Button>
        <Button
          actiontype="submit"
          disabled={files.length === 0 || downloadingFiles}
          fluid
          loading={filesUploading}
          onClick={handleUploadClick}
        >
          {t('common:admin.project.uploadTranslated')}
        </Button>
      </ControlsContainer>
    </>
  );
};

export default withTranslation('common')(TranslationUpload);
