import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';

import '../Subscription/SubscriptionDetails/style.scss';

import { sendUserInteraction } from 'utils/tagManager.utils';

import CatapultImgSrc from '../../assets/Catapult.svg';

const LegacySubscription = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const redirectToCreate = () => {
    sendUserInteraction('clicked start a new project on the legacy subscription overview page');
    history.push('/projects/create');
  };

  return (
    <>
      <section className="container">
        <img alt="catapult" className="package-image" src={CatapultImgSrc} />
        <div className="details-container">
          <h2 className="heading-2">{t('common:catapult.subscription.legacyHeading')}</h2>
          <p className="text">{t('common:catapult.subscription.legacyFlavorText')}</p>
          <div className="buttons-container">
            <Button actiontype="primary" labelPosition="left" onClick={redirectToCreate}>
              <Icon name="plus-square" />
              {t('common:projects.startANewProject')}
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default LegacySubscription;
