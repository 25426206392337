import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Loader from '../../common/Loader/Loader';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 100px;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin-top: 16px;
  `}
`;

/**
 * Displays loader and text which depends on summary status
 *
 * @param {object} summary object containing the current status
 * @param {string} className css classes
 * @param {object} style inline styles
 * @param {boolean} setStepParam indicates to add step param to current url
 */
const ProjectLoader = ({ summary, style, className, setStepParam = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  /**
   * If setStepParam is passed/true on mount
   * add step query param with value "analyzing" to current url
   */
  useEffect(() => {
    if (setStepParam) {
      history.push({
        pathname: location.pathname,
        search: '?step=analyzing',
      });
    }

    // cleanup
    return () => {
      if (setStepParam) {
        history.push({
          pathname: location.pathname,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loaderTexts = summary.preparing
    ? t('common:projects.preparing')
    : summary.IN_QUEUE_BEFORE
    ? t('common:projects.inQueue')
    : summary.analysisFinished
    ? t('common:projects.analysisFinished')
    : summary.STATUS === 'BUSY' || summary.STATUS === 'NOT_READY_FOR_ANALYSIS' || summary.STATUS === 'NEW'
    ? t('common:projects.startingAnalysis')
    : t('common:projects.analyzingProject') + `${summary.SEGMENTS_ANALYZED}/${summary.TOTAL_SEGMENTS}`;

  return (
    <LoaderContainer style={style} className={className}>
      <Loader inline>{loaderTexts}</Loader>
    </LoaderContainer>
  );
};

// Prop types
ProjectLoader.propTypes = {
  summary: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  setStepParam: PropTypes.bool,
};

export default ProjectLoader;
