import styled from 'styled-components';
import Button from '../../common/Button/Button';
import Label from '../../common/Label/Label';

export const LinkContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const LinkTextContainer = styled.div`
  margin-right: 1rem;
`;

export const Container = styled.div`
  width: 100%;
  background: #fff0cf;
  padding: 40px 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    width: calc(100% - (${theme.paddings.mainContainer.mobile.x} * 2));
    margin: 2rem auto;
    padding: 40px ${theme.paddings.mainContainer.mobile.x};
  `}
`;

export const StyledLabel = styled(Label)`
  background: ${({ theme }) => theme.colors.yellow}64;
  color: #cc8d00;
  font-size: 11px;
  height: 20px;
  text-transform: uppercase;
`;

export const UrlContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 18px 0 33px;
  width: 100%;

  .input {
    max-width: 370px;
    width: 60%;
    caret-color: transparent;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin-bottom: 0;

    flex-direction: column;

    .button,
    .input {
      width: 100%;
      max-width: unset;
    }

    .button {
      margin: 12px 0 0 !important;
    }
  `}
`;

export const StyledHeader = styled.h2`
  margin: 12px 0;
  font-size: 22px;
  font-family: 'DM Sans', sans-serif;
`;

export const StyledSubtext = styled.div`
  color: ${({ theme }) => theme.colors.greyDark};
`;

export const Highlighted = styled.span`
  color: ${({ theme }) => theme.colors.yellow};
`;

export const StyledButton = styled(Button)`
  margin-left: 10px !important;
`;

export const AffiliateTableWrapper = styled.div`
  max-width: 700px;
  width: 100%;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    table {
      margin-top: 28px;
    }  

    table, thead, tbody, th, td, tr { 
      display: block; 
      // Remove white bg
      background: transparent;
    }
    
    thead tr { 
      display: none;
    }

    tbody {
      tr {
        padding: 17px 21px;

        td {
          padding: 0 !important;
          display: flex;

          font-size: 16px;
          font-weight: normal !important;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          color: #999999;
        }
      }

      // Remove borders
      tr:first-child td,
      tr td {
        border: none;
      }

      // Spacing between each referral
      > :not(:last-child) {
        margin-bottom: 14px;
      }
    }

    .affiliate-referrer-email {
      font-weight: bold !important;
      justify-content: space-between;

      > div {
        max-width: 70%;
      }
    }

    .affiliate-comission-cell {
      color: #999999 !important;
    }

    .affiliate-comission {
      color: #000000 !important;
    }

    .affiliate-counter {
      font-size: 16px;
      font-weight: normal !important;
      font-stretch: normal;
      font-style: normal;
      color: #c4c4c4 !important;
    }
  `}
`;
