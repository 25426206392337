import React from 'react';

import image from 'assets/taia-graphic-list-checkmark.png';

import { Link } from 'react-router-dom';
import Button from 'components/common/Button/Button';

import './style.scss';

const Finish = () => {
  return (
    <div className="finish-step">
      <img src={image} alt="" />
      <h1>Almost there.</h1>
      <p>
        Thank you for filling out your details, we appreciate your time and dedication.
        <br />
        <br />
        Our vendor management team will review your profile and get back to you with further information
        before verifing your profile.
      </p>

      <Link to="/projects">
        <Button color="blue" big basic>
          Go back home
        </Button>
      </Link>
    </div>
  );
};

export default Finish;
