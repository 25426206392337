import styled from 'styled-components';

export const CustomerSuggestionsContainer = styled.div`
  width: 100%;
  font-size: 1em;

  position: relative;

  display: flex;
  align-items: center;

  ${({ theme }) => theme.inputStyle}

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.blue};
    .start-field {
      color: ${({ theme }) => theme.colors.blue};
    }

    .autocomplete-field {
      border-color: ${({ theme }) => theme.colors.blue};
    }
  }

  .start-field {
    margin-right: 0.67857143em !important;
    transition: color 0.1s ease;
  }

  .input-container {
    width: 100%;

    input {
      width: 100%;
      padding: 0 !important;
      line-height: 1.21428571em;
      font-size: 1em;
      border: none !important;
      outline: none;
      width: 100%;
    }
  }

  .dropdown-field {
    white-space: nowrap;
    margin-left: 0.67857143em !important;
    color: ${({ theme }) => theme.colors.blue};
  }

  &.autocomplete-open {
    border-radius: 0.28571429rem 0.28571429rem 0 0;
    border-bottom: none;

    .autocomplete-field {
      display: block;
    }
  }

  .autocomplete-field {
    display: none;
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    color: rgba(0, 0, 0, 0.87);
    z-index: 10;

    background: #fff;

    box-shadow: 0 0 0 0 transparent inset;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-top: none;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    transition: color 0.1s ease, border-color 0.1s ease;
    .suggestion {
      padding: 0.67857143em 1em;
      cursor: pointer;
      transition: background-color 0.1s ease;
      background: ${({ theme }) => theme.colors.white};
      &:hover {
        background-color: ${({ theme }) => theme.colors.blueLight};
      }
    }
  }
`;
