import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ServiceRows from './ServiceRows';

import { Container, JobName, JobPrice } from './JobRow.styles';
import { getJobTranslationKey, getShownServices } from '../../../../../utils/jobs.utils';

const JobRow = ({ currency, job, price }) => {
  const { t } = useTranslation();

  const shownServices = useMemo(() => getShownServices(job), [job]);

  return (
    <Container>
      <JobName>{t(getJobTranslationKey(job))}</JobName>
      <JobPrice>{price}</JobPrice>
      {shownServices.map((service) => (
        <ServiceRows currency={currency} key={`service-${service.id}`} service={service} />
      ))}
    </Container>
  );
};

export default JobRow;
