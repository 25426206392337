import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const Container = styled(Segment)`
  background-color: #fff;
  max-width: 50ch;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto !important;
`;
