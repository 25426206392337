import TagManager from 'react-gtm-module';

import packageData from '../../package.json';
import store from 'store/store';

export const initializeTagManager = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GMT_ID,
  };

  TagManager.initialize(tagManagerArgs);
};

export const sendDataLayer = (dataLayer) => {
  // clean data layer with each new request so variables do not persist inside different events
  window.dataLayer.push(function () {
    this.reset();
  });

  TagManager.dataLayer({
    dataLayer,
  });
};

export const sendGaVirtual = ({ page, userData }) => {
  //if (!window.linktr) return;

  sendDataLayer({
    event: 'ga-virtual',
    ...userData,
    page,
  });
};

export const sendGaPurchase = ({ billingId, page, products = [], type, price, project }) => {
  const globalState = store.getState();
  const userData = globalState.userStore.user;

  let purchase = {};

  switch (type) {
    case 'project':
      // create project purchase object here
      purchase = {
        actionField: {
          id: `${project.id}`,
          revenue: `${price.gross_price}`,
          tax: `${price.vat_total}`,
        },
        products: products.map((product) => {
          const { name, sourceLang, targetLang, totalWords } = product;
          return {
            brand: `${sourceLang} to ${targetLang}`,
            category: `${name}`,
            name: `${sourceLang} to ${targetLang} - ${name}`,
            price: `${product.price}`,
            quantity: 1,
            metric1: totalWords,
          };
        }),
      };

      const deliveryNames = {
        50: 'Regular delivery',
        48: 'Fast delivery',
        45: 'Super fast delivery',
      };

      // if a coupon was added to the project send it inside the payload
      if (!!project.disc_coupon) {
        purchase.actionField['coupon'] = `${project.disc_coupon}`;
      }

      if (!!price.delivery_price) {
        purchase.products.push({
          name: deliveryNames[project.is_delivery],
          price: `${price.delivery_price}`,
        });
      }
      break;
    case 'package':
      // if user already has a free plan change page name to upgrade
      if (!!userData.free_subscription) {
        page = 'catapult-upgrade-package-completed';
      }

      // create package purchase object here
      purchase = {
        actionField: {
          id: `${billingId}`,
          revenue: `${price.total}`,
          tax: `${price.tax}`,
        },
        products: products.map((product) => {
          const { name, quantity, subname, wordLimit } = product;
          return {
            name: `${name}`,
            price: `${product.price}`,
            brand: 'Catapult',
            category: `${subname}`,
            quantity: quantity,
            metric1: wordLimit,
          };
        }),
      };
      break;
    default:
      // if we do not recognize the product type do not send the event
      return;
  }

  sendDataLayer({
    event: 'ga-purchase',
    page: page,
    ecommerce: {
      purchase: { ...purchase },
    },
    user_id: userData.id,
  });
};

export const sendUserInteraction = (description) => {
  const pathName = window.location.pathname;
  const globalState = store.getState();
  const userData = globalState.userStore.user;

  sendDataLayer({
    description,
    event: 'interaction',
    pathName: pathName,
    userData: { ...userData },
    version: packageData.version,
  });
};

export const sendAnalysisFailed = () => {
  sendDataLayer({
    event: 'analysis-failed',
  });
};
