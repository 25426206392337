import React, { useMemo } from 'react';
import { ProductsWrapper, ProductWrapper, QuantityContainer, SwapLineWrapper } from './Products.style';
import packageIcon from './assets/package.svg';
import translationIcon from './assets/translation.svg';
import { useTranslation } from 'react-i18next';
import Price from '../../../common/Price/Price';
import plusIcon from './assets/plus.svg';
import minusIcon from './assets/minus.svg';
import { useSelector } from 'react-redux';
import { isCompanyRole } from '../../../../utils/user.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

const QuantityIcon = ({ onClick = () => {}, type }) => {
  const icon = type === 'plus' ? plusIcon : minusIcon;
  return (
    <div className="quantity-icon" onClick={onClick}>
      <img src={icon} alt="" />
    </div>
  );
};

const PackageDetails = ({ disabled = false, product, currency, onQuantityChange = () => {} }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);

  const onQuantityPlus = () => {
    sendUserInteraction('added subscription seat');
    onQuantityChange(Number(product.quantity) + 1);
  };

  const onQuantityMinus = () => {
    if (product.quantity > 1) {
      sendUserInteraction('removed subscription seat');
      onQuantityChange(Number(product.quantity) - 1);
    }
  };

  return (
    <>
      <div className={`detail-field ${!!disabled ? 'disabled' : ''}`}>
        <div className="detail-header">{t('common:checkout.orderSummary.product.package')}</div>
        <div className="detail-content">{product.name}</div>
      </div>
      <div className={`detail-field ${!!disabled ? 'disabled' : ''}`}>
        <div className="detail-header">{t('common:checkout.orderSummary.product.seats')}</div>
        <div className="detail-content">
          {isCompanyRole(user.role) && !disabled ? (
            <QuantityContainer>
              <QuantityIcon type="minus" onClick={onQuantityMinus} />
              {product.quantity}
              <QuantityIcon type="plus" onClick={onQuantityPlus} />
            </QuantityContainer>
          ) : (
            product.quantity
          )}
        </div>
      </div>
      <div className={`detail-field ${!!disabled ? 'disabled' : ''}`}>
        <div className="detail-header">{t('common:checkout.orderSummary.product.wordLimit')}</div>
        <div className="detail-content">{product.wordLimit}</div>
      </div>
      <div className={`detail-field ${!!disabled ? 'disabled' : ''}`}>
        <div className="detail-price">
          {!!product.price ? <Price currency={currency} price={product.price}></Price> : null}{' '}
          {!!product.interval && <span className="interval">/ {product.interval} </span>}
        </div>
      </div>
    </>
  );
};

const JobDetails = ({ product, currency }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="detail-field">
        <div className="detail-header">{t('common:checkout.orderSummary.product.service')}</div>
        <div className="detail-content">{product.name}</div>
        <div className="detail-subtext">{`${product.sourceLang} -> ${product.targetLang}`}</div>
      </div>

      <div className="detail-field">
        <div className="detail-price">
          <Price currency={currency} price={product.price}></Price>
        </div>
      </div>
    </>
  );
};

export const Product = ({ type = 'package', product = {}, currency, onQuantityChange = () => {} }) => {
  return (
    <ProductWrapper>
      <div className="product-icon">
        <img src={type === 'package' ? packageIcon : translationIcon} alt=""></img>
      </div>
      <div className={`product-details ${type === 'project' ? 'project' : ''}`}>
        {type === 'package' ? (
          <PackageDetails product={product} currency={currency} onQuantityChange={onQuantityChange} />
        ) : (
          <JobDetails product={product} currency={currency} />
        )}
      </div>
    </ProductWrapper>
  );
};

export const CurrentPackage = ({ newPackage = {}, product = {}, currency }) => {
  const { t } = useTranslation();

  const changeText = useMemo(() => {
    if (product.wordLimit === newPackage.wordLimit) {
      return `${
        product.quantity > newPackage.quantity
          ? t('common:catapult.packages.downgrade')
          : t('common:catapult.packages.upgrade')
      } ${t('common:catapult.packages.to')}`;
    }

    return `${
      product.wordLimit >= newPackage.wordLimit
        ? t('common:catapult.packages.downgrade')
        : t('common:catapult.packages.upgrade')
    } ${t('common:catapult.packages.to')}`;
  }, [product, newPackage, t]);

  return (
    <>
      <ProductWrapper>
        <div className="product-icon">
          <img src={packageIcon} alt=""></img>
        </div>
        <div className="product-details">
          <PackageDetails disabled={true} product={product} currency={currency} />
        </div>
      </ProductWrapper>
      <SwapLineWrapper>
        <div className="text">{changeText}</div>
        <hr />
      </SwapLineWrapper>
    </>
  );
};

const Products = ({ currentProduct, type, onQuantityChange = () => {}, products = [] }) => {
  const productComponents = products.map((product) => {
    return (
      <Product
        key={product.id}
        type={type}
        product={product}
        currency={product.currency}
        onQuantityChange={onQuantityChange}
      ></Product>
    );
  });
  return (
    <ProductsWrapper>
      {!!currentProduct && (
        <CurrentPackage
          newPackage={products[0]}
          product={currentProduct}
          currency={currentProduct.currency}
        />
      )}
      {productComponents}
    </ProductsWrapper>
  );
};

export default Products;
