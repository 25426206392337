import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  .ui.dropdown {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 3px;
    padding: 5px 10px;
  }
`;
