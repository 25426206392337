import { StepControls } from 'components/AdminAddVendor/AddSteps';
import { useAddVendorContext } from 'components/AdminAddVendor/AddVendorProvider';
import ProfessionalExperienceForm from 'components/common/Vendor/Forms/ProfessionalExperienceForm/ProfessionalExperienceForm';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const UploadAttachments = () => {
  const { useVendorData } = useAddVendorContext();
  const [formData, setFormData] = useVendorData('attachments');
  const { t } = useTranslation();

  const handleFormChange = useCallback(
    ({ linkedin, education, experience, cv, additionalFiles }) => {
      // Store only text fields to local storage
      setFormData({
        linkedin,
        education,
        experience,
        cv,
        additionalFiles,
      });
    },
    [setFormData],
  );

  return (
    <section className="steps-content form-step">
      <header>
        <h2>{t('common:admin.vendorOnboarding.stepThree.title', 'Professional experience')}</h2>
        <p>
          {
            (t('common:admin.vendorOnboarding.stepThree.description'),
            'Add information and files that pretaining to the vendors experience')
          }
        </p>
      </header>
      <div className="form vendor-onboarding-step-three-form">
        <ProfessionalExperienceForm data={formData} onChange={handleFormChange} />
        <StepControls></StepControls>
      </div>
    </section>
  );
};

export default UploadAttachments;
