import styled from 'styled-components';

export const PriceSummaryWrapper = styled.div`
  position: sticky;
  top: 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 20px 22px 22px 22px;

  .price-header {
    margin-bottom: 22px;
  }

  .price-loader {
    display: flex;
    justify-content: center;

    margin-top: 120px;
    margin-bottom: 120px;
  }

  .confirm-button {
    .verified-user {
      margin-top: 10px;
      .confirm-advance {
        margin-top: 22px;
      }
    }
  }
`;

export const PriceRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
  .striketrough {
    text-decoration: line-through;
  }
  &:last-of-type {
    margin-bottom: 22px;
  }

  &.large {
    font-size: 18px;
    .start {
      .start-text {
        display: flex;
        font-weight: bold;
      }
      .start-subtext {
        font-size: 14px;
      }
    }

    .end {
      text-align: right;
      .end-text {
      }
      .end-subtext {
        font-size: 14px;
      }
    }
  }

  .start {
    .start-text {
      display: flex;
    }

    .start-subtext {
      font-size: 10px;
      &.light {
        color: ${({ theme }) => theme.colors.grey};
      }
    }
  }

  .end {
    margin-left: auto;
    text-align: right;
    .end-text {
    }

    .end-subtext {
      display: flex;
      justify-content: flex-end;
      font-size: 10px;
      &.light {
        color: ${({ theme }) => theme.colors.grey};
      }
    }
  }
`;

export const PriceLabel = styled.div`
  .quantity {
    font-weight: bold;
  }
  .value {
    font-weight: bold;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.grey};
  margin-bottom: 22px;
  margin-top: 22px;
`;

export const BalanceDetailsContainer = styled.div`
  .balance-item {
    display: flex;
    min-width: 240px;
    .balance-text {
      margin-right: 10px;
    }

    .balance-price {
      margin-left: auto;
      font-weight: bold;
    }
  }
`;
