import styled, { css } from 'styled-components';

import { Header, Image } from 'semantic-ui-react';
import TextArea from '../TextArea/TextArea';
import Button from '../Button/Button';

export const StyledImage = styled(Image)`
  width: 80px;
  height: auto;
  margin-bottom: 1rem;
`;

export const DropzoneWrapper = styled.div`
  padding: 2rem;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.28571429rem;
  background-color: #f9fbfc;
  margin-bottom: 1rem;
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
  transition: border-width 0.07s ease;
  position: relative;

  .absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0.28571429rem;
    border: 1px dotted rgba(34, 36, 38, 0.15);
    transition: border-width, transform;
    transition-duration: 0.1s;
    transition-timing-function: ease;
  }

  ${(props) =>
    props.isDraggingOver &&
    css`
      background-color: ${({ theme }) => theme.colors.greyLight};
      .absolute {
        border-width: 3px;
        transform: scale(0.97, 0.9);
      }
    `}
`;

export const StyledSpan = styled.span`
  color: rgba(0, 0, 0, 0.6);
`;

export const StyledHeader = styled(Header)`
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  color: ${({ theme }) => theme.colors.greyDark};
`;

export const DropzoneText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Browse = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: underline;
`;

export const StyledTextArea = styled(TextArea)`
  height: 136px;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
  border: solid 1px #cccccc;
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  margin-bottom: 14px;
  position: relative;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .checkbox {
    margin-right: 1em;
  }
`;

export const AbsoluteButton = styled(Button)`
  position: absolute !important;
  margin: 0 !important;
  font-size: 10px !important;
  right: 0px;
  bottom: 0px;

  transform: translate(40%, 40%);
`;

export const FilesListContainer = styled.div`
  overflow: auto;
  margin-top: 0.25em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-height: calc(6 * 35px + 1px);
`;

export const FileRowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :last-child {
    border-bottom: none;
  }
`;
export const TypeRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TypesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const DisabledUpload = styled.div`
  width: 100%;
  padding: 18px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DisabledHeading = styled.div`
  font-size: 36px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  margin-bottom: 1em;
`;
