import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { useRef, useState, useEffect } from 'react';
import { breakpoints } from 'style/theme';
import styled, { css } from 'styled-components';
import Hammer from 'react-hammerjs';
import LoadingPane from '../LoadingPane/LoadingPane';

const TabItems = styled.div`
  .tab-items-container {
    display: flex;
    border-bottom: 2px solid #dddddd;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    background: #f6f6f6;
    position: sticky;
    top: 0;
    z-index: 10;

    .tab-items-container {
      // So it scrolls smoothly when using js to scroll
      scroll-behavior: smooth;

      overflow: hidden;
      overflow-x: scroll;
      padding: 0 ${theme.paddings.mainContainer.mobile.x};
      border-bottom: 0;

      > :not(:last-child) {
        margin-right: 45px;
      }

      // Hide scrollbar
      ::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    // Bottom border
    &::after {
      content: '';
      background-color: #dddddd;
      position: absolute;
      bottom: 0;
      height: 2px;
      width: 100%;
      left: 0;
      z-index: 0;
    }

    // The scroll indicator - a very light grey gradient to right
    &::before {
      position: absolute;
      pointer-events: none;
      content: "";
      display: block;
      z-index: 10;
      width: 33%;
      height: calc(100% - 2px);
      right: 0;
      top: 0;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      width: 65px;
      right: 0;
      top: 0;
      background-image: linear-gradient(to right,
        rgba(255,255,255, 0),
        rgba(220,220,220, 0.9) 100%
      );
    }

    // Hide scroll overlay indicator
    &.hide-overlay {
      &::before {
        opacity: 0;
      }
    }
  `}
`;

const TabContainer = styled.div`
  ${({ theme }) => theme.mediaMaxWidth.mobile`
    height: 100%;
    display: flex;
    flex-direction: column;

    .swipe-container {
      flex-grow: 1;
    }
  `}
`;

const TabItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme, active }) => (active ? theme.colors.black : 'rgba(0, 0, 0, 0.3)')};
  font-size: 1.1428571rem;
  line-height: 30px;
  margin-bottom: -2px; // so that it aligns with the parent border
  transition: color 0.3s ease, border-color 0.3s ease;
  border-bottom: 2px solid;
  border-bottom-color: ${({ active, theme }) => (active ? theme.colors.blue : 'transparent')};

  padding: 10px 22px 12px;

  &:hover {
    color: ${({ active }) => !active && 'rgba(0, 0, 0, 0.6)'};
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
      &:first-of-type {
        padding-left: 0 !important;
      }
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    white-space: nowrap;
    padding: 14px 0;
    margin-bottom: 0;
    z-index: 9;
  `}
`;

const StyledTabPane = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: solid 1px #e9e9e9;
  width: 100%;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    height: 100%;
  `}
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  font-size: 11px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #e9e9e9;
  color: ${({ theme }) => theme.colors.greyDark};
  border: 1px solid transparent;
  transition: color, background-color, border-color, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  opacity: ${({ loading }) => (loading ? '0' : '1')};

  ${({ active, theme }) =>
    active &&
    css`
      background-color: #c8e7ef;
      color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};
    `}
`;

const TabPane = ({ children, ...rest }) => {
  return <StyledTabPane {...rest}>{children}</StyledTabPane>;
};

const Tab = ({ panes, activeIndex = 0, onTabChange = () => {}, loading = false, children, ...rest }) => {
  const [_activeIndex, setActiveIndex] = useState(activeIndex);
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile}px)`);
  const tabItemsRef = useRef(null);
  const tabItemsLinksRefs = useRef([]);

  const handleTabChange = (e, tabIndex) => {
    e.persist();
    setActiveIndex(tabIndex);
    onTabChange(e, { ...panes[tabIndex], activeIndex: tabIndex });
  };

  const handleTabsContainerScroll = (e) => {
    if (!tabItemsRef.current) return;

    const tabItems = tabItemsRef.current;
    const tabItemsContainer = e.target;

    if (
      tabItemsContainer.scrollWidth - tabItemsContainer.getBoundingClientRect().width ===
      tabItemsContainer.scrollLeft
    ) {
      tabItems.classList.add('hide-overlay');
    } else {
      tabItems.classList.remove('hide-overlay');
    }
  };

  /**
   * Handle swipe event, function passed to hammerjs swipe handler
   *
   * @param {HammerInput} ev
   */
  const handleSwipe = (ev) => {
    if (ev.pointerType === 'mouse') return;

    switch (ev.direction) {
      // Swipe left
      case 2:
        // Check current active index, if at last tab don't change active index
        if (_activeIndex + 1 === panes.length) {
          break;
        }

        setActiveIndex(_activeIndex + 1);

        break;
      // Swipe right
      case 4:
        // Check current active index, if at first tab don't change active index
        if (_activeIndex === 0) {
          break;
        }

        setActiveIndex(_activeIndex - 1);

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isMobile && tabItemsLinksRefs.current.length > 0) {
      const tabElement = tabItemsLinksRefs.current[_activeIndex];
      const tabOffset = tabElement.offsetLeft - 20;
      const parent = tabElement.parentNode;

      parent.scrollLeft = tabOffset;

      // Also reset scroll of the page
      document.querySelector('.main-content-container').scrollTo(0, 0);
    }
  }, [_activeIndex, isMobile]);

  // Update local state when active index in props changes
  useEffect(() => {
    setActiveIndex(activeIndex);
  }, [activeIndex]);

  return (
    <TabContainer className="tabs" {...rest}>
      <TabItems className="tabs-links" ref={tabItemsRef}>
        <div className="tab-items-container" onScroll={isMobile ? handleTabsContainerScroll : null}>
          {panes.map((pane, idx) => (
            <TabItem
              key={pane.title}
              onClick={(e) => handleTabChange(e, idx)}
              active={idx === _activeIndex}
              ref={(el) => (tabItemsLinksRefs.current[idx] = el)}
            >
              {pane.title}
            </TabItem>
          ))}
        </div>
      </TabItems>
      <Hammer className="swipe-container tabs-contents" onSwipe={handleSwipe}>
        <div>
          {loading ? <LoadingPane /> : panes[_activeIndex].render()}
          <footer>{children}</footer>
        </div>
      </Hammer>
    </TabContainer>
  );
};

Tab.Pane = TabPane;
Tab.Number = Number;

export default Tab;
