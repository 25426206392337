const { default: styled } = require('styled-components');

export const StepsWrapper = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-areas:
    'steps'
    'content'
    'controls';
  grid-row-gap: 20px;
  justify-items: center;

  .steps {
    grid-area: steps;
    display: flex;
    justify-content: space-between;
    width: 90%;
    .step {
      display: grid;
      grid-template-rows: min-content auto;
      grid-gap: 5px;
      justify-items: center;

      .step-text {
        color: #407fce;
        font-weight: bold;
        text-align: center;

        cursor: pointer;
      }
    }
    .between-step {
      background-color: #e5e5e5;
      margin-top: 27px;
      width: 100%;
      height: 2px;
    }
  }

  .content {
    grid-area: content;
    display: grid;
    justify-content: stretch;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  .controls {
    grid-area: controls;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .left {
      margin-right: auto;
    }
  }
`;
