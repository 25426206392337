import { useLocalStorage } from 'utils/storage.utils';

export const useFilters = (name, initialFilters = {}) => {
  const [filters, setFilters] = useLocalStorage(`${name}-filters`, initialFilters);

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  return { filters, setFilters, resetFilters };
};
