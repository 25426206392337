import { zindexPopperModifier } from 'constants/general';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import './style.scss';

const SubjectMatters = ({ subjectMatters, staticCategories }) => {
  if (!Array.isArray(subjectMatters)) return null;

  const subjNames = subjectMatters.map((subID) => staticCategories.find(({ id }) => subID === id));

  const text = (
    <span>
      {subjNames[0].name}
      {subjNames.length > 1 && ` + ${subjNames.length - 1}`}
    </span>
  );

  if (subjNames.length > 1) {
    return (
      <Popup
        position="top center"
        inverted
        trigger={text}
        content={
          <ul className="subject-matters-popup-content">
            {subjNames.slice(1).map(({ name, id }) => (
              <li key={id}>{name}</li>
            ))}
          </ul>
        }
        popperModifiers={[zindexPopperModifier]}
      />
    );
  }

  return text;
};

export default SubjectMatters;
