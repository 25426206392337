import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { hasAdminPermissions } from 'utils/user.utils';

/**
 * A component that conditionally wraps any children passed in, a admin user profile link for given id
 *
 * @param {number} userId
 * @param {React.ReactNode} children
 */
const UserProfileAdminLink = ({ userId, newTab = false, children }) => {
  const user = useSelector((state) => state.userStore.user);

  return hasAdminPermissions(user.role) && userId ? (
    <Link target={newTab ? '_blank' : ''} to={`/admin/user/${userId}`}>
      {children}
    </Link>
  ) : (
    children
  );
};

export default UserProfileAdminLink;
