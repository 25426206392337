import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import ResumeOnboarding from 'components/ServiceProvider/VendorOnboarding/ResumeOnboarding/ResumeOnboarding';
import { userStartedVendorOnboarding } from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';

// Assets
import blankDashboardImg from 'assets/taia-graphic-blank-dashboard.svg';

// Styles
import './style.scss';

/**
 * Render vendor welcome screen
 */
const WelcomeScreen = () => {
  // Hooks
  const { t } = useTranslation();

  // User state
  const user = useSelector((state) => state.userStore.user);

  const isOnboardingVendor = !user.vendor && userStartedVendorOnboarding();

  const title = t(
    `common:serviceProvider.jobs.welcomeScreen.${isOnboardingVendor ? 'title' : 'titleOnboarded'}`,
  );

  const description = t(
    `common:serviceProvider.jobs.welcomeScreen.${
      isOnboardingVendor ? 'description' : 'descriptionOnboarded'
    }`,
  );

  const descriptionOnboardCTA = t(
    `common:serviceProvider.jobs.welcomeScreen.${
      isOnboardingVendor ? 'descriptionContinueOnboard' : 'descriptionStartOnboard'
    }`,
  );

  return (
    <div className="vendor-no-jobs">
      <img className="hero-img" src={blankDashboardImg} alt="" />

      <h1>{title}</h1>

      <p>{`${description} ${!user.vendor ? descriptionOnboardCTA : ''}`}</p>

      {isOnboardingVendor && <ResumeOnboarding />}
    </div>
  );
};

export default WelcomeScreen;
