import i18n from 'i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';

import common_en from './translations/en/common.json';
// import common_de from './translations/de/common.json';
// import common_sl from './translations/sl/common.json';
// import common_it from './translations/it/common.json';
// import common_tr from './translations/tr/common.json';
// import common_hr from './translations/hr/common.json';
// import common_sr from './translations/sr/common.json';
// import common_fr from './translations/fr/common.json';
// import common_ru from './translations/ru/common.json';

import languages_en from './translations/en/languages.json';
// import languages_de from './translations/de/languages.json';
// import languages_sl from './translations/sl/languages.json';
// import languages_it from './translations/it/languages.json';

import categories_en from './translations/en/categories.json';
// import categories_de from './translations/de/categories.json';
// import categories_sl from './translations/sl/categories.json';
// import categories_it from './translations/it/categories.json';

import services_en from './translations/en/services.json';

import roles_en from './translations/en/roles.json';

import tasks_en from './translations/en/tasks.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        categories: categories_en,
        languages: languages_en,
        common: common_en, // 'common' is our custom namespace
        services: services_en,
        roles: roles_en,
        tasks: tasks_en,
      },
      // de: {
      //   categories: categories_de,
      //   languages: languages_de,
      //   common: common_de,
      // },
      // sl: {
      //   categories: categories_sl,
      //   languages: languages_sl,
      //   common: common_sl,
      // },
      // it: {
      //   categories: categories_it,
      //   languages: languages_it,
      //   common: common_it,
      // },
      // tr: {
      //   common: common_tr,
      // },
      // hr: {
      //   common: common_hr,
      // },
      // sr: {
      //   common: common_sr,
      // },
      // fr: {
      //   common: common_fr,
      // },
      // ru: {
      //   common: common_ru,
      // },
    },
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });

export default i18n;
