import { useLocalStorage } from 'utils/storage.utils';

const defaultSort = {
  sort_by: null,
  sort_order: 'DESC',
};

export const useSorting = (name) => {
  const [sorting, setSorting] = useLocalStorage(`${name}-sorting`, { ...defaultSort });

  const toggleSort = (field) => {
    if (sorting.sort_by === field) {
      setSorting({
        sort_by: sorting.sort_by,
        sort_order: sorting.sort_order === 'ASC' ? 'DESC' : 'ASC',
      });
    } else {
      setSorting({
        sort_by: field,
        sort_order: 'DESC',
      });
    }
  };

  const getSortIcon = (field) => {
    if (field === sorting.sort_by) {
      if (sorting.sort_order === 'ASC') {
        return 'angle-up';
      } else {
        return 'angle-down';
      }
    } else {
      return 'sort';
    }
  };

  const resetSort = () => {
    setSorting({ ...defaultSort });
  };

  return { sorting, toggleSort, getSortIcon, resetSort };
};
