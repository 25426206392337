import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { getCompanyBillingDetailsTeams } from '../../../services/billing';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import { CompanyBillingWrapper } from './CompanyBilling.style';

import Table from '../../common/Table/Table';
import { useCompanyDispatch, useCompanyState, fetchTeams } from '../Company.state';

import BillingTableRow from './BIllingTableRow';

const CompanyBilling = () => {
  const {
    teams: { data: companyTeams },
    billing: { data: companyBilling, loading: isBillingLoading },
  } = useCompanyState();

  const dispatch = useCompanyDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    try {
      dispatch({ type: 'SET_BILLING_ERROR', payload: null });
      dispatch({ type: 'SET_BILLING_LOADING', payload: true });

      const billing = await getCompanyBillingDetailsTeams(id);
      dispatch({ type: 'SET_BILLING', payload: billing });
    } catch (e) {
      dispatch({ type: 'SET_BILLING_ERROR', payload: e });
      throw e;
    } finally {
      dispatch({ type: 'SET_BILLING_LOADING', payload: false });
    }
  }, [dispatch, id]);

  const refetchCompanyTeams = async () => {
    await fetchTeams(dispatch, id);
  };

  const updateBillingDetails = async () => {
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CompanyBillingWrapper>
      {isBillingLoading ? (
        <LoadingPane height={'260px'} />
      ) : (
        <Table width="100%" marginTop="0">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common:company.billing.billingName')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:company.billing.vatNumber')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:company.billing.contactEmail')}</Table.HeaderCell>
              {/* <Table.HeaderCell>Verified billing</Table.HeaderCell> */}
              <Table.HeaderCell>{t('common:company.billing.vatCharges')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:company.billing.balance')}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {companyBilling.length > 0 ? (
              companyBilling.map((billing) => (
                <BillingTableRow
                  key={billing.id}
                  billing={billing}
                  refetchTeams={refetchCompanyTeams}
                  teams={companyTeams}
                  updateBillingDetails={updateBillingDetails}
                />
              ))
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="6">
                  {t('common:company.billing.noBillingDetails')}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}
    </CompanyBillingWrapper>
  );
};

export default CompanyBilling;
