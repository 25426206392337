import { Checkbox } from 'components/common/Checkbox/Checkbox';
import styled from 'styled-components';

export const ProjectCreateWrapper = styled.div`
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;

  margin-bottom: 400px;
  .project-create-header {
    margin-top: 28px;
    margin-bottom: 28px;
    h1 {
      margin-bottom: 10px;
    }
  }

  .project-create-form {
    margin-bottom: 28px;
    .project-create-settings {
      margin-bottom: 28px;
    }

    .project-create-jobs {
      h2 {
        margin-bottom: 10px;
      }
    }
  }
`;

export const StyledTitle = styled.div`
  display: inline-flex;
  align-items: center;
  .remove-job {
    margin-right: 10px;
    border-radius: 50%;
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export const StyledHeader = styled.h4`
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 10px;
  margin-top: 16px;
  font-size: 16px;
`;

export const FormGroup = styled.div`
  display: flex;
  width: 100%;
  column-gap: 10px;
  margin-bottom: 1rem;
  .field {
    width: 100%;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 14px;
`;

export const AnalysisStatus = styled.div``;
