import styled from 'styled-components';

export const LanguagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  .job {
    width: 100%;
  }

  ${({ theme }) => theme.mediaMinWidth.HD`
    > :not(:last-child) {
      margin-bottom: 2em;
    }

    .job {
      display: grid;
      grid-template-columns: 266px auto;
      gap: 0 1.4em;
    }
  `}

  ${({ theme }) => theme.mediaMaxWidth.desktop`
    .dropdown-header {
      height: 100%;
    }
  `}

  ${({ theme }) => theme.mediaMaxWidth.HD`
    > :last-child {
      margin-bottom: 0;
    }

    .dropdown {
      margin-top: 14px;
      margin-bottom: 56px;
      width: 100%;
    }
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .dropdown {
      margin-bottom: 0;
      margin-top: 4px;
    }

    .job {
      padding: 17px ${theme.paddings.mainContainer.mobile.x};
      border-bottom: 1px solid ${theme.colors.greyBright};
    }
  `}
`;

export const TargetLanguage = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #f6f6f6;
  font-family: 'DM Sans', sans-serif;
  align-items: center;

  .flag {
    width: 60px;
    height: 45px;
    border-radius: 4px;
    grid-row: 1/3;
    margin: 17px;
  }

  .lang-name {
    margin-top: auto;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .payable-words {
    display: flex;
    height: fit-content;
    color: ${({ theme }) => theme.colors.greyDark};
    .icon {
      margin-left: 8px;
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.HD`
    background-color: transparent;
    width: 100%;

    .flag__wrapper {
      margin-right: 9px;
      display: flex;
      align-items: center;
    }

    .flag {
      width: 24px;
      height: 16px;
      margin: 0;
    }

    .language-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `}
`;

export const CommentSection = styled.div`
  margin-top: calc(2rem - 0.14286em);

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    background-color: ${theme.colors.greyLight};
    padding: 17px ${theme.paddings.mainContainer.mobile.x};
  `}
`;
