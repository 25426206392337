import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

import {
  Brand,
  BrandMenuItem,
  SidebarFooter,
  CollapseButton,
  HidingText,
  MainMenuText,
  MenuContainer,
  MenuItem,
  SidebarContainer,
  SubMenuItem,
  Divider,
  Wrapper,
  Background,
  SidebarHeader,
} from './Sidebar.styles';

import { Support, UserActions } from './Sidebar.components';
import MenuIcon from './MenuIcon';
import { Icon } from '../../common/Icon/Icon';
import { Popup } from '../../common/Popup/Popup';
import brandIcon from './assets/brand.svg';
import brandIconExtended from './assets/brand-extended.svg';
import { getSidebarRoutes } from '../../../routes';
import { connect } from 'react-redux';
import { breakpoints } from 'style/theme';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { sizes } from 'style/theme';
import { setCssVariable } from 'utils/base.utils';
import MiniSteps from '../../common/Steps/MiniSteps';
import { isVendorOnboardStepOneValid } from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';

const Sidebar = ({ user, isCollapsed, setIsCollapsed, subscriptions, pageTitle, stepsLength }) => {
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  const [isMobileCollapsed, setIsMobileCollapsed] = useState(true);
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile}px)`);
  const isHDBreakpoint = useMediaQuery(`(max-width: ${breakpoints.HD}px)`);

  // Refs
  const sidebarHeaderRef = useRef(null);

  /**
   * Handle sidebar header resize
   */
  const handleSidebarHeaderResize = (entries) => {
    for (let entry of entries) {
      const cr = entry.contentRect;

      setCssVariable('--sidebar-header-height', cr.height + 'px');
    }
  };

  /**
   * When sidebar collapses set css variable which stores the current sidebar width
   */
  useEffect(() => {
    setCssVariable('--sidebar-current-width', isCollapsed ? sizes.sidebar.collapsed : sizes.sidebar.expanded);
  }, [isCollapsed]);

  useResizeObserver(handleSidebarHeaderResize, sidebarHeaderRef);

  const isLinkActive = (linkPathnames) => {
    const isActive = linkPathnames.reduce((acc, currValue) => {
      if (pathname === currValue) {
        return true;
      }
      return acc;
    }, false);

    return isActive;
  };

  const handleBackgroundClick = (e) => {
    setIsMobileCollapsed(true);
  };

  const handleMenuIconClick = (e) => {
    // stop icon click from redirecting
    e.preventDefault();
    setIsMobileCollapsed(!isMobileCollapsed);
  };

  const getBrandIcon = () => {
    if (isMobile || isCollapsed || window.innerWidth < breakpoints.mobile) return brandIcon;

    return brandIconExtended;
  };

  const menuLinks = getSidebarRoutes({
    companyId: user.company_owner_id,
    userId: user.id,
    subscriptions: subscriptions,
    teams: user.teams,
    isVendor: user.vendor || isVendorOnboardStepOneValid(),
  }).map((route) => {
    const linkPath = route.sidebar ? route.sidebar.link : route.path;

    const linkProps = {
      onClick: () => sendUserInteraction(`sidebar link click to ${linkPath}`),
      to: linkPath,
      key: linkPath,
      'data-cy': `sidebar-${route.name.toLowerCase()}-link`,
    };

    return (
      route.allowedRoles.indexOf(user.role) > -1 && (
        <Link {...linkProps}>
          {route.sidebar.level > 0 ? (
            (!isCollapsed || isMobile) && (
              <SubMenuItem
                id={route.sidebar.id}
                active={isLinkActive([linkPath])}
                highlighted={isLinkActive(route.sidebar.highlighted)}
                isTeam={route.name === 'Team'}
              >
                <MainMenuText>
                  <Icon name={route.sidebar.icon} size={20} />
                  <HidingText
                    animate={isCollapsed && !isMobile ? 'hidden' : 'shown'}
                    style={{ marginLeft: '30px' }}
                  >
                    {t(route.sidebar.text)}
                  </HidingText>
                </MainMenuText>
              </SubMenuItem>
            )
          ) : route.sidebar.level === 0 ? (
            <Popup
              dark
              style={{ visibility: isCollapsed ? 'visible' : 'hidden' }}
              trigger={
                <MenuItem
                  id={route.sidebar.id}
                  active={isLinkActive([linkPath])}
                  highlighted={isLinkActive(route.sidebar.highlighted)}
                  $isCollapsed={isCollapsed && !isMobile}
                >
                  <MainMenuText>
                    <Icon name={route.sidebar.icon} size={24} />
                    <HidingText
                      $isCollapsed={isCollapsed && !isMobile}
                      animate={isCollapsed && !isMobile ? 'hidden' : 'shown'}
                    >
                      {t(route.sidebar.text)}
                    </HidingText>
                  </MainMenuText>
                </MenuItem>
              }
              position="right-center"
              content={t(route.sidebar.text)}
              open={isMobile ? false : undefined}
            />
          ) : null}
        </Link>
      )
    );
  });

  /**
   * Runs on desktop medium breakpoint change,
   * if true, collapse sidebar
   */
  useEffect(() => {
    if (isHDBreakpoint) {
      setIsCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHDBreakpoint]);

  return (
    <>
      <SidebarContainer
        data-cy="sidebar-main"
        id="sidebar"
        $isCollapsed={isCollapsed}
        animate={isCollapsed ? 'collapsed' : 'expanded'}
      >
        <SidebarHeader ref={sidebarHeaderRef}>
          <Link
            data-cy="logo-home-link"
            to="/projects"
            onClick={() => sendUserInteraction('click sidebar brand logo')}
            title="Go to homepage"
          >
            <BrandMenuItem active={isLinkActive(['/projects'])} highlighted={isLinkActive(['/projects'])}>
              <Brand src={getBrandIcon()} alt="projects screen" />
              {isMobile && (
                <div className="page-title__wrapper">
                  <p className="page-title">{pageTitle}</p>
                  {new URLSearchParams(search).get('step') && (
                    <MiniSteps
                      step={new URLSearchParams(search).get('step')}
                      stepsLength={stepsLength}
                      loading={!stepsLength}
                    />
                  )}
                </div>
              )}
            </BrandMenuItem>
          </Link>

          {isMobile && <MenuIcon open={!isMobileCollapsed} onClick={handleMenuIconClick} />}
        </SidebarHeader>
        <Wrapper isMobileCollapsed={isMobileCollapsed}>
          <MenuContainer>{menuLinks}</MenuContainer>
          <SidebarFooter className={isCollapsed && !isMobile ? 'collapsed' : ''}>
            <UserActions user={user} isMobile={isMobile} />

            <Divider>
              <CollapseButton data-cy="sidebar-collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
                <Icon name={isCollapsed ? 'angle-right' : 'angle-left'} />
              </CollapseButton>
            </Divider>

            <Support isCollapsed={isCollapsed} />
          </SidebarFooter>
        </Wrapper>
      </SidebarContainer>
      <Background show={isMobile && !isMobileCollapsed} onClick={handleBackgroundClick} />
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.userStore.user,
  subscriptions: state.subscriptionsStore.subscriptions,
  stepsLength: state.projectsStore.stepsLength,
});

export default connect(mapStateToProps)(Sidebar);
