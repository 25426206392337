import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 0;
  display: flex;
  min-height: 0; // don't delete
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    flex-direction: column;
  `}
`;
