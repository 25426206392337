import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 26px;

  > a {
    color: inherit;

    .taia-card {
      height: 100%;
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.desktopMedium`
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  `}
`;
