import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Table from '../../common/Table/Table';
import Pagination from '../../common/Pagination/Pagination';
import { useDebounce } from '../../../hoc/debouncer';
import { TableToolbar } from '../../common.styles';
import ProjectLanguages from '../../common/ProjectLanguages/NewProjectLanguages';
import TemporaryFilesDownload from './TemporaryFilesDownload';

import { getPmProjects } from '../../../services/project';
import LoadingPane from '../../common/LoadingPane/LoadingPane';
import Message from '../../common/Message/Message';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { TruncatedWrapper } from './AttemptedProjects.styles';
import { useLocalStorage } from 'utils/storage.utils';
import { formatAPIDateString } from 'utils/date.utils';

const entriesPerPage = 13;

const AttemptedProjects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [, setError] = useState(false);
  const [activePage, setActivePage] = useLocalStorage('admin-attempted-project-page', 1);
  const [totalPages, setTotalPages] = useState(1);
  const [projects, setProjects] = useState([]);

  const { t } = useTranslation();
  const { debounce } = useDebounce();

  useEffect(() => {
    const fetchProjects = async (page) => {
      debounce(
        'fetchProjects',
        async () => {
          try {
            setIsLoading(true);
            setError(false);
            const data = await getPmProjects(entriesPerPage, page);
            setProjects(data.data);
            if (page > data.last_page) {
              setActivePage(data.last_page);
            }
            setTotalPages(data.last_page);
          } catch (e) {
            setError(true);
            console.error(e);
          } finally {
            setIsLoading(false);
          }
        },
        400,
      );
    };
    fetchProjects(activePage);
  }, [activePage, debounce, setActivePage]);

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('attempted projects page change');
    setActivePage(activePage);
  };

  const projectRows = projects.map((project) => {
    return (
      <Table.Row key={project.id} paddingY="14px">
        <Table.Cell>{project.id}</Table.Cell>
        <Table.Cell>
          <Link
            onClick={() => {
              sendUserInteraction('attempted projects user email link click');
            }}
            to={`/admin/user/${project.user_id}`}
          >
            {project.user_email}
          </Link>
        </Table.Cell>
        <Table.Cell>{formatAPIDateString({ dateString: project.created_at })}</Table.Cell>
        <Table.Cell>
          <TruncatedWrapper>{t('categories:' + project.category)}</TruncatedWrapper>
        </Table.Cell>
        <Table.Cell>{project.word_count}</Table.Cell>
        <Table.Cell>
          <ProjectLanguages project={project} />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <TemporaryFilesDownload projectId={project.id} />
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      <TableToolbar>
        {totalPages > 1 && (
          <Pagination
            activePage={activePage}
            totalPages={totalPages}
            onPageChange={handlePaginationChange}
            className="left"
          />
        )}
      </TableToolbar>
      {isLoading ? (
        <LoadingPane />
      ) : projectRows.length > 0 ? (
        <Table width="100%">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.customer')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.createdAt')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.category')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.words')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.projects.headers.langComb')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">{t('common:admin.projects.headers.file')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{projectRows}</Table.Body>
        </Table>
      ) : (
        <Message text={t('common:projects.noProjectsFound')} type="info" />
      )}
    </>
  );
};

export default AttemptedProjects;
