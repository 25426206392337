import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import ActivityLog from '../ActivityLog/ActivityLog';
import Comment from './Comment';
import JobsTable from './DiyTable';
import LinkBack from '../../common/LinkBack/LinkBack';
import OriginalFilesDownload from 'components/common/OriginalFilesDownload/OriginalFilesDownload';
import ProjectControls from './ProjectControls';

import { Container, ProjectName, SubHeading, StyledProjectLanguages } from './CatapultProjectOrdered.styles';
import LoadingPane from '../../common/LoadingPane/LoadingPane';

import { hasActiveSubscriptions } from 'utils/catapult.utils';
import { postUpdateProjectById } from '../../../services/project';
import { subtractFreeUsage } from 'store/subscriptionsSlice';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useHistory, useLocation } from 'react-router';

const CatapultProjectOrdered = ({ project, refreshProject }) => {
  const [loading, setIsLoading] = useState(false);
  const { id, name } = project;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.subscriptionsStore.subscriptions);
  const history = useHistory();
  const location = useLocation();

  const canOpenCatLink = useMemo(() => {
    return hasActiveSubscriptions(subscriptions);
  }, [subscriptions]);

  const setIsDIY = async (isDiy) => {
    setIsLoading(true);
    try {
      await postUpdateProjectById({
        category_id: project.category_id,
        comment: project.comment,
        diy: isDiy,
        id: project.id,
      });
      await refreshProject();
    } catch (e) {
      toast.error(t('common:toasts.updateDiy.error'));
      setIsLoading(false);
    }
  };

  const canOrderTranslation = useMemo(() => {
    return project.jobs.every((job) => Number(job.progress) < 5);
  }, [project.jobs]);

  /**
   * Add a catapult query to current url, this indicates that the project is diy type.
   * Mainly used by Help hero
   */
  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: '?catapult=1',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingPane height={'100%'} />
      ) : (
        <>
          {project.status_id === 10 && canOrderTranslation && (
            <LinkBack
              text={t('common:projects.analyse.back')}
              onClick={() => {
                sendUserInteraction('catapult project ordered back to translation type click');
                dispatch(subtractFreeUsage(project.total_words));
                setIsDIY(null);
              }}
              to={`/project/${project.id}`}
            />
          )}
          <ProjectName>{name}</ProjectName>
          <SubHeading>{`${t('common:projects.analyse.details')}:`}</SubHeading>
          <StyledProjectLanguages project={project} />
          <OriginalFilesDownload projectId={id} icon={false} buttonActionType="secondary" />
          <JobsTable canOpenCatLink={canOpenCatLink} jobs={project.jobs} />
          <ProjectControls project={project} refreshProject={refreshProject} />
          <Comment comment={project.comment} />
          <ActivityLog logs={project.logs} />
        </>
      )}
    </Container>
  );
};

export default CatapultProjectOrdered;
