import httpClient from './httpclient';
import { format } from 'date-fns';

export const getCalendar = async ({ from, to }) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/calendar`, {
      params: {
        from: format(from, 'MM/dd/yyyy'),
        to: format(to, 'MM/dd/yyyy'),
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postCalendar = async ({ date }) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/calendar`, {
      date: format(date, 'MM/dd/yyyy'),
    });

    return data;
  } catch (e) {
    throw e;
  }
};
