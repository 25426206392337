import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { classnames } from 'utils/base.utils';

const StyledCell = styled.td`
  &.unstyled {
    padding: 0 !important;

    a {
      padding: 14px 8px 14px 14px;
      display: block;
      color: inherit;
    }
  }
`;

const LinkTableCell = ({ text = '', children, to = '', unstyled = false, ...rest }) => {
  const classes = classnames('link-cell', rest.classnames, { unstyled: unstyled });
  return (
    <StyledCell {...rest} className={classes}>
      <Link to={to}>{children ? children : text}</Link>
    </StyledCell>
  );
};

export default LinkTableCell;
