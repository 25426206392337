import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputLabel from './InputLabel';
import { Container, InputContainer, InputsContainer } from './Inputs.styles';
import { BillingGroupContainer, DiscountText } from './UserDiscountInputs.styles';

import { hasAdminPermissions } from '../../../../utils/user.utils';
import { withTheme } from 'styled-components';
import { Icon } from '../../Icon/Icon';
import { Input } from 'components/common/Input/Input';
import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';

const UserDiscountsInputs = ({ billing_group_id, country_id, discount, updateProperty, theme }) => {
  const billingGroups = useSelector((state) => state.classifiersStore.billingGroups);
  const availableCountries = useSelector((state) => state.classifiersStore.countries);

  const userRole = useSelector((state) => state.userStore.user.role);

  const isAdmin = hasAdminPermissions(userRole);
  const { t } = useTranslation();

  const getCountryData = () => {
    const country = availableCountries.find((country) => country.id === country_id);
    if (!country) {
      return null;
    }
    return country;
  };

  const getDefaultBillingGroup = () => {
    const countryData = getCountryData(country_id);
    if (countryData) {
      return countryData.billing_group;
    }
    return null;
  };

  const getBillingGroup = () => {
    if (billing_group_id) {
      return billingGroups.find((group) => group.id === billing_group_id);
    }
    return getDefaultBillingGroup();
  };

  const getBillingGroupOptions = () => {
    const defaultBillingGroup = getDefaultBillingGroup();

    const defaultOption = defaultBillingGroup
      ? {
          key: defaultBillingGroup.id,
          text: 'Default billing group (0%)',
          value: null,
        }
      : null;

    const billingGroupOptions = billingGroups
      .map((group) => {
        return {
          key: group.id,
          text: `${group.name} (${group.discount_percentage}%)`,
          value: group.id,
        };
      })
      .filter((option) => !!option);

    return [defaultOption, ...billingGroupOptions].filter((option) => !!option);
  };

  const billingGroupOptions = getBillingGroupOptions();
  const billingGroup = getBillingGroup();

  const handleGroupChange = (e, { name, value }) => {
    updateProperty({ name: 'billing_group_id', value });
  };

  const handleDiscountChange = (e, { name, value }) => {
    const newValue = parseInt(value);

    if (newValue > -1 && newValue < 101) {
      updateProperty({ name, value: newValue });
    } else if (value === null || value === '') {
      updateProperty({ name, value: null });
    }
  };

  return isAdmin ? (
    <Container>
      <Icon name="user" size="large" color={theme.colors.blue} style={{ display: 'block' }} />
      <InputsContainer>
        <BillingGroupContainer>
          <InputContainer>
            <SelectDropdown
              label={t('common:userInfo.billing.labels.billingGroup')}
              name="billing_group_id"
              search
              selection
              onChange={handleGroupChange}
              options={billingGroupOptions}
              value={billing_group_id}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel text={t('common:userInfo.billing.labels.AIDiscount')} />
            <DiscountText>{`${billingGroup ? billingGroup.discount_percentage : 0} %`}</DiscountText>
          </InputContainer>
        </BillingGroupContainer>
        <InputContainer customwidth="200px">
          <Input
            label={`${t('common:userInfo.billing.labels.billingDiscount')} (%)`}
            name="discount"
            onChange={handleDiscountChange}
            value={discount || ''}
            type="number"
          />
        </InputContainer>
      </InputsContainer>
    </Container>
  ) : null;
};

export default withTheme(UserDiscountsInputs);
