import styled from 'styled-components';
import { Icon } from '../../../common/Icon/Icon';

export const HelpText = styled.span`
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-right: 5px;
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.blue};
`;

export const HelpPopupContainer = styled.div`
  display: flex;
`;
