import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import ProjectLanguages from '../../common/ProjectLanguages/ProjectLanguages';
import Label from 'components/common/Label/Label';
import PercentageGraph from 'components/common/PercentageGraph/PercentageGraph';

import { useTranslation } from 'react-i18next';
import { isProjectProofread } from 'utils/jobs.utils';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { setCssVariable } from 'utils/base.utils';

const Container = styled.div`
  padding: 2.5em;
  background: #fff;
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;
  width: 100%;

  .project-details {
    width: 50%;
    .team {
      color: #999999;
    }

    .project-name-container {
      display: flex;
      column-gap: 0.6rem;
      align-items: center;
      margin: 0.5rem 0 1rem;
    }
    h3 {
      margin: 0;
      font-family: 'DM Sans', sans-serif;
      font-size: 1.71428571em;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 5em);
    }
  }

  .word-counts {
    margin-left: auto;
    font-family: 'DM Sans', sans-serif;

    .count-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.desktopMedium`
    padding: 1.5em 2.5em;

    flex-wrap: wrap;

    .project-details {
      width: 100%;
    }

    .word-counts {
      margin-top: 16px;
      width: 100%;

      .percentage-graph {
        .graph {
          display: none;
        }
      }
    }


  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    display: none;
  `}
`;

const OrderProjectHeader = ({ project }) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  useResizeObserver((entries) => {
    // Set css variable for project order header height
    for (let entry of entries) {
      setCssVariable('--project-order-header-height', entry.target.getBoundingClientRect().height + 'px');
    }
  }, ref);

  const isProofread = useMemo(() => {
    return isProjectProofread(project);
  }, [project]);

  const percentageGraphValues = [
    {
      value: project.total_words,
      text: t('common:projects.analyse.totalWords'),
      description: t('common:projects.analyse.totalWordsDescription'),
    },
    {
      value: project.payable_words,
      text: t('common:projects.analyse.payableWords'),
      description: t(
        `common:projects.analyse.${isProofread ? 'proofreadPayableDescription' : 'payableWordsDescription'}`,
      ),
    },
  ];

  return (
    <Container ref={ref}>
      <div className="project-details">
        {project.team && <span className="team">{project.team.name}</span>}
        <div className="project-name-container">
          <h3>{project.name}</h3>
          <Label text={`ID: ${project.id}`} backgroundColor="#1caac5" />
          <StatusLabel project={project} />
        </div>
        <ProjectLanguages project={project} style={{ fontSize: '14px' }} showWords />
      </div>

      <div className="word-counts" id="order-project-analysis">
        <div className="count-title">{t('common:projects.analyse.analysis')}:</div>
        <PercentageGraph values={percentageGraphValues} />
      </div>
    </Container>
  );
};

export default OrderProjectHeader;
