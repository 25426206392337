import React from 'react';
import { classnames } from 'utils/base.utils';
import { Icon } from '../Icon/Icon';

// Styles
import './style.scss';

export const Checkbox = ({
  checked,
  onClick = () => {},
  onChange = () => {},
  label,
  style,
  className,
  name,
  value,
  error,
  disabled = false,
  toggle = false,
  size = 'small',
  cypressID = 'checkbox',
  indeterminate = false,
}) => {
  const handleClick = (e) => {
    e.persist();
    if (!disabled) {
      onClick(e, { name, value, checked: !checked });
      onChange(e, { name, value, checked: !checked });
    }
  };

  const classes = classnames('taia-checkbox checkbox', className, size, { toggle, disabled, indeterminate });
  const containerClasses = classnames('checkbox-container', { error });
  const showIndeterminate = indeterminate && !toggle;

  return (
    <label style={style} className={classes}>
      <input
        data-cy={cypressID}
        type="checkbox"
        onChange={handleClick}
        checked={checked}
        disabled={disabled}
      />
      <span className={containerClasses}>
        {showIndeterminate && <Icon className="icon-minus" name="minus" size="large" />}
        {toggle ? (
          <span className="toggle-circle" />
        ) : (
          <Icon className="icon-check" name="check" size="large" />
        )}
      </span>
      {label && <span className="text">{label}</span>}
    </label>
  );
};
