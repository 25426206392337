import React from 'react';
import styled from 'styled-components';
import { getHighlightedSegments } from 'utils/string.utils';

const StyledRadio = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  input[type='radio'] {
    opacity: 0;
    position: absolute;
  }

  .outer-radio {
    display: inline-flex;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: solid 1px #c4c4c4;
    transition: border-color 0.2s ease-out;
    background-color: ${({ theme }) => theme.colors.white};
    align-items: center;
    justify-content: center;
    position: relative;
    .inner-radio {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      transition: background-color 0.2s ease-out;
    }
  }

  input[type='radio']:checked + .outer-radio {
    border: solid 2px ${({ theme }) => theme.colors.blue};
    .inner-radio {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }

  input[type='radio']:focus + .outer-radio:after {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    border-radius: 15px;
    border: 2px solid ${({ theme }) => theme.colors.blueLight};
  }

  label {
    margin-left: 12px;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.greyDark};
    transition: color 0.2s ease-out;
    cursor: pointer;
  }

  input[type='radio']:checked ~ label {
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    align-items: initial;

    .outer-radio {
      min-width: 22px;
      min-height: 22px;
    }
  `}
`;

export const Radio = ({
  checked,
  label,
  onChange = () => {},
  value,
  name,
  highlighted,
  className,
  disabled = false,
  style,
  ...rest
}) => {
  const handleClick = (e) => {
    e.persist();
    onChange(e, { value, name });
  };

  const renderLabel = () => {
    if (!highlighted) return label;
    if (typeof label !== 'string') return label;
    const segments = getHighlightedSegments(label, highlighted);
    return segments.map(({ text, highlighted }, idx) =>
      highlighted ? <strong key={text + idx}>{text}</strong> : text,
    );
  };

  return (
    <StyledRadio className={className} style={style} onClick={handleClick} disabled={disabled} {...rest}>
      <input type="radio" checked={checked} disabled={disabled} name={name} readOnly />
      <span className="outer-radio">
        <span className="inner-radio" />
      </span>
      {label && <label htmlFor="radio">{renderLabel()}</label>}
    </StyledRadio>
  );
};
