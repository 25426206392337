import Loader from 'components/common/Loader/Loader';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header } from 'semantic-ui-react';
import { getCatapultJobID } from 'services/project';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { RedirectCatapultContainer } from './RedirectCatapult.style';

const RedirectCatapult = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);
  const { id } = useParams();

  const referer = useMemo(() => query.get('referer'), [query]);

  useEffect(() => {
    const redirect = async () => {
      sendUserInteraction('redirected from catapult project');

      try {
        const { project_id } = await getCatapultJobID({ jobId: id, referer: referer });
        history.push(`/project/${project_id}`);
      } catch (e) {
        toast.error(t('common:toasts.catapultRedirect.error'));
      }
    };
    redirect();
  }, [id, t, history, referer]);

  return (
    <RedirectCatapultContainer>
      <Header as="h1">{t('common:catapultRedirect.header')}</Header>
      <div className="sub-header">{t('common:catapultRedirect.subHeader')}</div>
      <Loader active />
    </RedirectCatapultContainer>
  );
};

export default RedirectCatapult;
