import React from 'react';

import { Icon } from '../Icon/Icon';
import { classnames } from 'utils/base.utils';

// Styles
import './style.scss';

const UserAvatar = ({ src = null, icon = <Icon className="placeholder-icon" name="user" />, className }) => {
  const cssClasses = classnames('user-avatar', className, { 'no-img': !src });

  if (src) {
    return <img className={cssClasses} src={src} alt="" />;
  }

  return <div className={cssClasses}>{icon}</div>;
};

export default UserAvatar;
