import TranslationUpload from 'components/AdminProject/TranslationUpload/TranslationUpload';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Modal } from 'semantic-ui-react';
import TaiaButton from '../Button/Button';

const UploadTranslationModal = ({ project, setProjectState = () => {}, setOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal open={true} onClose={() => setOpen(false)}>
      <Header>{t('common:admin.project.translatedFiles')}</Header>
      <Modal.Content>
        <TranslationUpload updateProjectState={setProjectState} project={project} />
      </Modal.Content>
      <Modal.Actions>
        <TaiaButton onClick={() => setOpen(false)}>{t('common:cancel')}</TaiaButton>
      </Modal.Actions>
    </Modal>
  );
};

export default UploadTranslationModal;
