import { ACCEPTED_FILES, DOC_FILES, OCR_FILES } from '../constants/files';

export const getFileExtension = (file) => {
  return file && file.name ? file.name.split('.').pop().toLowerCase() : null;
};

export const getFileName = (file) => {
  return file && file.name ? file.name.split('.')[0] : null;
};

export const filesIncludeDoc = (files) => {
  const docIncluded = files.some((file) => {
    const fileExtension = getFileExtension(file);
    return DOC_FILES.includes(fileExtension);
  });
  return docIncluded;
};

// returns an array of files that need OCR
export const filterOCRFiles = (files) => {
  return files.filter((file) => {
    const extension = getFileExtension(file);
    return OCR_FILES.includes(extension);
  });
};

// returns an array of only .docx files
export const filterDocxFiles = (files) => {
  return files.filter((file) => {
    const extension = getFileExtension(file);
    return extension === 'docx';
  });
};

export const isFileTypeValid = (file) => {
  return ACCEPTED_FILES.some((validFileType) => getFileExtension(file) === validFileType);
};

export const filterSupported = (files) => {
  const supportedFiles = [];
  const unsupportedFiles = [];

  for (let file of files) {
    if (isFileTypeValid(file)) {
      supportedFiles.push(file);
    } else {
      unsupportedFiles.push(file);
    }
  }

  return [supportedFiles, unsupportedFiles];
};
