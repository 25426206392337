import httpClient from './httpclient';

export const getFiles = async (page, per_page) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/files`, {
      params: {
        per_page: per_page || 12,
        page: page || 1,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const uploadFile = async (userId, file) => {
  try {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    await httpClient.post(`auth/user/${userId}/files`, formData, {
      timeout: 3000000,
    });
  } catch (e) {
    throw e;
  }
};

export const getUserFiles = async (userId) => {
  try {
    const { data } = await httpClient.get(
      `auth/user/${userId}/files`,

      {
        params: {
          per_page: 10000,
        },
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteFile = async (fileId) => {
  try {
    // /api/auth/files/{file_id}/delete
    await httpClient.post(`auth/files/${fileId}/delete`);
  } catch (e) {
    throw e;
  }
};

export const downloadMultimediaFile = async (fileId) => {
  try {
    const response = await httpClient.get(`auth/files/${fileId}`, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw e;
  }
};
