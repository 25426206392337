import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '../common/Icon/Icon';
import { Checkbox } from '../common/Checkbox/Checkbox';
import { Input } from '../common/Input/Input';
import Message from '../common/Message/Message';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import PasswordValidator from '../common/PasswordValidator/PasswordValidator';

import { getInitialUserData } from '../../utils/initialLoad';
import { postRegister } from '../../services/auth';
import { isValidEmail } from '../../utils/string.utils';

import { StyledButton, StyledFormError, StyledFormWarning } from '../common.styles';
import { StyledForm, StyledAnchor, CheckboxContainer } from './SignUpForm.styles';

import { sendDataLayer, sendGaVirtual, sendUserInteraction } from '../../utils/tagManager.utils';
import { useRecaptchaV3 } from 'hooks/useRecaptcha';

const SignUpForm = ({ name: nameProp, email: emailProp, catapultUser = false, code }) => {
  const [name, setName] = useState(nameProp || '');
  const [email, setEmail] = useState(emailProp || '');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [networkError, setNetworkError] = useState(null);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: null,
    password: null,
    email: null,
    termsOfUse: null,
  });
  const [formInteractions, setFormInteractions] = useState({
    name: false,
    password: false,
    email: false,
  });
  const [formWarnings, setFormWarnings] = useState({
    email: null, // Initialize state to track email warning
  });

  const affiliateToken = useSelector((state) => state.userStore.affiliateToken);
  const { t } = useTranslation();
  const { generateToken } = useRecaptchaV3();

  const handleCheckbox = () => {
    const newTermsOfUse = !termsOfUse;
    setTermsOfUse(newTermsOfUse);
    setFormErrors({
      ...formErrors,
      termsOfUse: newTermsOfUse ? null : true,
    });
  };

  const canSubmit = () => {
    return !formErrors.name && !formErrors.password && !formErrors.email && !formErrors.termsOfUse;
  };

  const handleSubmit = async (e) => {
    sendUserInteraction('clicked create an account');

    e.preventDefault();
    try {
      setIsSubmitting(true);
      const captcha = await generateToken();

      /** Start campaign data setup */
      let campaignData = '';

      const campaignInput = document.querySelector('input[name="campaign_data"]');

      if (!!campaignInput) {
        campaignData = campaignInput.value;
      }

      await postRegister({
        affiliate: affiliateToken,
        balance: code,
        email,
        name,
        password,
        captcha,
        campaign_data: campaignData,
      });

      const pageName = catapultUser ? 'registration-completed-catapult' : 'registration-completed';

      sendGaVirtual({
        page: pageName,
        userData: {
          email,
          name,
        },
      });

      await getInitialUserData();
    } catch (e) {
      if (!!e && !e.response) {
        setNetworkError([t('common:errors.noConnection')]);
      } else if (e.response.status === 422) {
        const errorData = e.response.data.error;

        const errorMessages = Object.values(errorData).reduce((acc, currentValues) => {
          return [...acc, ...currentValues];
        }, []);

        setNetworkError(errorMessages);
      } else {
        throw e;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const setPasswordError = useCallback((isValid) => {
    setFormErrors((prev) => ({ ...prev, password: !isValid }));
  }, []);

  useEffect(() => {
    // empty form errors object
    const newFormErrors = {
      name: null,
      password: null,
      email: null,
      termsOfUse: null,
    };

    if (isEmpty(name) || name.trim() === '') {
      newFormErrors.name = 'common:signUp.errors.name.missing';
    }

    if (isEmpty(email)) {
      newFormErrors.email = 'common:signUp.errors.email.missing';
    } else if (!isValidEmail(email)) {
      newFormErrors.email = 'common:signUp.errors.email.invalid';
    }

    if (!termsOfUse) {
      newFormErrors.termsOfUse = 'common:signUp.errors.termsOfUse.missing';
    }

    setFormErrors(newFormErrors);
  }, [name, email, termsOfUse]);

  const handleInputChange = ({ name, value }) => {
    setFormInteractions((prevInteractions) => ({
      ...prevInteractions,
      [name]: true,
    }));

    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);

      const personalEmailDomains = [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'aol.com',
        'outlook.com',
        'icloud.com',
        'mail.com',
        'msn.com',
        'live.com',
        'yandex.ru',
        'protonmail.com',
        'zoho.com',
      ];
      const domain = value.split('@')[1]; // Extract the domain from the email address

      // Dynamically set or clear the warning for the email field
      if (personalEmailDomains.includes(domain)) {
        setFormWarnings((prevWarnings) => ({
          ...prevWarnings,
          email: 'common:signUp.warnings.email.business', // Assuming this is the translation key for the warning message
        }));
      } else {
        setFormWarnings((prevWarnings) => ({
          ...prevWarnings,
          email: null,
        }));
      }
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  // Effect runs only on component mount
  useEffect(() => {
    sendDataLayer({
      event: 'sign-up-form-loaded',
    });
  }, []);

  return (
    <div style={{ margin: '0 auto' }}>
      {networkError && <Message text={networkError} type="error" />}
      <StyledForm onSubmit={handleSubmit}>
        <div>
          <Input
            type="text"
            label={t('common:signUp.labels.yourFullName')}
            name="name"
            value={name}
            onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
            error={formInteractions?.name && formErrors?.name}
            data-cy="user-signup-full-name-input"
          />
          {formInteractions?.name && formErrors?.name ? (
            <StyledFormError>{t(formErrors.name)}</StyledFormError>
          ) : null}
        </div>
        <div>
          <Input
            type="text"
            label={t('common:signUp.labels.yourEmail')}
            name="email"
            value={email}
            onChange={(e) => handleInputChange({ name: 'email', value: e.target.value })}
            error={formInteractions?.email && formErrors?.email}
            data-cy="user-signup-email-input"
          />
          {formInteractions?.email && formErrors?.email && (
            <StyledFormError data-cy="user-signup-email-not-valid-indicator">
              {t(formErrors.email)}
            </StyledFormError>
          )}
          {formInteractions?.email && formWarnings?.email && (
            <StyledFormWarning data-cy="user-signup-email-warning">{t(formWarnings.email)}</StyledFormWarning>
          )}
        </div>
        <div>
          <Input
            icon={
              <Icon
                name={showPassword ? 'eye' : 'eye-slash'}
                link
                inElement
                onClick={() => setShowPassword((prev) => !prev)}
              />
            }
            type={showPassword ? 'text' : 'password'}
            label={t('common:signUp.labels.password')}
            name="password"
            onChange={(e) => handleInputChange({ name: 'password', value: e.target.value })}
            value={password}
            data-cy="user-signup-password-input"
          />
          <PasswordValidator password={password} setIsValid={setPasswordError} />
        </div>
        <CheckboxContainer>
          <Checkbox
            name="termsOfUse"
            checked={termsOfUse}
            onChange={handleCheckbox}
            error={!!formErrors.termsOfUse}
            label={
              <label>
                <StyledAnchor
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://taia.io/terms-and-conditions/"
                >
                  {t('common:signUp.termsText')}
                </StyledAnchor>
              </label>
            }
            cypressID="user-signup-terms-checkbox"
          />
        </CheckboxContainer>
        <StyledButton
          marginBottom="0px"
          loading={isSubmitting}
          disabled={!canSubmit()}
          actiontype="submit"
          big
          floated="right"
          data-cy="user-signup-submit-button"
        >
          {t('common:signUp.createAnAccount')}
        </StyledButton>

        {/** Hidden input for campaign data, used by google tag manager */}
        <input type="hidden" name="campaign_data" />
      </StyledForm>
    </div>
  );
};

export default SignUpForm;
