import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Divider, Label, List, Modal } from 'semantic-ui-react';
import Table from '../../../common/Table/Table';
import { localiseAndConvertPrice } from '../../../../utils/string.utils';
import { ListContainer } from './CouponInfoModal.style';
import { getUserCurrencyData } from '../../../../store/selectors';
import { formatAPIDateString } from 'utils/date.utils';

const NewCouponModal = ({ currencyData, i18n, t, coupon, open, onClose }) => {
  if (!coupon) {
    return null;
  }
  const usedBy = coupon.users.map((user) => {
    return (
      <Table.Row key={user.id}>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>{formatAPIDateString({ dateString: user.created_at })}</Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Header>
        {t('common:admin.coupons.couponCode')}: {coupon.hash_code}
      </Modal.Header>
      <Modal.Content>
        <ListContainer>
          <List divided selection>
            <List.Item>
              <Label color="blue" horizontal>
                ID:{' '}
              </Label>
              {coupon.id}
            </List.Item>
            <List.Item>
              <Label color="blue" horizontal>
                {' '}
                {t('common:admin.coupons.createdAt')}:{' '}
              </Label>
              {formatAPIDateString({ dateString: coupon.created_at })}
            </List.Item>
            <List.Item>
              <Label color="blue" horizontal>
                {' '}
                {t('common:admin.coupons.lastUsed')}:{' '}
              </Label>
              {coupon.updated_at
                ? formatAPIDateString({ dateString: coupon.updated_at })
                : t('common:admin.coupons.never')}
            </List.Item>
          </List>
          <List divided selection>
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.coupons.discount')}:{' '}
              </Label>
              {coupon.discount_amount
                ? localiseAndConvertPrice({
                    currencyData,
                    number: coupon.discount_amount,
                    language: i18n.language,
                  })
                : `-${coupon.ratio}%`}
            </List.Item>
            <List.Item>
              <Label color="blue" horizontal>
                {' '}
                {t('common:admin.coupons.useLimit')}:{' '}
              </Label>
              {coupon.use_limit ? coupon.use_limit : '∞'}
            </List.Item>
            <List.Item>
              <Label color="blue" horizontal>
                {' '}
                {t('common:admin.coupons.timesUsed')}:{' '}
              </Label>
              {coupon.used}
            </List.Item>
          </List>
        </ListContainer>
        <Divider />
        <Table width="100%">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common:admin.coupons.usedBy')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common:admin.coupons.useDate')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{usedBy}</Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currencyData: getUserCurrencyData(state),
});

export default connect(mapStateToProps)(withTranslation('common')(NewCouponModal));
