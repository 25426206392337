import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isBefore } from 'date-fns';

import Loader from '../../../common/Loader/Loader';
import Message from '../../../common/Message/Message';

import PaymentInfo from './PaymentInfo/PaymentInfo';
import PaymentsHistory from './PaymentsHistory/PaymentsHistory';
import SubscriptionDetails from './SubscriptionDetails/SubscriptionDetails';
import UsersTable from './UsersTable/UsersTable';
import UsageTable from './UsageTable/UsageTable';

import { getSubscriptionById } from '../../../../services/subscriptions';
import { getDefaultPaymentMethod } from '../../../../services/billing';
import { hasAdminPermissions, isCompanyRole } from 'utils/user.utils';

const Subscription = ({ billingId, subscriptionId, subscriptionTeams = [] }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);

  const canManageSubscription = useMemo(() => {
    if (!subscriptionDetails || !user) {
      return false;
    }

    const { billing_detail_id } = subscriptionDetails;
    const { role } = user;
    // if user role is individual, admin or company manager they can manage the subscription
    if (role === 10 || role === 29 || role === 40) {
      return true;
    }

    // if user role is team/billing manager they can manage the subscription
    if (role === 42) {
      const canManageBilling = user.teams.some((team) => {
        return (
          team.billing_detail_id === billing_detail_id &&
          (team.pivot?.role_id === 1 || team.pivot?.role_id === 4)
        );
      });
      return canManageBilling;
    }

    // return false by default
    return false;
  }, [user, subscriptionDetails]);

  const isSubscriptionExpired = useMemo(() => {
    if (!!subscriptionDetails) {
      const { ends_at } = subscriptionDetails;

      if (!!ends_at) {
        const endDate = new Date(ends_at);
        const currentDate = new Date();

        const isExpired = isBefore(endDate, currentDate);

        return isExpired;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [subscriptionDetails]);

  const canEditUsers = useMemo(() => {
    return isCompanyRole(user.role) || hasAdminPermissions(user.role);
  }, [user]);

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const paymentMethod = await getDefaultPaymentMethod({
          billing_id: billingId,
        });
        setPaymentMethod(paymentMethod);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (!!canManageSubscription) {
      fetchPaymentMethod();
    }
  }, [billingId, canManageSubscription]);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        setError(null);
        setIsLoading(true);
        const newDetails = await getSubscriptionById(subscriptionId);
        setSubscriptionDetails(newDetails);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (!!subscriptionId) {
      fetchSubscriptionDetails();
    }
  }, [subscriptionId]);

  const updatePaymentMethod = (newMethod) => {
    setPaymentMethod(newMethod);
  };

  const updateSubscriptionDetails = (newDetails) => {
    setSubscriptionDetails(newDetails);
  };

  return (
    <>
      {isLoading ? (
        <div className="subscription-loader-container">
          <Loader active />
        </div>
      ) : error ? (
        <Message
          header={t('common:catapult.subscription.loadHeader')}
          text={t('common:catapult.subscription.loadError')}
          type="error"
        />
      ) : (
        <>
          <SubscriptionDetails
            canManageSubscription={canManageSubscription}
            subscriptionDetails={subscriptionDetails}
            updateSubscriptionDetails={updateSubscriptionDetails}
            isSubscriptionExpired={isSubscriptionExpired}
            subscriptionTeams={subscriptionTeams}
          />
          {!isSubscriptionExpired && canManageSubscription && canEditUsers ? (
            <UsersTable
              subscriptionDetails={subscriptionDetails}
              updateSubscriptionDetails={updateSubscriptionDetails}
            />
          ) : null}
          {canManageSubscription ? <UsageTable subscriptionId={subscriptionDetails.id} /> : null}
          {canManageSubscription ? <PaymentsHistory billingId={billingId} /> : null}
          {!isSubscriptionExpired && canManageSubscription ? (
            <PaymentInfo
              paymentMethod={paymentMethod}
              subscriptionData={subscriptionDetails}
              updateSubscriptionDetails={updateSubscriptionDetails}
              updatePaymentMethod={updatePaymentMethod}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default Subscription;
