import Button from 'components/common/Button/Button';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header } from 'semantic-ui-react';
import { getTempProjectFiles } from 'services/project';
import { sendUserInteraction } from 'utils/tagManager.utils';

import { DownloadAttemptedContainer } from './DownloadAttempted.style';

const DownloadAttempted = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const downloadFiles = async () => {
      sendUserInteraction('temporary project files download');
      setIsLoading(true);

      try {
        // get file blob
        const response = await getTempProjectFiles(id);
        const headers = response.headers;
        const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
        const blob = new Blob([response.data], { type: headers['content-type'] });
        // save as file
        FileSaver.saveAs(blob, fileName);
      } catch (e) {
        toast.error(t('common:toasts.downloadFile.error'));
      } finally {
        setIsLoading(false);
      }
    };
    downloadFiles();
  }, [id, t]);

  return (
    <DownloadAttemptedContainer>
      <Header as="h1">
        {isLoading ? t('common:downloadAttempted.header') : t('common:downloadAttempted.headerReady')}
      </Header>
      <div className="sub-header">
        {isLoading ? t('common:downloadAttempted.subHeader') : t('common:downloadAttempted.subHeaderReady')}
      </div>
      <Button
        actiontype="primary"
        loading={isLoading}
        onClick={() => {
          sendUserInteraction('go to attempted projects from file download');
          history.push('/admin/projects?tab=attempted');
        }}
      >
        {t('common:downloadAttempted.toAttempted')}
      </Button>
    </DownloadAttemptedContainer>
  );
};

export default DownloadAttempted;
