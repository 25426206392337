import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import { useSorting } from 'hooks/useSorting';
import React, { createContext, useContext } from 'react';

const FilterContext = createContext(null);

export const FiltersProvider = ({ name, initialFilters = {}, children = <></> }) => {
  const { filters, setFilters, resetFilters: reset } = useFilters(name, initialFilters);
  const { activePage, numOfPages, changePage } = usePagination(name);
  const { sorting, toggleSort: toggle, getSortIcon, resetSort } = useSorting(name);

  const changeFilters = (filters) => {
    setFilters(filters);
    changePage({ activePage: 1 });
  };

  const resetFilters = () => {
    reset();
    changePage({ activePage: 1 });
    resetSort();
  };

  const toggleSort = (field) => {
    toggle(field);
    changePage({ activePage: 1 });
  };

  const value = {
    filters,
    changeFilters,
    resetFilters,
    activePage,
    numOfPages,
    changePage,
    sorting,
    toggleSort,
    getSortIcon,
  };
  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFiltersContext = () => {
  return useContext(FilterContext);
};

export const clearFilters = (name) => {
  localStorage.clear(`${name}-page`);
  localStorage.clear(`${name}-filters`);
  localStorage.clear(`${name}-sorting`);
};
