import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const BillForm = styled(Form)`
  max-width: 500px !important;
  margin-bottom: 1em;

  .custom-disabled {
    .ui.disabled {
      opacity: 1 !important;
    }
  }

  .reason {
    color: ${({ theme }) => theme.colors.grey};
    margin-bottom: 1em;
    margin-top: -0.5em;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .reason {
      margin-top: 0;
    }
  `}
`;

export const BillingFieldsStyles = styled.div`
  .reason {
    margin-bottom: 1em;
    margin-top: -0.5em;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .reason {
      margin-top: 1em;
    }
  `}
`;
