import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// Components
import { Icon } from 'components/common/Icon/Icon';

// Styles
import './style.scss';

const LinkBack = ({ text = {}, to = null, onClick = () => {} }) => {
  const history = useHistory();

  const RenderComponent = to ? Link : 'button';

  const props = {
    className: 'link-back',
    'data-cy': 'back-link',
    to,
    onClick: (e) => {
      onClick(e);

      if (to === null) history.goBack();
    },
  };

  return (
    <RenderComponent {...props}>
      <div className="back-icon">
        <Icon name="arrow-left" />
      </div>
      <div className="link-text">{text}</div>
    </RenderComponent>
  );
};

export default LinkBack;
