import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Header } from 'semantic-ui-react';
import Message from '../../common/Message/Message';
import { getUser, postProfileUpdate, postUserOnboard } from '../../../services/auth';
import {
  getBillingDetails,
  postBillingCreate,
  postBillingUpdate,
  postCustomer,
  patchCustomer,
  getPaymentMethods,
  getCustomer,
} from '../../../services/billing';
import { postCreateCompany } from '../../../services/company';
import { getBillingSubscriptions } from '../../../services/subscriptions';
import { postUpdateTeam } from '../../../services/teams';
import { isValidEmail } from '../../../utils/string.utils';
import {
  checkAllowedRole,
  checkTeamRole,
  getTeamRole,
  hasAdminPermissions,
  isCompanyRole,
} from '../../../utils/user.utils';
import Button from '../../common/Button/Button';
import LinkBack from '../../common/LinkBack/LinkBack';
import BillingForm from './BillingForm';
import { ReviewBillingWrapper, TeamBillingForm, PhoneForm } from './ReviewBilling.style';
import RoleSelect from './RoleSelect';
import TeamBillingDropdown from './TeamBillingDropdown';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { Input } from 'components/common/Input/Input';

const ReviewBilling = ({
  type = 'project',
  initialBilling = {},
  currentUser,
  user,
  team,
  plan,
  onContinue = () => {},
  initialCustomer = null,
  initialSubscriptions = [],
}) => {
  const { t } = useTranslation();
  const isCompanyUser = useMemo(() => isCompanyRole(user.role), [user]);
  const [originalBilling, setOriginalBilling] = useState(initialBilling);
  const [billing, setBilling] = useState(initialBilling);
  const [role, setRole] = useState(user.role === 1 ? 10 : user.role);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState(user.phone_number || '');
  const [selectedBilling, setSelectedBilling] = useState(initialBilling.id);
  const [selectedTeam, setSelectedTeam] = useState(isCompanyUser ? team : { role_id: 1 });
  const [customer, setCustomer] = useState(initialCustomer);
  const [missingStripeCustomer, setMissingStripeCustomer] = useState(false);
  const [subscriptions, setSubscriptions] = useState(initialSubscriptions);

  const hasEdited = useRef(false);

  const canEditBilling = useMemo(() => {
    if (isCompanyUser) {
      return !checkTeamRole({
        allowedRoles: [2, 3],
        teamRole: getTeamRole({ user: currentUser, teamId: selectedTeam.id }),
      });
    } else {
      return true;
    }
  }, [isCompanyUser, selectedTeam.id, currentUser]);

  const canCreateBilling = useMemo(() => {
    return checkAllowedRole({ allowedRoles: [40, 29, 10], userRole: currentUser.role });
  }, [currentUser.role]);

  useEffect(() => {
    if (isCompanyUser) {
      const billedTeam = user.teams.find((team) => team.billing_detail_id === originalBilling.id);
      setSelectedTeam(billedTeam ? billedTeam : { role_id: 1 });
    }
  }, [originalBilling.id, user, isCompanyUser]);

  useEffect(() => {
    const fetchSubscriptions = async (billing_detail_id) => {
      try {
        if (billing_detail_id === null) {
          return [];
        } else {
          const subs = (await getBillingSubscriptions({ billing_id: billing_detail_id }))['data'];
          return subs;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const fetchCustomer = async (billing_detail_id) => {
      try {
        if (billing_detail_id === null) {
          return null;
        } else {
          const customer = await getCustomer(billing_detail_id);
          return customer;
        }
      } catch (e) {
        if (e.response.status === 404) {
          // limited and full members cannot create stripe customers, lock continue button and show error message
          if (checkTeamRole({ allowedRoles: [2, 3], teamRole: selectedTeam.role_id })) {
            setMissingStripeCustomer(true);
          }

          return null;
        } else {
          console.error(e);
        }
      }
    };

    const fetchBilling = async () => {
      if (selectedBilling) {
        try {
          setIsLoading(true);
          const bill = await getBillingDetails(selectedBilling);

          const custom = await fetchCustomer(selectedBilling);
          setCustomer(custom);
          if (type === 'package') {
            const subs = await fetchSubscriptions(selectedBilling);
            setSubscriptions(subs);
          }

          setBilling(bill);
          setOriginalBilling(bill);
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      } else {
        setOriginalBilling({
          id: null,
          country_id: null,
          company_name: '',
          company_vat: '',
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: '',
          currency_id: type === 'package' ? plan.currency_id : 1,
          user_email: user.email,
        });

        setBilling({
          id: null,
          country_id: null,
          company_name: '',
          company_vat: '',
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: '',
          currency_id: type === 'package' ? plan.currency_id : 1,
          user_email: user.email,
        });
        setCustomer(null);
        setSubscriptions([]);
      }
    };
    fetchBilling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBilling, user, type]);

  const handleSubmit = async () => {
    let bill = { ...originalBilling };
    let userData = { ...user };
    sendUserInteraction('clicked next on billing details screen');
    try {
      setIsSubmitting(true);
      // User is unonboarded
      if (userData.role === 1) {
        // Individual role is selected
        if (role === 10) {
          bill = await postBillingCreate({
            ...billing,
            company_street: `${billing.line1}, ${billing.postal_code} ${billing.city}`,
            user_id: user.id,
          });
          userData = await postUserOnboard({ userRole: role });
        } else if (role === 40) {
          await postUserOnboard({ userRole: 2 });
          const newCompany = await postCreateCompany({
            company_name: billing.company_name,
            comp_discount: 0,
          });
          bill = await postBillingCreate({
            ...billing,
            company_id: newCompany.id,
            company_street: `${billing.line1}, ${billing.postal_code} ${billing.city}`,
            name: 'Default billing',
            user_id: user.id,
          });
          await postUpdateTeam({
            team_id: newCompany.teams[0].id,
            name: `${billing.company_name} team`,
            billing_detail_id: bill.id,
          });
        }
      } else {
        const newBilling = { ...billing };

        if (newBilling.id) {
          if (!isEqual(newBilling, originalBilling)) {
            if (isCompanyUser) {
              if (
                hasAdminPermissions(currentUser.role) ||
                !checkTeamRole({ allowedRoles: [2, 3], teamRole: selectedTeam.role_id })
              ) {
                bill = await postBillingUpdate(newBilling.id, newBilling);
              } else {
                bill = { ...originalBilling };
              }
            } else {
              bill = await postBillingUpdate(newBilling.id, newBilling);
            }
          }
        } else {
          if (checkAllowedRole({ allowedRoles: [29], userRole: currentUser.role })) {
            // Is the admin creating their own billing details or billing details for another user.

            if (checkAllowedRole({ allowedRoles: [29], userRole: role })) {
              bill = await postBillingCreate({ ...newBilling, user_id: user.id });
            } else {
              if (checkAllowedRole({ allowedRoles: [40], userRole: role })) {
                // Only company managers and admins can create new company billing details and assign them to teams
                bill = await postBillingCreate({
                  ...newBilling,
                  company_id: selectedTeam.company_id,
                  company_street: `${billing.line1}, ${billing.postal_code} ${billing.city}`,
                  name: `${selectedTeam.name} billing`,
                });
                await postUpdateTeam({
                  team_id: selectedTeam.id,
                  billing_detail_id: bill.id,
                });
              } else {
                bill = await postBillingCreate({ ...newBilling, user_id: user.id });
              }
            }
          } else {
            if (checkAllowedRole({ allowedRoles: [40], userRole: role })) {
              // Only company managers and admins can create new company billing details and assign them to teams
              bill = await postBillingCreate({
                ...newBilling,
                company_id: selectedTeam.company_id,
                company_street: `${billing.line1}, ${billing.postal_code} ${billing.city}`,
                name: `${selectedTeam.name} billing`,
              });
              await postUpdateTeam({
                team_id: selectedTeam.id,
                billing_detail_id: bill.id,
              });
            } else {
              bill = await postBillingCreate({ ...newBilling, user_id: user.id });
            }
          }
        }
      }

      if (phone.length > 0) {
        if (phone !== userData.phone_number) {
          const newUserData = { ...userData, user_id: user.id, phone_number: phone };
          await postProfileUpdate(newUserData);
          userData = { ...newUserData };
        }
      }
    } catch (e) {
      console.error(e);
      return;
    }

    let custom = customer;
    let paymentMethods = [];
    try {
      if (!custom) {
        custom = await postCustomer({
          billing_id: bill.id,
          line1: bill.line1,
          line2: bill.line2,
          postal_code: bill.postal_code,
          city: bill.city,
          state: bill.state,
          name: bill.company_name,
          // TODO VAT
          tax_exempt: bill.vat_rate !== null || bill.digital_vat_rate !== null ? 'none' : 'exempt',
        });
      } else if (!isEqual(billing, originalBilling)) {
        custom = await patchCustomer({
          billing_id: bill.id,
          line1: bill.line1,
          line2: bill.line2,
          postal_code: bill.postal_code,
          city: bill.city,
          state: bill.state,
          name: bill.company_name,
          // TODO VAT
          tax_exempt: bill.vat_rate !== null || bill.digital_vat_rate !== null ? 'none' : 'exempt',
        });
      }

      if (bill.id === null) {
        paymentMethods = [];
      } else {
        paymentMethods = (await getPaymentMethods({ billing_id: bill.id }))['data'];
      }

      userData = (await getUser())['data'];
    } catch (e) {
      console.error(e);
      return;
    } finally {
      setIsSubmitting(false);
    }

    onContinue({
      billing: bill,
      customer,
      user: userData,
      payment: paymentMethods,
      team: selectedTeam,
      subscriptions,
    });
  };

  const onPhoneChange = (e) => {
    sendUserInteraction('changed phone number');
    setPhone(e.target.value);
  };

  const isValid = useMemo(() => {
    if (checkAllowedRole({ allowedRoles: [40, 42], userRole: role })) {
      return (
        billing.company_name &&
        billing.country &&
        (billing.country.check_vat ? !!billing.company_vat : true) &&
        billing.currency_id &&
        billing.company_vat &&
        billing.line1 &&
        billing.city &&
        billing.postal_code &&
        isValidEmail(billing.user_email)
      );
    }
    return (
      billing.company_name &&
      billing.country &&
      billing.currency_id &&
      billing.line1 &&
      billing.city &&
      billing.postal_code
    );
  }, [
    billing.company_name,
    billing.country,
    billing.currency_id,
    billing.company_vat,
    billing.line1,
    billing.city,
    billing.postal_code,
    billing.user_email,
    role,
  ]);

  const hasActiveSubscriptions = useMemo(() => {
    return subscriptions.reduce((acc, cur) => acc || cur.stripe_status === 'active', false);
  }, [subscriptions]);

  return (
    <ReviewBillingWrapper>
      <LinkBack
        onClick={() => {
          sendUserInteraction('clicked go back on review billing');
        }}
        text={t('common:back')}
      ></LinkBack>
      <div className="review-billing-header">
        <Header as="h1">{t('common:checkout.billing.header')}</Header>
        <div className="sub-header-text">
          {billing ? t('common:checkout.billing.subHeader') : t('common:checkout.billing.subHeaderMissing')}
        </div>
      </div>
      {user.role === 1 ? (
        <RoleSelect
          onRoleChange={(role) => {
            sendUserInteraction('changed billing role');
            setRole(role);
          }}
        />
      ) : null}
      {isCompanyUser && type === 'package' ? (
        <TeamBillingForm>
          <Form.Field width="5">
            <TeamBillingDropdown
              user={user}
              teamId={selectedTeam.id}
              onChange={(id) => {
                const team = user.teams.find((team) => team.id === id);
                setSelectedTeam(team);
                setSelectedBilling(team.billing_detail_id);
                sendUserInteraction('changed selected team and billing');
              }}
            />
          </Form.Field>
        </TeamBillingForm>
      ) : null}

      {billing.id === null && checkAllowedRole({ allowedRoles: [42], userRole: user.role }) ? (
        <Message text={t('common:checkout.billing.noCompanyBilling')} type="warning" />
      ) : (
        <>
          <BillingForm
            loading={isLoading}
            disabled={
              hasAdminPermissions(currentUser.role)
                ? false
                : isCompanyUser
                ? checkTeamRole({ allowedRoles: [2, 3], teamRole: selectedTeam.role_id })
                : false
            }
            disableCurrency={type === 'package'}
            role={role}
            billing={billing}
            onChange={(bill) => {
              sendUserInteraction('changed payment billing info');
              hasEdited.current = true;
              setBilling(bill);
            }}
          >
            {billing.id ? (
              !canEditBilling ? (
                <Message
                  header={t('common:userInfo.billing.cannotEditHeading')}
                  type="warning"
                  text={t('common:userInfo.billing.cannotEdit')}
                />
              ) : null
            ) : !canCreateBilling ? (
              <Message type="warning" text={t('common:userInfo.billing.cannotCreate')} />
            ) : null}
            {isCompanyUser && hasEdited.current ? (
              <Message type="warning" text={t('common:userInfo.billing.editingTeam')} />
            ) : null}
            {missingStripeCustomer && (
              <Message
                header={t('common:userInfo.billing.missingStripeCustomerHeading')}
                type="error"
                text={t('common:userInfo.billing.missingStripeCustomer')}
              />
            )}
          </BillingForm>

          {!user.phone_number ? (
            <PhoneForm>
              <Form.Field>
                <label></label>
                <Input
                  label={`${t('common:checkout.billing.form.phone')} (${t(
                    'common:checkout.billing.form.optional',
                  )}):`}
                  placeholder={t('common:checkout.billing.form.phone')}
                  className="custom-disabled"
                  value={phone || ''}
                  onChange={onPhoneChange}
                  data-cy="checkout-phone-input"
                ></Input>
                <div className="phone-reason">{t('common:checkout.billing.form.phoneReason')}</div>
              </Form.Field>
            </PhoneForm>
          ) : null}

          <div className="already-subscribed">
            {!!hasActiveSubscriptions && (
              <Message
                text={
                  type === 'package'
                    ? `${t('common:checkout.alreadySubscribedDecription')} ${t(
                        'common:checkout.overrideSubscription',
                      )}`
                    : t('common:checkout.alreadySubscribedDecription')
                }
                type="info"
              />
            )}
          </div>

          <div className="continue-button">
            <Button
              loading={isSubmitting}
              actiontype="primary"
              disabled={!isValid || isSubmitting || missingStripeCustomer || isLoading}
              onClick={handleSubmit}
              data-cy="checkout-continue-button"
            >
              {t('common:continue')}
            </Button>
          </div>
        </>
      )}
    </ReviewBillingWrapper>
  );
};

export default ReviewBilling;
