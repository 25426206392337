import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Message from '../../common/Message/Message';
import { getTeamMembers } from '../../../services/teams';
import LoadingPane from '../../common/LoadingPane/LoadingPane';
import { TeamMembersLayout, StyledPagination } from './TeamMembers.style';
import TeamMembersTable from './TeamMembersTable/TeamMembersTable';
import { connect } from 'react-redux';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

const TeamMembers = ({ t }) => {
  const { id } = useParams();

  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useLocalStorage('team-members-page', 1);
  const [lastPage, setLastPage] = useState(1);

  const fetchMembers = async (page) => {
    try {
      setIsLoading(true);
      const { data, last_page } = await getTeamMembers({ team_id: id, page: page });

      setMembers(data);
      if (page > last_page) {
        setPage(lastPage);
      }
      setLastPage(last_page);
    } catch (e) {
      setIsError(true);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialMembers = async () => {
      try {
        setIsLoading(true);
        const data = await getTeamMembers({ team_id: id, page: 1 });

        setMembers(data.data);
        setPage(1);
        setLastPage(data.last_page);
      } catch (e) {
        setIsError(true);
        throw e;
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialMembers();
  }, [id, setPage]);

  const onSubmit = async (idx) => {
    fetchMembers(1);
  };

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('team page pagination click');
    setPage(activePage);
    fetchMembers(activePage);
  };

  return (
    <TeamMembersLayout>
      {isLoading ? <LoadingPane /> : <TeamMembersTable members={members} onSubmit={onSubmit} />}
      {lastPage > 1 && (
        <StyledPagination activePage={page} totalPages={lastPage} onPageChange={handlePaginationChange} />
      )}
      {isError && <Message text={t('common:team.members.error')} type="error" />}
    </TeamMembersLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.userStore.user,
});

export default connect(mapStateToProps)(withTranslation('common')(TeamMembers));
