import { Input } from 'components/common/Input/Input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { currencyByIdSelector } from '../../../../store/selectors';

const BalanceInput = ({ currency_id, euro_balance, updateProperty, currencyLabel }) => {
  const getCurrencyById = useSelector(currencyByIdSelector);
  const currencyData = getCurrencyById(currency_id);
  const { t } = useTranslation();

  const getInitialBalance = () => {
    if (!!euro_balance && !!currency_id) {
      const roundedBalance = parseInt((euro_balance * currencyData.conversion_rate).toFixed(2));
      return roundedBalance;
    }
    return euro_balance;
  };

  const [balanceValue, setBalanceValue] = useState(getInitialBalance());

  useEffect(() => {
    let newBalance = euro_balance;

    if (!!euro_balance && !!currency_id) {
      newBalance = parseInt((euro_balance * currencyData.conversion_rate).toFixed(2));
    }

    setBalanceValue(newBalance);
    updateProperty({ name: 'euro_balance', value: newBalance });
    // only want to run this in the rare case that currency_id changes and the value of the balance input needs to be converted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency_id]);

  const handleBalanceChange = (e, { name, value }) => {
    const parsedBalance = parseFloat(value);
    if (value > -1) {
      setBalanceValue(parsedBalance);
      updateProperty({ name, value: parsedBalance });
    }
  };

  return (
    <Input
      label={`${t('common:userInfo.billing.labels.balanceInput')} ${currencyLabel}`}
      name="euro_balance"
      onChange={handleBalanceChange}
      value={balanceValue || ''}
      type="number"
    />
  );
};

export default BalanceInput;
