import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import CatapultProjectOrdered from './CatapultProjectOrdered/CatapultProjectOrdered';
import OrderProject from './OrderProject/OrderProject';
import ProjectInReview from './ProjectInReview/ProjectInReview';
import UpdateWrapper from '../Projects/ProjectsCards/UpdateWrapper';
import ProjectOrdered from './ProjectOrdered/ProjectOrdered';
import { MainContainer } from 'components/common.styles';

import Message from '../common/Message/Message';
import LoadingPage from 'components/common/LoadingPane/LoadingPane';

import { getProject, getProjectJobs, getProjectLogs, getProjectPrices } from '../../services/project';
import { getBillingDetails } from '../../services/billing';

import { updateProject } from '../../store/projectsSlice';
import ProjectLoader from './ProjectLoader/ProjectLoader';
import ProjectWaiting from './ProjectWaiting/ProjectWaiting';
import { withTranslation } from 'react-i18next';
import { PROJECT_STATUS_FAILED } from 'constants/projectStatus';
import ProjectFailed from './ProjectFailed/ProjectFailed';

class Project extends Component {
  state = {
    error: null,
    isLoading: true,
    pricesData: null,
    projectBillingDetails: null,
  };

  findProject = () => {
    const { match, projects } = this.props;
    const id = parseInt(match.params.id);
    return projects.find((project) => project.id === id);
  };

  componentDidMount = async () => {
    this.fetchProject();
  };

  fetchProject = async () => {
    const { dispatch, match, t } = this.props;
    const id = parseInt(match.params.id);

    this.setState({ isLoading: true });

    try {
      const projectResponse = await getProject(id);

      if (projectResponse.success) {
        const billingDetails = await this.getBillingDetails(projectResponse.data);
        const projectJobs = await getProjectJobs(id);
        const projectLogs = await getProjectLogs({ projectId: id });

        const newProject = {
          ...projectResponse.data,
          jobs: projectJobs, // No progress calculation
          logs: projectLogs.data,
        };

        const pricesData = await getProjectPrices(newProject);

        dispatch(updateProject({ project: newProject }));

        this.setState({
          error: null,
          isLoading: false,
          pricesData,
          projectBillingDetails: billingDetails,
        });
      } else {
        this.setState({
          error: t('common:projects.projectInfo.notFound'),
          isLoading: false,
        });
      }
    } catch (e) {
      const error = window.navigator.onLine
        ? t('common:projects.projectInfo.notFound')
        : t('common:errors.noConnection');
      this.setState({
        error,
        isLoading: false,
      });
      throw e;
    }
  };

  getBillingDetails = async (projectData) => {
    try {
      if (projectData.billing_detail_id) {
        const billingResponse = await getBillingDetails(projectData.billing_detail_id);
        return billingResponse;
      }
      return null;
    } catch (e) {
      throw e;
    }
  };

  setPricesData = (pricesData) => {
    this.setState({ pricesData });
  };

  showInReviewMessage = (project) => {
    const { user } = this.props;

    if (project.status_id === 21 && user.role === 42) {
      const projectTeamId = project.team.id;
      const userTeam = user.teams.find((team) => team.id === projectTeamId);
      return !!userTeam && userTeam.pivot.role_id !== 1;
    }
  };

  render() {
    const { projectBillingDetails, error, isLoading, pricesData } = this.state;

    const project = this.findProject();

    return (
      <>
        {isLoading ? (
          <MainContainer>
            <LoadingPage height={'100%'} />
          </MainContainer>
        ) : error || !project ? (
          <MainContainer>
            <Message text={error} type="error" />
          </MainContainer>
        ) : project.status_id === 1 ? (
          <MainContainer>
            <UpdateWrapper project={project} reloadProjects={() => this.fetchProject()}>
              <ProjectLoader setStepParam />
            </UpdateWrapper>
          </MainContainer>
        ) : project.status_id === 22 ? (
          <MainContainer>
            <ProjectWaiting project={project} refreshProject={() => this.fetchProject()} />
          </MainContainer>
        ) : project.status_id === PROJECT_STATUS_FAILED ? (
          <MainContainer className="main-container-project--status-failed">
            <ProjectFailed project={project} reloadProjects={() => this.fetchProject()} />
          </MainContainer>
        ) : this.showInReviewMessage(project) ? (
          <MainContainer>
            <ProjectInReview />
          </MainContainer>
        ) : project.status_id === 2 || project.status_id === 21 ? (
          <OrderProject
            billingDetails={projectBillingDetails}
            project={project}
            pricesData={pricesData}
            setPricesData={this.setPricesData}
            refreshProject={() => this.fetchProject()}
          />
        ) : project.diy ? (
          <MainContainer>
            <CatapultProjectOrdered
              project={project}
              pricesData={pricesData}
              refreshProject={() => this.fetchProject()}
            />
          </MainContainer>
        ) : (
          <ProjectOrdered
            billingDetails={projectBillingDetails}
            project={project}
            pricesData={pricesData}
            onSubmit={() => this.fetchProject()}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  billingDetails: state.userStore.billingDetails,
  projects: state.projectsStore.projects,
  user: state.userStore.user,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Project)));
