import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BillingStatus from './BillingStatus';
import DeleteConfirmation from './DeleteConfirmation';

import { ButtonsContainer } from './TeamsTable.styles';
import { Link } from 'react-router-dom';
import Button from '../../../common/Button/Button';
import Table from '../../../common/Table/Table';
import { Popup } from '../../../common/Popup/Popup';
import { checkAllowedRole } from '../../../../utils/user.utils';
import { ADMIN_ROLES } from '../../../../constants/roles';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { Input } from '../../../common/Input/Input';

const TeamRow = ({ removeTeam, team, role, updateTeam }) => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState(team.name);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    setTeamName(team.name);
  }, [team]);

  const handleNameChange = (e) => {
    e.preventDefault();
    setTeamName(e.target.value);
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    // TODO handle all other exception for when nothing changed after we solve the members / owners things
    if (team.name === teamName) {
      setIsSubmitting(false);
      setIsEditing(false);
      return;
    }
    const teamData = { ...team, name: teamName };

    await updateTeam(teamData);
  };

  const handleCancel = (e) => {
    sendUserInteraction('cancel editing team click');
    e.preventDefault();
    if (team.name !== teamName) {
      setTeamName(team.name);
    }
    setIsEditing(false);
  };

  const managersString =
    team.team_managers.length > 0
      ? team.team_managers.reduce((acc, current, index) => {
          if (index > 0) {
            return `${acc}, ${current.name}`;
          }
          return `${current.name}`;
        }, '')
      : t('common:team.managerNotSet');

  return (
    <>
      {isEditing ? (
        <Table.Row>
          <Table.Cell style={{ paddingBlock: '6px' }}>
            <Input name="name" onChange={handleNameChange} value={teamName} autoFocus size="small" />
          </Table.Cell>
          <Table.Cell>{managersString}</Table.Cell>
          <Table.Cell>
            {team.billing_detail ? (
              <Link to={`/team/${team.id}/billing`}>{team.billing_detail.name}</Link>
            ) : (
              <Link to={`/team/${team.id}/billing`}>{t('common:team.billingNotSet')}</Link>
            )}
            <BillingStatus teamId={team.id} billingDetails={team.billing_detail_id} />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <ButtonsContainer>
              <Button disabled={isSubmitting} icon="times" onClick={handleCancel} />
              <Button
                disabled={isSubmitting}
                icon="check"
                className="check"
                onClick={handleSubmit}
                loading={isSubmitting}
              />
            </ButtonsContainer>
          </Table.Cell>
        </Table.Row>
      ) : (
        <Table.Row>
          <Table.Cell>
            <Link to={`/team/${team.id}/members`}>{team.name}</Link>
          </Table.Cell>
          <Table.Cell>{managersString}</Table.Cell>
          <Table.Cell>
            <span style={{ marginRight: '5px' }}>
              {team.billing_detail ? (
                <Link to={`/team/${team.id}/billing`}>{team.billing_detail.name}</Link>
              ) : (
                <Link to={`/team/${team.id}/billing`}>{t('common:team.billingNotSet')}</Link>
              )}
            </span>
            <BillingStatus teamId={team.id} billingDetails={team.billing_detail_id} />
          </Table.Cell>

          <Table.Cell textAlign="right">
            {checkAllowedRole({ allowedRoles: [...ADMIN_ROLES, 40], userRole: role }) && (
              <ButtonsContainer>
                <Popup
                  trigger={
                    <Button disabled={isSubmitting} icon="edit-alt" onClick={() => setIsEditing(true)} />
                  }
                  dark
                >
                  {t('common:company.teams.editTeam')}
                </Popup>
                <Popup
                  trigger={
                    <Button
                      icon="trash-alt"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      onClick={() => setShowDeleteConfirmation(true)}
                    />
                  }
                  dark
                >
                  {t('common:company.teams.deleteTeam')}
                </Popup>
              </ButtonsContainer>
            )}
          </Table.Cell>
        </Table.Row>
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          onClose={() => setShowDeleteConfirmation(false)}
          removeTeam={removeTeam}
          team={team}
        />
      )}
    </>
  );
};

const TeamsTable = ({ removeTeam, teams, toggleEdit, updateTeam, role }) => {
  const { t } = useTranslation();

  return (
    <Table width="100%" marginTop="0">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('common:team.labels.name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.labels.manager')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.labels.billingName')}</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {teams.map((team) => (
          <TeamRow
            key={`${team.id}-${team.name}`}
            removeTeam={removeTeam}
            team={team}
            role={role}
            toggleEdit={toggleEdit}
            updateTeam={updateTeam}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default TeamsTable;
