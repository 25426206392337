import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from '../../../hooks/useResizeObserver';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
import { Flag } from '../Flag/Flag';

const Container = styled.div`
  display: flex;
  gap: 4px;
  font-family: 'DM Sans', sans-serif;
  color: ${({ theme }) => theme.colors.greyDark};
  flex-wrap: wrap;
  position: relative;
`;

const TargetLanguagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 3px;
`;

export const LanguageLabel = styled.div`
  position: relative;
  height: min-content;
  width: min-content;
  display: flex;
  column-gap: 7px;
  padding: 6px 12px;
  font-size: 14px;
  background: #ededed;
  white-space: nowrap;

  &.not-last::after {
    content: '+';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    background-color: white;
    color: ${({ theme }) => theme.colors.grey};
    border-radius: 50%;
  }

  ${({ theme }) => theme.mediaMaxWidth.HD`
    .language-text {
      display: none;
    }
  `}
`;

const StyledIcon = styled(Icon)`
  align-self: flex-start;
  margin-top: 5px;
  color: #c4c4c4;
`;

const ProjectLanguages = ({ project, className, style, showWords = false }) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const addClasses = () => {
    if (!containerRef.current) return;

    const labels = containerRef.current.children;

    [...labels].forEach((label, idx) => {
      label.classList.remove('not-last');

      if (idx === labels.length - 1) {
        return;
      }
      const currentY = label.getBoundingClientRect().top;
      const nextY = labels[idx + 1].getBoundingClientRect().top;

      if (currentY === nextY) {
        label.classList.add('not-last');
      }
    });
  };

  useLayoutEffect(() => {
    addClasses();
  });

  const targetLanguages = project.target_language
    ? Array.isArray(project.target_language)
      ? project.target_language
      : [project.target_language]
    : null;

  useResizeObserver(addClasses, containerRef);

  return (
    <Container style={style} className={className}>
      {!!project.source_language ? (
        <LanguageLabel>
          <Flag lang={project.source_language} />
          {t('languages:' + project.source_language.code)}
        </LanguageLabel>
      ) : (
        <LanguageLabel>?</LanguageLabel>
      )}
      {!!targetLanguages && (
        <>
          <StyledIcon name="arrow-right" />
          <TargetLanguagesContainer ref={containerRef}>
            {targetLanguages.map((lang) => (
              <LanguageLabel key={lang.id}>
                <Flag lang={lang} />
                <span className="language-text">{t('languages:' + lang.code)}</span>
              </LanguageLabel>
            ))}
          </TargetLanguagesContainer>
        </>
      )}
    </Container>
  );
};

export default ProjectLanguages;
