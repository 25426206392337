import { motion } from 'framer-motion';
import { theme } from 'style/theme';
import styled from 'styled-components';

export const Banner = styled.div`
  position: relative;
  border-radius: 4px 4px 0 0;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.blueLight};

  p {
    padding: 0 1rem 0 1rem;
    margin-bottom: 0;
  }

  .resend {
    margin-bottom: 1rem;
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
    min-width: 460px;
    max-width: 850px;
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: ${theme.paddings.mainContainer.mobile.x};
    padding-right: calc(${theme.paddings.mainContainer.mobile.x} * 2);

    p {
      padding: 0;
    }
  `}
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  .dismiss-icon {
    color: ${({ theme }) => theme.colors.black};
    transition: color ease-in-out 200ms;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    right: ${theme.paddings.mainContainer.mobile.x};
  `}
`;

const contentContainerVariants = {
  collapsed: (mobile) => {
    return {
      transform: mobile ? 'none' : `translateX(${theme.sizes.sidebar.collapsed})`,
      width: mobile ? '100%' : `calc(100% - ${theme.sizes.sidebar.collapsed})`,
    };
  },
  expanded: (mobile) => {
    return {
      transform: mobile ? 'none' : `translateX(${theme.sizes.sidebar.expanded})`,
      width: mobile ? '100%' : `calc(100% - ${theme.sizes.sidebar.expanded})`,
    };
  },
};

export const Container = styled(motion.div).attrs((props) => ({
  initial: props.$isCollapsed ? 'collapsed' : 'expanded',
  variants: contentContainerVariants,
  custom: props.isMobile,
}))`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    left: 0;
    bottom: var(--mobile-steps-controls-height);
  `}
`;

export const ResendText = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: underline;
  cursor: pointer;
`;
