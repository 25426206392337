import { Icon } from 'components/common/Icon/Icon';
import React from 'react';
import styled from 'styled-components';

export const ProjectNameContainer = styled.div`
  h2 {
    font-size: 1.2857142em;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    margin: 0;
  }

  span {
    color: #999999;
    font-weight: normal;
    font-size: 0.875em;
  }
`;

const StyledProgress = styled.div`
  width: ${({ percent }) => (percent || 0) + '%'};
  background: ${({ color }) => color};
  height: 8px;
  border-radius: 7px;
`;

const ProgressWrapper = styled.div`
  background: #ededed;
  border-radius: 7px;
  width: 100%;
  margin: 9px 0;
`;

export const Progress = ({ percent, color, ...rest }) => (
  <ProgressWrapper {...rest}>
    <StyledProgress percent={percent} color={color} />
  </ProgressWrapper>
);

export const ControlsContainer = styled.div`
  width: min-content;
  margin-left: auto;
  margin-right: 1em;
`;

export const StyledIcon = styled(Icon)`
  padding: 11px;
  border-radius: 3px;
  background: #e9e9e9;
`;
