import styled from 'styled-components';

export const CurrencyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  width: 100%;

  .reason {
    margin-top: -0.5em;
    color: ${({ theme }) => theme.colors.grey};
  }
`;
