import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import { ButtonsContainer, Container } from './VersionToast.styles';

import { sendUserInteraction } from 'utils/tagManager.utils';
import LINKS from 'constants/links';

const VersionToast = () => {
  const handleClose = () => {
    sendUserInteraction('clicked close version toast');
    toast.dismiss('version-toast');
  };

  const handleShowMore = () => {
    sendUserInteraction('clicked read more');
    toast.dismiss('version-toast');
  };

  const { t } = useTranslation();

  return (
    <Container>
      {t('common:versionToast.text')}
      <ButtonsContainer>
        <Button actiontype="cancel" fluid onClick={handleClose}>
          {t('common:versionToast.close')}
        </Button>
        <a href={LINKS.blog} rel="noopener noreferrer" target="_blank" onClick={handleShowMore}>
          <Button actiontype="submit" fluid>
            {t('common:versionToast.readMore')}
          </Button>
        </a>
      </ButtonsContainer>
    </Container>
  );
};

export default VersionToast;
