import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  JOB_STATUS_COLORS,
  STATUS_COLORS,
  STATUS,
  JOB_REVISION_STATUS,
  JOB_TRANSLATION_STATUS,
} from './StatusLabel.constants';

import Label from '../Label/Label';

// Styles
import './style.scss';

const StatusLabel = ({
  project,
  data,
  isJobType = false,
  isRevision = false,
  text = '',
  margin,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const isJob = isJobType || isRevision;

  // Get correct color constant depending on if is job
  const colors = isJob ? JOB_STATUS_COLORS : STATUS_COLORS;

  // Get correct translation keys constant depending on if is job and if revision
  const translationKeys = isJob ? (isRevision ? JOB_REVISION_STATUS : JOB_TRANSLATION_STATUS) : STATUS;

  return (
    <Label
      className="taia-status-label"
      color={colors[project.status_id][1]}
      backgroundColor={colors[project.status_id][0]}
      text={t(translationKeys[project.status_id])}
      loading={loading}
      style={{
        margin,
      }}
      {...rest}
    />
  );
};

export default StatusLabel;
