import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-bottom: 20px;
  }
`;

export const StyledAnchor = styled.a`
  color: initial !important;
  text-decoration: underline;
`;

export const ErrorUl = styled.ul`
  margin: 0;
`;

export const CheckboxContainer = styled.div`
  text-align: left;
`;
