import React from 'react';
import styled from 'styled-components';
import {
  StyledTable,
  StyledTableHeaderCell,
  StyledTableCell,
  StyledTableRow,
  StyledTableHeader,
} from './Table.styles';

const Table = React.forwardRef(({ children, width, marginTop, compact, ...rest }, ref) => (
  <StyledTable width={width} marginTop={marginTop} compact={compact} ref={ref} {...rest}>
    {children}
  </StyledTable>
));

Table.Body = styled.tbody``;
Table.Row = StyledTableRow;

Table.Cell = StyledTableCell;

Table.Header = StyledTableHeader;
Table.HeaderCell = StyledTableHeaderCell;

export default Table;
