import React from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Message from '../../common/Message/Message';
import { deleteTeamPendingInvites } from '../../../services/teams';
import LoadingPane from '../../common/LoadingPane/LoadingPane';
import TeamInvitesTable from './TeamInvitesTable/TeamInvitesTable';
import { sendUserInteraction } from 'utils/tagManager.utils';

const TeamInvites = ({ t, invites, setInvites, error, isLoading }) => {
  const removeInvite = (idx) => {
    const newInvtes = [...invites];
    newInvtes.splice(idx, 1);
    setInvites(newInvtes);
  };

  const deleteInvite = async (id, idx) => {
    sendUserInteraction('delete team invite');
    try {
      await deleteTeamPendingInvites({ invitation_id: id });
      removeInvite(idx);
      toast.success(t('common:toasts.revokeTeamInvite.success'));
    } catch (e) {
      toast.error(t('common:toasts.revokeTeamInvite.error'));
      throw e;
    }
  };

  return isLoading ? (
    <LoadingPane />
  ) : error ? (
    <Message text={t('common:team.invites.loadingError')} type="error" />
  ) : (
    <TeamInvitesTable invites={invites} deleteInvite={deleteInvite} />
  );
};

export default withTranslation('common')(TeamInvites);
