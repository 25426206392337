export const versionChanged = () => {
  const oldVersion = localStorage.getItem('app-version');
  if (!!oldVersion) {
    return oldVersion !== process.env.REACT_APP_VERSION;
  }
  // If user does not have an old version saved this is the first time using the app - no need to show release notes.
  return false;
};

export const getVersionInfo = () => {
  const currentVersion = process.env.REACT_APP_VERSION;
  const savedVersion = localStorage.getItem('app-version');

  const versionInfo = {
    minorChange: false,
    majorChange: false,
    version: currentVersion,
    versionChanged: false,
  };

  if (!!savedVersion) {
    const [currentBreaking, currentMajor, currentMinor] = currentVersion.split('.');
    const [savedBreaking, savedMajor, savedMinor] = savedVersion.split('.');

    const minorChange = currentMinor !== savedMinor;
    const majorChange = currentMajor !== savedMajor || currentBreaking !== savedBreaking;
    const versionChanged = minorChange || majorChange;

    updateVersion();

    return {
      ...versionInfo,
      minorChange,
      majorChange,
      versionChanged,
    };
  }

  updateVersion();

  return versionInfo;
};

export const updateVersion = async (onVersionChange = () => {}) => {
  try {
    localStorage.setItem('app-version', process.env.REACT_APP_VERSION);
    await onVersionChange();
  } catch (e) {
    console.error(e);
  }
};

export const saveRemindLater = (value = true) => {
  localStorage.setItem('app-version-reminder', value);
};

export const shouldRemindVersion = () => {
  const shouldRemind = localStorage.getItem('app-version-reminder');
  if (shouldRemind === null) {
    return false;
  }
  return JSON.parse(shouldRemind);
};
