import { AnimateSharedLayout, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const MiniStepsStyles = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &[data-loading='true'] {
    height: 16px;
    width: 20px;
  }

  .text {
    opacity: 0.5;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .graphic {
    display: flex;
    margin-left: 12px;

    > :not(:last-child) {
      margin-right: 7px;
    }

    > :not(:first-child) {
      &::before {
        content: '';
        position: absolute;
        width: 7px;
        height: 2px;
        top: 50%;
        left: -100%;
        transform: translateY(-50%);
        background-color: #b2d3dc;
      }

      &.active {
        &::before {
          background-color: ${({ theme }) => theme.colors.blue};
        }
      }
    }

    .circle {
      border-radius: 100%;
      background-color: #b2d3dc;
      width: 8px;
      height: 8px;
      position: relative;

      &.active {
        background-color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`;

const MiniSteps = ({ step, stepsLength, loading = false }) => {
  return (
    <MiniStepsStyles data-loading={loading} className="mini-steps">
      <AnimateSharedLayout>
        {loading ? (
          <Loader active size="tiny" />
        ) : (
          <>
            <motion.span className="text" layout>
              Step {step} of {stepsLength}
            </motion.span>
            <motion.div className="graphic">
              {new Array(stepsLength).fill(0).map((el, i) => (
                <motion.div key={`circle-${i}`} className={`circle ${i + 1 <= step && 'active'}`} />
              ))}
            </motion.div>
          </>
        )}
      </AnimateSharedLayout>
    </MiniStepsStyles>
  );
};

export default MiniSteps;
