import CouponInput from 'components/Checkout/OrderSummary/CouponInput/CouponInput';
import { BalanceDetails } from 'components/Checkout/OrderSummary/PriceSummary/PriceSummary';
import { PriceLabel } from 'components/Checkout/OrderSummary/PriceSummary/PriceSummary.style';
import { PriceRow } from 'components/Checkout/OrderSummary/PriceSummary/PriceSummary.style';
import Loader from 'components/common/Loader/Loader';
import Message from 'components/common/Message/Message';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { resolveSelectedService } from 'utils/jobs.utils';
import { getJobPrice } from 'utils/jobs.utils';
import Button from '../../common/Button/Button';
import { Icon } from '../../common/Icon/Icon';
import Price from '../../common/Price/Price';

const Container = styled.div`
  min-width: 350px;
  padding: 2.14285714em;
  padding-bottom: 0.71428571em;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    column-gap: 5px;

    h3 {
      margin: 0;
      font-size: 1.71428571em;
      font-family: 'DM Sans', sans-serif;
    }
  }

  h4 {
    font-size: 1.14285714em;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    margin: 1.5em 0 0.5em;
  }

  .products {
    margin: 1.5em 0 0 0;
    color: #999999;
    line-height: 1.64285714em;
    padding-bottom: 0.85714286em;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
    min-height: 100px;

    .loader {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: grid;
      place-items: center;

      background: rgba(255, 255, 255, 0.8);
    }
  }

  .discounts {
    color: ${({ theme }) => theme.colors.blue};
    padding-bottom: 1.1em;
    border-bottom: 1px solid #e1e1e1;

    .discounts-header {
      display: flex;
      color: grey;
      column-gap: 0.4em;
      margin: 1.1em 0 0.3em;
      h4 {
        margin: 0;
        color: #000;
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  .summary-container {
    padding: 1em 0;
    & > div {
      display: flex;
      justify-content: flex-end;
      column-gap: 0.3em;
      color: #999999;
      line-height: 1.64285714em;
    }

    .total {
      color: #000;
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .subtext {
    color: #b2b2b2;
    font-size: 0.85714286em;
    text-align: center;
  }

  ${({ theme }) => theme.mediaMaxWidth.HD`
    min-width: initial;
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: 0;

    .products,
    .discounts,
    .summary-container {
      padding: 0 ${theme.paddings.mainContainer.mobile.x};
    }

    .header {
      padding: 11px ${theme.paddings.mainContainer.mobile.x};
      position: sticky;
      top: 0;
      background-color: ${theme.colors.greyLight};
      z-index: ${theme.zIndex.sidebar};
      border-bottom: 1px solid ${theme.colors.greyBright};

      h3 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
      }
    }
  `}
`;

const SubmitButton = styled(Button)`
  margin-top: 0.5em !important;
  margin-bottom: 0.71428571em !important;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.14285714em !important;
`;

const OrderSummary = ({
  project,
  price,
  loadingPrices,
  nextStep = () => {},
  onLastStep = false,
  onCoupon = () => {},
  children = <></>,
  isLimitedMember,
  isMobile,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const deductBalance = useMemo(() => {
    if (price) {
      return price.user_balance > 0;
    }
    return false;
  }, [price]);

  const currency = useMemo(() => {
    return price ? price.currency : {};
  }, [price]);

  const delivery = useMemo(() => {
    return { price: price.delivery_price, time: price.delivery_time };
  }, [price.delivery_price, price.delivery_time]);

  const subTotal = useMemo(() => {
    if (price) {
      return deductBalance ? price.net_with_deducted_balance : price.net_price;
    }
    return 0;
  }, [price, deductBalance]);

  const vatPrice = useMemo(() => {
    if (price) {
      return subTotal * (price.vat_rate / 100);
    }
    return 0;
  }, [subTotal, price]);

  const totalPrice = useMemo(() => {
    if (price) {
      return deductBalance
        ? price.should_pay_vat
          ? price.gross_with_deducted_balance
          : price.net_with_deducted_balance
        : price.should_pay_vat
        ? price.gross_price
        : price.net_price;
    }
    return 0;
  }, [deductBalance, price]);

  const deductedBalance = useMemo(() => {
    if (price) {
      return price.user_balance - price.balance_left_after_deduct;
    }
    return 0;
  }, [price]);

  const numOfDiscounts = useMemo(() => {
    let num = 0;
    if (price.reg_disc_ratio) num++;
    if (price.spec_disc_ratio) num++;
    if (price.deductBalance) num++;
    if (price.disc_coupon) num++;

    return num;
  }, [price.deductBalance, price.disc_coupon, price.reg_disc_ratio, price.spec_disc_ratio]);

  const discountSum = useMemo(() => {
    return (
      price.reg_discount_value + price.special_discount_value + deductedBalance + price.coupon_discount_value
    );
  }, [deductedBalance, price.coupon_discount_value, price.reg_discount_value, price.special_discount_value]);

  const products = useMemo(() => {
    let prods = null;
    prods = project.jobs.map((job) => {
      if (job.proofread) {
        const jobName = t('common:checkout.orderSummary.services.proofread');

        const jobPrice = job.proofread_price;
        const jobPPW = job.proofread_per_word;

        return {
          id: job.id,
          name: jobName,
          service: 'proofread',
          subname: job.target_language.name,
          sourceLang: job.source_language.name,
          targetLang: job.target_language.name,
          price: jobPrice,
          pricePerWord: jobPPW,
          payableWords: job.payable_words,
          currency: currency,
        };
      } else {
        const selectedService = resolveSelectedService(job);
        const jobName = t('common:checkout.orderSummary.services.service' + selectedService);

        const jobPrice = getJobPrice(job);

        return {
          id: job.id,
          name: jobName,
          service: selectedService,
          subname: job.target_language.name,
          sourceLang: job.source_language.name,
          targetLang: job.target_language.name,
          price: jobPrice,
          currency: currency,
        };
      }
    });

    return prods;
  }, [currency, project.jobs, t]);

  const ProductRows = useMemo(() => {
    const serviceProducts = [];

    if (price === null) {
      return [];
    }

    for (let i = 1; i <= 5; i++) {
      if (price[`service_${i}_price`] > 0) {
        const langs = products
          .reduce((acc, cur) => {
            return acc + (cur.service === i ? cur.targetLang + ', ' : '');
          }, '')
          .slice(0, -2);

        serviceProducts.push(
          <PriceRow key={i}>
            <div className="start">
              <div className="start-text">{t('common:checkout.orderSummary.services.service' + i)}:</div>
              <div className="start-subtext light">({langs})</div>
            </div>
            <div className="end">
              <div className="end-text">
                <PriceLabel>
                  <span className="value">
                    <Price currency={price.currency} price={price[`service_${i}_price`]} />
                  </span>
                </PriceLabel>
              </div>
            </div>
          </PriceRow>,
        );
      }
    }

    if (price.proofread_price) {
      const langs = products
        .reduce((acc, cur) => {
          return acc + (cur.service === 6 ? cur.targetLang + ', ' : '');
        }, '')
        .slice(0, -2);

      serviceProducts.push(
        <PriceRow key={'proofread'}>
          <div className="start">
            <div className="start-text">{t('common:checkout.orderSummary.services.proofread')}:</div>
            <div className="start-subtext light">({langs})</div>
          </div>
          <div className="end">
            <div className="end-text">
              <PriceLabel>
                <span className="value">
                  <Price currency={price.currency} price={price.proofread_price} />
                </span>
              </PriceLabel>
            </div>
          </div>
        </PriceRow>,
      );
    }

    if (price.dtp_price > 0) {
      serviceProducts.push(
        <PriceRow key="dtp">
          <div className="start">
            <div className="start-text">{t('common:checkout.orderSummary.services.serviceDtp')}:</div>
          </div>
          <div className="end">
            <div className="end-text">
              <PriceLabel>
                <span className="value">
                  <Price currency={price.currency} price={price.dtp_price} />
                </span>
              </PriceLabel>
            </div>
          </div>
        </PriceRow>,
      );
    }

    if (delivery) {
      serviceProducts.push(
        <PriceRow data-cy="order-summary-delivery-price" key="delivery">
          <div className="start">
            <div className="start-text">{t('common:checkout.orderSummary.delivery.deliveryCost')}:</div>
          </div>
          <div className="end">
            <div className="end-text">
              <PriceLabel>
                <span className="value">
                  <Price currency={price.currency} price={delivery.price} />
                </span>
              </PriceLabel>
            </div>
          </div>
        </PriceRow>,
      );
    }

    return serviceProducts;
  }, [delivery, price, products, t]);

  const handleSubmit = () => {
    if (onLastStep && !isLimitedMember) {
      history.push(`/checkout?project=${project.id}`);
    } else {
      nextStep();
    }
  };

  return (
    <Container data-cy="order-summary">
      <div className="header">
        <Icon name="shopping-cart" size={30} /> <h3>{t('common:checkout.orderSummary.orderSummary')}</h3>
      </div>

      <div className="products">
        {loadingPrices ? (
          <div className="loader">
            <Loader data-cy="loader" inline />
          </div>
        ) : null}
        {ProductRows}
      </div>

      <div className="discounts">
        <div className="discounts-header">
          {numOfDiscounts === 0 ? (
            <h4>{t('common:checkout.orderSummary.discounts')}</h4>
          ) : (
            <>
              <h4>{t('common:checkout.orderSummary.discountsApplied')}</h4> ({numOfDiscounts}x)
            </>
          )}

          {numOfDiscounts > 0 ? (
            <h4>
              <Price price={discountSum} currency={price.currency} />
            </h4>
          ) : (
            <h4> </h4>
          )}
        </div>
        <>
          <div className="discount-fields">
            {price.reg_disc_ratio ? (
              <PriceRow>
                <div className="start">
                  <div className="start-text">
                    {t('common:checkout.orderSummary.price.regionalDiscount')} ({price.reg_disc_ratio}%):
                  </div>
                </div>
                <div className="end">
                  <div className="end-text">
                    <PriceLabel>
                      <span className="value">
                        <Price currency={price.currency} price={-price.reg_discount_value} />
                      </span>
                    </PriceLabel>
                  </div>
                </div>
              </PriceRow>
            ) : null}
            {price.spec_disc_ratio ? (
              <PriceRow>
                <div className="start">
                  <div className="start-text">
                    {t('common:checkout.orderSummary.price.specialDiscount')} ({price.spec_disc_ratio}%):
                  </div>
                </div>
                <div className="end">
                  <div className="end-text">
                    <PriceLabel>
                      <span className="value">
                        <Price currency={price.currency} price={-price.special_discount_value} />
                      </span>
                    </PriceLabel>
                  </div>
                </div>
              </PriceRow>
            ) : null}
            {deductBalance ? (
              <Popup
                position="left center"
                content={<BalanceDetails price={price} deduction={deductedBalance} />}
                flowing
                trigger={
                  <PriceRow>
                    <div className="start">
                      <div className="start-text">
                        {t('common:checkout.orderSummary.price.balanceUsed')} (
                        <Price currency={price.currency} price={price.balance_left_after_deduct} />{' '}
                        {t('common:left')}) {<Icon name="question-circle" />}:
                      </div>
                    </div>
                    <div className="end">
                      <div className="end-text">
                        <PriceLabel>
                          <span className="value">
                            <Price currency={price.currency} price={-deductedBalance} />
                          </span>
                        </PriceLabel>
                      </div>
                    </div>
                  </PriceRow>
                }
              />
            ) : null}
            {price.disc_coupon ? (
              <PriceRow>
                <div className="start">
                  <div className="start-text">
                    {t('common:checkout.orderSummary.price.couponDiscount')} ({price.disc_coupon}%):
                  </div>
                </div>
                <div className="end">
                  <div className="end-text">
                    <PriceLabel>
                      <span className="value">
                        <Price currency={price.currency} price={-price.coupon_discount_value} />
                      </span>
                    </PriceLabel>
                  </div>
                </div>
              </PriceRow>
            ) : (
              <CouponInput projectId={project.id} onCoupon={onCoupon} />
            )}
          </div>
        </>
      </div>

      <div className="summary-container">
        <div data-cy="order-summary-subtotal">
          {t('common:checkout.orderSummary.subtotal')}: <Price price={subTotal} currency={price.currency} />
        </div>
        {price.should_pay_vat ? (
          <div data-cy="order-summary-vat">
            {t('common:checkout.orderSummary.vat')}({price.vat_rate}%):{' '}
            <Price price={vatPrice} currency={price.currency} />
          </div>
        ) : null}
        <div className="total" data-cy="order-summary-total">
          {t('common:checkout.orderSummary.orderTotal')}:{' '}
          <Price price={totalPrice} currency={price.currency} />
        </div>
      </div>

      {!isMobile && (
        <SubmitButton
          actiontype="primary"
          fluid
          onClick={handleSubmit}
          loading={loadingPrices}
          disabled={loadingPrices || (isLimitedMember && onLastStep)}
          data-cy={onLastStep ? 'order-summary-checkout-button' : 'order-summary-next-step'}
        >
          {onLastStep ? t('common:checkout.orderSummary.continueToCheckout') : t('common:projects.nextStep')}
        </SubmitButton>
      )}
      {children}
      {project.billing_detail_id === null ? (
        <div className="subtext">{t('common:checkout.orderSummary.continueToCheckoutSubtext')}</div>
      ) : null}
      {isLimitedMember ? (
        <Message type="warning" text={t('common:checkout.cannotOrderProjectDescription')} />
      ) : null}
    </Container>
  );
};

export default OrderSummary;
