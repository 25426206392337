import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import { Popup } from '../../../common/Popup/Popup';
import Table from '../../../common/Table/Table';
import { TEAM_TRANSLATIONS } from '../../../../constants/roles';
import UserCell from '../../../Team/TeamMembers/UserCell';

import { formatAPIDateString } from 'utils/date.utils';

const MembersRow = ({ member }) => {
  const { t } = useTranslation();

  const rolesString = member.teams.reduce((acc, currentTeam, index) => {
    const roleTranslation = t(TEAM_TRANSLATIONS[currentTeam.role_id]);
    if (index > 0) {
      // check if role is already referenced
      if (acc.indexOf(roleTranslation) > -1) {
        return acc;
      }
      return `${acc}, ${roleTranslation}`;
    }
    return `${roleTranslation}`;
  }, '');

  const teamLinks = member.teams.map((team, index) => {
    if (index < member.teams.length - 1) {
      return (
        <React.Fragment key={`team-${team.id}-link`}>
          <Link to={`/team/${team.id}`}>{team.name}</Link>
          ,&nbsp;
        </React.Fragment>
      );
    }
    return (
      <Link key={`team-${team.id}-link`} to={`/team/${team.id}`}>
        {team.name}
      </Link>
    );
  });

  return (
    <Table.Row key={member.id}>
      <Table.Cell>
        <UserCell user={member} />
      </Table.Cell>
      <Table.Cell>{formatAPIDateString({ dateString: member.created_at, showTime: false })}</Table.Cell>
      <Table.Cell>
        {member.company_owner === 1 ? (
          <Popup
            trigger={
              <Label color="blue" as="a">
                {t('common:company.members.owner')}
              </Label>
            }
            style={{ maxWidth: '255px' }}
            dark
          >
            {t('common:company.members.managerInfo')}
          </Popup>
        ) : (
          rolesString
        )}
      </Table.Cell>
      <Table.Cell>{teamLinks}</Table.Cell>
    </Table.Row>
  );
};

export default MembersRow;
