import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Banner, ButtonContainer, Container, ResendText } from './EmailBanner.styles';

import { Icon } from 'components/common/Icon/Icon';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { resendActivationEmail } from 'utils/user.utils';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { setCssVariable } from 'utils/base.utils';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { theme } from 'style/theme';

const EmailBanner = ({ isCollapsed }) => {
  const { email, active } = useSelector((state) => state.userStore.user);
  const [showBanner, setShowBanner] = useState(false);
  const { t } = useTranslation();
  const emailBannerRef = useRef(null);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  useEffect(() => {
    const shouldShowBanner = !active;
    setShowBanner(shouldShowBanner);
  }, [active]);

  useEffect(() => {
    if (!emailBannerRef.current) return;

    setCssVariable('--email-activation-resend-banner-height', emailBannerRef.current.offsetHeight + 'px');

    return () => {
      setCssVariable('--email-activation-resend-banner-height', '0px');
    };
  }, [showBanner]);

  const handleDismiss = (e) => {
    sendUserInteraction('dismissed email not verified banner');
    e.preventDefault();
    setShowBanner(false);
  };

  const handleResend = async (e) => {
    e.preventDefault();

    resendActivationEmail(email, t);

    setShowBanner(false);
  };

  useResizeObserver((entries) => {
    for (let entry of entries) {
      setCssVariable('--email-activation-resend-banner-height', entry.target.offsetHeight + 'px');
    }
  }, emailBannerRef);

  return showBanner ? (
    <Container
      $isCollapsed={isCollapsed}
      isMobile={isMobile}
      animate={isCollapsed ? 'collapsed' : 'expanded'}
    >
      <Banner ref={emailBannerRef}>
        <p>
          {t('common:activateUser.emailVerification.bannerCopy')}{' '}
          {t('common:activateUser.emailVerification.cantFind')}{' '}
          <ResendText onClick={handleResend}>{t('common:activateUser.emailVerification.resend')}</ResendText>
        </p>

        <ButtonContainer>
          <Icon name="times" className="dismiss-icon" onClick={handleDismiss} />
        </ButtonContainer>
      </Banner>
    </Container>
  ) : null;
};

export default EmailBanner;
