import styled from 'styled-components';
import { Segment, Icon } from 'semantic-ui-react';

const StyledCard = styled(Segment)`
  max-width: 800px;
  width: 100%;
  margin: 0 auto !important;
  background: white;
  padding: 2rem !important;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledHeader = styled.h1`
  margin-bottom: ${({ marginBottom }) => (marginBottom === undefined ? '2rem' : marginBottom)} !important;
  text-align: center;
`;

export { StyledCard, StyledIcon, StyledHeader };
