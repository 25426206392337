import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

export const ImgWrapper = styled.div`
  height: 115px;
  width: 115px;
  overflow: hidden;
  flex-shrink: 0; //TODO: Find better solution
  ${(props) => (props.round ? ' border-radius: 50%' : null)};
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

  object-fit: ${(props) => (props.noavatar === 'true' ? 'contain' : 'cover')};
`;
