import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'semantic-ui-react';
import CouponModal from '../CouponModal';
import CompanyEditForm from '../EditForm/CompanyEditForm';

import { checkRoles } from '../../../../utils/user.utils';
import { StyledDropdownItem } from '../../../common.styles';
import { Icon } from '../../Icon/Icon';

import { sendUserInteraction } from 'utils/tagManager.utils';

const TeamDropdown = ({
  billingDetails,
  billingId,
  company_id,
  onAssignClick,
  teamId,
  updateBillingDetails,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const handleCouponClick = (e) => {
    sendUserInteraction('team billing open coupon billing modal');
    e.preventDefault();
    setShowCouponModal(true);
  };

  const handleCouponModalClose = () => {
    setShowCouponModal(false);
  };

  const handleEditClick = (e) => {
    sendUserInteraction('team billing open edit billing modal');
    e.preventDefault();
    setShowEditForm(true);
  };

  const handleEditModalClose = () => {
    setShowEditForm(false);
  };

  return (
    <>
      <Dropdown closeOnBlur closeOnChange closeOnEscape direction="left" icon={<Icon name="ellipsis-v" />}>
        <Dropdown.Menu style={{ marginTop: '.25rem' }}>
          <StyledDropdownItem
            icon={<Icon name="pen" size="small" />}
            onClick={handleEditClick}
            text={t('common:userInfo.billing.labels.editBilling')}
          />
          {checkRoles({ user: user, teamId: teamId, allowedUserRoles: [29, 40] }) && (
            <StyledDropdownItem
              icon={<Icon name="exchange" size="small" />}
              onClick={onAssignClick}
              text={t('common:team.billing.changeHeader')}
            />
          )}
          <StyledDropdownItem
            icon={<Icon name="tag-alt" size="small" />}
            onClick={handleCouponClick}
            text={t('common:userInfo.billing.labels.useCoupon')}
          />
        </Dropdown.Menu>
      </Dropdown>
      {showCouponModal && (
        <CouponModal
          billingId={billingId}
          closeModal={handleCouponModalClose}
          updateBilling={updateBillingDetails}
        />
      )}
      {showEditForm && (
        <CompanyEditForm
          closeModal={handleEditModalClose}
          company_id={company_id}
          initialBillingDetails={billingDetails}
          updateBilling={updateBillingDetails}
        />
      )}
    </>
  );
};

export default TeamDropdown;
