import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon } from '../../common/Icon/Icon';
import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LanguagesWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    align-items: center;
  `}
`;

const StyledLabel = styled.div`
  margin-right: calc(0.14285714em * 2);
  padding: 6px 12px;
  height: min-content;
  font-size: 14px;
  background: #ededed;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    white-space: nowrap;
  `}
`;

const TargetLanguages = styled.div`
  display: flex;
  align-items: center;
  margin-left: calc(0.14285714em * 2);
  overflow: hidden;
`;

const TruncatedLines = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ProjectLanguages = ({ project, t }) => {
  // TODO add translations for languages when refactoring this
  return (
    <FlexWrapper>
      <LanguagesWrapper>
        {!!project.source_language ? (
          <StyledLabel>{project.source_language.name}</StyledLabel>
        ) : (
          <StyledLabel>?</StyledLabel>
        )}
        <Icon name="arrow-right" />
        {project.target_language.length > 0 ? (
          <TargetLanguages>
            <TruncatedLines>
              {project.target_language.map((language) => language.name).join(', ')}
            </TruncatedLines>
          </TargetLanguages>
        ) : (
          <StyledLabel style={{ margin: '0 0 0 0.3em' }}>{project.source_language.name}</StyledLabel>
        )}
      </LanguagesWrapper>
    </FlexWrapper>
  );
};

export default memo(withTranslation('common')(ProjectLanguages));
