import httpClient from './httpclient';

import { getCatJobStatus } from './cat';

export const getAllTranslators = async () => {
  try {
    const { data } = await httpClient.get('/auth/admin/getalltranslators');
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCatJobStats = async (job) => {
  try {
    if (job?.cat_setting) {
      const catData = await getCatJobStatus({
        baseUrl: job.cat_setting.link,
        catId: job.job_id,
        catPass: job.job_pass,
      });

      // Check if it's the new structure
      if (catData.job.chunks[0].stats.equivalent) {
        const { equivalent } = catData.job.chunks[0].stats;
        const totalWords = equivalent.total;

        // Calculate the percentages based on the new structure
        let cat_translated_words_percentage =
          totalWords > 0 ? Math.round((equivalent.translated / totalWords) * 100) : 0;
        let cat_approved_words_percentage =
          totalWords > 0 ? Math.round((equivalent.approved / totalWords) * 100) : 0;

        return {
          ...job,
          catData: {
            ...job.catData, // Keep the structure for compatibility
            cat_approved_words: equivalent.approved,
            cat_approved_words_percentage,
            cat_draft_words: equivalent.draft,
            cat_rejected_words: 0, // Assuming there's no direct mapping for 'rejected' in the new structure
            cat_total_words: totalWords,
            cat_total_payable_words: totalWords,
            cat_translated_words: equivalent.translated,
            cat_translated_words_percentage,
            cat_revise_urls: catData.job.chunks[0].urls.revise_urls,
          },
        };
      } else {
        // Return the original catData object if the old structure is detected
        return {
          ...job,
          catData: {
            cat_approved_words: Math.round(catData.job.chunks[0].stats.APPROVED),
            cat_approved_words_percentage: Math.round(catData.job.chunks[0].stats.APPROVED_PERC),
            cat_draft_words: Math.round(catData.job.chunks[0].stats.DRAFT),
            cat_rejected_words: Math.round(catData.job.chunks[0].stats.REJECTED),
            cat_total_words: catData.job.chunks[0].total_raw_wc,
            cat_total_payable_words: Math.round(catData.job.chunks[0].stats.TOTAL),
            cat_translated_words: Math.round(catData.job.chunks[0].stats.TRANSLATED),
            cat_translated_words_percentage: Math.round(catData.job.chunks[0].stats.TRANSLATED_PERC),
            cat_revise_urls: catData.job.chunks[0].urls.revise_urls,
          },
        };
      }
    } else {
      return job; // Return the job unmodified if no CAT settings are found
    }
  } catch (e) {
    return {
      ...job,
      catData: null, // Handle errors gracefully
    };
  }
};

export const getTranslatorJobs = async ({ page, per_page, status_id, user_id }) => {
  try {
    const { data } = await httpClient.get(`/auth/translator/${user_id}/jobs`, {
      params: {
        page,
        per_page,
        status_id,
      },
    });

    const jobsDataWithStatus = await Promise.all(
      data.data.map(async (job) => {
        try {
          const dataWithStats = await getCatJobStats(job);
          return dataWithStats;
        } catch (e) {
          throw e;
        }
      }),
    );

    return {
      ...data,
      data: jobsDataWithStatus,
    };
  } catch (e) {
    throw e;
  }
};

export const postAcceptJob = async (job_id) => {
  try {
    const { data } = await httpClient.post(`/auth/translator/acceptjob`, {
      job_id,
    });
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data);
    }
  } catch (e) {
    throw e;
  }
};

export const postAcceptRevision = async (job_id) => {
  try {
    const { data } = await httpClient.post(`/auth/translator/acceptrevision`, {
      job_id,
    });
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data);
    }
  } catch (e) {
    throw e;
  }
};

export const postDeclineJob = async ({ job_id, job_type }) => {
  try {
    const { data } = await httpClient.post(`/auth/translator/declinejob`, {
      job_id,
      job_type,
    });
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data);
    }
  } catch (e) {
    throw e;
  }
};

export const postFinishJob = async ({ job_id, translator_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/translator/finishjob`, {
      job_id,
      translator_id,
    });
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data);
    }
  } catch (e) {
    throw e;
  }
};

export const postFinishRevision = async ({ job_id, translator_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/translator/finishrevision`, {
      job_id,
      translator_id,
    });
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data);
    }
  } catch (e) {
    throw e;
  }
};

export const getSingleJob = async (jobID) => {
  try {
    const { data } = await httpClient.get(`/auth/jobs/${jobID}`);

    const dataWithStats = await getCatJobStats(data);
    return dataWithStats;
  } catch (e) {
    throw e;
  }
};
