import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const IconContainer = styled.span`
  display: flex;
  column-gap: 6px;
`;

export const StyledLabel = styled.div`
  padding: 1px 10px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 10px;
  background-color: ${({ active, theme }) => (active ? theme.colors.blue : theme.colors.red)};
  color: #fff;
  text-transform: uppercase;
  width: fit-content;
  letter-spacing: 0.5px;
`;
