import Button from 'components/common/Button/Button';
import LinkBack from 'components/common/LinkBack/LinkBack';
import { fadeInOut } from 'constants/animation';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { Form, Modal } from 'semantic-ui-react';
import { getUser } from 'services/auth';
import { postUserOnboard } from 'services/auth';
import { postCreateCompany } from 'services/company';
import { postUpdateTeam } from 'services/teams';
import { setUser } from 'store/userSlice';
import { sendUserInteraction } from 'utils/tagManager.utils';

import CustomerTypeCard from './CustomerTypeCard';

// Styles
import { OnboardingContainer, OnboardingSubTitle, OnboardingTitle } from '../Onboarding.style';
import './style.scss';
import { vendorOnboardingStartedLocalStorageKey } from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';
import { Checkbox } from 'components/common/Checkbox/Checkbox';

const OnboardingCustomer = () => {
  // State
  const [selectedType, setSelectedType] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const checkType = () => {
    // User selected vendor, show confirm modal
    if (selectedType === 2) {
      setModalOpen(true);
    } else {
      onboardUser();
    }
  };

  const handleCheckbox = () => setTermsOfUse((c) => !c);

  const closeModal = () => setModalOpen(false);

  const onboardUser = async () => {
    sendUserInteraction('click submit customer role');

    try {
      setIsSubmitting(true);

      const userRole = selectedType !== 1 ? 10 : 2; // client or company

      await postUserOnboard({ userRole });

      // Also create company and update team
      if (userRole === 2) {
        const newCompany = await postCreateCompany({
          company_name: companyName,
          comp_discount: 0,
        });

        await postUpdateTeam({
          team_id: newCompany.teams[0].id,
          name: `${companyName} team`,
        });
      }

      // Update reduce user state
      const userData = (await getUser()).data;
      dispatch(setUser({ user: { ...userData } }));

      const instant = Number(query.get('instant'));

      if (instant === 1) {
        history.push('/projects/create');
      }

      // User type === service provider => redirect to vendor onboarding
      if (selectedType === 2) {
        localStorage.setItem(vendorOnboardingStartedLocalStorageKey, 'true');
        history.push('/vendor-onboarding');
      }
    } catch (e) {
      toast.error(t('commont:toasts.customerOnboard.error'));
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const presenceProps = {
    ...fadeInOut.base,
  };

  const typeDescriptions = [
    // Individual
    <motion.div key="type-1" {...presenceProps}>
      <p>{t('common:onboarding.customer.selectIf')}:</p>
      <ul>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.individual.numberOne')},</li>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.individual.numberTwo')},</li>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.individual.numberThree')},</li>
      </ul>
    </motion.div>,

    // Company
    <motion.div key="type-2" {...presenceProps}>
      <p>{t('common:onboarding.customer.selectIf')}:</p>
      <ul>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.company.numberOne')},</li>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.company.numberTwo')},</li>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.company.numberThree')},</li>
      </ul>
      <Form.Field>
        <label>{t('common:onboarding.customer.companyName')}</label>
        <Form.Input
          placeholder={t('common:onboarding.customer.companyName')}
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          data-cy="onboarding-company-name-input"
        ></Form.Input>
      </Form.Field>
    </motion.div>,

    // Service provider
    <motion.div key="type-3" {...presenceProps}>
      <p>{t('common:onboarding.customer.selectIf')}:</p>
      <ul>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.vendor.numberOne')},</li>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.vendor.numberTwo')},</li>
        <li>{t('common:onboarding.customer.selectRoleDescriptions.vendor.numberThree')},</li>
      </ul>
    </motion.div>,
  ];

  const isValid = selectedType !== 1 ? true : companyName.length > 0;

  return (
    <OnboardingContainer className="onboarding-customer">
      <LinkBack
        onClick={() => {
          sendUserInteraction('onboarding customer go back click');
        }}
        text={t('common:back')}
      />

      <OnboardingTitle>{t('common:onboarding.customer.header')}</OnboardingTitle>
      <OnboardingSubTitle className="subtitle">
        {t('common:onboarding.customer.subheader')}
      </OnboardingSubTitle>

      <div className="customer-type-selects">
        <CustomerTypeCard
          text={t('common:onboarding.customer.private')}
          icon={{
            name: 'user',
            size: 40,
          }}
          index={0}
          onSelect={setSelectedType}
          selected={selectedType === 0}
        />

        <CustomerTypeCard
          text={t('common:onboarding.customer.bussines')}
          icon={{
            name: 'briefcase-alt',
            size: 40,
          }}
          index={1}
          onSelect={setSelectedType}
          selected={selectedType === 1}
        />

        <CustomerTypeCard
          text="Vendor"
          icon={{
            name: 'language',
            size: 40,
          }}
          index={2}
          onSelect={setSelectedType}
          selected={selectedType === 2}
        />
      </div>

      <div className="customer-type-descriptions">
        <AnimatePresence exitBeforeEnter>{typeDescriptions[selectedType]}</AnimatePresence>
      </div>

      <Button
        actiontype="primary"
        loading={isSubmitting}
        disabled={isSubmitting || !isValid}
        onClick={checkType}
        data-cy="onboarding-confirm-role-button"
        className="confirm-role-button"
      >
        {t('common:onboarding.customer.selectRole')}
      </Button>

      <Modal open={modalOpen} onClose={closeModal} className="become-vendor-modal-confirm">
        <Modal.Content>
          <h2>{t('common:becomeVendorBanner.modal.title')}</h2>
          <p>{t('common:becomeVendorBanner.modal.description')}</p>
          <div className="terms-and-conditions">
            <Checkbox
              checked={termsOfUse}
              onChange={handleCheckbox}
              label={
                <p>
                  {t('common:becomeVendorBanner.modal.terms.haveRead')}{' '}
                  <a href="https://taia.io/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                    {t('common:becomeVendorBanner.modal.terms.link')}
                  </a>
                </p>
              }
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} actiontype="cancel">
            {t('common:cancel')}
          </Button>
          <Button onClick={onboardUser} actiontype="primary" disabled={!termsOfUse}>
            {t('common:confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    </OnboardingContainer>
  );
};

export default OnboardingCustomer;
