import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Popup } from 'semantic-ui-react';
import { Icon } from '../../../common/Icon/Icon';
import Loader from '../../../common/Loader/Loader';
import Message from '../../../common/Message/Message';
import Button from '../../../common/Button/Button';
import Price from '../../../common/Price/Price';
import CouponInput from '../CouponInput/CouponInput';
import ProformaButton from '../ProformaButton/ProformaButton';
import StripeBalanceNotification from '../StripeBalanceNotification/StripeBalanceNotification';
import {
  BalanceDetailsContainer,
  PriceLabel,
  PriceRow,
  PriceSummaryWrapper,
  Separator,
} from './PriceSummary.style';
import { hasAdminPermissions } from 'utils/user.utils';
import { useSelector } from 'react-redux';
import { formatAPIDateString } from 'utils/date.utils';
import { Link } from 'react-router-dom';
import { isBefore, parseISO } from 'date-fns';
import { formatDate } from 'utils/date.utils';

export const BalanceDetails = ({ price, deduction = 0 }) => {
  const { t } = useTranslation();
  return (
    <BalanceDetailsContainer>
      <div className="balance-item">
        <div className="balance-text">{t('common:projects.projectPrice.currentBalance')}:</div>
        <div className="balance-price">
          <Price currency={price.currency} price={price.user_balance} />
        </div>
      </div>
      <div className="balance-item">
        <div className="balance-text">{t('common:projects.projectPrice.deduction')}:</div>
        <div className="balance-price">
          <Price currency={price.currency} price={deduction} />
        </div>
      </div>
      <div className="balance-item">
        <div className="balance-text">{t('common:projects.projectPrice.balanceLeft')}:</div>
        <div className="balance-price">
          <Price currency={price.currency} price={price.balance_left_after_deduct} />
        </div>
      </div>
    </BalanceDetailsContainer>
  );
};

const PriceSummary = ({
  currentProduct,
  type = 'package',
  paymentMethod,
  paymentType,
  verified,
  price,
  products = [],
  project = {},
  error,
  onSubmit,
  delivery = null,
  deductBalance = false,
  onCoupon = () => {},
  loading,
  stripeBalance,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(null);
  const user = useSelector((state) => state.userStore.user);

  const deductedBalance = useMemo(() => {
    if (price) {
      return price.user_balance - price.balance_left_after_deduct;
    }
    return 0;
  }, [price]);

  const subTotal = useMemo(() => {
    if (price) {
      return type === 'package'
        ? price.subtotal
        : deductBalance
        ? price.net_with_deducted_balance
        : price.net_price;
    }
    return 0;
  }, [type, price, deductBalance]);

  const vatPrice = useMemo(() => {
    if (price) {
      return subTotal * (price.vat_rate / 100);
    }
    return 0;
  }, [subTotal, price]);

  const totalPrice = useMemo(() => {
    if (price) {
      return type === 'package'
        ? price.total
        : deductBalance
        ? price.should_pay_vat
          ? price.gross_with_deducted_balance
          : price.net_with_deducted_balance
        : price.should_pay_vat
        ? price.gross_price
        : price.net_price;
    }
    return 0;
  }, [price, type, deductBalance]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    return await onSubmit();
  };

  const showDateNotification = useMemo(() => {
    if (!!price?.date_of_next_invoice) {
      const paymentDate = new Date(price?.date_of_next_invoice);
      const currentDate = new Date();

      return isBefore(currentDate, paymentDate);
    }
    return false;
  }, [price]);

  const ProductRows = useMemo(() => {
    if (type === 'package') {
      const rows = [];

      if (!!currentProduct) {
        const currentProductRow = (
          <PriceRow key={`current-sub-${currentProduct.id}`}>
            <div className="start">
              <div className="start-text striketrough">{currentProduct.name}</div>
              <div className="start-subtext">
                ({t('common:checkout.orderSummary.currentSubscription')}) <br />
                {currentProduct?.priceDescription && currentProduct.priceDescription}
              </div>
            </div>
            <div className="end">
              <div className="end-text">
                <PriceLabel className="striketrough">
                  {!!currentProduct?.price ? (
                    <>
                      <span className="quantity">{currentProduct.quantity}</span> x&nbsp;
                    </>
                  ) : null}
                  <span className="value">
                    {!!currentProduct?.price ? (
                      <Price
                        currency={currentProduct.currency}
                        price={currentProduct?.swapPrice ? currentProduct.swapPrice : currentProduct.price}
                      />
                    ) : null}
                  </span>
                </PriceLabel>
                {!!currentProduct?.price && <div className="end-subtext">/{currentProduct.interval}</div>}
              </div>
            </div>
          </PriceRow>
        );

        rows.push(currentProductRow);
      }

      return [
        ...rows,
        ...products.map((product) => {
          return (
            <PriceRow key={product.id}>
              <div className="start">
                <div className="start-text">{product.name}:</div>
                <div className="start-subtext">
                  ({!!currentProduct ? t('common:checkout.orderSummary.newSubscription') : product.subname}){' '}
                  <br />
                  {product?.priceDescription && product.priceDescription}
                </div>
              </div>
              <div className="end">
                <div className="end-text">
                  <PriceLabel>
                    <span className="quantity">{product.quantity}</span> x{' '}
                    <span className="value">
                      <Price
                        currency={product.currency}
                        price={product?.swapPrice ? product.swapPrice : product.price}
                      />
                    </span>
                  </PriceLabel>
                </div>
                <div className="end-subtext">/{product.interval}</div>
              </div>
            </PriceRow>
          );
        }),
      ];
    } else {
      const serviceProducts = [];

      if (price === null) {
        return [];
      }

      for (let i = 1; i <= 5; i++) {
        if (price[`service_${i}_price`] > 0) {
          const langs = products
            .reduce((acc, cur) => {
              return acc + (cur.service === i ? cur.targetLang + ', ' : '');
            }, '')
            .slice(0, -2);

          serviceProducts.push(
            <PriceRow key={i}>
              <div className="start">
                <div className="start-text">{t('common:checkout.orderSummary.services.service' + i)}:</div>
                <div className="start-subtext light">({langs})</div>
              </div>
              <div className="end">
                <div className="end-text">
                  <PriceLabel>
                    <span className="value">
                      <Price currency={price.currency} price={price[`service_${i}_price`]} />
                    </span>
                  </PriceLabel>
                </div>
              </div>
            </PriceRow>,
          );
        }
      }

      if (price.proofread_price) {
        const langs = products
          .reduce((acc, cur) => {
            return acc + (cur.service === 6 ? cur.targetLang + ', ' : '');
          }, '')
          .slice(0, -2);

        serviceProducts.push(
          <PriceRow key={'proofread'}>
            <div className="start">
              <div className="start-text">{t('common:checkout.orderSummary.services.proofread')}:</div>
              <div className="start-subtext light">({langs})</div>
            </div>
            <div className="end">
              <div className="end-text">
                <PriceLabel>
                  <span className="value">
                    <Price currency={price.currency} price={price.proofread_price} />
                  </span>
                </PriceLabel>
              </div>
            </div>
          </PriceRow>,
        );
      }

      if (price.dtp_price > 0) {
        serviceProducts.push(
          <PriceRow key="dtp">
            <div className="start">
              <div className="start-text">{t('common:checkout.orderSummary.services.serviceDtp')}:</div>
            </div>
            <div className="end">
              <div className="end-text">
                <PriceLabel>
                  <span className="value">
                    <Price currency={price.currency} price={price.dtp_price} />
                  </span>
                </PriceLabel>
              </div>
            </div>
          </PriceRow>,
        );
      }

      if (delivery) {
        serviceProducts.push(
          <PriceRow key="delivery">
            <div className="start">
              <div className="start-text">
                {t('common:checkout.orderSummary.delivery.deliveryText')}{' '}
                {formatDate({
                  dateObject: parseISO(delivery.time),
                  showTime: false,
                  showYear: false,
                })}
                :
              </div>
            </div>
            <div className="end">
              <div className="end-text">
                <PriceLabel>
                  <span className="value">
                    <Price currency={price.currency} price={delivery.price} />
                  </span>
                </PriceLabel>
              </div>
            </div>
          </PriceRow>,
        );
      }

      return serviceProducts;
    }
  }, [currentProduct, delivery, products, type, price, t]);

  return (
    <PriceSummaryWrapper>
      <div className="price-header">
        <Header as="h1">{t('common:checkout.orderSummary.price.header')}</Header>
      </div>
      {price === null || loading ? (
        <div className="price-loader">
          <Loader inline />
        </div>
      ) : (
        <>
          <div className="products">{ProductRows}</div>
          <Separator />
          {type === 'project' ? (
            <>
              <div className="discounts">
                {price.reg_disc_ratio ? (
                  <PriceRow>
                    <div className="start">
                      <div className="start-text">
                        {t('common:checkout.orderSummary.price.regionalDiscount')} ({price.reg_disc_ratio}%):
                      </div>
                    </div>
                    <div className="end">
                      <div className="end-text">
                        <PriceLabel>
                          <span className="value">
                            <Price currency={price.currency} price={-price.reg_discount_value} />
                          </span>
                        </PriceLabel>
                      </div>
                    </div>
                  </PriceRow>
                ) : null}
                {price.spec_disc_ratio ? (
                  <PriceRow>
                    <div className="start">
                      <div className="start-text">
                        {t('common:checkout.orderSummary.price.specialDiscount')} ({price.spec_disc_ratio}%):
                      </div>
                    </div>
                    <div className="end">
                      <div className="end-text">
                        <PriceLabel>
                          <span className="value">
                            <Price currency={price.currency} price={-price.special_discount_value} />
                          </span>
                        </PriceLabel>
                      </div>
                    </div>
                  </PriceRow>
                ) : null}
                {deductBalance ? (
                  <Popup
                    position="left center"
                    content={<BalanceDetails price={price} deduction={deductedBalance} />}
                    flowing
                    trigger={
                      <PriceRow>
                        <div className="start">
                          <div className="start-text">
                            {t('common:checkout.orderSummary.price.balanceUsed')} (
                            <Price currency={price.currency} price={price.balance_left_after_deduct} />{' '}
                            {t('common:left')}) {<Icon name="question-circle" />}:
                          </div>
                        </div>
                        <div className="end">
                          <div className="end-text">
                            <PriceLabel>
                              <span className="value">
                                <Price currency={price.currency} price={-deductedBalance} />
                              </span>
                            </PriceLabel>
                          </div>
                        </div>
                      </PriceRow>
                    }
                  />
                ) : null}
                {price.disc_coupon ? (
                  <PriceRow>
                    <div className="start">
                      <div className="start-text">
                        {t('common:checkout.orderSummary.price.couponDiscount')} ({price.disc_coupon}%):
                      </div>
                    </div>
                    <div className="end">
                      <div className="end-text">
                        <PriceLabel>
                          <span className="value">
                            <Price currency={price.currency} price={-price.coupon_discount_value} />
                          </span>
                        </PriceLabel>
                      </div>
                    </div>
                  </PriceRow>
                ) : (
                  <CouponInput projectId={project.id} onCoupon={onCoupon} />
                )}
              </div>
              <Separator />
            </>
          ) : null}
          <div className="sub-total">
            <PriceRow>
              <div className="start">
                <div className="start-text">{t('common:checkout.orderSummary.price.subTotal')}:</div>
              </div>
              <div className="end">
                <div className="end-text">
                  <PriceLabel>
                    <span className="value">
                      <Price currency={price.currency} price={subTotal} />
                    </span>
                  </PriceLabel>
                </div>
              </div>
            </PriceRow>
            {(type === 'package' ? price.tax_included : price.should_pay_vat) ? (
              <PriceRow>
                <div className="start">
                  <div className="start-text">
                    {t('common:checkout.orderSummary.price.tax')} (
                    {type === 'package' ? price.tax_rate : price.vat_rate}%):
                  </div>
                </div>
                <div className="end">
                  <div className="end-text">
                    <PriceLabel>
                      <span className="value">
                        <Price currency={price.currency} price={type === 'package' ? price.tax : vatPrice} />
                      </span>
                    </PriceLabel>
                  </div>
                </div>
              </PriceRow>
            ) : null}
          </div>
          <Separator />
          {totalPrice >= 0 ? (
            <div className="final-total">
              <PriceRow className="large">
                <div className="start">
                  <div className="start-text">{t('common:checkout.orderSummary.price.total')}:</div>
                </div>
                <div className="end">
                  <div className="end-text">
                    <PriceLabel>
                      <span className="value">
                        <Price currency={{ ...price.currency }} price={totalPrice} />
                      </span>
                    </PriceLabel>
                  </div>
                  {deductBalance ? (
                    <div className="end-subtext light">
                      {t('common:checkout.orderSummary.price.afterDeduction')}
                    </div>
                  ) : null}
                  {type === 'package' && !currentProduct ? (
                    <div className="end-subtext">/{products[0].interval}</div>
                  ) : null}
                </div>
              </PriceRow>
            </div>
          ) : (
            <Message text={t('common:checkout.orderSummary.creditGiven')} type="info" />
          )}
          {showDateNotification && (
            <Message
              text={`${t('common:checkout.orderSummary.chargeDateText')} ${formatAPIDateString({
                dateString: price?.date_of_next_invoice,
                dateStringFormat: 'yyyy-MM-dd',
                showTime: false,
                showYear: true,
              })}.`}
              type="info"
            />
          )}
          <StripeBalanceNotification
            balanceLeft={stripeBalance?.balanceLeft}
            cardCharge={stripeBalance?.cardCharge}
            currency={{ ...price.currency }}
            deduction={stripeBalance?.deduction}
          />
          <div className="confirm-button">
            {paymentType === 'card' ? (
              <Button
                actiontype="primary"
                loading={isSubmitting}
                disabled={isSubmitting || !paymentMethod}
                onClick={handleSubmit}
                fluid
                data-cy="confirm-subscription"
              >
                {type === 'package'
                  ? !!currentProduct
                    ? t('common:checkout.orderSummary.price.confirmNewSubscription')
                    : t('common:checkout.orderSummary.price.confirmSubscription')
                  : t('common:checkout.orderSummary.price.orderProject')}
              </Button>
            ) : (
              <>
                <ProformaButton
                  verified={verified || hasAdminPermissions(user.role)}
                  project={project}
                  pricesData={price}
                ></ProformaButton>
                {verified || hasAdminPermissions(user.role) ? (
                  <>
                    <Separator />
                    <div className="verified-user">
                      {hasAdminPermissions(user.role)
                        ? t('common:checkout.orderSummary.price.admin')
                        : verified
                        ? t('common:checkout.orderSummary.price.verified')
                        : null}
                      <Button
                        className="confirm-advance"
                        actiontype="primary"
                        loading={isSubmitting}
                        disabled={isSubmitting || error === 'delivery-expired'}
                        fluid
                        onClick={handleSubmit}
                      >
                        {t('common:checkout.orderSummary.price.confirmAdvance')}
                      </Button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
          {!paymentMethod && (
            <Message text={t('common:checkout.orderSummary.price.missingPayment')} type="warning" />
          )}
        </>
      )}
      {error === 'general-error' ? (
        <Message text={t('common:checkout.orderSummary.price.error')} type="error" />
      ) : null}
      {error === 'delivery-expired' ? (
        <Message
          text={
            <>
              {t('common:checkout.orderSummary.price.deliveryError')}{' '}
              <Link to={`/project/${project.id}`}>{t('common:checkout.orderSummary.price.here')}</Link>
            </>
          }
          type="error"
        />
      ) : null}
    </PriceSummaryWrapper>
  );
};

export default PriceSummary;
