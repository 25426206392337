import styled from 'styled-components';
import { MainContainer } from 'components/common.styles';

export const NewProjectMainContainer = styled(MainContainer)`
  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: 0;
    height: calc(
      (var(--vh, 1vh) * 100) - 
      var(--sidebar-header-height) - 
      var(--mobile-steps-controls-height) -
      var(--email-activation-resend-banner-height)
    );
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  `}
`;

export const StyledCard = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 630px;
  border: 1px solid #e1e1e1;
  background: white;
  transition: height 0.5s ease-out;
  font-family: 'DM Sans', sans-serif !important;

  button {
    font-family: 'DM Sans', sans-serif !important;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin: initial;
    flex-grow: 1;
    width: auto;
    min-height: min-content;
  `}
`;

export const InnerContainer = styled.div`
  margin: 30px 39px 45px;

  .select-dropdown {
    width: 50%;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin: ${theme.paddings.mainContainer.mobile.x};
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .select-dropdown,
    .multiple {
      width: 100%;
    }

    input:not([type="radio"]) {
      height: 52px;
    }
  `}
`;

export const CardHeader = styled.div`
  max-width: 630px;
  margin: 0 auto 36px auto;
  font-family: 'DM Sans', sans-serif;
  font-size: 30px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: 12px ${theme.paddings.mainContainer.mobile.x}; 
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    width: initial;
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.sidebar - 1};
    background-color: ${theme.colors.greyLight};
  `}
`;

export const StyledHeader = styled.h4`
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 12px;
  font-size: 16px;
`;

export const StepFooter = styled.div`
  margin-top: 2rem;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  column-gap: 8px;
  button {
    margin: 0 !important;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .button {
      flex-grow: 1;
    }
  `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
