import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.8em 1em;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : theme.colors.black)};
  border-radius: 3px;
  outline: none !important;
  resize: ${({ resizable }) => (resizable ? 'vertical' : 'none')};
  height: 100%;

  &:focus {
    border-color: ${({ theme, error }) => (error ? theme.colors.red : theme.colors.blue)};
  }
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
`;
