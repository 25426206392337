import React from 'react';
import styled, { css } from 'styled-components';
import { Header, Dropdown } from 'semantic-ui-react';
import Table from './common/Table/Table';
import Pagination from './common/Pagination/Pagination';
import { Link } from 'react-router-dom';
import Button from './common/Button/Button';
import Card from './common/Card/Card';

export const MainContainer = styled.main`
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
  width: 100%;

  ${({ theme, hasMobilePadding = true }) =>
    theme.mediaMaxWidth.mobile`
      padding: ${hasMobilePadding ? theme.paddings.mainContainer.mobile.full : 0};
    `}
`;

export const StyledButton = styled(({ marginBottom, ...rest }) => <Button {...rest} />)`
  width: 100%;
  transition: all 0.2 ease-in-out !important;
  margin-top: 0 !important;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '2rem'} !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 20px 0 !important;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: normal !important;
`;

export const StyledLink = styled(Link)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue};
`;

export const StyledHeader = styled.h1`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '3rem'} !important;
  text-align: center;
  width: 100%;
  line-height: 28px;
  font-size: 36px;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: normal;
`;

export const StyledSpan = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
`;

export const StyledFormError = styled.div`
  color: ${({ theme }) => theme.colors.red};
  width: 100%;
  text-align: left;
  line-height: 1.2em;
  padding-bottom: 0.2em;
  margin-top: 0.2em;
`;

export const StyledFormWarning = styled.div`
  color: ${({ theme }) => theme.colors.yellow};
  width: 100%;
  text-align: left;
  line-height: 1.2em;
  padding-bottom: 0.2em;
  margin-top: 0.2em;
`;

export const StyledHeading = styled(Header)`
  margin-bottom: 0.25em !important;
`;

export const StyledLabel = styled.label`
  opacity: 0.7;
`;

export const TableToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  .left {
    margin-right: auto !important;
  }
`;

export const StyledPagination = styled(Pagination)`
  margin-right: auto !important;
`;

export const StyledHeaderCell = styled(Table.HeaderCell)`
  color: ${({ theme }) => theme.colors.blue} !important;
  cursor: pointer !important;
  user-select: none;

  div {
    display: flex;
    column-gap: 3px;
  }
`;

export const SortHeaderCell = ({ children, ...rest }) => {
  return (
    <StyledHeaderCell {...rest}>
      <div>{children}</div>
    </StyledHeaderCell>
  );
};

export const OverflowScroll = styled.div`
  overflow-y: auto;
  height: ${(props) => props.height || '100%'};
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex !important;
`;

export const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
`;

export const ContentContainer = styled.div`
  width: 90%;
  max-width: 560px;
  margin-bottom: 100px;
  margin-top: 2rem;
  color: black;
  text-align: left;

  p {
    font-size: 1.1em;
    a {
      color: ${({ theme }) => theme.colors.blue};
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 450px;
  }
`;

export const StyledTableCard = (props) => (
  <div style={{ marginTop: '14px' }}>
    <Card {...props} />
  </div>
);

// needs width to work
export const truncateStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
