import styled from 'styled-components';
import Button from '../../../common/Button/Button';

export const StyledButton = styled(Button)`
  margin-bottom: 20px !important;
`;

export const VoucherAmount = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 1rem;
  .field {
    align-self: flex-end !important;
    margin-bottom: 1em !important;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
