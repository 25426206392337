import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SaveChanges = (props) => {
  const { t } = useTranslation();

  return (
    <div className="section-description save-changes">
      <div className="left" />
      <div className="right">
        <Button big labelPosition="left" actiontype="primary" {...props}>
          <Icon name="save" />
          {t('common:admin.singleVendor.saveChangesBtn')}
        </Button>
      </div>
    </div>
  );
};

export default SaveChanges;
