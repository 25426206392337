import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCompanyDispatch } from '../Company.state';

import { Modal } from 'semantic-ui-react';
import { InputContainer, StyledInput } from './AddTeam.styles';

import { postTeams } from '../../../services/teams';
import Button from '../../common/Button/Button';
import { Icon } from '../../common/Icon/Icon';

const AddTeam = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useCompanyDispatch();

  const [teamName, setTeamName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNameChange = (e) => {
    setTeamName(e.target.value);
  };

  const isSubmitDisabled = () => {
    return isSubmitting || teamName === '';
  };

  const submitTeam = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const newTeam = await postTeams(id, teamName, null);

      toast.success(t('common:toasts.addTeam.success'));

      dispatch({
        type: 'ADD_TEAM',
        payload: {
          ...newTeam,
          billing_detail_id: null,
          isEditing: false,
          isSubmitting: false,
        },
      });
      setTeamName('');
    } catch (e) {
      toast.error(t('common:toasts.addTeam.error'));
    } finally {
      setIsSubmitting(false);
      setIsOpen(false);
    }
  };

  return (
    <div>
      <Button actiontype="primary" icon labelPosition="left" onClick={() => setIsOpen(true)}>
        <Icon name="plus-square" />
        {t('common:team.addTeam')}
      </Button>
      <Modal onClose={() => setIsOpen(false)} open={isOpen} size="small">
        <Modal.Header>{t('common:team.addTeam')}</Modal.Header>
        <Modal.Content>
          <InputContainer>
            <StyledInput
              label={t('common:team.labels.teamName')}
              name="name"
              onChange={handleNameChange}
              type="text"
              value={teamName}
              autoFocus
            />
          </InputContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button actiontype="cancel" disabled={isSubmitting} onClick={() => setIsOpen(false)}>
            {t('common:cancel')}
          </Button>
          <Button
            actiontype="create"
            disabled={isSubmitDisabled()}
            loading={isSubmitting}
            onClick={submitTeam}
          >
            {t('common:confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default AddTeam;
