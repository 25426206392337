import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Message from 'components/common/Message/Message';

import { localisePrice } from 'utils/string.utils';

const StripeBalanceNotification = ({ balanceLeft, cardCharge, currency, deduction }) => {
  const { i18n, t } = useTranslation();

  const notificationText = useMemo(() => {
    const localisedDeduction = !!deduction
      ? localisePrice({ currencyData: currency, number: deduction, language: i18n.language })
      : '';

    const localisedCreditLeft = !!balanceLeft
      ? localisePrice({ currencyData: currency, number: balanceLeft, language: i18n.language })
      : '';

    const localisedCardCharge = !!cardCharge
      ? localisePrice({ currencyData: currency, number: cardCharge, language: i18n.language })
      : '';

    const deductionString = `${localisedDeduction} ${t(
      'common:checkout.orderSummary.price.credit.paidUsingCredit',
    )}`;

    const creditLeftString = !!balanceLeft
      ? `${t('common:checkout.orderSummary.price.credit.creditLeft')} ${localisedCreditLeft} ${t(
          'common:checkout.orderSummary.price.credit.left',
        )}`
      : t('common:checkout.orderSummary.price.credit.noCredit');

    const cardChargeString = !!cardCharge
      ? `${t('common:checkout.orderSummary.price.credit.additional')} ${localisedCardCharge} ${t(
          'common:checkout.orderSummary.price.credit.creditCardCharged',
        )}`
      : t('common:checkout.orderSummary.price.credit.creditCardNotCharged');

    return `${deductionString} ${creditLeftString} ${cardChargeString}`;
  }, [balanceLeft, cardCharge, currency, deduction, i18n, t]);

  return !!deduction && deduction > 0 ? (
    <Message
      header={t('common:checkout.orderSummary.price.credit.heading')}
      text={notificationText}
      type="info"
    />
  ) : null;
};

export default StripeBalanceNotification;
