import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../common/Table/Table';
import Message from '../../common/Message/Message';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import ProjectLanguages from '../ProjectsCards/NewProjectLanguages';
import Price from '../../common/Price/Price';
import { ProjectNameContainer, Progress, ControlsContainer, StyledIcon } from './ProjectsTable.styles';
import CardControls from '../ProjectsCards/CardControls/CardControls';
import { STATUS_COLORS } from '../../common/StatusLabel/StatusLabel.constants';
import { useHistory } from 'react-router';

import { formatAPIDateString } from 'utils/date.utils';

const ProjectTableRow = ({ project, reloadProjects = () => {}, ...rest }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const redirectToProject = (e, project) => {
    history.push(`project/${project.id}`);
  };

  return (
    <Table.Row style={{ height: '90px' }} onClick={(e) => redirectToProject(e, project)} {...rest}>
      <Table.Cell>
        <ProjectNameContainer>
          <h2>{project.name}</h2>
          {!!project.team && <span>{project.team.name}</span>}
        </ProjectNameContainer>
      </Table.Cell>
      <Table.Cell>
        <StatusLabel project={project} />
        {project.progress !== null && (
          <Progress color={STATUS_COLORS[project.status_id][2]} percent={project.progress} />
        )}
      </Table.Cell>
      <Table.Cell>
        <ProjectLanguages project={project} />
      </Table.Cell>
      <Table.Cell textAlign="center">{project.payable_words}</Table.Cell>
      <Table.Cell>
        {project.status_id >= 3 &&
          project.status_id !== 10 &&
          project.status_id !== 20 &&
          project.status_id !== 22 &&
          project.status_id !== 11 &&
          project.price && (
            <>
              <Price
                currency={{ ...project.currency, ...project.conversion_rate }}
                price={project.price}
                style={{ fontWeight: 'bold', color: 'black' }}
              />
              &nbsp;
              {parseInt(project.status_id) < 3 && t('common:projects.withVat')}
            </>
          )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {project.status_id > 2 &&
        project.status_id !== 10 &&
        project.status_id !== 20 &&
        project.status_id !== 22 &&
        project.status_id !== 11 &&
        project.delivery_time &&
        !project.diy ? (
          <div>{formatAPIDateString({ dateString: project.delivery_time })}</div>
        ) : null}
      </Table.Cell>
      <Table.Cell>
        <ControlsContainer>
          <CardControls
            project={project}
            reloadProjects={reloadProjects}
            trigger={<StyledIcon name="ellipsis-v" />}
          />
        </ControlsContainer>
      </Table.Cell>
    </Table.Row>
  );
};

const ProjectsTable = ({ projects, reloadProjects = () => {} }) => {
  const { t } = useTranslation();

  return projects.length > 0 ? (
    <Table width="100%" selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>{t('common:projects.userProject.project')}</Table.HeaderCell>
          <Table.HeaderCell width={1}>{t('common:projects.userProject.status')}</Table.HeaderCell>
          <Table.HeaderCell width={6}>{t('common:projects.userProject.languages')}</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            {t('common:projects.userProject.numOfWords')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>{t('common:projects.projectPrice.price')}</Table.HeaderCell>
          <Table.HeaderCell width={3} textAlign="right">
            {t('common:projects.userProject.deliveryDate')}
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {projects.map((project) => (
          <ProjectTableRow project={project} key={project.id} reloadProjects={reloadProjects} />
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Message text={t('common:projects.noProjectsFound')} type="info" />
  );
};

export default ProjectsTable;
