import { ZINDEX } from 'constants/zIndex';
import { css } from 'styled-components';

// variables
import { BREAKPOINTS } from 'constants/breakpoints';
import { PADDINGS } from 'constants/paddings';

export const taiaColors = {
  blue: '#1CAAC5',
  yellow: '#FFB102',
  red: '#EC5A58',
  green: '#32BF73',
  greenLight: '#DFF7E5',
  redLighter: '#ffe8e8',
  blueLight: '#C9E8F0',
  blueLighter: '#f1f9fb',
  yellowLight: '#FFEBBF',
  redLight: '#FAD5D5',
  black: '#000000',
  grey: '#C4C4C4',
  greyBright: '#EEEEEE',
  greyLight: '#F6F6F6',
  greyDark: '#666666',
  greyWhite: '#f0f0f0',
  white: '#FFFFFF',
};

// Sizes
export const sizes = {
  sidebar: {
    expanded: '245px',
    collapsed: '55px',
  },
};

// z-index
export const zIndex = ZINDEX;

// Paddings
export const paddings = PADDINGS;

// Drop shadows
export const getTaiaDropshadows = () => {
  const dropshadows = {
    soft: '10px 10px 40px rgba(113,113,113,0.2)',
    softTopLeft: '-20px -4px 40px rgba(113,113,113,0.2)',
    softDown: '0 8px 10px 0 rgba(0,0,0,.16)',
    softUp: '0 -2px 16px 0 rgba(0, 0, 0, 0.1)',
    lightDown: '4px 4px 18px -4px rgba(0, 0, 0, 0.25)',
  };

  for (const color in taiaColors) {
    dropshadows[`${color}Hard`] = `7px 7px 0px ${taiaColors[color]}`;
    dropshadows[`${color}HardHover`] = `5px 5px 0px ${taiaColors[color]}`;
  }

  return { ...dropshadows };
};

// Input
export const inputStyle = css`
  color: rgba(0, 0, 0, 0.87);
  padding: 0.67857143em 1em;
  background: #fff;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: color 0.1s ease, border-color 0.1s ease;
`;

// Breakpoints
export const breakpoints = BREAKPOINTS;

// Media queries utils
export const mediaMaxWidth = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const mediaBetween = (breakpointOne, breakpointTwo, style) => {
  return css`
    @media (min-width: ${breakpoints[breakpointOne]}px) and (max-width: ${breakpoints[breakpointTwo]}px) {
      ${style};
    }
  `;
};

export const mediaMinWidth = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const theme = {
  colors: taiaColors,
  paddings,
  shadows: getTaiaDropshadows(),
  inputStyle: inputStyle,
  breakpoints,
  mediaMaxWidth,
  mediaMinWidth,
  zIndex,
  sizes,
  mediaBetween,
};
