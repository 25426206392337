import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Message from '../common/Message/Message';
import { getProducts } from '../../services/subscriptions';
import Button from '../common/Button/Button';
import LoadingPane from '../common/LoadingPane/LoadingPane';
import { Toolbar } from './AdminSubscriptionPackages.style';
import PackagesTable from './PackagesTable';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { MainContainer } from 'components/common.styles';

const AdminSubscriptionPackages = () => {
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        setIsLoading(true);
        const pack = await getProducts();
        setPackages(pack);
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();
  }, []);
  return (
    <MainContainer>
      <Toolbar>
        <Button
          actiontype="primary"
          onClick={() => {
            sendUserInteraction('admin subscription package create click');
            history.push('/admin/packages/create');
          }}
        >
          {t('common:admin.packages.createPackage')}
        </Button>
      </Toolbar>
      {error && <Message text={t('common:admin.packages.error')} type="error" />}
      {isLoading ? <LoadingPane /> : <PackagesTable packages={packages}></PackagesTable>}
    </MainContainer>
  );
};

export default AdminSubscriptionPackages;
