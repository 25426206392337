import React, { useEffect, useState } from 'react';

import { VENDOR_STATUS } from 'constants/vendors';
import { classnames } from 'utils/base.utils';

import { Icon } from 'components/common/Icon/Icon';
import Placeholder from 'components/common/Placeholder/Placeholder';
import { Popup } from 'components/common/Popup/Popup';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const mapStatusToIcon = {
  1: 'file-plus-alt', //'New'
  2: 'file-search-alt', //'Under review'
  3: 'file-check-alt', //'Active'
  4: 'file-lock-alt', //'Temporarily unavailable'
  5: 'file-times-alt', //'Canceled'
  6: 'file-question-alt', //'Unknown'
};

const mapStatusToColor = {
  1: 'has-text-green-primary', //'New'
  2: 'has-text-blue-primary', //'Under review'
  3: 'has-text-green-primary', //'Active'
  4: 'has-text-yellow-primary', //'Temporarily unavailable'
  5: 'has-text-red-primary', //'Canceled'
  6: 'has-text-grey-primary', //'Unknown'
};

const AdminVendorHeader = ({ vendorData, loading }) => {
  const { name, email, status } = vendorData;

  const [showPendingRatesIcon, setShowPendingRatesIcon] = useState(false);

  // Hooks
  const history = useHistory();
  const { t } = useTranslation();

  const handleRatesPendingRedirect = () =>
    history.push(`/admin/vendor/${vendorData.id}?tab=language-combinations`);

  /**
   * On mount and on vendorData change, check prices
   * If any of the rates status is pending ~ null, show the pending rates icon
   */
  useEffect(() => {
    if (!vendorData?.prices) return;

    for (const price of vendorData.prices) {
      if (price.rate_status === null) {
        setShowPendingRatesIcon(true);
        return;
      }
    }

    setShowPendingRatesIcon(false);
  }, [vendorData, setShowPendingRatesIcon]);

  /**
   * If status is null or undefined:
   *  set statusID, which is used for displaying the icon, to 6 ~ unknown
   */
  const statusID = status ?? 6;
  const statusClassnames = classnames('status-icon', mapStatusToColor[statusID]);

  return (
    <header>
      <h1>
        {/* Vendor name */}
        <div className="name">{loading ? <Placeholder /> : name}</div>

        {/* status icon */}
        {statusID && (
          <span className={statusClassnames}>
            <Popup
              dark
              trigger={<Icon name={mapStatusToIcon[statusID]} size={32} />}
              content={VENDOR_STATUS[statusID]}
            />
          </span>
        )}

        {/* Pending rates icon */}
        {showPendingRatesIcon && (
          <span className="rates-pending-icon">
            <Popup
              dark
              trigger={<Icon onClick={handleRatesPendingRedirect} name="credit-card-search" size={32} />}
              content={t('common:admin.singleVendor.header.pendingRatePopupContent')}
            />
          </span>
        )}
      </h1>

      {/* Vendor email */}
      <p>
        {t('common:admin.singleVendor.header.emailPrefix')}: {loading ? <Placeholder /> : email}
      </p>
    </header>
  );
};

export default AdminVendorHeader;
