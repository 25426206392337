import styled from 'styled-components';

export const ContentContainer = styled.div`
  overflow: auto;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.greyLight};
  position: relative;
  /* display: grid; */

  ${({ theme }) => theme.mediaMaxWidth.HD`
    padding-left: ${theme.sizes.sidebar.collapsed};
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding-left: 0;
  `}
`;

export const FullscreenContainer = styled.div`
  background: ${({ theme }) => theme.colors.greyLight};
  flex: 1;
  overflow-y: auto;
  position: relative;

  .fullscreen-container {
    display: grid;
    grid-template-rows: 75px auto;
    padding-top: 20px;

    max-width: 1400px;
    padding-left: 50px;
    padding-right: 50px;
    margin-right: auto;
    margin-left: auto;
    .content {
      max-width: 1252px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
    .navbar-img {
      height: 65px;
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .fullscreen-container {
      padding: ${theme.paddings.mainContainer.mobile.full};
    }
  `}
`;
