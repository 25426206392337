import { handleUpdateVendor } from 'components/AdminVendor/AdminVendor.utils';
import VendorSettingsForm from 'components/common/Vendor/Forms/VendorSettingsForm/VendorSettingsForm';
import { TabContainer } from 'components/UserProfile/UserProfile.styles';
import { isEqual } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import SaveChanges from '../SaveChanges';

const VendorSettings = ({ vendorData, setVendorData }) => {
  // Refs
  const initialFormData = useRef({
    country_id: vendorData.country_id ?? '',
    native_lang_id: vendorData.native_lang_id ?? '',
    timezone: vendorData.timezone ?? '',
    description: vendorData.description ?? '',
  });

  // Local State
  const [submitting, setSubmitting] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [formData, setFormData] = useState(initialFormData.current);

  // Functions
  const handleSaveChanges = async () => {
    try {
      setSubmitting(true);

      const response = await handleUpdateVendor({
        vendor: vendorData,
        formInitial: initialFormData.current,
        formCurrent: formData,
      });

      setVendorData(response.data);

      toast.success(`Successfully saved changes for ${vendorData.name}`);
    } catch (error) {
      console.error(error);
      toast.error('There was a problem saving your changes');
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Fires on form data change
   */
  const handleFormChange = (_, data) => setFormData(data);

  /**
   * When vendorData updates:
   * - sync the local ref
   * - sync the local state for curr form data
   */
  useEffect(() => {
    initialFormData.current = {
      country_id: vendorData.country_id ?? '',
      native_lang_id: vendorData.native_lang_id ?? '',
      timezone: vendorData.timezone ?? '',
      description: vendorData.description ?? '',
    };

    setFormData(initialFormData.current);
  }, [vendorData, setFormData]);

  // Check if there are any differences
  useEffect(() => {
    const formChanged = !isEqual(formData, initialFormData.current);

    // Check if native lang and country have values
    const valid = formData.native_lang_id !== null && formData.country_id !== null;

    setCanSubmit(formChanged && valid);
  }, [formData, setCanSubmit]);

  return (
    <TabContainer>
      <VendorSettingsForm initialFormData={initialFormData.current} onChange={handleFormChange} />
      <SaveChanges onClick={handleSaveChanges} loading={submitting} disabled={submitting || !canSubmit} />
    </TabContainer>
  );
};

export default VendorSettings;
