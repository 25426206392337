const { default: styled } = require('styled-components');

export const ProjectsLayout = styled.div`
  position: relative;
  display: grid;
  flex: 1;
`;

export const CompanyProjectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  margin-top: 20px;
  gap: 26px;
  margin-right: 68px;
`;

export const FilterButtonContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
`;
