import styled from 'styled-components';
import React from 'react';
import { Icon } from '../Icon/Icon';

export const StyledContainer = styled.div`
  position: relative;

  &.error {
    .dropdown-header {
      border-color: ${({ theme }) => theme.colors.red}!important;
      box-shadow: inset 1px 1px 3px 0 ${({ theme }) => theme.colors.redLight}!important;
    }
  }
  &.fluid {
    width: 100%;
  }

  &.disabled {
    .dropdown-header {
      background-image: none;
      background: ${({ theme }) => theme.colors.greyLight};
      color: ${({ theme }) => theme.colors.grey};
    }
  }

  &.label {
    .dropdown-header {
      padding: 22px 14px 12px;
      .search-input {
        font-size: 1em;
        padding: 22px 14px 12px;
      }
      label {
        position: absolute;
        font-size: 11px;
        top: 6px;
        left: 15px;
      }
    }
  }

  &.selected-icon-show {
    .selected-item-icon {
      max-width: 20px;
      height: fit-content;
      display: flex;
      align-items: center;
    }

    .dropdown-header {
      align-items: center;
    }

    &:not(.empty) {
      .dropdown-header .search-input {
        padding-left: calc(1rem + 20px + 0.5rem);
      }
    }
  }

  .dropdown-header {
    display: grid;
    grid-template-columns: auto min-content;
    position: relative;
    min-width: 190px;
    padding: 12px 10px 12px 14px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'DM Sans';
    transition: box-shadow 0.15s ease-out;

    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #dbdbdb;
    border-radius: 3px;
    background-image: linear-gradient(to top, #f6f6f6, #ffffff);
    outline-color: ${({ theme }) => theme.colors.blueLight};

    &:hover {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.075);
    }

    .search-input {
      position: absolute;
      width: calc(100% - 3em);
      padding: 12px 10px 12px 14px;
      margin: 1px;
      border: none;
      outline: none;
      background: transparent !important;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.greyDark};
      font-family: 'DM Sans', sans-serif;

      &::placeholder {
        transition: color 0.07s ease-out;
        color: ${({ theme }) => theme.colors.black};
      }

      &:focus::placeholder {
        color: ${({ theme }) => theme.colors.greyDark};
      }
      &.selected::placeholder {
        color: ${({ theme }) => theme.colors.black}!important;
      }
    }

    .reset {
      margin-left: auto;
      cursor: pointer;
    }

    .arrow {
      transition: transform 0.25s ease;
      margin-left: auto;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0px;
    z-index: 3;

    min-width: 190px;
    max-height: 288px;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
    text-align: left;
    font-size: 14px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow-x: hidden;
    overflow-y: auto;

    transition: opacity, box-shadow, transform;
    will-change: transform, box-shadow, opacity;
    transition-timing-function: ease-out;
    transition-duration: 150ms;
    transform-origin: top center;
    transform: scaleY(0);

    .dropdown-item {
      align-items: center;
      padding: 10px 14px;
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      cursor: pointer;
      display: flex;
      transition: background-color 0.2s ease-out;
      min-height: min-content;

      &.highlighted,
      &:hover {
        background-color: #e3f3f7;
      }

      &.disabled,
      &[data-selected='true'] {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.white};
    }

    .icon {
      margin-right: 0.78571429em;
    }
  }

  &.open {
    .dropdown-header {
      background: white;
      border-bottom-color: transparent;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .arrow {
        transform: rotate(180deg);
      }
    }

    .dropdown-menu {
      transform: scaleY(1);
      border: solid 1px #dbdbdb;
      border-top-color: transparent;
    }
  }

  // Multiple select dropdown
  &.multiple {
    .dropdown-header {
      grid-template-columns: 1fr;
    }

    &.empty .multiple-dropdown-selected {
      padding: 0;
    }

    .multiple-dropdown-selected {
      padding: 18px 0 0;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    &.show-selected-in-menu {
      .dropdown-menu {
        width: fit-content;
        min-width: 300px;

        .multiple-dropdown-selected {
          width: fit-content;
          padding: 1rem;

          > span {
            white-space: nowrap;
          }
        }

        @media (max-width: 600px) {
          width: calc(100vw - 32px);
        }
      }

      &.empty {
        .dropdown-menu {
          .multiple-dropdown-selected {
            display: none;
          }
        }
      }
    }

    &.fit-placeholder {
      .dropdown-header {
        padding: 0;

        input {
          position: relative;
          width: fit-content;
          // Icon size
          padding-right: 40px;
        }

        > .icon {
          position: absolute;
          right: 10px;
          transform: translateY(-50%);
          top: 50%;
        }
      }
    }
  }

  &.upward {
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .dropdown-menu {
      top: auto;
      bottom: calc(100% - 2px);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: none;
      z-index: 2;
      transform-origin: bottom center;
    }
    &.open {
      .dropdown-menu {
        border: solid 1px #dbdbdb;
        border-bottom: none;
      }
    }
  }

  &.small {
    .dropdown-header {
      font-size: 14px;
      padding: 10px 10px 10px 15px;
      .search-input {
        padding: 12px 10px 12px 15px;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        font-size: 14px;
        padding: 10px 15px;
      }
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .dropdown-menu {
      transform: scale(0);
    }

    .dropdown-header {
      min-height: 52px;

      .search-input {
        // Dropdown header height minus its border (52px - 2px)
        height: 50px;
        left: 0;
        top: 0;
      }
    }

    .dropdown-items-wrapper {
      margin-bottom: auto;
      overflow-y: auto;
    }

    &.open {
      .dropdown-header {
        opacity: 0;
      }

      .dropdown-menu {
        background-color: ${theme.colors.white};
        margin: 16px;
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100vw - 32px);
        height: calc((var(--vh, 1vh) * 100) - 32px);
        z-index: ${theme.zIndex.bgOverlay + 1};
        transform-origin: initial;
        transform: scale(1);
        max-height: initial;
        max-width: initial;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .dropdown-item:first-child {
          border-top: 1px solid #e1e1e1;
        }

        > .dropdown-item:last-child {
          margin-bottom: auto;
        }

        .dropdown-item {
          padding: 20px;

          &[data-selected="true"] {
            background-color: #e3f3f7;
          }

          &.highlighted {
            background-color: ${theme.colors.white};
            border-left: none;
          }
        }
      }
    }
  `}
`;

export const MultipleDropdownMenuHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-areas:
    'input close'
    'selected selected';
  grid-template-columns: 1fr max-content;
  z-index: 1;
  box-shadow: ${({ theme }) => theme.shadows.lightDown};

  .search-input {
    padding: 20px;
    width: 100%;
    grid-area: input;
    border: none;

    &:focus-visible {
      outline: none;
    }
  }

  .close-btn {
    grid-area: close;
    background-color: transparent;
    border: none;

    .icon {
      margin-right: 0;
      color: ${({ theme }) => theme.colors.grey};
    }
  }

  .multiple-dropdown-selected {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    grid-area: selected;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
    display: none;
  `}
`;

export const MultipleDropdownMenuFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.softUp};
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  z-index: 10;
  position: relative;
  min-height: min-content;

  .button {
    margin-right: 0;
    flex-grow: 1;
    white-space: nowrap;

    &.big {
      font-size: 14px;
    }
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
    display: none;
  `}
`;

const StyledLabel = styled.span`
  display: flex;
  align-items: center;
  column-gap: 7px;
  height: 32px;
  padding: 0 10px;
  background-color: #ededed;
  color: ${({ theme }) => theme.colors.greyDark};
  font-size: 12px;

  .close-icon {
    cursor: pointer;
    svg {
      color: #c4c4c4;
      &:hover {
        color: ${({ theme }) => theme.colors.greyDark};
      }
    }
  }
  will-change: height, transform;
  animation-duration: ${({ animate }) => (animate ? '0.15s' : '0')};
  animation-timing-function: ease-out;
  animation-name: appear;

  @keyframes appear {
    from {
      transform: scale(0);
      height: 0;
    }

    to {
      height: 32px;
      transform: scale(1);
    }
  }
`;

export const Label = ({ onRemove, text, icon, preventDeleting = false, ...rest }) => {
  return (
    <StyledLabel {...rest}>
      {icon && icon}
      {text && text}
      {!preventDeleting && onRemove && <Icon name="times" className="close-icon" onClick={onRemove} />}
    </StyledLabel>
  );
};
