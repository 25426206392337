import React from 'react';
import { useTranslation } from 'react-i18next';

import StepsTimeline from 'components/ServiceProvider/VendorOnboarding/StepsTimeline/StepsTimeline';
import Button from 'components/common/Button/Button';
import { Link } from 'react-router-dom';

import { stepsLabels } from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';

// Styles
import './style.scss';
import { userStartedVendorOnboarding, validateStepsInLocalStorage } from '../VendorOnboarding.utils';

// Constants
const steps = [stepsLabels[0], stepsLabels[1], stepsLabels[2]];

/**
 * Renders a resume vendor onboarding steps
 * Gets current onboarding steps from localStorage
 */
const ResumeOnboarding = () => {
  // Hooks
  const { t } = useTranslation();

  const validSteps = validateStepsInLocalStorage();

  // Preprocess steps context
  const stepsContext = {
    completed: validSteps,
    index: validSteps.length === 0 ? 0 : validSteps[validSteps.length - 1] + 1,
  };

  const userStartedOnboarding = userStartedVendorOnboarding();

  const subtitle = t(
    `common:serviceProvider.jobs.welcomeScreen.${
      userStartedOnboarding ? 'continue' : 'start'
    }OnboardingTitle`,
  );

  const btnText = userStartedOnboarding ? 'Continue onboarding' : 'Start onboarding';
  const stepLink = `/vendor-onboarding${stepsContext.index !== 0 ? `?step=${stepsContext.index + 1}` : ''}`;

  return (
    <div className="resume-vendor-onboarding">
      <h2 className="heading-3">{subtitle}</h2>

      <StepsTimeline steps={steps} stepsContext={stepsContext} iconsSize={25} />

      <Link to={stepLink}>
        <Button actiontype="primary">{btnText}</Button>
      </Link>
    </div>
  );
};

export default ResumeOnboarding;
