import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '../../../common/Accordion/Accordion';
import AdminUserDropdown from '../../../common/BillingDetails/AdminUserDropdown/AdminUserDropdown';
import BillingCard from '../../../common/BillingDetails/BillingCard/BillingCard';
import { Loader, Message } from 'semantic-ui-react';
import { Container, LoaderContainer } from './BillingInfo.styles';

import { getBillingDetails, getDefaultPaymentMethod } from '../../../../services/billing';
import CardPaymentMethod from 'components/Checkout/Payment/CardPaymentMethod/CardPaymentMethod';

const BillingInfo = ({ billingId }) => {
  const { t } = useTranslation();
  const [billingDetails, setBillingDetails] = useState({});
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    const fetchBillingDetails = async () => {
      try {
        setError(false);
        setIsLoading(true);

        const newBillingDetails = await getBillingDetails(billingId);
        const paymentMethod = await getDefaultPaymentMethod({ billing_id: billingId });
        setBillingDetails(newBillingDetails);
        setPaymentMethod(paymentMethod);
      } catch (e) {
        setError(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingDetails();
  }, [billingId]);

  const fetchBillingDetails = async () => {
    try {
      setError(false);
      setIsLoading(true);

      const newBillingDetails = await getBillingDetails(billingId);
      setBillingDetails(newBillingDetails);
    } catch (e) {
      setError(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Accordion title={t('common:catapult.subscription.paymentMethods.billingDetails')} watch={billingDetails}>
      {isLoading ? (
        <LoaderContainer>
          <Loader active size="large" />
        </LoaderContainer>
      ) : error ? (
        <Message negative>
          <Message.Content>{t('common:catapult.subscription.loadError')}</Message.Content>
        </Message>
      ) : (
        <Container>
          <BillingCard
            billingDetails={billingDetails}
            controlsComponent={() =>
              AdminUserDropdown({
                billingDetails: billingDetails,
                onSubmit: fetchBillingDetails,
              })
            }
            isCollapsedOnMount={false}
            isCollapsible={false}
          />
          {paymentMethod ? (
            <div>
              <CardPaymentMethod paymentMethod={paymentMethod} selected={true} />
            </div>
          ) : null}
        </Container>
      )}
    </Accordion>
  );
};

export default BillingInfo;
