import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
