import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { BtnContainer, IconContainer } from './Icon.styles';
import { toPascalCase } from '../../../utils/string.utils';
import { Loader } from 'semantic-ui-react';
import { classnames } from 'utils/base.utils';

const SIZES = {
  small: 15,
  large: 27,
  big: 35,
  huge: 75,
  massive: 135,
};

/**
 * @param {{
 *   size: "small" | "large" | "big" | "huge" | "massive"
 *   inElement: boolean
 *   link: boolean
 *   circular: boolean
 *   style: React.CSSProperties
 * }} props
 */
export const Icon = React.forwardRef(
  (
    {
      name,
      size,
      style,
      inline = false,
      inElement = false,
      className = '',
      link = false,
      loading = false,
      ...rest
    },
    ref,
  ) => {
    const iconName = `Uil${toPascalCase(name)}`;
    const Unicon = Unicons[iconName];
    const iconSize = (typeof size === 'number' ? size : SIZES[size]) || 20;
    const iconProps = { ...rest, size: iconSize, link, inline, ref };
    const uniconProps = { color: rest.color, size: iconSize };
    const classes = classnames('icon', className, { link, loading });

    const icon = loading ? <Loader active inline size="tiny" /> : <Unicon {...uniconProps} />;

    if (Unicon) {
      if (inElement)
        return (
          <BtnContainer className={classes} style={style}>
            <IconContainer {...iconProps}>{icon}</IconContainer>
          </BtnContainer>
        );
      return (
        <IconContainer {...iconProps} className={classes} style={style}>
          {icon}
        </IconContainer>
      );
    }
    return null;
  },
);
