import { Input } from 'components/common/Input/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import CountriesDropdown from '../../../common/BillingDetails/EditForm/CountriesDropdown';

const PaymentForm = ({ payment, onPaymentChange = () => {} }) => {
  const { t } = useTranslation();

  const onChange = (e) => {
    const { name, value } = e.target;
    onPaymentChange({ name, value });
  };

  const onCountryChange = ({ value }) => {
    onPaymentChange({ name: 'countryId', value: value });
  };

  return (
    <Form style={{ maxWidth: '500px' }}>
      <Form.Field>
        <Input
          label={t('common:checkout.payment.form.name')}
          placeholder={t('common:checkout.payment.form.name')}
          name="name"
          value={payment.name}
          onChange={onChange}
          data-cy="checkout-name-input"
        />
      </Form.Field>
      <Form.Field>
        <Input
          label={t('common:checkout.payment.form.address')}
          placeholder={t('common:checkout.payment.form.address')}
          name="address"
          value={payment.address}
          onChange={onChange}
          data-cy="checkout-address-line-1-input"
        />
      </Form.Field>
      <Form.Field>
        <Input
          label={t('common:checkout.payment.form.address2')}
          placeholder={t('common:checkout.payment.form.address2')}
          name="address2"
          value={payment.address2}
          onChange={onChange}
          data-cy="checkout-address-line-2-input"
        />
      </Form.Field>

      <Form.Group>
        <Form.Field>
          <Input
            label={t('common:checkout.payment.form.city')}
            placeholder={t('common:checkout.payment.form.city')}
            name="city"
            value={payment.city}
            onChange={onChange}
            data-cy="checkout-city-input"
          />
        </Form.Field>
        <Form.Field>
          <Input
            label={t('common:checkout.payment.form.postalCode')}
            placeholder={t('common:checkout.payment.form.postalCode')}
            name="postalCode"
            value={payment.postalCode}
            onChange={onChange}
            data-cy="checkout-postal-code-input"
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Input
          label={t('common:checkout.payment.form.state')}
          placeholder={t('common:checkout.payment.form.state')}
          name="state"
          value={payment.state}
          onChange={onChange}
          data-cy="checkout-state-input"
        />
      </Form.Field>
      <Form.Group>
        <Form.Field>
          <label>{t('common:checkout.payment.form.country')}</label>
          <CountriesDropdown
            country_id={payment.countryId}
            updateProperty={onCountryChange}
          ></CountriesDropdown>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default PaymentForm;
