import React, { memo } from 'react';
import { isEmpty } from 'lodash';

import { ImgWrapper, StyledImage } from './Avatar.styles';

import NoAvatar from '../../../assets/avatar_placeholder.png';

const Avatar = ({ url, style }) => {
  const imgUrl = isEmpty(url) ? NoAvatar : url;

  return (
    <ImgWrapper round={!isEmpty(url)} style={style}>
      <StyledImage src={imgUrl} alt="avatar" noavatar={isEmpty(url) ? 'true' : 'false'} />
    </ImgWrapper>
  );
};
export default memo(Avatar);
