import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CreateProject from './CreateProject/CreateProject';

import { getCheckTool, postNewMatecatProject } from '../../services/cat';
import { postCreateProject, postTempProject, sendChatNotification } from '../../services/project';

import { getTranslationMemories } from '../../services/users';
import { useSelector } from 'react-redux';
import { checkAllowedRole } from '../../utils/user.utils';
import { getCompanyTranslationMemories } from '../../services/company';
import { Observable } from 'rxjs';
import { Provider, reducer, initialState } from './NewProject.reducer';
import { sendAnalysisFailed } from '../../utils/tagManager.utils';
import { hasFreeSubscriptions } from 'utils/catapult.utils';
import { uniq } from 'lodash';
import { NewProjectMainContainer } from './NewProject.styles';

// container component that submits new project data for analysis
const NewProject = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const user = useSelector((state) => state.userStore.user);
  const categories = useSelector((state) => state.classifiersStore.categories);
  const subscriptions = useSelector((state) => state.subscriptionsStore.subscriptions);

  const [subscriber, setSubscriber] = useState(null);

  const analysisStep = useRef('START');

  useEffect(() => {
    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
  }, [subscriber]);

  const disableTM = useMemo(() => {
    return hasFreeSubscriptions(subscriptions);
  }, [subscriptions]);

  const history = useHistory();
  const { t } = useTranslation();

  const redirectToProject = (projectId) => {
    history.push(`/project/${projectId}?step=analyzing`);
  };

  const handleError = async ({ error, errorMessage }) => {
    dispatch({ type: 'RESET_SUBMIT' });
    dispatch({ type: 'SET_ERROR', payload: error });
    dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
    errorMessage = `[${analysisStep.current}] ${errorMessage}`;
    sendAnalysisFailed();
    await sendChatNotification({ errorMessage });
  };

  const setTempProgress = (progress) => {
    dispatch({ type: 'SET_TEMP_PROGRESS', payload: progress });
  };

  const handleSubmit = async (e) => {
    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    const {
      files,
      projectCategory,
      projectName,
      projectType,
      sourceLanguage,
      targetLanguages: _targetLanguages,
      teamId,
    } = state.projectData;

    const targetLanguages = projectType === 'proofread' ? [sourceLanguage] : uniq(_targetLanguages);

    let cat_setting = null;
    try {
      analysisStep.current = 'START';
      const uniqueId = `id${new Date().getTime()}`;
      // default projectName to the first file name if not set
      const name = projectName.trim() !== '' ? projectName : files[0].name;

      analysisStep.current = 'FETCHING_CAT_URL';
      // check which cat tool to use
      cat_setting = await getCheckTool();

      const category = categories.find((category) => category.id === projectCategory);

      let private_tm_key = null;
      if (checkAllowedRole({ userRole: user.role, allowedRoles: [40, 42] })) {
        analysisStep.current = 'FETCHING_COMPANY_TM_KEY';
        const companyId = user.role === 40 ? user.company_owner_id : user.teams[0].company_id;
        private_tm_key = await getCompanyTranslationMemories({
          category_id: category.id,
          id: companyId,
        });
      } else {
        analysisStep.current = 'FETCHING_TM_KEY';
        private_tm_key = await getTranslationMemories({ category_id: category.id, id: user.id });
      }

      analysisStep.current = 'POSTING_TEMP_PROJECT';
      // first upload to BE endpoint for temporary projects
      try {
        await postTempProject(
          {
            category: category.code,
            files,
            sourceLanguage,
            targetLanguage: targetLanguages,
            uniqueId,
          },
          setTempProgress,
        );
      } catch (e) {
        if (e?.response.status === 422) {
          await handleError({
            error: `${t('common:projects.createProject.submitErrors.largeVolume1')} ${
              e.response.data.limit
            } ${t('common:projects.createProject.submitErrors.largeVolume2')}`,
            errorMessage: `Project blocked before analysis due to large number of words: \`${
              e.message
            }\` URL: ${e?.request.responseURL} \nCAT: \`${
              cat_setting?.link
            }\` \n Request response: \n\`\`\`${JSON.stringify(e.response.data)}\`\`\``,
          });
        } else {
          await handleError({
            error: t(
              'common:projects.createProject.submitErrors.unableToAnalyze',
              "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
            ),
            errorMessage: `Newproject.js general error: ${e.message}`,
          });
        }
        return;
      } finally {
        setTempProgress(0);
      }

      const newMatecateProjectObservable = new Observable(async (subscriber) => {
        try {
          analysisStep.current = 'POSTING_CAT_PROJECT';
          const matecatResponse = await postNewMatecatProject({
            baseUrl: cat_setting.link,
            category: category.code,
            files,
            projectName: name,
            sourceLanguage,
            targetLanguage: targetLanguages,
            private_tm_key: private_tm_key,
            get_public_matches: 'false', // Always disable public TM matches
            tms_engine: disableTM ? 0 : 1, // disable TM for free catapult users
          });

          if (matecatResponse.status === 'FAIL') {
            analysisStep.current = 'CAT_STATUS_FAIL';
            subscriber.error({
              error: t(
                'common:projects.createProject.submitErrors.unableToAnalyze',
                "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
              ),
              errorMessage: `Project name: "${name}", new matecat project response status FAIL.`,
            });
            return;
          }

          const { id_project, project_pass } = matecatResponse;

          analysisStep.current = 'CREATING_PROJECT';
          const createProjectResponse = await postCreateProject({
            catId: id_project,
            catPass: project_pass,
            files,
            projectName: name,
            sourceLanguage,
            targetLanguages,
            team_id: teamId,
            taiaOCR: category.code,
            uniqueId: uniqueId,
            categoryId: category.id,
            private_tm_key: private_tm_key,
            new_keys: matecatResponse.new_keys,
          });

          if (createProjectResponse.success) {
            analysisStep.current = 'PROJECT_SUCCESS';
            const projectId = createProjectResponse.data;

            subscriber.next(projectId);
            subscriber.complete();
          } else {
            analysisStep.current = 'UNABLE_TO_ANALYZE';
            subscriber.error({
              error: t(
                'common:projects.createProject.submitErrors.unableToAnalyze',
                "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
              ),
              errorMessage: `Project name: "${name}", API create project did not return success.`,
            });
            return;
          }
        } catch (e) {
          if (e?.response) {
            subscriber.error({
              error: t(
                'common:projects.createProject.submitErrors.unableToAnalyze',
                "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
              ),
              errorMessage: `Newproject.js general error: \`${e.message}\` URL: ${
                e?.request.responseURL
              } \nCAT: \`${cat_setting?.link}\` \n Request response: \n\`\`\`${JSON.stringify(
                e.response.data,
              )}\`\`\``,
            });
          } else {
            subscriber.error({
              error: t(
                'common:projects.createProject.submitErrors.unableToAnalyze',
                "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
              ),
              errorMessage: `Newproject.js general error: ${e.message}`,
            });
          }
        }
      });

      const retriedCreate = newMatecateProjectObservable;

      const sub = retriedCreate.subscribe({
        next: (projectId) => {
          redirectToProject(projectId);
        },
        error: async (e) => {
          await handleError(e);
        },
      });

      setSubscriber(sub);
    } catch (e) {
      if (!window.navigator.onLine) {
        await handleError({
          error: t('common:errors.noConnection'),
          errorMessage: `Newproject.js Browser offline: \`${e.message}.\` \nCAT: \`${cat_setting?.link}\` \n Request response: \n\`\`\`${e.response.data}\`\`\``,
        });
      } else if (e?.response) {
        await handleError({
          error: t(
            'common:projects.createProject.submitErrors.unableToAnalyze',
            "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
          ),
          errorMessage: `Newproject.js request error: \`${e.message}\` URL: ${
            e?.request.responseURL
          } \nCAT: \`${cat_setting?.link}\` \n Request response: \n\`\`\`${JSON.stringify(
            e.response.data,
          )}\`\`\``,
        });
      } else {
        await handleError({
          error: t(
            'common:projects.createProject.submitErrors.unableToAnalyze',
            "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
          ),
          errorMessage: `Newproject.js general error: ${e.message}`,
        });
      }
    }
  };
  return (
    <Provider state={state} dispatch={dispatch}>
      <NewProjectMainContainer data-cy="create-new-project-form">
        <CreateProject submitProject={handleSubmit} />
      </NewProjectMainContainer>
    </Provider>
  );
};

export default NewProject;
