import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FlavourImage = styled.img`
  width: 90%;
  max-width: 450px;
`;

export const FlavorText = styled.p`
  max-width: 60ch;
  line-height: 1.4em;
  text-align: left;
`;

export const Heading = styled.h1`
  font-size: 1.6em;
  margin-bottom: 0.5em;
`;

export const TextContainer = styled.div`
  max-width: 60ch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
`;
