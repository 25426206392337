import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Observable, of } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { getCatProjectCreationStatus, getCatProjectStatus } from 'services/cat';

// Helper functions
const getStatusText = (summary, t, isCatapult) => {
  return summary.preparing
    ? t('common:projects.preparing')
    : summary.IN_QUEUE_BEFORE
    ? t('common:projects.inQueue')
    : summary.analysisFinished
    ? t('common:projects.analysisFinished')
    : summary.STATUS === 'BUSY' || summary.STATUS === 'NOT_READY_FOR_ANALYSIS' || summary.STATUS === 'NEW'
    ? t('common:projects.startingAnalysis')
    : isCatapult
    ? t('common:projects.analyzingProject') + `${summary.SEGMENTS_ANALYZED}/${summary.TOTAL_SEGMENTS}`
    : t('common:projects.analyzingProject') + `${summary.segments_analyzed}/${summary.total_segments}`;
};

const setSummaryHelper = (analyzeResponse, setSummary, isCatapult) => {
  if (isCatapult && analyzeResponse.hasOwnProperty('data')) {
    setSummary(analyzeResponse.data.summary);
  } else if (!isCatapult && analyzeResponse.hasOwnProperty('summary')) {
    setSummary(analyzeResponse.summary);
  }
};

const createUpdatePayload = (analyzeResponse, project, isCatapult) => {
  if (isCatapult) {
    const summary = analyzeResponse.data?.summary;
    if (!summary) {
      console.error('Invalid response structure:', analyzeResponse);
      throw new Error('Invalid response structure: Missing data.summary');
    }
    const { TOTAL_RAW_WC, TOTAL_PAYABLE, REPETITIONS } = summary;
    const expectedProperties = ['TOTAL_RAW_WC', 'TOTAL_PAYABLE'];
    const missingProperties = expectedProperties.filter((prop) => summary[prop] === undefined);

    if (missingProperties.length > 0) {
      console.error('Missing expected properties in data.summary:', { summary, missingProperties });
      throw new Error(
        `Invalid response structure: Missing expected properties in data.summary: ${missingProperties.join(
          ', ',
        )}`,
      );
    }

    return {
      id: project.id,
      jobs: analyzeResponse,
      total_word: TOTAL_RAW_WC,
      total_payable: TOTAL_PAYABLE,
      repeated_words: REPETITIONS ?? 0,
      regional_disc: project.reg_disc_ratio,
      special_disc: project.spec_disc_ratio,
    };
  } else {
    const summary = analyzeResponse.summary;
    if (!summary) {
      console.error('Invalid response structure:', analyzeResponse);
      throw new Error('Invalid response structure: Missing summary');
    }
    const { total_raw, total_equivalent } = summary;
    const expectedProperties = ['total_raw', 'total_equivalent'];
    const missingProperties = expectedProperties.filter((prop) => summary[prop] === undefined);

    if (missingProperties.length > 0) {
      console.error('Missing expected properties in summary:', { summary, missingProperties });
      throw new Error(
        `Invalid response structure: Missing expected properties in summary: ${missingProperties.join(', ')}`,
      );
    }

    return {
      id: project.id,
      jobs: analyzeResponse.jobs,
      total_word: total_raw,
      total_payable: total_equivalent,
      repeated_words: 0, // Setting repetitions to 0
      regional_disc: project.reg_disc_ratio,
      special_disc: project.spec_disc_ratio,
    };
  }
};

// Main hook
const waitPromise = (time) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });

const getIsCatapult = (baseUrl) => {
  return baseUrl === 'https://catapult.taia.io' || baseUrl === 'https://catapult.taia-translations.com';
};

export const useAnalysisStatus = () => {
  const [summary, setSummary] = useState({ preparing: true });
  const [status, setStatus] = useState('IDLE');
  const [error, setError] = useState(null);
  const [subscribe, setSubscribe] = useState(null);
  const analysisStep = useRef('START');
  const baseUrlRef = useRef(''); // Use useRef to store baseUrl

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    };
  }, [subscribe]);

  const statusText = useMemo(() => {
    const isCatapult = getIsCatapult(baseUrlRef.current);
    return getStatusText(summary, t, isCatapult);
  }, [summary, t]);

  const checkProjectStatus = async (project) => {
    const checkProject = new Observable(async (subscriber) => {
      const { cat_id, cat_pass, cat_setting } = project;
      baseUrlRef.current = cat_setting.link; // Ensure baseUrl is derived from cat_setting
      analysisStep.current = 'START';
      try {
        analysisStep.current = 'INITIAL_CREATION_STATUS';
        let creationStatus = await getCatProjectCreationStatus({
          baseUrl: baseUrlRef.current,
          catId: cat_id,
          catPass: cat_pass,
        });

        // Waiting in queue
        while (creationStatus.status === 202) {
          analysisStep.current = 'WAITING_IN_QUEUE';
          setStatus('WAITING');
          setSummary({ IN_QUEUE_BEFORE: true });
          await waitPromise(5000);
          creationStatus = await getCatProjectCreationStatus({
            baseUrl: baseUrlRef.current,
            catId: cat_id,
            catPass: cat_pass,
          });
        }

        analysisStep.current = 'INITIAL_PROJECT_STATUS';
        let analyzeResponse = await getCatProjectStatus({
          baseUrl: baseUrlRef.current,
          catId: cat_id,
          catPass: cat_pass,
        });

        let STATUS = analyzeResponse.status;
        const isCatapult = getIsCatapult(baseUrlRef.current);
        // Project is being ANALYZED
        while (STATUS === 'ANALYZING' && !subscriber.isStopped) {
          analysisStep.current = 'PROJECT_ANALYZING';
          setStatus('ANALYZING');
          setSummaryHelper(analyzeResponse, setSummary, isCatapult);
          await waitPromise(5000);
          analyzeResponse = await getCatProjectStatus({
            baseUrl: baseUrlRef.current,
            catId: cat_id,
            catPass: cat_pass,
          });
          STATUS = analyzeResponse.status;
        }
        analysisStep.current = 'ANALYSIS_FINISHED';
        setSummary({ analysisFinished: true });

        if (STATUS === 'DONE') {
          analysisStep.current = 'STATUS_DONE';
          setStatus('DONE');
          const updatePayload = createUpdatePayload(analyzeResponse, project, isCatapult);
          subscriber.next(updatePayload);
          subscriber.complete();
        } else if (STATUS === 'NO_SEGMENTS_FOUND') {
          analysisStep.current = 'STATUS_NO_SEGMENT';
          setStatus('ERROR');
          subscriber.error({
            notificationText: `Project ID: ${project.id}, CAT response status NO_SEGMENTS_FOUND.`,
            errorText: t(
              'common:projects.createProject.submitErrors.noSegment',
              "We couldn't identify any text segments in your files. Please re-upload your text in a different format.",
            ),
            archive: true,
          });
        } else if (STATUS === 'FAIL') {
          analysisStep.current = 'STATUS_FAIL';
          setStatus('ERROR');
          subscriber.error({
            notificationText: `Project ID: ${project.id}, CAT response status FAIL.`,
            errorText: t(
              'common:projects.createProject.submitErrors.unableToAnalyze',
              "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
            ),
            archive: false,
          });
        } else if (STATUS === 'ANALYZING') {
          analysisStep.current = 'STATUS_ANALYZING2';
          setStatus('ERROR');
          subscriber.error({
            notificationText: `UpdateWrapper.js: (project: ${project.id}) Cat response status ANALYZING`,
            errorText: t(
              'common:projects.createProject.submitErrors.analyzing',
              'Project is still being processed. Please try again at later time.',
            ),
            archive: false,
          });
        } else {
          analysisStep.current = 'UNHANDLED_STATUS';
          setStatus('ERROR');
          subscriber.error({
            notificationText: `UpdateWrapper.js: (project: ${project.id}) Unhandled cat status: ${STATUS}`,
            errorText: t(
              'common:projects.createProject.submitErrors.unableToAnalyze',
              "Due to special file properties we weren't able to analyze your document(s). We will send you the offer within a few hours to your email. Thank you for your understanding.",
            ),
            archive: true,
          });
        }
      } catch (e) {
        setStatus('ERROR');
        const errorResponse = e?.response?.data?.errors?.[0];
        const { cat_setting } = project;
        if (errorResponse) {
          if (errorResponse.code === 403) {
            subscriber.error({
              notificationText: `Project ID: ${project.id}, Request access to analyze project: ${
                e.message
              } \n CAT-response: \`\`\`${JSON.stringify(e.response.data)}\`\`\``,
              errorText: t('common:errors.largeSegments'),
              archive: true,
            });
          } else if (errorResponse.code === -1) {
            subscriber.error({
              notificationText: `Project ID: ${project.id}, Files seem to contain no text: ${
                e.message
              }\n CAT-response: \`\`\`${JSON.stringify(e.response.data)}\`\`\``,
              errorText: t('common:errors.noText'),
              archive: true,
            });
          } else {
            subscriber.error({
              notificationText: `Project ID: ${project.id}, \`${e.message}\` URL: ${
                e?.request?.responseURL
              } \nCAT: \`${cat_setting?.link}\` \n Request response: \n\`\`\`${JSON.stringify(
                e.response.data,
              )}\`\`\``,
              errorText: t('common:errors.genericError'),
              archive: true,
            });
          }
        } else {
          subscriber.error({
            notificationText: `Project ID: ${project.id}, general error: ${e.message}`,
            errorText: t('common:errors.genericError'),
            archive: false,
          });
        }
      }
    });

    return new Promise((resolve, reject) => {
      if (project.status_id === 1) {
        const retriedCheck = checkProject.pipe(
          retryWhen((errors) => {
            return errors.pipe(
              delay(5000),
              mergeMap((error, i) => {
                console.warn(i + ' retrying...');
                if (i > 15) {
                  throw error;
                }
                return of(error);
              }),
            );
          }),
        );

        const sub = retriedCheck.subscribe({
          next: async (updatePayload) => {
            resolve({ ...updatePayload, setup: true });
          },
          error: async (e) => {
            setError(e.errorText);
            e.notificationText = `[${analysisStep.current}] ${e.notificationText}`;
            reject(e);
          },
        });
        setSubscribe(sub);
      } else {
        resolve({ setup: false });
      }
    });
  };

  return { checkProjectStatus, summary, status, error, statusText };
};
