import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Header, Icon, Popup } from 'semantic-ui-react';
import { Checkbox } from '../../common/Checkbox/Checkbox';

export const PlanForm = ({ planName, setPlanName, wordLimit, setWordLimit, privatePlan, setPrivatePlan }) => {
  const { t } = useTranslation();
  return (
    <Form>
      <Form.Input
        label={t('common:admin.createPackage.planInfo.planName')}
        placeholder={t('common:admin.createPackage.planInfo.planName')}
        onChange={(e) => {
          setPlanName(e.target.value);
        }}
        value={planName}
      ></Form.Input>
      <Form.Input
        label={t('common:admin.createPackage.planInfo.wordLimit')}
        placeholder={t('common:admin.createPackage.planInfo.wordLimit')}
        type="number"
        min="0"
        onChange={(e) => {
          setWordLimit(Number(e.target.value));
        }}
        value={wordLimit}
      ></Form.Input>
      <Form.Field>
        <Checkbox
          checked={privatePlan}
          toggle
          label={t('common:admin.createPackage.planInfo.privateToggle')}
          onClick={() => {
            setPrivatePlan(!privatePlan);
          }}
        />
        <Popup
          trigger={<Icon link name="question" />}
          content={t('common:admin.createPackage.planInfo.privateDescription')}
        />
      </Form.Field>
    </Form>
  );
};

const PlanInformation = ({ onChange = () => {} }) => {
  const [planName, setPlanName] = useState('');
  const [wordLimit, setWordLimit] = useState('');
  const [privatePlan, setPrivatePlan] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    onChange({
      planName,
      wordLimit,
      private: privatePlan,
    });
  }, [planName, wordLimit, privatePlan, onChange]);

  return (
    <>
      <Header as="h2">{t('common:admin.createPackage.planInfo.header')}</Header>
      <PlanForm
        planName={planName}
        setPlanName={setPlanName}
        wordLimit={wordLimit}
        setWordLimit={setWordLimit}
        privatePlan={privatePlan}
        setPrivatePlan={setPrivatePlan}
      />
    </>
  );
};

export default PlanInformation;
