import { SelectGroup } from 'components/common/SelectGroup/SelectGroup';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Styles
import './style.scss';

const ShowProjectsFilter = ({
  updateSearchParams,
  teamOptions,
  userID,
  selected = false,
  selectedTeams = [],
}) => {
  // Hooks
  const { t } = useTranslation();

  // Functions

  /**
   * Fires on select group option change
   * Updates search params
   *
   * @param {boolean} showMyProjects
   */
  const handleSelectGroupChange = (showMyProjects = true) => {
    updateSearchParams({
      user_id: showMyProjects ? userID : null,
      team_id: selectedTeams.length > 0 ? selectedTeams : teamOptions.map(({ id }) => id),
    });
  };

  return (
    <div className="show-projects-filter">
      <p>{t('common:projects.filters.showFilter.title')}:</p>
      <SelectGroup border flat fluid size="small" selected={selected} onChange={handleSelectGroupChange}>
        <SelectGroup.Option value={true}>
          {t('common:projects.filters.showFilter.myProjects')}
        </SelectGroup.Option>
        <SelectGroup.Option value={false}>
          {t('common:projects.filters.showFilter.allTeamsProjects')}
        </SelectGroup.Option>
      </SelectGroup>
    </div>
  );
};

// Prop types
ShowProjectsFilter.propTypes = {
  updateSearchParams: PropTypes.func.isRequired,
  teamOptions: PropTypes.array.isRequired,
  userID: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  selectedTeams: PropTypes.array,
};

export default ShowProjectsFilter;
