import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Input } from 'semantic-ui-react';
import { postProjectDiscountCoupon } from '../../../../services/project';
import Button from '../../../common/Button/Button';
import { CouponInputWrapper } from './CouponInput.style';

const CouponInput = ({ projectId, onCoupon = () => {} }) => {
  const [coupon, setCoupon] = useState('');
  const [fieldActive, setFieldActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const checkCoupon = async (e) => {
    try {
      setIsLoading(true);
      setError(false);
      await postProjectDiscountCoupon({ discoutCode: coupon, projectId: projectId });
      onCoupon();
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onCouponChange = (e) => {
    setError(false);
    setCoupon(e.target.value);
  };

  return (
    <CouponInputWrapper>
      {fieldActive ? (
        <div data-cy="coupon-field" className="coupon-field">
          <>
            <Input
              placeholder={t('common:checkout.orderSummary.price.coupon')}
              value={coupon}
              onChange={onCouponChange}
            />
            <Button
              onClick={checkCoupon}
              color={error ? 'red' : null}
              loading={isLoading}
              disabled={isLoading}
            >
              {error ? <Icon name="times" fitted /> : t('common:checkout.orderSummary.price.apply')}
            </Button>
          </>
        </div>
      ) : (
        <div data-cy="coupon-link" className="coupon-link" onClick={(e) => setFieldActive(true)}>
          {t('common:checkout.orderSummary.price.haveCoupon')}
        </div>
      )}
    </CouponInputWrapper>
  );
};

export default CouponInput;
