import { StepControls } from 'components/AdminAddVendor/AddSteps';
import { useAddVendorContext } from 'components/AdminAddVendor/AddVendorProvider';
import { Icon } from 'components/common/Icon/Icon';
import Table from 'components/common/Table/Table';
import { LanguagePairRow } from 'components/ServiceProvider/VendorOnboarding/Steps/LanguagePairs/LanguagePairs';
import { VENDOR_TASK_TYPE_PROOFREAD_ONLY } from 'constants/vendors';
import React from 'react';
import { useTranslation } from 'react-i18next';

const singleRowDefaultState = {
  source_lang_id: null,
  target_lang_id: null,
  category_id: [],
  task_type_id: null,
  rate: 0,
};

const LanguagePairs = () => {
  const { useVendorData } = useAddVendorContext();
  const [rowsData, setRowsData] = useVendorData('combination');
  const { t } = useTranslation();

  const handleRowDataChange = ({ rowIndex, input }) => {
    const newRowsState = [...rowsData];

    // Find row data
    const currData = newRowsState[rowIndex];

    // Either update row or push new row
    if (currData) {
      currData[input.name] = input.name === 'rate' ? Number(input.value) : input.value;
    } else {
      newRowsState.push({
        [input.name]: input.value,
      });
    }

    // Check if task is proofread
    if (currData.task_type_id === VENDOR_TASK_TYPE_PROOFREAD_ONLY) {
      currData.target_lang_id = currData.source_lang_id;
    } else if (currData.target_lang_id === currData.source_lang_id) {
      currData.target_lang_id = null;
    }

    setRowsData(newRowsState);
  };

  const addNewRow = () => setRowsData([...rowsData, { ...singleRowDefaultState }]);

  const deleteRow = (idx) => {
    const newRows = [...rowsData];

    newRows.splice(idx, 1);

    setRowsData(newRows);
  };

  return (
    <section className="steps-content form-step">
      <header>
        <h2>{t('common:admin.vendorOnboarding.stepThree.title', 'Language pairs')}</h2>
        <p>
          {
            (t('common:admin.vendorOnboarding.stepThree.description'),
            'Add the language pairs in which these vendors provide service')
          }
        </p>
      </header>
      <div className="form vendor-onboarding-step-three-form">
        <div className="vendor-onboarding-step-two-form">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.srcLang')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.targetLang')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.subMatter')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.service')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.rate')}
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rowsData.map((row, idx) => (
                <LanguagePairRow
                  key={idx}
                  rowIndex={idx}
                  onChange={handleRowDataChange}
                  onDelete={deleteRow}
                  data={row}
                />
              ))}

              <Table.Row className="add-row">
                <td colSpan="6">
                  <button className="button-reset" onClick={addNewRow}>
                    <Icon name="plus" />
                    <span>Add additional language pair</span>
                  </button>
                </td>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <StepControls></StepControls>
      </div>
    </section>
  );
};

export default LanguagePairs;
