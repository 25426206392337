import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingContainer, OnboardingSubTitle, OnboardingTitle, TitleImage } from '../Onboarding.style';
import logo from 'assets/taia_logo.svg';
import Button from 'components/common/Button/Button';
import { useHistory } from 'react-router';
import { sendUserInteraction } from 'utils/tagManager.utils';

const OnboardingWelcome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <OnboardingContainer>
      <TitleImage alt="" src={logo} height="120px" />
      <OnboardingTitle>{t('common:onboarding.welcome.header')}</OnboardingTitle>
      <OnboardingSubTitle>{t('common:onboarding.welcome.subheader')}</OnboardingSubTitle>
      <Button
        data-cy="onboarding-account-setup-link"
        actiontype="primary"
        onClick={() => {
          sendUserInteraction('onboarding get started click');
          history.push('/onboarding/customer');
        }}
      >
        {t('common:onboarding.welcome.button')}
      </Button>
    </OnboardingContainer>
  );
};

export default OnboardingWelcome;
