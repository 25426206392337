import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'semantic-ui-react';
import Message from '../../Message/Message';

import InputLabel from './InputLabel';
import CountriesDropdown from './CountriesDropdown';
import { Container, InputContainer, InputGroup, InputsContainer } from './Inputs.styles';

import { withTheme } from 'styled-components';
import { Icon } from '../../Icon/Icon';

const UserAddressInput = ({
  company_name = null,
  line1 = null,
  line2 = null,
  state = null,
  postalCode = null,
  city = null,
  company_street = null,
  country = null,
  updateProperty,
  theme,
}) => {
  const { t } = useTranslation();

  const handleTextInputChange = (e, { name, value }) => {
    e.preventDefault();
    updateProperty({ name, value });
  };

  return (
    <Container>
      <Icon name="postcard" color={theme.colors.blue} size="large" style={{ display: 'block' }} />
      <InputsContainer>
        <InputContainer>
          <InputLabel isRequired={true} text={t('common:userInfo.billing.labels.userName')} />
          <Input
            fluid
            name="company_name"
            onChange={handleTextInputChange}
            placeholder={t('common:userInfo.billing.placeholders.userName')}
            value={company_name || ''}
          />
        </InputContainer>
        <InputContainer>
          {(line1 === null || city === null || postalCode === null) && company_street !== null && (
            <Message text={t('common:checkout.billing.form.addressInfo') + company_street} type="info" />
          )}
        </InputContainer>
        <InputGroup>
          <InputContainer>
            <InputLabel isRequired={true} text={t('common:userInfo.billing.labels.line1')} />
            <Input
              fluid
              name="line1"
              onChange={handleTextInputChange}
              placeholder={t('common:userInfo.billing.placeholders.line1')}
              value={line1 || ''}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel text={t('common:userInfo.billing.labels.line2')} />
            <Input
              fluid
              name="line2"
              onChange={handleTextInputChange}
              placeholder={t('common:userInfo.billing.placeholders.line2')}
              value={line2 || ''}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <InputLabel isRequired={true} text={t('common:userInfo.billing.labels.city')} />
            <Input
              fluid
              name="city"
              onChange={handleTextInputChange}
              placeholder={t('common:userInfo.billing.placeholders.city')}
              value={city || ''}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel isRequired={true} text={t('common:userInfo.billing.labels.postalCode')} />
            <Input
              fluid
              name="postal_code"
              onChange={handleTextInputChange}
              placeholder={t('common:userInfo.billing.placeholders.postalCode')}
              value={postalCode || ''}
            />
          </InputContainer>
        </InputGroup>

        <InputContainer>
          <InputLabel isRequired={true} text={t('common:userInfo.billing.labels.state')} />
          <Input
            fluid
            name="state"
            onChange={handleTextInputChange}
            placeholder={t('common:userInfo.billing.placeholders.state')}
            value={state || ''}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel isRequired={true} text={t('common:userInfo.billing.labels.country')} />
          <CountriesDropdown country={country} updateProperty={updateProperty} />
        </InputContainer>
      </InputsContainer>
    </Container>
  );
};

export default withTheme(UserAddressInput);
