import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../../../common/Button/Button';
import CancelConfirmation from './CancelConfirmation/CancelConfirmation';
import { Link } from 'react-router-dom';
import ProgressBar from '../../../../common/ProgressBar/ProgressBar';
import SubscriptionTeams from '../SubscriptionTeams/SubscriptionTeams';

import { getSubscriptionById, postCancelSubscription } from '../../../../../services/subscriptions';
import { hasAdminPermissions } from 'utils/user.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { formatAPIDateString } from 'utils/date.utils';

import defaultPackageImage from '../../../assets/Catapult.svg';

const SubscriptionDetails = ({
  canManageSubscription = false,
  isSubscriptionExpired = false,
  subscriptionDetails,
  updateSubscriptionDetails,
  subscriptionTeams,
}) => {
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const history = useHistory();

  const { current_period_end, ends_at } = subscriptionDetails;

  const packageImgUrl = get(subscriptionDetails, 'plan.product.image', null);
  const productName = get(subscriptionDetails, 'plan.product.name', '');
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const userRole = user.role;

  const subscriptionUsage = useMemo(() => {
    const { auth_user_data, active_seats, quantity, subscription_seats } = subscriptionDetails;
    const limit_used = get(auth_user_data, 'current_month_statistic.limit_used', null);
    const word_limit = get(auth_user_data, 'current_month_statistic.word_limit', null);

    const currentUsage = canManageSubscription && subscription_seats.length > 1 ? active_seats : limit_used;
    const maxUsage = canManageSubscription && subscription_seats.length > 1 ? quantity : word_limit;

    const usagePercentage = !!currentUsage ? (currentUsage * 100) / maxUsage : 0;

    return {
      currentUsage,
      maxUsage,
      usagePercentage,
    };
  }, [canManageSubscription, subscriptionDetails]);

  const handleCancelSubscription = async (e) => {
    sendUserInteraction('subscription overview page cancel subscription button');

    try {
      setCancellingSubscription(true);
      await postCancelSubscription(subscriptionDetails.id);
      const newSubscriptionData = await getSubscriptionById(subscriptionDetails.id);
      toast.success(t('common:toasts.cancelSubscription.success'));
      updateSubscriptionDetails(newSubscriptionData);
    } catch (e) {
      toast.error(t('common:toasts.cancelSubscription.error'));
    } finally {
      setCancellingSubscription(false);
      setShowCancelConfirmation(false);
    }
  };

  const handleChangePackage = (e) => {
    e.preventDefault();
    history.push(`/catapult/packages?billingId=${subscriptionDetails.billing_detail_id}`);
  };

  const onCancelSubscriptionClick = () => {
    setShowCancelConfirmation(true);
  };

  const onCancelCancelClick = () => {
    setShowCancelConfirmation(false);
  };

  return (
    <div className="content-container">
      {showCancelConfirmation && (
        <CancelConfirmation
          loading={cancellingSubscription}
          onCancel={onCancelCancelClick}
          onConfirm={handleCancelSubscription}
        />
      )}
      <img
        alt="catapult"
        className="package-image"
        src={packageImgUrl ? packageImgUrl : defaultPackageImage}
      />
      <div className="details-container">
        <label className="label">{`${t('common:catapult.subscription.package')}:`}</label>
        <h2 className="heading-2 product-name">{productName}</h2>
        {isSubscriptionExpired ? (
          <h4 className="payment-heading heading-4">
            {`${t('common:catapult.subscription.subscriptionExpired')}: ${formatAPIDateString({
              dateString: ends_at,
              showTime: false,
            })}.`}
            {hasAdminPermissions(userRole) ? null : (
              <>
                {` ${t('common:catapult.subscription.subscriptionUpsell')} `}
                <Link
                  onClick={() => {
                    sendUserInteraction('catapult packages page link');
                  }}
                  to="/catapult/packages"
                >
                  {t('common:catapult.subscription.packagesPage')}
                </Link>
              </>
            )}
          </h4>
        ) : (
          <>
            {subscriptionUsage.currentUsage !== null && subscriptionUsage.maxUsage !== null && (
              <label className="usage-label label">
                {`${t('common:catapult.subscription.currentlyUsing')} ${subscriptionUsage.currentUsage}/${
                  subscriptionUsage.maxUsage
                }`}
              </label>
            )}
            <ProgressBar progress={subscriptionUsage.usagePercentage} width="100%" />
            {canManageSubscription ? (
              <div className="buttons-container">
                {!ends_at && (
                  <Button
                    actiontype="primary"
                    disabled={cancellingSubscription}
                    onClick={handleChangePackage}
                  >
                    {t('common:catapult.subscription.changePackage')}
                  </Button>
                )}
                {ends_at === null ? (
                  <Button loading={cancellingSubscription} onClick={onCancelSubscriptionClick}>
                    {t('common:catapult.subscription.cancelSubscription')}
                  </Button>
                ) : null}
              </div>
            ) : null}
            {canManageSubscription ? (
              <h4 className="payment-heading heading-4">
                {ends_at
                  ? `${t('common:catapult.subscription.subscriptionCancelled')}: ${formatAPIDateString({
                      dateString: ends_at,
                      showTime: false,
                    })}.`
                  : `${t('common:catapult.subscription.nextPayment')}: ${formatAPIDateString({
                      dateString: current_period_end,
                      showTime: false,
                    })}.`}
              </h4>
            ) : null}
          </>
        )}
        <SubscriptionTeams teams={subscriptionTeams} />
      </div>
    </div>
  );
};

export default SubscriptionDetails;
