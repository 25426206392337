import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  affiliateToken: null,
  billingDetails: null,
  isAuthenticated: false,
  isAuthenticating: false,
  user: null,
  adminUsers: [],
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState: { ...initialState },
  reducers: {
    setAffiliateToken(state, action) {
      const { affiliateToken } = action.payload;
      state.affiliateToken = affiliateToken;
    },
    setIsAuthenticated(state, action) {
      const { isAuthenticated } = action.payload;
      state.isAuthenticated = isAuthenticated;
    },
    setIsAuthenticating(state, action) {
      const { isAuthenticating } = action.payload;
      state.isAuthenticating = isAuthenticating;
    },
    setBillingDetails(state, action) {
      const { billingDetails } = action.payload;
      state.billingDetails = billingDetails;
    },
    setUser(state, action) {
      const { user } = action.payload;
      state.user = user;
    },
    setAdminUsers(state, action) {
      const { users } = action.payload;
      state.adminUsers = users;
    },
    removeInvite(state, action) {
      const inviteId = action.payload;
      const newInvites = state.user.invitations.filter((invite) => invite.id !== inviteId);
      const newUser = { ...state.user, invitations: newInvites };
      state.user = newUser;
    },
  },
});

export const {
  removeInvite,
  setAffiliateToken,
  setIsAuthenticated,
  setIsAuthenticating,
  setBillingDetails,
  setUser,
  setAdminUsers,
} = userSlice.actions;

export default userSlice.reducer;
