import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './ProjectInReview.styles';
import { Header, Icon } from 'semantic-ui-react';

const ProjectInReview = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Icon color="pink" circular name="briefcase" size="massive" />
      <Header as="h2">{t('common:admin.project.projectInReview')}</Header>
      <p>{t('common:admin.project.inReviewNotice')}</p>
    </Container>
  );
};

export default ProjectInReview;
