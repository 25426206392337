import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Button from '../../common/Button/Button';
import { Heading } from './DiyTable.styles';
import ProgressBar from '../../common/ProgressBar/ProgressBar';
import Table from '../../common/Table/Table';

import { truncateString } from 'utils/string.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useResizeObserver } from 'hooks/useResizeObserver';

// Styles
const Container = styled.div`
  width: 100%;
`;

const TableHeaderCellHelpHero = styled.div`
  position: absolute;
  height: ${({ tableHeight }) => tableHeight}px;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
`;

const DiyTable = ({ canOpenCatLink, t, jobs }) => {
  const [tableHeight, SetTableHeight] = useState(0);
  const tableRef = useRef(null);

  const jobRows = jobs.map((job) => {
    const openCatText = `${t('common:projects.projectJobs.translate')} ${job.target_language.name}`;
    const truncatedOpenText = truncateString({ maxLength: 23, string: openCatText });

    return (
      <Table.Row key={job.id}>
        <Table.Cell>{job.target_language.name}</Table.Cell>
        <Table.Cell>{job.total_words}</Table.Cell>
        <Table.Cell>
          <ProgressBar progress={job.progress} />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            actiontype="primary"
            disabled={job.job_url === null || !canOpenCatLink}
            as="a"
            href={job.job_url !== undefined ? job.job_url : null}
            style={{
              width: '212px',
            }}
            onClick={() => sendUserInteraction('catapult project ordered job translate click')}
          >
            {truncatedOpenText}
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  });

  /**
   * Sets table height which is needed in the <TableHeaderCellHelpHero />,
   * this way that helper div element stretches the whole height of the column.
   *
   * @param {ResizeObserverEntry[]} entries
   */
  const handleTableResize = (entries) => {
    for (let entry of entries) {
      const cr = entry.contentRect;
      SetTableHeight(cr.height);
    }
  };

  useResizeObserver(handleTableResize, tableRef);

  return (
    <Container>
      <Heading>{`${t('common:projects.projectJobs.title')}:`}</Heading>
      <Table width={'100%'} ref={tableRef}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <TableHeaderCellHelpHero
                id="catapult-project-target-language-column"
                tableHeight={tableHeight}
              />
              {t('common:projects.projectJobs.targetLanguage')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellHelpHero id="catapult-project-total-words-column" tableHeight={tableHeight} />
              {t('common:projects.projectJobs.totalWords')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellHelpHero id="catapult-project-progress-column" tableHeight={tableHeight} />
              {t('common:projects.projectJobs.progress')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <TableHeaderCellHelpHero id="catapult-project-buttons-column" tableHeight={tableHeight} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{jobRows}</Table.Body>
      </Table>
    </Container>
  );
};

export default withTranslation('common')(DiyTable);
