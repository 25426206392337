import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDebounce, withDebounce } from '../../../hoc/debouncer';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';

// Components
import LoadingPane from '../../common/LoadingPane/LoadingPane';
import Button from '../../common/Button/Button';
import FilterSidebar from '../../common/FilterSidebar/FilterSidebar';
import Message from '../../common/Message/Message';
import { SelectGroup } from 'components/common/SelectGroup/SelectGroup';
import { Header, Input } from 'semantic-ui-react';
import { Icon } from '../../common/Icon/Icon';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import Table from '../../common/Table/Table';
import Pagination from '../../common/Pagination/Pagination';
import { MultipleDropdown } from 'components/common/Dropdown/MultipleDropdown';
import UserAvatar from 'components/common/UserAvatar/UserAvatar';
import AnalyzedProjectRow from './AnalyzedProjectRow/AnalyzedProjectRow';

// Services, utils, vars
import { getAdminProjects } from '../../../services/project';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { STATUS } from 'components/common/StatusLabel/StatusLabel.constants';

// Styles
import './style.scss';
import { usePagination } from 'hooks/usePagination';
import { TableToolbar } from '../../common.styles';

// Constants
const paginationAnimationVariants = {
  exit: {
    opacity: 0,
    height: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
  },
};

const entriesPerPage = 10;

const AnalyzedProjects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceIssued, setInvoiceIssued] = useLocalStorage('admin-project-invoice-issued', null);

  const [projects, setProjects] = useState([]);
  const [filterString, setFilterString] = useLocalStorage('admin-project-search', '');
  const [, setError] = useState(false);
  const [paidFilters, setPaidFilters] = useLocalStorage('admin-project-paid-filters', null);
  const [statusFilters, setStatusFilters] = useLocalStorage('admin-project-status-filters', []);
  const [pmFilters, setPMFilters] = useLocalStorage('admin-project-project-manager-filters', []);

  const adminUsers = useSelector((state) => state.userStore.adminUsers);

  const { t } = useTranslation();
  const { debounce } = useDebounce();
  const { activePage, numOfPages, changePage } = usePagination('admin-project');

  useEffect(() => {
    const fetchProjects = async (page, search) => {
      debounce(
        'fetchAnalyzedProjects',
        async () => {
          try {
            setIsLoading(true);
            setError(false);

            const { data } = await getAdminProjects({
              per_page: entriesPerPage,
              page,
              project_paid: paidFilters,
              search,
              status_id: statusFilters,
              invoice_issued: invoiceIssued,
              pm_id: pmFilters,
            });

            setIsLoading(false);
            setProjects(addPopup(data.data));

            if (page > data.last_page) {
              changePage({ page: data.last_page });
            }
            changePage({ numOfPages: data.last_page });
          } catch (e) {
            setError(false);
            console.error(e);
          } finally {
            setIsLoading(false);
          }
        },
        400,
      );
    };

    fetchProjects(activePage, filterString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, filterString, paidFilters, statusFilters, invoiceIssued, pmFilters]);

  const fetchProjects = async (page, search) => {
    try {
      setIsLoading(true);
      setError(false);

      const { data } = await getAdminProjects({
        per_page: entriesPerPage,
        page,
        project_paid: paidFilters,
        search,
        status_id: statusFilters,
        pm_id: pmFilters,
      });

      setIsLoading(false);
      setProjects(addPopup(data.data));

      if (page > data.last_page) {
        changePage({ activePage: data.last_page });
      }
      changePage({ numOfPages: data.last_page });
    } catch (e) {
      setError(false);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const addPopup = (projects) => {
    return projects.map((project) => {
      project.popup = false;
      return project;
    });
  };

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('analyzed projects page change');
    changePage({ activePage });
  };

  const handleFilterString = (e, { value }) => {
    sendUserInteraction('analyzed projects filter string change');
    setFilterString(value);
  };

  const handlePMFilterSelect = (_, { value }) => {
    setPMFilters(value);
    changePage({ activePage: 1 });
  };

  const handleStatusClick = (value) => {
    sendUserInteraction('analyzed project status filters change');
    let newStatus = [...statusFilters];
    const valIdx = newStatus.findIndex((paid) => paid === value);

    if (valIdx >= 0) {
      newStatus.splice(valIdx, 1);
    } else {
      newStatus = [...newStatus, value];
    }

    setStatusFilters(newStatus);
    changePage({ activePage: 1 });
  };

  const handleStatusAll = () => {
    const statusAllChecked = statusFilters.length === statusOptions.length;
    const newStatuses = statusAllChecked ? [] : statusOptions.map(({ value }) => value);

    setStatusFilters(newStatuses);
  };

  const handleInvoiceIssuedClick = (val) => {
    setInvoiceIssued(val);
    changePage({ activePage: 1 });
  };

  const handlePaidClick = (val) => {
    setPaidFilters(val);
    changePage({ activePage: 1 });
  };

  const handleClearFilters = async (e) => {
    sendUserInteraction('analyzed projects reset filters');
    setFilterString('');
    setPaidFilters([null]);
    setStatusFilters([]);
    setPMFilters([]);
    changePage({ activePage: 1 });
    setInvoiceIssued(null);
  };

  // Render variables
  const adminUsersOptions = [
    {
      name: 'No PM',
      id: null,
    },
    ...adminUsers,
  ].map((adminUser) => ({
    icon: <UserAvatar className="icon" src={adminUser?.avatar} />,
    text: adminUser.name,
    value: adminUser.id,
  }));

  const isStatusSelected = (value) => statusFilters.includes(value);

  const statusOptions = Object.keys(STATUS).map((statusKey) => {
    return {
      text: t(STATUS[statusKey]),
      value: statusKey,
    };
  });

  const statusAllChecked = statusFilters.length === statusOptions.length;
  const statusSomeChecked = statusFilters.length > 0;
  const statusAllButtonText = t(`common:projects.filters.${statusAllChecked ? 'de' : ''}selectAll`);

  const projectRows = projects.map((project) => {
    const refetchProjects = async () => {
      await fetchProjects(1, filterString, paidFilters, statusFilters);
      changePage({ activePage: 1 });
    };
    return (
      <AnalyzedProjectRow
        key={project.id}
        project={project}
        refetchProjects={refetchProjects}
        adminUsers={adminUsers}
      />
    );
  });

  return (
    <div className="analyzed-projects">
      <div className="actions">
        <TableToolbar>
          <AnimatePresence>
            {numOfPages > 1 && (
              <Pagination
                as={motion.div}
                activePage={activePage}
                totalPages={numOfPages}
                onPageChange={handlePaginationChange}
                className="left"
                variants={paginationAnimationVariants}
                exit="exit"
                initial="exit"
                animate="animate"
              />
            )}
          </AnimatePresence>
        </TableToolbar>
        <FilterSidebar className="filters-sidebar">
          <Header as="h3">{`${t('common:projects.filters.heading')}:`}</Header>
          <p>{`${t('common:projects.filters.projectName')}:`}</p>
          <Input
            icon={<Icon name="search" inElement />}
            placeholder="Search..."
            value={filterString}
            onChange={handleFilterString}
            loading={isLoading}
          />
          <div className="sidebar-field-group">
            <p>{t('common:admin.projects.analyzedProjects.projectManager')}:</p>
            <MultipleDropdown
              value={pmFilters}
              className="person-filter-dropdown"
              options={adminUsersOptions}
              onChange={handlePMFilterSelect}
              placeholder="Select one or more PMs"
              search
            />
          </div>
          <div className="sidebar-field-group">
            <p>{t('common:admin.projects.analyzedProjects.invoiceStatus')}:</p>
            <div className="sidebar-field">
              <SelectGroup
                border
                flat
                onChange={handleInvoiceIssuedClick}
                selected={invoiceIssued}
                size="small"
              >
                <SelectGroup.Option value={null}>all</SelectGroup.Option>
                <SelectGroup.Option value={true}>issued</SelectGroup.Option>
                <SelectGroup.Option value={false}>not issued</SelectGroup.Option>
              </SelectGroup>
            </div>
          </div>
          <div className="sidebar-field-group">
            <p>{t('common:admin.projects.analyzedProjects.paidDropdownPlaceholder')}:</p>
            <div className="sidebar-field">
              <SelectGroup border flat onChange={handlePaidClick} selected={paidFilters} size="small">
                <SelectGroup.Option value={null}>all</SelectGroup.Option>
                <SelectGroup.Option value={true}>paid</SelectGroup.Option>
                <SelectGroup.Option value={false}>not paid</SelectGroup.Option>
              </SelectGroup>
            </div>
          </div>
          <div className="sidebar-field-group">
            <p>{t('common:admin.projects.analyzedProjects.statusDropdownPlaceholder')}:</p>
            <div className="sidebar-field">
              <Checkbox
                className="filters-checkbox-select-all"
                label={statusAllButtonText}
                onClick={handleStatusAll}
                checked={statusAllChecked}
                indeterminate={statusSomeChecked && !statusAllChecked}
              />
            </div>
            {statusOptions.map((option, index) => {
              const { value, text, key } = option;
              return (
                <div key={`${index}-${key}`} className="sidebar-field">
                  <Checkbox
                    checked={isStatusSelected(value)}
                    key={key}
                    label={text}
                    onClick={() => handleStatusClick(value)}
                    value={value}
                  />
                </div>
              );
            })}
          </div>
          <div className="sidebar-field">
            <Button loading={isLoading} disabled={isLoading} onClick={handleClearFilters}>
              {t('common:projects.filters.reset')}
            </Button>
          </div>
        </FilterSidebar>
      </div>
      <div className="projects">
        {isLoading && <LoadingPane height="100%" />}
        {projectRows.length > 0 ? (
          <div className="table-wrapper">
            <Table width="100%">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.projectName')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.customer')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.projectManager')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.createdAt')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.status')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.words')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.Price')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.langComb')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.dueDate')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.projects.headers.payment')}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{projectRows}</Table.Body>
            </Table>
          </div>
        ) : (
          !isLoading && <Message text={t('common:projects.noProjectsFound')} type="info" />
        )}
      </div>
    </div>
  );
};

export default withDebounce(withTranslation('common')(AnalyzedProjects));
