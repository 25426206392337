import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Message from 'components/common/Message/Message';
import InviteRow from './InviteRow';
import Table from '../../common/Table/Table';

import { MessageContainer, TableContainer } from './Invites.styles';
import { TabContainer } from '../UserProfile.styles';

const Invites = () => {
  const { t } = useTranslation();
  const invitations = useSelector((state) => state.userStore.user.invitations);

  return (
    <TabContainer>
      {invitations.length > 0 ? (
        <TableContainer>
          <Table width="100%" marginTop="0">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('common:userInfo.invites.createdAt')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:userInfo.invites.invitedTo')}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {invitations.map((invite) => (
                <InviteRow key={invite.id} invite={invite} />
              ))}
            </Table.Body>
          </Table>
        </TableContainer>
      ) : (
        <MessageContainer>
          <Message text={t('common:userInfo.invites.noInvites')} type="info" />
        </MessageContainer>
      )}
    </TabContainer>
  );
};

export default Invites;
