import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Message from '../common/Message/Message';
import { Container, ButtonsContainer } from './InviteNotification.styles';

import { updateInvitation } from '../../services/users';
import { getUser } from '../../services/auth';
import { setUser } from '../../store/userSlice';
import Button from '../common/Button/Button';

const InviteNotification = ({ invite }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const history = useHistory();
  const { invitable_type } = invite;
  const [isAccepting, setIsAccepting] = useState(false);
  const { t } = useTranslation();

  const checkIfCompany = () => {
    return invitable_type === 'App\\Models\\TaiaCompany';
  };

  const isCompany = checkIfCompany();

  const getNotificationText = () => {
    return isCompany
      ? `${t('common:userInfo.invites.company')}: ${invite.invitable.company_name} ${t(
          'common:userInfo.invites.invitePending',
        )}`
      : `${t('common:userInfo.invites.team')}: ${invite.invitable.name} ${t(
          'common:userInfo.invites.invitePending',
        )}`;
  };

  const handleAcceptClick = async (e) => {
    setIsAccepting(true);
    try {
      await updateInvitation({ acceptInvite: 1, invitationId: invite.id });
      const userInfo = await getUser();
      dispatch(setUser({ user: userInfo.data }));
      setError(null);
      toast.dismiss(invite.id);
    } catch (e) {
      setError(true);
      setIsAccepting(false);
    }
  };

  const handleSeeAllClick = (e) => {
    e.preventDefault();
    toast.dismiss(invite.id);
    history.push('/profile');
  };

  return (
    <Container>
      {getNotificationText()}
      {error && (
        <Message text={t('common:userInfo.invites.error')} type="error" style={{ marginTop: '1rem' }} />
      )}
      <ButtonsContainer>
        <Button actiontype="cancel" disabled={isAccepting} fluid onClick={handleSeeAllClick}>
          {t('common:userInfo.invites.seeAll')}
        </Button>
        <Button actiontype="submit" fluid loading={isAccepting} onClick={handleAcceptClick}>
          {t('common:userInfo.invites.accept')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default InviteNotification;
