import DE_COUNTRY_TRANSLATIONS from '../../../assets/translations/de/countryIsoCodes.json';
import EN_COUNTRY_TRANSLATIONS from '../../../assets/translations/en/countryIsoCodes.json';
import HR_COUNTRY_TRANSLATIONS from '../../../assets/translations/hr/countryIsoCodes.json';
import IT_COUNTRY_TRANSLATIONS from '../../../assets/translations/it/countryIsoCodes.json';
import SL_COUNTRY_TRANSLATIONS from '../../../assets/translations/sl/countryIsoCodes.json';
import SR_COUNTRY_TRANSLATIONS from '../../../assets/translations/sr/countryIsoCodes.json';

export const getCountryLabelTranslation = (ISOCode, i18n) => {
  let translations = [];

  switch (i18n.language) {
    case 'de':
      translations = DE_COUNTRY_TRANSLATIONS;
      break;
    case 'en':
      translations = EN_COUNTRY_TRANSLATIONS;
      break;
    case 'hr':
      translations = HR_COUNTRY_TRANSLATIONS;
      break;
    case 'it':
      translations = IT_COUNTRY_TRANSLATIONS;
      break;
    case 'sl':
      translations = SL_COUNTRY_TRANSLATIONS;
      break;
    case 'sr':
      translations = SR_COUNTRY_TRANSLATIONS;
      break;
    default:
      translations = EN_COUNTRY_TRANSLATIONS;
      break;
  }

  const translationValue = translations.find((country) => country.value === ISOCode);
  if (translationValue) {
    return translationValue.text;
  }
  return null;
};

export const renderProperty = (property) => {
  if (property === null || property === undefined || property === '') {
    return 'N/A';
  }
  return property;
};
