import { useEffect } from 'react';

const MetaTag = ({ name, content }) => {
  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = name;
    metaTag.content = content;
    document.head.appendChild(metaTag);

    return () => {
      const metaTags = document.head.querySelectorAll(`meta[name="${name}"]`);
      metaTags.forEach((mt) => document.head.removeChild(mt));
    };
  }, [name, content]);

  return null;
};

export default MetaTag;
