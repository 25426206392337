import { useReducer, useRef, useEffect, useCallback } from 'react';

export const getInitialState = ({ initialState = {} }) => {
  return {
    highlightedIndex: null,
    isOpen: false,
    selectedItem: null,
    inputValue: '',
    expandUpward: false,
    ...initialState,
  };
};

export const defaultProps = {
  stateReducer: (s, a) => a.changes,
};

const invokeOnChangeHandler = (key, action, state, newState) => {
  const { props, type } = action;
  const capitalized = key.charAt(0).toUpperCase() + key.slice(1);
  const handler = `on${capitalized}Change`;
  if (props[handler] && newState[key] !== undefined && newState[key] !== state[key]) {
    props[handler]({ type, ...newState });
  }
};

export const callOnChangeProps = (action, state, newState) => {
  const { props, type } = action;
  const changes = {};

  Object.keys(state).forEach((key) => {
    invokeOnChangeHandler(key, action, state, newState);

    if (newState[key] !== state[key]) {
      changes[key] = newState[key];
    }
  });

  if (props.onStateChange && Object.keys(changes).length) {
    props.onStateChange({ type, ...changes });
  }
};

export const getState = (state, props) => {
  return Object.keys(state).reduce((prevState, key) => {
    prevState[key] = props[key] === undefined ? state[key] : props[key];
    return prevState;
  }, {});
};

export const useControlledReducer = (reducer, initialState, props) => {
  const prevStateRef = useRef();
  const actionRef = useRef();
  const enhancedReducer = useCallback(
    (state, action) => {
      actionRef.current = action;
      state = getState(state, action.props);

      const changes = reducer(state, action);
      const newState = action.props.stateReducer(state, { ...action, changes });

      return newState;
    },
    [reducer],
  );
  const [state, dispatch] = useReducer(enhancedReducer, initialState);
  const propsRef = useRef(props);
  propsRef.current = props;

  const dispatchWithProps = useCallback((action) => dispatch({ props: propsRef.current, ...action }), [
    propsRef,
  ]);
  const action = actionRef.current;

  useEffect(() => {
    if (action && prevStateRef.current && prevStateRef.current !== state) {
      callOnChangeProps(action, getState(prevStateRef.current, action.props), state);
    }

    prevStateRef.current = state;
  }, [state, props, action]);

  return [getState(state, props), dispatchWithProps];
};

export const includes = (item, search) => {
  return item.text.toLowerCase().includes(search.toLowerCase());
};

export const getOptionFromValue = (value, options) => {
  return options.find((opt) => opt.value === value) || null;
};
