import React from 'react';
import { classnames } from 'utils/base.utils';
import CircleTimelineItem from './CircleTimelineItem';

// Styles
import './style.scss';

const CircleTimeline = ({ items, className }) => {
  const classNames = classnames('circle-timeline', className);

  return (
    <div className={classNames}>
      {items.map((item, idx) => (
        <React.Fragment key={`item-${idx}`}>
          <CircleTimelineItem index={idx} {...item} />
          {idx + 1 !== items.length && <div className="separator" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CircleTimeline;
