import styled from 'styled-components';

export const DropZoneContainer = styled.div`
  cursor: pointer;
  padding: 1rem 0;
`;

export const DropZoneText = styled.div`
  margin: 1rem;
  color: #2185d0;
`;

export const ControlsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  width: 100%;
  margin: 0 auto;
`;
