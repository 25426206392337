import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';

import {
  ProjectHeader,
  ProjectProgress,
  ProjectWrapper,
  StyledHeader,
  TitleWrapper,
  TranslatedFiles,
} from './AdminProject.style';
import { Checkbox, Divider, Dropdown, Header, Label, List, Modal } from 'semantic-ui-react';
import { Icon } from '../common/Icon/Icon';

import DownloadInvoice from './DownloadInvoice';
import CompanyCard from '../common/ProfileCard/CompanyCard';
import ProfileCard from '../common/ProfileCard/ProfileCard';
import ProjectLogs from '../common/ProjectLogs/ProjectLogs';
import LoadingPane from '../common/LoadingPane/LoadingPane';
import PriceDetails from './PriceDetails/PriceDetails';
import WordsDetails from './WordsDetails/WordsDetails';
import ProjectLanguages from '../common/ProjectLanguages/ProjectLanguages';
import JobsTable from './JobsTable/JobsTable';
import UpdateStatusCategoryForm from './UpdateStatusCategoryForm/UpdateStatusCategoryForm';
import TranslationUpload from './TranslationUpload/TranslationUpload';
import OriginalFilesDownload from '../common/OriginalFilesDownload/OriginalFilesDownload';
import ServicesTable from './ServicesTable/ServicesTable';

import { getUser } from '../../services/users';
import {
  getProject,
  getProjectJobs,
  getProjectLogs,
  getTranslatedFiles,
  postSendInvoice,
  postUpdateStatus,
  toggleProjectPaid,
  postResendInvoice,
  assignPmToProject,
} from '../../services/project';
import { getCompany } from '../../services/company';
import { getBillingDetails } from '../../services/billing';
import { getAllTranslators } from '../../services/translators';
import { getUpdateZip } from '../../services/project';

import { STATUS, STATUS_COLORS } from '../../constants/jobs';
import TransferModal from '../Projects/ProjectsCards/CardControls/TransferModal';
import { toast } from 'react-toastify';
import Button from '../common/Button/Button';
import Card from '../common/Card/Card';
import { StyledTextArea } from 'components/Project/OrderProject/Comment/Comment.styles';
import { hasJobServices } from '../../utils/jobs.utils';
import { Link } from 'react-router-dom';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { formatAPIDateString } from 'utils/date.utils';
import ArchiveModal from 'components/common/ArchiveModal/ArchiveModal';
import { canTransferProject } from 'utils/user.utils';
import { PROJECT_STATUS_ARCHIVED } from 'constants/projectStatus';
import DeleteProjectModal from 'components/common/DeleteProjectModal/DeleteProjectModal';
import { canDeleteProject } from 'utils/user.utils';
import AssignPerson from 'components/common/AssignPerson/AssignPerson';
import { SidebarWrapper } from 'components/common/AdminSidebar/AdminSidebar.styles';
import ProjectOrderedAdminSidebar from 'components/common/AdminSidebar/ProjectOrdered';
import OrderProjectAdminSidebar from 'components/common/AdminSidebar/OrderProject';

class AdminProject extends Component {
  state = {
    archiveLoading: false,
    pmLoading: false,
    billing: null,
    company: null,
    deleteModal: false,
    error: false,
    isLoading: true,
    issuingInvoice: false,
    jobs: null,
    logs: null,
    paidLoading: false,
    project: null,
    translators: [],
    user: null,
    sendEmailInvioce: true,
    syncModalOpen: false,
    syncingWithCat: false,
    showTransferModal: false,
    currentPM: null,
  };

  async componentDidMount() {
    this.fetchAll();
  }

  async fetchProject(project_id) {
    try {
      const { data } = await getProject(project_id);
      this.setState({ project: data });
      return data;
    } catch (e) {
      this.setState({ error: true, isLoading: false });
      throw e;
    }
  }

  async fetchLogs(project_id) {
    try {
      const { data } = await getProjectLogs({ projectId: project_id });
      this.setState({ logs: data });
      return data;
    } catch (e) {
      this.setState({ error: true });
      throw e;
    }
  }

  updateProjectState = (data) => {
    this.setState({
      project: data,
    });
  };

  async fetchUser() {
    const { project } = this.state;
    try {
      if (!!project.user_id) {
        const user = await getUser(project.user_id);
        this.setState({ user: user });
      }

      if (!!project.team) {
        const { company_id } = project.team;
        const companyData = await getCompany(company_id);
        this.setState({
          company: companyData,
        });
      }
    } catch (e) {
      this.setState({ error: true, isLoading: false });
      throw e;
    }
  }

  async fetchJobs(project_id) {
    try {
      const jobs = await getProjectJobs(project_id);
      this.setState({ jobs: jobs });
    } catch (e) {
      this.setState({ error: true, isLoading: false });
      throw e;
    }
  }

  updateJob = (job) => {
    const { jobs } = this.state;

    const newJobs = jobs.map((oldJob) => {
      if (oldJob.id === job.id) {
        return job;
      }
      return oldJob;
    });

    this.setState({
      jobs: newJobs,
    });
  };

  async fetchBilling(billing_id) {
    try {
      const billing = await getBillingDetails(billing_id);
      this.setState({ billing: billing });
    } catch (e) {
      this.setState({ error: true, isLoading: false });
      throw e;
    }
  }

  async fetchTranslators() {
    try {
      const data = await getAllTranslators();
      this.setState({ translators: data });
      return data;
    } catch (e) {
      this.setState({ error: true, isLoading: false });
      throw e;
    }
  }

  async fetchAll() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });

    try {
      const project = await this.fetchProject(params.project_id);
      let currPM = null;

      await this.fetchJobs(params.project_id);
      await this.fetchTranslators();
      await this.fetchUser();
      // If billing detail id is not null
      if (!!project?.billing_detail_id) await this.fetchBilling(project.billing_detail_id);
      await this.fetchLogs(params.project_id);

      // Fetch PM data
      if (project.project_manager) {
        currPM = project.project_manager;
      }

      this.setState({
        currentPM: currPM,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        error: true,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleProjectPaidClicked = (project_id) => {
    sendUserInteraction('admin project overview mark project as paid');
    const { t } = this.props;
    return async () => {
      try {
        this.setState({ paidLoading: true });
        await toggleProjectPaid(project_id);
        await this.fetchAll();
        toast.success(t('common:toasts.markPaid.success'));
        this.setState({ paidLoading: false });
      } catch (e) {
        toast.error(t('common:toasts.markPaid.error'));
        throw e;
      }
    };
  };

  handleArchiveClicked = (project_id) => {
    sendUserInteraction('admin project overview mark archive project');
    return async () => {
      const { t } = this.props;
      try {
        this.setState({ archiveLoading: true });
        await postUpdateStatus(project_id, 20);
        await this.fetchAll();
        this.setState({ deleteModal: false, archiveLoading: true });
        toast.success(t('common:toasts.archiveProject.success'));
      } catch (e) {
        toast.error(t('common:toasts.archiveProject.error'));
        throw e;
      }
    };
  };

  handleInvoiceClick = (project_id, langCode = 'en-GB') => {
    sendUserInteraction(`admin project overview send invoice click - ${langCode}`);
    const { languages, t } = this.props;

    const language = languages.find((lang) => lang.code === langCode);
    if (!language) {
      toast.error(t('common:toasts.invoiceSent.error'));
      this.setState({ issuingInvoice: false });
    }

    return async () => {
      try {
        this.setState({ issuingInvoice: true });
        if (this.state.project.proforma) {
          await postResendInvoice(project_id, language.id);
        } else {
          await postSendInvoice(project_id, language.id, this.state.sendEmailInvioce ? 0 : 1);
        }
        await this.fetchAll();
        this.setState({ issuingInvoice: false });
        toast.success(t('common:toasts.invoiceSent.success'));
      } catch (e) {
        toast.error(t('common:toasts.invoiceSent.error'));
        this.setState({ issuingInvoice: false });
        throw e;
      }
    };
  };

  handleSyncWithCAT = (project_id) => {
    sendUserInteraction('admin project overview sync with CAT click');
    const { t } = this.props;
    return async () => {
      try {
        this.setState({ syncingWithCat: true });
        await getUpdateZip(project_id);
        await this.fetchAll();
        this.setState({ syncingWithCat: false });
        toast.success(t('common:toasts.translatedFiles.success'));
      } catch (e) {
        toast.error(t('common:toasts.translatedFiles.error'));
        this.setState({ syncingWithCat: false });
        throw e;
      }
    };
  };

  handleDownloadTranslatedClick = async () => {
    sendUserInteraction('admin project overview download translated files click');
    this.setState({
      downloadingTranslatedFiles: true,
    });

    const { t } = this.props;

    try {
      // get file blob
      const response = await getTranslatedFiles(this.state.project.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      this.setState({
        downloadingTranslatedFiles: false,
      });
    }
  };

  handleSendEmailChange = (e, { checked }) => {
    this.setState({ sendEmailInvioce: checked });
  };

  handlePMAssign = async (userID) => {
    const { t } = this.props;

    this.setState({
      pmLoading: true,
    });

    try {
      const { project } = this.state;

      const response = await assignPmToProject({
        project_id: project.id,
        pm_id: userID,
      });

      this.setState({
        currentPM: response.project_manager,
      });
    } catch (error) {
      toast.error(t('common:admin.project.errors.assignPM'));
    } finally {
      this.setState({
        pmLoading: false,
      });
    }
  };

  render() {
    const { t, history } = this.props;
    const {
      downloadingTranslatedFiles,
      company,
      jobs,
      user,
      project,
      isLoading,
      translators,
      issuingInvoice,
      syncingWithCat,
      syncModalOpen,
      sendEmailInvioce,
      showTransferModal,
      logs,
    } = this.state;

    // Show
    const showTransferOption = canTransferProject(user?.role, project?.status_id);
    const showDeleteOption = canDeleteProject(project?.status_id);

    return isLoading ? (
      <LoadingPane />
    ) : (
      <SidebarWrapper isAdmin={true}>
        <ProjectWrapper divided selection>
          <div className="project-details">
            <Header>{t('common:admin.project.projectDetails')}</Header>
            <Card style={{ height: 'calc(100% - 2.5rem)' }}>
              <div className="card-content">
                <ProjectHeader backgroundColor={STATUS_COLORS[project.status_id]}>
                  <TitleWrapper>
                    <Header inverted={project.status_id !== 20}>{project.name}</Header>
                    <Dropdown
                      button
                      className="ui circular icon button quick-actions"
                      closeOnBlur
                      closeOnEscape
                      direction="left"
                      icon="ellipsis vertical"
                      color="blue"
                      style={{ height: 'min-content' }}
                    >
                      <Dropdown.Menu style={{ marginTop: '.25rem' }}>
                        {project.status_id !== 10 && project.status_id !== 11 && (
                          <Dropdown.Item
                            icon={project.project_paid ? 'times' : 'checkmark'}
                            text={
                              project.project_paid
                                ? t('common:admin.project.unmarkPaid')
                                : t('common:admin.project.markPaid')
                            }
                            onClick={this.handleProjectPaidClicked(project.id)}
                          />
                        )}
                        {showTransferOption && (
                          <Dropdown.Item
                            text={t('common:projects.transferProject')}
                            icon="move"
                            onClick={() => this.setState({ showTransferModal: true })}
                          />
                        )}
                        <ArchiveModal
                          project={project}
                          user={user}
                          trigger={
                            <Dropdown.Item
                              icon="archive"
                              text={t(
                                `common:admin.project.${
                                  project.status_id === PROJECT_STATUS_ARCHIVED ? 'unarchive' : 'archive'
                                }`,
                              )}
                            />
                          }
                          setLoading={(loading) => {
                            // Loading end
                            if (!loading) this.fetchAll();
                          }}
                        />
                        {showDeleteOption && (
                          <DeleteProjectModal
                            trigger={
                              <Dropdown.Item
                                text={t('common:delete')}
                                icon="trash"
                                className="body-text-red hover:bg-red-4"
                              />
                            }
                            projectId={project.id}
                            setLoading={(loading) => {
                              // Loading end
                              if (!loading) history.push('/');
                            }}
                          />
                        )}
                        <Dropdown.Item icon="edit">
                          <Link to={`/project/${this.props.match.params.project_id}/settings`}>
                            {t('common:admin.updateProject.header')}
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </TitleWrapper>
                  <StyledHeader inverted={project.status_id !== 20}>
                    {STATUS[project.status_id]}{' '}
                  </StyledHeader>
                </ProjectHeader>
                <ProjectProgress size="tiny" percent={project.progress} />
                <List>
                  <List.Item>
                    <Label color="blue" horizontal>
                      {t('common:admin.project.projectDue')}:
                    </Label>
                    {formatAPIDateString({ dateString: project.delivery_time })}
                  </List.Item>
                  <List.Item className="project-manager">
                    <Label color="blue" horizontal>
                      {t('common:admin.project.projectManager')}:
                    </Label>
                    <AssignPerson
                      choices={this.props.adminUsers}
                      currentPerson={this.state.currentPM}
                      displayName
                      onAssign={this.handlePMAssign}
                      loading={this.state.pmLoading}
                      popupProps={{
                        position: 'right-center',
                      }}
                    />
                  </List.Item>
                </List>
                <ProjectLanguages project={project} />

                <Divider />
                <WordsDetails project={project} />
                <Divider />
                <PriceDetails project={project} />
                <Divider />
                <OriginalFilesDownload projectId={project.id} />
                <TranslatedFiles>
                  <Button
                    disabled={syncingWithCat || !project.translated_file || downloadingTranslatedFiles}
                    download
                    loading={syncingWithCat || downloadingTranslatedFiles}
                    onClick={this.handleDownloadTranslatedClick}
                  >
                    {t('common:projects.projectInfo.translatedFiles')}
                  </Button>
                  {project.cat_id && project.cat_setting && project.cat_pass ? (
                    <Dropdown className="button icon" floating trigger={<></>}>
                      <Dropdown.Menu>
                        {project.translated_file ? (
                          <Modal
                            size="mini"
                            onClose={() => this.setState({ syncModalOpen: false })}
                            onOpen={() => this.setState({ syncModalOpen: true })}
                            open={syncModalOpen}
                            trigger={<Dropdown.Item text={t('common:admin.project.sync')} icon="sync" />}
                          >
                            <Modal.Header>{t('common:admin.project.syncModalTitle')}</Modal.Header>
                            <Modal.Content>{t('common:admin.project.syncWillOverWrite')}</Modal.Content>
                            <Modal.Actions>
                              <Button
                                actiontype="cancel"
                                disabled={syncingWithCat}
                                onClick={() => this.setState({ syncModalOpen: false })}
                              >
                                {t('common:cancel')}
                              </Button>
                              <Button
                                actiontype="submit"
                                loading={syncingWithCat}
                                onClick={async () => {
                                  await this.handleSyncWithCAT(project.id);
                                  this.setState({ syncModalOpen: false });
                                }}
                              >
                                {t('common:confirm')}
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        ) : (
                          <Dropdown.Item
                            text={t('common:admin.project.sync')}
                            icon="sync"
                            onClick={this.handleSyncWithCAT(project.id)}
                          />
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}
                </TranslatedFiles>
                {(project.status_id === 6 || project.status_id === 20) && (
                  <div style={{ marginTop: '4px', marginRight: '4px' }}>
                    <Dropdown
                      floating
                      pointing={false}
                      icon={null}
                      trigger={
                        <Button
                          labelPosition="right"
                          actiontype={!project.proforma ? 'submit' : null}
                          loading={issuingInvoice}
                        >
                          {project.proforma
                            ? t('common:admin.project.invoice')
                            : t('common:admin.project.issueInvoice')}
                          <Icon name="angle-down" />
                        </Button>
                      }
                    >
                      <Dropdown.Menu>
                        {project.proforma && <DownloadInvoice projectId={project.id} />}
                        <Dropdown.Item
                          text={
                            project.proforma
                              ? t('common:admin.project.resendEnglish')
                              : t('common:language.english')
                          }
                          onClick={this.handleInvoiceClick(project.id, 'en-GB')}
                        />
                        <Dropdown.Item
                          text={
                            project.proforma
                              ? t('common:admin.project.resendSlovene')
                              : t('common:language.slovene')
                          }
                          onClick={this.handleInvoiceClick(project.id, 'sl-SI')}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                    {!project.proforma && (
                      <Checkbox
                        toggle
                        label={t('common:admin.project.invoiceNotification')}
                        checked={sendEmailInvioce}
                        onClick={this.handleSendEmailChange}
                      />
                    )}
                  </div>
                )}
                {!!logs && logs.length > 0 ? (
                  <>
                    <Divider />
                    <ProjectLogs logs={logs} />
                  </>
                ) : null}
              </div>
            </Card>
          </div>
          <div className="tools">
            {!!user || !!company ? (
              <>
                <Header>{t('common:admin.project.customer')}</Header>
                {user && <ProfileCard isAdmin={true} user={user} simple></ProfileCard>}
                {company && <CompanyCard companyData={company} teamData={project.team} />}
              </>
            ) : null}
            {!!project.comment && (
              <>
                <Header>{t('common:projects.analyse.comments')}</Header>
                <Card>
                  <div className="card-content">
                    <StyledTextArea disabled value={project.comment} resizable></StyledTextArea>
                  </div>
                </Card>
              </>
            )}
            {project.status_id !== 10 && project.status_id !== 11 && (
              <>
                <Header>{t('common:admin.project.translatedFiles')}</Header>
                <Card>
                  <div className="card-content">
                    <TranslationUpload updateProjectState={this.updateProjectState} project={project} />
                  </div>
                </Card>
              </>
            )}
            <Header>{t('common:admin.project.updateProject')}</Header>
            <Card>
              <div className="card-content">
                <UpdateStatusCategoryForm
                  project={project}
                  onSubmit={() => {
                    this.fetchAll();
                  }}
                />
              </div>
            </Card>
          </div>
          {jobs.length > 0 && (
            <div className="jobs">
              <Card>
                <div className="card-content">
                  {hasJobServices(jobs) ? (
                    <ServicesTable
                      currency={project.currency}
                      jobs={jobs}
                      updateJob={this.updateJob}
                      projectStatusId={project.status_id}
                      refreshJobsData={() => {
                        this.fetchAll();
                      }}
                      vendors={translators}
                    />
                  ) : (
                    <JobsTable
                      jobs={jobs}
                      user={this.props.user}
                      currency={project.currency}
                      translators={translators}
                      status_id={project.status_id}
                      onSubmit={() => {
                        this.fetchAll();
                      }}
                      updateJob={this.updateJob}
                    />
                  )}
                </div>
              </Card>
            </div>
          )}
          {showTransferModal && (
            <TransferModal
              isAdmin={true}
              onClose={() => {
                this.setState({ showTransferModal: false });
              }}
              onSubmit={() => {
                this.fetchAll();
                this.setState({ showTransferModal: false });
              }}
              project={project}
            />
          )}
        </ProjectWrapper>
        {project.status_id === 2 ? (
          <OrderProjectAdminSidebar project={project} refreshProject={this.fetchAll} isAdminPage={true} />
        ) : (
          <ProjectOrderedAdminSidebar project={project} refreshProject={this.fetchAll} isAdminPage={true} />
        )}
      </SidebarWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  languages: state.classifiersStore.languages,
  user: state.userStore.user,
  adminUsers: state.userStore.adminUsers,
});

export default withRouter(connect(mapStateToProps)(withTranslation('common')(AdminProject)));
