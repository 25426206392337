import styled from 'styled-components';

export const OrderSummaryWrapper = styled.div`
  .summary-header {
    margin-top: 28px;
    margin-bottom: 28px;

    h1 {
      margin-bottom: 10px;
    }
  }

  .order {
    width: 100%;
    display: grid;
    grid-template-columns: auto 384px;
    grid-column-gap: 24px;
    grid-template-areas: 'products price';

    .products {
      grid-area: products;

      .price-loader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        height: 100%;
      }
    }

    .price {
      grid-area: price;
    }
  }
`;
