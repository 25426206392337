const LINKS = {
  support: {
    general: 'https://taia.io/help-center/',
    usingTheApp: 'https://taia.io/help-center/getting-started/',
    catapult: 'https://taia.io/help-center/using-the-taia-platform/',
  },
  blog: 'https://taia.io/blog/',
};

export default LINKS;
