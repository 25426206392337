import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SectionTitle from './SectionTitle';

import Card from '../../../common/Card/Card';

import {
  BillingName,
  CardFooter,
  HeaderContainer,
  IconButtonContainer,
  SectionContainer,
  SectionContent,
  SectionRow,
} from './BillingCard.styles';

import { hasAdminPermissions } from '../../../../utils/user.utils';
import { localiseAndConvertPrice } from '../../../../utils/string.utils';
import { getCountryLabelTranslation } from '../Billing.utils';

import { currencyByIdSelector } from '../../../../store/selectors';
import { getBillingSubscriptions } from '../../../../services/subscriptions';
import { Icon } from '../../Icon/Icon';

import { sendUserInteraction } from 'utils/tagManager.utils';

const BillingCard = ({
  billingDetails,
  controlsComponent = null,
  isCollapsedOnMount = false,
  isCollapsible = true,
}) => {
  const { i18n, t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(isCollapsedOnMount);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subs = (await getBillingSubscriptions({ billing_id: billingDetails.id, active: true }))['data'];
        setSubscriptions(subs);
      } catch (e) {
        console.error(e);
      }
    };
    fetchSubscriptions();
  }, [billingDetails]);

  const availableCountries = useSelector((state) => state.classifiersStore.countries);
  const billingGroups = useSelector((state) => state.classifiersStore.billingGroups);
  const userRole = useSelector((state) => state.userStore.user.role);

  const getCurrencyById = useSelector(currencyByIdSelector);

  const isAdmin = hasAdminPermissions(userRole);

  const {
    billing_group_id,
    company_name,
    company_street,
    company_vat,
    country_id,
    currency_id,
    discount,
    euro_balance,
    vat_rate,
    digital_vat_rate,
    name,
    user_email,
    project_invoices,
    verified,
  } = billingDetails;

  const billingName = name ? name : t('common:userInfo.billing.billingDetails');

  const getCountryData = () => {
    const country = availableCountries.find((country) => country.id === country_id);
    if (!country) {
      return null;
    }
    return country;
  };

  const getBillingGroup = () => {
    if (billing_group_id) {
      const group = billingGroups.find((stateGroup) => billing_group_id === stateGroup.id);
      return group;
    }
    const countryData = getCountryData(country_id);
    if (countryData) {
      return countryData.billing_group;
    }
    return null;
  };

  const getCountryLabel = () => {
    const country = getCountryData();
    if (!country) {
      return null;
    }
    const label = getCountryLabelTranslation(country.iso_code, i18n);
    return label ? label : country.name;
  };

  const getCurrencyLabel = () => {
    const currency = getCurrencyById(currency_id);
    if (!currency) {
      return null;
    }
    return `${currency.code} (${currency.symbol})`;
  };

  const billingGroup = getBillingGroup();

  const handleCollapseClick = (e) => {
    sendUserInteraction('billing card expand/collapse button');

    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Card>
      <HeaderContainer>
        <BillingName>{billingName}</BillingName>
        {controlsComponent ? controlsComponent(subscriptions) : null}
      </HeaderContainer>
      <SectionContainer>
        <SectionTitle icon="postcard" text={company_name} />
        <SectionContent>
          {company_vat && (
            <SectionRow>
              <span></span>
              <span>{company_vat}</span>
            </SectionRow>
          )}
          <SectionRow>
            <span></span>
            <span>{company_street}</span>
          </SectionRow>
          <SectionRow>
            <span></span>
            <span>{getCountryLabel(country_id)}</span>
          </SectionRow>
        </SectionContent>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle
          icon="envelope"
          isValid={!!user_email}
          text={!!user_email ? user_email : t('common:userInfo.billing.errors.emailNotSet')}
        />
      </SectionContainer>

      {!isCollapsed && (
        <>
          <SectionContainer>
            <SectionTitle icon="money-bill" text={getCurrencyLabel(currency_id)} />
            <SectionContent>
              <SectionRow>
                <span></span>
                <span>{`${t('common:userInfo.billing.labels.paymentOptions')}:`}</span>
              </SectionRow>

              <SectionRow>
                <span></span>
                <span>{`- ${t('common:userInfo.billing.labels.creditCard')}`}</span>
              </SectionRow>

              {euro_balance > 0 ? (
                <SectionRow>
                  <span></span>
                  <span>{`- ${t('common:userInfo.billing.labels.accountBalance')}: ${localiseAndConvertPrice({
                    currencyData: getCurrencyById(currency_id),
                    number: euro_balance,
                    language: i18n.language,
                  })}`}</span>
                </SectionRow>
              ) : null}

              {!!verified && (
                <SectionRow>
                  <span></span>
                  <span>{`- ${t('common:userInfo.billing.labels.invoice')} ${
                    isAdmin && project_invoices
                      ? `(${t('common:userInfo.billing.labels.paymentDue')}: ${project_invoices})`
                      : ''
                  }`}</span>
                </SectionRow>
              )}
            </SectionContent>
          </SectionContainer>

          <SectionContainer>
            <SectionTitle
              icon="usd-circle"
              text={
                // TODO VAT
                vat_rate !== null || digital_vat_rate !== null
                  ? t('common:userInfo.billing.labels.vatCharged')
                  : t('common:userInfo.billing.labels.vatNotCharged')
              }
            />
          </SectionContainer>

          {isAdmin && (
            <SectionContainer>
              <SectionTitle
                icon="user"
                text={billingGroup ? billingGroup.name : t('common:userInfo.billing.labels.noBillingGroup')}
              />
              <SectionContent>
                {billingGroup && (
                  <SectionRow>
                    <span />
                    <span>{`${t('common:userInfo.billing.labels.AIDiscount')}: ${
                      billingGroup.discount_percentage
                    }%`}</span>
                  </SectionRow>
                )}
                {!!discount && (
                  <SectionRow>
                    <span></span>
                    <span>{`${t('common:userInfo.billing.labels.billingDiscount')} ${discount}%`}</span>
                  </SectionRow>
                )}
              </SectionContent>
            </SectionContainer>
          )}
        </>
      )}

      <CardFooter>
        {isCollapsible && (
          <IconButtonContainer onClick={handleCollapseClick}>
            <Icon name={isCollapsed ? 'angle-down' : 'angle-up'} color="#000" />
          </IconButtonContainer>
        )}
      </CardFooter>
    </Card>
  );
};

export default BillingCard;
