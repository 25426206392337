import httpClient from './httpclient';

export const postLogin = async ({ email, password, ip_address, captcha }) => {
  localStorage.removeItem('authToken');

  try {
    const { data } = await httpClient.post(`/auth/login`, {
      email,
      password,
      ip_address,
      recaptcha_token: captcha,
    });

    const authToken = data.access_token;
    localStorage.setItem('authToken', authToken);

    httpClient.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  } catch (e) {
    throw e;
  }
};

export const postRegister = async ({
  affiliate,
  balance,
  email,
  invite_token,
  name,
  password,
  captcha,
  campaign_data,
}) => {
  try {
    const { data } = await httpClient.post(`/auth/signup`, {
      affiliate,
      balance,
      email,
      invite_token,
      name,
      password,
      recaptcha_token: captcha,
      campaign_data,
    });

    const authToken = data.access_token;
    localStorage.setItem('authToken', authToken);

    httpClient.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

    return data;
  } catch (e) {
    throw e;
  }
};

export const getUser = async () => {
  try {
    const { data } = await httpClient.get(`/auth/user`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getAffiliateToken = async (referralToken) => {
  try {
    const { data } = await httpClient.get(`/referral/${referralToken}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUpdateLanguage = async (languageId) => {
  try {
    const { data } = await httpClient.post('/auth/updatelanguage', {
      language_id: languageId,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postRequestPasswordReset = async ({ email, captcha }) => {
  try {
    const { data } = await httpClient.post(`/password/create`, { email, recaptcha_token: captcha });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPasswordResetToken = async (token) => {
  try {
    const { data } = await httpClient.get(`/password/find/${token}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postPasswordReset = async ({ email, password, token, captcha }) => {
  try {
    const { data } = await httpClient.post(`/password/reset`, {
      email,
      password,
      token,
      recaptcha_token: captcha,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getActivateUser = async (token) => {
  try {
    const { data } = await httpClient.get(`/auth/signup/activate/${token}`);

    const authToken = data.access_token;

    if (!!authToken) {
      localStorage.setItem('authToken', authToken);
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }

    return data;
  } catch (e) {
    throw e;
  }
};

export const postSetPicture = async (img, id) => {
  const formData = new FormData();
  formData.append('input_img', img);
  formData.append('user_id', id);

  try {
    const { data } = await httpClient.post(`/auth/profile/setpicture`, formData);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postConfirmPhone = async (phoneNumber) => {
  try {
    const { data } = await httpClient.post(`/auth/profile/confirmphone`, {
      phone_number: phoneNumber,
    });
    return data;
  } catch (e) {
    return false;
  }
};

export const postProfileUpdate = async (userData) => {
  try {
    const { data } = await httpClient.post(`/auth/profile/update`, { ...userData });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getLatestIncomes = async (userId) => {
  try {
    const { data } = await httpClient.get(`/auth/latestincomes/${userId}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getBillingDetails = async (userId) => {
  try {
    const { data } = await httpClient.get(`/auth/billing/details/${userId}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postSetPassword = async (old_password, new_password, new_password_confirmation) => {
  try {
    const { data } = await httpClient.post(`/auth/user/password-reset`, {
      old_password: old_password,
      new_password: new_password,
      new_password_confirmation: new_password_confirmation,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUserOnboard = async ({ billingDetails, userRole }) => {
  try {
    const payload =
      userRole === 40
        ? {
            role: userRole,
            company_name: billingDetails.company_name,
          }
        : {
            role: userRole,
          };
    const { data } = await httpClient.post(`/auth/useronboard`, payload);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postProfileTranslatorDeleteComb = async (index) => {
  try {
    await httpClient.post(`/auth/profile/translatordeletecomb`, {
      combination_id: index,
    });
  } catch (e) {
    throw e;
  }
};

export const getInviteToken = async (inviteToken) => {
  try {
    const { data } = await httpClient.get(`/invite/${inviteToken}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postActivateEmail = async (email) => {
  try {
    const { data } = await httpClient.post(`/activation-email`, { email });
    return data;
  } catch (e) {
    throw e;
  }
};
