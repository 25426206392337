import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;

  .catapult-card {
    margin: 110px 64px 0 0;
  }

  .taia-card {
    margin-top: 110px;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    flex-wrap: wrap;

    .catapult-card {
      margin: 0;
    }

    .taia-card {
      margin-top: 24px;
    }
  `}
`;

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    height: calc((var(--vh, 1vh) * 100) - var(--sidebar-header-height));
    padding: ${theme.paddings.mainContainer.mobile.x};
    overflow-y: scroll;
    justify-content: initial;
  `}
`;

export const MainHeading = styled.h1`
  font-family: 'DM Sans';
  font-size: 52px;
  padding: 0;
  padding-bottom: 26px;
  margin: 0;
  text-align: center;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    font-size: 32px;
    min-height: initial;
  `}
`;

export const CardButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  row-gap: 10px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`

  `}
`;

export const CardContentContainer = styled.div`
  height: calc(100% + 110px);
  font-family: 'DM Sans';
  display: flex;
  flex-direction: column;
  margin-top: -110px;
  justify-items: flex-start;
  padding: 28px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin-top: 0;
  `}
`;

export const CardImage = styled.img`
  // width: 280px;
  height: 233px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    width: 50%;
    height: auto;
    margin: 0 auto;
  `}
`;

export const CardHeading = styled.div`
  text-align: center;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 18px;
  font-family: 'DM Sans';

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: bold;
  `}
`;

export const CardText = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: 'DM Sans';
  margin-bottom: 16px;
  width: 100%;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    text-align: center;
  `}
`;

export const PricesText = styled(CardText)`
  margin-bottom: 8px;
`;

export const PricesValue = styled.span`
  font-weight: 700;
`;

export const DeliveryText = styled(CardText)`
  margin-bottom: 0;
`;

export const DeliveryValue = styled(DeliveryText)`
  font-weight: 700;
`;
