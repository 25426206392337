import { useEffect } from 'react';

/**
 *
 * @param {function} callback
 * @param {React.RefObject<HTMLElement>} innerRef
 */
export const useOuterClick = (callback, innerRef) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!innerRef.current || innerRef.current.contains(event.target)) {
        return;
      }
      // Check if the clicked element is an anchor tag and prevent closing the popup
      if (event.target.tagName === 'A') {
        return;
      }
      callback(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [innerRef, callback]);
};
