import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  billingGroups: [],
  categories: [],
  countries: [],
  currencies: [],
  freePlanUnitLimit: 2000, // default this to 500 in case it is for some reason not returned from the API
  languages: [],
  products: [],
  timezones: [],
};

const classifiersSlice = createSlice({
  name: 'classifiersSlice',
  initialState: { ...initialState },
  reducers: {
    setBillingGroups(state, action) {
      const groups = action.payload;
      state.billingGroups = groups;
    },

    setCountries(state, action) {
      const countries = action.payload;
      state.countries = countries;
    },

    setCurrencies(state, action) {
      const currencies = action.payload;
      const parsedCurrencies = currencies.map((curr) => {
        const parsedConversion = parseFloat(curr.conversion_rate);
        return {
          ...curr,
          conversion_rate: parsedConversion,
        };
      });
      state.currencies = parsedCurrencies;
    },

    setFreePlanLimit(state, action) {
      const limit = action.payload;
      if (!!limit) {
        state.freePlanUnitLimit = limit;
      }
    },

    setLanguages(state, action) {
      const languages = action.payload;
      state.languages = languages;
    },

    setTimezones(state, action) {
      const timezones = action.payload;
      state.timezones = timezones;
    },

    setCategories(state, action) {
      const categories = action.payload;
      state.categories = categories;
    },
    setProducts(state, action) {
      const products = action.payload;
      state.products = products;
    },
  },
});

export const {
  setBillingGroups,
  setCategories,
  setCountries,
  setCurrencies,
  setFreePlanLimit,
  setLanguages,
  setTimezones,
  setProducts,
} = classifiersSlice.actions;

export default classifiersSlice.reducer;
