import styled from 'styled-components';

export const BillingGroupContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 16px;
`;

export const DiscountText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
`;
