import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 30px;
  font-weight: 400;
  line-height: 39px;
  margin: 0 0 50px 0;
`;

export const Container = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0 0 70px 0;
`;

export const ListItem = styled.li`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  width: 300px;
`;

export const ListItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 10px;
`;

export const ListItemText = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

export const ListItemAnnotation = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 20px;
`;
