import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { companyRoutes } from '../../../routes/index';

import { StyledTab } from '../Company.styles';

import CompanyInfo from '../CompanyInfo/CompanyInfo';
import CompanyInvites from '../CompanyInvites/CompanyInvites';
import CompanyMembers from '../CompanyMembers/CompanyMembers';
import CompanyBilling from '../CompanyBilling/CompanyBilling';
import CompanyInvoices from '../CompanyInvoices/CompanyInvoices';
import Teams from '../Teams/Teams';
import CompanyProjects from '../CompanyProjects/CompanyProjects';
import { hasAdminPermissions } from '../../../utils/user.utils';
import { useSelector } from 'react-redux';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useCompanyState } from '../Company.state';

const CompanyTabs = ({ companyData, updateCompanyData, shareTm, updateTmSetting = () => {} }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, tabName } = useParams();
  const {
    invites: { data: invites, loading },
  } = useCompanyState();

  const user = useSelector((state) => state.userStore.user);

  const tabs = hasAdminPermissions(user.role)
    ? [
        {
          index: 0,
          name: 'Company info',
          render: () => (
            <CompanyInfo
              companyData={companyData}
              updateCompanyData={updateCompanyData}
              shareTm={shareTm}
              updateTmSetting={updateTmSetting}
            />
          ),
        },
        { index: 1, name: 'Company teams', render: () => <Teams /> },
        { index: 2, name: 'Company members', render: () => <CompanyMembers /> },
        { index: 3, name: 'Company invites', render: () => <CompanyInvites /> },
        { index: 4, name: 'Company billing', render: () => <CompanyBilling /> },
        { index: 5, name: 'Company invoices', render: () => <CompanyInvoices /> },
        {
          index: 6,
          name: 'Company projects',
          render: () => <CompanyProjects />,
        },
      ]
    : [
        { index: 0, name: 'Company teams', render: () => <Teams /> },
        { index: 1, name: 'Company members', render: () => <CompanyMembers /> },
        { index: 2, name: 'Company invites', render: () => <CompanyInvites /> },
        { index: 3, name: 'Company billing', render: () => <CompanyBilling /> },
        { index: 4, name: 'Company invoices', render: () => <CompanyInvoices /> },
      ];

  const panes = tabs.map((routeTab) => {
    const route = companyRoutes.find((route) => routeTab.name === route.name);
    const title =
      routeTab.name === 'Company invites' ? (
        <>
          {t(route.sidebar.text)}
          <StyledTab.Number active={tabName === 'invites'} loading={loading}>
            {invites.length}
          </StyledTab.Number>
        </>
      ) : (
        t(route.sidebar.text)
      );
    return {
      index: routeTab.index,
      title,
      render: routeTab.render,
      path: route.sidebar.link,
      pathToMatch: route.sidebar.link.split('/')[3],
    };
  });

  const getActiveTabIndex = () => {
    const activePane = panes.findIndex((pane) => tabName === pane.pathToMatch);
    return activePane !== -1 ? activePane : 0;
  };

  const handleTabChange = (e, { activeIndex }) => {
    e.preventDefault();
    const pane = { ...panes[activeIndex] };
    const newPath = pane.path.replace(':id', id);
    sendUserInteraction(`change active company tab: ${newPath}`);
    history.push(newPath);
  };

  return <StyledTab activeIndex={getActiveTabIndex()} panes={panes} onTabChange={handleTabChange} />;
};

export default CompanyTabs;
