import Button from 'components/common/Button/Button';
import CircleTimeline from 'components/common/CircleTimeline/CircleTimeline';
import { Icon } from 'components/common/Icon/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAddVendorContext } from './AddVendorProvider';

export const StepControls = () => {
  const { activeStep, completedSteps, stepsValid, steps, submitVendor, isSubmiting } = useAddVendorContext();
  const history = useHistory();
  const { t } = useTranslation();

  const handlePrevClick = () => {
    history.push(`/admin/vendor-onboarding?step=${activeStep - 1}`);
  };

  const handleNextClick = () => {
    history.push(`/admin/vendor-onboarding?step=${activeStep + 1}`);
  };

  const handleSubmit = () => {
    history.push(`/admin/vendors`);
  };

  return (
    <div className="steps-controls">
      {activeStep > 0 && (
        <Button
          className="prev"
          big
          basic
          color="light-grey"
          onClick={handlePrevClick}
          loading={isSubmiting}
          disabled={isSubmiting || activeStep === 0}
        >
          Back
        </Button>
      )}
      {activeStep === steps.length - 1 ? (
        <Button
          className="next"
          actiontype="success"
          labelPosition="right"
          fluid
          big
          onClick={() => submitVendor(handleSubmit)}
          disabled={!stepsValid || isSubmiting}
          loading={isSubmiting}
        >
          {t('common:submit', 'Submit')}
          <Icon name="arrow-right" />
        </Button>
      ) : (
        <Button
          className="next"
          actiontype="primary"
          labelPosition="right"
          fluid
          big
          onClick={handleNextClick}
          disabled={!completedSteps.includes(activeStep) || isSubmiting}
          loading={isSubmiting}
        >
          {t('common:next', 'Next')}
          <Icon name="arrow-right" />
        </Button>
      )}
    </div>
  );
};

const AddSteps = ({ iconsSize = 'small' }) => {
  const { activeStep, completedSteps, steps } = useAddVendorContext();

  const isStepActive = (idx) => idx === activeStep;
  const isStepCompleted = (idx) => completedSteps.includes(idx);

  const isStepDisabled = (idx) => {
    const stepActive = isStepActive(idx);
    const stepCompleted = isStepCompleted(idx);

    // Also check prev step if its complete
    const prevStepCompleted = idx === 0 ? true : isStepCompleted(idx - 1);

    return !prevStepCompleted && !stepCompleted && !stepActive;
  };

  const getStateForStep = (idx) => ({
    current: isStepActive(idx),
    disabled: isStepDisabled(idx),
    finished: isStepCompleted(idx),
  });

  const items = steps.map(({ icon, label: title }, idx) => ({
    icons: {
      default: icon,
      finished: 'check',
      size: iconsSize,
    },
    label: { title },
    state: getStateForStep(idx),
    to: `/admin/vendor-onboarding${idx !== 0 ? `?step=${idx}` : ''}`,
  }));

  return <CircleTimeline items={items} />;
};

export default AddSteps;
