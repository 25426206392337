export const ACCEPTED_FILES = [
  'doc',
  'dot',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'rtf',
  'odt',
  'ott',
  'pdf',
  'txt',
  'xls',
  'xlt',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'ods',
  'ots',
  'tsv',
  'ppt',
  'pps',
  'pot',
  'pptx',
  'pptm',
  'ppsx',
  'ppsm',
  'potx',
  'potm',
  'odp',
  'otp',
  'htm',
  'html',
  'xhtml',
  'dtd',
  'json',
  'yaml',
  'yml',
  'bmp',
  'png',
  'gif',
  'jpeg',
  'jpg',
  'tiff',
  'xliff',
  'sdlxliff',
  'tmx',
  'ttx',
  'xlf',
  'mif',
  'idml',
  'icml',
  'properties',
  'resx',
  'xml',
  'sxml',
  'txml',
  'dita',
  'strings',
  'srt',
  'wix',
  'po',
  'g',
  'ts',
  'pages',
  'numbers',
  'key',
  'zip',
  'md',
  'jfif',
  'sbv',
  'vtt',
];

export const DOC_FILES = ['doc', 'docx', 'pdf'];

export const FILE_NAMES = {
  office: [
    'doc',
    'dot',
    'docx',
    'docm',
    'dotx',
    'dotm',
    'rtf',
    'odt',
    'ott',
    'txt',
    'xls',
    'xlt',
    'xlsm',
    'xltx',
    'xlsx',
    'xltm',
    'ods',
    'ots',
    'tsv',
    'ppt',
    'pps',
    'pot',
    'pptx',
    'pptm',
    'ppsx',
    'ppsm',
    'potx',
    'potm',
    'odp',
    'otp',
    'pages',
    'numbers',
    'key',
  ],
  localisation: [
    'htm',
    'html',
    'xhtml',
    'dtd',
    'json',
    'yaml',
    'yml',
    'xliff',
    'sdlxliff',
    'tmx',
    'ttx',
    'xlf',
    'mif',
    'properties',
    'resx',
    'xml',
    'sxml',
    'txml',
    'dita',
    'strings',
    'srt',
    'wix',
    'po',
    'g',
    'ts',
    'zip',
    'md',
    'sbv',
    'vtt',
  ],
  image: ['bmp', 'png', 'gif', 'jpeg', 'jpg', 'tiff', 'jfif'],
  graphic: ['pdf', 'idml', 'icml'],
};

export const OCR_FILES = ['pdf', 'jpeg', 'jpg', 'png', 'bmp', 'gif', 'docx'];

export const MAX_FILES = 150;
export const MAX_FILESIZE = 200_000_000;
