import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import { Icon } from '../../common/Icon/Icon';
import Message from '../../common/Message/Message';
import { AddPricing } from './Pricing.style';
import { hasSameCombination, pricingCombinations, remainingPricingCombinations } from './Pricing.util';
import PricingField from './PricingField';

const Pricing = ({ onChange }) => {
  const [pricing, setPricing] = useState([]);
  const [remainingCominations, setRemainingCombinations] = useState(pricingCombinations([]));
  const { t } = useTranslation();

  useEffect(() => {
    const remaining = remainingPricingCombinations(pricing);
    setRemainingCombinations(remaining);
    onChange([...pricing]);
  }, [pricing, onChange]);

  const addPricing = () => {
    const newPricing = [
      ...pricing,
      {
        amount: 0,
        interval: remainingCominations[0].interval,
        currency: remainingCominations[0].currency.code.toLowerCase(),
      },
    ];
    setPricing(newPricing);
  };

  const updatePricing = (price, idx) => {
    const newPricing = [...pricing];
    newPricing[idx] = { ...price };
    setPricing(newPricing);
  };

  const removePricing = (idx) => {
    const newPricing = [...pricing];
    newPricing.splice(idx, 1);
    setPricing(newPricing);
  };

  const pricingFields = pricing.map((price, idx) => {
    return (
      <PricingField
        key={idx}
        pricing={price}
        onRemove={() => removePricing(idx)}
        onChange={(pricing) => updatePricing(pricing, idx)}
      />
    );
  });

  return (
    <>
      <Header as="h2">{t('common:admin.createPackage.pricing.header')}</Header>
      {hasSameCombination(pricing) && (
        <Message
          header={t('common:admin.createPackage.pricing.combinationWarningHeader')}
          text={t('common:admin.createPackage.pricing.combinationWarning')}
          type="warning"
        />
      )}
      {pricingFields}
      {remainingCominations.length > 0 && (
        <AddPricing onClick={addPricing}>
          <div className="header-text">{t('common:admin.createPackage.pricing.addPricing')}</div>
          <div className="header-icon">
            <Icon name="plus" />
          </div>
        </AddPricing>
      )}
    </>
  );
};

export default Pricing;
