import React, { useEffect, useRef, useState } from 'react';

import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';
import SectionDescription from 'components/common/SectionDescription/SectionDescription';
import TextArea from 'components/common/TextArea/TextArea';
import { TabContainer } from 'components/UserProfile/UserProfile.styles';
import { VENDOR_RISK } from 'constants/vendors';
import { VENDOR_STATUS } from 'constants/vendors';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { Divider } from 'semantic-ui-react';
import SaveChanges from '../SaveChanges';
import { handleUpdateVendor } from 'components/AdminVendor/AdminVendor.utils';
import { useTranslation } from 'react-i18next';
import VendorUserSelect from 'components/AdminAddVendor/VendorUserSelect';
import { Input } from 'components/common/Input/Input';
import DeleteVendorModal from 'components/common/DeleteVendorModal/DeleteVendorModal';
import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';

const AdminSettings = ({ vendorData, setVendorData = () => {} }) => {
  // Refs
  const initialFormData = useRef({
    email: vendorData.email ?? '',
    status: vendorData.status,
    risk: vendorData.risk,
    note: vendorData.note ?? '',
    iso: !!vendorData.iso, // Get boolean from 0 or 1
    payment_from: vendorData.payment_from,
  });

  // Local state
  const [formData, setFormData] = useState(initialFormData.current);
  const [canSubmit, setCanSubmit] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  // Hooks
  const { t } = useTranslation();

  // Functions
  /**
   * Fires on input change,
   * update local state for form data
   */
  const handleInputsChange = (_, { name, value }) => {
    // checked argument is passed from our Checkbox components onChange cb

    setFormData((c) => ({
      ...c,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (_, { name, checked }) => {
    setFormData((c) => ({
      ...c,
      [name]: checked,
    }));
  };
  /**
   * Used for saving vendor changes
   */
  const handleSaveChanges = async () => {
    try {
      setSubmitting(true);

      const response = await handleUpdateVendor({
        vendor: vendorData,
        formInitial: initialFormData.current,
        formCurrent: formData,
      });

      setVendorData(response.data);

      toast.success(`Successfully saved changes for ${vendorData.name}`);
    } catch (error) {
      console.error(error);
      toast.error('There was a problem saving your changes');
    } finally {
      setSubmitting(false);
    }
  };

  const handleUserSelect = (user) => {
    if (user) {
      setFormData({ ...formData, email: user.email, user_id: user.id });
    } else {
      setFormData({ ...formData, user_id: null });
    }
  };

  // Effects
  /**
   * On form data change, check if form state is different from the start
   * If so, enable submit else disable
   */
  useEffect(() => setCanSubmit(!isEqual(formData, initialFormData.current)), [formData]);

  /**
   * When vendorData updates:
   * - sync the local ref
   * - sync the local state for curr form data
   */
  useEffect(() => {
    initialFormData.current = {
      email: vendorData.email,
      status: vendorData.status,
      risk: vendorData.risk,
      note: vendorData.note ?? '',
      iso: !!vendorData.iso, // Get boolean from 0 or 1
      payment_from: vendorData.payment_from,
      user_id: vendorData.user_id,
    };
    setFormData(initialFormData.current);
  }, [vendorData, setFormData]);

  // Variables for render
  // Generate vendor status options
  const statusDropdownOptions = Object.keys(VENDOR_STATUS).map((id, idx) => ({
    key: idx,
    text: VENDOR_STATUS[id],
    value: parseInt(id),
  }));

  // Generate vendor risk options
  const riskDropdownOptions = Object.keys(VENDOR_RISK).map((id, idx) => ({
    key: idx,
    text: VENDOR_RISK[id],
    value: parseInt(id),
  }));

  // Generate vendor payment from options
  const paymentFromDropdownOptions = [
    {
      key: 1,
      text: 'Slovenia',
      value: 'Slovenia',
    },
    {
      key: 2,
      text: 'United Kingdom',
      value: 'United Kingdom',
    },
  ];

  return (
    <TabContainer>
      <SectionDescription
        title={t(`common:serviceProvider.forms.inputs.user.title`, 'User')}
        description={t(
          `common:serviceProvider.forms.inputs.user.description`,
          'Select the user to attach this vendor profile to.',
        )}
        content={<VendorUserSelect initialUser={vendorData.user} onUserSelect={handleUserSelect} />}
      />

      <Divider />

      <SectionDescription
        title={t('common:admin.singleVendor.tabs.adminSettings.inputs.email.title', 'Email')}
        description={t(
          'common:admin.singleVendor.tabs.adminSettings.inputs.email.description',
          'Email address with which this vendor can be contacted',
        )}
        content={
          <Input
            name="email"
            onChange={handleInputsChange}
            value={formData.email}
            disabled={formData.user_id}
            placeholder={t('common:admin.singleVendor.tabs.adminSettings.inputs.email.placeholder', 'Email')}
          />
        }
      />

      <Divider />

      <SectionDescription
        title={t('common:admin.singleVendor.tabs.adminSettings.inputs.status.title')}
        description={t('common:admin.singleVendor.tabs.adminSettings.inputs.status.description')}
        content={
          <SelectDropdown
            options={statusDropdownOptions}
            selection
            onChange={handleInputsChange}
            value={formData.status}
            placeholder={t('common:admin.singleVendor.tabs.adminSettings.inputs.status.placeholder')}
            name="status"
          />
        }
      />

      <Divider />

      <SectionDescription
        title={t('common:admin.singleVendor.tabs.adminSettings.inputs.risk.title')}
        description={t('common:admin.singleVendor.tabs.adminSettings.inputs.risk.description')}
        content={
          <SelectDropdown
            options={riskDropdownOptions}
            selection
            onChange={handleInputsChange}
            value={formData.risk}
            placeholder={t('common:admin.singleVendor.tabs.adminSettings.inputs.risk.placeholder')}
            name="risk"
          />
        }
      />

      <Divider />

      <SectionDescription
        title={t('common:admin.singleVendor.tabs.adminSettings.inputs.notes.title')}
        description={t('common:admin.singleVendor.tabs.adminSettings.inputs.notes.description')}
        content={
          <TextArea
            placeholder={t('common:admin.singleVendor.tabs.adminSettings.inputs.notes.placeholder')}
            onChange={handleInputsChange}
            value={formData.note}
            name="note"
          />
        }
      />

      <Divider />

      <SectionDescription
        title={t('common:admin.singleVendor.tabs.adminSettings.inputs.iso.title')}
        description={t('common:admin.singleVendor.tabs.adminSettings.inputs.iso.description')}
        content={<Checkbox onChange={handleCheckboxChange} name="iso" checked={formData.iso} />}
      />

      <Divider />

      <SectionDescription
        title={t('common:admin.singleVendor.tabs.adminSettings.inputs.paymentFrom.title')}
        description={t('common:admin.singleVendor.tabs.adminSettings.inputs.paymentFrom.description')}
        content={
          <SelectDropdown
            onChange={handleInputsChange}
            options={paymentFromDropdownOptions}
            value={formData.payment_from}
            name="payment_from"
            placeholder={t('common:admin.singleVendor.tabs.adminSettings.inputs.paymentFrom.placeholder')}
          />
        }
      />

      <Divider />

      <SectionDescription
        title={t('common:admin.singleVendor.tabs.adminSettings.inputs.delete.title', 'Delete vendor')}
        description={t(
          'common:admin.singleVendor.tabs.adminSettings.inputs.delete.description',
          'Delete this vendor profile',
        )}
        content={
          <DeleteVendorModal
            vendorId={vendorData.id}
            trigger={
              <div>
                <Button big labelPosition="left" actiontype="delete">
                  <Icon name="trash" />
                  {t('common:admin.singleVendor.delteVednor', 'Delete vendor')}
                </Button>
              </div>
            }
          />
        }
      />

      <Divider />

      <SaveChanges onClick={handleSaveChanges} loading={submitting} disabled={submitting || !canSubmit} />
    </TabContainer>
  );
};

export default AdminSettings;
