import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Loader from '../../common/Loader/Loader';
import Message from '../../common/Message/Message';
import { postFreeSubscription } from '../../../services/subscriptions';
import Button from '../../common/Button/Button';
import { fetchSubscriptions } from '../Checkout.utils';
import { toast } from 'react-toastify';
import { sendUserInteraction } from 'utils/tagManager.utils';

import '../NotificationPage.scss';

const ActivateFreePlan = ({ user }) => {
  const [error, setError] = useState('');
  const [active, setActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const orderFreeSubcription = async () => {
      try {
        await postFreeSubscription(user.id);
        await fetchSubscriptions();
        setActive(true);
        toast.success(t('common:checkout.activatingFree.activated'));
        history.push(user.role === 1 ? '/onboarding/customer' : '/');
      } catch (e) {
        console.error(e);
        if (e.response.status === 422) {
          setError(t('common:checkout.activatingFree.alreadyActive'));
        } else {
          setError(t('common:checkout.activatingFree.activationError'));
        }
      } finally {
        setIsSubmitting(false);
      }
    };

    setTimeout(orderFreeSubcription, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container">
      <h1 className="heading-1">
        {active
          ? t('common:checkout.activatingFree.headerActivated')
          : t('common:checkout.activatingFree.header')}
      </h1>
      <p className="text last-text">
        {active
          ? t('common:checkout.activatingFree.activated')
          : t('common:checkout.activatingFree.activating')}
      </p>

      {error.length > 0 ? (
        <>
          <Message text={error} type="error" />
          <Button
            actiontype="primary"
            onClick={() => {
              sendUserInteraction('activate free plan error back to homepage click');
              history.push('/');
            }}
          >
            {t('common:checkout.backToHomepage')}
          </Button>
        </>
      ) : isSubmitting ? (
        <div className="page-loader">
          <Loader inline />
        </div>
      ) : (
        <Button
          actiontype="primary"
          onClick={() => {
            sendUserInteraction('activate free plan back to homepage click');
            history.push('/');
          }}
        >
          {t('common:checkout.backToHomepage')}
        </Button>
      )}
    </section>
  );
};

export default ActivateFreePlan;
