import { zindexPopperModifier } from 'constants/general';
import React from 'react';
import { Popup } from 'semantic-ui-react';

const TruncatedHeaderCell = ({ text }) => {
  return (
    <Popup
      position="bottom center"
      inverted
      trigger={<span className="truncated-text">{text}</span>}
      content={text}
      popperModifiers={[zindexPopperModifier]}
    />
  );
};

export default TruncatedHeaderCell;
