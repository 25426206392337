import React from 'react';
import { classnames } from 'utils/base.utils';
import Loader from '../../common/Loader/Loader';

// Styles
import './style.scss';

const LoadingPane = ({ height = '80vh', active = true, className }) => {
  const classes = classnames(className, 'loading-pane', { active });

  return (
    <div
      className={classes}
      style={{
        '--loading-pane-height': height,
      }}
    >
      <Loader />
      <div className="background"></div>
    </div>
  );
};

export default LoadingPane;
