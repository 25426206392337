import { Dropdown, Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  margin-left: 10px !important;
`;

export const FilterDropdown = styled(Dropdown)`
  margin-left: 10px !important;
`;
