import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { hasNumber, hasSymbol, hasMinLength } from '../../../utils/string.utils';

const Container = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  list-style: none;
  padding: 0 0 0 30px;
  margin: 8px 0 0 0;
`;

export const StyledLi = styled.li`
  text-align: left;
  color: ${({ valid, theme }) => (valid ? theme.colors.black : theme.colors.grey)};
  transition: color 0.3s ease-in-out;
  font-size: 13px;
  list-style: none;
  font-family: 'DM Sans', sans-serif;

  &::before {
    color: ${({ valid, theme }) => (valid ? '#32bf73' : theme.colors.grey)};
    font-weight: bold;
    content: '• ';
    display: inline-block;
    width: 0.8em;
    margin-left: -1em;
    transform: scale(2);
    transition: color 0.3s ease-in-out;
  }
`;

const getChecks = (password, extraChecks) => [
  ...extraChecks,
  ['common:signUp.errors.password.minCharacters', hasMinLength(password, 8), 'password-min-length-indicator'],
  [
    'common:signUp.errors.password.numberOrSymbol',
    hasNumber(password) || hasSymbol(password),
    'password-has-number-indicator',
  ],
];

// in parent, wrap setIsValid in a useCallback to prevent an infinite loop
const PasswordValidator = ({ password, extraChecks = [], setIsValid = () => {}, className, style }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const checks = getChecks(password, extraChecks);
    setIsValid(checks.map((check) => check[1]).every((check) => check === true));
  }, [password, extraChecks, setIsValid]);

  const checks = getChecks(password, extraChecks);
  return (
    <Container className={className} style={style}>
      {checks.map(([check, valid, cypressID]) => (
        <StyledLi valid={valid} key={check} data-cy={cypressID} data-valid={valid}>
          {t(check)}
        </StyledLi>
      ))}
    </Container>
  );
};

export default memo(PasswordValidator);
