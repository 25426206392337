import { Icon } from 'components/common/Icon/Icon';
import Table from 'components/common/Table/Table';
import React from 'react';

import './style.scss';

const AddLanguagePairRow = ({ onClick = () => {}, colSpan = 6 }) => {
  return (
    <Table.Row className="add-language-pair-row">
      <td colSpan={colSpan}>
        <button className="button-reset" onClick={onClick}>
          <Icon name="plus" />
          <span>Add additional language pair</span>
        </button>
      </td>
    </Table.Row>
  );
};

export default AddLanguagePairRow;
