import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Loader from '../common/Loader/Loader';
import { Container } from './PrivateRoute.styles';

import PrivateWrapper from '../PrivateWrapper/PrivateWrapper';

export default ({
  allowedRoles,
  component: Component,
  isAuthenticated,
  isAuthenticating,
  role,
  isFullscreen = false,
  noWrapper,
  pageTitle = 'Taia',
  ...rest
}) => {
  // TODO handle case where user role is not allowed on /project on login

  const routeProps = {
    isFullscreen,
    pageTitle,
    noWrapper,
  };
  return (
    <Route {...rest}>
      <Container>
        {isAuthenticating ? (
          <Loader />
        ) : isAuthenticated && allowedRoles.some((allowedRole) => allowedRole === role) ? (
          <PrivateWrapper {...routeProps}>
            <Component />
          </PrivateWrapper>
        ) : (
          <Redirect to="/" />
        )}
      </Container>
    </Route>
  );
};
