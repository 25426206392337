import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 14px;
  font-weight: 700;
`;

export const JobPrice = styled.span`
  font-weight: 700;
`;

export const JobTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
`;
