import React from 'react';
import styled from 'styled-components';

const Disclaimer = styled.div`
  font-size: 11px;
`;

const RecaptchaDisclaimer = () => {
  return (
    <Disclaimer>
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </Disclaimer>
  );
};

export default RecaptchaDisclaimer;
