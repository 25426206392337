import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { hasAdminPermissions } from 'utils/user.utils';

const PaymentTypeDrowpown = ({ value, onChange, verified }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const paymentTypeOptions = useMemo(
    () => [
      {
        key: 'card',
        text: t('common:checkout.payment.cardType'),
        value: 'card',
      },
      {
        key: 'quote',
        text: hasAdminPermissions(user.role)
          ? t('common:checkout.payment.adminType')
          : verified
          ? t('common:checkout.payment.advanceOrder')
          : t('common:checkout.payment.proformaType'),
        value: 'quote',
      },
    ],
    [t, user, verified],
  );

  /*verified ||  ? (
      <>
        <Separator />
        <div className="verified-user">
          {verified
            ? t('common:checkout.orderSummary.price.verified')
            : t('common:checkout.orderSummary.price.admin')}
          <Button
            className="confirm-advance"
            actiontype="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            fluid
            onClick={handleSubmit}
          >
            {t('common:checkout.orderSummary.price.confirmAdvance')}
          </Button>
        </div>
      </>
    ) : null;*/

  const handleChange = (e, target) => {
    onChange(target.value);
  };
  return <Dropdown selection value={value} options={paymentTypeOptions} onChange={handleChange}></Dropdown>;
};

export default PaymentTypeDrowpown;
