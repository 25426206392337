import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Message from '../../common/Message/Message';
import Button from '../../common/Button/Button';
import LinkBack from '../../common/LinkBack/LinkBack';
import CreditCardForm from './CreditCardForm/CreditCardForm';
import PaymentForm from './PaymentForm/PaymentForm';
import PaymentMethodsSelect from './PaymentMethodsSelect/PaymentMethodsSelect';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getSetupIntent, postPaymentMethod } from '../../../services/billing';
import { useSelector } from 'react-redux';
import PaymentTypeDrowpown from './PaymentTypeDropdown/PaymentTypeDropdown';
import { hasAdminPermissions } from 'utils/user.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut } from 'constants/animation';
import { Checkbox } from 'components/common/Checkbox/Checkbox';

// Styles
import './style.scss';

const Payment = ({
  type = 'package',
  billing = {},
  paymentInfo = {},
  onContinue = () => {},
  paymentMethods = [],
  paymentMethodId,
  canAddPayment = true,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const countries = useSelector((state) => state.classifiersStore.countries);
  const user = useSelector((state) => state.userStore.user);

  const [showForm, setShowForm] = useState(
    paymentInfo.hasOwnProperty('usingBilling') ? paymentInfo.usingBilling : true,
  );

  const [saveCard, setSaveCard] = useState(true);
  const onlyProformaOption = useMemo(
    () => !canAddPayment && paymentMethods.length === 0 && type === 'project',
    [canAddPayment, paymentMethods, type],
  );

  const [payment, setPayment] = useState({
    type: onlyProformaOption ? 'quote' : paymentInfo.type ? paymentInfo.type : 'card',
    usingBilling: paymentInfo.hasOwnProperty('usingBilling') ? paymentInfo.usingBilling : true,
    name: paymentInfo.name || billing.company_name || '',
    address: paymentInfo.address || billing.line1 || '',
    address2: paymentInfo.address2 || billing.line2 || '',
    city: paymentInfo.city || billing.city || '',
    postalCode: paymentInfo.postalCode || billing.postal_code || '',
    countryId: paymentInfo.countryId || billing.country_id || 1,
    state: paymentInfo.state || billing.state || '',
  });

  const [selectedPayment, setSelectedPayment] = useState(-1);

  useEffect(() => {
    if (paymentMethodId === 'quote') {
      setSelectedPayment(-1);
    } else if (paymentMethodId) {
      const selected = paymentMethods.findIndex((method) => method.id === paymentMethodId);
      setSelectedPayment(selected === -1 ? 0 : selected);
    } else {
      setSelectedPayment(paymentMethods.length === 0 ? -1 : 0);
    }
  }, [paymentMethods, paymentMethodId, payment.type]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardError, setCardError] = useState(null);

  const onPaymentChange = ({ name, value }) => {
    const newPayment = { ...payment };
    newPayment[name] = value;
    setPayment(newPayment);
  };

  const handleSubmit = async (e) => {
    sendUserInteraction('clicked submit payment method');
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    try {
      setIsSubmitting(true);
      setCardError(null);
      if (selectedPayment === -1 && payment.type === 'card') {
        const cardElement = elements.getElement(CardElement);

        const country = countries.find((c) => c.id === payment.countryId);

        let paymentMethod = null;

        if (saveCard) {
          const intent = await getSetupIntent(billing.id);
          paymentMethod = await stripe.confirmCardSetup(intent.client_secret, {
            payment_method: {
              type: 'card',
              card: cardElement,
              billing_details: {
                name: payment.name,
                email: billing.email || null,
                address: {
                  line1: payment.address,
                  line2: payment.address2,
                  state: payment.state,
                  city: payment.city,
                  country: country.iso_code,
                  postal_code: payment.postalCode,
                },
              },
            },
          });
        } else {
          // Payment method creation for normal use (May be needed for Projects)
          paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: payment.name,
              email: billing.email || null,
              address: {
                line1: payment.address,
                line2: payment.address2,
                state: payment.state,
                city: payment.city,
                country: country.iso_code,
                postal_code: payment.postalCode,
              },
            },
          });
        }

        if (paymentMethod.hasOwnProperty('error')) {
          setCardError(paymentMethod.error);
        } else {
          if (saveCard) {
            await postPaymentMethod({
              billing_id: billing.id,
              payment_method: paymentMethod.setupIntent.payment_method,
            });
            onContinue({
              payment,
              paymentMethod: paymentMethod.setupIntent.payment_method,
              newPaymentMethod: true,
            });
          } else {
            onContinue({
              payment,
              paymentMethod: paymentMethod.paymentMethod.id,
              newPaymentMethod: false,
            });
          }
        }
      } else {
        if (payment.type === 'card') {
          onContinue({
            payment,
            paymentMethod: paymentMethods[selectedPayment].id,
            newPaymentMethod: false,
          });
        } else {
          onContinue({
            payment,
            paymentMethod: 'quote',
            newPaymentMethod: false,
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting();
    }
  };

  const isValid = () => {
    return (
      !!stripe &&
      !!elements &&
      !!payment.name &&
      !!payment.address &&
      !!payment.postalCode &&
      !!payment.city &&
      !!payment.countryId
    );
  };

  const onBillingToggle = (e, { checked }) => {
    sendUserInteraction(`use billing details as card details: ${checked}`);
    const newPayment = { ...payment };
    newPayment.usingBilling = checked;
    setPayment(newPayment);
    setShowForm(checked);
  };

  const onSaveCardToggle = (e, { checked }) => {
    sendUserInteraction(`save card info toggle: ${checked}`);
    setSaveCard(checked);
  };

  const changeSelectedPayment = (idx) => {
    sendUserInteraction('changed selected payment');
    setSelectedPayment(idx);
  };

  const onPaymentTypeChange = (type) => {
    sendUserInteraction(`changed payment type: ${type}`);
    const newPayment = { ...payment, type };
    setPayment(newPayment);
  };

  // Checks
  const displayPaymentSelection = type === 'project' && user.role !== 10 && !onlyProformaOption;

  // Props
  const fadeInProps = {
    variants: { ...fadeInOut.base },
    initial: 'initial',
    animate: 'animate',
    exit: 'exit',
  };

  return (
    <div className="checkout-payment-step">
      <LinkBack text={t('common:checkout.payment.link')}></LinkBack>
      <h1>{t('common:checkout.payment.header')}</h1>

      {displayPaymentSelection && (
        <div className="checkout-payment-step__type-select">
          <p className="label">{t('common:checkout.payment.subHeader')}</p>
          <PaymentTypeDrowpown
            value={payment.type}
            onChange={onPaymentTypeChange}
            verified={billing.verified}
          ></PaymentTypeDrowpown>
        </div>
      )}

      <AnimatePresence exitBeforeEnter>
        {payment.type === 'card' && (
          <motion.section {...fadeInProps} key="payment-method-card">
            <h2 className="heading-3">{t('common:checkout.payment.viaCardDesc')}</h2>

            {/** List of current credit cards */}
            {paymentMethods.length > 0 && (
              <PaymentMethodsSelect
                selected={selectedPayment}
                paymentMethods={paymentMethods}
                onSelectChange={changeSelectedPayment}
                canAddPayment={canAddPayment}
              />
            )}

            {/** Add new credit card */}
            <AnimatePresence>
              {selectedPayment === -1 && (
                <motion.div {...fadeInProps} className="credit-card-info">
                  {paymentMethods.length > 0 && (
                    <h2 className="heading-3">{t('common:checkout.payment.creditCardInfo')}</h2>
                  )}

                  <CreditCardForm postalCode={paymentInfo.postalCode} />
                  {type === 'project' && (
                    <Checkbox
                      toggle
                      label={t('common:checkout.payment.saveCard')}
                      checked={saveCard}
                      onChange={onSaveCardToggle}
                    />
                  )}
                  <Checkbox
                    toggle
                    label={t('common:checkout.payment.useBilling')}
                    checked={showForm}
                    onChange={onBillingToggle}
                    cypressID="payment-show-billing-toggle"
                  />

                  {!showForm && (
                    <>
                      <h2 className="heading-3">{t('common:checkout.payment.paymentInfo')}</h2>
                      <PaymentForm payment={payment} onPaymentChange={onPaymentChange} />
                    </>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.section>
        )}

        {payment.type === 'quote' && (
          <motion.section {...fadeInProps} key="payment-method-quote">
            <h2 className="heading-3">
              {hasAdminPermissions(user.role)
                ? t('common:checkout.payment.adminOrder')
                : billing.verified
                ? t('common:checkout.payment.orderInAdvance')
                : t('common:checkout.payment.viaQuote')}
            </h2>
            <p>
              {hasAdminPermissions(user.role)
                ? t('common:checkout.payment.adminOrderDesc')
                : billing.verified
                ? t('common:checkout.payment.orderInAdvanceDesc')
                : t('common:checkout.payment.viaQuoteDesc')}
            </p>
          </motion.section>
        )}
      </AnimatePresence>
      {cardError && <Message text={cardError.message} type="error" />}
      <div className="continue-button">
        <Button
          loading={isSubmitting}
          actiontype="primary"
          disabled={(!isValid() && payment.type === 'card') || isSubmitting}
          onClick={handleSubmit}
          data-cy="checkout-continue-button"
        >
          {t('common:continue')}
        </Button>
      </div>
    </div>
  );
};

export default Payment;
