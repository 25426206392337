import httpClient from './httpclient';

export const getProducts = async () => {
  try {
    const { data } = await httpClient.get(`/auth/products`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getProduct = async (id) => {
  try {
    const { data } = await httpClient.get(`/auth/products/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPlan = async (id) => {
  try {
    const { data } = await httpClient.get(`/auth/plans/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postProduct = async ({ name, word_limit, privatePlan, active = 0 }) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/products`, {
      name,
      word_limit,
      private: privatePlan ? 1 : 0,
      active,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postPlanBatch = async ({ product_id, plans }) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/products/${product_id}/batch-plans`, { plans });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postPlan = async ({ product_id, name, amount, interval, currency, active = 1 }) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/plans`, {
      product_id,
      name,
      amount,
      interval,
      currency,
      active,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const updateProduct = async ({ id, name, word_limit, active, seat_limit, privatePlan }) => {
  try {
    const { data } = await httpClient.patch(`/auth/admin/products/${id}`, {
      name,
      word_limit,
      active,
      seat_limit,
      private: privatePlan ? 1 : 0,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteProduct = async (id) => {
  try {
    const { data } = await httpClient.delete(`/auth/admin/products/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const deletePlan = async (id) => {
  try {
    const { data } = await httpClient.delete(`/auth/admin/plans/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPlanPricing = async ({ id, billing_detail_id, quantity }) => {
  try {
    const { data } = await httpClient.get(`/auth/plans/${id}/price`, {
      params: {
        billing_detail_id,
        quantity,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPlanSwapData = async ({ quantity, plan_id, subscription_id }) => {
  try {
    const { data } = await httpClient.get(`/auth/subscriptions/${subscription_id}/swap-preview`, {
      params: {
        quantity,
        plan_id,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postSwapSubscription = async ({ quantity, payment_method, plan_id, subscription_id }) => {
  try {
    const { data } = await httpClient.post(`auth/subscriptions/${subscription_id}/swap`, {
      quantity,
      payment_method,
      plan_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

// /auth/users/{id}/free-subscriptions
export const postFreeSubscription = async (user_id) => {
  try {
    const { data } = await httpClient.post(`/auth/users/${user_id}/free-subscriptions`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getBillingSubscriptions = async ({
  billing_id,
  current_page = 1,
  per_page = 100000,
  active = false,
}) => {
  try {
    const { data } = await httpClient.get(`/auth/billing/${billing_id}/subscriptions`, {
      current_page,
      per_page,
    });

    if (active) {
      data.data = data.data.filter((s) => s.stripe_status === 'active');
    }

    return data;
  } catch (e) {
    throw e;
  }
};

export const getSubscriptionById = async (subscription_id) => {
  try {
    const { data } = await httpClient.get(`/auth/subscriptions/${subscription_id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postCancelSubscription = async (subscription_id) => {
  try {
    const { data } = await httpClient.post(`/auth/subscriptions/${subscription_id}/cancel`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPossibleSubscriptionUsers = async (subscription_id) => {
  try {
    const { data } = await httpClient.get(`/auth/subscriptions/${subscription_id}/available-users`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const patchSubscriptionUser = async ({ seatId, userId = null }) => {
  try {
    const { data } = await httpClient.patch(`/auth/subscription-seats/${seatId}`, {
      user_id: userId,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getBillingPaymentIntents = async ({ billing_id, per_page = 1000000, page = 1 }) => {
  try {
    const { data } = await httpClient.get(`/auth/billing/${billing_id}/payment-intents`, {
      params: {
        page,
        per_page,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getUsageHistoryTimeframes = async (subscription_id) => {
  try {
    const { data } = await httpClient.get(`/auth/subscriptions/${subscription_id}/usage-history-timeframes`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getBillingPaymentInvoice = async ({ billing_id, payment_intent_id }) => {
  try {
    const response = await httpClient.get(
      `auth/billing/${billing_id}/payment-intents/${payment_intent_id}/invoice`,
      {
        responseType: 'arraybuffer',
      },
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export const getUsageHistory = async ({ per_page = 1000000, page = 1, end_date, subscription_id }) => {
  try {
    const { data } = await httpClient.get(`/auth/subscriptions/${subscription_id}/usage-history`, {
      params: {
        per_page,
        page,
        end_date,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const adminGetCatSubscriptions = async (requestParams = {}) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/catapult-subscriptions`, {
      params: { ...requestParams },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const adminGetFreeSubscriptions = async (requestParams = {}) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/free-subscriptions`, {
      params: { ...requestParams },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const adminGetLegacySubscriptions = async (requestParams = {}) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/legacy-subscriptions`, {
      params: { ...requestParams },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const patchFreePlanLimit = async ({ id, word_limit }) => {
  try {
    const { data } = await httpClient.patch(`/auth/admin/free-subscriptions/${id}`, {
      word_limit,
    });
    return data;
  } catch (e) {
    throw e;
  }
};
