import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';
import Loader from '../../common/Loader/Loader';
import { useDebounce } from '../../../hoc/debouncer';
import { postSubscription } from '../../../services/billing';
import {
  getProjectPrices,
  postConfirmProjectCharge,
  postOrderProject,
  postProjectCharge,
  postProjectPaycheck,
} from '../../../services/project';
import { getPlanPricing, getPlanSwapData, postSwapSubscription } from '../../../services/subscriptions';
import LinkBack from '../../common/LinkBack/LinkBack';
import { OrderSummaryWrapper } from './OrderSummary.style';
import PriceSummary from './PriceSummary/PriceSummary';
import Products from './Products/Products';
import { getJobPrice, getShownServices, hasJobServices, resolveSelectedService } from 'utils/jobs.utils';
import { sendGaPurchase, sendUserInteraction } from 'utils/tagManager.utils';
import { getActiveStripeSubscription } from 'utils/catapult.utils';
import { convertPrice } from 'utils/string.utils';
import Message from 'components/common/Message/Message';

const OrderSummary = ({
  customer = {},
  subscriptions = [],
  type = 'package',
  plan = {},
  project = {},
  quantity,
  paymentMethod,
  paymentType,
  billing,
  onQuantityChange = () => {},
  onContinue = () => {},
  onPaymentError = () => {},
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [price, setPrice] = useState(null);
  const [priceLoading, setPriceLoading] = useState(false);
  // const subscriptions = useSelector((state) => state.subscriptionsStore.subscriptions);
  const user = useSelector((state) => state.userStore.user);
  const stripe = useStripe();
  const { debounce } = useDebounce();

  const shouldSwap = useMemo(() => {
    // we only check for Stripe subscriptions here since we are only swapping Stripe subscriptions
    return getActiveStripeSubscription({ subscriptions, billingId: billing.id });
  }, [billing.id, subscriptions]);

  const deductBalance = useMemo(() => {
    if (price) {
      return price.user_balance > 0;
    }
    return false;
  }, [price]);

  const currency = useMemo(() => {
    return !!price?.new_plan ? price?.new_plan?.currency_rel : price?.currency;
  }, [price]);

  useEffect(() => {
    const fetchPrice = async () => {
      setPriceLoading(true);
      debounce(
        'loadingPrice',
        async () => {
          try {
            let price = {};
            if (type === 'package') {
              price = await getPlanPricing({
                id: plan.id,
                billing_detail_id: billing.id,
                quantity: quantity,
              });
            } else {
              price = await getProjectPrices(project);
            }
            setPrice(price);
          } catch (e) {
            setError(e?.response?.data ? e.response.data : e.message);
          } finally {
            setPriceLoading(false);
          }
        },
        400,
      );
    };

    const fetchSwapData = async () => {
      setPriceLoading(true);
      debounce(
        'loadingSwapData',
        async () => {
          try {
            const activeSubscription = getActiveStripeSubscription({ subscriptions, billingId: billing.id });

            const swapData = await getPlanSwapData({
              quantity,
              plan_id: plan?.id,
              subscription_id: activeSubscription?.id,
            });

            // when swapping plans we can take the new plan currency as the checkout currency
            swapData.currency = swapData?.new_plan?.currency_rel;

            setPrice(swapData);
          } catch (e) {
            setError(e?.response?.data ? e.response.data : e.message);
          } finally {
            setPriceLoading(false);
          }
        },
        400,
      );
    };

    if (!!shouldSwap && type === 'package') {
      fetchSwapData();
    } else {
      fetchPrice();
    }
  }, [shouldSwap, billing, quantity, plan, project, type, debounce, subscriptions]);

  const products = useMemo(() => {
    if (type === 'package') {
      return [
        {
          id: plan.id,
          name: plan.name,
          subname: 'Subscription',
          price: !!price ? (!!price?.new_plan ? price?.new_plan?.amount : price?.product_price) : 0,
          currency: currency,
          interval: plan.interval,
          quantity: quantity,
          wordLimit: plan.wordLimit,
          priceDescription: price?.new_plan?.description,
          swapPrice: price?.new_plan?.swap_price,
        },
      ];
    } else {
      let prods = null;
      if (hasJobServices(project.jobs)) {
        prods = project.jobs.map((job) => {
          const selectedServices = getShownServices(job);

          // filter out DTP
          const serviceId = selectedServices.filter((service) => {
            return service.service_type_id !== 7;
          })[0]['service_type_id'];
          const jobName = t('common:checkout.orderSummary.services.service' + serviceId);

          const jobPrice = selectedServices.reduce((accumulator, service) => {
            return accumulator + service.net_price;
          }, 0);
          return {
            id: job.id,
            name: jobName,
            service: serviceId,
            subname: job.target_language.name,
            sourceLang: job.source_language.name,
            targetLang: job.target_language.name,
            price: jobPrice,
            currency: currency,
            totalWords: job.total_words,
          };
        });
      } else {
        prods = project.jobs.map((job) => {
          if (job.proofread) {
            const jobName = t('common:checkout.orderSummary.services.proofread');

            const jobPrice = job.proofread_price;
            const jobPPW = job.proofread_per_word;

            return {
              id: job.id,
              name: jobName,
              service: 'proofread',
              subname: job.target_language.name,
              sourceLang: job.source_language.name,
              targetLang: job.target_language.name,
              price: jobPrice,
              pricePerWord: jobPPW,
              payableWords: job.payable_words,
              currency: currency,
              totalWords: job.total_words,
            };
          } else {
            const selectedService = resolveSelectedService(job);
            const jobName = t('common:checkout.orderSummary.services.service' + selectedService);

            const jobPrice = getJobPrice(job);

            return {
              id: job.id,
              name: jobName,
              service: selectedService,
              subname: job.target_language.name,
              sourceLang: job.source_language.name,
              targetLang: job.target_language.name,
              price: jobPrice,
              currency: currency,
            };
          }
        });
      }

      return prods;
    }
  }, [type, plan, quantity, price, currency, t, project]);

  const currentProduct = useMemo(() => {
    if (type === 'package') {
      if (!!user.free_subscription) {
        return {
          id: user?.free_subscription?.id,
          name: t('common:catapult.packages.freePlan'),
          subname: 'Subscription',
          price: 0,
          quantity: 1,
          wordLimit: user?.free_subscription?.word_limit,
        };
      }

      if (shouldSwap) {
        const planData = {
          id: price?.current_plan?.id,
          name: price?.current_plan?.product?.name,
          subname: 'Subscription',
          price: price?.current_plan?.amount,
          priceDescription: price?.current_plan?.description,
          currency: price?.current_plan?.currency_rel,
          interval: price?.current_plan?.interval,
          quantity: price?.current_quantity,
          wordLimit: price?.current_word_limit,
          swapPrice: price?.current_plan?.swap_price,
        };

        return planData;
      }
      return null;
    }
    return null;
  }, [price, shouldSwap, t, type, user]);

  const fetchPrice = async () => {
    try {
      let price = {};
      if (type === 'package') {
        price = await getPlanPricing({
          id: plan.id,
          billing_detail_id: billing.id,
          quantity: quantity,
        });
      } else {
        price = await getProjectPrices(project);
      }
      setPrice(price);
    } catch (e) {
      setError(e?.response?.data ? e.response.data : e.message);
    }
  };

  const onCoupon = () => {
    fetchPrice();
  };

  // handleSubmit currently only handles GA purchase event for ordering projects and catapult subscriptions. In case we add new products in the future this needs to be handled in the sendGaPurchase function
  const handleSubmit = async () => {
    try {
      if (type === 'package') {
        let subs = [];
        // use case where we need to swap subscriptions instead of post a new one
        // we check for currenctProduct.price in order to handle both use cases with no currentProduct and free current product
        if (shouldSwap) {
          const activeSubscription = getActiveStripeSubscription({ subscriptions, billingId: billing.id });

          sendUserInteraction('ordered subscription swap');
          subs = await postSwapSubscription({
            subscription_id: activeSubscription?.id,
            payment_method: paymentMethod,
            plan_id: products[0].id,
            quantity: products[0].quantity,
          });
        } else {
          sendUserInteraction('ordered subscription');
          subs = await postSubscription({
            billing_id: billing.id,
            payment_method: paymentMethod,
            plan_id: products[0].id,
            quantity: products[0].quantity,
          });
        }

        if (subs.object === 'payment_intent') {
          const pay = await stripe.confirmCardPayment(subs.client_secret, {
            payment_method: subs.payment_method,
          });

          if (pay.error) {
            history.push(`/payment-intent/${subs.id}`);
          } else {
            sendGaPurchase({
              billingId: billing.id,
              page: 'catapult-signup-completed',
              products,
              type: 'package',
              price,
            });
            onContinue();
          }
        } else {
          sendGaPurchase({
            billingId: billing.id,
            page: 'catapult-signup-completed',
            products,
            type: 'package',
            price,
          });
          onContinue();
        }
      } else {
        sendUserInteraction('ordered project');
        const delivery_option = !!project.is_delivery ? project.is_delivery : 50;

        let paycheck = null;
        try {
          paycheck = await postProjectPaycheck({
            calculation: true,
            comment: project.comment,
            diy: project.diy === 1,
            deliveryOption: delivery_option,
            deductBalance: deductBalance,
            jobs: project.jobs,
            projectId: project.id,
          });
        } catch (e) {
          if (e?.response.status === 422) {
            setError('delivery-expired');
            setPriceLoading(false);
            return;
          } else {
            throw e;
          }
        }

        const requiresPayment =
          paymentType === 'quote'
            ? false
            : deductBalance
            ? paycheck.hasOwnProperty('requires_card_payment')
              ? paycheck.requires_card_payment
              : true
            : true;

        if (requiresPayment) {
          const intent = await postProjectCharge({
            project_id: project.id,
            deduct_balance: deductBalance,
            payment_method: paymentMethod,
          });

          const pay = await stripe.confirmCardPayment(intent.client_secret, {
            payment_method: intent.payment_method,
          });

          if (pay.error) {
            history.push(`/payment-intent/${intent.id}`);
          } else {
            await postConfirmProjectCharge({ project_id: project.id, payment_intent: intent.id });
            await postProjectPaycheck({
              calculation: false,
              comment: project.comment,
              diy: project.diy === 1,
              deliveryOption: delivery_option,
              deductBalance: deductBalance,
              jobs: project.jobs,
              projectId: project.id,
            });

            sendGaPurchase({
              billingId: billing.id,
              page: 'taia-project-ordered',
              products,
              type: 'project',
              price,
              project,
            });
            onContinue();
          }
        } else {
          await postProjectPaycheck({
            calculation: false,
            comment: project.comment,
            diy: project.diy === 1,
            deliveryOption: delivery_option,
            deductBalance: deductBalance,
            jobs: project.jobs,
            projectId: project.id,
          });
          await postOrderProject({
            comment: project.comment,
            deductBalance: deductBalance,
            deliveryOption: project.is_delivery || 50,
            diy: project.diy === 1,
            jobs: project.jobs,
            projectId: project.id,
          });
          sendGaPurchase({
            billingId: billing.id,
            page: 'taia-project-ordered',
            products,
            type: 'project',
            price,
            project,
          });
          onContinue();
        }
      }
    } catch (e) {
      console.error(e);
      setError('general-error');
    }
  };

  const stripeBalance = useMemo(() => {
    const { balance } = customer;
    const currentBalance = Math.abs(balance);

    if (!!currentBalance && !!price) {
      const currencyBalance = currentBalance / 100;
      // gross price needs to be converted to correct currency since stripe saved balance in customer currency

      const finalPrice =
        type === 'package'
          ? price.total
          : deductBalance
          ? price.should_pay_vat
            ? price.gross_with_deducted_balance
            : price.net_with_deducted_balance
          : price.should_pay_vat
          ? price.gross_price
          : price.net_price;

      const grossPrice = convertPrice({
        currencyData: price?.currency,
        number: finalPrice,
      });

      const deduction = currencyBalance >= grossPrice ? grossPrice : currencyBalance;
      const balanceLeft = currencyBalance >= grossPrice ? currencyBalance - grossPrice : 0;

      const cardCharge = grossPrice - deduction;

      return {
        balanceLeft,
        cardCharge,
        deduction,
      };
    }

    // if we have no balance we have nothing to deduct
    return {
      balanceLeft: 0,
      cardCharge: !!price ? convertPrice({ currencyData: price?.currency, number: price?.gross_price }) : 0,
      deduction: 0,
    };
  }, [customer, deductBalance, price, type]);

  return (
    <OrderSummaryWrapper>
      <LinkBack text="Payment" />

      <>
        <div className="summary-header">
          <Header as="h1">{t('common:checkout.orderSummary.header')}</Header>
          <div className="sub-header-text">{t('common:checkout.orderSummary.subHeader')}</div>
        </div>
        {!!error ? (
          <Message header={t('common:checkout:orderSummary.error.heading')} type="error" text={error} />
        ) : (
          <div className="order">
            <div className="products">
              {price === null ? (
                <div className="price-loader">
                  <Loader inline />
                </div>
              ) : (
                <Products
                  currentProduct={currentProduct}
                  onQuantityChange={onQuantityChange}
                  products={products}
                  type={type}
                />
              )}
            </div>

            <div className="price">
              <PriceSummary
                currentProduct={currentProduct}
                products={products}
                verified={billing.verified}
                price={price}
                type={type}
                error={error}
                onSubmit={handleSubmit}
                delivery={{ price: price?.delivery_price, time: price?.delivery_time }}
                project={project}
                paymentMethod={paymentMethod}
                deductBalance={deductBalance}
                onCoupon={onCoupon}
                loading={priceLoading}
                paymentType={paymentType}
                stripeBalance={stripeBalance}
              />
            </div>
          </div>
        )}
      </>
    </OrderSummaryWrapper>
  );
};

export default OrderSummary;
