import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { Icon } from '../common/Icon/Icon';

export const AdminDashboardLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
`;

export const AdminDashboardItem = styled(Segment)`
  min-width: 320px;
  cursor: pointer;
  display: grid;
  justify-items: center;
  text-align: center;
  margin: 20px !important;
  padding: 20px !important;

  grid-template-rows: 100px 50px auto;

  &:hover {
    svg {
      color: #1898b1 !important;
    }
    h1 {
      color: #1898b1 !important;
    }
  }
`;

export const AdminItemIcon = styled(Icon)`
  svg {
    color: ${({ theme }) => theme.colors.blue};
    cursor: pointer;
    height: 80px;
    width: auto;
  }
`;

export const AdminItemHeader = styled.h1`
  color: ${({ theme }) => theme.colors.blue};
`;
