import React from 'react';
import { toast } from 'react-toastify';
import { uniqBy } from 'lodash';

import InviteNotification from '../components/InviteNotification/InviteNotification';

import { getBillingDetails, getUser } from '../services/auth';
import { getCompanyTeams } from '../services/company';
import { getBillingSubscriptions, getSubscriptionById, getProducts } from '../services/subscriptions';

import { addSubscriptions } from '../store/subscriptionsSlice';
import {
  setBillingGroups,
  setCountries,
  setCurrencies,
  setFreePlanLimit,
  setLanguages,
  setCategories,
  setProducts,
  setTimezones,
} from '../store/classifiersSlice';
import {
  setIsAuthenticated,
  setIsAuthenticating,
  setBillingDetails,
  setUser,
  setAdminUsers,
} from '../store/userSlice';

import store from '../store/store';
import { checkTeamRole } from 'utils/user.utils';
import { getStaticData } from '../services/classifiers';
import { sendGaVirtual } from '../utils/tagManager.utils';
import { hasAdminPermissions } from './user.utils';
import { getAdminUsers } from 'services/users';

export const getClassifiers = async () => {
  const {
    billing_groups,
    categories,
    countries,
    currencies,
    free_plan_word_limit,
    languages,
    timezones,
  } = await getStaticData();

  store.dispatch(setBillingGroups(billing_groups));
  store.dispatch(setCategories(categories));
  store.dispatch(setCountries(countries));
  store.dispatch(setCurrencies(currencies));
  store.dispatch(setLanguages(languages));
  store.dispatch(setTimezones(timezones));
  store.dispatch(setFreePlanLimit(free_plan_word_limit));
};

export const getTeamsSubscriptions = async (teams = []) => {
  try {
    let subscriptions = [];
    const teamsToLoad = uniqBy(teams, 'billing_detail_id').filter((team) => !!team.billing_detail_id);
    await Promise.all(
      teamsToLoad.map(async (team) => {
        const teamSubscriptions = await getBillingSubscriptions({ billing_id: team.billing_detail_id });
        subscriptions = [...subscriptions, ...teamSubscriptions.data];
      }),
    );
    return subscriptions;
  } catch (e) {
    throw e;
  }
};

export const getInitialUserData = async () => {
  try {
    store.dispatch(setIsAuthenticating({ isAuthenticating: true }));
    const userInfo = await getUser();

    const products = await getProducts();
    store.dispatch(setProducts(products));

    const {
      data: {
        company_owner_id,
        email,
        id,
        name,
        teams,
        billing_detail,
        free_subscription,
        legacy_subscription,
        role,
      },
    } = userInfo;

    // send user data to tag manager with initial getUser request
    sendGaVirtual({
      page: 'login-completed',
      userData: {
        email,
        id: id,
        name: name,
      },
    });

    store.dispatch(setUser({ user: userInfo.data }));

    /**
     * If admin, fetch admin users, used in assign managers to projects and companies
     */
    if (hasAdminPermissions(role)) {
      // Get admin users
      const { data } = await getAdminUsers();

      store.dispatch(setAdminUsers({ users: data }));
    }

    if (!!free_subscription) {
      store.dispatch(addSubscriptions([free_subscription]));
    }

    if (!!legacy_subscription) {
      store.dispatch(addSubscriptions([legacy_subscription]));
    }

    // if user is a company owner load all teams for that company, load all subscriptions for teams billing
    if (!!company_owner_id) {
      const companyTeams = await getCompanyTeams(company_owner_id);
      const subscriptions = await getTeamsSubscriptions(companyTeams);
      store.dispatch(addSubscriptions(subscriptions));
    }

    // else if user is a member of any team load all subscriptions for teams that have billing
    if (!company_owner_id) {
      const subscriptions = await getTeamsSubscriptions(teams);

      const subscriptionsDetails = await Promise.all(
        subscriptions.map(async (sub) => {
          const data = await getSubscriptionById(sub.id);
          return data;
        }),
      );

      // filter out subscriptions for which user role 42 has data
      const userSubscriptions = subscriptionsDetails.filter((sub) => {
        // get team related to this subscription
        const subscriptionTeam = teams.find((team) => team.billing_detail_id === sub.billing_detail_id);
        // billing managers and team managers can manage subscriptions with seats, full and limited members can only see seat usage data
        const { role_id } = subscriptionTeam;
        const isManager = checkTeamRole({ allowedRoles: [1, 4], teamRole: role_id });

        // if the user can manage subscription data, return teh data, if the user cannot manage subscription data only return the subscription if he has an active subscription seat
        return !!isManager ? true : !!sub.auth_user_data;
      });

      store.dispatch(addSubscriptions(userSubscriptions));
    }

    // else if user has billing details set, load data for those billing details
    if (billing_detail) {
      const billingDetails = await getBillingDetails(id);
      const billingSubscriptions = await getBillingSubscriptions({ billing_id: billingDetails.data.id });
      store.dispatch(setBillingDetails({ billingDetails: billingDetails.data }));
      store.dispatch(addSubscriptions(billingSubscriptions.data));
    } else {
      store.dispatch(setBillingDetails({ billingDetails: null }));
    }

    store.dispatch(setIsAuthenticated({ isAuthenticated: true }));
    store.dispatch(setIsAuthenticating({ isAuthenticating: false }));

    // if user role is not 1 (new user) show toast notifications for invites
    if (userInfo.data.role !== 1) {
      userInfo.data.invitations.forEach((invite) => {
        toast(<InviteNotification invite={invite} />, {
          toastId: invite.id,
          autoClose: false,
          closeOnClick: false,
        });
      });
    } else {
      // TODO: Redirect to onboarding
    }
  } catch (e) {
    throw e;
  }
};
