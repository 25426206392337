import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Tab from '../common/Tab/Tab';
import BasicInformation from './BasicInformation/BasicInformation';
import ReferralsFooter from './Referrals/ReferralsFooter';
import PasswordReset from './PasswordReset/PasswordReset';
import Invites from './Invites/Invites';
import { Number, UserProfileMainContainer } from './UserProfile.styles';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import Message from 'components/common/Message/Message';
import ProfessionalExperience from './Vendor/ProfessionalExperience/ProfessionalExperience';

import { checkAllowedRole } from 'utils/user.utils';
import { getUser, getBillingDetails } from '../../services/auth';
import { setUser, setBillingDetails } from '../../store/userSlice';
import UserBillingDetails from './UserBillingDetails/UserBillingDetails';
import { VENDOR_ROLES } from 'constants/roles';
import BecomeVendorBanner from 'components/common/BecomeVendorBanner/BecomeVendorBanner';
import VendorSettings from './Vendor/VendorSettings/VendorSettings';

// Styles
import './style.scss';
import VendorLanguageCombinations from './Vendor/VendorLanguageCombinations/VendorLanguageCombinations';
import { getVendorData } from 'services/vendors';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const [vendorData, setVendorData] = useState({});

  const invites = useSelector((state) => state.userStore.user.invitations);
  const role = useSelector((state) => state.userStore.user.role);
  const user = useSelector((state) => state.userStore.user);

  const handleTabChange = (_, { activeIndex }) => setCurrTabIndex(activeIndex);

  const panes = useMemo(() => {
    const isVendor = user.vendor;

    const handleInfoSubmit = async (d) => {
      try {
        const { data } = await getUser();
        dispatch(setUser({ user: data }));
      } catch (e) {
        console.error(e);
      }
    };

    return [
      {
        title: t('common:userInfo.tabs.basicInfo'),
        render: () => (
          <Tab.Pane className="user-basic-info__tab-pane">
            <BasicInformation user={user} onSubmit={handleInfoSubmit} />
          </Tab.Pane>
        ),
      },

      {
        title: t('common:userInfo.tabs.billingDetails'),
        render: () => (
          <Tab.Pane className="user-billing-details__tab-pane">
            <UserBillingDetails user={user} onChange={handleInfoSubmit} />
          </Tab.Pane>
        ),
      },

      {
        title: t('common:userInfo.tabs.changePassword'),
        render: () => (
          <Tab.Pane className="user-change-password__tab-pane">
            <PasswordReset />
          </Tab.Pane>
        ),
      },

      {
        title: (
          <>
            {t('common:userInfo.tabs.invitations')}
            <Number active={invites.length > 0}>{invites.length}</Number>
          </>
        ),
        render: () => (
          <Tab.Pane className="user-invitations__tab-pane">
            <Invites />
          </Tab.Pane>
        ),
      },
      ...(isVendor
        ? [
            {
              title: t('common:userInfo.tabs.vendorSettings'),
              render: () => (
                <Tab.Pane>
                  <VendorSettings
                    vendorData={vendorData}
                    setVendorData={setVendorData}
                    onChange={handleInfoSubmit}
                  />
                </Tab.Pane>
              ),
            },
            {
              title: t('common:userInfo.tabs.langCombinations'),
              render: () => (
                <Tab.Pane>
                  <VendorLanguageCombinations vendorData={vendorData} onCombinationsChange={setVendorData} />
                </Tab.Pane>
              ),
            },
            {
              title: 'Professional experience',
              render: () => (
                <Tab.Pane>
                  <ProfessionalExperience
                    vendorData={vendorData}
                    setVendorData={setVendorData}
                    onChange={handleInfoSubmit}
                  />
                </Tab.Pane>
              ),
            },
          ]
        : []),
    ];
  }, [dispatch, invites, t, user, vendorData, setVendorData]);

  useEffect(() => {
    const loadUserData = async () => {
      setIsLoading(true);

      try {
        const userData = await getUser();

        if (userData.data.billing_detail) {
          const billingDetails = await getBillingDetails(userData.data.id);
          dispatch(setBillingDetails({ billingDetails: billingDetails.data }));
        }

        dispatch(setUser({ user: userData.data }));

        // If user has vendor model, also fetch the vendor data
        if (userData.data.vendor) {
          const { data: vData } = await getVendorData(userData.data.vendor.id);
          setVendorData(vData);
        }
      } catch (e) {
        setError(true);
        throw e;
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();
    // only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showBecomeVendorBanner =
    checkAllowedRole({ allowedRoles: VENDOR_ROLES, userRole: user.role }) && !user.vendor;

  const showReferralsFooter = useMemo(
    () => checkAllowedRole({ allowedRoles: [10], userRole: role }) && currTabIndex === 0,
    [role, currTabIndex],
  );

  return (
    <UserProfileMainContainer className="user-profile" hasMobilePadding={false}>
      <h1 className="hide-on-mobile">{t('common:userInfo.myProfile')}</h1>
      {isLoading ? (
        <LoadingPane />
      ) : error ? (
        <Message text={t('common:userInfo.loadError')} type="error" />
      ) : (
        <Tab panes={panes} onTabChange={handleTabChange}>
          {showBecomeVendorBanner ? <BecomeVendorBanner /> : null}
          {showReferralsFooter && <ReferralsFooter />}
        </Tab>
      )}
    </UserProfileMainContainer>
  );
};

export default UserProfile;
