import React, { useEffect } from 'react';

import { useAnalysisStatus } from 'hooks/useAnalysisStatus';
import { postUpdateProject } from 'services/project';
import { getProject } from 'services/project';
import { getProjectJobs } from 'services/project';
import { getProjectLogs } from 'services/project';
import { updateProject } from 'store/projectsSlice';
import { sendAnalysisFailed } from 'utils/tagManager.utils';
import { sendChatNotification } from 'services/project';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { postFailedProject } from 'services/project';

const GetAnalysis = ({ project, children, reloadProjects = () => {} }) => {
  const { checkProjectStatus, summary } = useAnalysisStatus();

  const user = useSelector((state) => state.userStore.user);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleSuccess = async (updatePayload) => {
      await postUpdateProject(updatePayload, user);
      const projectData = await getProject(project.id);
      const projectJobs = await getProjectJobs(project.id);
      const logsResponse = await getProjectLogs({ projectId: project.id });

      const newProject = { ...projectData.data, jobs: projectJobs, logs: logsResponse.data };

      dispatch(updateProject({ project: newProject }));
    };

    const handleFailure = async (e) => {
      sendAnalysisFailed();

      try {
        await sendChatNotification({
          errorMessage: e.notificationText,
        });

        await postFailedProject(project.id);
      } catch (error) {
        console.error(error);
      } finally {
        toast.error(e.errorText);
        reloadProjects();
      }
    };

    const projectAnalyzed = async () => {
      try {
        const updatePayload = await checkProjectStatus(project);
        if (updatePayload.setup) {
          await handleSuccess(updatePayload);
        }
      } catch (e) {
        handleFailure(e);
      }
    };

    projectAnalyzed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      summary: { ...summary },
    });
  });

  return <React.Fragment>{newChildren}</React.Fragment>;
};

export default GetAnalysis;
