import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  width: 100%;
  .ui.checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
  }
`;
