import React from 'react';

import { TabContainer } from '../../UserProfile.styles';
import ProfessionalExperienceFormEdit from 'components/common/Vendor/Forms/ProfessionalExperienceFormEdit/ProfessionalExpriencesFormEdit';

// Styles
import './style.scss';

/**
 * Renders professional experience tab on user profile
 */
const ProfessionalExperience = ({ vendorData, setVendorData, onChange }) => {
  const handleSubmit = (data) => {
    onChange();

    if (data) setVendorData(data);
  };
  return (
    <TabContainer className="user-profile-tab-vendor-prof-exp">
      <ProfessionalExperienceFormEdit vendorData={vendorData} onSubmit={handleSubmit} />
    </TabContainer>
  );
};

export default ProfessionalExperience;
