import { Dropdown, Input } from 'semantic-ui-react';
import Pagination from '../common/Pagination/Pagination';
import styled from 'styled-components';

export const AdminInvoicesLayout = styled.div`
  position: relative;
  display: grid;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
`;

export const StyledPagination = styled(Pagination)`
  margin-right: auto !important;
`;

export const StyledDropdown = styled(Dropdown)`
  margin-left: auto;
  margin-right: 0.25em;
`;

export const StyledInput = styled(Input)`
  margin-right: 0.25em;
`;
