import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import Avatar from '../Avatar';
import Dropzone from 'react-dropzone';
import UserInfoForm from './UserInfoForm';
import { Modal, Header, Grid } from 'semantic-ui-react';
import Message from '../../Message/Message';
import { StyledSelectText, StyledDropzoneContainer, StyledLoader } from './EditUserModal.styles';

import { postSetPicture } from '../../../../services/auth';
import Button from '../../Button/Button';

import { sendUserInteraction } from 'utils/tagManager.utils';

const EditUserModal = ({ user, t, fetchUser, isAdmin, shareTm }) => {
  const [openState, setOpenState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAvatarError, setisAvatarError] = useState(false);

  const onDrop = async (pic) => {
    sendUserInteraction('admin upload user avatar');

    setIsLoading(true);
    setisAvatarError(false);
    try {
      await postSetPicture(pic[0], user.id);
      fetchUser();
    } catch (e) {
      // TODO handle error state here
      setisAvatarError(true);
      throw e;
    } finally {
      // setTimeout here in order to make the UX more smooth
      setIsLoading(false);
    }
  };

  const { avatar } = user;

  const handleClose = () => {
    fetchUser();
    setOpenState(false);
  };

  return (
    <Modal
      trigger={
        <Button onClick={() => sendUserInteraction('admin open edit user profile modal')} icon="pen" />
      }
      open={openState}
      onOpen={() => setOpenState(true)}
      onClose={() => setOpenState(false)}
    >
      <Header>{t('common:admin.user.editUserModal.title')}</Header>
      <Modal.Content>
        <Grid centered>
          <StyledDropzoneContainer>
            {isLoading ? (
              <StyledLoader active={true} inline={true} disabled={false} size="large" />
            ) : (
              <Dropzone
                accept={['image/jpeg', 'image/png', 'image/bmp', 'image/gif']}
                onDrop={onDrop}
                maxSize={1000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <Grid.Column textAlign="center" width={4}>
                      <Avatar url={avatar} style={{ margin: '0 auto' }} />
                      <input {...getInputProps()} />

                      <StyledSelectText>{t('common:userInfo.selectImage')}</StyledSelectText>
                    </Grid.Column>
                  </div>
                )}
              </Dropzone>
            )}
          </StyledDropzoneContainer>
          {isAvatarError && <Message text={t('userInfo.avatarError')} type="error" />}
          <Grid.Row>
            <Grid.Column>
              <UserInfoForm isAdmin={isAdmin} user={user} onClose={handleClose} shareTm={shareTm} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation('common')(EditUserModal);
