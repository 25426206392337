import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { DataRow, StyledLabel } from './CompanyCard.styles';
import { Segment } from 'semantic-ui-react';
import { sendUserInteraction } from 'utils/tagManager.utils';

const CompanyCard = ({ companyData, teamData }) => {
  const { t } = useTranslation();

  return companyData && teamData ? (
    <Segment>
      <DataRow>
        <StyledLabel>{`${t('common:projects.projectInfo.companyData.companyName')}:`}</StyledLabel>
        <Link
          onClick={() => sendUserInteraction('company card company name link click')}
          to={`/company/${companyData.id}`}
        >
          {companyData.company_name}
        </Link>
      </DataRow>
      <DataRow>
        <StyledLabel>{`${t('common:projects.projectInfo.companyData.companyDiscount')}:`}</StyledLabel>
        {`${companyData.comp_discount ? companyData.comp_discount : '0'} %`}
      </DataRow>
      <DataRow>
        <StyledLabel>{`${t('common:projects.projectInfo.companyData.teamName')}:`}</StyledLabel>
        {teamData.name}
      </DataRow>
    </Segment>
  ) : null;
};

export default CompanyCard;
