import styled, { css } from 'styled-components';

const inlineStyles = css`
  position: relative;
  margin: 0;
  left: 0;
  top: 0;
  transform: none;
`;

export const Container = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  text-align: center;

  ${({ inline }) => inline && inlineStyles}
`;

export const LoaderContainer = styled.div`
  margin-bottom: 7px;
  width: 75px;
  height: 75px;
`;
