import styled from 'styled-components';

export const TeamWrapper = styled.div`
  display: grid;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
  .team-header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    h2 {
      font-family: 'DM Sans', sans-serif;
      font-weight: 500;
      font-size: 2.14285714em;
      margin-bottom: 0px;
      margin-right: 10px;
    }
    .ui.segment {
      margin: 0 !important;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
`;

export const StyledLabel = styled.div`
  width: fit-content;
  padding: 6px 13px;
  background-color: #ededed;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.greyDark};
`;
