import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LinkTableCell from '../../../common/Table/LinkTableCell';
import Loader from '../../../common/Loader/Loader';
import Message from '../../../common/Message/Message';
import Pagination from '../../../common/Pagination/Pagination';
import Table from '../../../common/Table/Table';

import { LoaderContainer, PaginationContainer } from './FreeSubscriptions.styles';

import { adminGetLegacySubscriptions } from '../../../../services/subscriptions';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { formatAPIDateString } from 'utils/date.utils';

const LegacySubscriptions = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true); // component loads data on mount
  const [requestParams, setRequestParams] = useLocalStorage('legacy-subscriptions-params', {
    page: 1,
    per_page: 20,
  });
  const [lastPage, setLastPage] = useState(1);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setError(false);
      setLoading(true);
      try {
        const { data, last_page } = await adminGetLegacySubscriptions(requestParams);
        setSubscriptionsData(data);
        if (requestParams.page > last_page) {
          setRequestParams({ ...requestParams, page: last_page });
        }
        setLastPage(last_page);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, [requestParams, setRequestParams]);

  const handlePageChange = (e, { activePage }) => {
    sendUserInteraction('legacy subscriptions page change');
    e.preventDefault();
    const newParams = {
      ...requestParams,
      page: activePage,
    };
    setRequestParams(newParams);
  };

  return loading ? (
    <LoaderContainer>
      <Loader inline />
    </LoaderContainer>
  ) : error ? (
    <Message text={t('common:catapult.admin.paidSubscriptionsTable.error')} type="error" />
  ) : (
    <>
      <Table width="100%" marginTop="0">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>
              {t('common:catapult.admin.freeSubscriptionsTable.id')}
            </Table.HeaderCell>
            <Table.HeaderCell>{t('common:catapult.admin.freeSubscriptionsTable.customer')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:catapult.admin.freeSubscriptionsTable.createdAt')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {subscriptionsData.map((subscription) => {
            const { created_at, id, user } = subscription;

            return (
              <Table.Row key={id}>
                <Table.Cell>{id}</Table.Cell>
                <LinkTableCell
                  onClick={() => {
                    sendUserInteraction('legacy subscription user name click');
                  }}
                  text={user.name}
                  to={`/admin/user/${user.id}`}
                />
                <Table.Cell>{formatAPIDateString({ dateString: created_at, showTime: false })}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <PaginationContainer>
        {lastPage > 1 && (
          <Pagination activePage={requestParams.page} onPageChange={handlePageChange} totalPages={lastPage} />
        )}
      </PaginationContainer>
    </>
  );
};

export default LegacySubscriptions;
