import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LinkTableCell from '../../../common/Table/LinkTableCell';
import Loader from '../../../common/Loader/Loader';
import Message from '../../../common/Message/Message';
import Pagination from '../../../common/Pagination/Pagination';
import Table from '../../../common/Table/Table';

import { LoaderContainer, PaginationContainer } from './FreeSubscriptions.styles';

import { adminGetFreeSubscriptions, patchFreePlanLimit } from '../../../../services/subscriptions';
import Button from 'components/common/Button/Button';
import { toast } from 'react-toastify';
import { Input } from 'components/common/Input/Input';
import FilterSidebar from 'components/common/FilterSidebar/FilterSidebar';
import FreeSubscriptionsFilter from './FreeSubscriptionFilters';
import { useDebounce } from 'hoc/debouncer';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

import { formatAPIDateString } from 'utils/date.utils';

const FreeSubscriptionRow = ({ subscription = {}, onSubmit }) => {
  const { created_at, deleted_at, id, limit_used, user, word_limit } = subscription;
  const [editing, setEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wordLimit, setWordLimit] = useState(Number(word_limit));
  const { t } = useTranslation();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await patchFreePlanLimit({ id: id, word_limit: wordLimit });

      toast.success(t('common:toasts.updateFree.success'));
      onSubmit({ ...subscription, word_limit: wordLimit });
      setEditing(false);
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.updateFree.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Table.Row key={id}>
      <Table.Cell>{id}</Table.Cell>
      <LinkTableCell text={user.name} to={`/admin/user/${user.id}`} />
      <Table.Cell>
        {!!deleted_at
          ? t('common:catapult.admin.freeSubscriptionsTable.inactive')
          : t('common:catapult.admin.freeSubscriptionsTable.active')}
      </Table.Cell>
      <Table.Cell>{formatAPIDateString({ dateString: created_at, showTime: false })}</Table.Cell>
      <Table.Cell>{limit_used}</Table.Cell>
      <Table.Cell>
        {editing ? (
          <Input
            type="number"
            value={wordLimit}
            placeholder={t('common:catapult.admin.freeSubscriptionsTable.limit')}
            onChange={(e) => setWordLimit(e.target.value)}
          ></Input>
        ) : (
          word_limit
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {editing ? (
          <>
            <Button
              icon="times"
              actiontype="cancel"
              onClick={() => setEditing(false)}
              disabled={isSubmitting}
            />
            <Button
              icon="check"
              actiontype="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </>
        ) : (
          <Button icon="edit-alt" actiontype="secondary" onClick={() => setEditing(true)} />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const FreeSubscriptions = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true); // component loads data on mount
  const [requestParams, setRequestParams] = useLocalStorage('free-subscriptions-query', {
    page: 1,
    per_page: 20,
    search: '',
  });
  const [lastPage, setLastPage] = useState(1);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const { t } = useTranslation();
  const { debounce } = useDebounce();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setError(false);
      setLoading(true);
      debounce(
        'freeSubscriptionFilter',
        async () => {
          try {
            const { data, last_page } = await adminGetFreeSubscriptions(requestParams);
            setSubscriptionsData(data);
            if (requestParams.page > last_page) {
              setRequestParams({ ...requestParams, page: last_page });
            }
            setLastPage(last_page);
          } catch (e) {
            setError(true);
          } finally {
            setLoading(false);
          }
        },
        400,
      );
    };

    fetchSubscriptions();
  }, [requestParams, debounce, setRequestParams]);

  const handlePageChange = (e, { activePage }) => {
    sendUserInteraction(`free subscriptions page change ${activePage}`);
    e.preventDefault();
    const newParams = {
      ...requestParams,
      page: activePage,
    };
    setRequestParams(newParams);
  };

  const handleSubscriptionUpdate = (idx) => (subscription) => {
    const newSubscriptions = [...subscriptionsData];
    newSubscriptions[idx] = { ...subscription };
    setSubscriptionsData(newSubscriptions);
  };

  const updateRequestParams = (newParams) => {
    setRequestParams({ ...newParams });
  };

  return (
    <>
      <FilterSidebar width="calc(42.6ch + 1rem + 2rem + 14px)">
        <FreeSubscriptionsFilter requestParams={requestParams} updateRequestParams={updateRequestParams} />
      </FilterSidebar>
      {loading ? (
        <LoaderContainer>
          <Loader inline />
        </LoaderContainer>
      ) : error ? (
        <Message text={t('common:catapult.admin.paidSubscriptionsTable.error')} type="error" />
      ) : (
        <>
          <Table width="100%" marginTop="0">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('common:catapult.admin.freeSubscriptionsTable.id')}</Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:catapult.admin.freeSubscriptionsTable.customer')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:catapult.admin.freeSubscriptionsTable.status')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:catapult.admin.freeSubscriptionsTable.createdAt')}
                </Table.HeaderCell>
                <Table.HeaderCell>{t('common:catapult.admin.freeSubscriptionsTable.usage')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:catapult.admin.freeSubscriptionsTable.limit')}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {subscriptionsData.map((subscription, idx) => (
                <FreeSubscriptionRow
                  key={`subscription-${subscription.id}-row`}
                  subscription={subscription}
                  onSubmit={handleSubscriptionUpdate(idx)}
                />
              ))}
            </Table.Body>
          </Table>
          <PaginationContainer>
            {lastPage > 1 && (
              <Pagination
                activePage={requestParams.page}
                onPageChange={handlePageChange}
                totalPages={lastPage}
              />
            )}
          </PaginationContainer>
        </>
      )}
    </>
  );
};

export default FreeSubscriptions;
