import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Icon } from '../Icon/Icon';

import { getOriginalFiles } from '../../../services/project';
import Button from '../Button/Button';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { Popup } from 'semantic-ui-react';
import { resendActivationEmail } from 'utils/user.utils';
import styled from 'styled-components';

// Styles
const ResendLinkStyles = styled.button`
  background-color: transparent;
  border: none;
  display: inline;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: underline;
  cursor: pointer;
`;

const OriginalFilesDownload = ({ projectId, icon = true, buttonActionType = null }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadClick = async (e) => {
    sendUserInteraction('download original project files click');
    e.preventDefault();
    setIsLoading(true);

    try {
      // get file blob
      const response = await getOriginalFiles(projectId);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = (e) => {
    e.preventDefault();

    resendActivationEmail(user.email, t);
  };

  const disableDownload = !user.active;

  const popupTrigger = (
    <Button
      disabled={disableDownload || isLoading}
      download
      labelPosition={icon ? 'right' : null}
      loading={isLoading}
      onClick={handleDownloadClick}
      actiontype={buttonActionType}
    >
      {t('common:projects.projectInfo.originalFiles')}
      {icon && <Icon name="import" />}
    </Button>
  );

  return user.active ? (
    popupTrigger
  ) : (
    <Popup size="large" trigger={<span>{popupTrigger}</span>} hoverable>
      <Popup.Content>
        {t('common:projects.projectInfo.filesNotice')}
        <ResendLinkStyles onClick={handleResend}>
          {t('common:activateUser.emailVerification.resend')}
        </ResendLinkStyles>
      </Popup.Content>
    </Popup>
  );
};

export default OriginalFilesDownload;
