import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from './OrderProject.styles';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import Price from '../../common/Price/Price';
import { useTranslation } from 'react-i18next';

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.yellowLight};
  padding: 24px 20px;
  display: flex;
  column-gap: 1em;
  font-family: 'DM Sans';

  .checkbox {
    margin: auto 0;
  }

  .title {
    font-size: 1.28571429em;
    font-weight: 500;
  }

  .price {
    color: ${({ theme }) => theme.colors.yellow};
    font-size: 1.28571429em;
    font-weight: bold;
    margin-left: 0.5em;
  }

  .desc {
    color: ${({ theme }) => theme.colors.greyDark};
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin: 17px ${theme.paddings.mainContainer.mobile.x} 0 ${theme.paddings.mainContainer.mobile.x};

    .price {
      display: block;
      margin: 5px 0;
    }

    .checkbox {
      margin: initial;
    }
  `}
`;

const GraphicalEditing = ({ setStep, jobs, project, updateJobs = () => {} }) => {
  const price = jobs.reduce((acc, job) => acc + job.dtp_price, 0);

  const [dtpChecked, setDtpChecked] = useState(jobs.some((job) => job.dtp_selected === 1));
  const { t } = useTranslation();

  const handleChange = (e, { checked }) => {
    const newJobs = jobs.map((job) => {
      return { ...job, dtp_selected: checked ? 1 : 0 };
    });
    setDtpChecked(checked);
    updateJobs(newJobs);
  };

  return (
    <>
      <StyledCard data-cy="graphical-editing">
        <Checkbox
          cypressID="graphical-editing-checkbox"
          checked={dtpChecked}
          onChange={handleChange}
          size="large"
        />
        <div>
          <span className="title">{t('common:projects.graphicEditing')}</span>
          <span className="price">
            +<Price price={price} currency={project.currency} />
          </span>
          <div className="desc">{t('common:projects.graphicEditingDesc')}</div>
        </div>
      </StyledCard>
      <StyledButton
        className="next-step-btn"
        onClick={() => setStep((prev) => prev + 1)}
        actiontype="primary"
      >
        {t('common:projects.nextStep')}
      </StyledButton>
    </>
  );
};

export default GraphicalEditing;
