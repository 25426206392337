import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';

import { getTempProjectFiles } from '../../../services/project';
import Button from '../../common/Button/Button';
import { Icon } from '../../common/Icon/Icon';
import { sendUserInteraction } from 'utils/tagManager.utils';

const TemporaryFilesDownload = ({ projectId }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadClick = async (e) => {
    sendUserInteraction('temporary project files download');
    e.preventDefault();
    setIsLoading(true);

    try {
      // get file blob
      const response = await getTempProjectFiles(projectId);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const disableDownload = !user.active;

  return (
    <Button
      disabled={disableDownload || isLoading}
      download
      labelPosition="right"
      loading={isLoading}
      onClick={handleDownloadClick}
      style={{ whiteSpace: 'nowrap' }}
    >
      {t('common:projects.projectInfo.originalFiles')}
      <Icon name="import" />
    </Button>
  );
};

export default TemporaryFilesDownload;
