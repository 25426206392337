import store from 'store/store';

export const getVendorLanguages = (vendor) => {
  const languageIds = [
    ...vendor.prices.map((price) => price.source_lang_id),
    ...vendor.prices.map((price) => price.target_lang_id),
  ].filter((value, index, self) => self.indexOf(value) === index);
  const languages = store.getState().classifiersStore.languages;
  return languages.filter((lang) => languageIds.includes(lang.id));
};

export const getVendorLanguagePairs = (vendor) => {
  const languages = store.getState().classifiersStore.languages;

  const uniquePairs = [];

  for (const price of vendor.prices) {
    const pairIdx = uniquePairs.findIndex(
      (pair) =>
        pair.sourceLanguage.id === price.source_lang_id && pair.targetLanguage.id === price.target_lang_id,
    );
    if (pairIdx < 0) {
      uniquePairs.push({
        sourceLanguage: languages.find((lang) => lang.id === price.source_lang_id),
        targetLanguage: languages.find((lang) => lang.id === price.target_lang_id),
        task_type_id: price.task_type_id,
      });
    }
  }

  return uniquePairs;
};
