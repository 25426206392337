import React from 'react';

import { Container } from './AdminSidebar.styles';

import MenuButton from './MenuButton';
import MenuLink from './MenuLink';

// This is an abstraction layer to create a dynamic admin sidebar
// buttons is an array of objects that require iconName, isLoading, onClick and text props
// links is an array of objects that require iconName, text and to props
const AdminSidebar = ({ buttons = [], links = [] }) => {
  return (
    <Container>
      {links.map((link, index) => {
        const { iconName, text, to } = link;
        return <MenuLink key={`admin-menu-link-${index}`} iconName={iconName} text={text} to={to} />;
      })}
      {buttons.map((button, index) => {
        const { iconName, isLoading, onClick, text, success, className } = button;

        return (
          <MenuButton
            success={success}
            key={`admin-menu-button-${index}`}
            iconName={iconName}
            isLoading={isLoading}
            onClick={onClick}
            text={text}
            className={className}
          />
        );
      })}
    </Container>
  );
};

export default AdminSidebar;
