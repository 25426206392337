import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Dropdown, Modal } from 'semantic-ui-react';
import { Icon } from '../../../common/Icon/Icon';
import Message from '../../../common/Message/Message';

import { postTransferProject, postTransferTeam } from '../../../../services/project';
import { removeProject } from '../../../../store/projectsSlice';

import { toast } from 'react-toastify';
import { getCompanyTeams } from '../../../../services/company';
import { getUserSuggestions } from '../../../../services/users';
import { useDebounce } from '../../../../hoc/debouncer';

import { RadioContainer, UserTypeContainer } from './TransferModal.styles';
import Button from '../../../common/Button/Button';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { Radio } from '../../../common/Radio/Radio';

const TransferModal = ({ onClose, onSubmit, project, t, isAdmin }) => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState('user');
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTeams, setIsLoadingTeams] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [selectedUser, setSelectedCustomer] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);

  const { debounce } = useDebounce();

  const transferProject = async () => {
    sendUserInteraction('transfer project modal submit click');
    setIsLoading(true);

    try {
      if (userType === 'user') {
        const transferResponse = await postTransferProject(project.id, selectedUser);

        if (!isAdmin) {
          dispatch(removeProject({ projectId: project.id }));
        }

        onSubmit(transferResponse);
      } else {
        const teamTransferResponse = await postTransferTeam({ projectId: project.id, teamId: selectedTeam });
        onSubmit(teamTransferResponse);
        if (!isAdmin) {
          dispatch(removeProject({ projectId: project.id }));
        }
      }
      toast.success(t('common:toasts.transferProject.success'));
    } catch (e) {
      toast.error(t('common:toasts.transferProject.error'));
      if (e.response.status === 404) {
        setError(t('common:projects.transferMissingBilling'));
      } else {
        setError(t('common:projects.transferError'));
      }
    }

    setIsLoading(false);
  };

  const handleSelectChange = async (e, data) => {
    e.preventDefault();
    setSelectedCustomer(data.value);

    if (userType === 'company') {
      setIsLoadingTeams(true);
      try {
        const teamsResponse = await getCompanyTeams(data.value);
        const newTeamOptions = teamsResponse.map((team) => {
          const isDisabled = !team.billing_detail_id;

          return {
            disabled: isDisabled,
            key: team.id,
            text: `${team.name} ${isDisabled ? t('common:projects.missingBilling') : ''}`,
            value: team.id,
          };
        });
        setTeamOptions(newTeamOptions);
        if (newTeamOptions.length === 1) {
          setSelectedTeam(newTeamOptions[0].value);
        }
      } catch (e) {
        toast.error(t('common:toasts.loadCompanyTeam.error'));
      } finally {
        setIsLoadingTeams(false);
      }
    }
  };

  const handleTeamChange = (e, { value }) => {
    e.preventDefault();
    setSelectedTeam(value);
  };

  const userOptions = users.map((user) => {
    return {
      key: user.id,
      text: userType === 'user' ? `${user.name} | ${user.email}` : `${user.company_name}`,
      value: user.id,
    };
  });

  const handleTypeChange = (newUserType) => {
    setSelectedCustomer(null);
    setUsers([]);
    setTeamOptions([]);
    setUserType(newUserType);
  };

  const handleAutocompleteChange = async (e, { searchQuery }) => {
    if (searchQuery.trim() === '') {
      return;
    }

    debounce(
      'transferAutocomplete',
      async () => {
        try {
          setIsLoadingUsers(true);
          const data = await getUserSuggestions({ searchQuery, userType });
          setUsers(data);
        } catch (e) {
          setError(t('common:projects.transferError'));
        } finally {
          setIsLoadingUsers(false);
        }
      },
      500,
    );
  };

  const canSubmit = () => {
    if (userType === 'user') {
      return !!selectedUser;
    }
    if (userType === 'company') {
      return !!selectedTeam;
    }
  };

  return (
    <Modal open={true} size="small">
      <Modal.Header>{t('common:projects.transferProject')}</Modal.Header>
      <Modal.Content>
        {error && <Message text={error} type="error" />}
        <UserTypeContainer>
          <RadioContainer onClick={() => handleTypeChange('user')}>
            <Icon circular name="user" size="big" color={userType === 'user' ? '#1CAAC5' : 'grey'} />
            <Radio
              checked={userType === 'user'}
              label={t('common:projects.individual')}
              style={{ margin: '1rem 0' }}
            />
          </RadioContainer>
          <RadioContainer onClick={() => handleTypeChange('company')}>
            <Icon circular name="building" size="big" color={userType === 'company' ? '#1CAAC5' : 'grey'} />
            <Radio
              checked={userType === 'company'}
              label={t('common:projects.company')}
              style={{ margin: '1rem 0' }}
            />
          </RadioContainer>
        </UserTypeContainer>

        <label>{`${t(
          userType === 'company' ? 'common:projects.selectCompany' : 'common:projects.selectUser',
        )}:`}</label>
        <Dropdown
          fluid
          onChange={handleSelectChange}
          onSearchChange={handleAutocompleteChange}
          options={userOptions}
          selection
          search
          loading={isLoadingUsers}
          style={{
            marginTop: '.25rem',
          }}
          value={selectedUser}
        />

        {!isLoadingTeams && userType === 'company' && selectedUser && teamOptions.length === 0 && (
          <Message
            text={t('common:projects.noTeam', 'Selected company does not have any teams.')}
            type="info"
          />
        )}

        {userType === 'company' && (
          <div style={{ marginTop: '1rem' }}>
            <label>{t('common:projects.selectTeam')}</label>
            <Dropdown
              disabled={teamOptions.length === 0}
              fluid
              onChange={handleTeamChange}
              options={teamOptions}
              selection
              search
              style={{
                marginBottom: '1rem',
                marginTop: '.25rem',
              }}
              value={selectedTeam}
            />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" disabled={isLoading} loading={isLoading} onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button
          actiontype="submit"
          disabled={!canSubmit()}
          floated="right"
          loading={isLoading}
          onClick={transferProject}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projectsStore.projects,
  user: state.userStore.user,
});

export default connect(mapStateToProps)(withTranslation('common')(TransferModal));
