import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadFileType: 'file',
  textFileLength: 0,
};

const newProjectSlice = createSlice({
  name: 'newProjectSlice',
  initialState: { ...initialState },
  reducers: {
    setUploadFileType(state, action) {
      const newUploadFileType = action.payload;
      state.uploadFileType = newUploadFileType;
    },
    setTextFileLength(state, action) {
      const newTextFileLength = action.payload;
      state.textFileLength = newTextFileLength;
    },
  },
});

export const { setUploadFileType, setTextFileLength } = newProjectSlice.actions;

export default newProjectSlice.reducer;
