import {
  ALL_ROLES,
  TEAM_ROLES,
  ADMIN_ROLES,
  COMPANY_ROLES,
  SALES_ROLES,
  VENDOR_ROLES,
} from '../constants/roles';
import { sendUserInteraction } from './tagManager.utils';
import { toast } from 'react-toastify';
import { postActivateEmail } from 'services/auth';
import { get } from 'lodash';
import {
  PROJECT_STATUS_DRAFT,
  PROJECT_STATUS_FINISHED,
  PROJECT_STATUS_SELF_SERVICE_FINISHED,
  PROJECT_STATUS_FAILED,
  PROJECT_STATUS_CREATED,
} from 'constants/projectStatus';

// check if passed role is one of the valid user roles
export const isValidRole = (userRole) => {
  return ALL_ROLES.includes(userRole);
};

// check if passed role is one of the valid team roles
export const isValidTeamRole = (userRole) => {
  const roles = TEAM_ROLES.map((role) => {
    return role.value;
  });
  roles.push(null);
  return roles.includes(userRole);
};

// check if passed role is inside the allowed roles array
export const checkAllowedRole = ({ allowedRoles = [], userRole }) => {
  if (!isValidRole(userRole)) {
    throw new Error(`User role ${userRole} not recognized.`);
  }
  return allowedRoles.includes(userRole);
};

export const isCompanyRole = (userRole) => {
  return checkAllowedRole({ allowedRoles: COMPANY_ROLES, userRole });
};

export const isVendorRole = (userRole) => {
  return checkAllowedRole({ allowedRoles: VENDOR_ROLES, userRole });
};

export const roleRequiresBillingDetails = (userRole) => {
  const hasCompany = isCompanyRole(userRole);

  return !hasCompany;
};

export const hasAdminPermissions = (userRole) => {
  return checkAllowedRole({ allowedRoles: ADMIN_ROLES, userRole });
};

export const hasSalesPermissions = (userRole) => {
  return checkAllowedRole({ allowedRoles: SALES_ROLES, userRole });
};

export const checkTeamRole = ({ allowedRoles = [], teamRole }) => {
  if (!isValidTeamRole(teamRole)) {
    throw new Error(`Team role ${teamRole} not recognized.`);
  }
  return allowedRoles.includes(teamRole);
};

export const getTeam = ({ user, teamId }) => {
  return user.teams.find((team) => team.id === teamId);
};

export const getTeamRole = ({ user, teamId }) => {
  const team = getTeam({ user, teamId: Number(teamId) });
  if (team) {
    return team.role_id;
  }
  return null;
};

export const checkRoles = ({ user, teamId, allowedUserRoles = [], allowedTeamRoles = [] }) => {
  return (
    checkAllowedRole({ allowedRoles: allowedUserRoles, userRole: user.role }) ||
    checkTeamRole({
      allowedRoles: allowedTeamRoles,
      teamRole: getTeamRole({ user: user, teamId: teamId }),
    })
  );
};

/**
 * Used for resending email verification
 *
 * @param {string} email Users email
 * @param {function} t The react-i18next translation function
 */
export const resendActivationEmail = async (email, t) => {
  sendUserInteraction('clicked email not verified resend verification');

  try {
    await postActivateEmail(email);
    toast.success(t('common:activateUser.emailVerification.success'));
  } catch (e) {
    const errorStatus = get(e, ['response', 'status']);

    let errorMessage = `${t('common:activateUser.emailVerification.errors.failedToResend')}`;

    if (errorStatus === 404) {
      errorMessage += ` ${t('common:activateUser.emailVerification.errors.notFound')}`;
    }

    if (errorStatus === 422) {
      errorMessage += ` ${t('common:activateUser.emailVerification.errors.allreadyVerified')}`;
    }

    toast.error(errorMessage);
  }
};

export const canTransferProject = (userRole, projectStatusID) => {
  if (!userRole || !projectStatusID) return false;

  const validStatus = projectStatusID === PROJECT_STATUS_DRAFT;
  return (hasAdminPermissions(userRole) || hasSalesPermissions(userRole)) && validStatus;
};

/**
 * Check if project can be archived
 *
 * @param {number} userRole        Users role
 * @param {number} projectStatusID Project status id
 * @returns {boolean}
 */
export const canArchiveProject = (projectStatusID) => {
  // Check if valid status
  const validStatus =
    projectStatusID === PROJECT_STATUS_SELF_SERVICE_FINISHED || projectStatusID === PROJECT_STATUS_FINISHED;

  return validStatus;
};

/**
 * Check if project can be deleted
 *
 * @param {number} projectStatusID
 * @returns {boolean}
 */
export const canDeleteProject = (projectStatusID) => {
  return (
    projectStatusID === PROJECT_STATUS_FAILED ||
    projectStatusID === PROJECT_STATUS_DRAFT ||
    projectStatusID === PROJECT_STATUS_CREATED
  );
};
