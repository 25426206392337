import React from 'react';
import { Link } from 'react-router-dom';
import taiaLogo from 'assets/taia_logo.svg';
import { useTranslation } from 'react-i18next';
import { AddVendorProvider } from './AddVendorProvider';
import AddSteps from './AddSteps';
import { useAddVendorContext } from './AddVendorProvider';
import VendorInfo from './Steps/VendorInfo/VendorInfo';
import UploadAttachments from './Steps/UploadAttachments/UploadAttachments';
import LanguagePairs from './Steps/LanguagePairs/LanguagePairs';

const AddVendor = () => {
  const { activeStepComponent } = useAddVendorContext();

  const { t } = useTranslation();

  return (
    <main className="vendor-onboarding-main">
      <header>
        <div className="content">
          <div className="logo">
            <Link to="/">
              <img src={taiaLogo} alt="" />
            </Link>
          </div>

          <AddSteps></AddSteps>

          <div className="help">
            <p>
              {t('common:serviceProvider.onboarding.help.text')}
              <Link to="/support">{t('common:serviceProvider.onboarding.help.linkText')}</Link>
            </p>
          </div>
        </div>
      </header>
      {activeStepComponent}
    </main>
  );
};

const AdminAddVendor = () => {
  const { t } = useTranslation();
  // Steps data
  const steps = [
    {
      name: 'vendor-info',
      label: t('common:admin.vendorOnboarding.stepOne.label', 'Fill out vendor info'),
      icon: 'user',
      initialData: {
        name: '',
        avatar: null,
        phone: '',
        native_lang_id: null,
        description: '',
        timezone: null,
        country_id: null,
        email: '',
        userID: null,
      },
      requiredFields: ['name', 'native_lang_id', 'country_id'],
      component: <VendorInfo></VendorInfo>,
    },
    {
      name: 'combination',
      label: t('common:admin.vendorOnboarding.stepTwo.label', 'Language pairs'),
      icon: 'english-to-chinese',
      initialData: [
        {
          source_lang_id: null,
          target_lang_id: null,
          category_id: [],
          task_type_id: null,
          rate: 0,
        },
      ],
      requiredFields: ['source_lang_id', 'target_lang_id', 'category_id', 'task_type_id', 'rate'],
      component: <LanguagePairs></LanguagePairs>,
    },
    {
      name: 'attachments',
      label: t('common:admin.vendorOnboarding.stepThree.label', 'Upload attachments'),
      icon: 'upload',
      initialData: { linkedin: '', education: '', experience: null, cv: null, additionalFiles: [] },
      requiredFields: ['education', 'experience', 'cv'],
      component: <UploadAttachments></UploadAttachments>,
    },
  ];
  return (
    <AddVendorProvider steps={steps}>
      <AddVendor />
    </AddVendorProvider>
  );
};

export default AdminAddVendor;
