import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';

// Components
import Categories from './Categories/Categories';
import Contacts from './Contacts/Contacts';

// Styles
import './styles.scss';
import { MainContainer } from 'components/common.styles';

/**
 * Renders the support sections
 */
const Support = () => {
  // Hooks
  const { t } = useTranslation();

  return (
    <MainContainer className="support-content">
      <h1 className="hide-on-mobile">{t('common:support.supportCenter')}</h1>

      <div className="support-content__sections">
        {/** Hints and tips / Zendesk categories  */}
        <section className="support-content__sections__hints-tips">
          <h2>{t('common:support.title')}</h2>
          <Categories />
          <hr />
        </section>

        {/** Support contact info */}
        <section>
          <h2>{t('common:support.title2')}</h2>
          <Contacts />
          <hr />
        </section>
      </div>
    </MainContainer>
  );
};

export default Support;
