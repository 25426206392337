import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AddLanguage, InputContainer, StyledDropdownItem, WarningSpan } from './NameAndLanguages.styles';
import Button from '../../../common/Button/Button';
import { StepFooter, StyledHeader } from '../../NewProject.styles';
import { Input } from '../../../common/Input/Input';
import { Icon } from '../../../common/Icon/Icon';
import { SelectGroup } from '../../../common/SelectGroup/SelectGroup';
import { SelectDropdown, MultipleDropdown } from '../../../common/Dropdown/Dropdown';
import { Flag } from '../../../common/Flag/Flag';
import { useNewProjectState } from '../../NewProject.reducer';

const NameAndLanguages = ({ handleNextClick, isNextDisabled, teams, isMobile }) => {
  const [projectNameWarning, setProjectNameWarning] = useState(false);
  const [{ projectData }, dispatch] = useNewProjectState();
  const { projectType, sourceLanguage, targetLanguages, projectName, teamId } = projectData;

  const { languages } = useSelector((state) => state.classifiersStore);
  const { t } = useTranslation();

  const supportedLanguages = useMemo(() => {
    const parsedLanguages = languages.map((lang) => ({
      key: lang.id,
      value: lang.code,
      text: t(`languages:${lang.code}`, lang.name),
    }));
    return parsedLanguages;
  }, [languages, t]);

  const handleSourceLanguageChange = (e, { value }) => {
    const newTargetLanguages =
      projectType === 'proofread'
        ? [sourceLanguage]
        : targetLanguages.filter((targetLanguage) => targetLanguage !== sourceLanguage);
    const payload = {
      ...projectData,
      sourceLanguage: value,
      targetLanguages: newTargetLanguages,
    };
    dispatch({ type: 'SET_PROJECT_DATA', payload });
  };

  const getSourceLanguageOptions = () =>
    supportedLanguages
      .filter((lang) => {
        if (projectType === 'translation') {
          return !targetLanguages.includes(lang.value);
        }
        return true;
      })
      .map((lang) => ({
        ...lang,
        icon: <Flag lang={{ code: lang.value }} />,
      }));

  const handleTargetLanguagesChange = (e, { value }) => {
    dispatch({ type: 'SET_TARGET_LANGUAGES', payload: value });
  };

  const getTargetLanguageOptions = () =>
    supportedLanguages
      .filter((lang) => {
        if (projectType === 'translation') {
          return lang.value !== sourceLanguage;
        }
        return !targetLanguages.includes(lang.value);
      })
      .map((lang) => ({
        ...lang,
        icon: <Flag lang={{ code: lang.value }} />,
      }));

  const handleTeamChange = (e, { value }) => {
    dispatch({ type: 'SET_TEAM_ID', payload: value });
  };

  const handleNameChange = (e) => {
    setProjectNameWarning(null);

    if (e.target.value.length > 190) {
      setProjectNameWarning(true);
      return;
    }
    dispatch({ type: 'SET_PROJECT_NAME', payload: e.target.value });
  };

  const setProjectType = (projectType) => {
    const payload = {
      ...projectData,
      projectType,
    };
    dispatch({ type: 'SET_PROJECT_DATA', payload });
  };

  const teamOptions = teams.map((team) => ({
    key: team.id,
    text: team.name,
    value: team.id,
  }));

  return (
    <>
      <StyledHeader>{t('common:projects.createProject.nameThisProject')}</StyledHeader>
      <InputContainer>
        <Input
          placeholder={t('common:projects.createProject.projectNameExample')}
          name="projectName"
          onChange={handleNameChange}
          type="text"
          value={projectName || ''}
          data-cy="step-one-project-name-input"
          id="create-new-project-name-input"
        />
        {projectNameWarning && <WarningSpan>{t('common:projects.createProject.nameTooLong')}</WarningSpan>}
      </InputContainer>
      {teamOptions.length > 1 && (
        <>
          <StyledHeader>{t('common:projects.createProject.selectTeam')}</StyledHeader>
          <SelectDropdown
            fluid
            options={teamOptions}
            selection
            onChange={handleTeamChange}
            value={teamId}
            placeholder={t('common:projects.createProject.teamPlaceholder')}
            id="create-new-project-select-team"
          />
        </>
      )}
      <StyledHeader>{t('common:projects.createProject.projectType')}</StyledHeader>

      <SelectGroup
        id="create-new-project-service-selection"
        selected={projectType}
        onChange={setProjectType}
        fluid
      >
        <SelectGroup.Option data-cy="step-one-translation-type-selector" value="translation">
          <Icon name="english-to-chinese" />
          {t('common:projects.createProject.translation')}
        </SelectGroup.Option>
        <SelectGroup.Option data-cy="step-one-proofread-type-selector" value="proofread">
          <Icon name="redo" />
          {t('common:projects.createProject.proofreadOnly')}
        </SelectGroup.Option>
      </SelectGroup>

      <StyledHeader>{t('common:projects.createProject.sourceLanguages')}</StyledHeader>
      <SelectDropdown
        name="sourceLanguage"
        value={sourceLanguage}
        onChange={handleSourceLanguageChange}
        options={getSourceLanguageOptions()}
        selection
        search
        emptyState={t('common:projects.createProject.noLanguagesFound')}
        placeholder={t('common:projects.createProject.selectALanguage')}
        id="create-new-project-source-language"
      />
      {projectType === 'translation' && (
        <>
          <StyledHeader>{t('common:projects.createProject.targetLanguages')}</StyledHeader>
          <MultipleDropdown
            name="targetLanguages"
            value={projectType === 'proofread' ? [sourceLanguage] : targetLanguages}
            onChange={handleTargetLanguagesChange}
            placeholder={t('common:projects.createProject.selectOneOrMore')}
            options={getTargetLanguageOptions()}
            multiple
            selection
            search
            emptyState={t('common:projects.createProject.noLanguagesFound')}
            fluid
            id="create-new-project-target-language"
            renderItem={(props) => (
              <StyledDropdownItem {...props}>
                {props.item.icon}
                <span data-cy="dropdown-item-text">{props.item.text}</span>
                <AddLanguage data-cy="add-language-button">
                  <Icon name="plus" /> {t('common:projects.createProject.addLanguage')}
                </AddLanguage>
              </StyledDropdownItem>
            )}
          />
        </>
      )}
      <StepFooter className="hide-on-mobile">
        <Button
          actiontype="primary"
          disabled={isNextDisabled}
          labelPosition="right"
          onClick={handleNextClick}
          fluid
          big
          data-cy="go-to-step-two-button"
          id="create-new-project-step-1-continue-btn"
        >
          {t('common:projects.createProject.continueToTranslationContent')}
          <Icon name="arrow-right" />
        </Button>
      </StepFooter>
    </>
  );
};

export default NameAndLanguages;
