import styled from 'styled-components';
import Card from '../common/Card/Card';

export const StyledCard = styled(Card)`
  max-width: 510px;
  width: 100%;
  margin: 0 auto;
  font-family: 'DM Sans', sans-serif !important;

  &:before {
    right: -14px;
    bottom: -14px;
    top: 34px;
    height: auto;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    max-width: initial;
    margin: 0 ${theme.paddings.mainContainer.mobile.x};
    width: calc(100% - (${theme.paddings.mainContainer.mobile.x} * 2));
  `}
`;

export const InnerContainer = styled.div`
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  @media only screen and (max-width: 480px) {
    padding: 60px 30px 30px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  background: ${({ theme }) => theme.colors.blueLight};
  font-size: 18px;
  padding: 21px 0;
`;

export const StyledForm = styled.form`
  margin: 0 auto;
  width: 100%;

  & > div {
    margin-bottom: 20px;
  }
`;
