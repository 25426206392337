import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Header } from 'semantic-ui-react';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { postProduct, postPlanBatch, deleteProduct } from '../../services/subscriptions';
import { CreateButton, CreatePackageWrapper } from './CreatePackage.style';
import PlanInformation from './PlanInformantion/PlanInformantion';
import Pricing from './Pricing/Pricing';
import { hasSameCombination } from './Pricing/Pricing.util';

const CreatePackage = () => {
  const [planInformation, setPlanInformation] = useState({ planName: '', wordLimit: 0, private: false });
  const [pricing, setPricing] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const handlePlanChange = useCallback((planInfo) => {
    setPlanInformation(planInfo);
  }, []);

  const handlePricingChange = useCallback((pricing) => {
    setPricing(pricing);
  }, []);

  const isValid = () => {
    return (
      planInformation.planName.length > 0 &&
      planInformation.wordLimit > 0 &&
      pricing.length > 0 &&
      !hasSameCombination(pricing)
    );
  };

  const handleSubmit = async () => {
    sendUserInteraction('create package submit');
    setIsSubmitting(true);
    let product;
    try {
      product = await postProduct({
        name: planInformation.planName,
        word_limit: planInformation.wordLimit,
        privatePlan: planInformation.private,
      });
    } catch (e) {
      toast.error(t('common:toasts.createPackage.error'));
      console.error(e);
    }

    try {
      const plans = pricing.map((price) => {
        return {
          name: `${planInformation.planName} ${price.interval}`,
          interval: price.interval,
          amount: price.amount,
          currency: price.currency,
          active: 1,
        };
      });

      await postPlanBatch({ product_id: product.id, plans: plans });

      toast.success(t('common:toasts.createPackage.success'));
      history.push(`/admin/packages/${product.id}`);
    } catch (e) {
      toast.error(t('common:toasts.createPackage.error'));
      console.error(e);
      await deleteProduct(product.id);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CreatePackageWrapper>
      <Header as="h1" className="hide-on-mobile">
        {t('common:admin.createPackage.header')}
      </Header>
      <PlanInformation onChange={handlePlanChange} />
      <Pricing onChange={handlePricingChange} />
      <CreateButton actiontype="primary" loading={isSubmitting} disabled={!isValid()} onClick={handleSubmit}>
        {t('common:admin.createPackage.createPackage')}
      </CreateButton>
    </CreatePackageWrapper>
  );
};

export default CreatePackage;
