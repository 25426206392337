import httpClient from './httpclient';

export const getAllCoupons = async (vat) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/allcoupons`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postCouponGenerate = async ({ currency_id, code, ratio, type, use_limit }) => {
  if (ratio < 0) {
    ratio = 0;
  }
  try {
    const { data } = await httpClient.post(`/auth/admin/coupongenerate`, {
      currency_id,
      code: code,
      ratio: ratio,
      type,
      use_limit: use_limit,
    });
    return data;
  } catch (e) {
    throw e;
  }
};
