import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import React from 'react';

const StepsControls = ({ nextStepLabel, stepsContext, isComplete, submitVendorData, loading }) => {
  const { navigation, index, completed } = stepsContext;

  const isFinished = completed.includes(0) && completed.includes(1) && completed.includes(2);
  const nextDisabled = !isComplete || (index === 2 && !isFinished) || loading;

  const handleNextStep = () => {
    if (isFinished) {
      // Onboard user, POST data to BE
      submitVendorData();
    } else {
      navigation.next();
    }
  };

  return (
    <div className="steps-controls">
      {index > 0 && (
        <Button
          className="prev"
          big
          basic
          color="light-grey"
          onClick={() => navigation.prev()}
          loading={loading}
          disabled={loading}
        >
          Back
        </Button>
      )}
      <Button
        className="next"
        actiontype="primary"
        labelPosition="right"
        fluid
        big
        onClick={handleNextStep}
        disabled={nextDisabled}
        loading={loading}
      >
        {nextStepLabel}
        <Icon name="arrow-right" />
      </Button>
    </div>
  );
};

export default StepsControls;
