import React from 'react';
import { withTranslation } from 'react-i18next';
import Table from '../../../common/Table/Table';
import MembersRow from './MembersRow';

const CompaniesTable = ({ t, members }) => {
  return (
    <Table width="100%" marginTop="0">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('common:company.members.user')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:company.members.joinedOn')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:company.members.roles')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:company.members.teams')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {members.length > 0 ? (
          members.map((member) => <MembersRow key={member.id} member={member} />)
        ) : (
          <Table.Row>
            <Table.Cell colSpan="5" textAlign="center">
              {t('common:company.members.noMembers')}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default withTranslation('common')(CompaniesTable);
