import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CenteredContainer, ContentContainer } from '../common.styles';

import Image from './assets/maintenance.png';
import { sendUserInteraction } from 'utils/tagManager.utils';

const Maintenance = () => {
  const { t } = useTranslation();

  const { maintenanceError } = useSelector((state) => state.errorsStore);
  const user = useSelector((state) => state.userStore.user);

  const redirectRoute = useMemo(() => {
    if (!user) {
      // if user is not logged in, redirect to the login page
      return '/';
    } else {
      return '/projects';
    }
  }, [user]);

  return maintenanceError ? (
    <CenteredContainer>
      <img style={{ width: '90%', maxWidth: '450px' }} src={Image} alt="maintenance" />
      <ContentContainer>
        <h1>{t('common:maintenance.schedualedDowntime')}</h1>
        <p>{t('common:maintenance.oops')}</p>
        <p>{t('common:maintenance.taiaIsUnavailable')}</p>
        <p>
          {t('common:maintenance.inTheMeanTime')}{' '}
          <a
            href="https://taia.io/blog/"
            onClick={() => {
              sendUserInteraction('maintenance blog link click');
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common:maintenance.readAboutTaia')}
          </a>
          .
        </p>
      </ContentContainer>
    </CenteredContainer>
  ) : (
    <Redirect to={redirectRoute} />
  );
};

export default Maintenance;
