import { getBillingDetails, getUser } from '../../services/auth';
import { getCompanyTeams } from '../../services/company';
import { getBillingSubscriptions, getProducts, getSubscriptionById } from '../../services/subscriptions';
import store from '../../store/store';
import { addSubscriptions, removeAllSubscriptions } from '../../store/subscriptionsSlice';
import { setProducts } from 'store/classifiersSlice';
import { setBillingDetails, setUser } from '../../store/userSlice';
import { getTeamsSubscriptions } from '../../utils/initialLoad';
import { checkTeamRole } from 'utils/user.utils';

export const findBillingId = (user) => {
  if (user.role === 1) {
    return null;
  }

  if (user.role === 40 || user.role === 42) {
    if (user.teams.length > 0) {
      const team = user.teams[0];
      return team.billing_detail_id;
    } else {
      return null;
    }
  } else {
    if (user.billing_detail) {
      if (user.billing_detail.hasOwnProperty('id')) {
        return user.billing_detail.id;
      }
    } else {
      return null;
    }
  }
};

export const findCurrencyIdForCode = (code) => {
  const currencies = store.getState().classifiersStore.currencies;

  return currencies.find((cur) => cur.code.toLowerCase() === code).id;
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const fetchSubscriptions = async (paidSub = false) => {
  try {
    store.dispatch(removeAllSubscriptions());
    let userInfo = (await getUser())['data'];

    let { company_owner_id, id, teams, billing_detail, free_subscription, legacy_subscription } = userInfo;

    if (paidSub) {
      while (free_subscription !== null) {
        await delay(500);
        userInfo = await (await getUser())['data'];
        ({ company_owner_id, id, teams, billing_detail, free_subscription, legacy_subscription } = userInfo);
      }
    }

    store.dispatch(setUser({ user: userInfo }));

    // refetch products personalised for this user
    const products = await getProducts();
    store.dispatch(setProducts(products));

    if (!!free_subscription) {
      store.dispatch(addSubscriptions([free_subscription]));
    }

    if (!!legacy_subscription) {
      store.dispatch(addSubscriptions([legacy_subscription]));
    }

    // if user is a company owner load all teams for that company, load all subscriptions for teams billing
    if (!!company_owner_id) {
      const companyTeams = await getCompanyTeams(company_owner_id);
      const subscriptions = await getTeamsSubscriptions(companyTeams);
      store.dispatch(addSubscriptions(subscriptions));
    }

    // else if user is a member of any team load all subscriptions for teams that have billing
    if (!company_owner_id) {
      const subscriptions = await getTeamsSubscriptions(teams);

      const subscriptionsDetails = await Promise.all(
        subscriptions.map(async (sub) => {
          const data = await getSubscriptionById(sub.id);
          return data;
        }),
      );

      // filter out subscriptions for which user role 42 has data
      const userSubscriptions = subscriptionsDetails.filter((sub) => {
        // get team related to this subscription
        const subscriptionTeam = teams.find((team) => team.billing_detail_id === sub.billing_detail_id);
        // billing managers and team managers can manage subscriptions with seats, full and limited members can only see seat usage data
        const { role_id } = subscriptionTeam;
        const isManager = checkTeamRole({ allowedRoles: [1, 4], teamRole: role_id });

        // if the user can manage subscription data, return teh data, if the user cannot manage subscription data only return the subscription if he has an active subscription seat
        return !!isManager ? true : !!sub.auth_user_data;
      });

      store.dispatch(addSubscriptions(userSubscriptions));
    }

    // else if user has billing details set, load data for those billing details
    if (billing_detail) {
      const billingDetails = await getBillingDetails(id);
      const billingSubscriptions = await getBillingSubscriptions({ billing_id: billingDetails.data.id });
      store.dispatch(setBillingDetails({ billingDetails: billingDetails.data }));
      store.dispatch(addSubscriptions(billingSubscriptions.data));
    } else {
      store.dispatch(setBillingDetails({ billingDetails: null }));
    }
  } catch (e) {
    throw e;
  }
};
