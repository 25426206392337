import styled from 'styled-components';

export const MainImage = styled.img`
  width: 400px;
  height: 319.8px;
  margin: 0 auto;
  margin-top: -104px;
  display: block;
  margin-bottom: 44px;
`;

export const Heading = styled.h1`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 29px;
  line-height: 49px;
  text-transform: capitalize;
  color: #262626;
  text-align: center;
  margin-top: 0;
  margin-bottom: 27px;
`;

export const BodyText = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  margin: 0;
  margin-bottom: 27px;
  color: #262626;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: justify-content;
  width: 100%:
`;

export const ButtonAnchor = styled.a`
  width: calc(50% - 5.5px);
  height: 38px;
`;
