export const SUBSCRIPTION_INTERVALS = ['month', 'year'];

export const SUBSCRIPTION_INTERVALS_OPTIONS = [
  {
    key: 'monthly',
    text: 'Monthly',
    value: 'month',
  },
  {
    key: 'yearly',
    text: 'Yearly',
    value: 'year',
  },
];
