import { Flag } from 'components/common/Flag/Flag';
import { zindexPopperModifier } from 'constants/general';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';

const LanguageLabel = ({ lang }) => {
  const { t } = useTranslation();

  return (
    <Popup
      position="top center"
      inverted
      trigger={
        <div className="lang-label">
          <Flag lang={lang} />
          <span>{t(`languages:${lang.code}`)}</span>
        </div>
      }
      content={<span>{t(`languages:${lang.code}`)}</span>}
      popperModifiers={[zindexPopperModifier]}
    />
  );
};

export default LanguageLabel;
