import React from 'react';
import { get } from 'lodash';

import { Redirect, Route } from 'react-router-dom';
import PublicWrapper from '../PublicWrapper/PublicWrapper';

export default ({ component: Component, isAuthenticated, location, user, routeName, ...rest }) => {
  let content = null;
  if (isAuthenticated) {
    const previousPathname = get(location, ['state', 'from', 'pathname'], location.pathname);
    const previousSearch = get(location, ['state', 'from', 'search'], location.search);

    const redirectFromPackages = previousPathname.includes('/catapult-packages');
    const redirectFromCatapult = previousPathname.includes('/catapult-landing');
    const redirectFromQuote = previousPathname.includes('/instant-quote');

    // redirect directly to the catapult subscriptions checkout page here if coming from catapult landing
    // all the rest of public routes should redirect to projects dashboard, only the catapult subscription route should point elsewhere
    let redirectRoute = redirectFromCatapult ? `/checkout` : '/projects';
    const redirectSearch = redirectFromCatapult ? previousSearch : null;

    // if user role is 1, user needs to choose their account type
    if ((user.role === 1 || user.role === 2) && !redirectFromCatapult) {
      redirectRoute = redirectFromQuote ? '/onboarding/customer?instant=1' : '/onboarding';
    }

    content = (
      <Redirect to={{ pathname: redirectRoute, search: redirectSearch, state: { from: location } }} />
    );

    // if user role is 1, user needs to choose their account type ()
    /*if ((user.role === 1 || user.role === 2) && !redirectFromCatapult) {
      content = <AccountType />;
    }*/

    if (redirectFromPackages) {
      content = <Component {...rest} />;
    }
  } else {
    content = <Component {...rest} />;
  }

  return (
    <Route {...rest}>
      <PublicWrapper routeName={routeName}>{content}</PublicWrapper>
    </Route>
  );
};
