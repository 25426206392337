import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Popup } from 'semantic-ui-react';
import { classnames } from 'utils/base.utils';
import { getNameAndExtension } from 'utils/filename.utils';
import { Icon } from '../../Icon/Icon';

// Style
import './style.scss';

/**
 * Renders uploaded file preview, with option to edit the name
 */
const FileUploadPreview = ({
  file,
  files = [],
  onRemove = () => {},
  onChange = () => {},
  showSize = true,
  showDlPopup = false,
  onClick = false,
  editable = true,
  removable = true,
}) => {
  // Get filename and its extension from the File object
  const { name, extension } = getNameAndExtension(file.name);

  // Local state
  const [editing, setEditing] = useState(false);
  const [filename, setFilename] = useState(name);

  // Refs
  const filenameRef = useRef(null);

  // Methods
  const toggleEditing = () => setEditing((c) => !c);

  const handleFilenameChange = (e) => setFilename(e.target.textContent);

  const handleFilenameUpdate = () => {
    if (filename === '') return;

    // Update filename
    const newFile = new File([file], `${filename}.${extension}`, { type: file.type });
    onChange(newFile);
    setEditing(false);
  };

  const handleFilenameCancel = () => {
    setFilename(name);
    setEditing(false);
  };

  const handleRemove = () => {
    onRemove(file);
  };

  const handleRedirect = (e) => {
    // Prevent default link behaviour
    if (onClick || editing) e.preventDefault();

    // If editing don't fire onClick
    if (editing) return;

    if (onClick) onClick();
  };

  // Effects
  useEffect(() => {
    if (!editing || filename.length === 0) return;

    const setpos = document.createRange();
    const set = window.getSelection();

    setpos.setStart(filenameRef.current.childNodes[0], filename.length);
    setpos.collapse(true);

    set.removeAllRanges();
    set.addRange(setpos);

    filenameRef.current.focus();
  }, [editing, filename]);

  // Variables used in render
  const mainClassnames = classnames('file-preview', { editing });

  const isDuplicate = useMemo(() => {
    const fileNames = files.map((f) => f.name).filter((fname) => fname !== file.name);

    return fileNames.includes(`${filename}.${extension}`);
  }, [files, file, filename, extension]);

  const fileUrl = URL.createObjectURL(file);
  const saveDisable = filename === '' || isDuplicate;

  // File name jsx
  const filenameComponent = (
    <a href={fileUrl} rel="noopener noreferrer" target="_blank" onClick={handleRedirect}>
      <Icon name="paperclip" className="paperclip-icon" />

      <span>
        <Popup
          content="A file with that name already exists"
          open={editing && isDuplicate}
          position="top center"
          trigger={
            <span
              onInput={handleFilenameChange}
              ref={filenameRef}
              contentEditable={editing}
              suppressContentEditableWarning
            >
              {filename}
            </span>
          }
        />
        <span className="extension">
          .{extension} {showSize && `- ${(parseFloat(file.size) / 1024 / 1024).toFixed(2)} MB`}
        </span>
      </span>
    </a>
  );

  return (
    <div className={mainClassnames}>
      {/* conditional download file popup */}
      {showDlPopup ? (
        <Popup position="top center" content="Click to download the file" trigger={filenameComponent} />
      ) : (
        filenameComponent
      )}

      {/* Edit filename buttons */}
      <div className="edit">
        {editing ? (
          <>
            <button
              title="Save file name changes"
              className="button-reset confirm"
              onClick={handleFilenameUpdate}
              disabled={saveDisable}
            >
              <Icon name="check" />
            </button>
            <button
              title="Discard file name changes"
              className="button-reset cancel"
              onClick={handleFilenameCancel}
            >
              <Icon name="times" />
            </button>
          </>
        ) : (
          editable && (
            <button title="Edit file name" className="button-reset edit-btn" onClick={toggleEditing}>
              <Icon name="edit-alt" />
            </button>
          )
        )}
        {removable && (
          <button title="Remove file" className="button-reset delete" onClick={handleRemove}>
            <Icon name="trash-alt" />
          </button>
        )}
      </div>
    </div>
  );
};

export default FileUploadPreview;
