/** Project statuses constants */

export const PROJECT_STATUS_CREATED = 1;
export const PROJECT_STATUS_DRAFT = 2;
export const PROJECT_STATUS_OPEN = 3;
export const PROJECT_STATUS_IN_PROGRESS = 4;
export const PROJECT_STATUS_REVIEW = 5;
export const PROJECT_STATUS_FINISHED = 6;

export const PROJECT_STATUS_SELF_SERVICE_IN_PROGRESS = 10;
export const PROJECT_STATUS_SELF_SERVICE_FINISHED = 11;

export const PROJECT_STATUS_ARCHIVED = 20;
export const PROJECT_STATUS_FAILED = 30;

export const PROJECT_STATUS_REVISION_FINISHED = 53;
export const PROJECT_STATUS_REVISION_ACCEPTED = 52;
export const PROJECT_STATUS_REVISION_ASSIGNED = 51;
