import styled, { css } from 'styled-components';

export const BtnContainer = styled.i`
  display: flex !important;
  justify-content: center;
  align-items: center;
`;

const circledStyles = css`
  width: ${(props) => props.size + 12}px;
  height: ${(props) => props.size + 12}px;
  box-shadow: 0 0 0 0.2em rgb(0 0 0 / 10%) inset;
  aspect-ratio: 1/1;
  border-radius: 50%;
  box-sizing: content-box;
  padding: 3px;
`;

const linkStyles = css`
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer !important;
  opacity: 0.8;
  transition: opacity 0.2s ease-out;
  &:hover {
    opacity: 1;
  }
`;

export const IconContainer = styled.span`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  ${(props) => (props.circular ? circledStyles : '')};
  ${(props) => (props.link ? linkStyles : '')};

  background: ${({ inverted, color }) => (inverted ? color : '')};
  svg {
    fill: ${({ inverted }) => (inverted ? '#fff' : '')};
  }
`;
