import { createSelector } from 'reselect';
import { memoize } from 'lodash';

export const getCurrencies = (state) => state.classifiersStore.currencies;
export const getBillingDetails = (state) => state.userStore.billingDetails;

export const getUserCurrencyId = createSelector(getBillingDetails, (billingDetails) => {
  if (!!billingDetails && !!billingDetails.currency_id) {
    return billingDetails.currency_id;
  }
  return null;
});

export const getUserCurrencyData = createSelector(
  [getCurrencies, getUserCurrencyId],
  (currencies, currency_id) => {
    if (currency_id) {
      return currencies.find((currency) => currency.id === currency_id);
    }
    // if user doesn't have billing details return euro currency data
    return currencies[0];
  },
);

export const getAllCurrencies = createSelector([getCurrencies], (currencies) => {
  return currencies;
});

// currency selector that can be used to get currency by id
// const getCurrencyById = useSelector(currencyByIdSelector);
export const currencyByIdSelector = createSelector(getCurrencies, (currencies) =>
  memoize((currencyId) => currencies.find((currency) => currency.id === currencyId)),
);

// currency selector that can be used to get currency by currency code
// const getCurrencyByCode = useSelector(currencyByCodeSelector);
export const currencyByCodeSelector = createSelector(getCurrencies, (currencies) =>
  memoize((currencyCode) => {
    if (typeof currencyCode === 'string') {
      return currencies.find((currency) => currency.code.toLowerCase() === currencyCode.toLowerCase());
    }
    return undefined;
  }),
);
