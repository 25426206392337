import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Steps from '../Steps/Steps';
import CreateProgress from '../CreateProgress/CreateProgress';
import NameAndLanguages from './NameAndLanguages/NameAndLanguages';
import FilesUpload from './FilesUpload/FilesUpload';
import Category from './Category/Category';

import Loader from '../../common/Loader/Loader';
import { LoaderContainer, StyledCard, CardHeader, InnerContainer } from '../NewProject.styles';

import { getCompanyTeams } from '../../../services/company';
import { useNewProjectState } from '../NewProject.reducer';
import { useHistory, useLocation } from 'react-router';

import { updateStepsLength } from '../../../store/projectsSlice';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { theme } from 'style/theme';
import MobileStepsControls from 'components/common/Steps/MobileStepsControls';

const steps = [
  { number: 1, text: 'Project details' },
  { number: 2, text: 'Translation content' },
  { number: 3, text: 'Subject matter' },
];

const CreateProject = ({ submitProject }) => {
  const [validSteps, setValidSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  // React router
  const history = useHistory();
  const location = useLocation();

  const [teams, setTeams] = useState([]);
  const [
    { activeStep, projectData, error, submittingProject, submitMessage },
    dispatch,
  ] = useNewProjectState();

  const user = useSelector((state) => state.userStore.user);
  const fileType = useSelector((state) => state.newProjectStore.uploadFileType);
  const textLength = useSelector((state) => state.newProjectStore.textFileLength);
  const reduxDispatch = useDispatch();

  const { t } = useTranslation();

  const mobileStepsTexts = [
    t('common:projects.createProject.continueToTranslationContent'),
    t('common:projects.createProject.continueToSubjectMatter'),
    t('common:projects.createYourProject'),
  ];

  const mobileTitles = [
    t('common:projects.createProject.mobileStepTitles.one'),
    t('common:projects.createProject.mobileStepTitles.two'),
    t('common:projects.createProject.mobileStepTitles.three'),
  ];

  // this is stored in local state because we have a use effect function that sets teams if user is company manager
  const { files, projectType, sourceLanguage, targetLanguages, teamId, projectCategory } = projectData;

  const setProjectData = (payload) => {
    dispatch({ type: 'SET_PROJECT_DATA', payload });
  };

  useEffect(() => {
    const getManagerSteps = async () => {
      const { company_owner_id } = user;
      try {
        const teamsWithBilling = await getCompanyTeams(company_owner_id);
        if (teamsWithBilling.length === 1) {
          const newProjectData = {
            ...projectData,
            teamId: teamsWithBilling[0].id,
          };
          setProjectData(newProjectData);
        }
        setTeams(teamsWithBilling);
      } catch (e) {
        // TODO handle teams error here
      } finally {
        setIsLoading(false);
      }
    };

    // if user role is 40, get all company teams (company manager can create projects for all teams)
    if (user.role === 40) {
      getManagerSteps();
    } else {
      // otherwise use teams from user object
      if (user.teams.length === 1) {
        const newProjectData = {
          ...projectData,
          teamId: user.teams[0].id,
        };
        setProjectData(newProjectData);
      }
      setTeams(user.teams);
      setIsLoading(false);
    }
    dispatch({ type: 'SET_STEP', payload: 1 });

    reduxDispatch(updateStepsLength({ length: 3 }));

    return () => reduxDispatch(updateStepsLength({ length: null }));

    // Want to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 1 name + file + teams select
  // 2 type + languages
  // 3 category

  useEffect(() => {
    const validSteps = [];

    // Team, project type and language selection
    const teamSelected = teams.length < 2 || (teams.length > 1 && teamId !== null);
    if (teamSelected && sourceLanguage) {
      if (projectType === 'proofread') {
        validSteps.push(1);
      } else if (projectType === 'translation') {
        if (targetLanguages.length > 0) {
          validSteps.push(1);
        }
      }
    }

    //Upload files
    if (files.length > 0 || (fileType === 'text' && textLength > 0)) {
      validSteps.push(2);
    }

    // Category
    if (projectCategory !== null) {
      validSteps.push(3);
    }

    setValidSteps(validSteps);
  }, [
    files,
    projectType,
    sourceLanguage,
    targetLanguages.length,
    teamId,
    fileType,
    textLength,
    activeStep,
    projectCategory,
    teams,
  ]);

  /**
   * Runs on active step change, sets step query in url
   */
  useEffect(() => {
    // Update url
    history.push({
      pathname: location.pathname,
      search: '?step=' + activeStep,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleNextClick = () => {
    dispatch({ type: 'SET_STEP', payload: activeStep + 1 });
  };

  const handlePreviousClick = () => {
    dispatch({ type: 'SET_STEP', payload: activeStep - 1 });
  };

  const isNextDisabled = (step) => {
    return !validSteps.includes(step);
  };

  const canSubmit = () => {
    return validSteps.includes(1) && validSteps.includes(2) && validSteps.includes(3);
  };

  const handleMobileControls = (direction) => {
    const newStep = activeStep + direction;

    // Check if new step is valid
    if (newStep === 0) return;
    if (newStep > steps.length) return;

    dispatch({ type: 'SET_STEP', payload: newStep });
  };

  return (
    <>
      <CardHeader>
        {isMobile ? mobileTitles[activeStep - 1] : t('common:projects.createProject.newProject')}
      </CardHeader>
      <StyledCard>
        <Steps
          activeStep={activeStep}
          validSteps={validSteps}
          setActiveStep={(step) => {
            dispatch({ type: 'SET_STEP', payload: step });
          }}
          steps={steps}
          className="hide-on-mobile"
        />
        <InnerContainer>
          {isLoading ? (
            <LoaderContainer>
              <Loader inline />
            </LoaderContainer>
          ) : submittingProject || error || submitMessage ? (
            <CreateProgress />
          ) : (
            <>
              {activeStep === 1 && (
                <NameAndLanguages
                  isNextDisabled={isNextDisabled(1)}
                  handleNextClick={handleNextClick}
                  handlePreviousClick={handlePreviousClick}
                  teams={teams}
                  isMobile={isMobile}
                />
              )}
              {activeStep === 2 && (
                <FilesUpload
                  isNextDisabled={isNextDisabled(2)}
                  handleNextClick={handleNextClick}
                  handlePreviousClick={handlePreviousClick}
                />
              )}
              {activeStep === 3 && (
                <Category
                  handlePreviousClick={handlePreviousClick}
                  canSubmit={canSubmit()}
                  submitProject={submitProject}
                />
              )}
            </>
          )}
        </InnerContainer>
        <MobileStepsControls
          className="hide-on-desktop"
          handleStepChange={handleMobileControls}
          handleLastStep={submitProject}
          step={activeStep}
          stepsLength={steps.length}
          stepsTexts={mobileStepsTexts}
          validSteps={validSteps}
        />
      </StyledCard>
    </>
  );
};

export default CreateProject;
