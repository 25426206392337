import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { withDebounce } from '../../../hoc/debouncer';
import Button from '../../common/Button/Button';
import CreateCompany from '../../common/CreateCompany/CreateCompany';

const NewCompanyModal = ({ t, onSubmit }) => {
  const [openState, setOpenState] = useState(false);

  const onCompanyCreate = () => {
    setOpenState(false);
    onSubmit();
  };

  return (
    <Modal
      trigger={
        <Button actiontype="primary" onClick={() => sendUserInteraction('open new company create modal')}>
          {t('common:admin.users.newCompanyModal.addNewCompany')}
        </Button>
      }
      open={openState}
      onOpen={() => setOpenState(true)}
      onClose={() => setOpenState(false)}
      size="small"
    >
      <Modal.Content>
        <CreateCompany onSubmit={onCompanyCreate}></CreateCompany>
      </Modal.Content>
    </Modal>
  );
};

export default withDebounce(withTranslation('common')(NewCompanyModal));
