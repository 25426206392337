import { zindexPopperModifier } from 'constants/general';
import React from 'react';
import { Popup } from 'semantic-ui-react';

const ModalPopup = (props) => {
  const newProps = {
    ...props,
    popperModifiers: [zindexPopperModifier],
  };
  return <Popup {...newProps} />;
};

export default ModalPopup;
