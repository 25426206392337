import styled from 'styled-components';

export const MessageContainer = styled.div`
  padding: 0 1rem;
`;

export const TableContainer = styled.div`
  table td > span {
    display: none;
    font-size: 13px;
    line-height: normal;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    table, thead, tbody, th, td, table td > span, tr { 
      display: block; 
    }
    
    thead tr { 
      display: none;
    }

    tbody {
      tr {
        border: 1px solid #ccc;

        td,
        td:first-child {
          padding-left: ${({ theme }) => theme.paddings.mainContainer.mobile.x};
        }
      }

      > :not(:last-child) {
        margin-bottom: 14px;
      }
    }

    .invite-actions {
      display: flex;

      > :not(:last-child) {
        margin-right: 7px;
      }

      button {
        flex-grow: 1;
        margin-right: 0;
      }
    }

  `}
`;
