import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { userStartedVendorOnboarding } from './VendorOnboarding.utils';

import VendorOnboarding from './VendorOnboarding';

/**
 * Check if user has vendor model or if user started onboarding
 * (checks for step one data in localStorage )
 * If none of the two are true, redirect user to home
 */
const VendorOnboardingController = () => {
  const { vendor } = useSelector((state) => state.userStore.user);

  // Redirect home if:
  // - user already has vendor model
  // - user has not started onboarding
  if (vendor || !userStartedVendorOnboarding()) return <Redirect to="/" />;

  return <VendorOnboarding />;
};

export default VendorOnboardingController;
