import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

// Styles
import './style.scss';

const FullscreenOverlay = ({ open, disabled = true, className, ...rest }) => {
  const classNames = () => {
    const final = ['fullscreen-overlay', className];

    if (open) final.push('fullscreen-overlay--open');
    if (disabled) final.push('fullscreen-overlay--disabled');

    return final.join(' ');
  };

  return createPortal(<div className={classNames()} {...rest} />, document.querySelector('#root'));
};

FullscreenOverlay.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FullscreenOverlay;
