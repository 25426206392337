import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    /* css variables */
    :root {
        --sidebar-header-height: 0px;
        --sidebar-current-width: 0px;
        --sidebar-collapsed-width: 55px;
        --sidebar-expanded-width: 245px;
        --project-order-header-height: 0px;
        --mobile-steps-controls-height: 0px;
        --email-activation-resend-banner-height: 0px;
        --zendesk-chat-z-index: ${({ theme }) => theme.zIndex.zendeskChatWidget};
        --zendesk-chat-bottom: 0px;
        --vh: 1vh;
    }

    // Zendesk iframe chat widget
    iframe#launcher {
        z-index: var(--zendesk-chat-z-index) !important;

        ${({ theme }) => theme.mediaMaxWidth.HD`
            bottom: calc(var(--mobile-steps-controls-height) + var(--email-activation-resend-banner-height)) !important;
        `}
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    ${({ theme }) => theme.mediaMinWidth.mobile`
        .hide-on-desktop {
            display: none;
        }
    `}

    ${({ theme }) => theme.mediaMaxWidth.mobile`
        .hide-on-mobile {
            display: none;
        }

        .dropdown-open {
            .order-project-container,
            .order-project-content-container,
            .main-content-container,
            .accordion-content {
                overflow: initial;
            }
        }
    `}
`;

export default GlobalStyles;
