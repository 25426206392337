import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Price from '../../common/Price/Price';
import Table from '../../common/Table/Table';
import Loader from '../../common/Loader/Loader';
import Message from '../../common/Message/Message';

import { getLatestIncomes } from '../../../services/auth';
import { formatAPIDateString } from 'utils/date.utils';

const AffiliateTable = () => {
  const [affiliateData, setAffiliateData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // component needs data in order to display anything

  const user = useSelector((state) => state.userStore.user);
  const { t } = useTranslation();

  const affiliateEmail = (email) => {
    const splitEmail = email.split('@').map((text, index) => {
      return (
        <span key={`affiliate-email-part-${index}`}>
          {index === 1 && <wbr />}
          {index === 1 && '@'}
          {text}
        </span>
      );
    });

    return splitEmail;
  };

  useEffect(() => {
    const getAffiliateData = async () => {
      try {
        const { id } = user;
        const { data } = await getLatestIncomes(id);
        setAffiliateData(data);
      } catch (e) {
        setError(e.message);
      } finally {
        setIsLoading(false);
      }
    };
    getAffiliateData();
  }, [user]);

  return (
    <>
      {isLoading ? (
        <Loader inline />
      ) : error ? (
        <Message text={error} type="error" />
      ) : (
        affiliateData.length > 0 && (
          <Table width="100%">
            <Table.Header color="yellow">
              <Table.Row>
                <Table.HeaderCell width={5} style={{ paddingLeft: '24px' }}>
                  {t('common:userInfo.referrals.user')}
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>{t('common:userInfo.referrals.date')}</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  {t('common:userInfo.referrals.orderValue')}
                </Table.HeaderCell>
                <Table.HeaderCell width={4} textAlign="right">
                  {t('common:userInfo.referrals.yourComission')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {affiliateData.map((affiliate, index) => (
                <Table.Row key={affiliate.id} paddingY="19px">
                  <Table.Cell
                    className="affiliate-referrer-email"
                    style={{ color: 'black', paddingLeft: '24px' }}
                    bold
                  >
                    <div>{affiliateEmail(affiliate.referrer_mail)}</div>
                    <span className="affiliate-counter hide-on-desktop">#{index + 1}</span>
                  </Table.Cell>
                  <Table.Cell>
                    {formatAPIDateString({ dateString: affiliate.created_at, showTime: false })}
                  </Table.Cell>
                  <Table.Cell className="affiliate-order-value" textAlign="center">
                    <span className="hide-on-desktop">Order value:&nbsp;</span>
                    <Price price={affiliate.value / 0.05} currency={{ code: 'EUR' }} />
                  </Table.Cell>
                  <Table.Cell
                    className="affiliate-comission-cell"
                    textAlign="right"
                    style={{ color: 'black' }}
                    bold
                  >
                    <span className="hide-on-desktop">Comission:&nbsp;</span>
                    <Price
                      className="affiliate-comission"
                      price={affiliate.value}
                      currency={{ code: 'EUR' }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )
      )}
    </>
  );
};

export default AffiliateTable;
