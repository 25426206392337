import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { checkIsRevision, checkIsProofread } from 'utils/vendors.utils';
import { getCatJobStats } from 'services/translators';
import { formatAPIDateString } from 'utils/date.utils';

// Components
import ProjectLanguages from 'components/common/ProjectLanguages/NewProjectLanguages';
import StatusLabel from 'components/common/StatusLabel/StatusLabel';
import Card from 'components/common/Card/Card';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

// Styles
import './style.scss';

// Style semantic ui divider
export const FullWidthDivider = styled(Divider)`
  width: 100%;
  margin: 1rem 0 !important;
`;

const JobCard = ({ job, updateJob }) => {
  const {
    delivery_time,
    revision_end,
    translation_end,
    revision_status_id,
    status_id,
    translator_payable_words,
  } = job;
  const { user } = useSelector((state) => state.userStore);
  const { t } = useTranslation();

  const isRevision = checkIsRevision({ job, user });
  const isProofread = checkIsProofread(job);

  useEffect(() => {
    // set a CAT stats update timer when component mounts
    const intervalID = setInterval(async () => {
      try {
        const dataWithStats = await getCatJobStats(job);
        updateJob(dataWithStats);
      } catch (e) {
        // we can just ignore the error here for now
        console.error('cat stats update error: ', e);
      }
    }, 300000);

    // clear timer when component unmounts
    return () => clearInterval(intervalID);
  });

  // Render variables
  const title = () => {
    let type = 'translation';

    if (isRevision) type = 'revision';
    if (isProofread) type = 'proofread';

    return `${t(`common:serviceProvider.jobs.${type}`)} ${job?.project_id}`;
  };

  const jobLanguages = () => {
    const tempJobData = { ...job };

    if (isProofread) {
      // Remove target language
      delete tempJobData.target_language;
    }

    return tempJobData;
  };

  const statusLabelProps = {
    project: {
      status_id: isRevision ? revision_status_id : status_id,
    },
    isJobType: true,
    isRevision,
  };

  return (
    <Card offsetBorder={false} shadow className="job-card">
      <h2>{title()}</h2>

      <FullWidthDivider />

      <div className="status-labels">
        <StatusLabel {...statusLabelProps} />
      </div>

      <FullWidthDivider />

      <p className="due-date">
        <span>{`${t('common:admin.projects.headers.dueDate')}: `}</span>
        {isRevision
          ? formatAPIDateString({ dateString: revision_end })
          : formatAPIDateString({ dateString: !!translation_end ? translation_end : delivery_time })}
      </p>
      <p className="due-date">
        <span>{t('common:admin.projects.headers.totalWords', 'Word count')}: </span>
        {translator_payable_words}
      </p>

      <FullWidthDivider className="divider-before-lngs" />
      <ProjectLanguages project={jobLanguages()} />

      <Link className="job-link" to={`/job/${job.id}`} />
    </Card>
  );
};

export default JobCard;
