import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCompanyDispatch, useCompanyState } from '../Company.state';

import Message from '../../common/Message/Message';
import TeamsTable from './TeamsTable/TeamsTable';

import { deleteTeam, postUpdateTeam } from '../../../services/teams';
import { sendUserInteraction } from 'utils/tagManager.utils';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import { TeamsContainer } from './Teams.styles';

const Teams = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);

  const {
    teams: { loading, error, data: teams },
  } = useCompanyState();
  const dispatch = useCompanyDispatch();

  const removeTeam = async (teamId) => {
    sendUserInteraction('confirm remove team');
    try {
      await deleteTeam(teamId, user);
      toast.success(t('common:toasts.removeTeam.success'));

      const newTeams = teams.filter((team) => team.id !== teamId);
      dispatch({ type: 'SET_TEAMS', payload: newTeams });
    } catch (e) {
      toast.error(t('common:toasts.removeTeam.error'));
    }
  };

  const updateTeam = async (team) => {
    sendUserInteraction('confirm update team');
    try {
      const newTeamData = await postUpdateTeam({
        team_id: team.id,
        name: team.name,
        billing_detail_id: team.billing_detail_id,
      });
      const newTeams = teams.map((oldTeam) => {
        if (oldTeam.id === team.id) {
          return {
            ...oldTeam,
            ...newTeamData,
          };
        }
        return { ...oldTeam };
      });
      dispatch({ type: 'SET_TEAMS', payload: newTeams });
      toast.success(t('common:toasts.updateTeam.success'));
    } catch (e) {
      toast.error(t('common:toasts.updateTeam.error'));
    }
  };

  return (
    <TeamsContainer>
      {loading ? (
        <LoadingPane height={'100%'} />
      ) : error ? (
        <Message text={error} type="error" />
      ) : (
        <TeamsTable role={user.role} teams={teams} removeTeam={removeTeam} updateTeam={updateTeam} />
      )}
    </TeamsContainer>
  );
};

export default Teams;
