import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import catapultImgSrc from './assets/catapult_project.png';

import Button from 'components/common/Button/Button';
import Card from 'components/common/Card/Card';
import {
  CardButtonContainer,
  CardContentContainer,
  CardHeading,
  CardImage,
  CardText,
} from './CatapultToggle.styles';

import Message from 'components/common/Message/Message';

const CatapultCard = ({
  isUpdatingCatapult = false,
  isUpdatingTaia = false,
  prices = {},
  project = {},
  selectCatapult,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const subscription = useSelector((state) => state.userStore.user.subscription);

  const { can_order_diy } = prices;
  const { total_words } = project;

  const redirectToProject = () => {
    history.push('/projects/create');
  };

  const currentBalance = useMemo(() => {
    if (!!subscription) {
      const { type } = subscription;
      const { current_month_statistic } = subscription;

      switch (type) {
        case 'stripe':
          return `${Number(current_month_statistic.word_limit) - Number(current_month_statistic.limit_used)}`;
        case 'legacy':
          return t('common:projects.analyse.unlimited');
        case 'free':
          return `${Number(subscription.word_limit) - Number(subscription.limit_used)}`;
        default:
          return t('common:projects.analyse.unlimited');
      }
    }
  }, [t, subscription]);

  return (
    <Card className="catapult-card" color="red" width="330px">
      <CardContentContainer>
        <CardImage alt="catapult-image" src={catapultImgSrc} />
        <CardHeading>{t('common:projects.analyse.catapult')}</CardHeading>
        <CardText>{t('common:projects.analyse.catapultText')}</CardText>

        {!can_order_diy ? (
          <Message
            type="warning"
            text={`${t('common:projects.analyse.allowanceWarningText')}: (${total_words}/${currentBalance})`}
          />
        ) : null}

        <CardButtonContainer>
          {can_order_diy ? (
            <Button
              id="catapult-toggle-select-catapult"
              actiontype="primary"
              loading={isUpdatingCatapult}
              disabled={isUpdatingTaia}
              onClick={selectCatapult}
            >
              {t('common:projects.analyse.selectCatapult')}
            </Button>
          ) : (
            <>
              <Button
                id="catapult-toggle-select-catapult"
                actiontype="primary"
                disabled={isUpdatingCatapult}
                onClick={redirectToProject}
              >
                {t('common:projects.analyse.createNewProject')}
              </Button>
              <Link to={`/catapult/packages?billingId=${subscription.billing_id}`}>
                {t('common:catapult.subscription.changePackage')}
              </Link>
            </>
          )}
        </CardButtonContainer>
      </CardContentContainer>
    </Card>
  );
};

export default CatapultCard;
