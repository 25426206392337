import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from 'components/common/Button/Button';
import { BodyText, ButtonAnchor, ButtonsContainer, MainImage, Heading } from './VarsionModal.styles';
import { Modal } from 'semantic-ui-react';

import lightBulbSrc from './light_bulb.png';

import { saveRemindLater } from 'utils/version.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import LINKS from 'constants/links';

const VersionModal = ({ onClose }) => {
  const { t } = useTranslation();

  // we should not render the modal until user is authenticated so we don't spam users before they login
  const { isAuthenticated } = useSelector((state) => state.userStore);

  const onRemindLaterClick = () => {
    sendUserInteraction('clicked remind me later about update');
    saveRemindLater(true);
    onClose();
  };

  const onShowMoreClick = () => {
    sendUserInteraction('clicked tell me more about update');
    saveRemindLater(false);
    onClose();
  };

  const handleClose = () => {
    sendUserInteraction('clicked close modal');
    saveRemindLater(false);
    onClose();
  };

  return isAuthenticated ? (
    <Modal
      closeIcon={true}
      onClose={handleClose}
      open
      style={{
        width: '640px',
        padding: '44px',
      }}
      id="version-modal"
    >
      <MainImage alt="version-update-art-light-bulb" src={lightBulbSrc} />
      <Heading>
        {`${t('common:versionModal.version')} ${process?.env?.REACT_APP_VERSION} ${t(
          'common:versionModal.isHere',
        )}`}
      </Heading>
      <BodyText>{t('common:versionModal.flavorText')}</BodyText>
      <ButtonsContainer>
        <Button
          actiontype="secondary"
          onClick={onRemindLaterClick}
          style={{ marginRight: '11px', width: 'calc(50% - 5.5px)' }}
        >
          {t('common:versionModal.remindMeLater')}
        </Button>
        <ButtonAnchor href={LINKS.blog} rel="noopener noreferrer" target="_blank" onClick={onShowMoreClick}>
          <Button actiontype="primary" style={{ height: '100%', width: '100%' }}>
            {t('common:versionModal.tellMeMore')}
          </Button>
        </ButtonAnchor>
      </ButtonsContainer>
    </Modal>
  ) : null;
};

export default VersionModal;
