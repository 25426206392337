import { StyledHeader } from 'components/common.styles';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const StyledLink = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`;

export const WelcomeHeading = styled(StyledHeader)`
  margin-bottom: 18px !important;
`;

export const WelcomeSubHeading = styled.p`
  text-align: left;
  margin-bottom: 2em !important;

  ${({ theme }) => theme.mediaMaxWidth.desktop`
    text-align: center;
  `}
`;
