import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import SignUpForm from './SignUpForm';
import { Footer, StyledCard, InnerContainer } from '../SignIn/SignIn.styles';
import { StyledLink, StyledSpan, StyledHeader } from '../common.styles';

import { sendUserInteraction } from 'utils/tagManager.utils';

const SignUp = ({ location: { search }, t }) => {
  return (
    <StyledCard>
      <InnerContainer>
        <StyledHeader>{t('common:signUp.signUp')}</StyledHeader>
        <SignUpForm
          email={qs.parse(search).email}
          code={qs.parse(search).code}
          name={qs.parse(search).name}
        />
      </InnerContainer>
      <Footer noBackground>
        <StyledSpan>{t('common:signUp.alreadyHaveAccount')}</StyledSpan>
        <StyledLink onClick={() => sendUserInteraction('clicked link Log in now')} to="/">
          {t('common:signUp.logInNow')}
        </StyledLink>
      </Footer>
    </StyledCard>
  );
};

export default memo(withRouter(withTranslation('common')(SignUp)));
