import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Currency, Hyphen } from './Currencies.styles';

const CurrenciesSelector = ({ currencyId, onCurrencyChange }) => {
  const { t } = useTranslation();

  const currencies = useSelector((state) => state.classifiersStore.currencies);

  return (
    <Container className="package-currencies">
      {`${t('common:catapult.packages.currency')}:`}&nbsp;
      {currencies.map((currency, index) => {
        const addHyphen = index < currencies.length - 1;
        return (
          <Currency
            key={currency.id}
            onClick={() => onCurrencyChange(currency.id)}
            $selected={currency.id === currencyId}
            data-selected={currency.id === currencyId}
            data-cy={`currency-selector-${index}`}
          >
            {`${currency.code}`}
            {addHyphen && <Hyphen>&nbsp;–&nbsp;</Hyphen>}
          </Currency>
        );
      })}
    </Container>
  );
};

export default CurrenciesSelector;
