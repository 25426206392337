import styled from 'styled-components';
import { Input } from 'semantic-ui-react';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const DatepickerContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  .ui.button {
    width: 100%;
  }
  span {
    width: 100%;
  }
`;

export const StatusesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
  .checkbox {
    margin-bottom: 0.5rem;
  }
`;

export const StyledInput = styled(Input)`
  width: 46.6ch;
  margin-bottom: 1rem;
`;
