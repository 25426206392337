export const USERS_TYPE = {
  1: 'Onboarding User',
  2: 'Onboarding Company',
  10: 'Client',
  20: 'Sales',
  21: 'Project Manager',
  29: 'Administrator',
  30: 'Translator',
  40: 'Company manager',
  42: 'Company Member',
  50: 'Deleted',
  60: 'Unverified Reseller',
  61: 'Verified Resellery',
};

export const ADMIN_ROLES = [29];

export const VENDOR_ROLES = [10, 29, 40, 42];

export const ALL_ROLES = [
  1, // new user that needs onboarding
  2, // new company that needs to finish onboarding
  10, // client
  20, // sales
  21, // project manager
  29, // admin
  30, // vendor (translator)
  40, // Company manager
  42, // company member
  60, // unverified reseller
  61, // verified reseller
];

export const COMPANY_ROLES = [
  40, // Company manager/manager
  42, // company member
];

export const SALES_ROLES = [
  20, // sales
  21, // project manager
  61, // verified reseller
];

export const TEAM_ROLES = [
  {
    value: 1,
    translation: 'roles:team:manager',
  },
  { value: 2, translation: 'roles:team:member' },
  { value: 3, translation: 'roles:team:limitedMember' },
  { value: 4, translation: 'roles:team:billingManager' },
];

export const TEAM_TRANSLATIONS = {
  0: 'roles:companyManager',
  1: 'roles:team:manager',
  2: 'roles:team:member',
  3: 'roles:team:limitedMember',
  4: 'roles:team:billingManager',
};
