import { useMediaQuery } from 'hooks/useMediaQuery';
import { useRef, useLayoutEffect, useCallback, useMemo, useEffect } from 'react';
import { theme } from 'style/theme';
import { setCssVariable } from 'utils/base.utils';
import { useOuterClick } from '../../../hooks/useOuterClick';
import { defaultReducer } from './reducers';
import { getInitialState, useControlledReducer, defaultProps } from './utils';

export const useDropdown = (userProps = {}) => {
  const props = { ...defaultProps, ...userProps };
  const { items, reducer, filterFunction = () => true } = props;

  const initialState = getInitialState(props);
  const [state, dispatch] = useControlledReducer(reducer || defaultReducer, initialState, props);
  const { isOpen, highlightedIndex, selectedItem, inputValue, expandUpward } = state;
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  // refs
  const toggleRef = useRef(null);
  const inputRef = useRef(null);
  const menuRef = useRef(null);
  const itemRefs = useRef({});
  const disabledIndexes = useRef(new Set());
  disabledIndexes.current = new Set();

  const filteredItems = useMemo(() => items.filter((item) => filterFunction(item, inputValue)), [
    items,
    inputValue,
    filterFunction,
  ]);

  const scrollIntoView = (itemIndex) => {
    const itemRef = itemRefs.current[itemIndex];

    if (!itemRef) return;
    const { bottom, top } = itemRef.getBoundingClientRect();
    const { top: menuTop, bottom: menuBottom } = menuRef.current.getBoundingClientRect();
    if (bottom > menuBottom) {
      itemRef.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
    if (top < menuTop) {
      itemRef.scrollIntoView();
    }
  };

  const getToggleProps = useCallback(
    (props = {}) => {
      const { onClick = () => {}, onKeyDown = () => {}, onFocus = () => {}, disabled = false } = props;
      const handleToggleClick = () => {};
      const handleToggleKeyDown = (e) => {
        const nOfItems = filteredItems.length;

        if (e.key === 'ArrowDown') {
          e.preventDefault();
          const newIndex = (highlightedIndex + 1) % nOfItems;
          dispatch({ type: 'TOGGLE_ARROW_MOVE', payload: newIndex });
          scrollIntoView(newIndex);
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          const newIndex = (highlightedIndex + nOfItems - 1) % nOfItems;
          dispatch({ type: 'TOGGLE_ARROW_MOVE', payload: newIndex });
          scrollIntoView(newIndex);
        } else if (e.key === 'Enter' && filteredItems.length > 0) {
          if (!disabledIndexes.current.has(highlightedIndex)) {
            dispatch({
              type: 'TOGGLE_ENTER_PRESS',
              payload: filteredItems[highlightedIndex],
            });
          }
        } else if (e.key === 'Escape') {
          dispatch({ type: 'TOGGLE_ESCAPE_PRESS' });
        } else if (e.key === 'Tab') {
          dispatch({ type: 'TOGGLE_TAB_PRESS' });
        }
      };

      const toggleProps = {
        tabIndex: inputRef.current ? undefined : 0,
        ref: toggleRef,
        onFocus,
      };
      if (!disabled) {
        toggleProps.onClick = (e) => {
          dispatch({ type: 'TOGGLE_CLICK' });

          handleToggleClick(e);
          onClick(e);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        };
        toggleProps.onKeyDown = (e) => {
          handleToggleKeyDown(e);
          onKeyDown(e);
        };
      }
      return toggleProps;
    },
    [dispatch, highlightedIndex, filteredItems],
  );

  const getInputProps = useCallback(
    (props = {}) => {
      const onChange = (e) => {
        dispatch({ type: 'SET_SEARCH', payload: e.target.value });
      };
      return { onChange, value: inputValue, ref: inputRef };
    },
    [dispatch, inputValue],
  );

  const getMenuProps = useCallback(
    ({ onMouseLeave = () => {}, ...rest } = {}) => {
      const handleMenuMouseLeave = (e) => {
        dispatch({ type: 'MENU_MOUSE_LEAVE' });
        onMouseLeave(e);
      };

      return {
        ref: menuRef,
        onMouseLeave: handleMenuMouseLeave,
        ...rest,
      };
    },
    [dispatch],
  );

  const getItemProps = useCallback(
    ({ item, index, onClick = () => {}, onMouseMove = () => {}, ref, disabled, ...rest }) => {
      const handleItemMouseMove = () => {
        if (index === highlightedIndex) {
          return;
        }
        dispatch({
          type: 'ITEM_MOUSE_MOVE',
          payload: index,
        });
      };
      const handleItemClick = () => {
        dispatch({
          type: 'ITEM_CLICK',
          payload: filteredItems[index],
        });
      };

      const itemProps = {
        ref: (ref) => {
          itemRefs.current[index] = ref;
        },
        ...rest,
      };

      if (!disabled) {
        itemProps.onClick = (e) => {
          handleItemClick();
          onClick(e);
        };

        itemProps.onMouseMove = (e) => {
          handleItemMouseMove();
          onMouseMove(e);
        };
      } else {
        disabledIndexes.current.add(index);
      }

      return itemProps;
    },
    [dispatch, filteredItems, highlightedIndex],
  );

  useOuterClick((e) => {
    if (!isOpen) return;
    if (toggleRef.current && !toggleRef.current.contains(e.target)) {
      dispatch({ type: 'TOGGLE_CLICK' });
    }
  }, menuRef);

  const handleRemoveItem = useCallback(
    (item) => {
      dispatch({ type: 'LABEL_REMOVE_CLICK', payload: item });
    },
    [dispatch],
  );

  const handleClearAll = () => {
    dispatch({ type: 'CLEAR_ALL', payload: initialState.selectedItem });
  };

  const handleCloseDropdown = () => {
    dispatch({ type: 'TOGGLE_CLICK' });
  };

  useLayoutEffect(() => {
    if (isOpen && menuRef.current && toggleRef.current) {
      const { bottom } = toggleRef.current.getBoundingClientRect();
      // offsetHeight property is not affected by css transforms, while getBoundingClientRect is
      const menuHeight = menuRef.current.offsetHeight;
      const shouldExpandUpward = bottom + menuHeight > window.innerHeight;
      dispatch({ type: 'SET_EXPAND_UPWARD', payload: shouldExpandUpward });
    }
  }, [isOpen, dispatch]);

  // On open change
  useEffect(() => {
    if (!isMobile) return;

    setCssVariable('--zendesk-chat-z-index', isOpen ? -1 : theme.zIndex.zendeskChatWidget);

    // Add/remove 'drodown-open' css class, this fixes overflow issues when opening dropdown on mobile
    document.body.classList[isOpen ? 'add' : 'remove']('dropdown-open');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    // prop getters
    getToggleProps,
    getInputProps,
    getMenuProps,
    getItemProps,

    // actions
    handleRemoveItem,
    handleCloseDropdown,
    handleClearAll,

    // state
    highlightedIndex,
    isOpen,
    selectedItem,
    items: filteredItems,
    inputValue,
    expandUpward,
  };
};
