import styled from 'styled-components';
import Pagination from '../common/Pagination/Pagination';
import Tab from '../common/Tab/Tab';

export const CompanyContainer = styled.div`
  margin: 2em 37px;
  min-height: calc(100% - 1em);
  display: grid;
  grid-template-rows: auto 1fr;

  .button {
    font-family: 'DM Sans', sans-serif !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: column;
`;

export const StyledPagination = styled(Pagination)`
  margin: auto auto 3em;
`;

export const Toolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 1.1em;
`;
