import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Accordion from '../../../../common/Accordion/Accordion';
import BillingCard from '../../../../common/BillingDetails/BillingCard/BillingCard';
import Button from '../../../../common/Button/Button';
import CreditCardForm from '../../../../Checkout/Payment/CreditCardForm/CreditCardForm';

import { ButtonsContainer, Container, MethodContainer, SectionHeading } from './PaymentInfo.styles';

import { getDefaultPaymentMethod, getSetupIntent, patchPaymentMethod } from '../../../../../services/billing';
import { getSubscriptionById } from '../../../../../services/subscriptions';
import { sendUserInteraction } from 'utils/tagManager.utils';
import CardPaymentMethod from 'components/Checkout/Payment/CardPaymentMethod/CardPaymentMethod';

const PaymentInfo = ({
  paymentMethod = null,
  subscriptionData,
  updatePaymentMethod,
  updateSubscriptionDetails,
}) => {
  const countries = useSelector((state) => state.classifiersStore.countries);
  const elements = useElements();
  const stripe = useStripe();
  const [showCardForm, setShowCardForm] = useState(false);
  const [settingCard, setSettingCard] = useState(false);
  const { t } = useTranslation();

  const fetchSubscriptionDetails = async () => {
    try {
      const newDetails = await getSubscriptionById(subscriptionData.id);
      const newMethod = await getDefaultPaymentMethod({ billing_id: subscriptionData.billing_detail.id });

      updateSubscriptionDetails(newDetails);
      updatePaymentMethod(newMethod);
    } catch (e) {
      throw e;
    }
  };

  const handleChangeClick = (e) => {
    sendUserInteraction('subscription overview change payment method click');
    e.preventDefault();
    setShowCardForm(true);
  };

  const handleCancelClick = (e) => {
    sendUserInteraction('subscription overview cancel change payment method click');
    e.preventDefault();
    setShowCardForm(false);
  };

  const handleSubmit = async (e) => {
    sendUserInteraction('subscription overview confirm change payment method click');
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    try {
      setSettingCard(true);

      const cardElement = elements.getElement(CardElement);
      const intent = await getSetupIntent(subscriptionData.billing_detail.id);

      const country = countries.find((c) => c.id === subscriptionData.billing_detail.country_id);

      const paymentMethod = await stripe.confirmCardSetup(intent.client_secret, {
        payment_method: {
          type: 'card',
          card: cardElement,
          billing_details: {
            name: subscriptionData.billing_detail.companyName,
            email: subscriptionData.billing_detail.user_email || null,
            address: {
              line1: subscriptionData.billing_detail.address,
              line2: subscriptionData.billing_detail.address2,
              state: subscriptionData.billing_detail.state,
              city: subscriptionData.billing_detail.city,
              country: country.iso_code,
            },
          },
        },
      });

      if (paymentMethod.hasOwnProperty('error')) {
        throw paymentMethod.error;
      } else {
        await patchPaymentMethod({
          billing_id: subscriptionData.billing_detail.id,
          payment_method: paymentMethod.setupIntent.payment_method,
        });

        await fetchSubscriptionDetails();

        toast.success(t('common:toasts.catapultUpdateCard.success'));
      }
    } catch (e) {
      toast.error(`${t('common:toasts.catapultUpdateCard.error')} ${e.message}`);
    } finally {
      setSettingCard(false);
      setShowCardForm(false);
    }
  };

  return (
    <Accordion title={t('common:catapult.subscription.paymentMethods.heading')}>
      <Container>
        <BillingCard billingDetails={subscriptionData.billing_detail} isCollapsible={false} />
        {paymentMethod ? (
          <div>
            <SectionHeading>{t('common:catapult.subscription.paymentMethods.cardHeading')}</SectionHeading>
            <MethodContainer>
              {!showCardForm ? (
                <CardPaymentMethod paymentMethod={paymentMethod} selected={true} />
              ) : (
                <CreditCardForm />
              )}
            </MethodContainer>
            <ButtonsContainer>
              <Button
                actiontype="secondary"
                disabled={settingCard}
                onClick={!showCardForm ? handleChangeClick : handleCancelClick}
              >
                {!showCardForm
                  ? t('common:catapult.subscription.paymentMethods.changeCard')
                  : t('common:catapult.subscription.paymentMethods.cancel')}
              </Button>
              {showCardForm ? (
                <Button actiontype="primary" loading={settingCard} onClick={handleSubmit}>
                  {t('common:catapult.subscription.paymentMethods.submit')}
                </Button>
              ) : null}
            </ButtonsContainer>
          </div>
        ) : (
          t('common:catapult.subscription.paymentMethods.methodNotSet')
        )}
      </Container>
    </Accordion>
  );
};

export default PaymentInfo;
