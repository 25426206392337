import { AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useOuterClick } from 'hooks/useOuterClick';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { theme } from 'style/theme';
import Button from '../../common/Button/Button';
import {
  FiltersSidebarContainer,
  SidbarButtonContainer,
  SidebarPanel,
  CloseButtonContainer,
  ContentWrapper,
} from './FilterSidebar.style';

const FilterSidebar = ({
  children,
  trigger = null,
  width = 'calc(30.6ch + 1rem + 2rem + 14px)',
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  // Refs
  const sidebarRef = useRef(null);

  const openSidebar = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
  };

  // Handle outer click
  useOuterClick(closeSidebar, sidebarRef);

  /**
   * Handle keydown event
   */
  const handleKeydown = useCallback(
    (e) => {
      if (e.keyCode === 27 && open) {
        closeSidebar();
      }
    },
    [open],
  );

  const getTrigger = () => {
    if (trigger !== null) {
      const Trigger = trigger;
      return <Trigger onClick={openSidebar} />;
    }
    return (
      <SidbarButtonContainer className="default-trigger">
        <Button actiontype="secondary" circular icon="filter" onClick={openSidebar} />
      </SidbarButtonContainer>
    );
  };

  /**
   * Adds an event listener for keydown
   */
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [handleKeydown]);

  return (
    <FiltersSidebarContainer {...rest}>
      {getTrigger()}
      <AnimatePresence>
        {open && (
          <SidebarPanel
            animate={{
              width: isMobile ? '100vw' : width,
            }}
            exit={{ width: 0 }}
            initial={{ width: 0 }}
            transition={{ duration: 1, type: 'spring' }}
            ref={sidebarRef}
          >
            <CloseButtonContainer>
              <Button circular icon="times" onClick={closeSidebar} />
            </CloseButtonContainer>
            <ContentWrapper>{children}</ContentWrapper>
          </SidebarPanel>
        )}
      </AnimatePresence>
    </FiltersSidebarContainer>
  );
};

export default FilterSidebar;
