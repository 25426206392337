import { useLayoutEffect, useRef } from 'react';

// Polyfill
import ResizeObserver from 'resize-observer-polyfill';

export const useResizeObserver = (callback, ref) => {
  const observer = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(callback);
      observer.current = resizeObserver;
      resizeObserver.observe(ref.current);
      return () => {
        observer.current.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
};
