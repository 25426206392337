import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Form, Modal, Popup } from 'semantic-ui-react';

import Message from '../../../common/Message/Message';
import { postCouponGenerate } from '../../../../services/coupons';
import { VoucherAmount, StyledButton, ButtonsContainer } from './NewCouponModal.style';
import Button from '../../../common/Button/Button';

import { currencyByCodeSelector } from '../../../../store/selectors';
import { sendUserInteraction } from 'utils/tagManager.utils';

const couponTypes = [
  { key: 0, text: 'Percentage discount', value: 'percentage' },
  { key: 1, text: 'Voucher', value: 'voucher' },
];

const NewCouponModal = ({ t, onSubmit }) => {
  const currencies = useSelector((state) => state.classifiersStore.currencies);
  const currencyOptions = currencies.map((currencyObject) => {
    const { id, symbol, code } = currencyObject;
    return {
      key: id,
      text: `${code} (${symbol})`,
      value: id,
    };
  });

  const getCurrencyByCode = useSelector(currencyByCodeSelector);
  const eurId = getCurrencyByCode('EUR')['id'];

  const [couponModal, setCouponModal] = useState(false);
  const [couponType, setCouponType] = useState('percentage');
  const [coupon, setCoupon] = useState({
    currency_id: eurId,
    useLimit: null,
    code: null,
    ratio: 0,
    voucher: 0,
  });
  const [error, setError] = useState(false);

  const handleModal = (modalOpen) => {
    setCouponModal(modalOpen);
  };

  const handleCouponTypeChange = (e, target) => {
    sendUserInteraction(`change coupon type: ${target.value}`);
    setCouponType(target.value);
  };

  const handleChange = (e, { value, name }) => {
    let newCoupon = { ...coupon };
    if (name === 'ratio') {
      if (value < 0) {
        value = 0;
      }

      if (value > 100) {
        value = 100;
      }
    }

    if (name === 'voucher') {
      if (value < 0) {
        value = 0;
      }
    }

    newCoupon[name] = value;
    setCoupon(newCoupon);
  };

  const handleCurrencyChange = (e, { value }) => {
    sendUserInteraction(`change coupon currency: ${value}`);
    const newCoupon = { ...coupon, currency_id: value };
    setCoupon(newCoupon);
  };

  const handleSubmit = async () => {
    try {
      sendUserInteraction('generate new coupon submit');
      await postCouponGenerate({
        currency_id: coupon.currency_id,
        code: coupon.code,
        ratio: couponType === 'percentage' ? parseFloat(coupon.ratio) : parseFloat(coupon.voucher),
        type: couponType,
        use_limit: coupon.useLimit,
      });
      onSubmit();
      setCouponModal(false);
      toast.success(t('common:toasts.generateCoupon.success'));
    } catch (e) {
      toast.error(t('common:toasts.generateCoupon.error'));
      setError(true);
      throw e;
    }
  };

  return (
    <Modal
      open={couponModal}
      onClose={() => handleModal(false)}
      trigger={
        <Popup
          content={t('common:admin.coupons.buttonTooltip')}
          trigger={
            <Button
              icon="plus"
              onClick={() => {
                sendUserInteraction('open new coupon modal');
                handleModal(true);
              }}
            />
          }
        />
      }
      size="tiny"
    >
      <Modal.Header>{t('common:admin.coupons.generateCoupon')}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <Form.Dropdown
              label={t('common:admin.coupons.couponType')}
              selection
              options={couponTypes}
              value={couponType}
              onChange={handleCouponTypeChange}
            ></Form.Dropdown>
          </Form.Field>
          <Form.Field>
            <Form.Input
              label={t('common:admin.coupons.couponCode')}
              type="text"
              placeholder={t('common:admin.coupons.emptyAutoGenerate')}
              name="code"
              onChange={handleChange}
              value={coupon.code || ''}
            ></Form.Input>
          </Form.Field>
          {couponType === 'percentage' ? (
            <Form.Field>
              <Form.Input
                label={t('common:admin.coupons.discountPercentage')}
                type="number"
                placeholder={t('common:admin.coupons.percentage')}
                name="ratio"
                onChange={handleChange}
                value={coupon.ratio}
                min={0}
              ></Form.Input>
            </Form.Field>
          ) : (
            <VoucherAmount>
              <Form.Input
                label={t('common:admin.coupons.voucherAmount')}
                type="number"
                placeholder={t('common:admin.coupons.amount')}
                fluid
                name="voucher"
                onChange={handleChange}
                value={coupon.voucher}
              ></Form.Input>
              <Form.Dropdown
                fluid
                onChange={handleCurrencyChange}
                options={currencyOptions}
                selection
                value={coupon.currency_id}
              />
            </VoucherAmount>
          )}
          <Form.Field>
            <Form.Input
              label={t('common:admin.coupons.useLimit')}
              type="number"
              placeholder={t('common:admin.coupons.emptyUnlimited')}
              name="useLimit"
              onChange={handleChange}
              value={coupon.useLimit || ''}
              min={0}
            ></Form.Input>
          </Form.Field>

          {error ? (
            <Message text={t('common.admin.coupons.errorSubmit')} type="error" />
          ) : (
            <ButtonsContainer>
              <StyledButton actiontype="cancel" onClick={() => handleModal(false)}>
                {t('common:cancel')}
              </StyledButton>
              <StyledButton actiontype="create" type="submit">
                {t('common:submit')}
              </StyledButton>
            </ButtonsContainer>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation('common')(NewCouponModal);
