import ActivateUser from '../components/ActivateUser/ActivateUser';
import AdminDashboard from '../components/AdminDashboard/AdminDashboard';
import AdminUser from '../components/AdminUser/AdminUser';
import AdminMultimedia from '../components/AdminMultimedia/AdminMultimedia';
import AdminUsers from '../components/AdminUsers/AdminUsers';
import Affiliate from '../components/Affiliate/Affiliate';
import NewProject from '../components/NewProject/NewProject';
import PasswordReset from '../components/PasswordReset/PasswordReset';
import RequestPasswordReset from '../components/PasswordReset/RequestPasswordReset';
import Project from '../components/Project/Project';
import Projects from '../components/Projects/Projects';
import SignIn from '../components/SignIn/SignIn';
import SignUp from '../components/SignUp/SignUp';
import Subscriptions from '../components/Catapult/Subscriptions/Subscriptions';
import Tutorial from '../components/Tutorial/Tutorial';
import UserProfile from '../components/UserProfile/UserProfile';
import AdminProject from '../components/AdminProject/AdminProject';
import AdminProjects from '../components/AdminProjects/AdminProjects';
import Team from '../components/Team/Team';
import AdminCompanies from '../components/AdminCompanies/AdminCompanies';
import AdminVendors from '../components/AdminVendors/AdminVendors';
import AdminCoupons from '../components/AdminCoupons/AdminCoupons';
import Company from '../components/Company/Company';
import FileStorage from '../components/FileStorage/FileStorage';
import Teams from '../components/Teams/Teams';
import InviteSignUp from '../components/InviteSignUp/InviteSignUp';
import AdminInvoices from '../components/AdminInvoices/AdminInvoices';
import UserAuth from '../components/Catapult/UserAuth/UserAuth';
import AdminSubscriptionPackages from '../components/AdminSubscriptionPackages/AdminSubscriptionPackages';
import CreatePackage from '../components/CreatePackage/CreatePackage';
import AdminSubscriptionPackage from '../components/AdminSubscriptionPackage/AdminSubscriptionPackage';
import Packages from '../components/Catapult/Packages/Packages';
import Checkout from '../components/Checkout/Checkout';
import PaymentFailed from '../components/PaymentFailed/PaymentFailed';
import AdminUserOverview from '../components/Catapult/Admin/UserOverview/AdminUserOverview';
import AdminSubscriptionOverview from '../components/Catapult/Admin/SubscriptionOverview';
import Maintenance from '../components/Maintenance/Maintenance';
import AdminProjectCreate from 'components/AdminProjectCreate/AdminProjectCreate';
import AdminProjectCalendar from 'components/AdminProjectCalendar';
import OnboardingWelcome from 'components/Onboarding/OnboardingWelcome/OnboardingWelcome';
import OnboardingCustomer from 'components/Onboarding/OnboardingCustomer/OnboardingCustomer';
import NoCompanySubscription from '../components/Catapult/Subscriptions/NoCompanySubscription/NoCompanySubscription';
import UpdateProject from 'components/UpdateProject/UpdateProject';
import DownloadAttempted from 'components/DownloadAttempted/DownloadAttempted';
import SignupInstantQuote from 'components/SignupInstantQuote/SignupInstantQuote';
import RedirectCatapult from 'components/RedirectCatapult/RedirectCatapult';
import SupportContent from 'components/Support/Support';
import VendorOnboardingRedirect from 'components/ServiceProvider/VendorOnboarding/VendorOnboardingRedirect';

import { ADMIN_ROLES, VENDOR_ROLES } from '../constants/roles';
import i18n from '../i18n';
import Job from 'components/ServiceProvider/Job/Job';
import JobsRedirect from 'components/ServiceProvider/Jobs/JobsRedirect';
import AdminAddVendor from 'components/AdminAddVendor/AdminAddVendor';
import AdminVendor from 'components/AdminVendor/AdminVendor';

export const teamsRoutes = [
  {
    type: 'private',
    name: 'Team',
    allowedRoles: [29, 40, 42], // TODO: Set correct roles
    path: '/team/:id/:tabName?',
    component: Team,
    requiresTeam: true,
    pageTitle: i18n.t('common:sidebar.team'),
  },
];

const subscriptionRoute = {
  type: 'private',
  name: 'Subscription',
  allowedRoles: [10, 29, 40, 42],
  path: '/catapult/subscriptions',
  component: Subscriptions,
  pageTitle: i18n.t('common:sidebar.catapultSubscriptions'),
  sidebar: {
    level: 0,
    icon: 'card-atm',
    link: '/catapult/subscriptions/',
    text: 'common:sidebar.catapultSubscriptions',
    highlighted: ['/catapult/subscriptions', '/catapult/subscriptions/:billingId'],
  },
};

// this is exported in order to generate tabs for the Company component
// TODO figure out how to pass company id from redux here in order to generate sidebar links
export const companyRoutes = [
  {
    type: 'private',
    name: 'Company teams',
    allowedRoles: [29, 40], // TODO: figure out how to only show this to user role 40
    path: '/company/:id/:tabName?',
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: -1,
      icon: 'pricetag-alt',
      link: '/company/:id/teams',
      text: 'common:sidebar.companyTeams',
      highlighted: ['/company/:id/teams'],
    },
    requiresCompany: true,
  },
  {
    type: 'private',
    name: 'Company members',
    allowedRoles: [29, 40], // TODO: figure out how to only show this to user role 40
    path: '/company/:id/:tabName?',
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: -1,
      icon: 'users-alt',
      link: '/company/:id/members',
      text: 'common:sidebar.companyMembers',
      highlighted: ['/company/:id/members'],
    },
    requiresCompany: true,
  },
  {
    type: 'private',
    name: 'Company invites',
    allowedRoles: [29, 40], // TODO: figure out how to only show this to user role 40
    path: '/company/:id/:tabName?',
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: -1,
      icon: 'envelope',
      link: '/company/:id/invites',
      text: 'common:sidebar.companyInvites',
      highlighted: ['/company/:id/invites'],
    },
    requiresCompany: true,
  },
  {
    type: 'private',
    name: 'Company billing',
    allowedRoles: [29, 40], // TODO: figure out how to only show this to user role 40
    path: '/company/:id/:tabName?',
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: -1,
      icon: 'money-bill',
      link: '/company/:id/billing',
      text: 'common:sidebar.companyBilling',
      highlighted: ['/company/:id/billing'],
    },
    requiresCompany: true,
  },
  {
    type: 'private',
    name: 'Company projects',
    allowedRoles: [29],
    path: '/company/:id/:tabName?',
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: -1,
      icon: 'apps',
      link: '/company/:id/projects',
      text: 'common:sidebar.companyProjects',
      highlighted: ['/company/:id/projects'],
    },
    requiresCompany: true,
  },
  {
    type: 'private',
    name: 'Company info',
    allowedRoles: [29],
    path: '/company/:id/:tabName?',
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: -1,
      icon: 'apps',
      link: '/company/:id/info',
      text: 'common:sidebar.companyInfo',
      highlighted: ['/company/:id/info'],
    },
    requiresCompany: true,
  },
  {
    type: 'private',
    name: 'Company invoices',
    allowedRoles: [29, 40],
    path: '/company/:id/:tabName?',
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: -1,
      icon: 'apps',
      link: '/company/:id/invoices',
      text: 'common:sidebar.companyInvoices',
      highlighted: ['/company/:id/invoices'],
    },
    requiresCompany: true,
  },
];

export const routes = [
  {
    type: 'public',
    name: 'SignIn',
    path: '/',
    component: SignIn,
  },
  {
    type: 'public',
    name: 'SignUp',
    path: '/register',
    component: SignUp,
  },
  {
    type: 'public',
    name: 'SignUp',
    path: '/instant-quote',
    component: SignupInstantQuote,
    pageTitle: 'Instant quote',
  },
  {
    type: 'public',
    name: 'Affiliate',
    path: '/affiliate/:referralToken',
    component: Affiliate,
  },
  {
    type: 'public',
    name: 'InviteSignUp',
    path: '/invite/:token',
    component: InviteSignUp,
  },
  {
    type: 'public',
    name: 'CatapultAuth',
    path: '/catapult-landing',
    component: UserAuth,
  },
  {
    allowedRoles: [42],
    type: 'private',
    name: 'noCompanyCatapultSubscription',
    path: '/catapult/no-company-subscription',
    component: NoCompanySubscription,
  },
  {
    type: 'normal',
    name: 'RequestPasswordReset',
    path: '/password/reset',
    component: RequestPasswordReset,
  },
  {
    type: 'normal',
    name: 'PasswordReset',
    path: '/email/user/password/new/:token',
    component: PasswordReset,
  },
  {
    type: 'normal',
    name: 'ActivateUser',
    path: '/email/user/activate/:token',
    component: ActivateUser,
  },
  {
    type: 'private',
    name: 'OnboardingWelcome',
    path: '/onboarding',
    component: OnboardingWelcome,
    isFullscreen: true,
    allowedRoles: [1, 2],
  },
  {
    type: 'private',
    name: 'OnboardingWelcome',
    path: '/onboarding/welcome',
    component: OnboardingWelcome,
    isFullscreen: true,
    allowedRoles: [1, 2],
  },
  {
    type: 'private',
    name: 'OnboardingCustomer',
    path: '/onboarding/customer',
    component: OnboardingCustomer,
    isFullscreen: true,
    allowedRoles: [1, 2],
  },
  {
    type: 'private',
    name: 'OnboardingVendor',
    path: '/vendor-onboarding',
    pageTitle: 'Vendor onboarding',
    component: VendorOnboardingRedirect,
    noWrapper: true,
    allowedRoles: VENDOR_ROLES,
  },
  {
    type: 'private',
    name: 'CatapultRedirect',
    path: '/catapult-job/:id',
    component: RedirectCatapult,
    isFullscreen: true,
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
  },
  {
    type: 'private',
    name: 'UserProfile',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/profile',
    component: UserProfile,
    pageTitle: i18n.t('common:userInfo.myProfile'),
  },
  {
    type: 'private',
    name: 'Checkout',
    allowedRoles: [1, 2, 10, 40, 41, 42, 29, 60, 61],
    path: '/checkout',
    isFullscreen: true,
    component: Checkout,
  },
  {
    type: 'private',
    name: 'PaymentFailed',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/payment-intent/:token',
    isFullscreen: true,
    component: PaymentFailed,
  },
  {
    type: 'private',
    name: 'DownloadAttempted',
    allowedRoles: ADMIN_ROLES,
    path: '/download-attempted/:id',
    isFullscreen: true,
    component: DownloadAttempted,
  },
  {
    type: 'private',
    name: 'Tutorial',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/tutorial',
    component: Tutorial,
    pageTitle: i18n.t('common:videoTutorial'),
  },
  {
    type: 'private',
    name: 'SupportPrivate',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/support',
    component: SupportContent,
    pageTitle: i18n.t('common:support.supportCenter'),
  },
  {
    type: 'private',
    name: 'Projects',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/projects',
    component: Projects,
    pageTitle: i18n.t('common:projects.myProjects'),
    sidebar: {
      level: 0,
      icon: 'apps',
      link: '/projects',
      text: 'common:sidebar.projects',
      highlighted: ['/projects'],
    },
  },
  {
    type: 'private',
    name: 'NewProject',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/projects/create',
    pageTitle: i18n.t('common:projects.createProject.newProject'),
    component: NewProject,
    sidebar: {
      level: 0,
      id: 'create-new-project-sidebar-link',
      icon: 'plus-square',
      link: '/projects/create',
      text: 'common:sidebar.newProject',
      highlighted: ['/projects/create'],
    },
  },
  {
    type: 'private',
    name: 'Project',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/project/:id',
    component: Project,
    pageTitle: i18n.t('common:projects.userProject.project'),
  },
  {
    type: 'private',
    name: 'AdminDashboard',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/dashboard',
    component: AdminDashboard,
    pageTitle: i18n.t('common:sidebar.adminPanel'),
    sidebar: {
      level: 0,
      icon: 'dashboard',
      link: '/admin/dashboard',
      text: 'common:sidebar.adminPanel',
      highlighted: [
        '/admin/dashboard',
        '/admin/users',
        '/admin/projects',
        '/admin/uploader',
        '/admin/settings',
        '/admin/files',
        '/admin/companies',
        '/admin/packages',
        '/admin/catapult/users',
        '/admin/calendar',
      ],
    },
  },
  {
    type: 'private',
    name: 'AdminUsers',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/users',
    component: AdminUsers,
    pageTitle: i18n.t('common:sidebar.adminUsers'),
    sidebar: {
      level: 1,
      icon: 'users-alt',
      link: '/admin/users',
      text: 'common:sidebar.adminUsers',
      highlighted: ['/admin/users'],
    },
  },
  {
    type: 'private',
    name: 'AdminUser',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/user/:user_id',
    component: AdminUser,
    pageTitle: i18n.t('common:sidebar.adminUsers'),
  },
  {
    type: 'private',
    name: 'AdminCompanies',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/companies',
    component: AdminCompanies,
    pageTitle: i18n.t('common:sidebar.adminCompanies'),
    sidebar: {
      level: 1,
      icon: 'building',
      link: '/admin/companies',
      text: 'common:sidebar.adminCompanies',
      highlighted: ['/admin/companies'],
    },
  },
  {
    type: 'private',
    name: 'AdminProjects',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/projects',
    component: AdminProjects,
    pageTitle: i18n.t('common:sidebar.adminProjects'),
    sidebar: {
      level: 1,
      icon: 'apps',
      link: '/admin/projects',
      text: 'common:sidebar.adminProjects',
      highlighted: ['/admin/projects'],
    },
  },
  {
    type: 'private',
    name: 'AdminProjects',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/project/create',
    component: AdminProjectCreate,
    pageTitle: i18n.t('common:sidebar.adminCreateProjects'),
    sidebar: {
      level: 1,
      icon: 'suitcase',
      link: '/admin/project/create',
      text: 'common:sidebar.adminCreateProjects',
      highlighted: ['/admin/project/create'],
    },
  },
  {
    type: 'private',
    name: 'AdminProject',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/project/:project_id',
    component: AdminProject,
    pageTitle: i18n.t('common:userProject.project'),
  },
  {
    type: 'private',
    name: 'AdminProject',
    allowedRoles: ADMIN_ROLES,
    path: '/project/:id/settings',
    component: UpdateProject,
    pageTitle: i18n.t('common:userProject.project'),
  },
  {
    type: 'private',
    name: 'AdminInvoices',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/invoices',
    component: AdminInvoices,
    pageTitle: i18n.t('common:sidebar.adminInvoices'),
    sidebar: {
      level: 1,
      icon: 'invoice',
      link: '/admin/invoices',
      text: 'common:sidebar.adminInvoices',
      highlighted: ['/admin/invoices'],
    },
  },
  {
    type: 'private',
    name: 'AdminCoupons',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/coupons',
    component: AdminCoupons,
    pageTitle: i18n.t('common:sidebar.adminCoupons'),
    sidebar: {
      level: 1,
      icon: 'pricetag-alt',
      link: '/admin/coupons',
      text: 'common:sidebar.adminCoupons',
      highlighted: ['/admin/coupons'],
    },
  },
  {
    type: 'private',
    name: 'OnboardingVendor',
    path: '/admin/vendor-onboarding',
    pageTitle: 'Vendor onboarding',
    component: AdminAddVendor,
    noWrapper: true,
    allowedRoles: ADMIN_ROLES,
  },
  {
    type: 'private',
    name: 'AddNewTranslator',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/vendors',
    component: AdminVendors,
    pageTitle: i18n.t('common:sidebar.adminTranslators'),
    sidebar: {
      level: 1,
      icon: 'english-to-chinese',
      link: '/admin/vendors',
      text: 'common:sidebar.adminTranslators',
      highlighted: ['/admin/vendors'],
    },
  },
  {
    type: 'private',
    name: 'AdminVendor',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/vendor/:id',
    component: AdminVendor,
    pageTitle: 'Admin vendors',
  },
  {
    type: 'private',
    name: 'AdminMultimedia',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/files',
    component: AdminMultimedia,
    pageTitle: i18n.t('common:sidebar.adminMultimedia'),
    sidebar: {
      level: 1,
      icon: 'database',
      link: '/admin/files',
      text: 'common:sidebar.adminMultimedia',
      highlighted: ['/admin/files'],
    },
  },
  {
    type: 'private',
    name: 'AdminSubscriptions',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/packages',
    component: AdminSubscriptionPackages,
    pageTitle: i18n.t('common:sidebar.adminSubscriptions'),
    sidebar: {
      level: 1,
      icon: 'credit-card',
      link: '/admin/packages',
      text: 'common:sidebar.adminSubscriptions',
      highlighted: ['/admin/packages'],
    },
  },
  {
    type: 'private',
    name: 'AdminPackages',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/packages/create',
    component: CreatePackage,
    pageTitle: i18n.t('common:admin.createPackage.header'),
  },
  {
    type: 'private',
    name: 'AdminPackage',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/packages/:id',
    component: AdminSubscriptionPackage,
    pageTitle: i18n.t('common:sidebar.adminSubscriptions'),
  },
  {
    type: 'private',
    name: 'AdminCatUserOverview',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/catapult/users',
    component: AdminUserOverview,
    pageTitle: i18n.t('common:catapult.admin.usersOverview'),
    sidebar: {
      level: 1,
      icon: 'credit-card',
      link: '/admin/catapult/users',
      text: 'common:catapult.admin.usersOverview',
      highlighted: ['/admin/catapult/users'],
    },
  },
  {
    type: 'private',
    name: 'AdminCalendar',
    allowedRoles: ADMIN_ROLES,
    path: '/admin/calendar',
    component: AdminProjectCalendar,
    pageTitle: i18n.t('ccommon:sidebar.adminCalendar'),
    sidebar: {
      level: 1,
      icon: 'schedule',
      link: '/admin/calendar',
      text: 'common:sidebar.adminCalendar',
      highlighted: ['/admin/calendar'],
    },
  },
  {
    type: 'private',
    name: 'Jobs',
    allowedRoles: VENDOR_ROLES,
    path: '/jobs',
    component: JobsRedirect,
    pageTitle: i18n.t('common:sidebar.vendorJobs'),
    sidebar: {
      level: 0,
      icon: 'english-to-chinese',
      link: '/jobs',
      text: 'common:sidebar.vendorJobs',
      highlighted: ['/jobs'],
    },
  },
  {
    type: 'private',
    name: 'Job',
    allowedRoles: VENDOR_ROLES,
    path: '/job/:id',
    component: Job,
    pageTitle: 'Single job',
  },
  {
    type: 'private',
    name: 'File storage',
    allowedRoles: [10, 40, 41, 42, 29, 60, 61],
    path: '/fileStorage',
    component: FileStorage,
    pageTitle: i18n.t('common:sidebar.fileStorage'),
    sidebar: {
      level: 0,
      icon: 'database',
      text: 'common:sidebar.fileStorage',
      link: '/fileStorage',
      highlighted: ['/fileStorage'],
      requiredId: [1921, 2288],
    },
  },
  {
    type: 'private',
    name: 'Company',
    allowedRoles: [29, 40], // TODO: figure out how to only show this to user role 40 in the sidebar
    path: '/company/:id/:tabName?', // TODO: figure out how to pass company id as path here
    component: Company,
    pageTitle: i18n.t('common:company.companyManagement'),
    sidebar: {
      level: 0,
      icon: 'building',
      link: '/company/:id/',
      text: 'common:sidebar.company',
      highlighted: ['/company/:id/:tabName?'],
    },
    requiresCompany: true,
  },
  ...companyRoutes,
  ...teamsRoutes,
  {
    type: 'private',
    name: 'CatapultPackages',
    allowedRoles: [10, 40, 42, 29],
    path: '/catapult/packages',
    component: Packages,
    pageTitle: i18n.t('common:catapult.packages.heading'),
  },
  {
    type: 'normal',
    name: 'Maintenance',
    path: '/maintenance',
    component: Maintenance,
  },
  {
    type: 'private',
    name: 'Subscription',
    allowedRoles: [10, 29, 40, 42],
    path: '/catapult/subscriptions/:billingId',
    component: Subscriptions,
    pageTitle: i18n.t('common:sidebar.catapultSubscriptions'),
  },
  {
    type: 'private',
    name: 'Subscription',
    allowedRoles: [29],
    path: '/admin/catapult/subscriptions/:billingId/:subscriptionId',
    component: AdminSubscriptionOverview,
    pageTitle: i18n.t('common:sidebar.catapultSubscriptions'),
  },
];

export const generateTeamRoutes = (teams) => {
  let teamRoutes = [];

  if (teams.length > 0) {
    teamRoutes = teams.map((team) => {
      return {
        type: 'private',
        name: 'Team',
        allowedRoles: [29, 40, 42],
        path: `/team/${team.id}`,
        component: Team,
        pageTitle: i18n.t('common:sidebar.team'),
        sidebar: {
          level: teams.length === 1 ? -1 : 1,
          icon: 'arrow-right',
          link: `/team/${team.id}/members`,
          text: team.name,
          highlighted: [`/team/:id`, '/team/:id/:tabName?'],
        },
      };
    });

    teamRoutes.unshift({
      type: 'private',
      name: 'Teams',
      allowedRoles: [29, 40, 42],
      path: '/teams',
      component: Teams,
      pageTitle: i18n.t('common:sidebar.team'),
      sidebar: {
        level: 0,
        icon: 'users-alt',
        link: '/teams',
        text: 'common:sidebar.team',
        highlighted: ['/teams', '/team/:id', '/team/:id/:tabName?'],
      },
    });
  }
  return teamRoutes;
};

export const getSidebarRoutes = ({ companyId, userId, teams, isVendor }) => {
  const teamRoutes = teams ? generateTeamRoutes(teams) : [];

  const filteredRoutes = [...routes, ...teamRoutes, subscriptionRoute].filter((r) => {
    if (r.hasOwnProperty('sidebar')) {
      // Only show jobs link if user is vendor
      if (r.path === '/jobs') {
        return isVendor;
      }
      return r.sidebar.requiredId ? r.sidebar.requiredId.includes(userId) : true;
    }
    return false;
  });
  // Sidebar items are ordered as they are placed in the array

  return [...filteredRoutes]
    .map((route) => {
      if (route.requiresCompany) {
        if (!companyId) {
          return null;
        } else {
          const newRoute = { ...route };
          newRoute.sidebar = { ...route.sidebar };
          newRoute.sidebar.link = newRoute.sidebar.link.replace(':id', companyId);
          return newRoute;
        }
      }
      return route;
    })
    .filter((r) => !!r);
};

export const getAllRoutes = ({ teams }) => {
  const teamRoutes = generateTeamRoutes(teams);

  return [...routes, ...teamRoutes, subscriptionRoute];
};
