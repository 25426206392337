import FileSaver from 'file-saver';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  toggleProjectPaid,
  postResendInvoice,
  getOriginalFiles,
  postSendInvoice,
  getInvoice,
} from 'services/project';
import { sendUserInteraction } from 'utils/tagManager.utils';
import AdminSidebar from './AdminSidebar';
import UploadTranslationModal from '../../common/UploadTranslationModal/UploadTranslationModal';
import { useSelector } from 'react-redux';
import ArchiveModal from '../ArchiveModal/ArchiveModal';
import { PROJECT_STATUS_ARCHIVED } from 'constants/projectStatus';

const ProjectOrderedAdminSidebar = ({ refreshProject, project, isAdminPage = false }) => {
  const { t } = useTranslation();
  const languages = useSelector((state) => state.classifiersStore.languages);

  const [archivingProject, setIsArchivingProject] = useState(false);
  const [togglingPaid, setTogglingPaid] = useState(false);
  const [downloadingInvoice, setDownloadingInvoice] = useState(false);
  const [sendingInvoice, setSendingInvoices] = useState(false);
  const [downloadingOriginalFiles, setDownloadingOriginalFiles] = useState(false);

  // Modal open states
  const [uploadTranslatedOpen, setUploadTranslatedOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  const openArchiveModal = () => setArchiveModalOpen(true);

  const handleProjectPaidClicked = async () => {
    sendUserInteraction('Ordered project overview mark project as paid');
    try {
      setTogglingPaid(true);
      await toggleProjectPaid(project.id);
      await refreshProject();
      toast.success(t('common:toasts.markPaid.success'));
      setTogglingPaid(false);
    } catch (e) {
      toast.error(t('common:toasts.markPaid.error'));
      throw e;
    }
  };

  const handleInvoiceClick = async (langCode = 'en-GB') => {
    sendUserInteraction(`Ordered project overview send invoice click - ${langCode}`);
    const language = languages.find((lang) => lang.code === langCode);
    if (!language) {
      toast.error(t('common:toasts.invoiceSent.error'));
      setSendingInvoices(false);
      return;
    }

    try {
      setSendingInvoices(true);
      if (project.proforma) {
        await postResendInvoice(project.id, language.id);
      } else {
        await postSendInvoice(project.id, language.id, 1);
      }
      await refreshProject();
      toast.success(t('common:toasts.invoiceSent.success'));
    } catch (e) {
      toast.error(t('common:toasts.invoiceSent.error'));
      console.error(e);
    } finally {
      setSendingInvoices(false);
    }
  };

  const downloadInvoice = async (e) => {
    e.preventDefault();
    setDownloadingInvoice(true);

    try {
      // get file blob
      const response = await getInvoice(project.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1];
      const decodedFileName = decodeURIComponent(fileName).replace(/"/g, '').replace(/\+/g, ' ');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, decodedFileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setDownloadingInvoice(false);
    }
  };

  const handleDownloadClick = async (e) => {
    sendUserInteraction('Ordered project original files download click');
    e.preventDefault();
    setDownloadingOriginalFiles(true);

    try {
      // get file blob
      const response = await getOriginalFiles(project?.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setDownloadingOriginalFiles(false);
    }
  };

  const buttons = [
    ...(project.status_id !== PROJECT_STATUS_ARCHIVED
      ? [
          {
            iconName: 'archive',
            isLoading: archivingProject,
            onClick: openArchiveModal,
            text: t('common:admin.project.sidebar.archive'),
          },
        ]
      : []),
    ...(project.status_id === PROJECT_STATUS_ARCHIVED
      ? [
          {
            iconName: 'archive',
            isLoading: archivingProject,
            onClick: openArchiveModal,
            text: t('common:admin.project.sidebar.unarchive'),
          },
        ]
      : []),
    {
      iconName: project.project_paid ? 'money-bill-slash' : 'money-withdraw',
      isLoading: togglingPaid,
      onClick: handleProjectPaidClicked,
      text: project.project_paid ? t('common:admin.project.unmarkPaid') : t('common:admin.project.markPaid'),
      success: project.project_paid,
    },
    {
      iconName: 'folder-download',
      isLoading: downloadingOriginalFiles,
      onClick: handleDownloadClick,
      text: t('common:admin.project.sidebar.downloadFiles'),
    },
    {
      iconName: 'file-check',
      isLoading: false,
      onClick: () => setUploadTranslatedOpen(true),
      text: t('common:admin.project.downloadUploadTranslated'),
    },
    ...(project.status_id === 6
      ? [
          {
            iconName: project.proforma ? 'envelope-redo' : 'envelope-bookmark',
            isLoading: sendingInvoice,
            onClick: () => handleInvoiceClick(),
            text: project.proforma
              ? t('common:admin.project.resendInvoice')
              : t('common:admin.project.issueInvoice'),
            success: project.proforma,
          },
        ]
      : []),
    ...((project.status_id === 6 || project.status_id === 20) && project.proforma
      ? [
          {
            iconName: 'file-download-alt',
            isLoading: downloadingInvoice,
            onClick: downloadInvoice,
            text: t('common:admin.project.downloadInvoice'),
          },
        ]
      : []),
  ];

  const links = useMemo(() => {
    return [
      ...(!!project?.user_id
        ? [
            {
              iconName: 'user',
              text: t('common:admin.project.sidebar.goToUser'),
              to: `/admin/user/${project?.user_id}`,
            },
          ]
        : []),
      ...(!!project?.team?.company_id
        ? [
            {
              iconName: 'building',
              text: t('common:admin.project.sidebar.goToCompay'),
              to: `/company/${project.team?.company_id}`,
            },
          ]
        : []),
      ...(isAdminPage
        ? [
            {
              iconName: 'briefcase',
              text: t('common:admin.project.sidebar.goToClient'),
              to: `/project/${project?.id}`,
            },
          ]
        : [
            {
              iconName: 'dashboard',
              text: t('common:admin.project.sidebar.goToAdmin'),
              to: `/admin/project/${project?.id}`,
            },
          ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, t]);

  return (
    <>
      <AdminSidebar buttons={buttons} links={links} />
      {uploadTranslatedOpen ? (
        <UploadTranslationModal
          project={project}
          setOpen={setUploadTranslatedOpen}
          setProjectState={() => refreshProject()}
        />
      ) : null}
      {archiveModalOpen && (
        <ArchiveModal
          open
          project={project}
          setLoading={(loading) => {
            if (loading) {
              setIsArchivingProject(true);
            } else {
              setIsArchivingProject(false);
              refreshProject();
            }
          }}
          onClose={() => setArchiveModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProjectOrderedAdminSidebar;
