import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { CreateStepsWrapper } from '../CreateCompany.style';

class CompanyName extends Component {
  handleNameChange = (e) => {
    let valid = false;
    if (e.target.value.length > 0) {
      valid = true;
    }
    this.props.validate(valid);
    this.props.onNameChange(e);
  };

  render() {
    const { t } = this.props;
    return (
      <CreateStepsWrapper>
        <div className="step-title">{t('common:admin.companies.companyName.companyName')}</div>
        <div className="step-description">{t('common:admin.companies.companyName.nameDescription')}</div>
        <div className="company-name-form">
          <Form>
            <Form.Field>
              <Form.Input
                placeholder={t('common:admin.companies.companyName.companyName')}
                value={this.props.companyName}
                onChange={this.handleNameChange}
              ></Form.Input>
            </Form.Field>
          </Form>
        </div>
      </CreateStepsWrapper>
    );
  }
}

export default withTranslation('common')(CompanyName);
