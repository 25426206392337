import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { deleteVendorData } from 'services/vendors';
import { useHistory } from 'react-router';

const DeleteVendorModal = ({ vendorId, trigger }) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await deleteVendorData(vendorId);

      history.push('/admin/vendors');
    } catch (error) {
      toast.error(t('common:toasts.deleteVendor.error', "Vendor couldn't be removed"));
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      trigger={trigger}
      size="tiny"
    >
      <Modal.Header>{t('common:admin.vendor.removeVendor.title', 'Remove vendor')}</Modal.Header>
      <Modal.Content>
        {t('common:admin.vendor.removeVendor.description', 'Are you sure you want to delete this vendor?')}
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" disabled={isSubmitting} onClick={() => setOpen(false)}>
          {t('common:cancel')}
        </Button>
        <Button
          actiontype="delete-confirm"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleConfirm}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteVendorModal;
