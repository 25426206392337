import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 39px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  width: 100%;
  .ui.button {
    margin-right: 0 !important;
  }
  .ui.button.dropdown {
    text-align: center;
    background-color: #e03997;
    color: #fff;
  }
`;
