import axios from 'axios';
import { get } from 'lodash';
import { customHistory } from '../history';

import { setMaintenanceError } from 'store/errorsSlice';
import store from 'store/store';

const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

const httpClient = axios.create({
  baseURL: baseUrl,
});

httpClient.defaults.headers.post['Content-Type'] = {
  'Content-Type': 'multipart / form - data',
  'cache-control': 'no-cache',
};

httpClient.interceptors.response.use(
  (response) => {
    store.dispatch(setMaintenanceError(false));
    return response;
  },
  (error) => {
    const errorStatus = get(error, 'response.status');

    if (errorStatus === 503) {
      store.dispatch(setMaintenanceError(true));
      customHistory.push('/maintenance');
    }
    return Promise.reject(error);
  },
);

export default httpClient;
