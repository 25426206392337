import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Tab from '../../../common/Tab/Tab';

import FreeSubscriptions from './FreeSubscriptions';
import LegacySubscriptions from './LegacySubscriptions';
import PaidSubscriptions from './PaidSubscriptions';
import { MainContainer } from 'components/common.styles';

const AdminUserOverview = () => {
  const { t } = useTranslation();

  const PANES = useMemo(
    () => [
      {
        title: t('common:catapult.admin.paidSubscriptions'),
        render: () => <PaidSubscriptions />,
      },
      {
        title: t('common:catapult.admin.freeSubscriptions'),
        render: () => <FreeSubscriptions />,
      },
      {
        title: t('common:catapult.admin.legacySubscriptions'),
        render: () => <LegacySubscriptions />,
      },
    ],
    [t],
  );

  return (
    <MainContainer>
      <Tab panes={PANES} />
    </MainContainer>
  );
};

export default AdminUserOverview;
