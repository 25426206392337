import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { CenteredContainer, ContentContainer } from '../common.styles';

const PageNotFound = ({ isAuthenticated, t }) => {
  return (
    <CenteredContainer className="page-not-found">
      <img
        style={{ width: '90%', maxWidth: '560px' }}
        src={require('./assets/404image.png')}
        alt="page not found"
      />
      <ContentContainer className="page-not-found__text-content">
        <h1>{t('common:pageNotFound.pageNotFound')}</h1>
        <p>{t('common:pageNotFound.description')}</p>
        <p>
          {t('common:pageNotFound.tryAgain')}
          <Link to="/projects">
            {isAuthenticated ? t('common:pageNotFound.projectsDashboard') : t('common:pageNotFound.homepage')}
          </Link>
          .
        </p>
      </ContentContainer>
    </CenteredContainer>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.userStore.isAuthenticated,
});

export default connect(mapStateToProps)(withTranslation('common')(PageNotFound));
