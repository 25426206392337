import styled from 'styled-components';
import { Dropdown, Input } from 'semantic-ui-react';
import { Icon } from '../common/Icon/Icon';
import Pagination from '../common/Pagination/Pagination';
import { Avatar } from '../common';

export const AdminUsersLayout = styled.div`
  position: relative;
  display: grid;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
  overflow: auto;

  .admin-users-table-row {
    > :first-child {
      a {
        padding-left: 36px;
      }
    }

    > :last-child {
      a {
        padding-right: 23px;
      }
    }
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const StyledPagination = styled(Pagination)`
  margin-right: auto !important;
`;

export const FilterDropdown = styled(Dropdown)`
  margin-left: 10px !important;
`;

export const StyledInput = styled(Input)`
  margin-left: 10px !important;
`;

export const CheckMark = styled(Icon)`
  color: ${(props) => (props.active ? '#21ba45!important' : '#db2828!important')};
`;

export const AddCompanyButton = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: white;
  display: grid;
  place-items: center;

  border: 1px solid rgba(34, 36, 38, 0.1);

  position: absolute;
  bottom: 20px;
  right: 20px;

  i {
    margin: 0 !important;
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 12px !important;
`;
