import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectDropdown } from '../Dropdown/SelectDropdown';
import { Flag } from '../Flag/Flag';

const LanguageDropdown = ({ name = 'language', value = null, usedLanguages = [], onChange = () => {} }) => {
  const { languages } = useSelector((state) => state.classifiersStore);
  const { t } = useTranslation();

  const supportedLanguages = useMemo(() => {
    const parsedLanguages = languages.map((lang) => ({
      key: lang.id,
      value: lang.id,
      text: t(`languages:${lang.code}`, lang.name),
      code: lang.code,
    }));
    return parsedLanguages;
  }, [languages, t]);

  const languagesOptions = useMemo(() => {
    return supportedLanguages
      .filter((lang) => !usedLanguages.includes(lang.value))
      .map((lang) => ({
        ...lang,
        icon: <Flag lang={{ code: lang.code }} />,
      }));
  }, [supportedLanguages, usedLanguages]);

  const handleSourceLanguageChange = (e, { name, value }) => {
    onChange(e, { name, value });
  };

  return (
    <SelectDropdown
      name={name}
      value={value}
      onChange={handleSourceLanguageChange}
      options={languagesOptions}
      selection
      search
      emptyState={t('common:projects.createProject.noLanguagesFound')}
      placeholder={t('common:projects.createProject.selectALanguage')}
    />
  );
};

export default LanguageDropdown;
