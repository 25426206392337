import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../common/Icon/Icon';
import { Radio } from '../../common/Radio/Radio';
import { RoleSelectWrapper } from './RoleSelect.style';

const RoleSelect = ({ onRoleChange = () => {} }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('private');

  const onChange = (e, { value }) => {
    setSelected(value);
    if (value === 'private') {
      // Individual role ID
      onRoleChange(10);
    } else if (value === 'business') {
      // Company manager
      onRoleChange(40);
    }
  };

  const onClickChange = (value) => {
    setSelected(value);
    if (value === 'private') {
      // Individual role ID
      onRoleChange(10);
    } else if (value === 'business') {
      // Company manager
      onRoleChange(40);
    }
  };

  return (
    <RoleSelectWrapper>
      <div className="role-radio">
        <div className="radio-icon">
          <Icon
            link
            onClick={() => onClickChange('private')}
            name="user"
            size="huge"
            fitted
            color={'private' === selected ? '#1CAAC5' : 'grey'}
          />
        </div>
        <div className="radio-control">
          <Radio
            label={t('common:checkout.billing.roleRadio.private')}
            name="radioGroup"
            value="private"
            checked={'private' === selected}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="role-radio">
        <div className="radio-icon">
          <Icon
            link
            onClick={() => onClickChange('business')}
            name="suitcase"
            size="huge"
            fitted
            color={'business' === selected ? '#1CAAC5' : 'grey'}
          />
        </div>
        <div className="radio-control">
          <Radio
            label={t('common:checkout.billing.roleRadio.business')}
            name="radioGroup"
            value="business"
            checked={'business' === selected}
            onChange={onChange}
          />
        </div>
      </div>
    </RoleSelectWrapper>
  );
};

export default RoleSelect;
