import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { useRef } from 'react';
import { theme } from 'style/theme';
import {
  StyledAccordionContent,
  AccordionContainer,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
} from './Accordion.styles';

import { useAccordion } from './useAccordion';

// Animations durations
const accorWrapAnimDur = 0.2;
const accordConAnimDur = accorWrapAnimDur;

// Variants
const accordionVariants = {
  open: {
    backgroundColor: '#ffffff',
  },
  collapsed: {
    backgroundColor: '#e9e9e9',
  },
};

const AccordionContent = ({ children, open, watch, cypressID, isMobile, animationEnd = () => {} }) => {
  // If isMobile isn't finished setting yet, don't render content yet
  if (isMobile === null) {
    return null;
  }

  return (
    <AnimatePresence onExitComplete={animationEnd}>
      {open && (
        <motion.div
          key="content"
          initial={{ height: isMobile ? 'auto' : 0 }}
          animate={{
            height: isMobile ? false : 'auto',
            transitionEnd: {
              overflow: 'initial',
            },
          }}
          exit={{ height: isMobile ? 'auto' : 0 }}
          transition={{ duration: isMobile ? 0 : accorWrapAnimDur }}
          style={{ overflow: 'hidden' }}
          data-cy={cypressID}
        >
          <motion.div
            key="wrapper"
            initial={{
              opacity: 0,
              transform: isMobile ? 'none' : 'scale(0)',
            }}
            animate={{
              opacity: 1,
              transform: isMobile ? 'none' : 'scale(1)',
              transitionEnd: {
                transform: 'none',
              },
            }}
            exit={{ opacity: 0, transform: isMobile ? 'none' : 'scale(0)' }}
            transition={{
              duration: accordConAnimDur,
            }}
            style={{
              transformOrigin: 'top',
            }}
          >
            <StyledAccordionContent className="accordion-content" open={open}>
              {children}
            </StyledAccordionContent>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const Accordion = ({
  items,
  openIndexes: openIndexes_,
  setOpenIndexes,
  single,
  preventClose,
  goToIndex,
  ...rest
}) => {
  const { openIndexes, toggleIndex } = useAccordion({
    openIndexes: openIndexes_,
    setOpenIndexes,
    single,
    preventClose,
  });

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);
  const accordionHeaderRefs = useRef([]);

  /**
   * Runs on accordion content animation end
   */
  const handleContentAnimationEnd = () => {
    if (accordionHeaderRefs.current.length < 1 || isMobile) return;

    const openIndex = openIndexes[0];
    const openAccordion = accordionHeaderRefs.current[openIndex];

    if (!openAccordion) return;

    // After accordion wrapper is finished animating,
    // try to scroll currently open element into view
    // using block nearest to vertically align itself
    setTimeout(() => {
      openAccordion.scrollIntoView({ block: 'nearest' });
    }, accorWrapAnimDur * 100);
  };
  return (
    <AccordionContainer {...rest}>
      {items.map((item, index) => {
        const open = openIndexes.includes(index);

        return (
          <AccordionItem
            className="accordion-item"
            data-cy={`accordion-item-${index}`}
            id={item.id}
            open={open}
            key={index}
            variants={accordionVariants}
            initial={isMobile ? 'open' : 'collapsed'}
            animate={isMobile ? false : open ? 'open' : 'collapsed'}
            transition={{
              duration: accordConAnimDur,
            }}
          >
            <AccordionHeader
              ref={(el) => (accordionHeaderRefs.current[index] = el)}
              data-cy="accordion-item-header"
              onClick={() => toggleIndex(index)}
              open={open}
            >
              {item.title}
              <AccordionIcon name="arrow-down" size="large" open={open} />
            </AccordionHeader>
            <AccordionContent
              isMobile={isMobile}
              cypressID="accordion-item-content"
              open={open}
              key={index}
              animationEnd={handleContentAnimationEnd}
            >
              {item.content}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </AccordionContainer>
  );
};
