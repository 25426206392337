export const VENDOR_RISK = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

export const VENDOR_STATUS = {
  1: 'New',
  2: 'Under review',
  3: 'Active',
  4: 'Temporarily unavailable',
  5: 'Canceled',
  6: 'Unknown',
};

export const VENDOR_EXPERIENCE = {
  1: 'Beginner',
  2: 'Intermediate',
  3: 'Advanced',
};

export const VENDOR_PAYMENT_FROM = {
  1: 'United Kingdom',
  2: 'Slovenia',
};

// Vendor experience ids
export const VENDOR_EXPERIENCE_BEGINNER = 1;
export const VENDOR_EXPERIENCE_INTERMEDIATE = 2;
export const VENDOR_EXPERIENCE_ADVANCED = 3;

export const VENDOR_TASK_TYPE_TRANSLATION = 1;
export const VENDOR_TASK_TYPE_REVISION = 4;
export const VENDOR_TASK_TYPE_PROOFREAD_ONLY = 6;

// File upload options
export const cvUploadOptions = {
  accept: '.doc, .pdf, .docx',
  maxSize: 3_000_000, // 3 MB
};

export const additionalFilesUploadOptions = {
  accept: '.pdf, .doc, .docx, .jpeg, .png',
  maxSize: 10_000_000,
};

// Language pairs stuff
export const singleRowDefaultStateOnboarding = {
  source_lang_id: '',
  target_lang_id: '',
  category_id: [],
  task_type_id: '',
  rate: '',
};

export const singleRowDefaultState = {
  source_lang_id: '',
  target_lang_id: '',
  category_id: '',
  task_type_id: '',
  rate: '',
  test_status: null,
  rate_status: null,
  pending_rate: null,
};
