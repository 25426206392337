import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../Button/Button';
import { Form, Input, Modal } from 'semantic-ui-react';
import Message from '../Message/Message';

import { postBillingCoupon } from '../../../services/billing';

const CouponModal = ({ billingId, closeModal, updateBilling }) => {
  const { t } = useTranslation();

  const [couponCode, setCouponCode] = useState(null);
  const [error, setError] = useState(null);
  const [submittingCoupon, setSubmittingCoupon] = useState(false);

  const canSubmitCoupon = () => {
    return couponCode && couponCode.trim() !== '';
  };

  const handleCouponCodeChange = (e) => {
    e.preventDefault();
    setCouponCode(e.target.value);
  };

  const handleCouponSubmit = async (e) => {
    e.preventDefault();
    setSubmittingCoupon(true);
    setError(null);

    try {
      const couponResponse = await postBillingCoupon({
        billing_id: billingId,
        coupon_code: couponCode,
      });

      updateBilling(couponResponse);
      toast.success(t('common:toasts.submitCoupon.success'));
      closeModal();
    } catch (e) {
      // 422 already used
      // 404 does not exist

      if (e.response && e.response.status === 404) {
        setError(t('common:userInfo.balance.errors.invalidCoupon'));
      } else if (e.response && e.response.status === 422) {
        setError(t('common:userInfo.balance.errors.usedCoupon'));
      } else {
        setError(e.message);
      }
    } finally {
      setSubmittingCoupon(false);
    }
  };

  return (
    <Modal open size="tiny">
      <Modal.Header>{t('common:admin.coupons.couponCode')}</Modal.Header>

      <Modal.Content>
        {error && <Message text={error} type="error" onDismiss={() => setError(null)} />}
        <p>{t('common:userInfo.balance.couponDescription')}</p>
        <Form onSubmit={handleCouponSubmit}>
          <Input
            fluid
            name="couponCode"
            onChange={handleCouponCodeChange}
            value={couponCode || ''}
            autoFocus
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" disabled={submittingCoupon} onClick={closeModal}>
          {t('common:cancel')}
        </Button>
        <Button
          actiontype="primary"
          disabled={!canSubmitCoupon()}
          loading={submittingCoupon}
          onClick={handleCouponSubmit}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CouponModal;
