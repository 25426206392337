import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  company: {},
};

const companySlice = createSlice({
  name: 'companySlice',
  initialState: { ...initialState },
  reducers: {
    setCompany(state, action) {
      const company = action.payload;
      state.company = { ...company };
    },
  },
});

export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
