import { useState } from 'react';

export const singleSetter = (index, openIndexes) => {
  const closing = openIndexes.includes(index);
  if (!closing) return [index];
};

export const preventCloseSetter = (index, openIndexes) => {
  const closing = openIndexes.includes(index);
  if (closing && openIndexes.length < 2) return openIndexes;
};

export const combineSetters = (setters) => {
  return (index, openIndexes) => {
    for (const setter of setters) {
      const result = setter(index, openIndexes);
      if (result) return result;
    }
  };
};

export const defaultSetter = (index, openIndexes) => {
  const closing = openIndexes.includes(index);
  return closing ? openIndexes.filter((idx) => idx !== index) : [...openIndexes, index];
};

export const useAccordion = (props = {}) => {
  const { initial = [], single, preventClose } = props;
  let [openIndexes, setOpenIndexes] = useState(initial);

  const setters = [defaultSetter];
  if (preventClose) setters.unshift(preventCloseSetter);
  if (single) setters.unshift(singleSetter);
  const stateSetter = combineSetters(setters);

  if (props.openIndexes !== undefined) {
    openIndexes = props.openIndexes;
  }
  if (props.setOpenIndexes) {
    setOpenIndexes = props.setOpenIndexes;
  }

  const toggleIndex = (index) => {
    setOpenIndexes(stateSetter(index, openIndexes));
  };

  return {
    openIndexes,
    toggleIndex,
  };
};
