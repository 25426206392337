import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  display: grid;
  margin-top: 50px;
  justify-items: center;

  h1 {
    font-size: 3rem !important;
  }

  .sub-header {
    margin-bottom: 30px;
  }
`;
