import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, JobsContainer, LoaderContainer, PaginationContainer } from './Jobs.styles';
import Loader from '../../common/Loader/Loader';
import { MainContainer } from 'components/common.styles';
import Message from '../../common/Message/Message';
import Pagination from '../../common/Pagination/Pagination';
import JobCard from '../JobCard/JobCard';
import { getTranslatorJobs } from '../../../services/translators';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import WelcomeScreen from './WelcomeScreen/WelcomeScreen';

// import { JOB_STATUS } from '../../common/StatusLabel/StatusLabel.constants';

export default () => {
  // Get user data from redux
  const user = useSelector((state) => state.userStore.user);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [jobs, setJobs] = useState([]);
  const [last_page, setLastPage] = useState(1);
  const [page, setPage] = useLocalStorage('service-provider-jobs-page', 1);
  const [status_id] = useState(null);
  const { t } = useTranslation();

  const handlePageChange = (e, { activePage }) => {
    sendUserInteraction('vendor jobs page pagination click');
    e.preventDefault();
    setPage(activePage);
  };

  // TODO add filtering by job type, job status

  // const statusOptions = Object.keys(JOB_STATUS)
  //   .filter((key) => {
  //     const availableStatuses = [4, 5, 6, 51, 52, 53];
  //     return availableStatuses.includes(parseInt(key));
  //   })
  //   .map((key) => {
  //     return {
  //       text: t(JOB_STATUS[key]),
  //       value: key,
  //     };
  //   });

  const removeJob = (job) => {
    const newJobs = jobs.filter((oldJob) => oldJob.id !== job.id);
    setJobs(newJobs);
  };

  const updateJob = (newJob) => {
    const newJobs = jobs.map((job) => {
      if (job.id === newJob.id) {
        return newJob;
      }
      return job;
    });
    setJobs(newJobs);
  };

  /**
   * Fetch jobs
   */
  const fetchJobs = useCallback(
    async (newPage) => {
      // Check if user has vendor model
      if (!user.vendor) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const { data, last_page } = await getTranslatorJobs({
          page: newPage,
          per_page: 15,
          status_id,
          user_id: user.id,
        });
        setJobs(data);
        if (newPage > last_page) {
          setPage(last_page);
        }
        setLastPage(last_page);
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setJobs, setError, setLastPage, user, status_id, setPage],
  );

  /**
   * Fires on initial load
   * and everytime the pagination page changes
   */
  useEffect(() => {
    fetchJobs(page);
  }, [page, fetchJobs]);

  // Show the welcome screen if user has no jobs or user is not a vendor yet/still onboarding
  const showWelcomeScreen = jobs.length === 0 || !user.vendor;

  return (
    <MainContainer>
      <Container>
        {error ? (
          <Message text={t('common:errors.pleaseRefresh')} type="error" />
        ) : isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            {showWelcomeScreen ? (
              <WelcomeScreen />
            ) : (
              <>
                {/* <Dropdown options={statusOptions} multiple select search /> */}
                <JobsContainer>
                  {jobs.map((job) => (
                    <JobCard key={job.id} job={job} removeJob={removeJob} updateJob={updateJob} />
                  ))}
                </JobsContainer>
                {last_page > 1 && (
                  <PaginationContainer>
                    <Pagination activePage={page} onPageChange={handlePageChange} totalPages={last_page} />
                  </PaginationContainer>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </MainContainer>
  );
};
