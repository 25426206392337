// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ErrorScreen from './ErrorScreen/ErrorScreen';
class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    toast.error(this.props.t('common:toasts.generalError'));
  }

  render() {
    const { hasError, error } = this.state;
    if (hasError) {
      return <ErrorScreen error={error} />;
    }
    return this.props.children;
  }
}

export default withTranslation('common')(ErrorBoundary);
