import CircleTimeline from 'components/common/CircleTimeline/CircleTimeline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAPIDateString } from 'utils/date.utils';

const ProjectTimeline = ({ deliveryDate, status, log }) => {
  const { t } = useTranslation();

  // Data for circle timeline
  const baseSteps = [
    // Created
    {
      logName: 'Project created',
      state: {
        finished: status > 1,
        current: status === 1,
        disabled: true,
      },
      icons: {
        default: 'file-upload-alt',
      },
      label: {
        title: t('common:projects.userProject.timeline.created'),
      },
    },
    // Ordered step
    {
      logName: 'Project ordered',
      state: {
        finished: status > 3,
        current: status === 3,
      },
      icons: {
        default: 'credit-card',
      },
      label: {
        title: t('common:projects.userProject.timeline.ordered'),
      },
    },
    // InProgress step
    {
      logName: 'Translation assigned',
      state: {
        finished: status > 4,
        current: status === 4,
      },
      icons: {
        default: 'file-edit-alt',
      },
      label: {
        title: t('common:projects.userProject.timeline.inProgress'),
      },
    },
    // Revision assigned
    {
      logName: 'Revision assigned',
      state: {
        finished: status === 6,
        current: status === 5 || status === 51 || status === 52 || status === 53,
      },
      icons: {
        default: 'file-search-alt',
      },
      label: {
        title: t('common:projects.userProject.timeline.inReview'),
      },
    },
    // Project finished
    {
      logName: 'Project finished',
      state: {
        finished: status === 6,
      },
      icons: {
        default: 'file-check-alt',
      },
      label: {
        title: t('common:projects.userProject.timeline.finished'),
      },
    },
  ];

  // Map over steps and figure out the subtitle text
  // If log entry for step exists, display that as a date
  const items = baseSteps.map(({ logName, ...rest }) => {
    const newItem = { ...rest };
    const logEntry = log.find((entry) => entry.event.includes(logName));

    if (logEntry) {
      newItem.label.subtitle = formatAPIDateString({ dateString: logEntry.created_at });
    } else {
      if (logName === 'Project finished') {
        newItem.label.subtitle = (
          <>
            {t('common:projects.userProject.EstimatedDelivery')}:<br />
            {formatAPIDateString({ dateString: deliveryDate })}
          </>
        );
      }
    }

    return { ...newItem };
  });

  return <CircleTimeline items={items} />;
};
export default ProjectTimeline;
