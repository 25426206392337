import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import Message from '../../common/Message/Message';
import CountriesDropdown from '../../common/BillingDetails/EditForm/CountriesDropdown';
import CurrenciesDropdown from '../../common/BillingDetails/EditForm/CurrenciesDropdown';
import { checkAllowedRole, hasAdminPermissions, isCompanyRole } from '../../../utils/user.utils';
import { BillForm, BillingFieldsStyles } from './BillingForm.style';
import { Input } from 'components/common/Input/Input';
import styled from 'styled-components';
import { Icon } from 'components/common/Icon/Icon';
import BalanceInput from 'components/common/BillingDetails/EditForm/BalanceInput';
import { useSelector } from 'react-redux';
import { currencyByIdSelector } from 'store/selectors';
import VerifiedInput from 'components/common/BillingDetails/EditForm/VerifiedInput';
import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';

const ShowLine2 = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
`;

export const BillingFields = ({
  billing: billingProps,
  role,
  disabled = false,
  disableCurrency = false,
  onChange = () => {},
}) => {
  const defaultCountry = { name: 'United Kingdom', code: 'GB', check_vat: true };
  const billing = {
    country: defaultCountry,
    ...billingProps,
  };
  const { t } = useTranslation();
  const [showLine2, setShowLine2] = useState(false);

  const onBillingChange = (e) => {
    const { value, name } = e.target;
    const newBilling = { ...billing };
    newBilling[name] = value;
    newBilling['country_id'] = newBilling?.country?.value || newBilling?.country?.id; // country gets changed by CountriesDropdown, update country_id too
    onChange(newBilling);
  };

  const onPropertyChange = ({ name, value }) => {
    const newBilling = { ...billing };
    newBilling[name] = value;

    if (name === 'country') {
      // Check if the selected country requires VAT
      const requiresVAT = value.check_vat;
      if (requiresVAT) {
        // Clear the company_vat field if VAT is applicable
        newBilling['company_vat'] = '';
      } else {
        // Set company_vat to "-" if the country does not require VAT
        newBilling['company_vat'] = '-';
      }
    }

    newBilling['country_id'] = newBilling?.country?.value || newBilling?.country?.id;
    onChange(newBilling);
  };

  return (
    <BillingFieldsStyles>
      <Form.Field>
        <Input
          label={
            isCompanyRole(role)
              ? t('common:checkout.billing.form.companyName')
              : t('common:checkout.billing.form.name')
          }
          placeholder={t('common:checkout.billing.form.name')}
          className="custom-disabled"
          disabled={disabled}
          required
          name="company_name"
          value={billing.company_name || ''}
          onChange={onBillingChange}
          data-cy="checkout-name-input"
        ></Input>
      </Form.Field>

      {(billing.line1 === null || billing.city === null || billing.postal_code === null) &&
        billing.company_street !== null && (
          <Message
            text={t('common:checkout.billing.form.addressInfo') + billing.company_street}
            type="info"
          />
        )}
      <Form.Field>
        <Input
          label={t('common:checkout.billing.form.address')}
          placeholder={t('common:checkout.billing.form.address')}
          className="custom-disabled"
          disabled={disabled}
          required
          name="line1"
          value={billing.line1 || ''}
          onChange={onBillingChange}
          data-cy="checkout-address-line-1-input"
        ></Input>
      </Form.Field>
      {showLine2 ? (
        <Form.Field>
          <Input
            label={t('common:checkout.billing.form.address2')}
            placeholder={t('common:checkout.billing.form.address2')}
            className="custom-disabled"
            disabled={disabled}
            name="line2"
            value={billing.line2 || ''}
            onChange={onBillingChange}
            data-cy="checkout-address-line-2-input"
          ></Input>
        </Form.Field>
      ) : (
        <ShowLine2
          onClick={() => {
            setShowLine2(true);
          }}
          data-cy="show-address-line-2-input"
        >
          <Icon inline name="plus" /> {t('common:checkout.billing.form.showLine2')}
        </ShowLine2>
      )}

      <Form.Group>
        <Form.Field>
          <Input
            label={t('common:checkout.billing.form.city')}
            placeholder={t('common:checkout.billing.form.city')}
            className="custom-disabled"
            disabled={disabled}
            required
            name="city"
            value={billing.city || ''}
            onChange={onBillingChange}
            data-cy="checkout-city-input"
          ></Input>
        </Form.Field>
        <Form.Field>
          <Input
            label={t('common:checkout.billing.form.postalCode')}
            placeholder={t('common:checkout.billing.form.postalCode')}
            className="custom-disabled"
            required
            disabled={disabled}
            name="postal_code"
            value={billing.postal_code || ''}
            onChange={onBillingChange}
            data-cy="checkout-postal-code-input"
          ></Input>
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Input
          label={t('common:checkout.billing.form.state')}
          placeholder={t('common:checkout.billing.form.state')}
          className="custom-disabled"
          disabled={disabled}
          name="state"
          value={billing.state || ''}
          onChange={onBillingChange}
          data-cy="checkout-state-input"
        ></Input>
      </Form.Field>

      <Form.Field>
        <CountriesDropdown
          disabled={!!disabled}
          country={billing.country}
          updateProperty={onPropertyChange}
        ></CountriesDropdown>
      </Form.Field>
      {isCompanyRole(role) && !!billing.country['check_vat'] && (
        <Form.Field>
          <Input
            label={t('common:checkout.billing.form.vatNumber')}
            placeholder={t('common:checkout.billing.form.vatNumber')}
            className="custom-disabled"
            disabled={disabled}
            required
            name="company_vat"
            value={billing.company_vat || ''}
            onChange={onBillingChange}
          ></Input>
        </Form.Field>
      )}

      <Form.Group>
        <Form.Field>
          <CurrenciesDropdown
            disabled={!!disabled || !!billing.stripe_id || disableCurrency}
            currency_id={billing.currency_id}
            updateProperty={onPropertyChange}
          />
        </Form.Field>
      </Form.Group>
      <div className="reason">{t('common:checkout.billing.form.currencyReason')}</div>
      {isCompanyRole(role) ? (
        <>
          <Form.Field>
            <Input
              disabled={!!disabled}
              label={t('common:checkout.billing.form.email') + ':'}
              placeholder={t('common:checkout.billing.form.email')}
              className="custom-disabled"
              name="user_email"
              required
              value={billing.user_email || ''}
              onChange={onBillingChange}
            ></Input>
          </Form.Field>
          <div className="reason">{t('common:checkout.billing.form.emailReason')}</div>
        </>
      ) : null}
    </BillingFieldsStyles>
  );
};

export const AdminBillingFields = ({ billing, role, onChange = () => {} }) => {
  const [showAdminSettings, setShowAdminSettings] = useState(false);

  const billingGroups = useSelector((state) => state.classifiersStore.billingGroups);
  const availableCountries = useSelector((state) => state.classifiersStore.countries);
  const { t } = useTranslation();

  const { country_id, billing_group_id, discount } = billing;

  const onPropertyChange = ({ name, value }) => {
    const newBilling = { ...billing };
    newBilling[name] = value;
    onChange(newBilling);
  };

  const handleGroupChange = (e, { name, value }) => {
    onPropertyChange({ name: 'billing_group_id', value });
  };

  const getCurrencyById = useSelector(currencyByIdSelector);
  const currencyLabel = useMemo(() => {
    const currencyData = getCurrencyById(billing.currency_id);
    return currencyData ? `${currencyData.code} (${currencyData.symbol})` : '';
  }, [billing.currency_id, getCurrencyById]);

  const getCountryData = () => {
    const country = availableCountries.find((country) => country.id === country_id);
    if (!country) {
      return null;
    }
    return country;
  };

  const getDefaultBillingGroup = () => {
    const countryData = getCountryData(country_id);
    if (countryData) {
      return countryData.billing_group;
    }
    return null;
  };

  const getBillingGroupOptions = () => {
    const defaultBillingGroup = getDefaultBillingGroup();

    const defaultOption = defaultBillingGroup
      ? {
          key: defaultBillingGroup.id,
          text: 'Default billing group (0%)',
          value: null,
        }
      : null;

    const billingGroupOptions = billingGroups
      .map((group) => {
        return {
          key: group.id,
          text: `${group.name} (${group.discount_percentage}%)`,
          value: group.id,
        };
      })
      .filter((option) => !!option);

    return [defaultOption, ...billingGroupOptions].filter((option) => !!option);
  };

  const billingGroupOptions = getBillingGroupOptions();

  const handleDiscountChange = (e, { name, value }) => {
    const newValue = parseInt(value);

    if (newValue > -1 && newValue < 101) {
      onPropertyChange({ name, value: newValue });
    } else if (value === null || value === '') {
      onPropertyChange({ name, value: null });
    }
  };

  return showAdminSettings ? (
    <>
      <Form.Field>
        <BalanceInput
          currency_id={billing.currency_id}
          euro_balance={billing.euro_balance}
          updateProperty={onPropertyChange}
          currencyLabel={currencyLabel}
        ></BalanceInput>
      </Form.Field>
      {checkAllowedRole({ allowedRoles: [40, 42], userRole: role }) ? (
        <Form.Field>
          <VerifiedInput
            project_invoices={billing.project_invoices}
            updateProperty={onPropertyChange}
            verified={billing.verified}
          />
        </Form.Field>
      ) : null}
      <Form.Field>
        <SelectDropdown
          label={t('common:userInfo.billing.labels.billingGroup')}
          name="billing_group_id"
          search
          selection
          onChange={handleGroupChange}
          options={billingGroupOptions}
          value={billing_group_id}
        />
      </Form.Field>
      <Form.Field>
        <Input
          label={`${t('common:userInfo.billing.labels.billingDiscount')} (%)`}
          name="discount"
          onChange={handleDiscountChange}
          value={discount || ''}
          type="number"
        />
      </Form.Field>
    </>
  ) : (
    <ShowLine2
      onClick={() => {
        setShowAdminSettings(true);
      }}
    >
      <Icon inline name="cog" /> {t('common:checkout.billing.form.showAdmin')}
    </ShowLine2>
  );
};

const BillingForm = ({
  children = <></>,
  loading = false,
  billing = {},
  role,
  disabled = false,
  disableCurrency = false,
  onChange = () => {},
}) => {
  const loggedUser = useSelector((state) => state.userStore.user);
  return (
    <BillForm loading={loading}>
      <BillingFields
        billing={billing}
        role={role}
        disabled={disabled}
        disableCurrency={disableCurrency}
        onChange={onChange}
      />
      {hasAdminPermissions(loggedUser.role) ? (
        <AdminBillingFields role={role} billing={billing} onChange={onChange} />
      ) : null}
      {children}
    </BillForm>
  );
};

export default BillingForm;
