import React, { createContext, useContext, useReducer } from 'react';
import { getCompanyInvitations, getCompanyTeams } from '../../services/company';
import { getTeamPendingInvites } from '../../services/teams';

const CompanyStateContext = createContext(null);
const CompanyDispatchContext = createContext(null);

export const useCompanyState = () => useContext(CompanyStateContext);
export const useCompanyDispatch = () => useContext(CompanyDispatchContext);

const initialState = {
  teams: {
    loading: true,
    error: null,
    data: [],
  },

  invites: {
    loading: true,
    data: [],
  },

  billing: {
    data: [],
    loading: true,
    error: null,
  },
};

export const fetchTeams = async (dispatch, companyId) => {
  dispatch({ type: 'SET_TEAMS_LOADING', payload: true });
  let teams;
  try {
    teams = await getCompanyTeams(companyId);
    dispatch({ type: 'SET_TEAMS', payload: teams });
  } catch (e) {
    dispatch({ type: 'SET_TEAMS_ERROR', payload: e.message });
  } finally {
    dispatch({ type: 'SET_TEAMS_LOADING', payload: false });
    return teams;
  }
};

export const fetchInvites = async (dispatch, companyId) => {
  dispatch({ type: 'SET_INVITES_LOADING', payload: true });

  try {
    const teams = await fetchTeams(dispatch, companyId);

    const allInvites = [];

    const companyPromise = new Promise(async (resolve, reject) => {
      const companyInvites = (await getCompanyInvitations(companyId)).data.map((invite) => ({
        ...invite,
        role_id: 0,
      }));
      allInvites.push(...companyInvites);
      resolve();
    });

    const teamPromises = teams.map((team) => {
      return new Promise(async (resolve, reject) => {
        try {
          const invites = await getTeamPendingInvites({ team_id: team.id });

          allInvites.push(...invites.data);
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    });
    await Promise.allSettled([companyPromise, ...teamPromises]);

    dispatch({ type: 'SET_INVITES', payload: allInvites });
  } catch (e) {
    throw e;
  } finally {
    dispatch({ type: 'SET_INVITES_LOADING', payload: false });
  }
};

const reducer = (state, { payload, type }) => {
  const { teams, invites, billing } = state;
  switch (type) {
    // teams
    case 'SET_TEAMS':
      return { ...state, teams: { ...teams, data: payload } };
    case 'SET_TEAMS_ERROR':
      return { ...state, teams: { ...teams, error: payload } };
    case 'SET_TEAMS_LOADING':
      return { ...state, teams: { ...teams, loading: payload } };
    case 'ADD_TEAM':
      return { ...state, teams: { ...teams, data: [...teams.data, payload] } };
    // invites
    case 'SET_INVITES':
      return { ...state, invites: { ...invites, data: payload } };
    case 'SET_INVITES_LOADING':
      return { ...state, invites: { ...invites, loading: payload } };
    case 'ADD_INVITES':
      return { ...state, invites: { ...invites, data: [...invites.data, ...payload] } };
    // billing
    case 'ADD_BILLING':
      return { ...state, billing: { ...billing, data: [...billing.data, payload] } };
    case 'SET_BILLING':
      return { ...state, billing: { ...billing, data: payload } };
    case 'SET_BILLING_LOADING':
      return { ...state, billing: { ...billing, loading: payload } };
    case 'SET_BILLING_ERROR':
      return { ...state, billing: { ...billing, error: payload } };
    default:
      throw Error('Action type is not specified');
  }
};

export const withCompanyState = (Component) => (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CompanyStateContext.Provider value={state}>
      <CompanyDispatchContext.Provider value={dispatch}>
        <Component {...props} />
      </CompanyDispatchContext.Provider>
    </CompanyStateContext.Provider>
  );
};
