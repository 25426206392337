import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from 'components/common/Button/Button';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { Icon } from 'components/common/Icon/Icon';

import { Container, Divider, InputContainer, Description, Segment, StyledInput } from './CompanyInfo.styles';

import { postCompanyUpdateById } from 'services/company';
import { postTmSettings } from 'services/users';
import { sendUserInteraction } from 'utils/tagManager.utils';
import AssignPerson from 'components/common/AssignPerson/AssignPerson';
import { useSelector } from 'react-redux';
import { assignPmToCompany } from 'services/company';
import { getUser } from 'services/users';
import { assignKAMToCompany } from 'services/company';

const CompanyInfo = ({ companyData, updateCompanyData, shareTm, updateTmSetting = () => {} }) => {
  const { t } = useTranslation();

  const [companyName, setCompanyName] = useState(companyData?.company_name);
  const [companyDiscount, setCompanyDiscount] = useState(companyData?.comp_discount);
  const [isSaving, setIsSaving] = useState(false);
  const [shareTmEnabled, setShareTmEnabled] = useState(shareTm?.share_tm === 1 ? true : false);
  const [loading, setLoading] = useState({
    pm: false,
    kam: false,
  });
  const [companyManagers, setCompanyManagers] = useState({
    pm: null,
    kam: null,
  });

  const adminUsers = useSelector((state) => state.userStore.adminUsers);

  const isDataDirty = useMemo(() => {
    const isDirty =
      companyName !== companyData?.company_name ||
      companyDiscount !== companyData?.comp_discount ||
      !!shareTmEnabled !== !!shareTm?.share_tm;
    return isDirty;
  }, [companyName, companyDiscount, shareTmEnabled, companyData, shareTm]);

  const handleNameChange = (e) => {
    e.preventDefault();
    setCompanyName(e.target.value);
  };

  const handleDiscountChange = (e) => {
    e.preventDefault();
    const newValue = e.target.value;
    // we do not want to POST an empty string instead of null to the API
    if (newValue === '') {
      setCompanyDiscount(null);
    }
    setCompanyDiscount(newValue);
  };

  const handleShareClick = () => {
    setShareTmEnabled(!shareTmEnabled);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    // check which data changed here instead of spamming onChange handlers
    if (companyName !== companyData?.company_name) {
      sendUserInteraction('saved new company name');
    }
    if (companyDiscount !== companyData?.comp_discount) {
      sendUserInteraction('saved new company discount');
    }
    if (!!shareTmEnabled !== !!shareTm?.share_tm) {
      sendUserInteraction('saved new company share Public TM setting');
    }

    try {
      if (companyName !== companyData?.company_name || companyDiscount !== companyData?.comp_discount) {
        const responseCompanyData = await postCompanyUpdateById({
          comp_discount: companyDiscount,
          company_name: companyName,
          companyId: companyData?.id,
        });
        updateCompanyData(responseCompanyData);
      }

      if (!!shareTmEnabled !== !!shareTm?.share_tm) {
        const responseTMData = postTmSettings({
          share_tm: shareTmEnabled ? 1 : 0,
          tm_settings_id: shareTm?.id,
        });
        updateTmSetting(responseTMData);
      }

      toast.success(t('common:toasts.updateCompany.success'));
    } catch (e) {
      toast.error(t('common:toasts.updateCompany.error'));
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handlePmAssign = async (pm_id) => {
    setLoading((c) => ({ ...c, pm: true }));

    try {
      const response = await assignPmToCompany({ company_id: companyData.id, pm_id });

      setCompanyManagers((c) => ({ ...c, pm: response.project_manager }));
      toast.success(t('common:toasts.updateCompany.pmAssign.success'));
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.updateCompany.pmAssign.error'));
    } finally {
      setLoading((c) => ({ ...c, pm: false }));
    }
  };

  const handleKAMAssign = async (kam_id) => {
    setLoading((c) => ({ ...c, kam: true }));

    try {
      const response = await assignKAMToCompany({ company_id: companyData.id, kam_id });

      setCompanyManagers((c) => ({ ...c, kam: response.key_account_manager }));
      toast.success(t('common:toasts.updateCompany.kamAssign.success'));
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.updateCompany.kamAssign.success'));
    } finally {
      setLoading((c) => ({ ...c, kam: false }));
    }
  };

  useEffect(() => {
    const fetchManagersData = async () => {
      try {
        if (companyData.pm_id) {
          const response = await getUser(companyData.pm_id);

          setCompanyManagers((c) => ({ ...c, pm: response }));
        }

        if (companyData.kam_id) {
          const response = await getUser(companyData.kam_id);

          setCompanyManagers((c) => ({ ...c, kam: response }));
        }
      } catch (e) {
        console.error(e);
        toast.error(t('common:company.toasts.failedManagersFetch'));
      }
    };

    fetchManagersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Segment>
        <div>
          <h3>{t('common:company.labels.name')}</h3>
          <Description>{t('common:company.labels.nameDesciption')}</Description>
        </div>
        <InputContainer>
          <StyledInput name="company_name" value={companyName || ''} onChange={handleNameChange} />
        </InputContainer>
      </Segment>
      <Divider />
      <Segment>
        <div>
          <h3>{t('common:company.labels.discount')}</h3>
          <Description>{t('common:company.labels.discountDescription')}</Description>
        </div>
        <InputContainer>
          <StyledInput
            name="comp_discount"
            min={0}
            max={100}
            type="number"
            onChange={handleDiscountChange}
            value={companyDiscount || ''}
          />
        </InputContainer>
      </Segment>
      <Divider />
      <Segment>
        <div>
          <h3>{t('common:userInfo.updateProfile.shareTM')}</h3>
          <Description>{t('common:userInfo.updateProfile.shareTMDecription')}</Description>
        </div>
        <InputContainer>
          <Checkbox name="tmKey" checked={shareTmEnabled} onClick={handleShareClick} />
        </InputContainer>
      </Segment>
      <Divider />
      <Segment>
        <div>
          <h3>{t('common:company.projectManager.title')}</h3>
          <Description>{t('common:company.projectManager.description')}</Description>
        </div>
        <AssignPerson
          loading={loading.pm}
          displayName
          currentPerson={companyManagers.pm}
          choices={adminUsers}
          onAssign={handlePmAssign}
          popupProps={{
            position: 'right-center',
          }}
        />
      </Segment>
      <Divider />
      <Segment>
        <div>
          <h3>{t('common:company.keyAccountManager.title')}</h3>
          <Description>{t('common:company.keyAccountManager.description')}</Description>
        </div>
        <AssignPerson
          loading={loading.kam}
          displayName
          currentPerson={companyManagers.kam}
          choices={adminUsers}
          onAssign={handleKAMAssign}
          popupProps={{
            position: 'right-center',
          }}
          labels={{
            noPerson: t('common:assignPerson.keyAccountManager.noPerson'),
            title: t('common:assignPerson.keyAccountManager.title'),
            placeholder: t('common:assignPerson.keyAccountManager.placeholder'),
            unassignHoverTitle: t('common:assignPerson.keyAccountManager.unassignHoverTitle'),
          }}
        />
      </Segment>
      <Divider />
      <Segment>
        <div />
        <InputContainer>
          <Button
            actiontype="primary"
            big
            disabled={!isDataDirty}
            labelPosition="left"
            loading={isSaving}
            onClick={handleSubmit}
          >
            <Icon name="save" />
            {t('common:userInfo.updateProfile.saveChanges')}
          </Button>
        </InputContainer>
      </Segment>
    </Container>
  );
};

export default CompanyInfo;
