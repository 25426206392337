import React from 'react';

import { Container } from './TimeframesDropdown.styles';
import { Dropdown } from 'semantic-ui-react';

import { formatAPIDateString } from 'utils/date.utils';

const TimeframesDropdown = ({ historyTimeframes = [], loading, selectedInterval, setSelectedInterval }) => {
  const dropdownOptions = historyTimeframes.map((timeframe) => {
    const { start_date, end_date } = timeframe;

    return {
      key: end_date,
      value: end_date,
      text: `${formatAPIDateString({
        dateString: start_date,
        dateStringFormat: 'yyyy-MM-dd',
        showTime: false,
      })} - ${formatAPIDateString({
        dateString: end_date,
        dateStringFormat: 'yyyy-MM-dd',
        showTime: false,
      })}`,
    };
  });

  const handleIntervalChange = (e, { value }) => {
    e.preventDefault();

    const newInterval = historyTimeframes.find((timeFrame) => timeFrame.end_date === value);
    setSelectedInterval(newInterval);
  };

  return (
    <Container>
      <Dropdown
        loading={loading}
        onChange={handleIntervalChange}
        options={dropdownOptions}
        value={selectedInterval.end_date}
      />
    </Container>
  );
};

export default TimeframesDropdown;
