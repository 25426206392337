import React from 'react';

import { Icon } from 'components/common/Icon/Icon';
import { Popup } from 'semantic-ui-react';
import { MenuLink } from './AdminSidebar.styles';

const MenuLinkComponent = ({ iconName, text, to }) => (
  <Popup
    content={text}
    inverted
    position="left center"
    trigger={
      <MenuLink to={to}>
        <Icon name={iconName} size={24} style={{ color: '#4183c4' }} />
      </MenuLink>
    }
  />
);

export default MenuLinkComponent;
