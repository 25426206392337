import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-family: font- 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  justify-content: flex-end;
  margin-bottom: 34px;
`;

export const Currency = styled.div`
  align-items: center;
  color: ${({ $selected }) => ($selected ? '#232323' : '#1CA0BA')};
  cursor: pointer;
  display: flex;
  font-weight: ${({ $selected }) => ($selected ? 800 : 400)};
  justify-content: flex-start;
`;

export const Hyphen = styled.div`
  color: #232323;
`;
