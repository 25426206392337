import { get } from 'lodash';
import { JOB_TITLES } from '../components/Project/OrderProject/ProjectDetails/Proforma/translations';

export const canToggleDTP = (job) => {
  return !!job.dtp_price;
};

export function resolveSelectedService(job) {
  let value = null;

  if (!job) {
    return value;
  }

  if (isJobProofread(job)) {
    return 6;
  }

  if (job.service_1_selected) {
    value = 1;
  } else if (job.service_2_selected) {
    value = 2;
  } else if (job.service_3_selected) {
    value = 3;
  } else if (job.service_4_selected) {
    value = 4;
  } else if (job.service_5_selected) {
    value = 5;
  }

  return value;
}

// this determines whether the project job services are in the old or new prices format
export const hasJobServices = (jobs = []) => {
  const jobsWithServices = jobs.filter((job) => {
    const jobServices = get(job, 'job_services', []);
    return jobServices.length > 0;
  });
  return jobsWithServices.length > 0;
};

export const getJobPrice = (job) => {
  const selectedService = resolveSelectedService(job);

  const dtpPrice = job?.dtp_selected ? get(job, 'dtp_price', 0) : 0;

  if (selectedService === 6) {
    return job.proofread_price + dtpPrice;
  }

  const finalPrice = get(job, `service_${selectedService}_price`, 0);
  return dtpPrice + finalPrice;
};

export const isJobProofread = (job) => {
  if (
    !!job &&
    !!job.source_language &&
    !!job.target_language &&
    !!job.source_language.id &&
    !!job.target_language.id
  ) {
    return job.source_language.id === job.target_language.id;
  }
  return false;
};

export const getJobTranslationKey = (job) => {
  const selectedJob = resolveSelectedService(job);
  if (selectedJob) {
    if (isJobProofread(job)) {
      return 'common:proforma.proofread';
    } else {
      return `common:proforma.${JOB_TITLES[selectedJob - 1]}`;
    }
  } else {
    return 'services:archived';
  }
};

export const getShownServices = (job) => {
  const { job_services } = job;
  return job_services.filter((service) => !!service.selected);
};

export const isProjectProofread = (project) => {
  return project.project_type_id === 2;
};

export const getJobStatusTranslationKeys = (job) => {
  const { status_id, revision_status_id } = job;

  const translationsArray = [];

  if (status_id === 1) {
    translationsArray.push('common:projects.jobStatus.created');
  }
  if (status_id === 2) {
    translationsArray.push('common:projects.jobStatus.draft');
  }
  if (status_id === 3) {
    translationsArray.push('common:projects.jobStatus.inProgress');
  }

  if (status_id === 4) {
    translationsArray.push('common:projects.jobStatus.translation.assigned');
  }
  if (revision_status_id === 4) {
    translationsArray.push('common:projects.jobStatus.revision.assigned');
  }

  if (status_id === 5) {
    translationsArray.push('common:projects.jobStatus.translation.accepted');
  }
  if (revision_status_id === 5) {
    translationsArray.push('common:projects.jobStatus.revision.accepted');
  }

  if (status_id === 6) {
    translationsArray.push('common:projects.jobStatus.translation.finished');
  }
  if (revision_status_id === 6) {
    translationsArray.push('common:projects.jobStatus.revision.finished');
  }

  if (status_id === 10) {
    translationsArray.push('common:projects.jobStatus.selfServiceInProgress');
  }
  if (status_id === 11) {
    translationsArray.push('common:projects.jobStatus.selfServiceFinished');
  }

  if (status_id === 20) {
    translationsArray.push('common:projects.jobStatus.archived');
  }

  return translationsArray;
};

export const hasDTP = (jobs) => {
  return jobs.some((job) => job.dtp_price > 0);
};
