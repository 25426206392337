import FileSaver from 'file-saver';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Dropdown, Popup } from 'semantic-ui-react';
import { getOriginalFiles } from 'services/project';
import { sendUserInteraction } from 'utils/tagManager.utils';

const DownloadFilesItem = ({ project, user }) => {
  const { t } = useTranslation();

  const handleDownloadClick = async (e) => {
    if (user.active) {
      sendUserInteraction('download original project files click');
      e.preventDefault();
      toast.info(t('common:toasts.downloadFile.info'));
      try {
        // get file blob
        const response = await getOriginalFiles(project.id);
        const headers = response.headers;
        const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
        const blob = new Blob([response.data], { type: headers['content-type'] });
        // save as file
        FileSaver.saveAs(blob, fileName);
      } catch (e) {
        toast.error(t('common:toasts.downloadFile.error'));
      }
    }
  };

  const popupTrigger = (
    <Dropdown.Item
      onClick={handleDownloadClick}
      style={!user.active ? { cursor: 'default', opacity: '.45' } : null}
    >
      {t('common:projects.downloadFiles')}
    </Dropdown.Item>
  );

  return user.active ? (
    popupTrigger
  ) : (
    <Popup trigger={popupTrigger} content={t('common:projects.projectInfo.filesNoticeShort')} />
  );
};

export default DownloadFilesItem;
