import styled from 'styled-components';
import { Input } from '../../common/Input/Input';

export const ViewTypeContainer = styled.div`
  margin-top: 3.2em;
  display: flex;

  .button {
    font-family: 'DM Sans', sans-serif !important;
    flex-grow: 1;
  }

  > :last-child {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    display: none;
  `}
`;

export const StyledInput = styled(Input)`
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    width: 100%;
  `}
`;

export const StatusesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  .taia-checkbox {
    margin-bottom: 0.5rem;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    justify-content: space-between;

    .select-dropdown {
      flex-grow: 1;
    }
  `}
`;

export const StyledHeader = styled.h3`
  font-family: 'DM Sans', sans-serif;
`;
