import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import App from './App';

import BrowserUnsupported from './components/BrowserUnsupported/BrowserUnsupported';

import 'style/index.scss';
import 'style/toast.css';
import 'react-toastify/dist/ReactToastify.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlaneDeparture,
  faStickyNote,
  faCalculator,
  faStamp,
  faChargingStation,
  faBriefcase,
  faLandmark,
  faMailBulk,
  faCogs,
  faStethoscope,
} from '@fortawesome/free-solid-svg-icons';

import i18n from './i18n';
import store from './store/store';
import { unsupportedBrowser } from './utils/browser.utils';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { ThemeProvider } from 'styled-components';
import { theme } from './style/theme';

import { initializeTagManager } from './utils/tagManager.utils';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

library.add(
  faPlaneDeparture,
  faStickyNote,
  faCalculator,
  faStamp,
  faChargingStation,
  faBriefcase,
  faLandmark,
  faMailBulk,
  faCogs,
  faStethoscope,
);

initializeTagManager();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            {unsupportedBrowser() ? (
              <BrowserRouter>
                <BrowserUnsupported />
              </BrowserRouter>
            ) : (
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            )}
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </Provider>
    </Elements>
  </I18nextProvider>,
  document.getElementById('root'),
);
