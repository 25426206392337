import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 10px 20px 0;
  &:before {
    content: '';
    background: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    right: -7px;
    bottom: -7px;
    border: 1px solid #000;
    z-index: -1;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 4px 4px 18px -4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BillingName = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
`;

export const SectionContainer = styled.div`
  margin-top: 16px;
  padding: 0 32px;
  color: ${({ theme }) => theme.colors.greyDark};
  width: 100%;
`;

export const SectionHeading = styled.div`
  display: grid;
  grid-template-columns: 25px auto;
  grid-column-gap: 16px;
  width: 100%;
  font-size: 24px;
`;

export const SectionHeadingText = styled.div`
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SectionContent = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SectionRow = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 16px;
  align-items: center;
`;

export const CardFooter = styled.div`
  width: 100%;
  padding: 8px 16px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`;

export const IconButtonContainer = styled.div`
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
