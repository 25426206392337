import React, { useState } from 'react';
import { Icon } from 'components/common/Icon/Icon';
import styled from 'styled-components';
import RequiredSign from '../RequiredSign/RequiredSign';

const InputContainer = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.greyDark};

  input {
    width: 100%;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #cccccc;
    border-radius: 4px;
    outline: none;
    padding: 12px 14px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
    font-family: 'DM Sans', sans-serif !important;

    &:focus {
      border: solid 1px ${({ theme }) => theme.colors.blue};
      box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
      & + .icon {
        opacity: 0.6;
      }
    }

    ${({ theme }) => theme.mediaMaxWidth.mobile`
      font-size: 18px;
      line-height: normal;
    `}
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.black}!important;
  }

  input:-webkit-autofill::first-line {
    font-size: 18px;
    font-family: 'DM Sans', sans-serif !important;
  }
  &.label {
    input {
      font-size: 16px;
      padding: 22px 14px 12px !important;

      ${({ theme }) => theme.mediaMaxWidth.mobile`
        padding-top: 25px !important;
        font-size: 18px;
        line-height: normal;
      `}
    }
    label {
      position: absolute;
      font-size: 11px;
      top: 6px;
      left: 15px;
      pointer-events: none;

      ${({ theme }) => theme.mediaMaxWidth.mobile`
        font-size: 13px;
        line-height: normal;
        top: 10px;
      `}
    }
  }

  &.icon {
    input {
      padding-right: 4em;
    }
    & > .icon {
      position: absolute;
      line-height: 1;
      text-align: center;
      top: 0;
      right: 0;
      margin: 0;
      height: 100%;
      width: 4em;
      opacity: 0.4;
      border-radius: 0 4px 4px 0;

      svg {
        width: 23px;
        height: auto;
      }
    }
  }

  &.error {
    input {
      border-color: ${({ theme }) => theme.colors.red}!important;
      box-shadow: inset 1px 1px 3px 0 ${({ theme }) => theme.colors.redLight}!important;
    }
  }

  &.disabled {
    input {
      color: ${({ theme }) => theme.colors.greyDark};
      border-color: #ddd;
      cursor: not-allowed;
    }
  }

  &.small {
    input {
      padding: 11px 15px;
      font-size: 16px;
    }
    & > .icon {
      width: 3em;
    }
  }
`;

export const ErrorLabel = styled.div`
  color: ${({ theme }) => theme.colors.red};
  width: 100%;
  text-align: left;
  line-height: 1.2em;
  padding-bottom: 0.2em;
  margin-top: 0.2em;
`;

export const Input = ({
  label = null,
  required,
  className,
  style,
  icon,
  error,
  errorMsg,
  onChange = () => {},
  size,
  ...rest
}) => {
  const getClasses = () => {
    const classes = [className, 'input'];
    if (label) classes.push('label');
    if (icon) classes.push('icon');
    if (error) classes.push('error');
    if (rest.disabled) classes.push('disabled');
    if (size) classes.push(size);
    return classes.join(' ');
  };

  const handleInputChange = (e) => {
    return onChange(e, e.target);
  };

  return (
    <>
      <InputContainer className={getClasses()} style={style}>
        {label !== null && (
          <label>
            {label}
            {required ? <RequiredSign /> : null}
          </label>
        )}
        <input onChange={handleInputChange} {...rest} />
        {icon && typeof icon === 'string' ? <Icon name={icon} /> : icon}
      </InputContainer>
      {errorMsg && <ErrorLabel>{errorMsg}</ErrorLabel>}
    </>
  );
};

export const PasswordInput = (props) => {
  const [isVisible, setisVisible] = useState(false);
  return (
    <Input
      {...props}
      type={isVisible ? 'text' : 'password'}
      icon={
        <Icon
          link
          name={isVisible ? 'eye' : 'eye-slash'}
          onClick={() => {
            setisVisible((prev) => !prev);
          }}
        />
      }
    />
  );
};
