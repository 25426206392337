import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const SliderTableCell = styled(Table.Cell)`
  width: 870px;
  padding: 0 4.2rem !important;
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
    background: ${({ isEconomic }) => (isEconomic ? '#21ba45' : '#007bff')};
  }
  :hover {
    opacity: 1;
  }
`;

export const NoWrapSpan = styled.span`
  white-space: nowrap;
`;
