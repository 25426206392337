import { MainContainer } from 'components/common.styles';
import styled, { css } from 'styled-components';

export const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  font-size: 11px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #e9e9e9;
  color: ${({ theme }) => theme.colors.greyDark};
  border: 1px solid transparent;
  transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;

  ${({ active, theme }) =>
    active &&
    css`
      background-color: #c8e7ef;
      color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};
    `}
`;

export const TabContainer = styled.div`
  margin: 30px 47px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin: 0;
    padding: 14px ${theme.paddings.mainContainer.mobile.x} 28px;
  `}
`;

export const Segment = styled.div`
  display: grid;
  grid-template-columns: 275px 1fr;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    h3 {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      margin-bottom: 0;
    }
  `}
`;

export const Divider = styled.hr`
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  border: 0;
  margin-top: 20px;
  margin-bottom: 30px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin-top: 24px;
    margin-bottom: 13px;
  `}
`;

export const Description = styled.p`
  /* color: ${({ theme }) => theme.colors.grey}; */
  color: #999999;
  margin-right: 65px;
  font-size: 13px;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
  `}
`;

export const UserProfileMainContainer = styled(MainContainer)`
  height: 100%;

  .user-invitations__tab-pane > div {
    margin: 0;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    .user-profile-photo {
      &__input-container {
        > div {
          margin-left: 20px;
          flex-grow: 1;

          .button.labeled {
            width: 100%;
          }
        }
      }
    }

    .user-save-changes-button,
    .user-save-changes-button.labeled.left {
      width: 100%;
      
      // Size of the icon divided by 2, so the text appears centered
      padding-left: calc(2.57142857em / 2) !important;
    }
  `}
`;
