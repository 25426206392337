import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Header } from 'semantic-ui-react';
import Loader from '../common/Loader/Loader';
import Message from '../common/Message/Message';
import { getBillingDetails, getPaymentIntent, patchPaymentIntent } from '../../services/billing';
import {
  getProject,
  getProjectJobs,
  postConfirmProjectCharge,
  postProjectPaycheck,
} from '../../services/project';
import { fetchSubscriptions, findBillingId } from '../Checkout/Checkout.utils';
import CreditCardForm from '../Checkout/Payment/CreditCardForm/CreditCardForm';
import PaymentForm from '../Checkout/Payment/PaymentForm/PaymentForm';
import Button from '../common/Button/Button';
import { PaymentFailedContainer, ShowBillingToggle } from './PaymentFailed.style';
import PaymentSuccessful from './PaymentSuccessful/PaymentSuccessful';
import { sendUserInteraction } from 'utils/tagManager.utils';

const PaymentFailed = () => {
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [requiresPayment, setRequiresPayment] = useState(false);
  const [payment, setPayment] = useState({
    name: '',
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    countryId: 1,
    state: '',
  });
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [project, setProject] = useState(null);

  const user = useSelector((state) => state.userStore.user);
  const countries = useSelector((state) => state.classifiersStore.countries);
  const { token } = useParams();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const fetchProject = async (projectId) => {
      try {
        const { data } = await getProject(projectId);
        return data;
      } catch (e) {
        if (e.response.status === 404) {
          return null;
        } else {
          console.error(e);
        }
      }
    };

    const fetchPaymentIntent = async () => {
      setIsLoading(true);
      try {
        const intent = await getPaymentIntent(token);
        if (intent.status === 'succeeded') {
          setPaymentSuccess(true);
          return;
        }
        const billId = findBillingId(user);
        const billing = await getBillingDetails(billId);
        const newPayment = {
          email: billing.user_email || user.email,
          name: billing.company_name || '',
          address: billing.line1 || '',
          address2: billing.line2 || '',
          city: billing.city || '',
          postalCode: billing.postal_code || '',
          countryId: billing.country_id || 1,
          state: billing.state || '',
        };
        setRequiresPayment(intent.payment_method === null);
        if (intent.last_payment_error) {
          setError(intent.last_payment_error.message);
        } else {
          setError(t('common:paymentFailed.paymentFailed'));
        }
        const intentMeta = intent.metadata;

        if (intentMeta.type === 'project') {
          let proj = await fetchProject(intentMeta.project_id);
          const jobs = await getProjectJobs(intentMeta.project_id);
          proj = { ...proj, jobs };
          setProject(proj);
        }

        setPayment(newPayment);
        setPaymentIntent(intent);
      } catch (e) {
        setError(t('common:paymentFailed.loadFailed'));
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, t]);

  const onBillingToggle = (e, { checked }) => {
    sendUserInteraction(`payment failed use billing info as card info: ${checked}`);
    setShowForm(checked);
  };

  const onPaymentChange = ({ name, value }) => {
    const newPayment = { ...payment };
    newPayment[name] = value;
    setPayment(newPayment);
  };

  const retryPayment = async (e) => {
    sendUserInteraction('retry payment click');
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    try {
      setIsSubmitting(true);

      const cardElement = elements.getElement(CardElement);
      const country = countries.find((c) => c.id === payment.countryId);
      const pay = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          type: 'card',
          card: cardElement,
          billing_details: {
            name: payment.name,
            email: payment.email || null,
            address: {
              line1: payment.address,
              line2: payment.address2,
              state: payment.state,
              city: payment.city,
              country: country.iso_code,
            },
          },
        },
      });

      if (pay.error) {
        if (pay.error.payment_intent) {
          const intent = { ...pay.error.payment_intent };
          setRequiresPayment(intent.status === 'requires_payment_method');
          setPaymentIntent(intent);
        }
        setError(pay.error.message);
      } else {
        await patchPaymentIntent({ token: paymentIntent.id, paid: true });

        // Project payment confirmation if the payment intent is for project
        // TODO: Check if this works once BE is finished
        if (paymentIntent.metadata) {
          const intentMeta = paymentIntent.metadata;

          if (intentMeta.type === 'project') {
            await postConfirmProjectCharge({
              project_id: intentMeta.project_id,
              payment_intent: paymentIntent.id,
            });

            await postProjectPaycheck({
              calculation: false,
              comment: project.comment,
              diy: project.diy === 1,
              deliveryOption: project.is_delivery || 50,
              deductBalance: false,
              jobs: project.jobs,
              projectId: project.id,
            });
          } else if (intentMeta.type === 'subscription') {
            await fetchSubscriptions(true);
          }
        }

        setPaymentSuccess(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const confirmPayment = async (e) => {
    sendUserInteraction('confirm payment click');
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    try {
      setIsSubmitting(true);
      const pay = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentIntent.payment_method,
      });

      if (pay.error) {
        if (pay.error.payment_intent) {
          const intent = { ...pay.error.payment_intent };
          setRequiresPayment(intent.status === 'requires_payment_method');
          setPaymentIntent(intent);
        }
        setError(pay.error.message);
      } else {
        await patchPaymentIntent({ token: paymentIntent.id, paid: true });

        // Project payment confirmation if the payment intent is for project
        // TODO: Check if this works once BE is finished
        if (paymentIntent.metadata) {
          const intentMeta = paymentIntent.metadata;

          if (intentMeta.type === 'project') {
            await postConfirmProjectCharge({
              project_id: intentMeta.project_id,
              payment_intent: paymentIntent.id,
            });

            await postProjectPaycheck({
              calculation: false,
              comment: project.comment,
              diy: project.diy === 1,
              deliveryOption: project.is_delivery || 50,
              deductBalance: false,
              jobs: project.jobs,
              projectId: project.id,
            });
          } else if (intentMeta.type === 'subscription') {
            await fetchSubscriptions();
          }
        }

        setPaymentSuccess(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : paymentSuccess ? (
    <PaymentSuccessful />
  ) : (
    <PaymentFailedContainer>
      <Header as="h1">{t('common:paymentFailed.header')}</Header>
      <div className="sub-header">{t('common:paymentFailed.subheader')}</div>
      <div className="error-message">
        <Message text={error} type="error" />
      </div>
      {requiresPayment ? (
        <div className="card-form">
          <Header as="h2">{t('common:paymentFailed.paymentDeclined')}</Header>
          <div className="sub-text">{t('common:paymentFailed.paymentDeclindeExplanation')}</div>
          <CreditCardForm postalCode={payment.postalCode} />
          <ShowBillingToggle
            toggle
            label={t('common:checkout.payment.useBilling')}
            checked={showForm}
            onChange={onBillingToggle}
          />

          {!showForm && (
            <>
              <Header as="h2">{t('common:checkout.payment.paymentInfo')}</Header>
              <PaymentForm payment={payment} onPaymentChange={onPaymentChange} />
            </>
          )}
          <div className="submit-button">
            <Button
              actiontype="primary"
              onClick={retryPayment}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('common:paymentFailed.retryPayment')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="requires-confirmation">
          <Header as="h2">{t('common:paymentFailed.confirmPayment')}</Header>
          <div className="sub-text">{t('common:paymentFailed.requiresConfirmation')}</div>
          <Button
            actiontype="primary"
            onClick={confirmPayment}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {t('common:paymentFailed.confirmPayment')}
          </Button>
        </div>
      )}
    </PaymentFailedContainer>
  );
};

export default PaymentFailed;
