import { Header, Progress } from 'semantic-ui-react';
import styled from 'styled-components';

export const ProjectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'project tools'
    'jobs    jobs';
  grid-gap: 20px;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
  .project-details {
    grid-area: project;
  }
  .tools {
    grid-area: tools;
  }
  .jobs {
    grid-area: jobs;
  }

  .item.project-manager {
    display: flex;
    align-items: center;
  }
`;

export const ProjectHeader = styled.div`
  margin-left: -1em;
  margin-right: -1em;
  margin-top: -1em;
  padding: 1em 1em;
  background-color: ${(props) => props.backgroundColor || 'none'};
`;

export const ProjectProgress = styled(Progress)``;

export const TranslatedUpload = styled.div`
  display: grid;
  justify-items: center;

  img {
    width: 150px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeader = styled(Header)`
  margin-top: 0 !important;
`;

export const ContentContainer = styled.div`
  padding: 1em;
  width: 100%;
`;

export const TranslatedFiles = styled.div`
  display: inline-flex;
  margin-right: 4px;
  .button {
    border-radius: 0;
    margin: 0;
  }
  .button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
