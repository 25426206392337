import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MainContainer } from 'components/common.styles';

import ProjectServices from './ProjectServices/ProjectServices';
import SelectedServices from './ProjectServices/SelectedServices/SelectedServices';
import AdminSidebar from 'components/common/AdminSidebar/ProjectOrdered';

import { hasJobServices } from '../../../utils/jobs.utils';
import { useHistory, useLocation } from 'react-router';
import { SidebarWrapper } from 'components/common/AdminSidebar/AdminSidebar.styles';
import { hasAdminPermissions } from 'utils/user.utils';

import './style.scss';
import OrderProjectHeader from '../OrderProject/OrderProjectHeader';
import { useTranslation } from 'react-i18next';
import ProjectTimeline from './ProjectTimline/ProjectTimeline';
import Price from 'components/common/Price/Price';
import { Comment } from 'components/common/Comment/Comment';
import ProjectFiles from './ProjectFiles/ProjectFiles';

const ProjectOrdered = ({ project, billingDetails, pricesData, onSubmit }) => {
  const user = useSelector((state) => state.userStore.user);
  const { jobs } = project;
  const [commentText, setCommentText] = useState(project.comment);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  /**
   * Runs on active step change, sets step query in url
   */
  useEffect(() => {
    // Update url
    history.push({
      pathname: location.pathname,
      search: '?step=ordered',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdmin = useMemo(() => {
    return hasAdminPermissions(user.role);
  }, [user]);

  return (
    <SidebarWrapper isAdmin={isAdmin} overflow={false}>
      <div className="project-open">
        <OrderProjectHeader project={project} />
        <MainContainer className="project-open-container">
          <div className="project-open-content ">
            <div className="project-progress margin-3">
              <ProjectTimeline
                status={project.status_id}
                log={project.logs}
                deliveryDate={project.delivery_time}
              />
            </div>
            {!!project.comment && (
              <div className="project-comment margin-3">
                <div className="heading-3 strong">{t('common:projects.userProject.comment')}</div>
                <Comment
                  project={project}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  placeholder={t('common:projects.commentPlaceholder')}
                  id="order-project-jobs-comment-input"
                />
              </div>
            )}
            <div className="project-files margin-3">
              <div className="heading-3 strong">{t('common:projects.projectInfo.downloadFiles')}</div>
              <div className="project-files-buttons">
                <ProjectFiles project={project}></ProjectFiles>
              </div>
            </div>
            <div className="project-price-breakdown margin-3">
              <div className="heading-3 strong">{t('common:projects.userProject.servicesOverview')}</div>
              {hasJobServices(jobs) ? (
                <SelectedServices currency={project.currency} jobs={jobs} />
              ) : (
                <ProjectServices jobs={jobs} role={user.role} currency={project.currency} />
              )}
              <div className="project-total-price margin-4">
                <div className="project-price-title heading-4 strong">
                  {t('common:projects.userProject.totalPrice')}
                </div>
                <div className="project-price-total heading-4 strong">
                  <Price currency={pricesData.currency} price={pricesData.full_price} />
                </div>
              </div>
            </div>
          </div>
        </MainContainer>
      </div>
      {isAdmin ? <AdminSidebar project={project} refreshProject={onSubmit} /> : null}
    </SidebarWrapper>
  );
};

export default ProjectOrdered;
