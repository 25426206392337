import styled from 'styled-components';
import ProjectLanguages from '../../common/ProjectLanguages/ProjectLanguages';

export const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ProjectName = styled.h1`
  font-size: 36px;
  text-align: left;
  margin: 16px 0 !important;
`;

export const SubHeading = styled.h2`
  font-size: 20px;
  text-align: left;
  margin: 0 !important;
`;

export const StyledProjectLanguages = styled(ProjectLanguages)`
  margin: 8px 0 16px 0;
`;
