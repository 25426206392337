import CircleTimeline from 'components/common/CircleTimeline/CircleTimeline';
import React from 'react';

const StepsTimeline = ({ steps, stepsContext, disableAll, iconsSize = 'small' }) => {
  const { index, completed } = stepsContext;

  const isStepActive = (idx) => idx === index;
  const isStepCompleted = (idx) => completed.includes(idx);

  const isStepDisabled = (idx) => {
    const stepActive = isStepActive(idx);
    const stepCompleted = isStepCompleted(idx);

    // Also check prev step if its complete
    const prevStepCompleted = idx === 0 ? true : isStepCompleted(idx - 1);

    return !prevStepCompleted && ((!stepCompleted && !stepActive) || disableAll);
  };

  const getStateForStep = (idx) => ({
    current: isStepActive(idx),
    disabled: isStepDisabled(idx),
    finished: isStepCompleted(idx),
  });

  const items = steps.map(({ icon, label: title }, idx) => ({
    icons: {
      default: icon,
      finished: 'check',
      size: iconsSize,
    },
    label: { title },
    state: getStateForStep(idx),
    to: `/vendor-onboarding${idx !== 0 ? `?step=${idx + 1}` : ''}`,
  }));

  return <CircleTimeline items={items} />;
};

export default StepsTimeline;
