import styled from 'styled-components';
import Button from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    margin-top: 20px;
  }

  // Mobile breakpoint
  ${({ theme }) => theme.mediaMaxWidth.mobile`
    flex-direction: row;
    margin-top: 15px;
  `}
`;

export const AvatarImg = styled.img`
  height: 88px;
  width: 88px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #e4e4e4;

  // Mobile breakpoint
  ${({ theme }) => theme.mediaMaxWidth.mobile`
    height: 66px;
    width: 66px;
  `}
`;

export const StyledInput = styled(Input)`
  width: clamp(300px, 85%, 400px);

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 20px !important;

  // Mobile breakpoint
  ${({ theme }) => theme.mediaMaxWidth.mobile`
    &.button.labeled.left {
      margin-left: 0px !important;
      padding-left: 3.71em !important;
      padding-right: 1.14em !important;

      font-size: 16px;
      line-height: 1.75;
      color: ${({ theme }) => theme.colors.greyDark};
    }
  `}
`;
