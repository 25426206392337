import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../common/Loader/Loader';
import Message from '../common/Message/Message';
import { StyledLink, StyledHeader } from '../common.styles';
import { StyledCard, InnerContainer } from 'components/SignIn/SignIn.styles';

import { getAffiliateToken } from '../../services/auth';
import { setAffiliateToken } from '../../store/userSlice';
import { sendUserInteraction } from 'utils/tagManager.utils';

const Affiliate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const validateToken = async () => {
      const { referralToken } = params;

      try {
        const affiliateResponseData = await getAffiliateToken(referralToken);
        if (affiliateResponseData.success) {
          dispatch(setAffiliateToken({ affiliateToken: referralToken }));
          history.push('/register');
        } else {
          // show token does not exist message
          setError('common:affiliate.errors.tokenMissing');
        }
      } catch (e) {
        setError('common:errors.noConnection');
      } finally {
        setIsLoading(false);
      }
    };

    validateToken();
  }, [params, dispatch, history]);

  return (
    <StyledCard>
      <InnerContainer>
        <StyledHeader>{t('common:affiliate.affiliateToken')}</StyledHeader>

        {isLoading && <Loader inline>{t('common:affiliate.validatingToken')}</Loader>}
        {error && (
          <>
            <Message text={t(error)} type="error" style={{ marginBottom: '2rem' }} />
            <StyledLink
              onClick={() => sendUserInteraction('affiliate page register without token link click')}
              to="/register"
            >
              {t('common:affiliate.registerWithoutToken')}
            </StyledLink>
          </>
        )}
      </InnerContainer>
    </StyledCard>
  );
};

export default Affiliate;
