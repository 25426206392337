import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Utils
import { isEqual } from 'lodash';
import { saveAs } from 'file-saver';
import { downloadVendorCV, downloadVendorAdditionalFiles } from 'services/vendors';
import { getFileExtension, getFileName } from 'utils/files.utils';
import { updateVendorData, getVendorData } from 'services/vendors';
import { cvUploadOptions, additionalFilesUploadOptions } from 'constants/vendors';

// Components
import { Icon } from 'components/common/Icon/Icon';
import ProfessionalExperienceForm from 'components/common/Vendor/Forms/ProfessionalExperienceForm/ProfessionalExperienceForm';
import Button from 'components/common/Button/Button';
import FileUploadMultiple from 'components/common/FileUpload/FileUploadMultiple/FileUploadMultiple';
import FileUploadSingle from 'components/common/FileUpload/FileUploadSingle/FileUploadSingle';
import FileUploadPreview from 'components/common/FileUpload/FileUploadPreview/FileUploadPreview';
import SectionDescription from 'components/common/SectionDescription/SectionDescription';
import { Divider } from 'semantic-ui-react';

// Helper functions for file initial states

/**
 * Create dummy CV File object
 */
const formatUserCVinitialState = (data) => {
  if (!data.cv) return null;

  const dummyCV = new File([] /** Empty */, 'cv');
  dummyCV.isFromBE = true;
  return dummyCV;
};

/**
 * Check if user has additional files uploaded as vendor
 * Create a dummy File object for each
 */
const formatAdditionalFilesInitState = (data) => {
  return data?.files
    ? data?.files.map((file) => {
        const dummyFile = new File([] /** Empty */, file.name);

        // Set extra properties needed
        dummyFile.isFromBE = true;
        dummyFile.id = file.id;

        return dummyFile;
      })
    : [];
};

/**
 * Renders professional experience tab on user profile
 */
const ProfessionalExperienceFormEdit = ({ vendorData, onSubmit = async () => {} }) => {
  // Local state
  const [formData, setFormData] = useState({
    linkedin: vendorData.linkedin ?? '',
    education: vendorData.education ?? '',
    experience: vendorData.experience ?? '',
  });

  const [loading, setLoading] = useState(false);
  const [userCV, setUserCV] = useState(formatUserCVinitialState(vendorData));
  const [userAdditionalFiles, setUserAdditionalFiles] = useState(formatAdditionalFilesInitState(vendorData));
  const [fileChanges, setFileChanges] = useState({
    cv: false,
    additionalFiles: false,
  });
  const [canSaveChanges, setCanSaveChanges] = useState(false);

  // Refs
  const onLoadFormState = useRef({
    ...formData,
  });

  const onLoadAdditionalFiles = useRef(userAdditionalFiles);

  // Hooks
  const { t } = useTranslation();

  // Methods
  /**
   * Fires on user CV change
   * Set userCV to passed File and set file changes tracker for cv to true
   */
  const handleCVChange = useCallback(
    (_, { value: file }) => {
      const extension = getFileExtension(file);
      const newCV = new File([file], `cv.${extension}`);
      setUserCV(newCV);

      setFileChanges((c) => ({
        ...c,
        cv: true,
      }));
    },
    [setUserCV, setFileChanges],
  );

  /**
   * Fires when user removes the file
   * Reset userCv to null and set file changes tracker for cv to true
   */
  const handleCVRemove = useCallback(() => {
    setUserCV(null);

    setFileChanges((c) => ({
      ...c,
      cv: true,
    }));
  }, [setUserCV, setFileChanges]);

  /**
   * Fires on additional files changes
   * Update state with new additional files and set file changes tracker for additional files to true
   */
  const handleAdditionalFilesChange = useCallback(
    (_, { value: files }) => {
      setUserAdditionalFiles(files);

      setFileChanges((c) => ({
        ...c,
        additionalFiles: true,
      }));
    },
    [setUserAdditionalFiles, setFileChanges],
  );

  /**
   * Remove additional file at index and update file changes tracker
   */
  const handleRemoveAdditionalFile = (index) => {
    setUserAdditionalFiles((c) => {
      const newFiles = [...c];
      newFiles.splice(index, 1);

      return newFiles;
    });

    setFileChanges((c) => ({
      ...c,
      additionalFiles: true,
    }));
  };

  /**
   * Rename file at index
   * The file preview component returns the changed File instance in the onChange callback
   */
  const handleFileRename = (file, index) => {
    setUserAdditionalFiles((c) => {
      const newFiles = [...c];
      newFiles[index] = file;

      return newFiles;
    });
  };

  /**
   * Handles the download of addition files
   * on component mount the additional files get an added property "isFromBE"
   * If file has that property download the file from the BE
   * else get client uploaded file data and use file saver to save the file to client device
   */
  const handleFileDownload = async (idx) => {
    try {
      const file = userAdditionalFiles[idx];
      let fileData = null;

      if (userAdditionalFiles[idx].isFromBE) {
        if (!file.id) return;

        const response = await downloadVendorAdditionalFiles(file.id);
        fileData = response.data;
      } else {
        // Download directly
        fileData = await file.arrayBuffer();
      }

      saveAs(fileData, file.name);
    } catch (_) {
      toast.error(t('common:userInfo.vendorTabs.professionalExperience.toast.fileDownload.error'));
    }
  };

  /**
   * Handles the download of vendor CV
   * Same as additional files, on component mount the file gets an extra property "isFromBe"
   * If file has that property download from BE, otherwise from client
   */
  const handleCVDownload = async () => {
    try {
      const file = userCV;

      let fileData = null;
      let filename = getFileName(file);

      // response header content type => extension for it
      const extensions = {
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/msword': 'doc',
        'application/pdf': 'pdf',
      };

      if (userCV.isFromBE) {
        const response = await downloadVendorCV(vendorData.id);

        fileData = response.data;
        filename += `.${extensions[response.headers['content-type']]}`;
      } else {
        fileData = file;
        filename += `.${getFileExtension(file)}`;
      }

      saveAs(fileData, filename);
    } catch (_) {
      toast.error(t('common:userInfo.vendorTabs.professionalExperience.toast.fileDownload.error'));
    }
  };

  /**
   * Handle save changes
   */
  const handleSaveChanges = async () => {
    setLoading(true);

    let newVendorData = null;

    /**
     * Helper function used to refetch vendor data
     */
    const refetchVendorData = async () => {
      try {
        const responseGet = await getVendorData(vendorData.id);
        newVendorData = responseGet.data;
      } catch (error) {
        console.error(error);
        toast.error('There was problem fetching vendor data');
      }
    };

    /**
     * Update vendor
     */
    try {
      const currFormState = formData;

      const differences = {
        files: {
          add: [],
          remove: [],
        },
      };

      // Text inputs differences
      for (const key in currFormState) {
        const currValue = currFormState[key];
        const initialValue = onLoadFormState.current[key];

        if (currValue !== initialValue) {
          differences[key] = currValue;
        }
      }

      // File differences
      if (fileChanges.cv || fileChanges.additionalFiles) {
        // User cv changed
        if (fileChanges.cv) {
          differences.cv = userCV;
        }

        // If additional files have changes
        if (fileChanges.additionalFiles) {
          const initialFiles = onLoadAdditionalFiles.current;
          const currFiles = userAdditionalFiles;

          // Check for deleted files
          initialFiles.forEach((file) => {
            // Check if file is in initial value
            if (!currFiles.some((f) => f.name === file.name)) {
              differences.files.remove.push(file);
            }
          });

          // Check for added files
          currFiles.forEach((file) => {
            // Check if file is in initial value
            if (!initialFiles.some((f) => f.name === file.name)) {
              differences.files.add.push(file);
            }
          });
        }
      }

      // Update vendor
      const responsePatch = await updateVendorData({ id: vendorData.id, data: differences });
      newVendorData = responsePatch?.data;

      toast.success(t('common:userInfo.vendorTabs.professionalExperience.toast.profileUpdate.success'));
    } catch (error) {
      toast.error(t('common:userInfo.vendorTabs.professionalExperience.toast.profileUpdate.error'));
      console.error(error);
    } finally {
      // If newVendorData was not set by updateVendorData or there was an error
      if (!newVendorData) await refetchVendorData();

      await onSubmit(newVendorData);

      // Reset loading and can save changes
      setLoading(false);
      setCanSaveChanges(false);
    }
  };

  /**
   * Used for checking the form data,
   * if data is different and if required values are valid
   * Set canSaveChanges accordingly
   */
  useEffect(() => {
    // Check if different from initial form state
    const currFormState = {
      ...formData,
    };

    const formDifferentFromInitial = !isEqual(currFormState, onLoadFormState.current);
    const haveFilesChanged = fileChanges.cv || fileChanges.additionalFiles;

    const valuesChanged = formDifferentFromInitial || haveFilesChanged;
    const areRequiredValid = formData.education !== '' && formData.experience !== '' && userCV !== null;

    const canSaveChanges = valuesChanged && areRequiredValid;

    setCanSaveChanges(canSaveChanges);
  }, [formData, fileChanges, onLoadFormState, userCV]);

  /**
   * Fires on vendorData prop change, so the initialForm state is synced, same for the files
   */
  useEffect(() => {
    const initialAdditionalFiles = formatAdditionalFilesInitState(vendorData);
    const initialCVFile = formatUserCVinitialState(vendorData);

    // Set initial files
    setUserAdditionalFiles(initialAdditionalFiles);
    setUserCV(initialCVFile);

    // Update initialFormState
    onLoadFormState.current = {
      linkedin: vendorData.linkedin ?? '',
      education: vendorData.education ?? '',
      experience: vendorData.experience ?? '',
    };

    onLoadAdditionalFiles.current = initialAdditionalFiles;

    // Reset file changes track
    setFileChanges({
      cv: false,
      additionalFiles: false,
    });
  }, [vendorData, setUserAdditionalFiles, setUserCV, setFileChanges]);

  return (
    <div className="professional-experience-form-edit">
      <ProfessionalExperienceForm data={formData} onChange={setFormData} handleFiles={false} />

      {/* Handle files in this component */}
      <SectionDescription
        title={`${t('common:serviceProvider.forms.inputs.cv.title')} *`}
        description={t('common:serviceProvider.forms.inputs.cv.description')}
        disabled={loading}
        content={
          <div className="cv-upload">
            <FileUploadSingle
              showPreviews={false}
              options={cvUploadOptions}
              onChange={handleCVChange}
              selectedFile={userCV}
            />
            {userCV && (
              <FileUploadPreview
                key={`file-display-cv`}
                file={userCV}
                onRemove={handleCVRemove}
                showDlPopup
                showSize={false}
                onClick={handleCVDownload}
                editable={false}
              />
            )}
            <p>
              {t('common:serviceProvider.forms.inputs.cv.support')}
              {cvUploadOptions.accept}. {t('common:serviceProvider.forms.inputs.cv.maxSize')} 3 MB.
            </p>
          </div>
        }
      />

      <Divider />

      <SectionDescription
        title={t('common:serviceProvider.forms.inputs.additionalFiles.title')}
        description={t('common:serviceProvider.forms.inputs.additionalFiles.description')}
        disabled={loading}
        content={
          <div className="additional-upload">
            <FileUploadMultiple
              options={additionalFilesUploadOptions}
              onChange={handleAdditionalFilesChange}
              selectedFiles={userAdditionalFiles}
              showPreviews={false}
            />

            {userAdditionalFiles.length > 0 &&
              userAdditionalFiles.map((file, idx) => (
                <FileUploadPreview
                  key={`file-display-${file.name}`}
                  file={file}
                  files={userAdditionalFiles}
                  onRemove={() => handleRemoveAdditionalFile(idx)}
                  onChange={(file) => handleFileRename(file, idx)}
                  showDlPopup
                  showSize={false}
                  onClick={() => handleFileDownload(idx)}
                  editable={false}
                />
              ))}

            <p>
              {t('common:serviceProvider.forms.inputs.additionalFiles.support')}
              {additionalFilesUploadOptions.accept}.{' '}
              {t('common:serviceProvider.forms.inputs.additionalFiles.maxSize')} 10 MB.
            </p>
          </div>
        }
      />

      <Divider />

      <div className="section-description">
        <div className="left" />
        <div className="right">
          <Button
            big
            labelPosition="left"
            actiontype="primary"
            disabled={!canSaveChanges || loading}
            loading={loading}
            onClick={handleSaveChanges}
          >
            <Icon name="save" />
            {t('common:userInfo.updateProfile.saveChanges')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalExperienceFormEdit;
