import { parse } from 'date-fns';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { enGB } from 'date-fns/locale';

export const parseAPIDateStringToDate = ({ dateString, dateStringFormat = 'yyyy-MM-dd HH:mm:ss' }) => {
  if (!!dateString) {
    const dateObject = parse(dateString, dateStringFormat, new Date());

    return dateObject;
  } else {
    return new Date();
  }
};

export const parseAPIDateStringToUTC = (dateString) => {
  if (!!dateString) {
    const date = zonedTimeToUtc(dateString, 'Europe/Ljubljana');
    return date;
  } else {
    return new Date();
  }
};

// use this to work with Date objects
export const formatDate = ({ dateObject, showTime = true, showYear = true }) => {
  // currently we default to user browser locale for date formatting, but we should change this to i18n selected language when we add support for multiple languages

  const formatOptions = {
    day: 'numeric',
    month: 'long',
  };

  if (showTime) {
    formatOptions.hour = '2-digit';
    formatOptions.minute = '2-digit';
  }

  if (showYear) {
    formatOptions.year = 'numeric';
  }

  return dateObject.toLocaleDateString('en-GB', formatOptions);
};

// use this to work with API date strings
export const formatAPIDateString = ({ dateString, showTime, showYear }) => {
  const dateObject = parseAPIDateStringToUTC(dateString);
  const localisedDate = formatDate({ dateObject, showTime, showYear });
  return localisedDate;
};

export const ukTimezone = () => {
  const date = new Date();
  date.setHours(5, 0, 0);

  return format(date, 'zzz', { timeZone: 'Europe/London', locale: enGB });
};

export const toDateTimeLocal = (date) => {
  return date ? date.toISOString().slice(0, -8) : date;
};
