import styled from 'styled-components';
import Button from '../../../common/Button/Button';

export const ConfirmationButton = styled(Button)`
  margin-top: 0.5rem !important;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ButtonsContainer = styled.div`
  min-width: 80px;
  .button {
    border: none;
    margin: 0;
    &.check {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;
