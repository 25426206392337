import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../../common/Button/Button';
import { Modal } from 'semantic-ui-react';

import { deleteTeamMember } from '../../../../services/teams';

const RemoveMemberModal = ({ onCancel, onSubmit, team_id, user }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRemove = async () => {
    try {
      setIsSubmitting(true);
      await deleteTeamMember({ team_id, user_id: user.id });
      onSubmit();
      toast.success(t('common:toasts.removeMember.success'));
      setIsSubmitting(false);
      onCancel();
    } catch (e) {
      toast.error(t('common:toasts.removeMember.error'));
    } finally {
      setIsSubmitting(true);
    }
  };

  return (
    <Modal open size="small">
      <Modal.Header>{t('common:team.members.removeMember.modalTitle')}</Modal.Header>
      <Modal.Content>
        {`${t('common:team.members.removeMember.modalText1')} ${user.name} ${t(
          'common:team.members.removeMember.modalText2',
        )}`}
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="secondary" disabled={isSubmitting} onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="primary" loading={isSubmitting} onClick={handleRemove}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RemoveMemberModal;
