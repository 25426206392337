import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../common/Button/Button';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import { Icon } from '../../common/Icon/Icon';
import SortInput from './SortInput';
import { StyledInput, StatusesContainer, ViewTypeContainer, StyledHeader } from './ProjectsFilter.styles';

import { STATUS } from '../../common/StatusLabel/StatusLabel.constants';
import FilterSidebar from '../../common/FilterSidebar/FilterSidebar';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { theme } from 'style/theme';
import ShowProjectsFilter from './ShowProjectsFilter/ShowProjectsFilter';

const ProjectFilter = ({
  nameQuery = '',
  selectedStatuses = [],
  selectedTeams = [],
  selectedUserID,
  sortParam,
  sortOrder,
  teamOptions = [],
  updateSearchParams,
  resetSearchParams,
  trigger,
  viewType = null,
  setViewType = () => {},
  userID,
  displayShowFilter = false,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  const statusOptions = Object.keys(STATUS).map((statusKey) => {
    return {
      text: t(STATUS[statusKey]),
      value: statusKey,
    };
  });

  const isStatusSelected = (statusId) => {
    return selectedStatuses.includes(statusId);
  };

  const isTeamSelected = (teamId) => {
    return selectedTeams.includes(teamId);
  };

  const handleResetClick = async (e) => {
    e.preventDefault();
    await resetSearchParams();
  };

  const handleNameChange = (e) => {
    updateSearchParams({
      search: e.target.value,
    });
  };

  const handleStatusClick = (statusId) => {
    const isSelected = isStatusSelected(statusId);
    if (isSelected) {
      const newStatuses = selectedStatuses.filter((oldStatusId) => oldStatusId !== statusId);
      updateSearchParams({
        status_id: newStatuses,
      });
    } else {
      const newStatuses = [...selectedStatuses, statusId];
      updateSearchParams({
        status_id: newStatuses,
      });
    }
  };

  const handleTeamClick = (teamId) => {
    const isSelected = isTeamSelected(teamId);
    if (isSelected) {
      const newTeams = selectedTeams.filter((oldTeamId) => oldTeamId !== teamId);

      updateSearchParams({
        team_id: newTeams,
      });
    } else {
      const newTeams = [...selectedTeams, teamId];

      updateSearchParams({
        team_id: newTeams,
      });
    }
  };

  // Effects
  useEffect(() => {
    if (isMobile) {
      // Set to grid view on mobile
      setViewType('GRID');
    }
  }, [isMobile, setViewType]);

  // Render variables
  const statusAllChecked = selectedStatuses.length === statusOptions.length;
  const statusSomeChecked = selectedStatuses.length > 0;
  const statusAllButtonText = t(`common:projects.filters.${statusAllChecked ? 'de' : ''}selectAll`);

  const handleStatusAll = () => {
    const newStatuses = statusAllChecked ? [] : statusOptions.map(({ value }) => value);

    updateSearchParams({
      status_id: newStatuses,
    });
  };

  return (
    <FilterSidebar trigger={trigger} width="auto" className="project-filter-sidebar">
      {viewType !== null && (
        <ViewTypeContainer>
          <Button
            onClick={() => setViewType('LIST')}
            actiontype={viewType === 'LIST' ? 'primary' : 'secondary'}
            labelPosition="left"
            className="btn-list-view"
          >
            <Icon name="list-ul" />
            {t('common:projects.listView')}
          </Button>
          <Button
            onClick={() => setViewType('GRID')}
            actiontype={viewType === 'GRID' ? 'primary' : 'secondary'}
            labelPosition="left"
          >
            <Icon name="apps" />
            {t('common:projects.gridView')}
          </Button>
        </ViewTypeContainer>
      )}
      <SortInput sortParam={sortParam} sortOrder={sortOrder} updateSearchParams={updateSearchParams} />
      <StyledHeader>{`${t('common:projects.filters.heading')}:`}</StyledHeader>

      <p>{`${t('common:projects.filters.projectName')}:`}</p>
      <StyledInput value={nameQuery} onChange={handleNameChange} size="small" />

      {teamOptions.length > 0 && displayShowFilter && (
        <ShowProjectsFilter
          userID={userID}
          teamOptions={teamOptions}
          updateSearchParams={updateSearchParams}
          selected={!!selectedUserID}
          selectedTeams={selectedTeams}
        />
      )}

      {teamOptions.length > 0 && (
        <>
          <p as="h3">{`${t('common:projects.filters.projectTeam')}:`}</p>
          <StatusesContainer>
            {teamOptions.map(({ id, name }) => (
              <Checkbox
                checked={isTeamSelected(id)}
                key={id}
                label={name}
                onClick={() => handleTeamClick(id)}
                value={id}
              />
            ))}
          </StatusesContainer>
        </>
      )}

      <p>{`${t('common:projects.filters.projectStatus')}:`}</p>
      <div className="sidebar-field">
        <Checkbox
          className="filters-checkbox-select-all"
          label={statusAllButtonText}
          onClick={handleStatusAll}
          checked={statusAllChecked}
          indeterminate={statusSomeChecked && !statusAllChecked}
        />
      </div>
      <StatusesContainer>
        {statusOptions.map(({ value, text }) => (
          <Checkbox
            checked={isStatusSelected(value)}
            key={value}
            label={text}
            onClick={() => handleStatusClick(value)}
            value={value}
          />
        ))}
      </StatusesContainer>

      <Button onClick={handleResetClick} style={{ marginBottom: '2rem' }} fluid>
        {t('common:projects.filters.reset')}
      </Button>
    </FilterSidebar>
  );
};

export default ProjectFilter;
