import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';

/**
 * Render category card
 */
const CategoryCard = ({ link, imgSrc, title = '' }) => {
  // Variables
  const isImgValid = imgSrc && imgSrc !== '';
  const isHrefValid = link && link !== '';

  // Props
  const linkProps = {
    href: link,
    target: '_blank',
    rel: 'noopener noreferrer',
    className: 'category-card__link',
  };

  return (
    <div className="category-card">
      {isHrefValid ? (
        <a {...linkProps}>
          <div className="category-card__content">
            {isImgValid && <img src={imgSrc} alt={title || 'Category'} />}
            <h2>{title}</h2>
          </div>
        </a>
      ) : (
        <div className="category-card__content">
          {isImgValid && <img src={imgSrc} alt={title || 'Category'} />}
          <h2>{title}</h2>
        </div>
      )}
    </div>
  );
};

// Prop types
CategoryCard.propTypes = {
  link: PropTypes.string,
  imgSrc: PropTypes.string,
  title: PropTypes.string,
};

export default CategoryCard;
