import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonContainer,
  Container,
  FlavorText,
  FlavourImage,
  Heading,
  TextContainer,
} from './ProjectWaiting.style';
import img from 'assets/taia-graphic-list-checkmark.png';
import OriginalFilesDownload from 'components/common/OriginalFilesDownload/OriginalFilesDownload';
import Button from 'components/common/Button/Button';
import { useSelector } from 'react-redux';
import { Icon } from 'components/common/Icon/Icon';
import { Link } from 'react-router-dom';
import { hasAdminPermissions } from 'utils/user.utils';
import ArchiveModal from 'components/common/ArchiveModal/ArchiveModal';
import { canArchiveProject } from 'utils/user.utils';

const ProjectWaiting = ({ project, refreshProject }) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.userStore.user);

  const showArchiveOption = canArchiveProject(project.status_id);

  return (
    <Container>
      <TextContainer>
        <FlavourImage src={img} />
        <Heading>{t('common:projects.waitingPM.waitingPM')}</Heading>
        <FlavorText>{t('common:projects.waitingPM.waitingDescription')}</FlavorText>
      </TextContainer>
      <ButtonContainer>
        <OriginalFilesDownload projectId={project?.id} />
        {showArchiveOption && (
          <ArchiveModal
            trigger={
              <Button labelPosition="right">
                {t('common:projects.archive')}
                <Icon name="archive" />
              </Button>
            }
            project={project}
            setLoading={(loading) => {
              if (!loading) {
                refreshProject();
              }
            }}
          />
        )}
        {hasAdminPermissions(user.role) ? (
          <Link to={`/project/${project?.id}/settings`}>
            <Button labelPosition="right">
              {t('common:projects.waitingPM.setupProject')}
              <Icon name="cog" />
            </Button>
          </Link>
        ) : undefined}
      </ButtonContainer>
    </Container>
  );
};

export default ProjectWaiting;
