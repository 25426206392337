import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  padding: 1em 1.5em;
  z-index: 10;
`;

export const Banner = ({ content, style, className }) => {
  return (
    <Container style={style} className={className}>
      {content}
    </Container>
  );
};

Banner.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
