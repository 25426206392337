import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router';

import { MainContainer } from 'components/common.styles';

import { getTmSettings, getUser, postTmSettings } from '../../services/users';

import Message from '../common/Message/Message';
import { checkAllowedRole } from '../../utils/user.utils';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import Tab from 'components/common/Tab/Tab';
import BasicInformation from 'components/UserProfile/BasicInformation/BasicInformation';
import { Divider } from 'components/UserProfile/UserProfile.styles';
import { Description } from 'components/UserProfile/UserProfile.styles';
import { Segment } from 'components/UserProfile/UserProfile.styles';
import { InputContainer } from 'components/UserProfile/BasicInformation/BasicInformation.styles';
import ShareToggle from 'components/common/ProfileCard/EditUserModal/ShareToggle';
import { hasActiveSubscriptions } from 'utils/catapult.utils';
import { collectUserSubscriptions } from 'utils/catapult.utils';
import UserBillingDetails from 'components/UserProfile/UserBillingDetails/UserBillingDetails';

const AdminUser = () => {
  const [user, setUser] = useState({ role: 30 });
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shareTm, setShareTm] = useState({});
  const [shareChanged, setShareChanged] = useState(false);
  const [error, setError] = useState(false);

  const { user_id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);
        const user = await getUser(user_id);
        let tm = null;
        if (!checkAllowedRole({ userRole: user.role, allowedRoles: [40, 42] })) {
          tm = await getTmSettings(user_id);
        }
        const subscriptions = await collectUserSubscriptions(user);
        setUser(user);
        setSubscriptions(subscriptions);
        setShareTm(
          !checkAllowedRole({ userRole: user.role, allowedRoles: [40, 42] })
            ? { tm_settings_id: tm.id, share_tm: tm.share_tm }
            : {},
        );
      } catch (e) {
        setError(true);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const panes = useMemo(() => {
    const handleInfoSubmit = async () => {
      try {
        if (!checkAllowedRole({ userRole: user.role, allowedRoles: [40, 42] })) {
          if (!hasActiveSubscriptions(subscriptions) && shareChanged) {
            await postTmSettings(shareTm);
          }
        }

        const newUser = await getUser(user_id);
        setUser(newUser);
      } catch (e) {
        console.error(e);
      }
    };

    const handleShareChange = (share) => {
      setShareChanged(true);
      setShareTm(share);
    };

    return [
      {
        title: t('common:userInfo.tabs.basicInfo'),
        render: () => (
          <Tab.Pane>
            <BasicInformation
              user={user}
              onSubmit={handleInfoSubmit}
              wasAdditionalChanged={() => shareChanged}
            >
              {!checkAllowedRole({ userRole: user.role, allowedRoles: [40, 42] }) &&
                !hasActiveSubscriptions(subscriptions) && (
                  <>
                    <Segment>
                      <div>
                        <h3>{t('common:userInfo.updateProfile.shareTM')}</h3>
                        <Description>{t('common:userInfo.updateProfile.shareTMDecription')}</Description>
                      </div>
                      <InputContainer>
                        <ShareToggle
                          shareTm={shareTm}
                          isDiy={hasActiveSubscriptions(subscriptions)}
                          updateShare={handleShareChange}
                        ></ShareToggle>
                      </InputContainer>
                    </Segment>
                    <Divider />
                  </>
                )}
            </BasicInformation>
          </Tab.Pane>
        ),
      },
      ...(!checkAllowedRole({ allowedRoles: [30], userRole: user.role })
        ? [
            {
              title: t('common:userInfo.tabs.billingDetails'),
              render: () => (
                <Tab.Pane>
                  <UserBillingDetails user={user} onChange={handleInfoSubmit} />
                </Tab.Pane>
              ),
            },
          ]
        : []),
    ];
  }, [shareChanged, shareTm, subscriptions, t, user, user_id]);

  return (
    <MainContainer>
      <h1>
        {t('common:admin.user.user')}: {user_id}
      </h1>
      {isLoading ? (
        <LoadingPane />
      ) : error ? (
        <Message text={t('common:userInfo.loadError')} type="error" />
      ) : (
        <>
          <Tab panes={panes} />
        </>
      )}
    </MainContainer>
  );
};

export default withRouter(withTranslation('common')(AdminUser));
