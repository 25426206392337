import styled from 'styled-components';
import { MultipleDropdown } from '../../../common/Dropdown/MultipleDropdown';

export const InputContainer = styled.div`
  margin: 0.25em 0 0.25rem 0;
`;

export const WarningSpan = styled.span`
  color: ${({ theme }) => theme.colors.red};
  padding: 0.5rem 0 0 0;
`;

export const StyledDropdownItem = styled(MultipleDropdown.Item)`
  &.highlighted div {
    opacity: 1;
  }
`;

export const AddLanguage = styled.div`
  margin-left: auto;
  font-size: 14px;
  display: flex;
  color: ${({ theme }) => theme.colors.blue};
  opacity: 0;
  transition: opacity 0.2s ease;
  will-change: opacity;

  .icon {
    margin-right: 0 !important;
  }
`;
