import styled from 'styled-components';
import Table from '../../common/Table/Table';

export const Cell = styled(Table.HeaderCell)`
  cursor: pointer;
  padding-right: ${(props) => (props.hasIcon ? '8px' : '28.52px')} !important;
  .icon {
    margin: 0 !important;
    margin-left: 4px !important;
  }
`;
