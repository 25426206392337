import paddings from 'style/variables/_paddings.scss';

export const PADDINGS = {
  mainContainer: {
    full: paddings.mainContainerFull,
    x: paddings.mainContainerX,
    y: paddings.mainContainerY,
    mobile: {
      x: paddings.mainContainerMobileX,
      y: paddings.mainContainerMobileY,
      full: paddings.mainContainerMobileFull,
    },
  },
};
