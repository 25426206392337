import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { AnimatePresence, motion } from 'framer-motion';
import { toast } from 'react-toastify';

import { fadeInOut } from 'constants/animation';

// Services
import { getSingleJob } from 'services/translators';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Utils
import {
  finishJob,
  declineJob,
  checkIsRevision,
  checkIsProofread,
  acceptJob,
  checkShowAcceptDeny,
} from 'utils/vendors.utils';
import { formatAPIDateString } from 'utils/date.utils';

// Components
import LinkBack from 'components/common/LinkBack/LinkBack';
import StatusLabel from 'components/common/StatusLabel/StatusLabel';
import ProjectLanguages from 'components/common/ProjectLanguages/ProjectLanguages';
import PercentageGraph from 'components/common/PercentageGraph/PercentageGraph';
import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import JobProgress from './JobProgress/JobProgress';
import { Modal } from 'semantic-ui-react';

// Styles
import './style.scss';
import { MainContainer } from 'components/common.styles';
import { getVendorsJobPricing } from 'utils/vendors.utils';
import { VENDOR_TASK_TYPE_REVISION } from 'constants/vendors';
import { VENDOR_TASK_TYPE_TRANSLATION } from 'constants/vendors';
import Price from 'components/common/Price/Price';

// Constants
const fadeInProps = {
  variants: { ...fadeInOut.base },
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
};

/**
 * Render single job screen
 */
const Job = () => {
  // State
  const [loading, setLoading] = useState(true);
  const [finishedModalOpen, setFinishedModalOpen] = useState(false);
  const [updating, setUpdating] = useState({
    acceptDeny: false,
    status: false,
    finished: false,
    downloadingFiles: false,
  });
  const [jobData, setJobData] = useState({});

  // Redux
  const { user } = useSelector((state) => state.userStore);

  // Hooks
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  // Variables
  const {
    revision_status_id,
    status_id,
    revision_end,
    translation_end,
    delivery_time,
    id: jobID,
    catData,
    job_url,
    translator_payable_words,
    job_id: catJobID,
    job_pass: catJobPass,
    cat_setting,
    total_words,
  } = jobData;

  const isRevision = checkIsRevision({ user, job: jobData });
  const isCatapult = !!catData;
  const statusID = isRevision ? revision_status_id : status_id;

  const isProofread = checkIsProofread(jobData);
  const showAcceptDeny = checkShowAcceptDeny({ job: jobData, isRevision });

  const canFinish = () => {
    if (!isCatapult) {
      // If not catapult, only check if status is 5 ~ accepted
      return statusID === 5;
    }
    // Disable CAT progress check for now because FU Matecat
    return statusID !== 6;
    // if (isRevision) {
    //   // Check
    //   return statusID !== 6 && catData?.cat_approved_words_percentage > 80;
    // } else {
    //   return statusID !== 6 && catData?.cat_translated_words_percentage > 80;
    // }
  };

  const canDlOrgFiles = cat_setting?.link && catJobID && catJobPass;

  // Functions
  /**
   * Handles modal logic
   * If confirm button is clicked isConfirm is true then update project status
   *
   * @param {boolean} isConfirm
   */
  const handleFinishedModal = (isConfirm = false) => {
    if (isConfirm) {
      handleFinishJob();
    }

    setFinishedModalOpen(false);
  };

  const handleJobAccept = async () => {
    setUpdating((c) => ({ ...c, acceptDeny: true, status: true }));
    try {
      await acceptJob({
        jobID,
        isRevision,
        callback: setJobData,
      });
    } catch (error) {
      console.error(error);
      toast.error(t('common:toasts.serviceProvider.errors.jobAccept'));
    } finally {
      setUpdating((c) => ({ ...c, acceptDeny: false, status: false }));
    }
  };

  const handleJobDecline = async () => {
    setUpdating((c) => ({ ...c, acceptDeny: true, status: true }));
    try {
      await declineJob({
        jobID,
        isRevision,
        // Redirect to all jobs
        callback: () => history.push('/jobs'),
      });
    } catch (error) {
      console.error(error);
      toast.error(t('common:toasts.serviceProvider.errors.jobDecline'));
    } finally {
      setUpdating((c) => ({ ...c, acceptDeny: false, status: false }));
    }
  };

  const handleDownloadClick = async () => {
    setUpdating((c) => ({ ...c, downloadingFiles: true }));
    try {
      const orgFilesDlLink = `${jobData.cat_setting.link}/?action=downloadOriginal&id_job=${catJobID}&password=${catJobPass}&download_type=all`;

      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = orgFilesDlLink;
      anchor.download = true;

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setTimeout(() => {
        setUpdating((c) => ({ ...c, downloadingFiles: false }));
      }, 1000);
    }
  };

  const handleFinishJob = async () => {
    setUpdating((c) => ({ ...c, finished: true }));
    try {
      await finishJob({
        jobID,
        isRevision,
        callback: setJobData,
        user,
      });
    } catch (error) {
      console.error(error);
      toast.error(t('common:toasts.serviceProvider.errors.jobFinish'));
    } finally {
      setUpdating((c) => ({ ...c, finished: false }));
    }
  };

  // Mounted effect
  useEffect(() => {
    const handleError = (error) => {
      toast.error(t('common:toasts.serviceProvider.errors.jobLoad'));
      console.error(error);
    };

    const fetchJobData = async () => {
      try {
        const data = await getSingleJob(id);

        // Check if user matches translator id
        if (data.translator_id !== user.vendor.id && data.revisioner !== user.vendor.id) {
          // Return to all jobs screen
          history.push('/jobs');
        }

        setJobData(data);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchJob = async () => {
      try {
        const data = await getSingleJob(id);
        setJobData(data);
      } catch (error) {
        handleError(error);
      }
    };

    fetchJobData();

    window.addEventListener('focus', fetchJob);

    return () => window.removeEventListener('focus', fetchJob);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Variables and functions used for rendering
  const title = () => {
    let type = 'translation';

    if (isRevision) type = 'revision';
    if (isProofread) type = 'proofread';

    return `${t(`common:serviceProvider.jobs.${type}`)} ${jobData?.project_id}`;
  };

  const dueDate = `${
    isRevision
      ? formatAPIDateString({ dateString: revision_end })
      : formatAPIDateString({ dateString: !!translation_end ? translation_end : delivery_time })
  }`;

  const catUrl = () => {
    if (isProofread || (!isProofread && !isRevision)) return job_url;

    return catData.cat_revise_urls[0].url;
  };

  const catButtonText = () => {
    if (isProofread || (!isProofread && !isRevision)) return t('common:serviceProvider.jobs.openTranslation');

    return t('common:serviceProvider.jobs.openRevision');
  };

  const jobLanguages = () => {
    const tempJobData = { ...jobData };

    if (isProofread) {
      // Remove target language
      delete tempJobData.target_language;
    }

    return tempJobData;
  };

  const jobPayment = useMemo(() => {
    if (jobData?.source_language_id) {
      const jobPricing = getVendorsJobPricing(
        jobData,
        isRevision ? VENDOR_TASK_TYPE_REVISION : VENDOR_TASK_TYPE_TRANSLATION,
        user,
      );
      const wordCount = isRevision ? total_words : translator_payable_words;

      const rate = jobPricing.rate ? jobPricing.rate : 0;
      return {
        payment: rate * wordCount,
        rate: jobPricing.rate,
        words: wordCount,
      };
    } else {
      return 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData, user]);

  return (
    <MainContainer className="single-job">
      <div className="single-job-content">
        <LinkBack text="Return to all jobs" />
        {loading ? (
          <LoadingPane height="80vh" />
        ) : (
          <>
            <header>
              <h1>{title()}</h1>
              <StatusLabel
                project={{ status_id: statusID }}
                loading={updating.status || updating.finished}
                isJobType
                isRevision={isRevision}
              />
            </header>

            <section className="single-job-info">
              <div className="left">
                <div className="single-job-label">
                  <strong>{t('common:serviceProvider.jobs.dueDate')}</strong>
                  <div className="grey-label">
                    <span>{dueDate}</span>
                  </div>
                </div>

                <div className="single-job-label">
                  <strong>{t('common:serviceProvider.combinations.languageCombinations')}</strong>
                  <ProjectLanguages project={jobLanguages()} />
                </div>
              </div>

              <div className="right">
                <div className="single-job-label"></div>
                <PercentageGraph
                  title="Words overview"
                  values={[
                    {
                      text: 'Total words',
                      value: total_words,
                    },
                    {
                      text: 'Payable words',
                      value: isRevision ? total_words : translator_payable_words,
                    },
                  ]}
                />
                <div className="single-job-label">
                  <strong>{t('common:serviceProvider.jobs.payment', 'Payment')}</strong>
                  <div className="grey-label">
                    <span>
                      {jobPayment.words} * <Price round={false} price={jobPayment.rate} /> ={' '}
                      <Price price={jobPayment.payment} />
                    </span>
                  </div>
                </div>
              </div>
            </section>

            {isCatapult && !showAcceptDeny && <JobProgress job={jobData} />}

            <section className="single-job-quick-actions">
              <AnimatePresence exitBeforeEnter>
                {showAcceptDeny ? (
                  <motion.div key="not-accepted-buttons" {...fadeInProps}>
                    <Button
                      loading={updating.acceptDeny}
                      disabled={updating.acceptDeny}
                      className="accept"
                      onClick={handleJobAccept}
                      actiontype="primary"
                    >
                      {t('common:serviceProvider.jobs.accept')}
                    </Button>

                    <Button
                      loading={updating.acceptDeny}
                      disabled={updating.acceptDeny}
                      className="reject"
                      actiontype="secondary"
                      onClick={handleJobDecline}
                    >
                      {t('common:serviceProvider.jobs.decline')}
                    </Button>
                  </motion.div>
                ) : (
                  <motion.div key="accepted-buttons" {...fadeInProps}>
                    {isCatapult && (
                      <a href={catUrl()} rel="noopener noreferrer" target="_blank">
                        <Button actiontype="primary" labelPosition="right">
                          {catButtonText()}
                          <Icon name="external-link-alt" />
                        </Button>
                      </a>
                    )}

                    {canFinish() && (
                      <Modal
                        closeIcon
                        size="mini"
                        open={finishedModalOpen}
                        onClose={() => handleFinishedModal()}
                        trigger={
                          <Button
                            loading={updating.finished}
                            disabled={updating.finished}
                            onClick={() => setFinishedModalOpen(true)}
                            labelPosition="right"
                            actiontype="success"
                          >
                            {t('common:serviceProvider.jobs.markJobAsFinished')}
                            <Icon name="check" />
                          </Button>
                        }
                      >
                        <Modal.Content>
                          <p>{t('common:serviceProvider.jobs.finishConfirmation')}</p>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button actiontype="cancel" onClick={() => handleFinishedModal()}>
                            {t('common:cancel')}
                          </Button>
                          <Button actiontype="primary" onClick={() => handleFinishedModal(true)}>
                            {t('common:confirm')}
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    )}

                    {canDlOrgFiles && (
                      <Button
                        loading={updating.downloadingFiles}
                        disabled={updating.downloadingFiles}
                        onClick={handleDownloadClick}
                        labelPosition="right"
                      >
                        {t('common:projects.projectInfo.originalFiles')}
                        <Icon name="download-alt" />
                      </Button>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </section>
          </>
        )}
      </div>
    </MainContainer>
  );
};

export default Job;
