import React from 'react';
import { classnames } from 'utils/base.utils';

import './style.scss';

const SectionDescription = ({ title, description, content, disabled = false }) => {
  const classNames = classnames('section-description', { disabled });

  return (
    <div className={classNames}>
      <div className="left">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="right">{content}</div>
    </div>
  );
};

export default SectionDescription;
