import styled from 'styled-components';

export const CardsContainer = styled.section`
  display: flex;
  flex-direction: column;
  .package-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto 40px auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif !important;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ControlsContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  .ui.search.selection.dropdown {
    margin-left: 2em;
    min-width: 15ch;
    width: 15ch;
  }
`;

export const Heading = styled.h1`
  font-size: 42px;
  font-weight: 400;
  margin: 0 0 20px 0;
`;

export const SubHeading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 62px 0;
`;

export const EnterpriseLink = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  justify-content: center;
  line-height: 20px;
  margin-bottom: 64px;
  width: 100%;
  a {
    color: #1ca0ba;
    cursor: pointer;
    text-decoration: none;
  }
`;
