import { zindexPopperModifier } from 'constants/general';
import { VENDOR_TASK_TYPE_REVISION } from 'constants/vendors';
import { VENDOR_TASK_TYPE_PROOFREAD_ONLY } from 'constants/vendors';
import { VENDOR_TASK_TYPE_TRANSLATION } from 'constants/vendors';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import Price from '../Price/Price';
import { RateInputContainer } from './PriceInput.style';

const RateInput = ({
  initialAmount = 0,
  onPriceChange = () => {},
  name = '',
  taskType = VENDOR_TASK_TYPE_TRANSLATION,
  ...rest
}) => {
  const currencies = useSelector((state) => state.classifiersStore.currencies);
  const [amount, setAmount] = useState(initialAmount);

  const { t } = useTranslation();

  const handleAmountChange = (e) => {
    // Helper functions
    const limitDecimals = () => {
      const tValue = e.target.value;
      const dotIndex = tValue.indexOf('.');
      const commaIndex = tValue.indexOf(',');

      if (dotIndex > -1 || commaIndex > -1) {
        const splitIndex = dotIndex > -1 ? dotIndex : commaIndex;

        return tValue.substr(0, splitIndex) + tValue.substr(splitIndex, 4);
      }

      return tValue;
    };

    // preprocess the value that was entered
    // if more than 3 decimals, ignore the extra decimal
    const value = limitDecimals();

    onPriceChange(e, { value, name });
    setAmount(value);
  };

  const rateRange =
    taskType === VENDOR_TASK_TYPE_TRANSLATION
      ? [0.035, 0.06]
      : taskType === VENDOR_TASK_TYPE_REVISION
      ? [0.02, 0.025]
      : taskType === VENDOR_TASK_TYPE_PROOFREAD_ONLY
      ? [0.01, 0.015]
      : [0.0, 0.0];

  return (
    <Popup
      disabled
      content={
        <span>
          {t('common:vendor.rateInput.reccomended', 'Recommended rates for this service range between')}{' '}
          <Price price={rateRange[0]} round={false} /> and <Price price={rateRange[1]} round={false} />
        </span>
      }
      trigger={
        <RateInputContainer>
          <div className="currency-symbol">{currencies[0].symbol}</div>
          <div className="price-amount">
            <input
              type="number"
              name={name}
              placeholder="0.00"
              min="0"
              value={amount}
              onChange={handleAmountChange}
              {...rest}
            ></input>
          </div>
          <div className="currency-select">Per word</div>
        </RateInputContainer>
      }
      popperModifiers={[zindexPopperModifier]}
    />
  );
};

export default RateInput;
