import React from 'react';
import { withTranslation } from 'react-i18next';
import { Label, Table } from 'semantic-ui-react';
import { Icon } from '../../../common/Icon/Icon';
import PricePerWord from '../../OrderProject/Jobs/Job/PricePerWord';
import TargetLanguages from '../../../common/ProjectLanguages/TargetLanguages';
import { StyledHeader, StyledProgress, StyledTableHeaderCell } from './ProjectServices.style';

const ProjectServices = ({ t, jobs, currency }) => {
  const jobRows = jobs.map((job) => {
    return (
      <Table.Row key={job.id}>
        <Table.Cell>
          <TargetLanguages languages={job.target_language} />
        </Table.Cell>
        <Table.Cell>{job.payable_words}</Table.Cell>
        <Table.Cell>
          <PricePerWord currencyData={currency} job={job} />
        </Table.Cell>
        <Table.Cell>
          {job.source_language !== job.target_language ? '-' : <Icon name="check" color="green" />}
        </Table.Cell>
        <Table.Cell>{job.service_1_selected === 0 ? '-' : <Icon name="check" color="green" />}</Table.Cell>
        <Table.Cell>{job.service_2_selected === 0 ? '-' : <Icon name="check" color="green" />}</Table.Cell>
        <Table.Cell>{job.service_3_selected === 0 ? '-' : <Icon name="check" color="green" />}</Table.Cell>
        <Table.Cell>{job.service_4_selected === 0 ? '-' : <Icon name="check" color="green" />}</Table.Cell>
        <Table.Cell>{job.service_5_selected === 0 ? '-' : <Icon name="check" color="green" />}</Table.Cell>
        {jobs[0].dtp_price !== 0 && (
          <Table.Cell>{job.dtp_selected ? <Icon name="check" color="green" /> : '-'}</Table.Cell>
        )}

        <Table.Cell>
          <StyledProgress color="green" percent={job.progress} size="tiny" />
        </Table.Cell>
      </Table.Row>
    );
  });
  return (
    <Table textAlign="center" stackable basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <StyledTableHeaderCell className="border-right border-left" colSpan="2">
            <StyledHeader color="green">{t('common:projects.projectJobs.economic')}</StyledHeader>
          </StyledTableHeaderCell>
          <StyledTableHeaderCell className="border-right" colSpan="3">
            <StyledHeader color="blue">{t('common:projects.projectJobs.professional')}</StyledHeader>
          </StyledTableHeaderCell>
          {jobs[0].dtp_price !== 0 && (
            <StyledTableHeaderCell className="border-right">
              <StyledHeader color="red">{t('common:projects.projectJobs.DTP')}</StyledHeader>
            </StyledTableHeaderCell>
          )}
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>{t('common:projects.projectJobs.targetLanguage')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:projects.projectJobs.payableWords')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:projects.projectJobs.pricePerWord')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:projects.projectJobs.proofread')}</Table.HeaderCell>
          <StyledTableHeaderCell className="border-left">
            <Label color="green" pointing="below" style={{ width: '100%' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('common:projects.projectJobs.machineTranslation.title', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
            </Label>
          </StyledTableHeaderCell>
          <StyledTableHeaderCell className="border-right">
            <Label color="green" pointing="below" style={{ width: '100%' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('common:projects.projectJobs.machineWithReview.title', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
            </Label>
          </StyledTableHeaderCell>
          <Table.HeaderCell>
            <Label color="blue" pointing="below" style={{ width: '100%' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('common:projects.projectJobs.machineWithTranslator.title', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
            </Label>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Label color="blue" pointing="below" style={{ width: '100%' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('common:projects.projectJobs.machineWithTranslators.title', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
            </Label>
          </Table.HeaderCell>
          <StyledTableHeaderCell className="border-right">
            <Label color="blue" pointing="below" style={{ width: '100%' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('common:projects.projectJobs.threeTranslators.title', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></span>
            </Label>
          </StyledTableHeaderCell>
          {jobs[0].dtp_price !== 0 && (
            <StyledTableHeaderCell className="border-right">
              <Label pointing="below" style={{ width: '100%' }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('common:projects.projectJobs.graphicEditing.title', {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></span>
              </Label>
            </StyledTableHeaderCell>
          )}
          <Table.HeaderCell>{t('common:projects.projectJobs.progress')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{jobRows}</Table.Body>
    </Table>
  );
};

export default withTranslation('common')(ProjectServices);
