import httpClient from './httpclient';

export const getVendors = async ({
  page = 1,
  per_page = 14,
  search,
  risk,
  experience,
  status,
  source_lang_id,
  target_lang_id,
  task_type_id,
  sort_by,
  sort_order,
}) => {
  try {
    const { data } = await httpClient.get(`/auth/vendors`, {
      params: {
        page: page,
        per_page: per_page,
        search,
        risk,
        experience,
        status,
        source_lang_id,
        target_lang_id,
        task_type_id,
        sort_by,
        sort_order,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getVendorData = async (userID) => {
  try {
    const response = await httpClient.get(`/auth/vendors/${userID}`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteVendorData = async (id) => {
  try {
    const { data } = await httpClient.delete(`/auth/vendors/${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const postVendor = async ({
  userID,
  email,
  name,
  avatar,
  phone,
  native_lang_id,
  description,
  timezone,
  country_id,
  combination,
  linkedin,
  education,
  experience,
  cv,
  additionalFiles,
}) => {
  try {
    const formData = new FormData();

    const combinations = combination
      .map((combi) => {
        // For each category create new entry
        return combi.category_id.map((catID) => ({
          ...combi,
          category_id: catID,
        }));
      })
      .flat();

    if (userID) formData.append('user_id', userID);
    if (email) formData.append('email', email);
    if (name) formData.append('name', name);
    if (avatar) formData.append('avatar', avatar);
    if (phone) formData.append('phone', phone);
    if (native_lang_id) formData.append('native_lang_id', native_lang_id);
    if (description) formData.append('description', description);
    if (timezone) formData.append('timezone', timezone);
    if (country_id) formData.append('country_id', country_id);
    if (combination) combinations.forEach((comb) => formData.append('combination[]', JSON.stringify(comb)));
    if (linkedin) formData.append('linkedin', linkedin);
    if (education) formData.append('education', education);
    if (experience) formData.append('experience', experience);
    if (cv) formData.append('cv', cv);
    if (additionalFiles) additionalFiles.forEach((file) => formData.append('attachment[]', file));

    const { data } = await httpClient.post(`/auth/vendors`, formData);

    return data;
  } catch (error) {
    throw error;
  }
};

export const postVendorData = async ({ vendorProfileImage, userCV, userAdditionalFiles, data, userID }) => {
  try {
    const formData = new FormData();

    // Format data for BE
    const vendorData = {
      ...data,
      combination: data.combination
        .map((combi) => {
          // For each category create new entry
          return combi.category_id.map((catID) => ({
            ...combi,
            category_id: catID,
          }));
        })
        .flat(),
    };

    // Append files
    if (userCV) formData.append('cv', userCV);

    if (userAdditionalFiles.length > 0) {
      userAdditionalFiles.forEach((file) => formData.append('attachment[]', file));
    }

    if (vendorProfileImage instanceof File) {
      formData.append('avatar', vendorProfileImage);
    }

    if (userID) {
      formData.append('user_id', userID);
    }

    // Append language combinations
    vendorData.combination.forEach((combo) => {
      formData.append('combination[]', JSON.stringify(combo));
    });

    // Loop over LS data
    for (const fieldName in vendorData) {
      const lngPairs = fieldName === 'combination';
      const fieldValue = vendorData[fieldName];

      // Skip language combinations
      if (lngPairs) continue;

      formData.append(fieldName, fieldValue);
    }

    const response = await httpClient.post(`/auth/vendors`, formData);

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteVendorAttachment = async (id) => {
  try {
    const response = await httpClient.delete(`/auth/vendor-files/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const createVendorAttachment = async (file, vendorID) => {
  try {
    const formData = new FormData();
    formData.append('attachment', file);

    const response = await httpClient.post(`/auth/vendors/${vendorID}/files`, formData);

    return response;
  } catch (error) {
    throw error;
  }
};

export const changeVendorCVAttachment = async (file, id) => {
  try {
    const formData = new FormData();
    formData.append('cv', file);

    // This is here because
    // https://laracasts.com/discuss/channels/general-discussion/how-can-i-send-a-file-over-a-putpatch-request
    formData.append('_method', 'PATCH');

    const response = await httpClient.post(`/auth/vendors/${id}`, formData);

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateVendorData = async ({ data, id }) => {
  try {
    const updatedFields = {};
    const changedFiles = data.files;

    for (const name in data) {
      if (Object.hasOwnProperty.call(data, name)) {
        const value = data[name];

        // Skip files, they are handled separately
        if (name === 'files' || name === 'cv') continue;

        // Add to updatedFields object
        updatedFields[name] = value;
      }
    }

    // If cv was changed
    if (data.cv) {
      await changeVendorCVAttachment(data.cv, id);
    }

    // Handle additional files
    if (changedFiles?.add.length > 0 || changedFiles?.remove.length > 0) {
      const handleChangedPlans = [];

      // Handle delete
      if (changedFiles.remove.length > 0) {
        for (const file of changedFiles.remove) {
          handleChangedPlans.push(deleteVendorAttachment(file.id));
        }
      }

      // Handle add file
      if (changedFiles.add.length > 0) {
        for (const file of changedFiles.add) {
          handleChangedPlans.push(createVendorAttachment(file, id));
        }
      }

      await Promise.all(handleChangedPlans);
    }

    // Check if any field changes
    if (Object.keys(updatedFields).length) {
      return await httpClient.patch(`/auth/vendors/${id}`, updatedFields);
    }
  } catch (error) {
    throw error;
  }
};

export const downloadVendorAdditionalFiles = async (fileID) => {
  try {
    const response = await httpClient.get(`/auth/vendor-files/${fileID}`, { responseType: 'blob' });

    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadVendorCV = async (vendorID) => {
  try {
    const response = await httpClient.get(`/auth/vendors/${vendorID}/cv`, { responseType: 'blob' });

    return response;
  } catch (error) {
    throw error;
  }
};

export const postLanguagePair = async ({
  vendor_id,
  source_lang_id,
  target_lang_id,
  category_id,
  task_type_id,
  rate,
  test_status,
  rate_status,
}) => {
  try {
    if (Array.isArray(category_id)) {
      // Build combinations
      const combinations = category_id.map((cate_id) => {
        return {
          vendor_id,
          source_lang_id,
          target_lang_id,
          category_id: cate_id,
          task_type_id,
          rate,
          test_status,
          rate_status,
        };
      });

      await batchPostLanguagePairs(combinations);
    } else {
      await httpClient.post(`/auth/vendor-prices`, {
        ...(vendor_id ? { vendor_id } : {}),
        ...(target_lang_id ? { target_lang_id } : {}),
        source_lang_id,
        category_id,
        task_type_id,
        rate,
        test_status,
        rate_status,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const batchPostLanguagePairs = async (pairs) => {
  const postPairs = pairs.map(async (pair) => await postLanguagePair(pair));

  await Promise.all(postPairs);
};

export const deleteLanguagePair = async (id) => {
  try {
    const { data } = await httpClient.delete(`/auth/vendor-prices/${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const batchDeleteLanguagePair = async (ids) => {
  try {
    const updatePairs = ids.map(async (id) => await deleteLanguagePair(id));

    await Promise.all(updatePairs);
  } catch (error) {
    throw error;
  }
};

export const batchPatchLanguagePairs = async ({ ids, ...rest }) => {
  try {
    const updatePairs = ids.map(async (id) => await patchLanguagePair({ id, ...rest }));

    await Promise.all(updatePairs);
  } catch (error) {
    throw error;
  }
};

export const patchLanguagePair = async ({ id, ...rest }) => {
  try {
    const { data } = await httpClient.patch(`/auth/vendor-prices/${id}`, { ...rest });

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Admin actions for vendors
 */

export const adminConfirmVendorRate = async (id) => {
  try {
    const { data } = await httpClient.post(`/auth/vendor-prices/${id}/confirm`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const adminBatchConfirmRate = async (pairsIDs) => {
  try {
    const updatePairs = pairsIDs.map(async (id) => await adminConfirmVendorRate(id));

    await Promise.all(updatePairs);
  } catch (error) {
    throw error;
  }
};

export const adminDeclineVendorRate = async (id) => {
  try {
    const { data } = await httpClient.post(`/auth/vendor-prices/${id}/decline`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const adminBatchDeclineRate = async (pairsIDs) => {
  try {
    const updatePairs = pairsIDs.map(async (id) => await adminDeclineVendorRate(id));

    await Promise.all(updatePairs);
  } catch (error) {
    throw error;
  }
};

export const adminDeleteVendorRate = async (id) => {
  try {
    const { data } = await httpClient.delete(`/auth/vendor-prices/${id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const adminBatchDeleteRate = async (pairsIDs) => {
  try {
    const updatePairs = pairsIDs.map(async (id) => await adminDeleteVendorRate(id));

    await Promise.all(updatePairs);
  } catch (error) {
    throw error;
  }
};
