import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Table from '../../../common/Table/Table';
import { TEAM_TRANSLATIONS } from '../../../../constants/roles';
import Button from '../../../common/Button/Button';
import { Icon } from '../../../common/Icon/Icon';
import { StyledLabel } from '../../Team.style';
import { Modal } from 'semantic-ui-react';

import { formatAPIDateString } from 'utils/date.utils';

const TeamInviteRow = ({ t, invite, deleteInvite }) => {
  const [revoking, setRevoking] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const revokeInvite = async () => {
    setRevoking(true);
    setShowModal(false);
    await deleteInvite(invite.id);
  };

  return (
    <Table.Row>
      <Table.Cell>{invite.email}</Table.Cell>
      <Table.Cell>
        <StyledLabel>{t(TEAM_TRANSLATIONS[invite.role_id])}</StyledLabel>
      </Table.Cell>
      <Table.Cell>{formatAPIDateString({ dateString: invite.created_at, showTime: false })}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          labelPosition="left"
          onClick={() => setShowModal(true)}
          loading={revoking}
          basic
          color="light-grey"
        >
          <Icon name="trash-alt" />
          {t('common:team.invites.revoke')}
        </Button>
        <Modal size="small" open={showModal} onClose={() => setShowModal(false)}>
          <Modal.Header> {`${t('common:team.invites.revokeModalHeader')} ${invite.email}`}</Modal.Header>
          <Modal.Content>{t('common:team.invites.revokeIsFinal')}</Modal.Content>
          <Modal.Actions>
            <Button actiontype="secondary" onClick={() => setShowModal(false)}>
              {t('common:cancel')}
            </Button>
            <Button actiontype="primary" onClick={revokeInvite}>
              {t('common:confirm')}
            </Button>
          </Modal.Actions>
        </Modal>
      </Table.Cell>
    </Table.Row>
  );
};

export default withTranslation('common')(TeamInviteRow);
