import React, { useContext } from 'react';

// Context
import { SelectGroupContext } from './SelectGroup';

// Prop types
import PropTypes from 'prop-types';
import { nullable } from 'utils/propTypes.utils';

/**
 * Render select group option
 */
const SelectGroupOption = ({ value, children, className, onClick, ...rest }) => {
  // State
  const { handleClick, selected, fluid, size } = useContext(SelectGroupContext);

  // Functions
  const getOptionClass = () => {
    const classes = ['select-group__option', className, size];

    if (value === selected) classes.push('selected');
    if (fluid) classes.push('fluid');

    return classes.join(' ');
  };

  const handleOnClick = () => {
    handleClick(value);

    if (onClick) onClick();
  };

  return (
    <button className={getOptionClass()} onClick={handleOnClick} {...rest}>
      {children}
    </button>
  );
};

// Prop Types for component
SelectGroupOption.propTypes = {
  // Required
  value: nullable(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])).isRequired,

  // Optional
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default SelectGroupOption;
