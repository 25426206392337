import React from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  AdminDashboardLayout,
  AdminDashboardItem,
  AdminItemIcon,
  AdminItemHeader,
} from './AdminDashboard.styles';

import { sendUserInteraction } from 'utils/tagManager.utils';

const AdminDashboard = ({ t }) => {
  const history = useHistory();

  const routeTo = (path) => {
    sendUserInteraction(`clicked admin dashboard ${path} item`);
    return (e) => history.push(path);
  };

  return (
    <AdminDashboardLayout>
      <AdminDashboardItem onClick={routeTo('/admin/users')}>
        <AdminItemIcon name="users-alt" />
        <Link to="/admin/users">
          <AdminItemHeader>{t('common:admin.dashboard.allUsers')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.allUsersDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/companies')}>
        <AdminItemIcon name="building" />
        <Link to="/admin/companies">
          <AdminItemHeader>{t('common:admin.dashboard.companies')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.companiesDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/projects')}>
        <AdminItemIcon name="suitcase" />
        <Link to="/admin/projects">
          <AdminItemHeader>{t('common:admin.dashboard.projects')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.projectsDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/project/create')}>
        <AdminItemIcon name="suitcase" />
        <Link to="/admin/project/create">
          <AdminItemHeader>{t('common:admin.dashboard.createProject')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.createProjectDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/invoices')}>
        <AdminItemIcon name="invoice" />
        <Link to="/admin/invoices">
          <AdminItemHeader>{t('common:admin.dashboard.invoices')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.invoicesDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/coupons')}>
        <AdminItemIcon name="pricetag-alt" />
        <Link to="/admin/coupons">
          <AdminItemHeader>{t('common:admin.dashboard.coupons')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.couponsDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/vendors')}>
        <AdminItemIcon name="english-to-chinese" />
        <Link to="/admin/vendors">
          <AdminItemHeader>{t('common:admin.dashboard.translators')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.translatorsDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/files')}>
        <AdminItemIcon name="hdd" />
        <Link to="/admin/files">
          <AdminItemHeader>{t('common:admin.dashboard.multimedia')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.multimediaDescription')}</p>
      </AdminDashboardItem>
      <AdminDashboardItem onClick={routeTo('/admin/packages')}>
        <AdminItemIcon name="credit-card" />
        <Link to="/admin/packages">
          <AdminItemHeader>{t('common:admin.dashboard.subscriptions')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.subscriptionsDescription')}</p>
      </AdminDashboardItem>
      <AdminDashboardItem onClick={routeTo('/admin/catapult/users')}>
        <AdminItemIcon name="credit-card" />
        <Link to="/admin/catapult/users">
          <AdminItemHeader>{t('common:catapult.admin.usersOverview')}</AdminItemHeader>
        </Link>
        <p>{t('common:catapult.admin.usersOverviewDescription')}</p>
      </AdminDashboardItem>

      <AdminDashboardItem onClick={routeTo('/admin/calendar')}>
        <AdminItemIcon name="schedule" />
        <Link to="/admin/calendar">
          <AdminItemHeader>{t('common:admin.dashboard.calendar')}</AdminItemHeader>
        </Link>
        <p>{t('common:admin.dashboard.calendarDescription')}</p>
      </AdminDashboardItem>
    </AdminDashboardLayout>
  );
};

export default withTranslation('common')(AdminDashboard);
