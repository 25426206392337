import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 8px;
`;

export const JobName = styled.div`
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const JobPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 700;
  padding-right: 33px;
`;
