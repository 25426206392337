import React, { useMemo, useState } from 'react';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import AdminSidebar from './AdminSidebar';
import TransferModal from 'components/Projects/ProjectsCards/CardControls/TransferModal';
import DeleteProjectModal from '../DeleteProjectModal/DeleteProjectModal';

import { getOriginalFiles, postUpdateStatus } from 'services/project';

import { sendUserInteraction } from 'utils/tagManager.utils';
import { useHistory } from 'react-router';
import { canDeleteProject } from 'utils/user.utils';

const OrderProjectAdminSidebar = ({ refreshProject, project, isAdminPage = false }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [archivingProject, setIsArchivingProject] = useState(false);
  const [deletingProject, setIsDeletingProject] = useState(false);
  const [downloadingOriginalFiles, seDownloadingOriginalFiles] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleArchive = async (e) => {
    e.preventDefault();
    sendUserInteraction('admin project draft sidebar mark archive project');
    try {
      setIsArchivingProject(true);
      await postUpdateStatus(project?.id, 20);
      toast.success(t('common:toasts.archiveProject.success'));
      refreshProject();
    } catch (e) {
      toast.error(t('common:toasts.archiveProject.error'));
      setIsArchivingProject(false);
      throw e;
    }
  };

  const handleDownloadClick = async (e) => {
    sendUserInteraction('admin project draft original files download click');
    e.preventDefault();
    seDownloadingOriginalFiles(true);

    try {
      // get file blob
      const response = await getOriginalFiles(project?.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      seDownloadingOriginalFiles(false);
    }
  };

  const handleTransferClick = (e) => {
    e.preventDefault();
    setShowTransferModal(true);
    sendUserInteraction('project draft screen admin opened transfer project modal');
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    sendUserInteraction('project draft screen admin opened delete project modal');
    setShowDeleteModal(true);
  };

  const buttons = [
    {
      iconName: 'message',
      isLoading: false,
      onClick: handleTransferClick,
      text: t('common:admin.project.sidebar.transfer'),
    },
    {
      iconName: 'archive',
      isLoading: archivingProject,
      onClick: handleArchive,
      text: t('common:admin.project.sidebar.archive'),
    },
    {
      iconName: 'folder-download',
      isLoading: downloadingOriginalFiles,
      onClick: handleDownloadClick,
      text: t('common:admin.project.sidebar.downloadFiles'),
    },
  ];

  if (canDeleteProject(project.status_id)) {
    buttons.push({
      iconName: 'trash-alt',
      isLoading: deletingProject,
      onClick: handleDeleteClick,
      text: t('common:delete'),
      className: 'body-text-red hover:bg-red-4',
    });
  }

  const links = useMemo(() => {
    return [
      ...(!!project?.user_id
        ? [
            {
              iconName: 'user',
              text: t('common:admin.project.sidebar.goToUser'),
              to: `/admin/user/${project?.user_id}`,
            },
          ]
        : []),
      ...(!!project?.team?.company_id
        ? [
            {
              iconName: 'building',
              text: t('common:admin.project.sidebar.goToCompay'),
              to: `/company/${project.team?.company_id}`,
            },
          ]
        : []),
      ...(isAdminPage
        ? [
            {
              iconName: 'briefcase',
              text: t('common:admin.project.sidebar.goToClient'),
              to: `/project/${project?.id}`,
            },
          ]
        : [
            {
              iconName: 'dashboard',
              text: t('common:admin.project.sidebar.goToAdmin'),
              to: `/admin/project/${project?.id}`,
            },
          ]),
      {
        iconName: 'shopping-cart',
        text: t('common:admin.project.sidebar.goToCheckout'),
        to: `/checkout?project=${project?.id}`,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, t]);

  return (
    <>
      <AdminSidebar buttons={buttons} links={links} />
      {showTransferModal ? (
        <TransferModal
          isAdmin={true}
          onClose={() => setShowTransferModal(false)}
          onSubmit={() => {
            setShowTransferModal(false);
            refreshProject();
          }}
          project={project}
        />
      ) : null}

      <DeleteProjectModal
        projectId={project.id}
        open={showDeleteModal}
        setLoading={(loading) => {
          if (loading) {
            setIsDeletingProject(true);
          } else {
            setIsDeletingProject(false);
            history.push('/');
          }
        }}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default OrderProjectAdminSidebar;
