import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Header, Icon, Label, List, Popup } from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react';
import { LoaderContainer } from './PriceDetails.styles';
import { getProjectPayment, getProjectPrices } from '../../../services/project';
import Price from '../../common/Price/Price';

const PriceDetails = ({ project, t }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [price, setPrice] = useState({});

  useEffect(() => {
    const fetchPricesData = async (project) => {
      setIsLoading(true);
      try {
        const pricesData = await getProjectPrices(project);
        setPrice(pricesData);
        const paymentsData = await getProjectPayment(project.id);
        setPayments(paymentsData);
      } catch (e) {
        throw e;
      } finally {
        setIsLoading(false);
      }
    };

    fetchPricesData(project);
  }, [project]);

  let netWithoutBalance = price.net_price;
  //Payment with balance is reducted from net price
  let balanceAmount = 0;
  for (let payment of payments) {
    if (payment.type === 'balance') {
      balanceAmount += payment.amount;
      netWithoutBalance -= payment.amount;
    }
  }

  //Whats left without balance + VAT
  const vatLeftToPay = price.vat_total;
  const grossLeftToPay = netWithoutBalance + vatLeftToPay;

  //What is paid by other payments
  const paymentsAmountWithoutBalance = payments.reduce((acc, payment) => {
    if (payment.type === 'balance') {
      return acc;
    }
    return acc + payment.amount;
  }, 0);

  const showGrossLeftToPay = () => {
    for (let payment of payments) {
      if (payment.type === 'balance') {
        if (price.net_price - payment.amount <= 0.01) {
          return false;
        }
      }
    }

    // if we do not have a gross_price do not display
    if (!price.gross_price) {
      return false;
    }
    // if we already paid the gross price do not display
    if (grossLeftToPay - paymentsAmountWithoutBalance <= 0.01) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Header>{t('common:admin.project.price.priceDetails')}</Header>
      {isLoading ? (
        <LoaderContainer>
          <Loader active inline size="medium">
            {t('common:loading')}
          </Loader>
        </LoaderContainer>
      ) : (
        <List>
          <List.Item>
            <Label color="blue" horizontal>
              {t('common:admin.project.price.basePrice')}:
            </Label>
            {price.full_price ? (
              <Price
                price={price.delivery_price ? price.full_price - price.delivery_price : price.full_price}
                currency={price.currency}
              ></Price>
            ) : (
              <span>{t('common:admin.project.price.projectInDraft')}</span>
            )}
          </List.Item>

          {!!price.delivery_price && (
            <>
              <List.Item>
                <Label color="blue" horizontal>
                  {t('common:admin.project.price.selectedDelivery')}
                </Label>
                <Price price={price.delivery_price} currency={price.currency} />
              </List.Item>
              <List.Item>
                <Label color="blue" horizontal>
                  {t('common:admin.project.price.fullPrice')}
                </Label>
                <Price price={price.full_price} currency={price.currency} />
              </List.Item>
            </>
          )}

          {!!price.reg_discount_value && (
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.project.price.aiDiscount')}:
              </Label>
              {price.full_price ? (
                <>
                  ~<Price price={price.reg_discount_value} currency={price.currency} /> |{' '}
                  {`${price.reg_disc_ratio}%`}
                </>
              ) : (
                <span>{t('common:admin.project.price.projectInDraft')}</span>
              )}
            </List.Item>
          )}

          {!!price.special_discount_value && (
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.project.price.specialDiscount')}:
              </Label>
              ~<Price price={price.special_discount_value} currency={price.currency} /> |{' '}
              {`${price.spec_disc_ratio}%`}
            </List.Item>
          )}

          {!!price.disc_coupon && (
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.project.price.couponDiscount')}:
              </Label>
              ~<Price price={price.coupon_discount_value} currency={price.currency} /> |{' '}
              {`${price.disc_coupon}%`}
            </List.Item>
          )}

          {price.net_price && (
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.project.price.priceWithDiscount')}
              </Label>
              <Price price={price.net_price} currency={price.currency}></Price>
            </List.Item>
          )}

          {balanceAmount > 0 && (
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.project.price.priceWithReductedBalance')}
              </Label>
              <Price price={netWithoutBalance} currency={price.currency}></Price>
            </List.Item>
          )}

          {price.vat_rate && (
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.project.price.VAT')}
              </Label>
              ~<Price currency={price.currency} price={vatLeftToPay} /> | {`${price.vat_rate}%`}
            </List.Item>
          )}

          <List.Item>
            <Label color="blue" horizontal>
              {t('common:admin.project.price.totalPrice')}
            </Label>
            <b>
              <Price price={grossLeftToPay + balanceAmount} currency={price.currency}></Price>{' '}
              {project.project_paid ? (
                <Popup
                  content={
                    project.payment_token
                      ? `Project paid with credit card: ${project.payment_token}`
                      : 'Project marked as paid'
                  }
                  trigger={<Icon link name="check" color="green" />}
                />
              ) : (
                <Popup content="Not paid yet" trigger={<Icon link name="times" color="red" />} />
              )}
            </b>
          </List.Item>

          {payments.map((payment) => {
            return (
              <List.Item key={payment.id}>
                <Label color="blue" horizontal>
                  {payment.type === 'balance'
                    ? t('common:admin.project.price.paidWithBalance')
                    : t('common:admin.project.price.paidWithCard')}
                  :
                </Label>
                <Price price={payment.amount} currency={price.currency}></Price>
              </List.Item>
            );
          })}

          {showGrossLeftToPay() && (
            <List.Item>
              <Label color="blue" horizontal>
                {t('common:admin.project.price.leftToPayGross')}
              </Label>
              <Price price={grossLeftToPay - paymentsAmountWithoutBalance} currency={price.currency}></Price>
            </List.Item>
          )}

          {!!project?.bill_name && (
            <List.Item>
              <Label color="green" horizontal>
                {t('common:admin.project.invoiceIssued')}
              </Label>
              <span>{project?.bill_name}</span>
            </List.Item>
          )}
        </List>
      )}
    </>
  );
};

export default withTranslation('common')(PriceDetails);
