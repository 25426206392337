import { useDebounce } from 'hoc/debouncer';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Loader } from 'semantic-ui-react';
import { getUserSuggestions } from 'services/users';
import { Icon } from '../Icon/Icon';
import { CustomerSuggestionsContainer } from './CustomerSuggestions.style';

const CustomerSuggestions = ({ customer = null, onCustomerChange = () => {} }) => {
  const [selected, setSelected] = useState(null);
  const [customerText, setCustomerText] = useState('');
  const [customerType, setCustomerType] = useState('company');
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const { t } = useTranslation();
  const { debounce } = useDebounce();

  useEffect(() => {
    if (selected) {
      if (customerType === 'user') {
        setCustomerText(`${selected.name} (${selected.email})`);
      } else if (customerType === 'company') {
        setCustomerText(`${selected.teamName} (${selected.companyName})`);
      } else if (customerType === 'team-member') {
        setCustomerText(`${selected.name} (${selected.companyName} - ${selected.teamName})`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const customerDropdownOptions = useMemo(
    () => [
      { key: 1, value: 'user', text: t('common:customerSuggest.individual') },
      { key: 2, value: 'company', text: t('common:customerSuggest.team') },
      { key: 3, value: 'team-member', text: t('common:customerSuggest.teamMember') },
    ],
    [t],
  );

  useEffect(() => {
    if (!customer) {
      if (customerText.trim() === '') {
        setCustomerOptions([]);
        setIsLoadingUsers(false);
        return;
      }
      setIsLoadingUsers(true);
      debounce(
        'transferAutocomplete',
        async () => {
          try {
            let suggestions = await getUserSuggestions({ searchQuery: customerText, userType: customerType });

            if (customerType === 'user') {
              suggestions = suggestions.map((user) => {
                return { key: user.id, text: `${user.name} (${user.email})`, value: user };
              });
            } else if (customerType === 'company') {
              const newSuggestions = [];
              for (let suggestion of suggestions) {
                for (let team of suggestion.teams) {
                  newSuggestions.push({
                    key: team.id,
                    text: `${team.name} (${suggestion.company_name})`,
                    value: {
                      team_id: team.id,
                      teamName: team.name,
                      companyName: suggestion.company_name,
                      company_id: suggestion.id,
                    },
                  });
                }
              }

              suggestions = newSuggestions
                .filter(
                  (team) =>
                    team.value.teamName.toLowerCase().indexOf(customerText.toLocaleLowerCase()) > -1 ||
                    team.value.companyName.toLowerCase().indexOf(customerText.toLocaleLowerCase()) > -1,
                )
                .slice(0, 5);
            } else if (customerType === 'team-member') {
              suggestions = suggestions.filter((suggestion) => suggestion.teams.length > 0);
              const newSuggestions = [];
              for (const user of suggestions) {
                for (const team of user.teams) {
                  newSuggestions.push({
                    key: `${user.id}-${team.id}`,
                    text: `${user.name} (${team.company.company_name} - ${team.name})`,
                    value: {
                      name: user.name,
                      user_id: user.id,
                      team_id: team.id,
                      teamName: team.name,
                      companyName: team.company.company_name,
                      company_id: team.company.id,
                    },
                  });
                }
              }
              suggestions = newSuggestions
                .filter(
                  (team) =>
                    team.value.teamName.toLowerCase().indexOf(customerText.toLocaleLowerCase()) > -1 ||
                    team.value.name.toLowerCase().indexOf(customerText.toLocaleLowerCase()) > -1,
                )
                .slice(0, 5);
            }

            setCustomerOptions(suggestions);
          } catch (e) {
            console.error(e);
          } finally {
            setIsLoadingUsers(false);
          }
        },
        500,
      );
    }
  }, [customerText, debounce, customerType, customer]);

  const handleInputChange = (e) => {
    if (customer) {
      onCustomerChange(null);
      setSelected(null);
    }
    setCustomerText(e.target.value);
  };

  const handleCustomerTypeChange = (e, { value }) => {
    onCustomerChange(null);
    setSelected(null);
    setCustomerType(value);
  };

  const handleCustomerClick = (option) => () => {
    setSelected(option.value);
    if (customerType === 'user') {
      onCustomerChange({ user_id: option.value.id });
    } else if (customerType === 'company') {
      onCustomerChange({ team_id: option.value.team_id });
    } else if (customerType === 'team-member') {
      onCustomerChange({ user_id: option.value.user_id, team_id: option.value.team_id });
    }
  };

  const suggestions = customerOptions.slice(0, 5).map((option) => {
    return (
      <div className="suggestion" onClick={handleCustomerClick(option)} key={option.key}>
        {option.text}
      </div>
    );
  });

  const inputPlaceholder = useMemo(
    () =>
      customerType === 'user'
        ? t('common:customerSuggest.indvidualPlaceholder')
        : customerType === 'company'
        ? t('common:customerSuggest.teamPlacehodler')
        : t('common:customerSuggest.teamMemberPlaceholder'),
    [customerType, t],
  );

  return (
    <CustomerSuggestionsContainer
      className={`${customerText.length > 0 && !customer ? 'autocomplete-open' : ''}`}
    >
      <div className="start-field">
        {isLoadingUsers ? <Loader active inline size="tiny"></Loader> : <Icon name="search" />}
      </div>
      <div className="input-container">
        <input type="text" placeholder={inputPlaceholder} value={customerText} onChange={handleInputChange} />
      </div>
      <div className="dropdown-field">
        <Dropdown
          options={customerDropdownOptions}
          value={customerType}
          onChange={handleCustomerTypeChange}
        />
      </div>
      <div className="autocomplete-field">
        {suggestions.length > 0 ? (
          suggestions
        ) : (
          <div className="suggestion">{t('common:autocomplete.noResults')}</div>
        )}
      </div>
    </CustomerSuggestionsContainer>
  );
};

export default CustomerSuggestions;
