import React from 'react';
import { withTranslation } from 'react-i18next';
import { StyledCard, StyledIcon, StyledHeader } from './BrowserUnsupported.styles';

import PublicWrapper from '../PublicWrapper/PublicWrapper';

const BrowserUnsupported = ({ t }) => {
  return (
    <PublicWrapper displaySidebar={false}>
      <StyledCard>
        <StyledHeader>{t('common:unsupportedBrowser.title')}</StyledHeader>
        <p>{t('common:unsupportedBrowser.description')}</p>
        <p>{t('common:unsupportedBrowser.we_recomend')}</p>
        <a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/en-us/edge">
          <StyledIcon name="edge" size="big"></StyledIcon>{' '}
        </a>
        <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank" rel="noopener noreferrer">
          <StyledIcon name="chrome" size="big"></StyledIcon>
        </a>
        <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">
          <StyledIcon name="firefox" size="big"></StyledIcon>
        </a>
        <a href="https://www.opera.com/" target="_blank" rel="noopener noreferrer">
          <StyledIcon name="opera" size="big"></StyledIcon>
        </a>
      </StyledCard>
    </PublicWrapper>
  );
};

export default withTranslation('common')(BrowserUnsupported);
