import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Dropdown } from 'semantic-ui-react';
import TransferModal from './TransferModal';

import { canArchiveProject, canDeleteProject, canTransferProject } from '../../../../utils/user.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { useTranslation } from 'react-i18next';
import DownloadFilesItem from './DownloadFilesItem';
import ArchiveModal from 'components/common/ArchiveModal/ArchiveModal';
import DeleteProjectModal from 'components/common/DeleteProjectModal/DeleteProjectModal';
import { PROJECT_STATUS_ARCHIVED } from 'constants/projectStatus';

const CardControls = ({ project, trigger, reloadProjects = () => {}, updateLoading = () => {} }) => {
  const [showTransferModal, setShowTransferModal] = useState(false);

  const user = useSelector((state) => state.userStore.user);
  const { t } = useTranslation();

  // Show checks
  const isProjectArchived = project.status_id === PROJECT_STATUS_ARCHIVED;
  const showTransferOption = canTransferProject(user.role, project.status_id);
  const showArchiveOption = canArchiveProject(project.status_id) || isProjectArchived;
  const showDeleteOption = canDeleteProject(project.status_id);

  return (
    <>
      <Dropdown
        className="ui"
        closeOnBlur
        closeOnChange
        closeOnEscape
        direction="left"
        icon={trigger}
        style={{ margin: '0 0 0 1rem' }}
        onClick={(e) => e.preventDefault()}
      >
        <Dropdown.Menu style={{ marginTop: '.25rem' }}>
          <DownloadFilesItem project={project} user={user}></DownloadFilesItem>
          {showTransferOption && (
            <Dropdown.Item
              onClick={() => {
                sendUserInteraction('Project card dropdown show transfer modal');
                setShowTransferModal(true);
              }}
            >
              {t('common:projects.transferProject')}
            </Dropdown.Item>
          )}
          {showArchiveOption && (
            <ArchiveModal
              trigger={
                <Dropdown.Item
                  onClick={() => {
                    sendUserInteraction('Project card dropdown archive project');
                  }}
                >
                  {t(`common:projects.${isProjectArchived ? 'unarchive' : 'archive'}`)}
                </Dropdown.Item>
              }
              setLoading={(loading) => {
                if (loading) {
                  updateLoading(true);
                } else {
                  reloadProjects();
                  updateLoading(false);
                }
              }}
              project={project}
            />
          )}

          {showDeleteOption && (
            <DeleteProjectModal
              trigger={
                <Dropdown.Item
                  onClick={() => sendUserInteraction('Project card dropdown show delete modal')}
                  text={t('common:delete')}
                  className="body-text-red hover:bg-red-4"
                />
              }
              projectId={project.id}
              setLoading={(loading) => {
                // Loading start
                if (loading) {
                  updateLoading(true);
                } else {
                  // Loading end
                  updateLoading(false);
                  reloadProjects();
                }
              }}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>

      {showTransferModal && (
        <TransferModal
          onSubmit={() => {
            setShowTransferModal(false);
            reloadProjects();
          }}
          onClose={() => setShowTransferModal(false)}
          project={project}
        />
      )}
    </>
  );
};

export default CardControls;
