import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { AccordionContainer } from './Accordion.style';

const AccordionContent = ({ open, children, watch }) => {
  const [height, setHeight] = useState(open ? document.documentElement.scrollHeight : 0);
  const ref = useRef(null);

  useEffect(() => {
    const reference = ref.current;
    window.addEventListener('resize', () => {
      if (open) {
        setHeight(reference?.scrollHeight + 42);
      } else {
        setHeight(0);
      }
    });

    return () => {
      window.addEventListener('resize', () => {
        if (open) {
          setHeight(reference?.scrollHeight + 42);
        } else {
          setHeight(0);
        }
      });
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      setHeight(ref.current?.scrollHeight + 42);
    } else {
      setHeight(0);
    }
  }, [open, watch]);

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          animate={{
            opacity: open ? 1 : 0,
            maxHeight: height,
            paddingTop: open ? 20 : 0,
            paddingBottom: open ? 20 : 0,
          }}
          initial={{
            opacity: 0,
            maxHeight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          exit={{
            opacity: 0,
            maxHeight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          transition={{ ease: 'easeInOut', duration: 0.25 }}
          className="accordion-content"
          ref={ref}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Accordion = ({ title, children, initialOpen = false, preOpen = () => {}, watch }) => {
  const [open, setOpen] = useState(initialOpen);

  const toggleOpen = async () => {
    await preOpen();
    setOpen(!open);
  };

  return (
    <AccordionContainer>
      <div className="accordion-header" onClick={toggleOpen}>
        <div className="header-text">{title}</div>
        <div className="header-icon">
          <motion.div animate={{ rotateZ: open ? 180 : 0 }} transition={{ ease: 'easeInOut' }}>
            <Icon fitted name="chevron down" />
          </motion.div>
        </div>
      </div>
      <AccordionContent open={open} watch={watch}>
        {children}
      </AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
