import styled from 'styled-components';

export const JobsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  grid-gap: 1rem;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    grid-template-columns: 1fr;
  `}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
