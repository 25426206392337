import styled from 'styled-components';
import { Icon } from 'components/common/Icon/Icon';

export const StyledSubtext = styled.div`
  color: #999999;
  line-height: 21px;
  display: flex;
  .underlined {
    border-bottom: 1px dotted #999999;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    flex-wrap: wrap;
  `}
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.blue};
  margin-left: 5px;
`;
