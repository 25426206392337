import React, { Component } from 'react';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { Header } from 'semantic-ui-react';
import { Icon } from '../common/Icon/Icon';
import Table from '../common/Table/Table';
import Loader from '../common/Loader/Loader';
import Pagination from '../common/Pagination/Pagination';
import { PaginationContainer, LoaderContainer } from './AdminMultimedia.style';
import { MainContainer } from 'components/common.styles';

import { downloadMultimediaFile, getFiles } from '../../services/multimedia';
import Button from '../common/Button/Button';
import { sendUserInteraction } from 'utils/tagManager.utils';
import UserProfileAdminLink from 'components/common/UserProfileAdminLink/UserProfileAdminLink';
import { formatAPIDateString } from 'utils/date.utils';

class AdminMultimedia extends Component {
  state = {
    isLoading: true,
    downloadingFileId: null,
    currentPage: 1,
    files: [],
    pages: 0,
  };

  componentDidMount() {
    this.fetchFiles(this.state.currentPage);
  }

  async fetchFiles(page) {
    try {
      this.setState({ isLoading: true });
      const { data, last_page } = await getFiles(page, 12);
      this.setState({
        files: data,
        pages: last_page,
        isLoading: false,
      });
    } catch (e) {
      this.setState({ isLoading: false });
      throw e;
    }
  }

  handleFileDownload = async (e, fileId) => {
    const { t } = this.props;
    sendUserInteraction('admin multimedia file download');
    this.setState({
      downloadingFileId: fileId,
    });
    try {
      // get file blob
      const response = await downloadMultimediaFile(fileId);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      this.setState({
        downloadingFileId: null,
      });
    }
  };

  handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('admin multimedia file page change');
    this.fetchFiles(activePage);
    this.setState({ currentPage: activePage });
  };

  render() {
    const { t } = this.props;
    const { downloadFileId, files, currentPage, pages, isLoading } = this.state;
    const fileRows = files.map((file) => (
      <Table.Row key={file.id}>
        <Table.Cell>
          {file.user && <UserProfileAdminLink userId={file.user.id}>{file.user.name}</UserProfileAdminLink>}
        </Table.Cell>
        <Table.Cell>{file.name}</Table.Cell>
        <Table.Cell>{formatAPIDateString({ dateString: file.created_at })}</Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            disabled={downloadFileId}
            loading={downloadFileId === file.id}
            labelPosition="right"
            onClick={(e) => this.handleFileDownload(e, file.id)}
          >
            {t('common:admin.multimedia.download')}
            <Icon name="file" />
          </Button>
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <MainContainer>
        <Header as="h1">{t('common:admin.multimedia.title')}</Header>
        {isLoading ? (
          <LoaderContainer>
            <Loader inline />
          </LoaderContainer>
        ) : (
          <Table width="100%">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('common:admin.multimedia.user')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:admin.multimedia.filename')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:admin.multimedia.createdAt')}</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">{t('common:admin.multimedia.download')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{fileRows}</Table.Body>
          </Table>
        )}
        {pages > 1 && (
          <PaginationContainer>
            <Pagination
              activePage={currentPage}
              onPageChange={this.handlePaginationChange}
              totalPages={pages}
            />
          </PaginationContainer>
        )}
      </MainContainer>
    );
  }
}

export default withTranslation('common')(AdminMultimedia);
