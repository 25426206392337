import React from 'react';
import PropTypes from 'prop-types';
import { StyledTextArea, ErrorMessage } from './TextArea.styles';

const TextArea = ({ error, errorMessage, resizable, label, onChange, ...rest }) => {
  const handleInputChange = (e) => {
    return onChange(e, e.target);
  };

  return (
    <>
      {label && <p>{label}</p>}
      <StyledTextArea
        onChange={handleInputChange}
        resizable={resizable}
        error={error || !!errorMessage}
        {...rest}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

TextArea.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  resizable: PropTypes.bool,
  label: PropTypes.string,
};

export default TextArea;
