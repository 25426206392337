import ProfessionalExperienceFormEdit from 'components/common/Vendor/Forms/ProfessionalExperienceFormEdit/ProfessionalExpriencesFormEdit';
import { TabContainer } from 'components/UserProfile/UserProfile.styles';
import React from 'react';

const ProfessionalExperience = ({ vendorData, setVendorData }) => {
  return (
    <TabContainer className="professional-experience-tab">
      <ProfessionalExperienceFormEdit vendorData={vendorData} onSubmit={setVendorData} />
    </TabContainer>
  );
};

export default ProfessionalExperience;
