import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Loader from '../../../../common/Loader/Loader';
import { LoaderContainer, TableContainer } from './UsageTable.styles';
import ProgressBar from '../../../../common/ProgressBar/ProgressBar';
import Table from '../../../../common/Table/Table';
import { taiaColors } from '../../../../../style/theme';

import { sortBy } from 'lodash';
import UserProfileAdminLink from 'components/common/UserProfileAdminLink/UserProfileAdminLink';

import { checkAllowedRole } from 'utils/user.utils';

const UsageTable = ({ loading, seats = [] }) => {
  const sortedSeats = useMemo(() => {
    return sortBy(seats, [(seat) => !seat.subscription_seat.user, 'id']);
  }, [seats]);
  const { t } = useTranslation();
  const userRole = useSelector((state) => state.userStore.user.role);

  const showTeamsRow = useMemo(() => {
    const isIndividual = checkAllowedRole({ allowedRoles: [10], userRole: userRole });
    return !isIndividual;
  }, [userRole]);

  return loading ? (
    <LoaderContainer>
      <Loader inline />
    </LoaderContainer>
  ) : (
    <TableContainer>
      <Table width="100%">
        <Table.Header>
          <Table.Row>
            <th>{t('common:catapult.subscription.usersTable.name')}</th>
            <th>{t('common:catapult.subscription.usersTable.email')}</th>
            {showTeamsRow && <th>{t('common:catapult.subscription.usersTable.team')}</th>}
            <th>{t('common:catapult.subscription.usersTable.usage')}</th>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedSeats.map((seat) => {
            const { limit_used, word_limit, id, subscription_seat } = seat;

            const usagePercentage = !limit_used ? 0 : (limit_used * 100) / word_limit;
            const { user } = subscription_seat;

            return (
              <Table.Row key={id} style={{ backgroundColor: !!user ? '#fff' : taiaColors.greyLight }}>
                <Table.Cell>
                  {!!user ? (
                    <UserProfileAdminLink userId={user.id}>{user.name}</UserProfileAdminLink>
                  ) : (
                    t('common:catapult.subscription.usersTable.notAssigned')
                  )}
                </Table.Cell>

                <Table.Cell>{!!user ? user.email : ''}</Table.Cell>
                {showTeamsRow && (
                  <Table.Cell>{!!user && user.teams.map((team) => team.name).join(', ')}</Table.Cell>
                )}
                <Table.Cell>
                  <div className="table-progress-bar">
                    <ProgressBar progress={usagePercentage} width="100px" />
                    <label className="usage-label label">
                      {`${limit_used}/${word_limit} ${t('common:catapult.subscription.words')}`}
                    </label>
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </TableContainer>
  );
};

export default UsageTable;
