import styled from 'styled-components';
import { Input } from '../../common/Input/Input';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;
