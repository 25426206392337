import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { getAllUsers } from '../../services/users';

import { AdminUsersLayout, FilterDropdown, CheckMark, StyledInput, StyledAvatar } from './AdminUsers.styles';
import { TablePlaceholderRow } from './TablePlaceholderRow/TablePlaceholderRow';
import { Icon } from '../common/Icon/Icon';
import Table from '../common/Table/Table';
import { TableToolbar, StyledPagination, SortHeaderCell } from '../common.styles';
import { USERS_TYPE } from '../../constants/roles';
import { useDebounce, withDebounce } from '../../hoc/debouncer';
import Button from '../common/Button/Button';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { formatAPIDateString } from 'utils/date.utils';
import LinkTableCell from 'components/common/Table/LinkTableCell';

const entriesPerPage = 14;

const AdminUsers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const [activePage, setActivePage] = useLocalStorage('admin-users-page', 1);
  const [filters, setFilters] = useLocalStorage('admin-users-filters', []);
  const [filterString, setFilterString] = useLocalStorage('admin-users-text-filter', '');
  const [numOfPages, setNumberOfPages] = useState(1);
  const [sorting, setSorting] = useLocalStorage('admin-users-sorting', {
    currentSort: 'created_at',
    ascending: false,
  });

  const { debounce } = useDebounce();
  const { t } = useTranslation();

  const options = [
    { key: 0, text: t('roles:client'), value: 10 },
    { key: 1, text: t('roles:salesman'), value: 20 },
    { key: 2, text: t('roles:projectManager'), value: 21 },
    { key: 3, text: t('roles:administrator'), value: 29 },
    { key: 4, text: t('roles:translator'), value: 30 },
    { key: 5, text: t('roles:companyOwner'), value: 40 },
    { key: 6, text: t('roles:companyMember'), value: 42 },
    { key: 7, text: t('roles:deleted'), value: 50 },
    { key: 8, text: t('roles:unknownCompany'), value: 60 },
    { key: 9, text: t('roles:knownCompany'), value: 61 },
    { key: 10, text: t('roles:created'), value: 1 },
  ];

  useEffect(() => {
    const fetchUsers = async (page, per_page, search, roles, sortBy = 'created_at', sort_order = 'DESC') => {
      debounce(
        'adminUsers',
        async () => {
          try {
            setIsLoading(true);
            const data = await getAllUsers({ page, per_page, search, roles, sortBy, sort_order });
            setUsers(data.data);

            if (page > data.last_page) {
              setActivePage(data.last_page);
            }
            setNumberOfPages(data.last_page);
          } catch (e) {
            setError(true);
            console.error(e);
          } finally {
            setIsLoading(false);
          }
        },
        500,
      );
    };

    fetchUsers(
      activePage,
      entriesPerPage,
      filterString,
      filters,
      sorting.currentSort,
      sorting.ascending ? 'ASC' : 'DESC',
    );
  }, [activePage, filterString, sorting, filters, debounce, setActivePage]);

  const sortUsers = (column) => {
    sendUserInteraction(`admin users sort users: ${column}`);
    const currentDirection = column === sorting.currentSort ? !sorting.ascending : true;
    setActivePage(1);
    setSorting({
      currentSort: column,
      ascending: currentDirection,
    });
  };

  const sortIcon = (name) => {
    if (name === sorting.currentSort) {
      if (sorting.ascending) {
        return 'angle-up';
      } else {
        return 'angle-down';
      }
    } else {
      return 'sort';
    }
  };

  //Handlers
  const handleUsersSort = (column) => {
    return (e) => {
      sortUsers(column);
    };
  };

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction(`admin users page change: ${activePage}`);
    setActivePage(activePage);
  };

  const handlefilterDropdownChange = (e, { value }) => {
    sendUserInteraction(`admin users filter dropdown change`);
    setFilters(value);
    setActivePage(1);
  };

  const handleFilterString = (e, { value }) => {
    sendUserInteraction(`admin users filter string change`);
    setFilterString(value);
    setActivePage(activePage);
  };

  const handleClear = async () => {
    sendUserInteraction(`admin users reset filters`);
    setFilters([]);
    setFilterString('');
    setSorting({
      currentSort: 'created_at',
      ascending: false,
    });
    setActivePage(1);
  };

  // Create User row elements
  const tableRows = isLoading ? (
    <>
      <TablePlaceholderRow />
      <TablePlaceholderRow />
      <TablePlaceholderRow />
      <TablePlaceholderRow />
      <TablePlaceholderRow />
      <TablePlaceholderRow />
    </>
  ) : (
    users.map((user, i) => {
      const { active, avatar, created_at, email, has_subscription, id, name, role } = user;

      /**
       * Mixed array of strings, jsx, react components to display inside of link cells
       */
      const cells = [
        // User name and avatar
        <>
          <StyledAvatar img={avatar} />
          {name}
        </>,
        // Email
        email,
        // Role
        role ? USERS_TYPE[role] : 'n/a',
        // Is activated
        <CheckMark active={active} name={active > 0 ? 'check' : 'times'} />,
        // Created date
        formatAPIDateString({ dateString: created_at }),
        // If it is catapult user
        <CheckMark active={has_subscription} name={has_subscription ? 'check' : 'times'} />,
      ];

      return (
        <Table.Row key={user.id} className="admin-users-table-row">
          {cells.map((val, idx) => (
            <LinkTableCell key={`cell-${idx}`} unstyled to={`/admin/user/${id}`} text={val} />
          ))}
        </Table.Row>
      );
    })
  );

  return (
    <AdminUsersLayout>
      <TableToolbar>
        {numOfPages > 1 && (
          <StyledPagination
            activePage={activePage}
            totalPages={numOfPages}
            onPageChange={handlePaginationChange}
          />
        )}
        <StyledInput
          icon={<Icon name="search" inElement />}
          placeholder="Search..."
          value={filterString}
          onChange={handleFilterString}
        />
        <FilterDropdown
          icon={<Icon name="filter" inElement />}
          labeled
          button
          className="icon"
          multiple
          onChange={handlefilterDropdownChange}
          options={options}
          placeholder={t('common:admin.users.dropdownPlaceholder')}
          selection
          value={filters}
        />
        <Button onClick={handleClear}>{t('common:clear')}</Button>
      </TableToolbar>
      <Table selectable width="100%">
        <Table.Header>
          <Table.Row>
            <SortHeaderCell onClick={handleUsersSort('name')}>
              {t('common:admin.users.tableHeader.name')}
              <Icon name={sortIcon('name')} />
            </SortHeaderCell>
            <SortHeaderCell onClick={handleUsersSort('email')}>
              {t('common:admin.users.tableHeader.email')}
              <Icon name={sortIcon('email')} />
            </SortHeaderCell>
            <SortHeaderCell onClick={handleUsersSort('role')}>
              {t('common:admin.users.tableHeader.role')}
              <Icon name={sortIcon('role')} />
            </SortHeaderCell>
            <SortHeaderCell textAlign="center" onClick={handleUsersSort('active')}>
              {t('common:admin.users.tableHeader.activated')}
              <Icon name={sortIcon('active')} />
            </SortHeaderCell>
            <SortHeaderCell textAlign="right" onClick={handleUsersSort('created_at')}>
              {t('common:admin.users.tableHeader.createdAt')}
              <Icon name={sortIcon('created_at')} />
            </SortHeaderCell>
            <Table.HeaderCell textAlign="right">
              {t('common:admin.users.tableHeader.catSubscription')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    </AdminUsersLayout>
  );
};

export default withDebounce(withTranslation('common')(AdminUsers));
