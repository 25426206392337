import { Checkbox } from 'components/common/Checkbox/Checkbox';
import styled from 'styled-components';

export const PaymentFailedContainer = styled.div`
  display: grid;
  margin-top: 50px;
  justify-items: center;

  h1 {
    font-size: 3rem !important;
  }

  .sub-header {
    margin-bottom: 10px;
  }
  .error-message {
    margin-bottom: 30px;
  }
  .card-form {
    margin-bottom: 200px;
    .sub-text {
      margin-bottom: 10px;
    }
  }

  .requires-confirmation {
    text-align: center;
    .sub-text {
      margin-bottom: 10px;
    }
  }

  .submit-button {
    margin-top: 20px;
  }
`;

export const ShowBillingToggle = styled(Checkbox)`
  margin-bottom: 1em;
`;
