import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { customHistory } from './history';
import { toast } from 'react-toastify';

import httpClient from './services/httpclient';

import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import PageNotFound from './components/PageNotFound/PageNotFound';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import VersionModal from 'components/VersionModal/VersionModal';
import VersionToast from 'components/VersionModal/VersionToast/VersionToast';

import { getClassifiers, getInitialUserData } from './utils/initialLoad';
import { setIsAuthenticated, setIsAuthenticating, setUser } from './store/userSlice';
import { getAllRoutes } from './routes';
import { Banner } from './components/common/Banner/Banner';
import { withTranslation } from 'react-i18next';
import { ALL_ROLES } from 'constants/roles';
import { getVersionInfo, shouldRemindVersion } from 'utils/version.utils';
import { clearFilterLocalStorage } from 'utils/storage.utils';
import GlobalStyles from 'style/global';
import { isIosSafari } from 'utils/browser.utils';
import { clearLSForVendorOnboarding } from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';
import { userStartedVendorOnboarding } from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';

import 'semantic-ui-css/semantic.min.css';

class App extends Component {
  state = {
    isLoading: true, // component loads data on mounts
    showVersionModal: false,
  };

  checkIfIosSafari() {
    if (isIosSafari) document.body.classList.add('is-safari-ios');
  }

  setViewportHeightVariable() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  componentDidMount = async () => {
    try {
      await getClassifiers();
      const authToken = localStorage.getItem('authToken');
      const { minorChange, majorChange, versionChanged } = getVersionInfo();

      if (versionChanged) {
        this.setState({
          showVersionModal: majorChange,
        });
        if (minorChange && !majorChange) {
          // if we have a minor patch change and no major change show toast notification instead of the full page modal
          toast(<VersionToast />, {
            toastId: 'version-toast',
            autoClose: false,
            closeOnClick: false,
          });
        }
        clearFilterLocalStorage();
      } else {
        if (shouldRemindVersion()) {
          this.setState({
            showVersionModal: true,
          });
        }
      }
      if (authToken) {
        const { dispatch } = this.props;
        // check if user session with localStorage token is valid
        dispatch(setIsAuthenticating({ isAuthenticating: true }));
        try {
          // first set httpClient auth header
          httpClient.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
          // get user info
          await getInitialUserData();
        } catch (e) {
          dispatch(setUser({ user: null }));
          dispatch(setIsAuthenticated({ isAuthenticated: false }));
        } finally {
          dispatch(setIsAuthenticating({ isAuthenticating: false }));
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });

      // If vendor model exists on user, remove local storage vendor onboarding data
      if (this.props?.user?.vendor && userStartedVendorOnboarding()) {
        clearLSForVendorOnboarding();
      }

      // Set viewport height, fix for mobile devices
      this.setViewportHeightVariable();

      window.addEventListener('resize', this.setViewportHeightVariable);

      // Check if ios safari
      this.checkIfIosSafari();
    }
  };

  closeVersionModal = () => {
    this.setState({
      showVersionModal: false,
    });
  };

  render() {
    const { user, isAuthenticated, isAuthenticating, t } = this.props;
    const { isLoading, showVersionModal } = this.state;

    let teams = [];

    if (user) {
      if (user.hasOwnProperty('teams')) {
        teams = [...user.teams];
      }
    }
    const Routes = getAllRoutes({ teams: teams }).map((r) => {
      /* Public routes that redirect to /projects once user is authenticated */
      if (r.type === 'public') {
        return (
          <PublicRoute
            key={r.path}
            component={r.component}
            exact
            isAuthenticated={isAuthenticated}
            user={user}
            path={r.path}
            routeName={r.name}
          />
        );
      }

      /* Private routes that redirect to / (login) if user is not logged in or does not have correct permissions */
      if (r.type === 'private') {
        return (
          <PrivateRoute
            key={r.path}
            allowedRoles={r.allowedRoles}
            component={r.component}
            exact
            isAuthenticated={isAuthenticated}
            isAuthenticating={isAuthenticating}
            isFullscreen={r.isFullscreen}
            noWrapper={r.noWrapper}
            role={user ? user.role : null}
            path={r.path}
            pageTitle={r.pageTitle}
          />
        );
      }

      /* Public routes that never redirect */
      return <Route key={r.path} component={r.component} exact path={r.path} />;
    });

    const hasUnpaidInvoices = isAuthenticated && user.unpaid_invoices > 0;

    return isLoading ? (
      <LoadingPane height={'calc((var(--vh, 1vh) * 100))'} />
    ) : (
      <>
        <>
          {hasUnpaidInvoices && <Banner content={t('common:admin.user.unpaidInvoices')} />}
          {showVersionModal && <VersionModal onClose={this.closeVersionModal} />}
          <GlobalStyles />
          <Router history={customHistory}>
            <Switch>
              {Routes}
              {/* 404 route */}
              {isAuthenticated ? (
                <PrivateRoute
                  allowedRoles={ALL_ROLES}
                  component={PageNotFound}
                  isAuthenticated={isAuthenticated}
                  isAuthenticating={isAuthenticating}
                  isFullscreen={false}
                  role={user ? user.role : null}
                />
              ) : (
                <PublicRoute component={PageNotFound} isAuthenticated={isAuthenticated} user={user} />
              )}
            </Switch>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </Router>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.userStore.isAuthenticated,
  isAuthenticating: state.userStore.isAuthenticating,
  user: state.userStore.user,
});

export default connect(mapStateToProps)(withTranslation()(App));
