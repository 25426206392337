import { ZINDEX } from 'constants/zIndex';

export const zindexPopperModifier = {
  name: 'zIndex',
  enabled: true,
  phase: 'write',
  fn: ({ state }) => {
    state.styles.popper.zIndex = ZINDEX.aboveAll;
  },
};
