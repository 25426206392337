import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Dropdown, Input, Modal } from 'semantic-ui-react';
import Table from '../../../common/Table/Table';
import Message from '../../../common/Message/Message';
import { isValidEmail } from '../../../../utils/string.utils';

import HelpPopup from '../../../Company/CompanyInvites/InviteUsersModal/HelpPopup';
import { HeaderContainer } from '../../../Company/CompanyInvites/InviteUsersModal/InviteUsersModal.style';
import { NewMemberWrapper } from './NewTeamMemberModal.style';
import { postMemberInvite } from '../../../../services/teams';
import { toast } from 'react-toastify';
import Button from '../../../common/Button/Button';
import { Icon } from '../../../common/Icon/Icon';
import { TEAM_ROLES, TEAM_TRANSLATIONS } from '../../../../constants/roles';
import { sendUserInteraction } from 'utils/tagManager.utils';

const NewTeamMemberModal = ({ addInvites = () => {} }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const [error, setError] = useState('');

  const [role, setRole] = useState(1);
  const [email, setEmail] = useState('');

  const [newMembers, setNewMembers] = useState([]);

  useEffect(() => {
    setError('');
    setRole(1);
    setEmail('');
    setNewMembers([]);
  }, [open]);

  const roleOptions = TEAM_ROLES.map((role) => {
    return {
      key: role.value,
      text: t(role.translation),
      value: role.value,
    };
  });

  const removeMember = (idx) => {
    sendUserInteraction('remove team member');
    return (e) => {
      const members = [...newMembers];
      members.splice(idx, 1);
      setNewMembers(members);
    };
  };

  const addMember = () => {
    sendUserInteraction('add team member');
    const members = [...newMembers, { role: role, email: email }];
    setNewMembers(members);
  };

  const isValid = () => {
    return isValidEmail(email) && role;
  };

  const handleRoleChange = (e, { value }) => {
    sendUserInteraction('change team member role');
    setRole(value);
  };

  const submitInvites = async () => {
    sendUserInteraction('send out invites');
    const invites = [...newMembers];
    const newInvites = [];
    let invite = null;
    try {
      setIsSubmitting(true);

      for (invite of newMembers) {
        const newInvite = await postMemberInvite({ team_id: id, email: invite.email, role_id: invite.role });
        invites.shift();
        toast.success(t('common:toasts.inviteMembers.success') + `: ${invite.email}`);
        newInvites.unshift(newInvite);
      }
      addInvites(newInvites);
      setOpen(false);
    } catch (e) {
      addInvites(newInvites);
      toast.error(t('common:toasts.inviteMembers.error'));
      if (e.response.status === 422) {
        setError(t('common:team.members.emailInUse') + `: ${invite.email}`);
      } else {
        setError(t('common:team.members.inviteError') + `: ${invite.email}`);
      }
      setNewMembers(invites);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button actiontype="primary" labelPosition="left">
          <Icon name="plus-square" />
          {t('common:team.members.inviteANewMember')}
        </Button>
      }
    >
      <Modal.Header>
        <HeaderContainer>
          {t('common:team.members.addMember')}
          <HelpPopup />
        </HeaderContainer>
      </Modal.Header>
      <Modal.Content>
        {error && <Message text={error} type="error" />}
        <NewMemberWrapper>
          <div className="member-form">
            <Input
              placeholder={t('common:team:members.email')}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{ width: '100%' }}
              value={email}
            />
            <Dropdown
              selection
              options={roleOptions}
              placeholder={t('common:team.members.role')}
              value={role}
              onChange={handleRoleChange}
            />
            <Button
              icon="plus"
              actiontype="primary"
              disabled={!isValid()}
              onClick={addMember}
              style={{ height: 'unset' }}
            />
          </div>
          {newMembers.length > 0 ? (
            <Table width="100%">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('common:team.members.email')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:team.members.role')}</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {newMembers.map((member, idx) => (
                  <Table.Row key={idx}>
                    <Table.Cell>{member.email}</Table.Cell>
                    <Table.Cell>{t(TEAM_TRANSLATIONS[member.role])}</Table.Cell>

                    <Table.Cell textAlign="right">
                      <Button actiontype="delete" icon="trash-alt" onClick={removeMember(idx)} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <Message text={t('common:team.members.addReminder')} type="info" />
          )}
        </NewMemberWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={() => setOpen(false)} disabled={isSubmitting}>
          {t('common:cancel')}
        </Button>
        <Button
          actiontype="submit"
          disabled={newMembers.length <= 0}
          onClick={submitInvites}
          loading={isSubmitting}
        >
          {t('common:submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewTeamMemberModal;
