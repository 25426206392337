import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 2rem 0;
`;

export const StyledHeader = styled.h3`
  margin-bottom: 0rem !important;
  margin-top: 0 !important;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
`;

export const StyledLabel = styled.label`
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MessageButton = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 18px;
`;

export const StyledMessage = styled.div`
  margin-bottom: 4em;
  width: 100%;
  line-height: 28px;
  font-size: 18px;
`;
