import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Header } from 'semantic-ui-react';
import Loader from '../../common/Loader/Loader';
import { postOrderProject, postProjectPaycheck } from '../../../services/project';
import Button from '../../common/Button/Button';
import { OrderProjectContainer } from './OrderProject.style';
import { toast } from 'react-toastify';
import { sendUserInteraction } from 'utils/tagManager.utils';

const OrderProject = ({ deductBalance = false, project }) => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const orderProject = async () => {
      try {
        const newProjectData = await postOrderProject({
          comment: project.comment,
          deductBalance: deductBalance,
          deliveryOption: project.is_delivery || 50,
          diy: project.diy === 1,
          jobs: project.jobs,
          projectId: project.id,
        });
        return newProjectData;
      } catch (e) {
        throw e;
      }
    };

    const orderingProject = async () => {
      try {
        const delivery_option = !!project.is_delivery ? project.is_delivery : 50;

        await postProjectPaycheck({
          calculation: true,
          comment: project.comment,
          diy: project.diy === 1,
          deliveryOption: delivery_option,
          deductBalance: deductBalance,
          jobs: project.jobs,
          projectId: project.id,
        });

        // directly order project
        await orderProject();
        toast.success(
          t(
            project.diy === 1
              ? 'common:toasts.projectReady.successDIY'
              : 'common:toasts.projectReady.success',
          ),
        );
        history.push(`/project/${project.id}`);
      } catch (e) {
        setError(true);
        console.error(e);
      }
    };

    orderingProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderProjectContainer>
      <Header as="h1">
        {error ? t('common:checkout.orderProjectError') : t('common:checkout.orderProject')}
      </Header>
      <div className="sub-header">
        {error
          ? t('common:checkout.orderProjectErrorDecription')
          : t('common:checkout.orderProjectDescription')}
      </div>
      {error ? (
        <Button
          actiontype="primary"
          onClick={() => {
            sendUserInteraction('clicked back to homepage on order project screen');
            history.push('/');
          }}
        >
          {t('common:checkout.backToHomepage')}
        </Button>
      ) : (
        <div className="page-loader">
          <Loader inline />
        </div>
      )}
    </OrderProjectContainer>
  );
};

export default OrderProject;
