import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../common/Icon/Icon';
import { getInvoice, getTranslatedFiles } from '../../../../services/project';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';

import OriginalFilesDownload from '../../../common/OriginalFilesDownload/OriginalFilesDownload';
import Button from '../../../common/Button/Button';
import { sendUserInteraction } from 'utils/tagManager.utils';

const ProjectFiles = ({ project }) => {
  const { t } = useTranslation();
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [translatedLoading, setTranslatedLoading] = useState(false);
  const { status_id } = project;
  const user = useSelector((state) => state.userStore.user);

  const isFinished = () => {
    return status_id === 6 || status_id === 20;
  };

  const handleDownloadTranslatedClick = async () => {
    sendUserInteraction('ordered project: download translated project files click');
    setTranslatedLoading(true);
    try {
      // get file blob
      const response = await getTranslatedFiles(project.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, fileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setTranslatedLoading(false);
    }
  };

  const handleDownloadInvoiceClick = async () => {
    sendUserInteraction('ordered project: download project invoice click');
    setInvoiceLoading(true);
    try {
      // get file blob
      const response = await getInvoice(project.id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1];
      const decodedFileName = decodeURIComponent(fileName).replace(/"/g, '').replace(/\+/g, ' ');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, decodedFileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setInvoiceLoading(false);
    }
  };

  const disableDownload = !user.active;

  return (
    <>
      <OriginalFilesDownload projectId={project.id} />

      <Button
        disabled={disableDownload || project.translated_file === null}
        download
        loading={translatedLoading}
        labelPosition="right"
        onClick={handleDownloadTranslatedClick}
      >
        {t('common:projects.projectInfo.translatedFiles')}
        <Icon name="english-to-chinese" />
      </Button>

      {isFinished() && project.proforma !== undefined && project.proforma !== null && (
        <Button
          disabled={disableDownload || project.proforma === null}
          download
          labelPosition="right"
          loading={invoiceLoading}
          onClick={handleDownloadInvoiceClick}
        >
          {t('common:projects.projectInfo.invoice')}
          <Icon name="file" />
        </Button>
      )}
    </>
  );
};

export default ProjectFiles;
