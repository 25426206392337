import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  background-color: ${({ $isPurchased }) => ($isPurchased ? '#ffb202' : '#8ed4e2')};
  color: #232323;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 10px 20px 10px;
  min-width: 250px;
  padding: 10px 20px;
  text-align: center;
`;

export const Name = styled.h1`
  font-size: 36px;
  font-weight: 500;
  line-height: 43.2px;
  margin: 0;
  padding: 20px;
  width: 100%;
`;

export const PriceContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 100%;
`;

export const OriginalPrice = styled.div`
  font-size: 35px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  text-decoration: line-through;
`;

export const Price = styled.div`
  font-size: 70px;
  font-weight: 800;
  line-height: 56px;
  margin-top: ${({ $marginTop }) => (!!$marginTop ? $marginTop : 0)};
`;

export const SubPrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
`;

export const Words = styled.ul`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin: 0;
  margin-top: ${({ $marginTop }) => (!!$marginTop ? $marginTop : 0)};
  padding: 0;
  width: 100%;
  li {
    margin: 10px 0;
  }
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${({ $marginTop }) => (!!$marginTop ? $marginTop : 0)};
  justify-content: center;
  padding: 30px;
  width: 100%;
`;

export const AlreadySubscribed = styled.div`
  color: #232323;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  padding: 10px 0;
`;
