import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Header } from 'semantic-ui-react';
import { Icon } from '../common/Icon/Icon';
import { Loader } from 'semantic-ui-react';

import {
  CardText,
  CardsContainer,
  Card,
  DeleteIcon,
  FilesList,
  FileRow,
  FileText,
  ListContainer,
  StyledDropzone,
} from './FileStorage.styles';
import { MainContainer } from 'components/common.styles';

import { uploadFile, getUserFiles, deleteFile } from '../../services/multimedia';
import Button from '../common/Button/Button';
import { sendUserInteraction } from 'utils/tagManager.utils';

export default () => {
  const [userFiles, setUserFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [erroredFiles, setErroredFiles] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true); // component loads list of user files on mount
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [deletingFileId, setDeletingFileId] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  const user = useSelector((state) => state.userStore.user);
  let { id } = useParams();
  // if we have user id in params this is admin view, else it's the logged in user viewing his files
  const userId = id ? id : user.id;

  useEffect(() => {
    const fetchFilesList = async () => {
      try {
        setIsLoadingFiles(true);
        const filesData = await getUserFiles(userId);
        setUserFiles(filesData.data);
      } catch (e) {
        // TODO files retrieval error here
      } finally {
        setIsLoadingFiles(false);
      }
    };

    if (!isUploadingFiles) {
      fetchFilesList();
    }
  }, [isUploadingFiles, setUserFiles, userId]);

  const handleFiles = (files) => {
    sendUserInteraction('add new files to file storage');
    const newFiles = [...filesToUpload, ...files];
    setFilesToUpload(newFiles);
  };

  const removeFile = (fileName) => {
    sendUserInteraction('remove file from files to upload');
    const newFiles = filesToUpload.filter((file) => file.name !== fileName);
    setFilesToUpload(newFiles);
  };

  const handleFilesUpload = async (e) => {
    sendUserInteraction('add files');
    e.preventDefault();
    setIsUploadingFiles(true);
    try {
      const erroredFiles = [];

      await Promise.all(
        filesToUpload.map(async (file) => {
          try {
            await uploadFile(userId, file);
          } catch (e) {
            erroredFiles.push(file.name);
          }
        }),
      );
      setErroredFiles(erroredFiles);
      setFilesToUpload([]);
    } catch (e) {
      // TODO files upload error here
      setFilesToUpload([]);
    } finally {
      setIsUploadingFiles(false);
    }
  };

  const handleDeleteFile = async (fileId) => {
    sendUserInteraction('delete file from file storage');
    setDeletingFileId(fileId);
    try {
      await deleteFile(fileId);
      const newUserFiles = userFiles.filter((file) => file.id !== fileId);
      setUserFiles(newUserFiles);
    } catch (e) {
      const userFile = userFiles.find((file) => file.id === fileId);
      setDeleteError(userFile.name);
    } finally {
      setDeletingFileId(null);
    }
  };

  return (
    <MainContainer>
      <Header as="h2">Permanent file storage</Header>
      <CardsContainer>
        <Card>
          {erroredFiles.length ? (
            <ListContainer>
              <FilesList style={{ maxHeight: '100%' }}>
                <CardText style={{ textAlign: 'left', marginBottom: '1rem' }}>
                  Some files failed to upload:
                </CardText>
                {erroredFiles.map((filename, index) => (
                  <FileRow key={`${index}-${filename}-error`}>
                    <div>{filename}</div>
                  </FileRow>
                ))}
              </FilesList>
            </ListContainer>
          ) : isUploadingFiles ? (
            <Loader active inline size="medium" />
          ) : (
            <>
              <StyledDropzone maxSize={500000000} onDrop={handleFiles}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Icon name="hdd" size="massive" />
                  </div>
                )}
              </StyledDropzone>
              {filesToUpload.length ? (
                <ListContainer>
                  <FilesList>
                    {filesToUpload.map((file, index) => (
                      <FileRow key={`${index}-${file.name}`}>
                        <FileText>{file.name}</FileText>
                        <DeleteIcon name="trash-alt" onClick={() => removeFile(file.name)} />
                      </FileRow>
                    ))}
                  </FilesList>
                  <Button fluid onClick={handleFilesUpload}>
                    Upload
                  </Button>
                </ListContainer>
              ) : (
                <CardText>Upload file(s) to permanent storage</CardText>
              )}
            </>
          )}
        </Card>
        <Card>
          {deleteError ? (
            <CardText style={{ textAlign: 'left' }}>{`Failed to delete file: ${deleteError}`}</CardText>
          ) : isLoadingFiles || isUploadingFiles ? (
            <Loader active inline size="medium" />
          ) : userFiles.length ? (
            <>
              <CardText>Stored files</CardText>
              <ListContainer>
                <FilesList style={{ maxHeight: '100%' }}>
                  {userFiles.map((file, index) => (
                    <FileRow key={`${index}-${file.name}-perma`}>
                      <FileText>{file.name}</FileText>
                      {deletingFileId ? (
                        <Loader active inline size="medium" />
                      ) : (
                        <DeleteIcon name="trash-alt" onClick={() => handleDeleteFile(file.id)} />
                      )}
                    </FileRow>
                  ))}
                </FilesList>
              </ListContainer>
            </>
          ) : (
            <>
              <Icon name="list-ul" size="massive" />
              <CardText>Stored files</CardText>
            </>
          )}
        </Card>
      </CardsContainer>
    </MainContainer>
  );
};
