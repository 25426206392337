import React, { useState } from 'react';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'semantic-ui-react';
import { getInvoice } from '../../services/project';

const DownloadInvoice = ({ projectId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const downloadInvoice = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // get file blob
      const response = await getInvoice(projectId);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1];
      const decodedFileName = decodeURIComponent(fileName).replace(/"/g, '').replace(/\+/g, ' ');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, decodedFileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dropdown.Item
      disabled={isLoading}
      download
      onClick={downloadInvoice}
      text={t('common:admin.project.downloadInvoice')}
    />
  );
};

export default DownloadInvoice;
