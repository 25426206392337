import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { PriceInputContainer } from './PriceInput.style';

const PriceInput = ({
  initialAmount = 0,
  initialCurrency,
  onPriceChange = () => {},
  onCurrencyChange = () => {},
}) => {
  const currencies = useSelector((state) => state.classifiersStore.currencies);
  const [amount, setAmount] = useState(initialAmount);
  const [currency, setCurrency] = useState(initialCurrency ? initialCurrency : currencies[0]);

  const currencyOptions = currencies.map((currency) => {
    return {
      key: currency.id,
      text: currency.code,
      value: currency.code,
    };
  });

  const handleCurrencyChange = (e, { value }) => {
    const cur = currencies.find((cur) => cur.code === value);
    setCurrency({ ...cur });
    onCurrencyChange({ ...cur });
  };

  const handleAmountChange = (e) => {
    const am = Number(e.target.value);

    if (am < 0) {
      setAmount(0);
      onPriceChange(0);
    } else {
      setAmount(am);
      onPriceChange(am);
    }
  };

  return (
    <PriceInputContainer>
      <div className="currency-symbol">{currency.symbol}</div>
      <div className="price-amount">
        <input type="number" placeholder="0.00" min="0" value={amount} onChange={handleAmountChange}></input>
      </div>
      <div className="currency-select">
        <Dropdown options={currencyOptions} value={currency.code} onChange={handleCurrencyChange}></Dropdown>
      </div>
    </PriceInputContainer>
  );
};

export default PriceInput;
