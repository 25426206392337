import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Assets
import logo from 'assets/taia_logo.svg';
import redCircleImg from './assets/red-circle.png';
import yellowCircleImg from './assets/yellow-circle.png';
import blueCircleImg from './assets/blue-circle.png';
import blueCircleShadowImg from './assets/blue-circle-shadow.png';

// Styles
import './style.scss';

/**
 * Render public sidear
 */
const PublicSidebar = ({ children }) => {
  const { t } = useTranslation();

  const defaultChildren = (
    <div className="public-sidebar__default-children">
      <h2> {t('common:publicSidebar.default.title')} </h2>
      <ul>
        <li> {t('common:publicSidebar.default.list.item1')} </li>
        <li> {t('common:publicSidebar.default.list.item2')} </li>
        <li> {t('common:publicSidebar.default.list.item3')} </li>
        <li> {t('common:publicSidebar.default.list.item4')}</li>
      </ul>
    </div>
  );

  const childrenCount = React.Children.count(children);

  return (
    <aside className="public-sidebar">
      <div className="public-sidebar__content">
        <Link className="public-sidebar__logo" to="/">
          <img src={logo} alt="" />
        </Link>
        <div className="public-sidebar__children-container">
          {childrenCount === 0 ? defaultChildren : children}
        </div>
      </div>
      <div className="public-sidebar__floating-shapes">
        <img className="red-circle floating" src={redCircleImg} alt="" />
        <img className="yellow-circle floating" src={yellowCircleImg} alt="" />
        <div className="blue-circle-w-shadow">
          <img className="circle floating" src={blueCircleImg} alt="" />
          <img className="shadow" src={blueCircleShadowImg} alt="" />
        </div>
      </div>
    </aside>
  );
};

// Prop types
PublicSidebar.propTypes = {
  // Can be any, string, number, element etc.
  children: PropTypes.node,
};

export default PublicSidebar;
