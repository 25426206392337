import React, { useCallback, useRef, useState, useEffect } from 'react';
import VendorSettingsForm from 'components/common/Vendor/Forms/VendorSettingsForm/VendorSettingsForm';
import { TabContainer } from 'components/UserProfile/UserProfile.styles';
import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { updateVendorData } from 'services/vendors';
import { toast } from 'react-toastify';

const requiredFields = ['native_lng_id', 'country_id'];

const VendorSettings = ({ vendorData, setVendorData, onChange }) => {
  // Store inital form data
  const initialFormData = useRef({
    native_lang_id: vendorData.native_lang_id ?? '',
    description: vendorData.description ?? '',
    country_id: vendorData.country_id ?? '',
    timezone: vendorData.timezone ?? '',
  });

  // Local state
  const [valid, setValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData.current);

  // Hooks
  const { t } = useTranslation();

  const handleFormChange = useCallback((_, data) => {
    // If data is not valid ie. required fields are empty
    // Set valid to false to disable save changes btn
    for (const fieldName of requiredFields) {
      // Checks for empty string
      if (data[fieldName] === '') {
        setValid(false);
        return;
      }
    }

    // Compare initialFormData to new data, if they are not equal enable save changes button
    if (!isEqual(data, initialFormData.current)) {
      setFormData(data);
      setValid(true);
    } else {
      setValid(false);
    }
  }, []);

  const handleSaveChanges = async () => {
    // Patch vendor
    // Only get the values for the fields that are different
    const differences = {};

    for (const fieldName in formData) {
      const value = formData[fieldName];
      const initialValue = initialFormData.current[fieldName];

      if (value !== initialValue) differences[fieldName] = value;
    }

    try {
      setIsSubmitting(true);

      const { data } = await updateVendorData({ id: vendorData.id, data: differences });

      setVendorData(data);

      await onChange();

      toast.success(t('common:userInfo.vendorTabs.settings.toast.success'));
    } catch (error) {
      toast.error(t('common:userInfo.vendorTabs.settings.toast.error'));
      console.error(error);
    } finally {
      setValid(false);
      setIsSubmitting(false);
    }
  };

  /**
   * Fires on redux vendor data change, which happens after saving changes
   */
  useEffect(() => {
    initialFormData.current = {
      native_lang_id: vendorData.native_lang_id ?? '',
      description: vendorData.description ?? '',
      country_id: vendorData.country_id ?? '',
      timezone: vendorData.timezone ?? '',
    };
  }, [vendorData]);

  return (
    <TabContainer>
      <VendorSettingsForm onChange={handleFormChange} initialFormData={initialFormData.current} />

      <div className="section-description">
        <div className="left" />
        <div className="right">
          <Button
            big
            labelPosition="left"
            actiontype="primary"
            onClick={handleSaveChanges}
            loading={isSubmitting}
            disabled={!valid || isSubmitting}
            data-cy="user-profile-edit-save"
            className="user-save-changes-button"
          >
            <Icon name="save" />
            {t('common:userInfo.updateProfile.saveChanges')}
          </Button>
        </div>
      </div>
    </TabContainer>
  );
};

export default VendorSettings;
