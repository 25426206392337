import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { withDebounce } from '../../../hoc/debouncer';

import Button from 'components/common/Button/Button';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import Message from '../../common/Message/Message';
import Pagination from '../../common/Pagination/Pagination';
import ProjectCard from 'components/Projects/ProjectsCards/ProjectCard/ProjectCard';
import ProjectFilter from '../../Projects/ProjectFilters/ProjectFilter';
import UpdateWrapper from 'components/Projects/ProjectsCards/UpdateWrapper';

import { CompanyProjectsWrapper, FilterButtonContainer, ProjectsLayout } from './CompanyProjects.style';

import { getCompanyProjects } from '../../../services/company';

import { sendUserInteraction } from 'utils/tagManager.utils';
import { useLocalStorage } from 'utils/storage.utils';

import { STATUS } from '../../common/StatusLabel/StatusLabel.constants';
import { useCompanyState } from '../Company.state';

const CompanyProjects = ({ debounce }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [numOfPages, setNumOfPages] = useState(1);
  const [projects, setProjects] = useState([]);
  const [showFilterControls, setShowFilterControls] = useState(false);

  const { id } = useParams();
  const isFirstTime = useRef(true);

  const INITIAL_PROJECT_PARAMS = {
    page: 1,
    per_page: 8,
    search: '',
    status_id: Object.keys(STATUS),
    sort_by: 'id',
    sort_order: 'DESC',
    team_id: [],
  };

  const {
    teams: { data: teams },
  } = useCompanyState();

  const [searchParams, setSearchParams] = useLocalStorage(
    `company-projects-params-${id}`,
    INITIAL_PROJECT_PARAMS,
  );
  const [teamOptions, setTeamOptions] = useState([]);

  const resetParams = () => {
    setSearchParams(INITIAL_PROJECT_PARAMS);
    sendUserInteraction('reset company projects filter');
  };

  const updateSearchParams = (newParams) => {
    sendUserInteraction('change company projects filter');
    const updatedParams = { ...searchParams, ...newParams, page: 1 };
    setSearchParams(updatedParams);
  };

  useEffect(() => {
    const selectedTeams = teams.map((team) => team.id);
    setTeamOptions(teams);
    setSearchParams((prevParams) => ({ ...prevParams, team_id: selectedTeams, company_id: id }));
  }, [teams, id, setSearchParams]);

  useEffect(() => {
    let debounceDelay = 500;
    if (isFirstTime.current) {
      debounceDelay = 0;
    }

    const fetchProjects = async () => {
      debounce(
        'fetchProjects',
        async () => {
          try {
            setIsLoading(true);
            const { data, last_page } = await getCompanyProjects({ ...searchParams, company_id: id });

            setProjects(data);
            if (searchParams.page > last_page) {
              setSearchParams({ ...searchParams, page: last_page });
            }
            setNumOfPages(last_page);
          } catch (e) {
            throw e;
          } finally {
            setIsLoading(false);
            isFirstTime.current = false;
          }
        },
        debounceDelay,
      );
    };

    fetchProjects();
  }, [debounce, id, searchParams, setSearchParams]);

  const handlePaginationChange = (e, { activePage }) => {
    sendUserInteraction('company projects pagination click');
    const updatedParams = { ...searchParams, page: activePage };
    setSearchParams(updatedParams);
  };

  const reloadProjects = async () => {
    debounce(
      'fetchProjects',
      async () => {
        try {
          setIsLoading(true);
          const { data, last_page } = await getCompanyProjects({ ...searchParams, company_id: id });

          setProjects(data);
          setNumOfPages(last_page);
        } catch (e) {
          throw e;
        } finally {
          setIsLoading(false);
        }
      },
      500,
    );
  };

  const projectCards = projects.map((project) => (
    <UpdateWrapper key={project.id} project={project} reloadProjects={reloadProjects}>
      <ProjectCard key={project.id} project={project} reloadProjects={reloadProjects} />
    </UpdateWrapper>
  ));

  const handleFilterClose = () => {
    sendUserInteraction('hide company projects filter sidebar');
    setShowFilterControls(false);
  };

  const filterTrigger = (props) => (
    <FilterButtonContainer {...props}>
      <Button actiontype="secondary" circular icon="filter" />
    </FilterButtonContainer>
  );

  return (
    <>
      <ProjectsLayout>
        {isLoading ? (
          <LoadingPane height={'260px'} />
        ) : (
          <>
            {projectCards.length > 0 ? (
              <CompanyProjectsWrapper>{projectCards}</CompanyProjectsWrapper>
            ) : (
              <Message
                text={'No projects found'}
                type="info"
                style={{ height: 'fit-content', marginTop: '5em' }}
              />
            )}
            {numOfPages > 1 && (
              <Pagination
                style={{ margin: 'auto' }}
                activePage={searchParams.page}
                totalPages={numOfPages}
                onPageChange={handlePaginationChange}
              />
            )}
          </>
        )}
        <ProjectFilter
          hideSidebar={handleFilterClose}
          isVisible={showFilterControls}
          nameQuery={searchParams.search}
          selectedStatuses={searchParams.status_id}
          selectedTeams={searchParams.team_id}
          sortParam={searchParams.sort_by}
          sortOrder={searchParams.sort_order}
          teamOptions={teamOptions}
          updateSearchParams={updateSearchParams}
          resetSearchParams={resetParams}
          trigger={filterTrigger}
        />
      </ProjectsLayout>
    </>
  );
};

export default withDebounce(CompanyProjects);
