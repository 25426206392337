import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { userStartedVendorOnboarding } from '../VendorOnboarding/VendorOnboarding.utils';
import Jobs from './Jobs';

/**
 * Check if user has vendor model or if user started onboarding
 * (checks for step one data in localStorage )
 * If user already has a vendor model
 */
const JobsRedirect = () => {
  const { vendor } = useSelector((state) => state.userStore.user);

  if (!vendor && !userStartedVendorOnboarding()) return <Redirect to="/" />;

  return <Jobs />;
};

export default JobsRedirect;
