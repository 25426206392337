export const parseFlag = (lang) => {
  if (lang?.code === 'cy-GB') {
    return 'gb-wls';
  }

  if (lang?.code === 'mg-MLG') {
    return 'mg';
  }

  if (lang?.code === 'US-HI') {
    return 'us';
  }

  if (lang?.code === 'yi-YD') {
    return 'yi';
  }

  if (lang?.code === 'ku-KMR' || lang?.code === 'ku-CKB') {
    return 'ku';
  }

  if (lang?.code === 'es-419') {
    return 'mx';
  }

  const langCodeSplit = lang?.code.split('-');
  return langCodeSplit[langCodeSplit.length - 1].toLowerCase();
};
