import React, { useEffect, useState, useMemo } from 'react';
import {
  redirectToStep,
  validateStepsInLocalStorage,
  stepsLabels,
  getVendorDataFromLocalStorage,
  clearLSForVendorOnboarding,
} from './VendorOnboarding.utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { postVendorData } from 'services/vendors';
import { setUser } from 'store/userSlice';
import { getUser } from 'services/auth';
import { hasAdminPermissions } from 'utils/user.utils';

// Hooks
import useStep from 'hooks/useSteps';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { Link } from 'react-router-dom';
import StepsTimeline from './StepsTimeline/StepsTimeline';
import BasicInfo from './Steps/BasicInfo/BasicInfo';
import LanguagePairs from './Steps/LanguagePairs/LanguagePairs';
import UploadAttachments from './Steps/UploadAttachments/UploadAttachments';
import Finish from './Steps/Finish/Finish';

// Assets
import taiaLogo from 'assets/taia_logo.svg';

// Styles
import './style.scss';

const Onboarding = () => {
  // React router hooks
  const history = useHistory();
  const { search } = useLocation();

  // Redux
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userStore.user);

  // Local state
  const [vendorProfileImage, setVendorProfileImage] = useState(user.avatar);
  const [userCV, setUserCV] = useState(null);
  const [userAdditionalFiles, setUserAdditionalFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValidate, setInitialValidate] = useState(true);

  // Translations hook
  const { t } = useTranslation();

  // Get stepNumber from query params
  const queryParams = new URLSearchParams(search);
  const stepNumber = +queryParams.get('step');

  // Step hook
  const stepsContext = useStep({
    steps: 4,
    initialStep: stepNumber ? stepNumber - 1 : 0,
    completedSteps: validateStepsInLocalStorage(),
  });

  // Memoized values
  const userCvFile = useMemo(() => ({ get: userCV, set: setUserCV }), [userCV]);

  // Methods
  const submitVendorData = async () => {
    setLoading(true);

    try {
      const data = {
        vendorProfileImage,
        userCV,
        userAdditionalFiles,
        data: getVendorDataFromLocalStorage(),
      };

      /**
       * In case an admin is going through onboarding, also pass their id
       */
      if (hasAdminPermissions(user.role)) {
        data.userID = user.id;
      }

      const response = await postVendorData(data);

      // Move steps to finished step
      if (response.status === 200) {
        stepsContext.navigation.go(3);
        // Clear local storage
        clearLSForVendorOnboarding();
      }
    } catch (error) {
      console.error(error);
      toast.error(t('common:serviceProvider.onboarding.errors.submitVendorData'));
    } finally {
      setLoading(false);
    }
  };

  // Steps data
  const steps = [
    {
      label: stepsLabels[0].label,
      icon: stepsLabels[0].icon,
      component: (
        <BasicInfo
          stepsContext={stepsContext}
          vendorProfileImage={{ set: setVendorProfileImage, get: vendorProfileImage }}
        />
      ),
    },
    {
      label: stepsLabels[1].label,
      icon: stepsLabels[1].icon,
      component: <LanguagePairs stepsContext={stepsContext} />,
    },
    {
      label: stepsLabels[2].label,
      icon: stepsLabels[2].icon,
      component: (
        <UploadAttachments
          stepsContext={stepsContext}
          userCV={userCvFile}
          userAdditionalFiles={{ get: userAdditionalFiles, set: setUserAdditionalFiles }}
          submitVendorData={submitVendorData}
          loading={loading}
        />
      ),
    },
    {
      component: <Finish />,
    },
  ];

  const { index, navigation } = stepsContext;

  /**
   * Run on step query param change
   * Update the steps accordingly
   */
  useEffect(() => {
    if (stepNumber - 1 !== index) {
      navigation.go(stepNumber - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepNumber]);

  // On steps index change
  useEffect(() => {
    // Already on the correct step, don't load different step
    if (stepNumber === index + 1 && loading) return;

    history.push(`/vendor-onboarding${index !== 0 ? `?step=${index + 1}` : ''}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  // On mount
  useEffect(() => {
    // Check if current step param is in completed steps
    if (!!stepNumber && stepNumber !== 1) {
      const stepIndex = stepNumber - 1;
      const currentValidStepsInLS = validateStepsInLocalStorage();
      const isStepValidOnLoad = currentValidStepsInLS.includes(stepIndex);

      // Reusable helper functions
      const goToStep = (idx) => redirectToStep({ navigation, history, idx });

      /**
       * Check if prev index is valid
       */
      const prevValid = (idx) => {
        if (idx === 0) return idx;

        // Prev step validation
        if (currentValidStepsInLS.includes(idx - 1)) {
          return idx;
        }

        return prevValid(idx - 1);
      };

      // Step is not valid, check previous steps
      if (!isStepValidOnLoad) {
        goToStep(prevValid(stepIndex));
      }
    }

    setLoading(false);
    setInitialValidate(false);

    return async () => {
      // Sync user on unmount
      const { data } = await getUser();

      dispatch(setUser({ user: data }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render variables
  const stepsWithoutFinal = steps.slice(0, steps.length - 1);
  const isFinished = index + 1 === steps.length;

  return (
    <main className="vendor-onboarding-main">
      <header>
        <div className="content">
          <div className="logo">
            <Link disabled={loading} to="/">
              <img src={taiaLogo} alt="" />
            </Link>
          </div>

          <StepsTimeline steps={stepsWithoutFinal} stepsContext={stepsContext} disableAll={isFinished} />

          <div className="help">
            <p>
              {t('common:serviceProvider.onboarding.help.text')}
              <Link to="/support">{t('common:serviceProvider.onboarding.help.linkText')}</Link>
            </p>
          </div>
        </div>
      </header>

      {!initialValidate && steps[index].component}
    </main>
  );
};

export default Onboarding;
