import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button/Button';
import i18n from 'i18next';
import { getBillingDetails } from '../../../../services/billing';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { generateProformaPdf } from 'components/Project/OrderProject/ProjectDetails/Proforma';

const ProformaButton = ({ project, pricesData, verified }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userStore.user);
  const countries = useSelector((state) => state.classifiersStore.countries);

  const [loading, setLoading] = useState(false);

  const downloadProforma = async () => {
    try {
      setLoading(true);
      // ghetto fix for missing billing details
      const billing_details = await getBillingDetails(project.billing_detail_id);

      // TODO move this to BE, please ???
      // NEVAHHH
      const { disc_coupon, is_delivery, id, name, reg_disc_ratio, spec_disc_ratio, vat } = project;
      const {
        coupon_discount_value,
        delivery_price,
        delivery_time,
        gross_price,
        jobs,
        net_price,
        reg_discount_value,
        special_discount_value,
        vat_total,
      } = pricesData;

      generateProformaPdf({
        billingDetails: billing_details,
        countries,
        currencyData: project.currency,
        deliveryOption: is_delivery || 50,
        deliveryPrice: delivery_price,
        deliveryDate: delivery_time,
        discountCouponPercentage: disc_coupon,
        discountCouponTotal: coupon_discount_value,
        grossPrice: gross_price,
        i18n,
        jobs,
        name,
        netPrice: net_price,
        projectId: id,
        projectName: name,
        regionalDiscountRatio: reg_disc_ratio,
        regionalDiscountTotal: reg_discount_value,
        specialDiscountRatio: spec_disc_ratio,
        specialDiscountTotal: special_discount_value,
        t,
        userRole: user.role,
        vatRate: vat,
        vatTotal: vat_total,
      });
    } catch (e) {
      Swal.fire({
        title: t('common:errors.heading'),
        text: t('common:errors.pleaseRefresh'),
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      actiontype={verified ? 'secondary' : 'primary'}
      disabled={loading}
      loading={loading}
      fluid
      onClick={downloadProforma}
    >
      {verified
        ? t('common:checkout.orderSummary.downloadQuote')
        : t('common:checkout.orderSummary.downloadProforma')}
    </Button>
  );
};

export default ProformaButton;
