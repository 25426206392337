import styled from 'styled-components';

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 24px;
`;

export const ProductWrapper = styled.div`
  padding: 20px;

  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey};

  display: grid;
  grid-template-columns: 53px auto;
  grid-column-gap: 24px;
  align-items: center;

  .product-icon {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.grey};

    display: grid;
    place-content: center;
    img {
      width: 25px;
      height: 25px;
    }
  }

  .product-details {
    display: grid;
    justify-content: space-between;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    &.project {
      grid-template-columns: 3fr 1fr;
    }

    .detail-field {
      margin: 10px;

      &.align-bottom {
        align-self: flex-end;
      }

      .detail-header {
        color: ${({ theme }) => theme.colors.grey};
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 5px;
      }

      .detail-content {
        font-size: 22px;
        font-weight: bold;
        line-height: 2rem;
      }

      .detail-price {
        font-size: 24px;
        font-weight: bold;
        text-align: right;
        line-height: 2rem;

        margin-top: 24px;

        .interval {
          font-size: 14px;
        }
      }

      .detail-subtext {
        color: ${({ theme }) => theme.colors.grey};
        font-size: 12px;
      }
    }
    .detail-field.disabled {
      margin: 10px;

      color: #c4c4c4;

      .detail-header {
        color: ${({ theme }) => theme.colors.grey};
      }

      .detail-subtext {
        color: ${({ theme }) => theme.colors.grey};
        font-size: 12px;
      }
    }
  }
`;

export const QuantityContainer = styled.div`
  display: inline-flex;
  align-items: center;

  .quantity-icon {
    height: 20px;
    width: 20px;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    border-radius: 50%;

    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    transition: border-color ease-in-out 0.4s;

    &:hover {
      border-color: ${({ theme }) => theme.colors.blue};
    }

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    display: grid;
    place-items: center;
    img {
      height: 15px;
      width: 15px;
    }
  }
`;

export const SwapLineWrapper = styled.div`
  position: relative;
  hr {
    background: #c4c4c4;
    border: none;
    height: 1px;
    width: 100%;
  }
  .text {
    background-color: #f6f6f6;
    color: #1caac5;
    font-family: 'DM Sans', sans-serif;
    font-size: 11px;
    font-weight: 400;
    left: 50%;
    padding: 7px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
