import styled from 'styled-components';

import { Flag, Label } from 'semantic-ui-react';

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const HeadContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
`;

export const NameContainer = styled.div`
  padding: 0 1rem;
  flex-grow: 1;
`;

export const LabelContainer = styled.div`
  margin-top: 10px;
`;

export const UserLabel = styled(Label)`
  margin-bottom: 5px !important;
`;

export const StyledFlag = styled(Flag)`
  margin: 0 !important;
`;

export const SubscriptionLabel = styled(Label)`
  cursor: pointer;
`;
