import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { Input, PasswordInput } from '../common/Input/Input';
import Loader from '../common/Loader/Loader';
import { Checkbox } from '../common/Checkbox/Checkbox';
import Message from '../common/Message/Message';
import PasswordValidator from 'components/common/PasswordValidator/PasswordValidator';
import { StyledButton, StyledFormError, StyledHeader } from '../common.styles';
import { FlavorText, LoaderContainer } from './InviteSignUp.styles';
import { StyledForm, StyledAnchor, CheckboxContainer } from '../SignUp/SignUpForm.styles';
import { InnerContainer, StyledCard } from 'components/SignIn/SignIn.styles';

import { getInitialUserData } from '../../utils/initialLoad';
import { getInviteToken, postRegister } from '../../services/auth';
import { toast } from 'react-toastify';

import { sendGaVirtual, sendUserInteraction } from '../../utils/tagManager.utils';
import { useRecaptchaV3 } from 'hooks/useRecaptcha';

const InviteSignUp = () => {
  const { token } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true); // component loads token data on mount
  const [loadError, setLoadError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState({
    name: null,
    password: null,
    termsOfUse: null,
  });
  const [acceptTOS, setAcceptTOS] = useState(false);
  const history = useHistory();
  const { generateToken } = useRecaptchaV3();

  useEffect(() => {
    const getTokenData = async () => {
      try {
        const tokenDataResponse = await getInviteToken(token);
        setTokenData(tokenDataResponse);
      } catch (e) {
        setLoadError(e);
      } finally {
        setIsLoading(false);
      }
    };

    getTokenData();
  }, [token]);

  useEffect(() => {
    // empty form errors object
    const formErrors = {
      name: null,
      termsOfUse: null,
    };

    if (isEmpty(name) || name.trim() === '') {
      formErrors.name = 'common:signUp.errors.name.missing';
    }

    if (!acceptTOS) {
      formErrors.termsOfUse = 'common:signUp.errors.termsOfUse.missing';
    }

    setFormErrors((prev) => ({ ...prev, ...formErrors }));
  }, [acceptTOS, name]);

  const setPasswordError = useCallback((isValid) => {
    setFormErrors((prev) => ({ ...prev, password: !isValid }));
  }, []);

  const handleNameChange = (e) => {
    e.preventDefault();
    if (!e.target.value) {
      setName('');
    }
    setName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleTOSClick = () => {
    setAcceptTOS(!acceptTOS);
  };

  const canSubmit = () => {
    return !formErrors.name && !formErrors.password && !formErrors.termsOfUse;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(false);
    setIsSubmitting(true);
    try {
      sendUserInteraction('clicked submit sign up');
      const captcha = await generateToken();
      await postRegister({
        email: tokenData.email,
        invite_token: token,
        name,
        password,
        captcha,
      });

      sendGaVirtual({
        page: 'registration-completed-invite',
        userData: {
          email: tokenData.email,
          name,
        },
      });

      await getInitialUserData();
      setSubmitSuccess(true);
      toast.success(t('common:toasts.inviteAccepted.success'));
      history.push('/');
    } catch (e) {
      if (!e.response) {
        setSubmitError([t('common:errors.noConnection')]);
      } else if (e.response.status === 422) {
        const errorData = e.response.data.error;

        const errorMessages = Object.values(errorData).reduce((acc, currentValue) => {
          return [...acc, ...currentValue];
        }, []);

        setSubmitError(errorMessages);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const getInviteText = () => {
    return `${t('common:signUp.invite.flavorText')}: ${
      tokenData.invitable.name ? tokenData.invitable.name : tokenData.invitable.company_name
    }.`;
  };

  return (
    <StyledCard>
      <InnerContainer>
        <StyledHeader>{t('common:signUp.signUp')}</StyledHeader>
        {isLoading ? (
          <LoaderContainer>
            <Loader active />
          </LoaderContainer>
        ) : loadError ? (
          <Message text={t('common:signUp.invite.tokenError')} type="error" style={{ textAlign: 'left' }} />
        ) : (
          <>
            {submitError && <Message text={submitError} type="error" style={{ textAlign: 'left' }} />}
            {submitSuccess ? (
              <Message text={t('common:signUp.success.checkInbox')} type="info" />
            ) : (
              <StyledForm onSubmit={handleSubmit}>
                <FlavorText>{getInviteText()}</FlavorText>

                <Input
                  disabled
                  type="email"
                  name="email"
                  value={tokenData.email}
                  label={t('common:signUp.labels.yourEmail')}
                />
                <Input
                  type="text"
                  label={t('common:signUp.labels.yourFullName')}
                  name="name"
                  value={name}
                  autoComplete="off" // browser tries to fill it with email
                  onChange={handleNameChange}
                  error={name && formErrors.name}
                />
                {name && formErrors.name && <StyledFormError>{t(formErrors.name)}</StyledFormError>}

                <div>
                  <PasswordInput
                    label={t('common:signUp.labels.password')}
                    name="password"
                    onChange={handlePasswordChange}
                    value={password}
                    error={password && formErrors.password}
                  />
                  <PasswordValidator password={password} setIsValid={setPasswordError} />
                </div>

                <CheckboxContainer>
                  <Checkbox
                    name="termsOfUse"
                    checked={acceptTOS}
                    onChange={handleTOSClick}
                    error={!!formErrors.termsOfUse}
                    label={
                      <label>
                        <StyledAnchor
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://taia.io/terms-and-conditions/"
                        >
                          {t('common:signUp.termsText')}
                        </StyledAnchor>
                      </label>
                    }
                  />
                </CheckboxContainer>
                <StyledButton
                  loading={isSubmitting}
                  disabled={!canSubmit()}
                  actiontype="submit"
                  big
                  floated="right"
                  marginBottom="0"
                >
                  {t('common:signUp.createAnAccount')}
                </StyledButton>
              </StyledForm>
            )}
          </>
        )}
      </InnerContainer>
    </StyledCard>
  );
};

export default InviteSignUp;
