import React from 'react';
import { Popup } from 'semantic-ui-react';
import { zindexPopperModifier } from 'constants/general';

// Components
import CircularProgress from 'components/common/CircularProgress/CircularProgress';
import { Icon } from 'components/common/Icon/Icon';

// Styles
import './style.scss';

const PercentageGraph = ({ title, values }) => {
  if (!values || values.length < 2) return null;

  const firstValue = values[0];
  const secondValue = values[1];

  const wordPercentOff = Math.floor((1 - secondValue.value / firstValue.value) * 100);

  return (
    <div className="percentage-graph">
      <div className="legend">
        <strong>{title}</strong>
        <ul>
          {values.map(({ value, text, description }, idx) => (
            <li className="color-label" key={`percentage-graph-item-${idx}`}>
              <span></span>
              <p>
                <span>{text}:</span> <strong>{value}</strong>
              </p>
              {description && (
                <Popup
                  position="bottom left"
                  trigger={<Icon className="description-trigger" name="info-circle" />}
                  content={<div>{description}</div>}
                  popperModifiers={[zindexPopperModifier]}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="graph">
        <CircularProgress progress={100 - wordPercentOff} />
      </div>
    </div>
  );
};

export default PercentageGraph;
