import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledLink, WelcomeHeading, WelcomeSubHeading } from './UserAuth.styles';
import SignInForm from '../../SignIn/SignInForm';
import SignUpForm from '../../SignUp/SignUpForm';
import { StyledCard, InnerContainer } from 'components/SignIn/SignIn.styles';
import { sendUserInteraction } from 'utils/tagManager.utils';
import RecaptchaDisclaimer from 'components/SignIn/RecaptchaDisclaimer';
import MetaTag from 'components/MetaTag/MetaTag';

const UserAuth = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const { t } = useTranslation();

  const toggleIsSignUp = (e) => {
    setIsSignUp(!isSignUp);
  };

  return (
    <>
      <MetaTag name="robots" content="noindex, nofollow" />
      <StyledCard>
        <InnerContainer>
          <WelcomeHeading>{t('common:catapult:userAuth.welcome')}</WelcomeHeading>
          <WelcomeSubHeading>
            {`${t('common:catapult:userAuth.heading1')} `}
            {!isSignUp ? (
              <StyledLink
                onClick={() => {
                  sendUserInteraction('clicked sign up');
                  toggleIsSignUp();
                }}
              >
                {t('common:catapult:userAuth.createAccount')}
              </StyledLink>
            ) : (
              t('common:catapult:userAuth.createAccount')
            )}
            {` ${t('common:catapult:userAuth.heading2')} `}
            {isSignUp ? (
              <StyledLink
                onClick={() => {
                  sendUserInteraction('clicked sign in');
                  toggleIsSignUp();
                }}
              >
                {t('common:catapult:userAuth.singIn')}
              </StyledLink>
            ) : (
              t('common:catapult:userAuth.singIn')
            )}
            {` ${t('common:catapult:userAuth.heading3')} `}
          </WelcomeSubHeading>
          {isSignUp ? <SignUpForm catapultUser /> : <SignInForm />}
          <RecaptchaDisclaimer />
        </InnerContainer>
      </StyledCard>
    </>
  );
};

export default UserAuth;
