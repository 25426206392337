import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  width: 14px;
  height: auto;

  .checkmark {
    stroke-dasharray: 640;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: draw-around 1s ease-out;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  @keyframes draw-around {
    0% {
      stroke-dashoffset: 640;
    }
    10% {
      stroke-dashoffset: 640;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

export const AnimatedCheckmark = ({ color, ...rest }) => {
  return (
    <StyledSvg width="245px" height="173px" viewBox="0 0 245 173" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polyline
          className="checkmark"
          stroke={color || 'black'}
          strokeWidth="35"
          points="21.640625 83.7607422 86.2539062 155.663086 226.87168 18.71328125"
        />
      </g>
    </StyledSvg>
  );
};
