import styled from 'styled-components';
import TextArea from '../../../common/TextArea/TextArea';

export const StyledTextArea = styled(TextArea)`
  flex: 1;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.87);
  outline: 0;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  border-radius: 0.28571429rem;
  font-family: 'Open Sans', 'Open Sans,Helvetica Neue', Arial, Helvetica, sans-serif;
  transition: all 0.1s ease;
  font-weight: 400;
  &:focus {
    color: rgba(0, 0, 0, 0.95);
    border-color: #85b7d9;
    &::placeholder {
      color: #868686;
    }
  }
  &::placeholder {
    color: lightgray;
  }
`;
