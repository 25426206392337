import styled from 'styled-components';

export const DataRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledLabel = styled.label`
  color: rgba(0, 0, 0, 0.57);
  margin-right: 0.5em;
`;
