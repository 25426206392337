import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MainContainer } from 'components/common.styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Loader, Modal } from 'semantic-ui-react';
import './style.scss';
import { Icon } from 'components/common/Icon/Icon';
import { Popup } from 'components/common/Popup/Popup';
import { parseAPIDateStringToDate } from 'utils/date.utils';
import Button from 'components/common/Button/Button';
import { getCalendar } from 'services/calendar';
import { postCalendar } from 'services/calendar';
import { useDebounce } from 'hoc/debouncer';

const initialDate = () => {
  const from = new Date();
  const to = new Date();

  from.setDate(-10);
  to.setMonth(to.getMonth() + 1, 10);

  return { from, to };
};

const AdminProjectCalendar = () => {
  const [events, setEvents] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [loadingDates, setLoadingDates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editDate, setEditDate] = useState(new Date());
  const [dateRange, setDateRange] = useState(initialDate());
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const { debounce } = useDebounce();

  useEffect(() => {
    const fetchCalendar = async () => {
      try {
        setLoading(true);

        const response = await getCalendar(dateRange);

        const disabledDates = response
          .filter((event) => event.enabled !== 1)
          .map((event) =>
            parseAPIDateStringToDate({ dateString: event.date, dateStringFormat: 'MM/dd/yyyy' }),
          );
        setDisabledDates(disabledDates);

        const newEvents = response
          .filter((event) => event.project_count > 0)
          .map((event) => {
            return {
              title: `${event.project_count} projects`,
              date: parseAPIDateStringToDate({
                dateString: event.date,
                dateStringFormat: 'MM/dd/yyyy',
              }).setHours(18),
              allDay: true,
            };
          });
        setEvents(newEvents);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    debounce('fetching-calendar', () => fetchCalendar(), 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const getDayClassNames = (arg) => {
    if (disabledDates.find((date) => date.getTime() === arg.date.getTime())) {
      return 'not-accepting';
    }

    if (arg.dow === 6 || arg.dow === 0) {
      return 'weekend';
    }
  };

  const getDayHeaderNames = (arg) => {
    const day = arg.date.getDay();
    if (day === 6 || day === 0) {
      return 'weekend';
    }
  };

  const dayContent = (day) => {
    return (
      <div className="day-entry">
        <div className="day-number">{day.dayNumberText}</div>
        <div className="day-icon">
          {loadingDates.find((date) => date.getTime() === day.date.getTime()) ? (
            <Loader active inline size="tiny" />
          ) : disabledDates.find((date) => date.getTime() === day.date.getTime()) ? (
            <Popup
              content={t('common:admin.calendar.notAcceptingProjects')}
              position="top-center"
              trigger={<Icon name="times" />}
            />
          ) : day.dow !== 0 && day.dow !== 6 ? (
            <Popup
              content={t('common:admin.calendar.acceptingProjects')}
              position="top-center"
              trigger={<Icon name="check" />}
            />
          ) : (
            <Popup
              content={t('common:admin.calendar.acceptingFast')}
              position="top-center"
              trigger={<Icon name="minus" />}
            />
          )}
        </div>
      </div>
    );
  };

  const handleDateClick = (arg) => {
    setEditDate(arg.date);
    setModalOpen(true);
  };

  const handleDateConfirm = async () => {
    setModalOpen(false);

    try {
      const newLoading = [...loadingDates, editDate];
      setLoadingDates(newLoading);
      await postCalendar({ date: editDate });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingDates(loadingDates.filter((date) => date.getTime() !== editDate.getTime()));
    }

    if (disabledDates.find((date) => date.getTime() === editDate.getTime())) {
      setDisabledDates(disabledDates.filter((date) => date.getTime() !== editDate.getTime()));
    } else {
      const newDisabledDates = [...disabledDates, editDate];
      setDisabledDates(newDisabledDates);
    }
  };

  const handleRangeSet = (dateInfo) => {
    setDateRange({ from: dateInfo.start, to: dateInfo.end });
  };

  return (
    <>
      <MainContainer className="admin-project-calendar-container">
        <div className="admin-project-calendar">
          <Header as="h1">{t('common:admin.dashboard.calendar')}</Header>
          <div className="calender-container">
            {loading ? (
              <div className="calendar-loader">
                <Loader active></Loader>
              </div>
            ) : null}
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={events}
              dayCellClassNames={getDayClassNames}
              dayCellContent={dayContent}
              dayHeaderClassNames={getDayHeaderNames}
              dateClick={handleDateClick}
              datesSet={handleRangeSet}
              firstDay={1}
              eventDisplay={'list-item'}
            />
          </div>
        </div>
      </MainContainer>
      <ConfirmModal
        open={modalOpen}
        onOpenChange={(open) => setModalOpen(open)}
        disabledDates={disabledDates}
        date={editDate}
        onContinue={handleDateConfirm}
      ></ConfirmModal>
    </>
  );
};

const ConfirmModal = ({ date = new Date(), disabledDates = [], open, onOpenChange, onContinue }) => {
  const { t } = useTranslation();
  const isEnabling = disabledDates.find((dt) => dt.getTime() === date.getTime());

  return (
    <Modal size="mini" onClose={() => onOpenChange(false)} onOpen={() => onOpenChange(true)} open={open}>
      <Modal.Header>{t('common:admin.calendar.confirmAction')}</Modal.Header>
      <Modal.Content>
        {t(
          isEnabling
            ? 'common:admin.calendar.enableConfirmation'
            : 'common:admin.calendar.disableConfirmation',
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={() => onOpenChange(false)}>
          {t('common:cancel')}
        </Button>
        <Button
          actiontype="submit"
          onClick={() => {
            onContinue();
          }}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AdminProjectCalendar;
