import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../common/Button/Button';

import 'components/Checkout/NotificationPage.scss';

const PaymentSuccessful = ({ type, projectId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <section className="container">
      <h1 className="heading-1">{t('common:paymentFailed.paymentSuccessful')}</h1>
      <p className="text last-text">{t('common:paymentFailed.paymentSuccessDescription')}</p>
      <Button
        actiontype="primary"
        onClick={() => {
          history.push('/');
        }}
      >
        {t('common:checkout.backToHomepage')}
      </Button>
    </section>
  );
};

export default PaymentSuccessful;
