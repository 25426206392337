import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Modal } from 'semantic-ui-react';

import EditFormControls from './EditFormControls';
import EmailInput from './EmailInput';
import PaymentInputs from './PaymentInputs';
import UserAddressInput from './UserAddressInput';
import UserDiscountInputs from './UserDiscountsInputs';
import VatNotice from './VatNotice';

import { setBillingDetails, setUser } from '../../../../store/userSlice';
import {
  patchCustomer,
  postBillingCreate,
  postBillingUpdate,
  postCustomer,
} from '../../../../services/billing';
import { getUser } from '../../../../services/auth';
import { currencyByIdSelector } from 'store/selectors';

const UserEditForm = ({ closeModal, initialBillingDetails = null, updateBilling = null, userId = null }) => {
  const [billingDetails, setStateBillingDetails] = useState(
    initialBillingDetails ? initialBillingDetails : {},
  );
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const {
    billing_group_id,
    company_name,
    company_street,
    country_id,
    currency_id,
    discount,
    euro_balance,
    user_email,
    line1,
    line2,
    city,
    state,
    postal_code,
    stripe_id,
  } = billingDetails;

  const updateProperty = ({ name, value }) => {
    const newBillingDetails = {
      ...billingDetails,
      [name]: value,
    };
    setStateBillingDetails(newBillingDetails);
  };

  const getCurrencyById = useSelector(currencyByIdSelector);
  const currencyData = getCurrencyById(currency_id);

  const updateBillingDetails = async () => {
    setIsSubmitting(true);

    const euroBalace = euro_balance ? euro_balance / currencyData.conversion_rate : euro_balance;

    const payloadBillingDetails = {
      ...billingDetails,
      company_street: `${line1}, ${postal_code} ${city}`,
      euro_balance: euroBalace,
    };

    try {
      // update or create billing details
      if (!initialBillingDetails) {
        // if we don't have initial billing details, we should create new ones

        // if a userId is passed an admin is creating billing details for a different user
        if (userId) {
          payloadBillingDetails.user_id = userId;
        }

        const newBillingDetails = await postBillingCreate(payloadBillingDetails);

        // if we are updating billing details for current logged in user we also need to update global billing details state
        if (!userId) {
          dispatch(setBillingDetails({ billingDetails: newBillingDetails }));
        }

        await postCustomer({
          billing_id: newBillingDetails.id,
          line1: newBillingDetails.line1,
          line2: newBillingDetails.line2,
          postal_code: newBillingDetails.postal_code,
          city: newBillingDetails.city,
          state: newBillingDetails.state,
          name: newBillingDetails.company_name,
          tax_exempt:
            newBillingDetails.vat_rate !== null || newBillingDetails.digital_vat_rate !== null
              ? 'none'
              : 'exempt',
        });
      } else {
        // if we have initial billing details, we should update existing billing details
        const newBillingDetails = await postBillingUpdate(payloadBillingDetails.id, payloadBillingDetails);

        // if we are updating billing details for current logged in user we also need to update global billing details state
        if (!userId) {
          dispatch(setBillingDetails({ billingDetails: newBillingDetails }));
        }

        if (newBillingDetails.stripe_id) {
          await patchCustomer({
            billing_id: newBillingDetails.id,
            line1: newBillingDetails.line1,
            line2: newBillingDetails.line2,
            postal_code: newBillingDetails.postal_code,
            city: newBillingDetails.city,
            state: newBillingDetails.state,
            name: newBillingDetails.company_name,
            tax_exempt:
              newBillingDetails.vat_rate !== null || newBillingDetails.digital_vat_rate !== null
                ? 'none'
                : 'exempt',
          });
        } else {
          await postCustomer({
            billing_id: newBillingDetails.id,
            line1: newBillingDetails.line1,
            line2: newBillingDetails.line2,
            postal_code: newBillingDetails.postal_code,
            city: newBillingDetails.city,
            state: newBillingDetails.state,
            name: newBillingDetails.company_name,
            tax_exempt:
              newBillingDetails.vat_rate !== null || newBillingDetails.digital_vat_rate !== null
                ? 'none'
                : 'exempt',
          });
        }
      }

      // toast that update succeded
      toast.success(t('common:toasts.billing.success'));

      // if we are updating billing details for current logged in user we also need to update global user details state
      if (!userId) {
        const userInfo = await getUser();
        dispatch(setUser({ user: userInfo.data }));
      }
      // close modal
      setIsSubmitting(false);
      !!updateBilling && updateBilling();
      closeModal();
    } catch (e) {
      // toast that update failed
      toast.error(t('common:toasts.billing.error'));
      setIsSubmitting(false);
    }
  };

  const isFormValid = () => {
    return (
      !!company_name && !!line1 && !!city && !!postal_code && !!country_id && !!currency_id && !!user_email
    );
  };

  return (
    <Modal closeOnDimmerClick onClose={closeModal} open size="small">
      <Modal.Content style={{ padding: '32px 52px 32px 32px' }}>
        <UserAddressInput
          company_name={company_name}
          company_street={company_street}
          city={city}
          line1={line1}
          line2={line2}
          state={state}
          postalCode={postal_code}
          country_id={country_id}
          updateProperty={updateProperty}
        />
        <VatNotice paysVat={true} />
        <EmailInput user_email={user_email} updateProperty={updateProperty} />
        <PaymentInputs
          customer={stripe_id}
          currency_id={currency_id}
          euro_balance={euro_balance}
          updateProperty={updateProperty}
        />
        <UserDiscountInputs
          billing_group_id={billing_group_id}
          country_id={country_id}
          discount={discount}
          updateProperty={updateProperty}
        />

        <EditFormControls
          canSubmit={isFormValid()}
          isSubmitting={isSubmitting}
          onCancel={closeModal}
          onConfirm={updateBillingDetails}
        />
      </Modal.Content>
    </Modal>
  );
};

export default UserEditForm;
