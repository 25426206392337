import React from 'react';

// Components
import CategoryCard from '../CategoryCard/CategoryCard';

// Assets
import generalSrc from '../assets/general.png';
import usingTheAppSrc from '../assets/using-the-app.png';
import catapultSrc from '../assets/catapult.png';

// Styles
import './style.scss';
import { useTranslation } from 'react-i18next';
import LINKS from 'constants/links';

/**
 * Renders a list of category cards
 */
const Categories = () => {
  // Hooks
  const { t } = useTranslation();

  // Categories to display
  const categories = [
    {
      imgSrc: generalSrc,
      title: t('common:support.general'),
      link: LINKS.support.general,
    },
    {
      imgSrc: usingTheAppSrc,
      title: t('common:support.usingTheApp'),
      link: LINKS.support.usingTheApp,
    },
    {
      imgSrc: catapultSrc,
      title: t('common:support.catapult'),
      link: LINKS.support.catapult,
    },
  ];

  return (
    <ul className="categories-list">
      {categories.map((category, idx) => (
        <li key={`${category.name} ${idx}`}>
          <CategoryCard {...category} />
        </li>
      ))}
    </ul>
  );
};

export default Categories;
