import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';
import SectionDescription from '../../../SectionDescription/SectionDescription';
import {
  VENDOR_EXPERIENCE_INTERMEDIATE,
  VENDOR_EXPERIENCE_BEGINNER,
  VENDOR_EXPERIENCE_ADVANCED,
} from 'constants/vendors';
import TextArea from '../../../TextArea/TextArea';
import { SelectDropdown } from '../../../Dropdown/SelectDropdown';
import FileUploadMultiple from '../../../FileUpload/FileUploadMultiple/FileUploadMultiple';
import FileUploadSingle from '../../../FileUpload/FileUploadSingle/FileUploadSingle';
import { Input } from '../../../Input/Input';
import { cvUploadOptions, additionalFilesUploadOptions } from 'constants/vendors';

// Styles
import './style.scss';

// Constants
const defaultFormData = {
  education: '',
  linkedin: '',
  experience: '',
};

const ProfessionalExperienceForm = ({ data, onChange = () => {}, handleFiles = true }) => {
  const [formData, setFormData] = useState(data ?? defaultFormData);

  // Hooks
  const { t } = useTranslation();

  // Methods
  const handleInputChange = useCallback(
    (_, { value, name }) => {
      setFormData((c) => ({
        ...c,
        [name]: value,
      }));
    },
    [setFormData],
  );

  useEffect(() => {
    onChange(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  // Texts
  const experienceLevelOptions = [
    {
      key: 1,
      value: VENDOR_EXPERIENCE_BEGINNER,
      text: t('common:serviceProvider.forms.inputs.workExperience.options.beginner'),
    },
    {
      key: 2,
      value: VENDOR_EXPERIENCE_INTERMEDIATE,
      text: t('common:serviceProvider.forms.inputs.workExperience.options.intermediate'),
    },
    {
      key: 3,
      value: VENDOR_EXPERIENCE_ADVANCED,
      text: t('common:serviceProvider.forms.inputs.workExperience.options.advanced'),
    },
  ];

  /**
   * Take array of input names (same key is used in i18n under serviceProvider.onboarding.steps.stepOne.inputs)
   * Map over it, for each name create key value pair:
   * [
   *    inputName,
   *    {
   *      title: '',
   *      description: '',
   *      placeholder: '',
   *    }
   * ]
   * and create an object using Object.fromEntries
   */
  const inputsTexts = Object.fromEntries(
    ['education', 'linkedin', 'workExperience', 'cv', 'additionalFiles'].map((inputName) => [
      inputName,
      {
        title: t(`common:serviceProvider.forms.inputs.${inputName}.title`),
        description: t(`common:serviceProvider.forms.inputs.${inputName}.description`),
        placeholder: t(`common:serviceProvider.forms.inputs.${inputName}.placeholder`),
      },
    ]),
  );

  return (
    <div className="vendor-prof-exp-form">
      <SectionDescription
        title={`${inputsTexts.education.title} *`}
        description={inputsTexts.education.description}
        content={
          <TextArea
            name="education"
            onChange={handleInputChange}
            value={formData.education}
            placeholder={inputsTexts.education.placeholder}
          />
        }
      />

      <Divider />

      <SectionDescription
        title={inputsTexts.linkedin.title}
        description={inputsTexts.linkedin.description}
        content={
          <Input
            name="linkedin"
            onChange={handleInputChange}
            value={formData.linkedin}
            placeholder={inputsTexts.linkedin.placeholder}
          />
        }
      />

      <Divider />

      <SectionDescription
        title={`${inputsTexts.workExperience.title} *`}
        description={inputsTexts.workExperience.description}
        content={
          <SelectDropdown
            name="experience"
            value={formData.experience}
            onChange={handleInputChange}
            placeholder={inputsTexts.workExperience.placeholder}
            options={experienceLevelOptions}
            selection
            search
            emptyState={t(`common:serviceProvider.forms.inputs.workExperience.empty`)}
            fluid
            canReset={false}
          />
        }
      />

      <Divider />

      {handleFiles && (
        <>
          <SectionDescription
            title={`${inputsTexts.cv.title} *`}
            description={inputsTexts.cv.description}
            content={
              <div className="cv-upload">
                <FileUploadSingle options={cvUploadOptions} onChange={handleInputChange} name="cv" />
                <p>
                  {t('common:serviceProvider.forms.inputs.cv.support')}
                  {cvUploadOptions.accept}. {t('common:serviceProvider.forms.inputs.cv.maxSize')} 3 MB.
                </p>
              </div>
            }
          />

          <Divider />

          <SectionDescription
            title={inputsTexts.additionalFiles.title}
            description={inputsTexts.cv.description}
            content={
              <div className="additional-upload">
                <FileUploadMultiple
                  options={additionalFilesUploadOptions}
                  onChange={handleInputChange}
                  name="additionalFiles"
                />
                <p>
                  {t('common:serviceProvider.forms.inputs.additionalFiles.support')}
                  {additionalFilesUploadOptions.accept}.{' '}
                  {t('common:serviceProvider.forms.inputs.additionalFiles.maxSize')} 10 MB.
                </p>
              </div>
            }
          />

          <Divider />
        </>
      )}
    </div>
  );
};

export default ProfessionalExperienceForm;
