import React from 'react';
import { Container, PageNumber, Arrow } from './Pagination.styles';
import { Icon } from '../Icon/Icon';

const range = (start, end) => [...Array(end - start + 1).keys()].map((el) => el + start);
const PAGE_NEIGHBORS = 1;
const MAX_WIDTH = 5 + 2 * PAGE_NEIGHBORS;
const COMPRESSED = 'COMPRESSED';

const Pagination = ({ totalPages, activePage, onPageChange, className, style, ...rest }) => {
  const getPageNumbers = () => {
    if (totalPages > MAX_WIDTH) {
      let startPage = Math.max(2, activePage - PAGE_NEIGHBORS - 1);
      if (activePage - PAGE_NEIGHBORS > 3) startPage++;

      let endPage = Math.min(totalPages - 1, activePage + PAGE_NEIGHBORS + 1);
      if (activePage + PAGE_NEIGHBORS < totalPages - 2) endPage--;

      let pages = range(startPage, endPage);
      const totalNumbers = PAGE_NEIGHBORS * 2 + 3;
      /**
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const spillOffset = totalNumbers - (pages.length + 1);
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;

      switch (true) {
        // handle: (1) ... {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [COMPRESSED, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} ... (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, COMPRESSED];
          break;
        }

        // handle: (1) ... {4 5} [6] {7 8} ... (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [COMPRESSED, ...pages, COMPRESSED];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const gotoPage = (e, page) => {
    if (page !== activePage) onPageChange(e, { activePage: page });
  };

  const pages = getPageNumbers();
  return (
    <Container className={className} style={style} {...rest}>
      <Arrow
        onClick={(e) => gotoPage(e, activePage - 1)}
        className={activePage === 1 ? 'disabled' : ''}
        disabled={activePage === 1}
      >
        <Icon name="arrow-left" />
      </Arrow>
      {pages.map((page, idx) =>
        page === COMPRESSED ? (
          <PageNumber key={idx}>...</PageNumber>
        ) : (
          <PageNumber
            key={idx}
            onClick={(e) => gotoPage(e, page)}
            className={page === activePage ? 'active' : ''}
          >
            {page}
          </PageNumber>
        ),
      )}
      <Arrow
        onClick={(e) => gotoPage(e, activePage + 1)}
        className={totalPages === activePage ? 'disabled' : ''}
        disabled={totalPages === activePage}
      >
        <Icon name="arrow-right" />
      </Arrow>
    </Container>
  );
};

export default Pagination;
