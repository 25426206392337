import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import NewCouponModal from './NewCouponModal/NewCouponModal';

import { getAllCoupons } from '../../../services/coupons';
import { getUserCurrencyData } from '../../../store/selectors';
import Table from '../../common/Table/Table';
import Loader from '../../common/Loader/Loader';
import { LoadCell } from './Coupons.style';

import { localiseAndConvertPrice } from '../../../utils/string.utils';
import CouponInfoModal from './CouponInfoModal/CouponInfoModal';
import { TableToolbar } from '../../common.styles';
import { formatAPIDateString } from 'utils/date.utils';

import { sendUserInteraction } from 'utils/tagManager.utils';

class Coupons extends Component {
  state = {
    error: false,
    isLoading: true,
    coupons: [],
    couponLimit: 5,
    couponModal: false,
    currentCoupon: null,
  };

  async componentDidMount() {
    this.fetchAllTokens();
  }

  async fetchAllTokens() {
    try {
      const { data } = await getAllCoupons();
      this.setState({ coupons: data, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      throw e;
    }
  }

  openModal(coupon) {
    return (e) => {
      e.preventDefault();
      sendUserInteraction('open coupon info modal');
      this.setState({ couponModal: true, currentCoupon: coupon });
    };
  }

  closeModal() {
    return () => {
      sendUserInteraction('close coupon info modal');
      this.setState({ couponModal: false, currentCoupon: null });
    };
  }

  loadMoreCoupons(couponAmount) {
    return () => {
      sendUserInteraction('load more coupons click');
      this.setState({ couponLimit: this.state.couponLimit + couponAmount });
    };
  }

  getLimitedCoupons() {
    return this.state.coupons.slice(0, this.state.couponLimit + 1);
  }

  render() {
    const { currencyData, i18n, t } = this.props;
    const { currentCoupon, couponModal } = this.state;
    const couponRows = this.getLimitedCoupons().map((coupon) => {
      const usesLeft = coupon.use_limit ? coupon.use_limit - coupon.used : '∞';
      return (
        <Table.Row key={coupon.id} negative={usesLeft === 0} onClick={this.openModal(coupon)}>
          <Table.Cell>{coupon.id}</Table.Cell>
          <Table.Cell>{coupon.hash_code}</Table.Cell>
          <Table.Cell>
            {coupon.discount_amount
              ? localiseAndConvertPrice({
                  currencyData,
                  number: coupon.discount_amount,
                  language: i18n.language,
                })
              : `-${coupon.ratio}%`}
          </Table.Cell>
          <Table.Cell>{usesLeft}</Table.Cell>
          <Table.Cell>
            {coupon.updated_at ? formatAPIDateString({ dateString: coupon.updated_at }) : 'Never'}
          </Table.Cell>
          <Table.Cell>{formatAPIDateString({ dateString: coupon.created_at })}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <>
        <TableToolbar>
          <NewCouponModal
            onSubmit={() => {
              this.fetchAllTokens();
            }}
          ></NewCouponModal>
        </TableToolbar>
        {this.state.coupons.length > 0 ? (
          <>
            <Table width="100%" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.coupons.code')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.coupons.discount')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.coupons.usesLeft')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.coupons.lastUsed')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common:admin.coupons.createdAt')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {couponRows}
                {this.state.couponLimit < this.state.coupons.length && (
                  <Table.Row>
                    <LoadCell textAlign="center" colSpan="7" onClick={this.loadMoreCoupons(5)}>
                      {t('common:admin.coupons.loadMore')}
                    </LoadCell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <CouponInfoModal
              open={couponModal}
              coupon={currentCoupon}
              onClose={this.closeModal()}
            ></CouponInfoModal>
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currencyData: getUserCurrencyData(state),
});

export default connect(mapStateToProps)(withTranslation('common')(Coupons));
