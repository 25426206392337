import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';

import Accordion from '../../../../common/Accordion/Accordion';
import { Loader } from 'semantic-ui-react';
import ProgressBar from '../../../../common/ProgressBar/ProgressBar';
import Table from '../../../../common/Table/Table';
import UserDropdown from './UserDropdown';
import { taiaColors } from '../../../../../style/theme';

import {
  getPossibleSubscriptionUsers,
  getSubscriptionById,
  patchSubscriptionUser,
} from '../../../../../services/subscriptions';
import { getUser } from 'services/auth';
import { setUser } from 'store/userSlice';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { checkAllowedRole } from 'utils/user.utils';

const UsersTable = ({ subscriptionDetails, updateSubscriptionDetails }) => {
  const [loadingUserOptions, setLoadingUserOptions] = useState(true);
  const [userOptions, setUserOptions] = useState([]);

  const { subscription_seats } = subscriptionDetails;
  const user = useSelector((state) => state.userStore.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const getUserOptions = async () => {
      setLoadingUserOptions(true);
      try {
        const optionsResponse = await getPossibleSubscriptionUsers(subscriptionDetails.id);
        setUserOptions(optionsResponse);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingUserOptions(false);
      }
    };

    getUserOptions();
  }, [subscriptionDetails]);

  const handleUserChange = async ({ e, seatId, userId }) => {
    sendUserInteraction('subscription overview page assign subscription seat');
    e.preventDefault();
    setLoadingUserOptions(true);
    try {
      let payloadUserId = userId === 0 ? null : userId;

      await patchSubscriptionUser({ seatId, userId: payloadUserId });
      // fetch subscription details again since patchUser only returns seat data
      const newDetails = await getSubscriptionById(subscriptionDetails.id);
      updateSubscriptionDetails(newDetails);

      // if the userId is the same as logged in user ID we also need to re-fetch user profile
      if (payloadUserId === user.id) {
        const userInfo = await getUser();
        dispatch(setUser({ user: userInfo.data }));
      }
    } catch (e) {
    } finally {
      setLoadingUserOptions(false);
    }
  };

  const sortedSeats = sortBy(subscription_seats, [(seat) => !seat.user, 'id']);

  const showTeamsRow = useMemo(() => {
    const isIndividual = checkAllowedRole({ allowedRoles: [10], userRole: user.role });
    return !isIndividual;
  }, [user]);

  return (
    <Accordion title={t('common:catapult.subscription.usersTable.heading')} watch={sortedSeats}>
      <Table width="100%">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common:catapult.subscription.usersTable.name')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common:catapult.subscription.usersTable.email')}</Table.HeaderCell>
            {showTeamsRow && (
              <Table.HeaderCell>{t('common:catapult.subscription.usersTable.team')}</Table.HeaderCell>
            )}
            <Table.HeaderCell>{t('common:catapult.subscription.usersTable.usage')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedSeats.map((seat) => {
            const { current_month_statistic, id, user } = seat;

            const usagePercentage = !current_month_statistic
              ? 0
              : current_month_statistic.limit_used
              ? (current_month_statistic.limit_used * 100) / current_month_statistic.word_limit
              : 0;

            const userText = !!user ? user.name : t('common:catapult.subscription.usersTable.notAssigned');

            return (
              <Table.Row key={id} style={{ backgroundColor: !!user ? '#fff' : taiaColors.greyLight }}>
                <Table.Cell>
                  {loadingUserOptions ? (
                    <>
                      <span>
                        {userText}
                        <Loader active inline size="tiny" style={{ marginLeft: '.5em' }} />
                      </span>
                    </>
                  ) : (
                    <UserDropdown
                      handleUserChange={(e, { value }) => handleUserChange({ e, seatId: id, userId: value })}
                      loadingUserOptions={loadingUserOptions}
                      user={user}
                      userOptions={userOptions}
                    />
                  )}
                </Table.Cell>

                <Table.Cell>{!!user ? user.email : ''}</Table.Cell>
                {showTeamsRow && (
                  <Table.Cell>{!!user && user.teams.map((team) => team.name).join(', ')}</Table.Cell>
                )}
                <Table.Cell>
                  <div className="table-progress-bar">
                    <ProgressBar progress={usagePercentage} width="100px" />
                    <label className="usage-label label">
                      {`${current_month_statistic.limit_used}/${current_month_statistic.word_limit} ${t(
                        'common:catapult.subscription.words',
                      )}`}
                    </label>
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Accordion>
  );
};

export default UsersTable;
