import React, { createContext, useContext } from 'react';

const INITIAL_PROJECT_DATA = {
  files: [],
  projectCategory: null,
  projectName: '',
  projectType: 'translation',
  sourceLanguage: null,
  targetLanguages: [],
  teamId: null,
};

export const initialState = {
  projectData: INITIAL_PROJECT_DATA,
  activeStep: 1,
  tempProgress: 0,
  taiaOCRprogress: 0,
  submitMessage: null,
  error: null,
  submittingProject: false,
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET_SUBMIT':
      return {
        ...state,
        error: null,
        taiaOCRprogress: 0,
        submitMessage: null,
        activeStep: 1,
      };
    case 'RESET_PROJECT_DATA':
      return { ...state, projectData: INITIAL_PROJECT_DATA };
    case 'SET_PROJECT_DATA':
      return { ...state, projectData: payload };
    case 'SET_PROJECT_NAME':
      return { ...state, projectData: { ...state.projectData, projectName: payload } };
    case 'SET_TARGET_LANGUAGES':
      return { ...state, projectData: { ...state.projectData, targetLanguages: payload } };
    case 'SET_TEAM_ID':
      return { ...state, projectData: { ...state.projectData, teamId: payload } };
    case 'SET_FILES':
      return {
        ...state,
        projectData: { ...state.projectData, files: payload },
      };
    case 'REMOVE_FILE':
      return {
        ...state,
        projectData: {
          ...state.projectData,
          files: state.projectData.files.filter((file) => file.name !== payload),
        },
      };
    case 'SET_PROJECT_CATEGORY':
      return { ...state, projectData: { ...state.projectData, projectCategory: payload } };
    case 'SET_IS_SUBMITTING':
      return { ...state, submittingProject: payload };
    case 'SET_TEMP_PROGRESS':
      return { ...state, tempProgress: payload };
    case 'SET_ERROR':
      return { ...state, error: payload };
    case 'SET_STEP':
      return { ...state, activeStep: payload };
    default:
      return state;
  }
};

export const Provider = ({ children, state, dispatch }) => {
  return <NewProjectsContext.Provider value={[state, dispatch]}>{children}</NewProjectsContext.Provider>;
};

const NewProjectsContext = createContext(null);

export const useNewProjectState = () => useContext(NewProjectsContext);
