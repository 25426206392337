import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  stepsLength: null,
};

const projectsSlice = createSlice({
  name: 'projectsSlice',
  initialState: { ...initialState },
  reducers: {
    addProject(state, action) {
      const { project } = action.payload;
      const newProjects = [project, ...state.projects];
      state.projects = newProjects;
    },
    setProjects(state, action) {
      const { projects } = action.payload;
      state.projects = projects;
    },
    removeProject(state, action) {
      const { projectId } = action.payload;
      const newProjects = state.projects.filter((project) => project.id !== projectId);
      state.projects = newProjects;
    },
    updateProject(state, action) {
      const { project } = action.payload;

      const hasProject = state.projects.find((oldProject) => oldProject.id === project.id);

      if (hasProject) {
        const newProjects = state.projects.map((oldProject) => {
          if (oldProject.id === project.id) {
            return project;
          }
          return oldProject;
        });

        state.projects = newProjects;
      } else {
        const newProjects = [project, ...state.projects];
        state.projects = newProjects;
      }
    },
    updateStepsLength(state, action) {
      const { length } = action.payload;
      state.stepsLength = length;
    },
  },
});

export const {
  addProject,
  removeProject,
  updateProject,
  setProjects,
  updateStepsLength,
} = projectsSlice.actions;

export default projectsSlice.reducer;
