import { Input } from 'semantic-ui-react';
import Pagination from '../common/Pagination/Pagination';
const { default: styled } = require('styled-components');

export const AdminCompaniesLayout = styled.div`
  position: relative;
  display: grid;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
`;
export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const StyledPagination = styled(Pagination)`
  margin-right: auto !important;
`;

export const StyledInput = styled(Input)`
  margin-right: 10px !important;
`;
