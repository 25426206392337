export const SUBSCRIPTION_STATUSES = [
  {
    status: 'incomplete',
    translationKey: 'common:catapult.admin.paidSubscriptionsTable.statuses.incomplete',
  },
  {
    status: 'incomplete_expired',
    translationKey: 'common:catapult.admin.paidSubscriptionsTable.statuses.incomplete_expired',
  },
  {
    status: 'trailing',
    translationKey: 'common:catapult.admin.paidSubscriptionsTable.statuses.trailing',
  },
  {
    status: 'active',
    translationKey: 'common:catapult.admin.paidSubscriptionsTable.statuses.active',
  },
  {
    status: 'past_due',
    translationKey: 'common:catapult.admin.paidSubscriptionsTable.statuses.past_due',
  },
  {
    status: 'canceled',
    translationKey: 'common:catapult.admin.paidSubscriptionsTable.statuses.canceled',
  },
  {
    status: 'unpaid',
    translationKey: 'common:catapult.admin.paidSubscriptionsTable.statuses.unpaid',
  },
];

export const INITIAL_REQUEST_PARAMS = {
  current_period_end_from: null, // these are here to make a range of current_period_end
  current_period_end_to: null, // these are here to make a range of current_period_end
  page: 1,
  per_page: 20,
  search: null, // search by customer name or plans name
  status: SUBSCRIPTION_STATUSES.map((statusObject) => statusObject.status), // array of statuses, everything selected by default
  sort_by: 'id',
  sort_order: 'desc',
};
