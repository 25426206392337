import React from 'react';
import PublicSidebar from './Sidebar/PublicSidebar';

// Styles
import './style.scss';

export default ({ children, displaySidebar = true }) => {
  return (
    <div className="public-wrapper">
      {displaySidebar && <PublicSidebar />}
      <div className="public-content">
        <div className="public-content__children">{children}</div>
      </div>
    </div>
  );
};
