import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { Icon } from '../common/Icon/Icon';

export const CardsContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const Card = styled.div`
  margin: 2rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 560px;
  box-shadow: 2px 0 4px 4px rgba(0, 0, 0, 0.1);
  color: #10396d;
`;

export const CardText = styled.div`
  font-size: 2rem;
  margin-top: 2rem;
  text-align: center;
  line-height: 2.5rem;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const FilesList = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 2rem;
  max-height: 237px;
`;

export const FileRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
`;

export const FileText = styled.div`
  margin-right: 2rem;
  width: 207px;
`;

export const StyledDropzone = styled(Dropzone)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 0.25rem dashed #10396d;
  cursor: pointer;
`;

export const DeleteIcon = styled(Icon)`
  :hover {
    color: #3094be;
  }
  cursor: pointer;
`;
