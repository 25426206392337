import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { localStorageKeyAndFields, validateLanguagePairs } from '../../VendorOnboarding.utils';
import { useLocalStorage } from 'utils/storage.utils';
import { useTranslation } from 'react-i18next';
import {
  VENDOR_TASK_TYPE_TRANSLATION,
  VENDOR_TASK_TYPE_REVISION,
  VENDOR_TASK_TYPE_PROOFREAD_ONLY,
  singleRowDefaultStateOnboarding,
} from 'constants/vendors';

// Components
import { Icon } from 'components/common/Icon/Icon';
import Table from 'components/common/Table/Table';
import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';
import { MultipleDropdown } from 'components/common/Dropdown/MultipleDropdown';
import { Flag } from 'components/common/Flag/Flag';
import StepsControls from '../../StepsControls/StepsControls';
import AddLanguagePairRow from 'components/common/Vendor/AddLanguagePairRow/AddLanguagePairRow';

// Styles
import './style.scss';
import RateInput from 'components/common/PriceInput/RateInput';

// Constants
const { localStorageKey } = localStorageKeyAndFields[1];
const defaultRowsData = [{ ...singleRowDefaultStateOnboarding }];

/**
 * Render language pair row
 */
export const LanguagePairRow = ({ onChange, rowIndex, data, onDelete }) => {
  const { languages, categories } = useSelector((state) => state.classifiersStore);
  const [rowData, setRowData] = useState(data);

  // Hooks
  const { t } = useTranslation();

  const handleInputChange = (_, { value, name }) => onChange({ rowIndex, input: { name, value } });

  const handleRowDelete = () => onDelete(rowIndex);

  // On data prop (row data in parent component) change, update local state
  useEffect(() => {
    setRowData(data);
  }, [data]);

  // Render variables
  const isProofreadTask = rowData.task_type_id === VENDOR_TASK_TYPE_PROOFREAD_ONLY;

  const languageOptions = useMemo(() => {
    const langs = [...languages];

    return langs.map((lang) => ({
      key: lang.id,
      value: lang.id,
      text: lang.name,
      icon: <Flag lang={{ code: lang.code }} />,
    }));
  }, [languages]);

  const subjectMatterOptions = useMemo(() => {
    return categories.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
  }, [categories]);

  const serviceOptions = [
    {
      key: 1,
      value: VENDOR_TASK_TYPE_TRANSLATION,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.translation'),
    },
    {
      key: 2,
      value: VENDOR_TASK_TYPE_REVISION,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.revision'),
    },
    {
      key: 3,
      value: VENDOR_TASK_TYPE_PROOFREAD_ONLY,
      text: t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.options.proofread'),
    },
  ];

  const languageOptionsWithoutSource = languageOptions.filter(
    (lang) => lang.value !== rowData.source_lang_id,
  );
  const languageOptionsWithoutTarget = languageOptions.filter((lang) => {
    // Is proofread
    if (isProofreadTask) return true;

    return lang.value !== rowData.target_lang_id;
  });

  return (
    <Table.Row className="lng-pair-row">
      <Table.Cell>
        <SelectDropdown
          name="source_lang_id"
          value={rowData.source_lang_id}
          onChange={handleInputChange}
          placeholder={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.srcLang.placeholder',
          )}
          options={languageOptionsWithoutTarget}
          selection
          search
          emptyState={t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.srcLang.empty')}
          fluid
          selectedShowIcon
        />
      </Table.Cell>
      <Table.Cell className="target-language-cell">
        {!isProofreadTask && (
          <SelectDropdown
            name="target_lang_id"
            value={rowData.target_lang_id}
            onChange={handleInputChange}
            placeholder={t(
              'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.targetLang.placeholder',
            )}
            options={languageOptionsWithoutSource}
            selection
            search
            emptyState={t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.srcLang.empty')}
            fluid
            selectedShowIcon
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <MultipleDropdown
          className="subject-matter-dropdown"
          name="category_id"
          value={rowData.category_id}
          onChange={handleInputChange}
          placeholder={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.subjectMatter.placeholder',
          )}
          options={subjectMatterOptions}
          multiple
          selection
          search
          emptyState={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.subjectMatter.empty',
          )}
          fluid
          showSelectedInMenu
          optionLabel={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.subjectMatter.single',
          )}
          fitPlaceholder
        />
      </Table.Cell>
      <Table.Cell>
        <SelectDropdown
          name="task_type_id"
          className="service-dropdown"
          value={rowData.task_type_id}
          onChange={handleInputChange}
          placeholder={t(
            'common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.placeholder',
          )}
          options={serviceOptions}
          selection
          search
          emptyState={t('common:serviceProvider.onboarding.steps.stepTwo.table.row.inputs.service.empty')}
          fluid
        />
      </Table.Cell>
      <Table.Cell className="rate-cell">
        <div className="wrapper">
          <RateInput onPriceChange={handleInputChange} initialAmount={rowData.rate} name="rate" />
        </div>
      </Table.Cell>
      <Table.Cell className="delete-row-cell">
        {rowIndex !== 0 && (
          <button onClick={handleRowDelete}>
            <Icon name="trash-alt" />
          </button>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

/**
 * Main component
 */
const LanguagePairs = ({ stepsContext }) => {
  const { complete, uncomplete, completed } = stepsContext;

  const isStepCompleted = completed.includes(1);

  const [rowsDataLS, setRowsDataLS] = useLocalStorage(localStorageKey, defaultRowsData);
  const [rowsData, setRowsData] = useState(rowsDataLS);

  // Translation hook
  const { t } = useTranslation();

  // Methods
  /**
   * Passed to each row, fires on row data change
   * Find the row data in current rowsData and update it
   */
  const handleRowDataChange = ({ rowIndex, input }) => {
    const newRowsState = [...rowsData];

    // Find row data
    const currData = newRowsState[rowIndex];

    // Either update row or push new row
    if (currData) {
      currData[input.name] = input.value;
    } else {
      newRowsState.push({
        [input.name]: input.value,
      });
    }

    // Check if task is proofread
    if (currData.task_type_id === VENDOR_TASK_TYPE_PROOFREAD_ONLY) {
      currData.target_lang_id = currData.source_lang_id;
    } else if (currData.target_lang_id === currData.source_lang_id) {
      currData.target_lang_id = '';
    }

    setRowsData(newRowsState);
  };

  const addNewRow = () => setRowsData([...rowsData, { ...singleRowDefaultStateOnboarding }]);

  const deleteRow = (idx) => {
    const newRows = [...rowsData];

    newRows.splice(idx, 1);

    setRowsData(newRows);
  };

  /**
   * Runs on rowsData change
   * Validate language pairs, if valid complete current step
   * Also sync state with localStorage state,
   * In localStorage only store rows with values, ignore empty rows
   */
  useEffect(() => {
    // is whole step valid
    const isStepValid = validateLanguagePairs(rowsData);

    // Get valid rows for LS (none empty rows)
    const validRows =
      // If only one row, keep it
      rowsData.length === 1
        ? rowsData
        : rowsData.filter((rowFields) => {
            const fieldValues = Object.values(rowFields);

            // Check for empty string, 0, empty array
            const rowEmpty = fieldValues.every((val) => val?.length === 0);

            return !rowEmpty;
          });

    if (isStepValid) {
      if (!isStepCompleted) complete(1);
    } else if (isStepCompleted) uncomplete(1);

    // Sync with local storage
    setRowsDataLS(validRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsData]);

  // Texts
  const title = t('common:serviceProvider.onboarding.steps.stepTwo.title');
  const description = t('common:serviceProvider.onboarding.steps.stepTwo.description');
  const nextStepLabel = t('common:serviceProvider.onboarding.steps.stepTwo.nextStepLabel');
  const isComplete = completed.includes(1);

  return (
    <section className="steps-content form-step">
      <header>
        <h2>{title}</h2>
        <p>{description}</p>
      </header>

      <div className="form">
        <div className="vendor-onboarding-step-two-form">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.srcLang')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.targetLang')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.subMatter')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.service')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common:serviceProvider.onboarding.steps.stepTwo.table.header.rate')}
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rowsData.map((row, idx) => (
                <LanguagePairRow
                  key={idx}
                  rowIndex={idx}
                  onChange={handleRowDataChange}
                  onDelete={deleteRow}
                  data={row}
                />
              ))}

              <AddLanguagePairRow onClick={addNewRow} />
            </Table.Body>
          </Table>
        </div>

        <StepsControls isComplete={isComplete} stepsContext={stepsContext} nextStepLabel={nextStepLabel} />
      </div>
    </section>
  );
};

export default LanguagePairs;
