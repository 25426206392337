import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../common/Button/Button';
import { Modal } from 'semantic-ui-react';

const DeleteConfirmation = ({ onClose, removeTeam, team }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRemoveTeam = async () => {
    setIsSubmitting(true);
    await removeTeam(team.id);
    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal open={true} size="small">
      <Modal.Header>{`${t('common:team.deleteTeam')}: ${team.name}`}</Modal.Header>
      <Modal.Content>{t('common:team.deleteConfirmation')}</Modal.Content>
      <Modal.Actions>
        <Button actiontype="secondary" disabled={isSubmitting} onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="primary" loading={isSubmitting} onClick={handleRemoveTeam}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteConfirmation;
