import { useState } from 'react';
import { useLocalStorage } from 'utils/storage.utils';

export const usePagination = (name) => {
  const [activePage, setActivePage] = useLocalStorage(`${name}-page`, 1);
  const [numOfPages, setNumberOfPages] = useState(1);

  const changePage = ({ activePage, numOfPages }) => {
    if (activePage) {
      setActivePage(activePage);
    }

    if (numOfPages) {
      setNumberOfPages(numOfPages);
    }
  };

  return { activePage, numOfPages, changePage };
};
