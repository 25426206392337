import React, { useEffect, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// Services
import { deleteProject as serviceDeleteProject } from 'services/project';

// Components
import { Modal } from 'semantic-ui-react';
import Button from 'components/common/Button/Button';

/**
 * Renders a modal which deletes a project
 */
const DeleteProjectModal = ({ trigger, projectId, setLoading, open, onClose }) => {
  // State
  const [showDeleteModal, setShowDeleteModal] = useState(open);

  // Hooks
  const { t } = useTranslation();

  // functions
  const closeModal = () => {
    if (onClose) onClose();

    setShowDeleteModal(false);
  };

  const openModal = () => setShowDeleteModal(true);

  const deleteProject = async () => {
    setShowDeleteModal(false);
    setLoading(true);

    try {
      await serviceDeleteProject(projectId);
      toast.success(t('common:toasts.deleteProject.success'));
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.deleteProject.error'));
    } finally {
      setLoading(false);
    }
  };

  // Effects

  /**
   * On props.open change update internal state
   */
  useEffect(() => {
    setShowDeleteModal(open);
  }, [open]);

  // Props modal props
  const modalProps = {
    closeIcon: true,
    size: 'mini',
    open: showDeleteModal,
    onClose: closeModal,
    onOpen: openModal,
    trigger: trigger ? trigger : null,
  };

  return (
    <Modal {...modalProps}>
      <Modal.Content>
        <p>{t('common:deleteModal.areYouSure')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={closeModal}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="delete-confirm" onClick={deleteProject}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

// Prop types
DeleteProjectModal.propTypes = {
  projectId: PropTypes.number.isRequired,
  open: function (props, propName, _) {
    if (!props['trigger'] && (props[propName] === undefined || typeof props[propName] !== 'boolean')) {
      return new Error('No trigger found, open prop must be provided');
    }
  },
  trigger: PropTypes.element,
  setLoading: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteProjectModal;
