import React from 'react';
import { useTranslation } from 'react-i18next';

import { CatImage, Container, FlavorText, Heading, TextContainer } from './NoCompanySubscription.styles';

import CatapultSrc from './Catapult.png';

const NoCompanySubscription = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <CatImage alt="catapult-graphic" src={CatapultSrc} />
      <TextContainer>
        <Heading>{t('common:catapult.subscription.noCompanySubscription.heading')}</Heading>
        <FlavorText>{t('common:catapult.subscription.noCompanySubscription.flavorText1')}</FlavorText>
        <FlavorText>{t('common:catapult.subscription.noCompanySubscription.flavorText2')}</FlavorText>
      </TextContainer>
    </Container>
  );
};

export default NoCompanySubscription;
