import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CompanyHeader from './CompanyHeader';
import CompanyTabs from './CompanyTabs/CompanyTabs';
// import ProfileCompletion from './ProfileCompletion';
import Loader from '../common/Loader/Loader';
import Message from '../common/Message/Message';
import { LoaderContainer } from './Company.styles';
import { MainContainer } from 'components/common.styles';

import { getCompany, getCompanyTmSettings } from '../../services/company';
import { hasAdminPermissions } from '../../utils/user.utils';
import { useSelector } from 'react-redux';
import { withCompanyState, fetchInvites, useCompanyDispatch } from './Company.state';

const Company = () => {
  // company ID read from URL params
  const { id } = useParams();

  // react i18n translation utils

  const [companyData, setCompanyData] = useState(null);
  const [loadError, setLoadError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [tmSetting, setTmSetting] = useState(false);

  const user = useSelector((state) => state.userStore.user);
  const dispatch = useCompanyDispatch();

  useEffect(() => {
    const fetchCompanyData = async () => {
      setIsLoading(true);

      try {
        const companyData = await getCompany(id);
        if (hasAdminPermissions(user.role)) {
          const tm = await getCompanyTmSettings(id);
          setTmSetting(tm);
        }
        setCompanyData(companyData);
        // TODO update global company state here
      } catch (e) {
        setLoadError(e.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyData();
    fetchInvites(dispatch, id);
  }, [id, user.role, dispatch]);

  const updateCompanyData = (newCompanyData) => {
    setCompanyData({ ...companyData, ...newCompanyData });
    // TODO update global company state here
  };

  const updateTmSetting = (newTmSetting) => {
    setTmSetting({ ...tmSetting, ...newTmSetting });
    // TODO update global company state here
  };

  return (
    <MainContainer>
      {loadError ? (
        <Message text={loadError} type="error" />
      ) : isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <CompanyHeader companyData={companyData} />
          {/* <ProfileCompletion /> */}
          <CompanyTabs
            companyData={companyData}
            updateCompanyData={updateCompanyData}
            shareTm={tmSetting}
            updateTmSetting={updateTmSetting}
          />
        </>
      )}
    </MainContainer>
  );
};

export default withCompanyState(Company);
