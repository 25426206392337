import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  box-shadow: -2px 0 20px rgb(0 0 0 / 25%);
  background-color: #fff;
  width: 55px;
  z-index: ${({ theme }) => theme.zIndex.sidebar + 1};
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #fff;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    display: none;
  `}
`;

export const MenuButton = styled.button`
  border: none;
  background-color: #fff;
  color: #232323;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: #f0f0f0;
  }
  svg {
    font-size: 22px;
  }

  &.success {
    color: ${({ theme }) => theme.colors.green};
  }

  padding: 1em;
  width: 100%;
  height: 52px;
`;

export const MenuLink = styled(Link)`
  border: none;
  background-color: #fff;
  color: #4183c4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: #e3f3f7;
  }
  svg {
    font-size: 22px;
  }
  padding: 1em;
  width: 100%;
  height: 52px;
`;

export const SidebarWrapper = styled.div`
  margin-right: ${({ isAdmin }) => (isAdmin ? '55px' : '0')};
  height: 100vh;

  ${({ overflow = true }) => {
    if (!overflow) {
      return `
        overflow-y: hidden;
        max-height: 100vh;
      `;
    }
  }}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    
    margin-right: 0px !important;
  `}
`;
