import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AnimateSharedLayout } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { fetchSubscriptions } from 'components/Checkout/Checkout.utils';

import CallToActionButton from 'components/common/CallToAction/CallToAction';
import {
  CardsContainer,
  Container,
  ControlsContainer,
  Heading,
  EnterpriseLink,
  SubHeading,
} from './Packages.styles';
import Features from './Features';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import { MainContainer } from 'components/common.styles';
import Message from 'components/common/Message/Message';
import CurrenciesSelector from './CurrenciesSelector';
import IntervalToggle from './IntervalToggle';
import FreeProductCard from './FreeProductCard';
import ProductCard from './ProductCard';

import { currencyByIdSelector, currencyByCodeSelector } from '../../../store/selectors';
import { canOrderCatapultSubscription, hasActiveSubscriptions } from 'utils/catapult.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { getBillingDetails } from 'services/billing';
import { getBillingSubscriptions } from 'services/subscriptions';

const Packages = () => {
  const getCurrencyByCode = useSelector(currencyByCodeSelector);
  const [error, setError] = useState(null);
  const eurId = getCurrencyByCode('EUR')['id'];
  const { products } = useSelector((state) => state.classifiersStore);
  const [billingDetails, setBillingDetails] = useState(null);
  const user = useSelector((state) => state.userStore.user);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const quantity = 1;
  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [showMonthlyPrices, setShowMonthlyPrices] = useState(false);
  const { t } = useTranslation();
  // const subscriptions = useSelector((state) => state.subscriptionsStore.subscriptions);
  const [subscriptions, setSubscriptions] = useState([]);

  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);
  const billingId = query.get('billingId') === 'null' ? null : query.get('billingId');

  // hardcoded for now, need to update with the new selector functions to default to EUR
  const [currencyId, setCurrencyId] = useState(eurId);

  const getCurrencyById = useSelector(currencyByIdSelector);
  const currentCurrencyData = getCurrencyById(currencyId);

  const handleIntervalClick = () => {
    sendUserInteraction('changed pricing interval display');
    setShowMonthlyPrices(!showMonthlyPrices);
  };

  useEffect(() => {
    // refetch products and billing info on packages page load
    const fetchInitialData = async () => {
      try {
        setError(null);
        await fetchSubscriptions();
        if (billingId) {
          const bill = await getBillingDetails(billingId);
          setBillingDetails(bill);

          const subs = (await getBillingSubscriptions({ billing_id: bill.id }))['data'];
          setSubscriptions(subs);
          if (subs?.length > 0) {
            const activeSubscription = subs.find(
              (sub) => sub.stripe_status === 'active' && sub.billing_detail_id === parseInt(billingId),
            );

            const stripeProduct = products.find((product) => {
              const productPlan = product.plans.find(
                (plan) => plan.stripe_id === activeSubscription.stripe_plan,
              );
              return !!productPlan;
            });

            const stripePlan = stripeProduct?.plans.find(
              (plan) => plan.stripe_id === activeSubscription.stripe_plan,
            );
            setCurrencyId(stripePlan ? stripePlan.currency_id : bill.currency_id);
          } else {
            setCurrencyId(bill.currency_id);
          }
        }
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // first filter out all products that do not have a pricing plan we can use for base calculations and all products that are not active
    // then sort the products by word limit descending
    const displayedProducts = products
      .filter((product) => {
        return (
          product.active &&
          !product.private &&
          product.plans.length > 1 &&
          product.plans.some((plan) => plan.interval === 'month') &&
          product.plans.some((plan) => plan.interval === 'year')
        );
      })
      .sort((a, b) => {
        return b.word_limit - a.word_limit;
      });

    setDisplayedProducts(displayedProducts);
  }, [currentCurrencyData, products, currencyId, showMonthlyPrices]);

  const handleCurrencyChange = (value) => {
    sendUserInteraction('changed pricing currency display');
    setCurrencyId(value);
  };

  const subscriptionStatus = useMemo(() => {
    const activeSubscription = hasActiveSubscriptions(subscriptions);
    const stripeSubscription = !!billingId
      ? subscriptions.find(
          (sub) => sub.stripe_status === 'active' && sub.billing_detail_id === parseInt(billingId),
        )
      : subscriptions.find((sub) => sub.stripe_status === 'active');

    const stripeProduct = !!stripeSubscription
      ? products.find((product) => {
          const productPlan = product.plans.find((plan) => plan.stripe_id === stripeSubscription.stripe_plan);
          return !!productPlan;
        })
      : undefined;

    return {
      activeSubscription: !!activeSubscription,
      stripeSubscription: stripeSubscription,
      stripeProduct,
    };
  }, [billingId, products, subscriptions]);

  const canOrderSubscription = useMemo(() => {
    return canOrderCatapultSubscription({ user });
  }, [user]);

  return (
    <MainContainer>
      {isLoading ? (
        <LoadingPane height="100%" />
      ) : !!error ? (
        <Message
          header={t('common:catapult.packages.loadError.heading')}
          text={t('common:catapult.packages.loadError.text')}
          type="error"
        />
      ) : (
        <Container>
          <Heading>{t('common:catapult.packages.heading')}</Heading>
          <SubHeading>{t('common:catapult.packages.subheading')}</SubHeading>
          <ControlsContainer>
            <IntervalToggle onClick={handleIntervalClick} showMonthlyPrices={showMonthlyPrices} />
          </ControlsContainer>
          <CardsContainer>
            <div className="package-cards">
              <AnimateSharedLayout>
                {displayedProducts.map((product, index) => {
                  return (
                    <ProductCard
                      billingId={billingId}
                      canOrderSubscription={canOrderSubscription}
                      currentCurrencyData={currentCurrencyData}
                      key={product.id}
                      quantity={quantity}
                      product={product}
                      subscriptionStatus={subscriptionStatus}
                      yearly={!showMonthlyPrices}
                      cypressID={`product-card-${index}`}
                    />
                  );
                })}
                {!subscriptionStatus?.activeSubscription ? (
                  <FreeProductCard currencyData={currentCurrencyData} yearly={!showMonthlyPrices} />
                ) : null}
              </AnimateSharedLayout>
            </div>
            {!canOrderSubscription && (
              <p className="text" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                {t('common:catapult.packages.unableToOrderText')}
              </p>
            )}
            {!billingDetails && (
              <CurrenciesSelector currencyId={currencyId} onCurrencyChange={handleCurrencyChange} />
            )}
          </CardsContainer>
          <EnterpriseLink>
            {t('common:catapult.packages.enterprise.text')}&nbsp;
            <a
              href="https://taia.io/contact/"
              onClick={() => {
                sendUserInteraction('clicked contact for enterprise link');
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('common:catapult.packages.enterprise.linkText')}
            </a>
            .
          </EnterpriseLink>
          <Features />

          <CallToActionButton
            color="blue"
            href="https://taia.io/catapult"
            onClick={() => {
              sendUserInteraction('clicked read more link');
            }}
            target="_blank"
            text={t('common:catapult.packages.readMore')}
            data-cy="read-more"
          />
        </Container>
      )}
    </MainContainer>
  );
};

export default Packages;
