import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { classnames } from 'utils/base.utils';

import CardControls from '../CardControls/CardControls';
import Price from '../../../common/Price/Price';
import ProjectLanguages from '../NewProjectLanguages';
import StatusLabel from '../../../common/StatusLabel/StatusLabel';

import {
  AdminButtonsContainer,
  CardFooter,
  FlexWrapper,
  HeaderWrapper,
  StyledIcon,
  TeamLabel,
  StyledButton,
  StyledHeader,
  LabelWrapper,
  PriceFooter,
  Progress,
  StyledDivider,
  DateContainer,
  AdminPrice,
  PriceContainer,
  StyledMobileProjectButton,
} from './ProjectCard.styles';
import ProjectLoader from '../../../Project/ProjectLoader/ProjectLoader';

import { hasAdminPermissions } from '../../../../utils/user.utils';
import { STATUS_COLORS } from 'components/common/StatusLabel/StatusLabel.constants';
import Loader from 'components/common/Loader/Loader';
import { useState } from 'react';

import { formatAPIDateString } from 'utils/date.utils';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { theme } from 'style/theme';
import Card from 'components/common/Card/Card';
import { Icon } from 'components/common/Icon/Icon';
import { PROJECT_STATUS_CREATED } from 'constants/projectStatus';

// Styles
import './style.scss';

const ProjectCard = ({ project, summary, reloadProjects = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const { role } = useSelector((state) => state.userStore.user);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  const getCardLinkProps = () => {
    return { to: `/project/${project.id}`, 'data-cy': 'project-card' };
  };

  const showPrice = () => {
    return (
      project.status_id >= 3 &&
      project.status_id !== 20 &&
      project.status_id !== 22 &&
      project.status_id !== 10 &&
      project.status_id !== 11 &&
      project.price
    );
  };

  const WrappedLink = ({ children }) =>
    hasAdminPermissions(role) ? <>{children}</> : <Link {...getCardLinkProps()}>{children}</Link>;

  const updateLoading = (load) => {
    setLoading(load);
  };

  return (
    <WrappedLink>
      <Card shadow className={classnames('project-card', { 'is-admin': hasAdminPermissions(role) })}>
        {project.progress !== null && (
          <Progress width="100%" color={STATUS_COLORS[project.status_id][2]} percent={project.progress} />
        )}
        <div className="project-card-inner-content">
          {loading && (
            <div className="loader">
              <Loader inline />
            </div>
          )}

          <LabelWrapper data-cy="project-card-status">
            <StatusLabel project={project} />
            <CardControls
              project={project}
              trigger={<StyledIcon name="ellipsis-h" />}
              reloadProjects={reloadProjects}
              updateLoading={updateLoading}
            />
          </LabelWrapper>
          <HeaderWrapper>
            <StyledHeader> {project.name}</StyledHeader>
            {!!project.team && <TeamLabel>{project.team.name}</TeamLabel>}
          </HeaderWrapper>

          <FlexWrapper>
            <ProjectLanguages project={project} />
          </FlexWrapper>

          {!isMobile && <StyledDivider />}

          {project.status_id === PROJECT_STATUS_CREATED ? (
            <ProjectLoader summary={summary} centered />
          ) : (
            <CardFooter>
              {project.status_id > 2 && project.delivery_time && !project.diy && (
                <DateContainer>
                  {t('common:projects.deliveryOn')}:{' '}
                  {formatAPIDateString({ dateString: project.delivery_time })}
                </DateContainer>
              )}

              {hasAdminPermissions(role) && showPrice() && (
                <AdminPrice>
                  {t('common:projects.projectPrice.price')}:&nbsp;
                  <Price
                    currency={{ ...project.currency, ...project.conversion_rate }}
                    price={project.price}
                    style={{ fontWeight: 'bold' }}
                  />
                </AdminPrice>
              )}

              {!hasAdminPermissions(role) && (
                <StyledMobileProjectButton flex basicLight big>
                  <span>Go to project</span>
                  <Icon name="arrow-right" size="large" />
                </StyledMobileProjectButton>
              )}
            </CardFooter>
          )}
        </div>
        {(showPrice() || hasAdminPermissions(role)) && (
          <PriceFooter>
            {hasAdminPermissions(role) ? (
              <AdminButtonsContainer>
                <Link to={`/project/${project.id}`}>
                  <StyledButton>{t('common:projects.showDetails')}</StyledButton>
                </Link>
                <Link to={`/admin/project/${project.id}`}>
                  <StyledButton>{t('common:projects.showAdminDetails')}</StyledButton>
                </Link>
              </AdminButtonsContainer>
            ) : (
              <PriceContainer>
                {t('common:projects.projectPrice.price')}:&nbsp;
                <Price
                  currency={{ ...project.currency, ...project.conversion_rate }}
                  price={project.price}
                  style={{ fontWeight: 'bold' }}
                />
                &nbsp;
                {parseInt(project.status_id) < 3 && t('common:projects.withVat')}
              </PriceContainer>
            )}
          </PriceFooter>
        )}
      </Card>
    </WrappedLink>
  );
};

export default ProjectCard;
