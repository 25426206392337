import React from 'react';
import { withTranslation } from 'react-i18next';

import { Progress } from 'semantic-ui-react';
import Loader from '../../common/Loader/Loader';
import { useNewProjectState } from '../NewProject.reducer';
import {
  ButtonsContainer,
  Container,
  Divider,
  MessageButton,
  StyledHeader,
  StyledLabel,
  StyledMessage,
} from './CreateProgress.styles';

const CreateProgress = ({ t }) => {
  const [
    { submittingProject, tempProgress, taiaOCRprogress, error, submitMessage },
    dispatch,
  ] = useNewProjectState();

  const resetSubmit = () => {
    dispatch({ type: 'RESET_SUBMIT' });
  };

  const resetProject = () => {
    resetSubmit();
    dispatch({ type: 'RESET_PROJECT_DATA' });
  };

  return (
    <Container>
      {submittingProject ? (
        <>
          <StyledHeader>{t('common:projects.createProject.creatingProject')}</StyledHeader>
          <Divider />
          {tempProgress !== 0 && (
            <>
              <StyledLabel>{`${t('common:projects.createProject.uploadingFiles')}...`}</StyledLabel>
              <Progress
                active
                color="green"
                progress="percent"
                size="large"
                style={{
                  margin: 0,
                  marginTop: '.5em',
                  width: '100%',
                }}
                percent={tempProgress}
              />
            </>
          )}
          {taiaOCRprogress !== 0 && (
            <>
              <StyledLabel>{`${t('common:projects.createProject.uploadingToOCR')}...`}</StyledLabel>
              <Progress
                active
                color="green"
                progress="percent"
                size="large"
                style={{
                  margin: 0,
                  marginTop: '.5em',
                  width: '100%',
                }}
                percent={taiaOCRprogress}
              />
            </>
          )}
          {tempProgress === 0 && taiaOCRprogress === 0 && <Loader inline />}
        </>
      ) : (
        <>
          {submitMessage && <StyledMessage>{submitMessage}</StyledMessage>}
          {error && <StyledMessage>{error}</StyledMessage>}
          <ButtonsContainer>
            <MessageButton onClick={resetSubmit}>
              {t('common:projects.createProject.backToProject')}
            </MessageButton>
            <MessageButton onClick={resetProject}>
              {t('common:projects.createProject.createNew')}
            </MessageButton>
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
};

export default withTranslation('common')(CreateProgress);
