import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { write } from 'clipboardy';
import { Dropdown, Modal } from 'semantic-ui-react';
import { Icon } from '../common/Icon/Icon';
import Message from '../common/Message/Message';
import { deletePlan, deleteProduct, postPlan, updateProduct } from '../../services/subscriptions';
import Button from '../common/Button/Button';
import { PlanForm } from '../CreatePackage/PlanInformantion/PlanInformantion';
import { PricingTitle } from '../CreatePackage/Pricing/Pricing.style';
import { hasSameCombination, remainingPricingCombinations } from '../CreatePackage/Pricing/Pricing.util';
import { PricingForm } from '../CreatePackage/Pricing/PricingField';
import { AddPricing, PricingRowWrapper } from './AdminSubscriptionPackage.style';
import { currencyByCodeSelector } from '../../store/selectors';

export const SubscriptionDeletePrompt = ({ pack }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deletePackage = async () => {
    try {
      setDeleting(true);
      const deletePlans = pack.plans.map((plan) => {
        return deletePlan(plan.id);
      });
      await Promise.all(deletePlans);

      await deleteProduct(pack.id);
      toast.success(t('common:toasts.deletePackage.success'));
      setOpen(false);
      history.push('/admin/packages');
    } catch (e) {
      toast.error(t('common:toasts.deletePackage.error'));
      console.error(e);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal
      trigger={<Dropdown.Item text={t('common:admin.package.deletePackage')} />}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>{t('common:admin.package.deletePackage')}</Modal.Header>
      <Modal.Content>{t('common:admin.package.deletePackagePrompt')}</Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={() => setOpen(false)} disabled={deleting}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="delete-confirm" onClick={deletePackage} disabled={deleting} loading={deleting}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const PricingDeletePrompt = ({ pricing, onDelete }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deletePricing = async () => {
    try {
      setDeleting(true);
      await deletePlan(pricing.id);
      toast.success(t('common:toasts.deletePricing.success'));
      onDelete();
      setOpen(false);
    } catch (e) {
      toast.error(t('common:toasts.deletePricing.error'));
      console.error(e);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal
      trigger={<Dropdown.Item text={t('common:admin.package.deletePricing')} />}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>{t('common:admin.package.deletePricing')}</Modal.Header>
      <Modal.Content>{t('common:admin.package.deletePricingPrompt')}</Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={() => setOpen(false)} disabled={deleting}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="delete-confirm" disabled={deleting} loading={deleting} onClick={deletePricing}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const PricingLinkItem = ({ planId = 0 }) => {
  const { t } = useTranslation();

  const onClick = async () => {
    try {
      await write(`${process.env.REACT_APP_DEPLOY_URL}catapult-landing?plan=${planId}&quantity=1`);
      toast.success(t('common:toasts.copyCheckoutLink.success'));
    } catch (e) {
      toast.error(t('common:toasts.copyCheckoutLink.error'));
    }
  };

  return <Dropdown.Item text={t('common:admin.package.copyCheckoutLink')} onClick={onClick} />;
};

export const PricingRow = ({ active = 0, pricing, onDelete = () => {} }) => {
  const getCurrencyByCode = useSelector(currencyByCodeSelector);
  const currency = getCurrencyByCode(pricing.currency);
  return (
    <PricingRowWrapper>
      <div className="header-text">
        <PricingTitle>
          <div>
            {currency.symbol}
            <span style={{ fontWeight: 'bold' }}>{pricing.amount.toFixed(2)}</span> {currency.code}
            <span> / {pricing.interval}</span>
          </div>
        </PricingTitle>
      </div>

      <div className="header-menu">
        <Dropdown direction="left" icon={<Icon name="ellipsis-h" inElement />}>
          <Dropdown.Menu>
            <PricingLinkItem planId={pricing.id}></PricingLinkItem>
            {active === 0 ? <PricingDeletePrompt pricing={pricing} onDelete={onDelete} /> : null}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </PricingRowWrapper>
  );
};

export const EditPackageModal = ({ pack, updatePack }) => {
  const [planName, setPlanName] = useState(pack.name);
  const [wordLimit, setWordLimit] = useState(pack.word_limit);
  const [privatePlan, setPrivatePlan] = useState(pack.private === 1);
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);

  const updatePackage = async () => {
    try {
      setUpdating(true);

      const data = await updateProduct({
        id,
        name: planName,
        word_limit: wordLimit,
        privatePlan: privatePlan,
        active: pack.active,
      });

      toast.success(t('common:toasts.updatePackage.success'));
      updatePack(data);
      setOpen(false);
    } catch (e) {
      toast.error(t('common:toasts.updatePackage.error'));
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Modal
      trigger={<Dropdown.Item text={t('common:admin.package.editPackage')} />}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>{t('common:admin.package.editPackage')}</Modal.Header>
      <Modal.Content>
        <PlanForm
          planName={planName}
          setPlanName={setPlanName}
          wordLimit={wordLimit}
          setWordLimit={setWordLimit}
          privatePlan={privatePlan}
          setPrivatePlan={setPrivatePlan}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={() => setOpen(false)} disabled={updating}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="primary" onClick={updatePackage} disabled={updating} loading={updating}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const remainingPricing = (pricings) => {
  const remainingCominations = remainingPricingCombinations(pricings);
  return {
    amount: 0,
    interval: remainingCominations[0].interval,
    currency: remainingCominations[0].currency.code.toLowerCase(),
  };
};

export const AddPricingModal = ({ productId, pricings, updatePricing = () => {} }) => {
  const [pricing, setPricing] = useState(remainingPricing(pricings));
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setPricing(remainingPricing(pricings));
  }, [open, pricings]);

  const addPackage = async () => {
    try {
      setUpdating(true);
      const data = await postPlan({
        product_id: productId,
        name: '' + pricing.interval + pricing.currency,
        interval: pricing.interval,
        amount: pricing.amount,
        currency: pricing.currency,
      });

      toast.success(t('common:toasts.updatePackage.success'));
      updatePricing(data);
      setOpen(false);
    } catch (e) {
      toast.error(t('common:toasts.updatePackage.error'));
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Modal
      size="tiny"
      trigger={
        <AddPricing AddPricing>
          <div className="header-text">{t('common:admin.createPackage.pricing.addPricing')}</div>
          <div className="header-icon">
            <Icon name="plus" />
          </div>
        </AddPricing>
      }
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>{t('common:admin.package.editPackage')}</Modal.Header>
      <Modal.Content>
        {hasSameCombination([...pricings, pricing]) && (
          <Message text={t('common:admin.package.pricingDuplicateWarning')} type="warning" />
        )}
        <PricingForm pricing={pricing} onChange={(pricing) => setPricing(pricing)} />
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={() => setOpen(false)} disabled={updating}>
          {t('common:cancel')}
        </Button>
        <Button
          actiontype="primary"
          onClick={addPackage}
          disabled={hasSameCombination([...pricings, pricing]) && updating}
          loading={updating}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
