import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, InputsContainer } from './Inputs.styles';
import { Notice } from './VatNotice.styles';
import { withTheme } from 'styled-components';
import { Icon } from '../../Icon/Icon';

const VatNotice = ({ paysVat, theme }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Icon name="usd-circle" size="large" color={theme.colors.blue} style={{ display: 'block' }} />
      <InputsContainer>
        <Notice>
          {paysVat
            ? t('common:userInfo.billing.labels.vatCharged')
            : t('common:userInfo.billing.labels.vatNotCharged')}
        </Notice>
      </InputsContainer>
    </Container>
  );
};

export default withTheme(VatNotice);
