import Loader from 'components/common/Loader/Loader';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { Icon } from '../../common/Icon/Icon';
import Price from '../../common/Price/Price';

import { getDayName } from '../../../utils/string.utils';
import { formatDate } from 'utils/date.utils';

const Container = styled.div`
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: 4px 4px 18px -4px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  user-select: none;

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    place-items: center;

    background: rgba(255, 255, 255, 0.8);
  }

  ${({ theme }) => theme.mediaMinWidth.mobile`
    margin-bottom: 2em;
  `}
`;

const FlexContainer = styled.div`
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    flex-wrap: wrap;
  `}
`;

const Handle = styled.div`
  position: absolute;
  height: 100%;
  width: 29px;
  button {
    position: absolute;
    width: 29px;
    height: 58px;
    top: 50%;
    border: none;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ disabled, theme }) => (disabled ? '#e1e1e1' : theme.colors.blue)};
    color: white;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    display: none;
  `}
`;

const LeftHandle = styled(Handle)`
  left: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
  button {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

const RightHandle = styled(Handle)`
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
  button {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
`;

const StyledDay = styled.div`
  height: 140px;
  min-width: 126px;
  text-align: center;
  cursor: pointer;
  border: 2px solid transparent;
  border-right: 1px solid #e1e1e1;
  transition: background-color, border-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueLighter};
  }

  .mobile-select {
    display: none;
    border-radius: 100%;
    height: 22px;
    width: 22px;
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.grey};

    &::before {
      background-color: ${({ theme }) => theme.colors.blue};
      border-radius: 100%;
      content: '';
      height: 12px;
      width: 12px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scaleY(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  &.selected {
    border-color: ${({ theme }) => theme.colors.blue};
    border-right: 2px solid ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme }) => theme.colors.blueLighter};

    .mobile-select {
      border: 2px solid ${({ theme }) => theme.colors.blue};

      &::before {
        transform: translate(-50%, -50%) scaleY(1);
      }
    }
  }

  .day-name {
    margin-top: 2em;
  }

  .date {
    font-size: 16px;
    font-weight: bold;
  }

  .price {
    margin-top: 1em;
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }

  &.disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.greyLight};

    .price {
      color: ${({ theme }) => theme.colors.grey};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.greyLight};
    }
  }

  &.increased {
    &.selected {
      border-color: ${({ theme }) => theme.colors.red};
      border-right: 2px solid ${({ theme }) => theme.colors.red};
      background-color: ${({ theme }) => theme.colors.redLighter};
    }
    .price {
      color: ${({ theme }) => theme.colors.red};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.redLighter};
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    background-color: ${theme.colors.white};
    display: grid;
    grid-template-areas: 
      'mobileselect dayname price'
      'mobileselect date price';
    grid-template-columns: 22px auto min-content;
    grid-template-rows: max-content;
    padding: 20px ${theme.paddings.mainContainer.mobile.x};
    height: auto;
    width: 100%;

    &.increased.selected {
      .mobile-select {
        border: 2px solid ${theme.colors.red};

        &::before {
          background-color: ${theme.colors.red};
        }
      }
    }

    .day-name,
    .price {
      margin-top: 0;
    }

    .day-name,
    .date {
      text-align: left;
      padding-left: 8px;
    }

    .mobile-select-wrapper {
      grid-area: mobileselect;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .day-name {
      grid-area: dayname;
    }

    .date {
      grid-area: date;
    }

    .price {
      grid-area: price;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mobile-select {
      display: block;
    }
  `}
`;

const Day = ({ day, id, currency, containerRef, selected, increased, disabled, onClick, cypressID }) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const handleClick = () => {
    if (!disabled) {
      onClick(day);
    }
  };

  const handleFocus = (e) => {
    if (!ref.current || !containerRef.current) return;

    const containerRight = containerRef.current.getBoundingClientRect().right;
    const dayRight = ref.current.getBoundingClientRect().right;
    if (dayRight > containerRight) {
      ref.current.scrollIntoView(true);
    }
  };

  const DayComponent = (
    <StyledDay
      ref={ref}
      id={id}
      onClick={handleClick}
      selected={selected}
      tabIndex={0}
      onFocus={handleFocus}
      disabled={disabled}
      data-cy={cypressID}
      className={`${selected ? 'selected' : ''} ${increased && !disabled ? 'increased' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <div className="mobile-select-wrapper">
        <div className="mobile-select"></div>
      </div>
      <div className="day-name">{day.dayName}</div>
      <div className="date">{day.dateString}</div>
      <div className="price">
        {disabled ? (
          t('common:unavailable')
        ) : (
          <>
            + <Price price={day.price} currency={currency} />
          </>
        )}
      </div>
    </StyledDay>
  );

  return increased && day.multiplierNotice ? (
    <Popup position="bottom center" trigger={DayComponent} content={day.multiplierNotice} />
  ) : (
    DayComponent
  );
};

/**
 *
 */
const DateSelection = ({ deliveryDates, onChange = () => {}, currency, loading, accordionOpen, ...rest }) => {
  const [isLeftDisabled, setIsLeftDisabled] = useState(false);
  const [selectedDay, setSelectedDay] = useState({ date: new Date() });
  const containerRef = useRef(null);

  const days = useMemo(() => {
    const dates = deliveryDates.map((date) => {
      const dateObject = new Date(date.date);
      return {
        id: date.id,
        dayName: getDayName(dateObject),
        dateString: formatDate({
          dateObject: dateObject,
          showTime: false,
          showYear: false,
        }),
        price: date.surcharge,
        date: dateObject,
        multiplier: date.priceMultiplier,
        multiplierNotice: date.label,
        disabled: date.enabled === 0,
        increased: date.isWeekend === 1,
      };
    });

    const datesBefore = [];

    let lastDate = deliveryDates.length > 0 ? new Date(deliveryDates[0]?.date) : new Date();

    for (let i = 0; i < 2; i++) {
      const currDate = new Date();
      currDate.setDate(lastDate.getDate() - 1);
      datesBefore.unshift({
        id: currDate.toString(),
        dayName: getDayName(currDate),
        dateString: formatDate({ dateObject: currDate, showTime: false, showYear: false }),
        price: 0,
        date: currDate,
        multiplier: 1,
        multiplierNotice: '',
        disabled: true,
        increased: false,
      });

      lastDate.setDate(lastDate.getDate() - 1);
    }
    return [...datesBefore, ...dates];
  }, [deliveryDates]);

  const handleWheel = (e) => {
    containerRef.current.scrollLeft -= e.deltaY;
  };

  const handleScroll = () => {
    const { scrollLeft } = containerRef.current;

    setIsLeftDisabled(scrollLeft === 0);
  };

  const handleNextClick = () => {
    const left = containerRef.current.scrollLeft + 378;
    containerRef.current.scrollTo({ left, behavior: 'smooth' });
  };

  const handlePreviousClick = () => {
    const left = Math.max(0, containerRef.current.scrollLeft - 378);
    containerRef.current.scrollTo({ left: left, behavior: 'smooth' });
  };

  const callbacks = useSwipe(containerRef);

  const handleClick = (day) => {
    onChange(day.id);
    setSelectedDay(day);
  };

  useEffect(() => {
    const day = deliveryDates.find((date) => date.selected === 1);

    if (day) {
      setSelectedDay({ ...day, date: new Date(day.date) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDates]);

  useEffect(() => {
    const dayElement = document.querySelector(`#a${selectedDay.id}`);

    if (dayElement) {
      dayElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [selectedDay]);

  return (
    <Container onWheel={handleWheel} onScroll={handleScroll} {...callbacks} {...rest}>
      {loading ? (
        <div className="loader">
          <Loader inline />
        </div>
      ) : null}
      <FlexContainer ref={containerRef}>
        <LeftHandle>
          <button
            data-cy="date-selection-prev-button"
            onClick={handlePreviousClick}
            disabled={isLeftDisabled}
          >
            <Icon name="arrow-left" />
          </button>
        </LeftHandle>

        {days.map((day, i) => (
          <Day
            key={day.id}
            id={'a' + day.id}
            day={day}
            selectedDay={selectedDay}
            currency={currency}
            selected={day.date.toDateString() === selectedDay.date.toDateString()}
            increased={day.increased}
            onClick={handleClick}
            containerRef={containerRef}
            disabled={day.disabled}
            cypressID="date-selection-day"
          />
        ))}
        <RightHandle onClick={handleNextClick}>
          <button
            data-cy="date-selection-next-button"
            onClick={handlePreviousClick}
            disabled={isLeftDisabled}
          >
            <Icon name="arrow-right" />
          </button>
        </RightHandle>
      </FlexContainer>
    </Container>
  );
};

/**
 * Main delivery date selection component
 */
const DeliveryDate = ({ deliveryDates, loading = false, project, onChange = (dateId) => {}, open }) => {
  return (
    <DateSelection
      id="order-project-delivery-date-selector"
      deliveryDates={deliveryDates}
      currency={project.currency}
      loading={loading}
      onChange={onChange}
      accordionOpen={open}
    />
  );
};

export default DeliveryDate;

function useSwipe(ref) {
  const isDown = useRef(false);
  const startX = useRef(null);
  const scrollLeft = useRef(null);

  const onMouseDown = (e) => {
    e.preventDefault();
    isDown.current = true;
    startX.current = e.clientX;
    if (ref.current) scrollLeft.current = ref.current.scrollLeft;
  };

  const onMouseUp = (e) => {
    e.preventDefault();
    isDown.current = false;
  };

  const onMouseLeave = (e) => {
    isDown.current = false;
  };

  const onMouseMove = (e) => {
    if (!isDown.current || !ref.current) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = x - startX.current;
    ref.current.scrollLeft = scrollLeft.current - walk;
  };

  return {
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseLeave,
  };
}
