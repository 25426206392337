import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Header } from 'semantic-ui-react';
import Accordion from 'components/common/Accordion/Accordion';
import { Icon } from 'components/common/Icon/Icon';
import Button from 'components/common/Button/Button';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { FormGroup, ProjectUpdateWrapper, StyledTitle } from './UpdateProject.style';
import {
  getProject,
  getProjectJobs,
  postUpdateProjectById,
  patchProjectJob,
  createProjectJobs,
  deleteProjectJob,
} from 'services/project';
import Loader from 'components/common/Loader/Loader';
import { toast } from 'react-toastify';
import { MultipleDropdown, SelectDropdown } from 'components/common/Dropdown/Dropdown';
import { Input } from 'components/common/Input/Input';
import { StyledDropdownItem } from 'components/NewProject/CreateProject/NameAndLanguages/NameAndLanguages.styles';
import { AddLanguage } from 'components/NewProject/CreateProject/NameAndLanguages/NameAndLanguages.styles';
import OriginalFilesDownload from 'components/common/OriginalFilesDownload/OriginalFilesDownload';
import { useValidation } from 'hooks/useValidation';

// JobField component now includes jobTotalWords input at the job level.

const JobField = ({ job, onChange = () => {}, onJobRemove = () => {}, analyze = false, targetLangs }) => {
  const { t } = useTranslation();
  const languages = useSelector((state) => state.classifiersStore.languages);
  const sourceLangs = useMemo(() => {
    const parsedLanguages = languages.map((lang) => {
      return {
        key: lang.id,
        value: lang.code,
        text: t(`languages:${lang.code}`, lang.name),
      };
    });
    return parsedLanguages;
  }, [languages, t]);

  // Add form validation for jobTotalWords.
  const formValid = {
    payabledWordsValid: useValidation(job.payableWords, () => job.payableWords > 0),
    translatorPayableWordsValid: useValidation(
      job.translatorPayableWords,
      () => job.translatorPayableWords > 0,
    ),
    jobTotalWordsValid: useValidation(job.jobTotalWords, () => job.jobTotalWords > 0), // Add validation for total words
  };

  const title = (
    <StyledTitle>
      <div>{job.targetLang.text}</div>
    </StyledTitle>
  );

  return (
    <Accordion title={title} initialOpen={true}>
      <FormGroup>
        <div className="field">
          <SelectDropdown
            label={t('common:admin.createProject.jobs.targetLanguage')}
            required
            name="sourceLanguage"
            value={job.targetLang.value}
            onChange={(e, { value }) => {
              const selectedLang = sourceLangs.find((lang) => lang.value === value);
              const newJob = { ...job, targetLang: selectedLang };
              onChange(newJob);
            }}
            selection
            search
            options={targetLangs}
            placeholder={t('common:admin.createProject.jobs.targetLanguage')}
          ></SelectDropdown>
        </div>

        {/* Add the new totalWords input on the job level */}
        <div className="field">
          <Input
            label={t('common:admin.createProject.jobs.jobsTotalWords')}
            required
            error={formValid.jobTotalWordsValid !== null ? !formValid.jobTotalWordsValid : false}
            type="number"
            placeholder={t('common:admin.createProject.jobs.jobsTotalWords')}
            value={job.jobTotalWords || ''} // Ensure it's either the value or an empty string
            onChange={(e) => {
              const newJob = { ...job, jobTotalWords: e.target.value };
              onChange(newJob);
            }}
          ></Input>
        </div>

        {!analyze && (
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.payableWords')}
              required
              error={formValid.payabledWordsValid !== null ? !formValid.payabledWordsValid : false}
              type="number"
              placeholder={t('common:admin.createProject.jobs.payableWords')}
              value={job.payableWords || ''}
              onChange={(e) => {
                const newJob = { ...job, payableWords: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        )}
        {!analyze && (
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.translatorPayableWords')}
              required
              error={
                formValid.translatorPayableWordsValid !== null
                  ? !formValid.translatorPayableWordsValid
                  : false
              }
              type="number"
              placeholder={t('common:admin.createProject.jobs.translatorPayableWords')}
              value={job.translatorPayableWords || ''}
              onChange={(e) => {
                const newJob = { ...job, translatorPayableWords: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        )}
      </FormGroup>

      {!analyze && (
        <FormGroup>
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.catURL')}
              type="text"
              placeholder={t('common:admin.createProject.jobs.catURL')}
              value={job.catUrl || ''}
              onChange={(e) => {
                const newJob = { ...job, catUrl: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        </FormGroup>
      )}
      {!analyze && (
        <FormGroup>
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.catId')}
              type="text"
              placeholder={t('common:admin.createProject.jobs.catId')}
              value={job.catId || ''}
              onChange={(e) => {
                const newJob = { ...job, catId: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
          <div className="field">
            <Input
              label={t('common:admin.createProject.jobs.catPass')}
              type="text"
              placeholder={t('common:admin.createProject.jobs.catPass')}
              value={job.catPass || ''}
              onChange={(e) => {
                const newJob = { ...job, catPass: e.target.value };
                onChange(newJob);
              }}
            ></Input>
          </div>
        </FormGroup>
      )}
    </Accordion>
  );
};

const UpdateProject = () => {
  const mountedRef = useRef(true); // Moved inside the component
  const [project, setProject] = useState(null);
  const [initialJobs, setInitialJobs] = useState(null);
  const [projectType, setProjectType] = useState(1);
  const [projectName, setProjectName] = useState('');
  const [sourceLang, setSourceLang] = useState(null);
  const [category, setCategory] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [targetLanguages, setTargetLanguages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const formValid = {
    projectNameValid: useValidation(projectName, () => !!projectName),
    sourceLangValid: useValidation(sourceLang, () => !!sourceLang),
    jobsValid: useValidation(
      jobs,
      () =>
        jobs.length > 0 &&
        jobs.reduce(
          (acc, job) =>
            acc &&
            !!job.targetLang.value &&
            job.payableWords > 0 &&
            job.translatorPayableWords > 0 &&
            job.jobTotalWords > 0, // Validate job total words as well
          true,
        ),
    ),
    categoryValid: useValidation(category, () => !!category),
  };

  const isValid = useMemo(() => {
    if (projectType === 2) {
      return formValid.projectNameValid && formValid.sourceLangValid && formValid.categoryValid;
    } else {
      return (
        formValid.projectNameValid &&
        formValid.sourceLangValid &&
        formValid.categoryValid &&
        formValid.jobsValid
      );
    }
  }, [formValid, projectType]);

  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const allCategories = useSelector((state) => state.classifiersStore.categories);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false; // Cleanup to mark the component as unmounted
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchProjectData = async () => {
      try {
        setIsLoading(true);
        const project = await getProject(id, { signal });
        const jobs = await getProjectJobs(id, { signal });

        if (mountedRef.current) {
          // Ensure the component is still mounted
          const { name, project_type_id, source_language, category_id } = project.data;

          setProjectName(name || '');
          setProjectType(project_type_id);
          setSourceLang(source_language.id);
          setCategory(category_id);
          setProject(project.data);

          const newJobs = jobs.map((job) => ({
            id: job.id,
            targetLang: {
              key: job.target_language.id,
              value: job.target_language.id,
              text: t(`languages:${job.target_language.code}`, job.target_language.name),
            },
            catUrl: job.job_url,
            translatorPayableWords: job.translator_payable_words,
            payableWords: job.payable_words,
            jobTotalWords: job.total_words || '', // Ensure total words is mapped
            catId: job.job_id,
            catPass: job.job_pass,
          }));

          setJobs(newJobs);
          setInitialJobs(newJobs);
        }
      } catch (e) {
        if (e.name === 'AbortError') {
          // console.log('Fetch aborted');
        } else {
          console.error(e);
        }
      } finally {
        if (mountedRef.current) {
          setIsLoading(false);
        }
      }
    };

    fetchProjectData();

    return () => {
      controller.abort(); // Abort async requests
      mountedRef.current = false; // Mark component as unmounted
    };
  }, [id, t]);

  const languages = useSelector((state) => state.classifiersStore.languages);
  const languageOptions = useMemo(() => {
    const parsedLanguages = languages.map((lang) => {
      return {
        key: lang.id,
        value: lang.id,
        text: t(`languages:${lang.code}`, lang.name),
      };
    });
    return parsedLanguages;
  }, [languages, t]);

  const sourceLanguageOptions = useMemo(() => {
    return languageOptions.filter((lang) => {
      if (projectType === 1) {
        return !targetLanguages.includes(lang.value);
      }
      return true;
    });
  }, [languageOptions, targetLanguages, projectType]);

  const targetLanguageOptions = useMemo(() => {
    return languageOptions.filter((lang) => {
      if (projectType === 1) {
        return lang.value !== sourceLang;
      }
      return !targetLanguages.includes(lang.value);
    });
  }, [languageOptions, targetLanguages, projectType, sourceLang]);

  useEffect(() => {
    if (!initialJobs) {
      const newJobs = targetLanguages.map((lang) => {
        const langOption = languageOptions.find((l) => l.value === lang);
        return {
          targetLang: langOption,
          payableWords: 0,
          jobTotalWords: 0, // Initialize total words on job creation
          catUrl: '',
          catId: '',
          catPass: '',
          translatorPayableWords: 0,
        };
      });
      setJobs(newJobs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jobFields = jobs.map((job, i) => (
    <JobField
      key={'job' + job.targetLang.key}
      job={job}
      onChange={(job) => {
        setJobs((prevJobs) => {
          const updatedJobs = prevJobs.map((existingJob) =>
            existingJob.id === job.id ? { ...existingJob, ...job } : existingJob,
          );
          return updatedJobs;
        });
      }}
      onJobRemove={() => {
        setJobs((prevJobs) => prevJobs.filter((_, index) => index !== i));
        setTargetLanguages((prevLangs) => prevLangs.filter((_, index) => index !== i));
      }}
      targetLangs={targetLanguageOptions}
    />
  ));

  const handleCategoriesChange = (e, data) => {
    const { value } = data;
    e.preventDefault();
    setCategory(value);
  };

  const categoryOptions = allCategories.map(({ id, name, code }) => {
    return {
      key: id,
      text: t(`categories:${code}`, name),
      value: id,
    };
  });

  const handleProjectUpdate = async () => {
    try {
      setIsSubmitting(true);
      const deleteJobs = [];
      const createJobs = [];
      const patchJobs = [];

      for (const job of jobs) {
        if (!!initialJobs.find((initialJob) => initialJob.targetLang.value === job.targetLang.value)) {
          patchJobs.push(job);
        } else {
          createJobs.push(job);
        }
      }

      for (const initialJob of initialJobs) {
        if (!jobs.find((job) => initialJob.targetLang.value === job.targetLang.value)) {
          deleteJobs.push(initialJob);
        }
      }

      await Promise.all(
        patchJobs.map((job) =>
          patchProjectJob({
            payable_words: job.payableWords,
            translator_payable_words: job.translatorPayableWords,
            job_total_words: job.jobTotalWords,
            job_id: job.catId,
            job_pass: job.catPass,
            job_url: job.catUrl,
            id: job.id,
          }),
        ),
      );

      await Promise.all(
        createJobs.map((job) =>
          createProjectJobs({
            project_id: id,
            target_language_id: job.targetLang.value,
            payable_words: job.payableWords,
            translator_payable_words: job.translatorPayableWords,
            job_total_words: job.jobTotalWords,
            job_url: job.catUrl,
            job_id: job.catId,
            job_pass: job.catPass,
          }),
        ),
      );

      await Promise.all(
        deleteJobs.map((job) =>
          deleteProjectJob({
            id: id,
          }),
        ),
      );

      if (mountedRef.current) {
        await postUpdateProjectById({
          category_id: category,
          id,
          name: projectName,
          status_id: project.status_id === 22 ? 2 : undefined,
        });

        history.push(`/project/${id}`);
      }
    } catch (e) {
      console.error(e);
      toast.error(t('common:toasts.updateProject.error'));
    } finally {
      if (mountedRef.current) {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <ProjectUpdateWrapper>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          <div className="project-create-header">
            <Header as="h1">{t('common:admin.updateProject.header')}</Header>
          </div>
          <div className="button-container">
            <OriginalFilesDownload projectId={id}></OriginalFilesDownload>
          </div>

          <div className="project-create-form">
            <div className="project-create-settings">
              <Form>
                <Form.Field>
                  <Input
                    label={t('common:admin.createProject.projectName')}
                    placeholder={t('common:admin.createProject.projectName')}
                    value={projectName || ''}
                    onChange={(e) => {
                      setProjectName(e.target.value);
                    }}
                    required
                    error={formValid.projectNameValid !== null ? !formValid.projectNameValid : false}
                  ></Input>
                </Form.Field>

                <Form.Field>
                  <SelectDropdown
                    label={t('common:admin.createProject.sourceLanguage')}
                    name="sourceLanguage"
                    value={sourceLang || ''}
                    onChange={(e, { value }) => {
                      setSourceLang(value);
                    }}
                    options={sourceLanguageOptions}
                    selection
                    search
                    emptyState={t('common:projects.createProject.noLanguagesFound')}
                    placeholder={t('common:projects.createProject.selectALanguage')}
                    style={{ width: '50%' }}
                    required
                    error={formValid.sourceLangValid !== null ? !formValid.sourceLangValid : false}
                  />
                </Form.Field>

                <Form.Field>
                  <SelectDropdown
                    label={t('common:serviceProvider.combinations.categories')}
                    name="categories"
                    value={category || ''}
                    onChange={handleCategoriesChange}
                    selection
                    search
                    options={categoryOptions}
                    placeholder={t('common:serviceProvider.combinations.categories')}
                    required
                    error={formValid.categoryValid !== null ? !formValid.categoryValid : false}
                  />
                </Form.Field>
              </Form>
            </div>
            {projectType !== 2 && (
              <div className="project-create-jobs">
                <Header as="h2">{t('common:admin.createProject.jobs.header')}</Header>
                <div>
                  <Form>
                    <Form.Field>
                      <MultipleDropdown
                        label={t('common:admin.createProject.jobs.header')}
                        required
                        name="targetLanguages"
                        value={targetLanguages || ''}
                        onChange={(e, { value }) => {
                          setTargetLanguages(value);
                        }}
                        placeholder={t('common:admin.createProject.jobs.addPlaceholder')}
                        options={targetLanguageOptions}
                        multiple
                        selection
                        search
                        emptyState={t('common:projects.createProject.noLanguagesFound')}
                        fluid
                        preventDeleting={true}
                        renderItem={(props) => (
                          <StyledDropdownItem {...props}>
                            {props.item.icon}
                            {props.item.text}
                            <AddLanguage>
                              <Icon name="plus" /> {t('common:projects.createProject.addLanguage')}
                            </AddLanguage>
                          </StyledDropdownItem>
                        )}
                      />
                    </Form.Field>
                  </Form>
                </div>
                {jobFields}
              </div>
            )}
          </div>
          <Button
            actiontype="primary"
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            onClick={handleProjectUpdate}
          >
            {t('common:admin.updateProject.button')}
          </Button>
        </>
      )}
    </ProjectUpdateWrapper>
  );
};

export default UpdateProject;
