import { uniq } from 'lodash';

import { getBillingDetails } from 'services/auth';
import { getCompanyTeams } from 'services/company';
import { getBillingSubscriptions } from 'services/subscriptions';
import { getSubscriptionById } from 'services/subscriptions';
import { getTeamsSubscriptions } from './initialLoad';
import { checkAllowedRole, checkTeamRole, isCompanyRole } from './user.utils';

import { ADMIN_ROLES } from 'constants/roles';

export const hasActiveSubscriptions = (subscriptions = []) => {
  return subscriptions.reduce(
    (acc, cur) => acc || cur.stripe_status === 'active' || cur.type === 'free' || cur.type === 'legacy',
    false,
  );
};

export const hasFreeSubscriptions = (subscriptions = []) => {
  return subscriptions.reduce((acc, cur) => acc || cur.type === 'free', false);
};

export const getActiveStripeSubscription = ({ subscriptions = [], billingId }) => {
  return subscriptions.reduce((acc, current) => {
    if (current.stripe_status === 'active' && current.billing_detail_id === billingId) {
      return current;
    }
    return acc;
  }, null);
};

export const canToggleCatapult = ({ project, user, subscriptions = [] }) => {
  // first check that project.diy is null
  if (project.diy !== null) {
    return false;
  }
  // then check if user is a company user
  if (isCompanyRole(user.role)) {
    // if user has free or legacy subscription still enable ordering catapult projects regardless of team status
    if (!!user.subscription) {
      if (user.subscription.type === 'legacy' || user.subscription.type === 'free') {
        return true;
      }

      // check that project team has correct billing details
      const billingDetailIds = subscriptions.reduce((acc, curr) => {
        if (acc.indexOf(curr.billing_detail_id) < 0 && curr.stripe_status === 'active') {
          acc.push(curr.billing_detail_id);
        }
        return acc;
      }, []);

      // if project team does not have a subscription
      if (billingDetailIds.indexOf(project.team.billing_detail_id) < 0) {
        return false;
      }

      // if company manager return true since he can order catapult project regardless of seats
      if (user.role === 40) {
        return true;
      }

      // if company user check that user has active subscription seat
      if (!!user.subscription_seat) {
        // check that subscription id matches the team billing ID and is active
        const teamSubscription = subscriptions.find(
          (sub) => sub.billing_detail_id === project.team.billing_detail_id,
        );
        if (!!teamSubscription) {
          return (
            teamSubscription.id === user.subscription_seat.subscription_id &&
            user.subscription_seat.active === 1
          );
        }
        return false;
      }

      // if role 42 and no subscription seat return false
      return false;
    }
    // if company user and no subscription model return false
    return false;
  }

  // if not company user and project.diy === null return true
  return true;
};

export const collectUserSubscriptions = async (user) => {
  let subscriptions = [];

  const { company_owner_id, id, teams, billing_detail, free_subscription, subscription_seat } = user;

  if (!!free_subscription) {
    subscriptions = [...subscriptions, free_subscription];
  }

  if (!!company_owner_id) {
    const companyTeams = await getCompanyTeams(company_owner_id);
    const subs = await getTeamsSubscriptions(companyTeams);
    subscriptions = [...subscriptions, ...subs];
  }

  if (!company_owner_id && !!subscription_seat) {
    const subs = await getTeamsSubscriptions(teams);

    const subscriptionsDetails = await Promise.all(
      subs.map(async (sub) => {
        const data = await getSubscriptionById(sub.id);
        return data;
      }),
    );

    // filter out subscriptions for which user role 42 has data
    const userSubscriptions = subscriptionsDetails.filter((sub) => {
      return !!sub.auth_user_data;
    });

    if (billing_detail) {
      const billingDetails = await getBillingDetails(id);
      const billingSubscriptions = await getBillingSubscriptions({ billing_id: billingDetails.data.id });

      subscriptions = [...subscriptions, ...billingSubscriptions.data];
    }

    subscriptions = [...subscriptions, ...userSubscriptions];
  }

  return subscriptions;
};

// gets all teams where the passed user can order a new Catapult subscription
// subscriptions passed should be tied to the passed user account
export const getTeamsToOrder = ({ user, subscriptions }) => {
  const { teams } = user;
  const billingsTaken = uniq(subscriptions, 'billing_detail_id').map((sub) => sub.billing_detail_id);
  const teamsToOrder = teams.filter((team) => {
    const { billing_detail_id, role_id } = team;
    const isManager = checkTeamRole({ allowedRoles: [1, 4], teamRole: role_id });
    const hasSubscription = billingsTaken.indexOf(billing_detail_id) > -1;

    return !!isManager && !hasSubscription;
  });

  return teamsToOrder;
};

// checks if user can order a catapult subscription for any for his teams or for himself (as individual)
export const canOrderCatapultSubscription = ({ user }) => {
  const { role, teams } = user;
  // admins, individual users and company owners can always order subscriptions
  if (checkAllowedRole({ allowedRoles: [10, 40, ...ADMIN_ROLES], userRole: role })) {
    return true;
  } else if (checkAllowedRole({ allowedRoles: [42], userRole: role })) {
    // user role 42 can only order a subscription for teams where he is a team manager or the team billing manager
    const hasCorrectTeamRole = teams.reduce((canOrder, team) => {
      // if user can already order for another team that always holds true
      if (canOrder) {
        return true;
      } else {
        const isCorrectRole = checkTeamRole({
          // team and billing managers
          allowedRoles: [1, 4],
          teamRole: team.role_id,
        });
        return isCorrectRole;
      }
    }, false);
    return hasCorrectTeamRole;
  }
  return false;
};
