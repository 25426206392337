import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LogText } from './ActivityLogs.styles';
import { Popup } from 'semantic-ui-react';
import Table from '../../common/Table/Table';

import { hasAdminPermissions } from '../../../utils/user.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { formatAPIDateString } from 'utils/date.utils';

const LogTable = ({ logs }) => {
  const userRole = useSelector((state) => state.userStore.user.role);

  return (
    <Table width="100%" marginTop="0" compact>
      <Table.Body>
        {logs.map((log) => {
          const { created_at, event, id, user } = log;

          return (
            <Table.Row key={id}>
              <Table.Cell style={{ paddingLeft: '8px' }}>
                {formatAPIDateString({ dateString: created_at })}
              </Table.Cell>
              <Table.Cell>{event}</Table.Cell>
              <Table.Cell>
                {hasAdminPermissions(userRole) ? (
                  <Link
                    onClick={() => sendUserInteraction('project activity log admin user name click')}
                    to={`/admin/user/${user.id}`}
                  >{`${user.name}`}</Link>
                ) : (
                  user.name
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const ActivityLog = ({ logs = [] }) => {
  const { t } = useTranslation();

  return (
    <Popup
      flowing
      closeOnPortalMouseLeave
      trigger={<LogText>{t('common:admin.project.logs.heading')}</LogText>}
    >
      <LogTable logs={logs} />
    </Popup>
  );
};

export default ActivityLog;
