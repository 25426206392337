import Pagination from '../../common/Pagination/Pagination';
import styled from 'styled-components';

export const TeamInvoicesLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.42857143em;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;

  .button {
    height: 42px;
    margin-right: 0.6em;
  }
`;

export const FilterLabel = styled.span`
  color: #999999;
  margin-right: 0.8em;
  font-size: 16px;
`;

export const StyledPagination = styled(Pagination)`
  margin-right: auto !important;
`;

export const StyledLabel = styled.div`
  width: fit-content;
  margin-left: auto;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 1px 10px;
  border-radius: 10px;
  color: ${({ paid }) => (paid ? '#32bf73' : '#cc8d00')};
  background-color: ${({ paid, theme }) => (paid ? '#dff7e5' : theme.colors.yellowLight)};
`;

export const StyledWrapper = styled.div`
  margin-left: auto;
  width: 22px;
  height: 22px;
`;
