import React from 'react';
import { Trans } from 'react-i18next';

export const STATUS = {
  1: <Trans i18nKey="common:projects.projectStatus.created">Created</Trans>,
  2: <Trans i18nKey="common:projects.projectStatus.draft">Draft</Trans>,
  3: <Trans i18nKey="common:projects.projectStatus.open">Open</Trans>,
  4: <Trans i18nKey="common:projects.projectStatus.inProgress"> In Progress</Trans>,
  5: <Trans i18nKey="common:projects.projectStatus.review">Review</Trans>,
  51: <Trans i18nKey="common:projects.projectStatus.revisionAssigned">Revision Assigned</Trans>,
  52: <Trans i18nKey="common:projects.projectStatus.revisionAccepted">Revision Accepted</Trans>,
  53: <Trans i18nKey="common:projects.projectStatus.revisionFinished">Revision Finished</Trans>,
  6: <Trans i18nKey="common:projects.projectStatus.finished">Finished</Trans>,
  20: <Trans i18nKey="common:projects.projectStatus.archived">Archived</Trans>,
  10: <Trans i18nKey="common:projects.projectStatus.selfServiceInProgress">Self-service - In progress</Trans>,
  11: <Trans i18nKey="common:projects.projectStatus.selfServiceFinished">Self-service - Finished</Trans>,
  21: <Trans i18nKey="common:projects.projectStatus.assignedForReview">Assigned for review</Trans>,
};

export const STATUS_COLORS = {
  1: '#7f7f7f',
  2: 'rgba(35, 132, 242, 0.23)',
  3: '#2384f2',
  4: 'rgba(39, 255, 171, 0.34901960784313724)',
  5: '#d53974',
  51: '#d53974',
  52: '#d53974',
  53: '#d53974',
  6: '#21d891',
  10: '#21d891',
  11: '#21d891',
  21: 'rgba(35, 132, 242, 0.23)',
};

export const JOB_STATUS = {
  1: 'Created',
  3: 'Ordered',
  4: 'Assigned',
  5: <Trans i18nKey="common:projects.jobStatus.accepted">Accepted</Trans>,
  51: <Trans i18nKey="common:projects.jobStatus.assigned">Assigned</Trans>,
  52: <Trans i18nKey="common:projects.jobStatus.accepted">Accepted</Trans>,
  53: <Trans i18nKey="common:projects.jobStatus.finished">Finished</Trans>,
  6: <Trans i18nKey="common:projects.jobStatus.finished">Finished</Trans>,
  10: <Trans i18nKey="common:projects.jobStatus.selfServiceInProgress">Self-service - In progress</Trans>,
  11: <Trans i18nKey="common:projects.jobStatus.selfServiceFinished">Self-service - Finished</Trans>,
  21: <Trans i18nKey="common:projects.projectStatus.assignedForReview">Assigned for review</Trans>,
};

export const JOB_STATUS_COLORS = {
  4: 'blue',
  5: 'pink',
  51: 'blue',
  52: 'pink',
  53: 'green',
  6: 'green',
  10: '#2384f2',
  11: '#2eb083',
};
