import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import FileDrop from '../../../common/FileDrop/FileDrop';
import { Icon } from '../../../common/Icon/Icon';
import Button from '../../../common/Button/Button';
import { StepFooter, StyledHeader } from '../../NewProject.styles';
import SupportedFiles from './SupportedFiles';
import { useNewProjectState } from '../../NewProject.reducer';

import { MAX_FILES } from 'constants/files';

const FilesUpload = ({ isNextDisabled, handleNextClick, handlePreviousClick, t }) => {
  const [
    {
      projectData: { files },
    },
    dispatch,
  ] = useNewProjectState();

  const handleFilesChange = (files) => {
    dispatch({ type: 'SET_FILES', payload: files });
  };

  const handleRemoveFile = (filename) => {
    dispatch({ type: 'REMOVE_FILE', payload: filename });
  };

  const fileLimitReached = useMemo(() => {
    return files.length >= MAX_FILES;
  }, [files]);

  return (
    <>
      {fileLimitReached ? null : (
        <StyledHeader>{t('common:projects.createProject.fileUploadHeader')}</StyledHeader>
      )}
      <>
        <FileDrop
          files={files}
          handleRemoveFile={handleRemoveFile}
          onFilesChange={handleFilesChange}
          addOnUnmount
        />

        <SupportedFiles />
      </>

      <StepFooter id="create-new-project-step-2-buttons" className="hide-on-mobile">
        <Button onClick={handlePreviousClick} big basic color="light-grey">
          {t('common:back')}
        </Button>
        <Button
          actiontype="primary"
          disabled={isNextDisabled}
          labelPosition="right"
          onClick={handleNextClick}
          fluid
          big
          data-cy="go-to-step-three-button"
        >
          {t('common:projects.createProject.continueToSubjectMatter')}
          <Icon name="arrow-right" />
        </Button>
      </StepFooter>
    </>
  );
};

export default withTranslation('common')(FilesUpload);
