import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0px;
  width: ${({ width }) => width || 'auto'};
  margin-top: ${({ marginTop }) => marginTop || '14px'};
  background-color: #fff;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;

  td,
  th {
    padding: 14px 8px 14px 14px;
    font-weight: 700;
    text-align: left;
  }
  thead tr th {
    background: #ededed;
    color: #999999;
    padding: 11px 14px 11px 8px;
  }

  thead tr th:first-child {
    padding-left: 36px;
  }

  thead tr th:last-child {
    padding-right: 23px;
  }

  tbody tr td {
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: ${({ theme }) => theme.colors.greyDark};
    padding: 17px 14px 16px 8px;
  }
  tbody tr td:first-child {
    padding-left: 36px;
    border-left: 1px solid #e9e9e9;
  }
  tbody tr td:last-child {
    border-right: 1px solid #e9e9e9;
    padding-right: 23px;
  }
  tbody tr:first-child td {
    border-top: 1px solid #e9e9e9;
  }
  tbody tr {
    cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
    background: ${({ negative, theme }) => (negative ? theme.colors.redLight : '#fff')};
    transition: background 0.1s ease-out;

    &:hover {
      background: ${({ selectable, theme }) => (selectable ? theme.colors.greyLight + '!important' : 'none')};
    }
  }
  ${({ compact }) =>
    compact &&
    css`
      tbody tr td {
        padding: 16px 7px 16px 6px;
      }
    `}
`;

export const StyledTableCell = styled.td.attrs((props) => ({
  colSpan: props.colspan,
}))`
  text-align: ${({ textAlign }) => textAlign || 'left'}!important;
  width: ${({ width }) => `${width * 6.25}%` || 'auto'};
  font-weight: ${({ bold }) => (bold ? 'bold!important' : '')};
`;

export const StyledTableHeaderCell = styled.th`
  width: ${({ width }) => `${width * 6.25}%` || 'auto'};
  text-align: ${({ textAlign }) => textAlign || 'left'}!important;
  position: relative;
`;

export const StyledTableRow = styled.tr`
  background: ${({ negative, theme }) => (negative ? theme.colors.redLight : '#fff')}!important;
  cursor: ${({ selectable }) => (selectable ? 'pointer !important' : 'default')};

  &:hover {
    background: ${({ selectable, theme }) => (selectable ? theme.colors.greyLight + '!important' : 'none')};
  }

  td {
    color: ${({ selectable, theme }) => (selectable ? theme.colors.blue + '!important' : 'inherit')};
    padding-top: ${({ paddingY }) => (paddingY ? paddingY + '!important' : '17px')};
    padding-bottom: ${({ paddingY }) => (paddingY ? paddingY + '!important' : '16px')};
  }

  &[aria-busy='true'] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const StyledTableHeader = styled.thead`
  tr th {
    ${({ width }) =>
      width
        ? css`
            width: width !important;
          `
        : ''}

    ${({ color, theme }) =>
      color === 'yellow'
        ? css`
            background: #ffdf9c !important;
            color: #cc8d00 !important;
          `
        : ''}
  }
`;
