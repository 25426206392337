import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyTeams: [],
  userTeams: [],
};

const teamsSlice = createSlice({
  name: 'classifiersSlice',
  initialState: { ...initialState },
  reducers: {
    setCompanyTeams(state, action) {
      const companyTeams = action.payload;
      state.companyTeams = companyTeams;
    },

    setUserTeams(state, action) {
      const teams = action.payload;
      state.userTeams = teams;
    },
  },
});

export const { setCompanyTeams, setUserTeams } = teamsSlice.actions;

export default teamsSlice.reducer;
