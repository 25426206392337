import store from '../store/store';
import { setUser } from '../store/userSlice';
import { getUser } from './auth';
import httpClient from './httpclient';

export const getAllTeams = async (company_id) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${company_id}/teams`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postTeams = async (company_id, name, billing_detail_id) => {
  try {
    const { data } = await httpClient.post(`/auth/company/${company_id}/teams`, {
      name: name,
      billing_detail_id: billing_detail_id,
    });

    const userInfo = await getUser();
    store.dispatch(setUser({ user: userInfo.data }));

    return data;
  } catch (e) {
    throw e;
  }
};

export const getTeam = async (team_id) => {
  try {
    const { data } = await httpClient.get(`/auth/teams/${team_id}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUpdateTeam = async ({ team_id, name, billing_detail_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/teams/${team_id}/update`, {
      name: name,
      billing_detail_id: billing_detail_id,
    });

    const userInfo = await getUser();
    store.dispatch(setUser({ user: userInfo.data }));

    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteTeam = async (team_id, user) => {
  try {
    const { data } = await httpClient.post(`/auth/teams/${team_id}/delete`);
    const newUser = { ...user, teams: user.teams.filter((team) => team.id !== team_id) };
    store.dispatch(setUser({ user: newUser }));
    return data;
  } catch (e) {
    throw e;
  }
};

export const getTeamMembers = async ({ team_id, per_page = 14, page = 1 }) => {
  try {
    const { data } = await httpClient.get(`/auth/teams/${team_id}/users`, {
      params: {
        per_page: per_page,
        page: page,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postMemberUpdate = async ({ team_id, user_id, is_contact, role_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/teams/${team_id}/users/${user_id}/update`, {
      is_contact,
      role_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteTeamMember = async ({ team_id, user_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/teams/${team_id}/users/${user_id}/delete`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postMemberInvite = async ({ team_id, email, role_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/teams/${team_id}/add-member`, {
      email,
      role_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getTeamPendingInvites = async ({ team_id }) => {
  try {
    const { data } = await httpClient.get(`/auth/teams/${team_id}/pending-invitations`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteTeamPendingInvites = async ({ invitation_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/team-invitation/${invitation_id}/delete`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getTeamInvoices = async ({
  team_id,
  page,
  per_page = 14,
  project_paid,
  issued_on_from,
  issued_on_to,
  payment_due_from,
  payment_due_to,
}) => {
  try {
    const { data } = await httpClient.get(`/auth/teams/${team_id}/invoices`, {
      params: {
        page,
        per_page,
        project_paid,
        issued_on_from,
        issued_on_to,
        payment_due_from,
        payment_due_to,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postJoinTeam = async ({ team_id, user_id, role = 'team manager' }) => {
  try {
    const { data } = await httpClient.post(`/auth/teams/${team_id}/join`, {
      user_id,
      role,
    });
    return data;
  } catch (e) {
    throw e;
  }
};
