import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, IntervalButton } from './IntervalToggle.styles';

const IntervalToggle = ({ onClick, showMonthlyPrices = true }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <IntervalButton
        onClick={onClick}
        $selected={showMonthlyPrices}
        data-selected={showMonthlyPrices}
        data-cy="packages-monthly-select"
      >
        {t('common:catapult.packages.monthly')}
      </IntervalButton>
      <IntervalButton
        onClick={onClick}
        $selected={!showMonthlyPrices}
        data-selected={!showMonthlyPrices}
        data-cy="packages-yearly-select"
      >
        {t('common:catapult.packages.yearly')}
      </IntervalButton>
    </Container>
  );
};

export default IntervalToggle;
