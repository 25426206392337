import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CategoryContainer, CategoriesContainer, StyledHeading, StyledSelectGroup } from './Category.styles';
import { StepFooter } from '../../NewProject.styles';
import Button from '../../../common/Button/Button';

import { Popup } from 'semantic-ui-react';
import { StyledSubtext } from '../FilesUpload/FilesUpload.styles';
import { Radio } from '../../../common/Radio/Radio';
import { Icon } from '../../../common/Icon/Icon';
import { Input } from '../../../common/Input/Input';
import { useNewProjectState } from '../../NewProject.reducer';
import { zindexPopperModifier } from 'constants/general';

const CategoryAndDiscount = ({ handlePreviousClick, submitProject, t, canSubmit }) => {
  const [search, setSearch] = useState('');
  const [categoriesType, setCategoriesType] = useState('popular');
  const [
    {
      projectData: { projectCategory },
    },
    dispatch,
  ] = useNewProjectState();
  const allCategories = useSelector((state) => state.classifiersStore.categories);

  const setProjectCategory = (categoryId) => {
    dispatch({ type: 'SET_PROJECT_CATEGORY', payload: categoryId });
  };

  const isSearched = (category) => {
    return category.name.toLowerCase().includes(search.toLowerCase());
  };

  const getCategories = () => {
    const categories =
      categoriesType === 'popular' ? allCategories.filter((cat) => cat.featured) : allCategories;
    return categories.filter((category) => isSearched(category) || category.id === projectCategory);
  };

  return (
    <>
      <StyledHeading className="hide-on-mobile">{t('common:projects.createProject.category')}</StyledHeading>
      <div id="create-new-project-subject-matter">
        <StyledSubtext>
          {t('common:projects.createProject.categoryDescription')}
          <Popup
            hoverable
            position="top center"
            trigger={<Icon name="info-circle" color="#1CAAC5" />}
            content={t('common:projects.createProject.categoryExplanation')}
            popperModifiers={[zindexPopperModifier]}
          />
        </StyledSubtext>
        <Input
          type="text"
          placeholder={t('common:projects.createProject.searchForCategory')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginTop: '1em' }}
          data-cy="search-for-category-input"
        />
        <StyledSelectGroup selected={categoriesType} onChange={setCategoriesType} fluid>
          <StyledSelectGroup.Option value="popular" data-cy="show-popular-categories-button">
            {t('common:projects.createProject.popularCategories')}
          </StyledSelectGroup.Option>
          <StyledSelectGroup.Option value="all" data-cy="show-all-categories-button">
            {t('common:projects.createProject.allCategories')}
          </StyledSelectGroup.Option>
        </StyledSelectGroup>
        <CategoriesContainer>
          {getCategories().length > 0 ? (
            getCategories().map((category) => (
              <CategoryContainer data-cy="categories-list" key={category.code}>
                <Radio
                  data-cy="single-category-radio-button"
                  data-value={category.code}
                  name="category"
                  checked={projectCategory === category.id}
                  onChange={() => setProjectCategory(category.id)}
                  label={category.name}
                  highlighted={search}
                  disabled={projectCategory === category.id && !isSearched(category)}
                />
              </CategoryContainer>
            ))
          ) : (
            <div>{t('common:projects.createProject.noCategoriesFound')}</div>
          )}
        </CategoriesContainer>
      </div>
      <StepFooter id="create-new-project-step-3-buttons" className="hide-on-mobile">
        <Button onClick={handlePreviousClick} big basic color="light-grey">
          {t('common:back')}
        </Button>
        <Button
          data-cy="create-project-button"
          actiontype="primary"
          onClick={submitProject}
          big
          disabled={!canSubmit}
        >
          {t('common:projects.createYourProject')}
        </Button>
      </StepFooter>
    </>
  );
};

export default withTranslation('common')(CategoryAndDiscount);
