import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { sendUserInteraction } from 'utils/tagManager.utils';
import Button from '../../common/Button/Button';
import { ProjectPaidContainer } from './ProjectPaid.style';

const ProjectPaid = ({ projectId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <ProjectPaidContainer>
      <Header as="h1">{t('common:checkout.projectPaid')}</Header>
      <div className="sub-header">
        {t('common:checkout.projectPaidDescription')}{' '}
        <Link
          onClick={() => {
            sendUserInteraction('clicked project link on project paid screen');
          }}
          to={`/project/${projectId}`}
        >{`Project ${projectId}`}</Link>
      </div>
      <Button
        actiontype="primary"
        onClick={() => {
          sendUserInteraction('clicked back to homepage on project paid screen');
          history.push('/');
        }}
      >
        {t('common:checkout.backToHomepage')}
      </Button>
    </ProjectPaidContainer>
  );
};

export default ProjectPaid;
