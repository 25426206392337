import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import BillingForm from 'components/Checkout/ReviewBilling/BillingForm';
import { CreateStepsWrapper } from '../CreateCompany.style';
import DescriptionInput from '../../../common/BillingDetails/EditForm/DescriptionInput';

const CompanyBilling = ({ t, billingDetails, changeBilling }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const updateProperty = ({ name, value }) => {
    const newBillingDetails = { ...billingDetails, [name]: value };
    changeBilling(newBillingDetails);
  };

  const updateProperties = (newBillingDetails) => {
    const newStateBillingDetails = {
      ...billingDetails,
      ...newBillingDetails,
    };
    changeBilling(newStateBillingDetails);
  };

  const { name } = billingDetails;

  return (
    <CreateStepsWrapper>
      <div className="step-title">{t('common:admin.companies.billing.billingDetails')}</div>
      <div className="step-description">{t('common:admin.companies.billing.billingDescription')}</div>
      <div className="company-name-form">
        <Form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ padding: '32px 8px 8px 8px', textAlign: 'left' }}
        >
          <DescriptionInput name={name} updateProperty={updateProperty} />
          <BillingForm
            loading={false}
            billing={billingDetails}
            role={40}
            disabled={false}
            onChange={updateProperties}
          />
        </Form>
      </div>
    </CreateStepsWrapper>
  );
};

export default withTranslation('common')(CompanyBilling);
