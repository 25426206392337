/* eslint-disable no-unused-vars */
import { useDebounce } from 'hoc/debouncer';
import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { postUpdateProjectById } from 'services/project';
import { updateProject } from 'store/projectsSlice';
import styled, { css } from 'styled-components';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { Icon } from '../Icon/Icon';

const Container = styled.div`
  box-shadow: inset 1px 1px 3px 0 rgb(0 0 0 / 25%);
  border: solid 1px #ccc;
  background-color: #f6f6f6;
  border-radius: 5px;
  position: relative;
  &:focus-within {
    box-shadow: inset 1px 1px 5px 0 ${({ theme }) => theme.colors.blue};
    border: ${({ theme }) => theme.colors.blue};
  }

  .loader-container {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: ${({ theme }) => theme.colors.blue};
    width: 24px;
    height: 24px;

    display: grid;
    align-items: bottom;
    justify-content: center;
    place-items: center;
  }

  ${(props) =>
    props.isDragging &&
    css`
      background-color: ${props.theme.colors.blueLight}50;
    `}
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  padding: 26px 28px;
  outline: none;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  border: none;
  background-color: transparent;
`;

const Files = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 4px;
  min-height: 51px;
  margin: 0 30px;
  border-top: 1px solid #c4c4c4;
  color: #666666;
  font-size: 16px;

  .attach-file {
    cursor: pointer;
    display: flex;
    column-gap: 4px;
    margin-right: 0.5em;
    white-space: nowrap;
    &:hover {
      color: #333;
    }
  }

  .file {
    display: flex;
    align-items: center;
    column-gap: 7px;
    height: 32px;
    padding: 10px 10px 10px 1em;
    margin-block: 5px;
    background-color: #ededed;
    color: ${({ theme }) => theme.colors.greyDark};
    font-size: 14px;

    .delete-icon {
      cursor: pointer;
      color: #c4c4c4;
      &:hover {
        color: ${({ theme }) => theme.colors.greyDark};
      }
    }
  }
`;

export const Comment = ({ project, placeholder, onFilesChange = () => {}, files = [], ...rest }) => {
  const [comment, setComment] = useState(project.comment || '');
  const [updatingComment, setUpdatingComment] = useState(null);
  const attachFileRef = useRef(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: [""]
  });

  const { debounce } = useDebounce();
  const dispatch = useDispatch();

  function onDrop(newFiles) {
    onFilesChange([...files, ...newFiles]);
  }

  const handleFooterClick = (e) => {
    if (attachFileRef.current.contains(e.target)) return;
    e.stopPropagation();
  };

  const removeFile = (file) => {
    onFilesChange(files.filter((f) => f.name !== file.name));
  };

  const handleCommentChange = async (e) => {
    e.preventDefault();
    const newProject = { ...project, comment: e.target.value };
    setComment(e.target.value);
    setUpdatingComment(true);
    const postUpdateProject = async () => {
      sendUserInteraction('saving new project comment');
      try {
        await postUpdateProjectById(newProject);
        dispatch(updateProject({ project: newProject }));
      } catch (e) {
        console.error(e);
      } finally {
        setUpdatingComment(false);
      }
    };

    debounce('commentUpdate', postUpdateProject, 1000);
  };

  return (
    <Container {...getRootProps()} isDragging={isDragActive} {...rest}>
      <Textarea
        data-cy="add-comment-input"
        value={comment}
        placeholder={placeholder}
        onChange={handleCommentChange}
      />
      {updatingComment ? (
        <div className="loader-container">
          <Loader inline active size="tiny" />
        </div>
      ) : updatingComment !== null ? (
        <div className="loader-container">
          <Icon name="check" inline={true} size="tiny" />
        </div>
      ) : null}
      {/*<Files onClick={handleFooterClick}>
        <div className="attach-file" ref={attachFileRef}>
          <input {...getInputProps()} />
          <Icon name="paperclip" /> Attach file
        </div>
        {files.map((file, idx) => (
          <div className="file" key={idx}>
            {file.name}
            <Icon name="trash-alt" className="delete-icon" onClick={() => removeFile(file)} />
          </div>
        ))}
      </Files>*/}
    </Container>
  );
};
