import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Icon } from '../../common/Icon/Icon';
import { SUBSCRIPTION_INTERVALS_OPTIONS } from '../../../constants/subscriptions';
import Accordion from '../../common/Accordion/Accordion';
import PriceInput from '../../common/PriceInput/PriceInput';
import { PricingTitle } from './Pricing.style';

import { currencyByCodeSelector } from '../../../store/selectors';

export const PricingForm = ({ pricing, onChange = () => {} }) => {
  const [interval, setInterval] = useState(pricing.interval);
  const [amount, setAmount] = useState(pricing.amount);
  const [currency, setCurrency] = useState(pricing.currency);

  const getCurrencyByCode = useSelector(currencyByCodeSelector);
  const currencyObj = getCurrencyByCode(currency);

  const { t } = useTranslation();

  useEffect(() => {
    onChange({
      interval,
      amount,
      currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, amount, currency]);

  const handleIntervalChange = (e, target) => {
    setInterval(target.value);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Dropdown
          selection
          label={t('common:admin.createPackage.pricing.billingInterval')}
          options={SUBSCRIPTION_INTERVALS_OPTIONS}
          value={interval}
          onChange={handleIntervalChange}
        />
        <Form.Field style={{ marginLeft: 'auto' }}>
          <label>{t('common:admin.createPackage.pricing.price')}</label>
          <PriceInput
            initialAmount={amount}
            initialCurrency={currencyObj}
            onCurrencyChange={(currency) => {
              setCurrency(currency.code.toLowerCase());
            }}
            onPriceChange={(amount) => {
              setAmount(amount);
            }}
          ></PriceInput>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

const PricingField = ({ pricing, onRemove = () => {}, onChange = () => {} }) => {
  const getCurrencyByCode = useSelector(currencyByCodeSelector);
  const currency = getCurrencyByCode(pricing.currency);

  const removePricing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove();
  };

  const title = (
    <PricingTitle>
      <Icon link name="times" size="small" onClick={removePricing} style={{ marginRight: '5px' }} />
      <div>
        {currency.symbol}
        <span style={{ fontWeight: 'bold' }}>{pricing.amount.toFixed(2)}</span> {currency.code}
        <span> / {pricing.interval}</span>
      </div>
    </PricingTitle>
  );

  return (
    <Accordion title={title} initialOpen={true}>
      <PricingForm pricing={pricing} onChange={(pricing) => onChange(pricing)} />
    </Accordion>
  );
};

export default PricingField;
