import { combineReducers } from '@reduxjs/toolkit';

import classifiersReducer from './classifiersSlice';
import errorsReducer from './errorsSlice';
import projectsReducer from './projectsSlice';
import userReducer from './userSlice';
import newProjectSlice from './newProjectSlice';
import teamsSlice from './teamsSlice';
import companySlice from './companySlice';
import subscriptionsSlice from './subscriptionsSlice';

const rootReducer = combineReducers({
  classifiersStore: classifiersReducer,
  errorsStore: errorsReducer,
  projectsStore: projectsReducer,
  userStore: userReducer,
  newProjectStore: newProjectSlice,
  teamsStore: teamsSlice,
  companyStore: companySlice,
  subscriptionsStore: subscriptionsSlice,
});

export default rootReducer;
