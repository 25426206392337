import React, { useEffect, useState } from 'react';
import { Checkbox } from '../../Checkbox/Checkbox';

const ShareToggle = ({ isEditing = true, updateShare, isDiy = false, shareTm }) => {
  const [share, setShare] = useState(false);
  const [shareId, setShareId] = useState(null);

  useEffect(() => {
    setShare(shareTm.share_tm === 1);
    setShareId(shareTm.tm_settings_id);
  }, [shareTm]);

  const toggleShare = () => {
    setShare(!share);
    updateShare({ tm_settings_id: shareId, share_tm: !share ? 1 : 0 });
  };

  return <Checkbox name="tmKey" onChange={toggleShare} checked={share} disabled={isDiy || !isEditing} />;
};

export default ShareToggle;
