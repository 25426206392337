import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../common/Table/Table';
import CompaniesTableRow from './CompaniesTableRow/CompaniesTableRow';
import { Icon } from 'components/common/Icon/Icon';
import { Popup } from 'components/common/Popup/Popup';

// Styles
import './style.scss';

const CompaniesTable = ({ companies }) => {
  const { t } = useTranslation();

  const tableHeaderCells = [
    {
      width: 1,
      content: 'ID',
    },
    {
      width: 4,
      content: t('common:admin.companies.name'),
    },
    {
      width: 3,
      content: t('common:admin.companies.owner'),
    },
    {
      width: null,
      content: (
        <div className="cell-label-with-popup">
          <span>{t('common:admin.companies.tableHeader.projectManager.short')}</span>
          <Popup
            dark
            trigger={<Icon name="info-circle" />}
            content={<span>{t('common:admin.companies.tableHeader.projectManager.full')}</span>}
          />
        </div>
      ),
    },
    {
      width: null,
      content: (
        <div className="cell-label-with-popup">
          <span>{t('common:admin.companies.tableHeader.keyAccountManager.short')}</span>
          <Popup
            dark
            trigger={<Icon name="info-circle" />}
            content={<span>{t('common:admin.companies.tableHeader.keyAccountManager.full')}</span>}
          />
        </div>
      ),
    },
    {
      width: 2,
      content: t('common:admin.companies.companyDiscount'),
    },
    {
      width: 2,
    },
  ];

  return (
    <Table width="100%" className="admin-companies-table">
      <Table.Header>
        <Table.Row>
          {tableHeaderCells.map((cell, idx) => (
            <Table.HeaderCell key={`companies-table-header-cell-${idx}`} width={cell.width}>
              {cell?.content && cell.content}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {companies.map((company) => (
          <CompaniesTableRow key={company.id} company={company} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default CompaniesTable;
