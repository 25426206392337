import React from 'react';
import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';
import AffiliateTable from './AffiliateTable';
import { Input } from '../../common/Input/Input';

import {
  Container,
  UrlContainer,
  StyledHeader,
  StyledSubtext,
  StyledButton,
  StyledLabel,
  Highlighted,
  AffiliateTableWrapper,
} from './Referrals.styles';
import { useSelector } from 'react-redux';

const ReferralsFooter = () => {
  const { t } = useTranslation();
  const { referral_link } = useSelector((state) => state.userStore.user);
  const referralLink = `${process.env.REACT_APP_DEPLOY_URL}affiliate/${referral_link}`;

  const copyToClipboard = (e) => {
    e.preventDefault();

    const elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = referralLink;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    Swal.fire({
      text: t('common:userInfo.referrals.copiedToClipboard'),
      title: t('common:success.heading'),
      timer: 1000,
    });
  };

  // big brain programming
  const highlighted = t('common:userInfo.referrals.referralsHeaderHighlighted');
  const headerText = t('common:userInfo.referrals.referralsHeader');
  const [left, right] = headerText.split(highlighted);

  return (
    <Container>
      <StyledLabel text={t('common:userInfo.referrals.referralProgram')} />
      <StyledHeader>
        {left}
        <Highlighted> {highlighted} </Highlighted>
        {right}
      </StyledHeader>
      <StyledSubtext>{t('common:userInfo.referrals.referralsExplanation')}</StyledSubtext>
      <UrlContainer>
        <Input type="text" value={referralLink} readOnly />
        <StyledButton color="yellow" basic big onClick={copyToClipboard}>
          {t('common:userInfo.referrals.copyURL')}
        </StyledButton>
      </UrlContainer>
      <AffiliateTableWrapper>
        <AffiliateTable />
      </AffiliateTableWrapper>
    </Container>
  );
};

export default ReferralsFooter;
