export const defaultReducer = (state, { type, payload, props }) => {
  const { isOpen, highlightedIndex } = state;

  switch (type) {
    case 'TOGGLE_CLICK':
      return { ...state, isOpen: !isOpen };
    case 'TOGGLE_ARROW_MOVE':
      return {
        ...state,
        highlightedIndex: payload,
      };
    case 'TOGGLE_ENTER_PRESS':
      return { ...state, selectedItem: payload, isOpen: !isOpen, inputValue: '' };
    case 'TOGGLE_ESCAPE_PRESS':
      return { ...state, isOpen: false };
    case 'TOGGLE_TAB_PRESS':
      return { ...state, isOpen: false };
    case 'TOGGLE_FOCUS':
      return { ...state, isOpen: true, highlightedIndex: highlightedIndex || 0 };
    case 'SET_SEARCH':
      return { ...state, inputValue: payload, isOpen: true, highlightedIndex: highlightedIndex || 0 };
    case 'SET_HIGHLIGHTED_ITEM':
    case 'ITEM_MOUSE_MOVE':
      return { ...state, highlightedIndex: payload };
    case 'ITEM_CLICK':
      return { ...state, selectedItem: payload, isOpen: false, inputValue: '' };
    case 'MENU_MOUSE_LEAVE':
      return { ...state, highlightedIndex: null };
    case 'SET_SELECTED_ITEM':
      return { ...state, selectedItem: payload };
    case 'SET_EXPAND_UPWARD':
      return { ...state, expandUpward: payload };
    default:
      throw Error('Not a specified action type');
  }
};

export const multipleReducer = (state, { type, payload, props }) => {
  const { isOpen, selectedItem, highlightedIndex } = state;

  switch (type) {
    case 'TOGGLE_CLICK':
      return { ...state, isOpen: !isOpen };
    case 'TOGGLE_ARROW_MOVE':
      return {
        ...state,
        highlightedIndex: payload,
      };
    case 'TOGGLE_ENTER_PRESS':
      return payload
        ? { ...state, selectedItem: [...selectedItem, payload], inputValue: '' }
        : { ...state, isOpen: true };
    case 'TOGGLE_ESCAPE_PRESS':
      return { ...state, isOpen: false };
    case 'TOGGLE_TAB_PRESS':
      return { ...state, isOpen: false };
    case 'TOGGLE_FOCUS':
      return { ...state, isOpen: true, highlightedIndex: highlightedIndex || 0 };
    case 'SET_SEARCH':
      return { ...state, inputValue: payload, isOpen: true, highlightedIndex: highlightedIndex || 0 };
    case 'SET_HIGHLIGHTED_ITEM':
    case 'ITEM_MOUSE_MOVE':
      return { ...state, highlightedIndex: payload };
    case 'ITEM_CLICK':
      return { ...state, selectedItem: [...selectedItem, payload], inputValue: '' };
    case 'MENU_MOUSE_LEAVE':
      return { ...state, highlightedIndex: null };
    case 'SET_EXPAND_UPWARD':
      return { ...state, expandUpward: payload };
    case 'LABEL_REMOVE_CLICK':
      return {
        ...state,
        selectedItem: selectedItem.filter((item) => item.value !== payload.value),
      };
    case 'CLEAR_ALL':
      return {
        ...state,
        selectedItem: payload,
      };
    case 'SET_SELECTED_ITEM':
      return { ...state, selectedItem: payload };
    default:
      throw Error('Not a specified action type');
  }
};
