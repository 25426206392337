export const setCssVariable = (property, value) => {
  document.documentElement.style.setProperty(property, value);
};

export const getCssVariable = (property, asNum = false) => {
  const value = getComputedStyle(document.documentElement).getPropertyValue(property);

  if (asNum) return parseFloat(value);

  return value;
};

/**
 * Classnames utility function
 * Source code: https://github.com/JedWatson/classnames
 *
 * classNames('foo', 'bar'); // => 'foo bar'
 * classNames('foo', { bar: true }); // => 'foo bar'
 * classNames({ 'foo-bar': true }); // => 'foo-bar'
 * classNames({ 'foo-bar': false }); // => ''
 * classNames({ foo: true }, { bar: true }); // => 'foo bar'
 * classNames({ foo: true, bar: true }); // => 'foo bar'
 *
 * // lots of arguments of various types
 * classNames('foo', { bar: true, duck: false }, 'baz', { quux: true }); // => 'foo bar baz quux'
 *
 * // other falsy values are just ignored
 * classNames(null, false, 'bar', undefined, 0, 1, { baz: null }, ''); // => 'bar 1'
 */
export function classnames(...rest) {
  const classes = [];
  const hasOwn = {}.hasOwnProperty;

  for (let i = 0; i < rest.length; i++) {
    const arg = rest[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === 'string' || argType === 'number') {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        const inner = classnames.apply(null, arg);
        if (inner) {
          classes.push(inner);
        }
      }
    } else if (argType === 'object') {
      if (arg.toString === Object.prototype.toString) {
        for (let key in arg) {
          if (hasOwn.call(arg, key) && arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg.toString());
      }
    }
  }

  return classes.join(' ');
}

/**
 * Object default property util
 */
export const objectDefaultGetter = (defaultValue) => {
  const defaultGetter = {
    get: function (target, name) {
      return target.hasOwnProperty(name) ? target[name] : defaultValue;
    },
  };

  return defaultGetter;
};

/**
 * Add default getter to an object using Proxy => https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
 *
 * @param {object} obj object on which to set the default getter
 * @param {any} defaultVal default value of the object
 * @returns {object}
 */
export const objectWithDefault = (obj, defaultVal) => {
  return new Proxy(obj, objectDefaultGetter(defaultVal));
};

export const getObjectsDifferences = (obj1, obj2) =>
  Object.keys(obj1).reduce((diff, key) => {
    if (obj2[key] === obj1[key]) return diff;
    return {
      ...diff,
      [key]: obj1[key],
    };
  }, {});

export const isEqualShallow = (obj1, obj2) =>
  Object.entries(obj1).sort().toString() === Object.entries(obj2).sort().toString();
