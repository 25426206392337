import React, { Component, useCallback, useRef } from 'react';

class Debouncer {
  timers = {};

  debounce(name, callback, maxTime) {
    if (this.timers.hasOwnProperty(name)) {
      clearTimeout(this.timers[name]);
    }
    this.timers[name] = setTimeout(callback, maxTime);
  }

  clearAllTimers() {
    for (let timer of Object.keys(this.timers)) {
      clearTimeout(this.timers[timer]);
    }
  }
}

export const withDebounce = (WrappedComponent) => {
  class Debounced extends Component {
    state = {
      debouncer: new Debouncer(),
    };

    componentWillUnmount() {
      this.state.debouncer.clearAllTimers();
    }

    render() {
      const debouncerProps = {
        debounce: (name, callback, maxTime) => this.state.debouncer.debounce(name, callback, maxTime),
        clearAllDebounce: () => this.state.debouncer.clearAllTimers(),
      };

      return <WrappedComponent {...this.props} {...debouncerProps} />;
    }
  }

  return Debounced;
};

export const useDebounce = () => {
  const debouncer = useRef(new Debouncer());

  return {
    debounce: useCallback(
      (name, callback, maxTime) => debouncer.current.debounce(name, callback, maxTime),
      [],
    ),
    clearAllDebounce: useCallback(() => debouncer.current.clearAllTimers(), []),
  };
};
