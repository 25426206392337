import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../common/Table/Table';

import InviteRow from './InviteRow';

const InvitesTable = ({ deleteInvite, invites }) => {
  const { t } = useTranslation();

  const tableRows =
    invites.length === 0 ? (
      <Table.Row>
        <Table.Cell colSpan="4" textAlign="center">
          {t('common:team.invites.noInvites')}
        </Table.Cell>
      </Table.Row>
    ) : (
      invites.map((invite) => {
        return <InviteRow deleteInvite={deleteInvite} key={invite.id} invite={invite} />;
      })
    );

  return (
    <Table width="100%" marginTop="0">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('common:company.invites.user')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:company.invites.role')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:company.invites.invitedOn')}</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{tableRows}</Table.Body>
    </Table>
  );
};

export default InvitesTable;
