import React, { useEffect, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Modal } from 'semantic-ui-react';
import Button from 'components/common/Button/Button';

// Utils
import { postUnarchiveProject, postArchiveProject } from 'services/project';
import { canArchiveProject } from 'utils/user.utils';
import { PROJECT_STATUS_ARCHIVED } from 'constants/projectStatus';
import { hasAdminPermissions } from 'utils/user.utils';
import { postAdminArchiveProject } from 'services/project';

/**
 * Renders a modal which deletes a project
 */
const ArchiveModal = ({ trigger, setLoading, open, onClose, project }) => {
  // State
  const [toggleModal, setToggleModal] = useState(open);

  // Redux
  const user = useSelector((state) => state.userStore.user);

  // Hooks
  const { t } = useTranslation();

  // functions

  const modalText = () => {
    return t(
      `common:projects.archiveModal.${
        project?.status_id === PROJECT_STATUS_ARCHIVED ? 'unarchive' : 'archive'
      }`,
    );
  };

  /**
   * Run onClose callback and close modal
   */
  const closeModal = () => {
    if (onClose) onClose();

    setToggleModal(false);
  };

  const openModal = () => setToggleModal(true);

  /**
   * Archive project
   */
  const archiveProject = async () => {
    try {
      setLoading(true);

      let serverResponse = {};

      if (hasAdminPermissions(user.role)) {
        serverResponse = await postAdminArchiveProject(project.id);
      } else {
        serverResponse = await postArchiveProject(project.id);
      }

      if (serverResponse?.success) {
        toast.success(t('common:toasts.archiveModal.archive.success'));
        setLoading(false);
      }
    } catch (e) {
      toast.error(t('common:toasts.archiveModal.archive.error'));
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Unarchive project
   */
  const unarchiveProject = async () => {
    setLoading(true);

    try {
      await postUnarchiveProject(project.id);

      toast.success(t('common:toasts.archiveModal.unarchive.success'));
    } catch (e) {
      let errorText = 'common:toasts.archiveModal.unarchive.error.default';

      // Check status code
      if (e.response.status) {
        switch (e.response.status) {
          case 400:
            errorText = 'common:toasts.archiveModal.unarchive.error.notFinished';
            break;
          case 403:
            errorText = 'common:toasts.archiveModal.unarchive.error.forbidden';
            break;
          case 404:
            errorText = 'common:toasts.archiveModal.unarchive.error.notFound';
            break;
          default:
            break;
        }
      }

      console.error(e);
      toast.error(t(errorText));
    } finally {
      setLoading(false);
    }
  };

  /**
   * Close modal and run archive or unarchive,
   * depending on user role, project status and if admin archive (allows archive in any case) is set
   */
  const doAction = () => {
    setToggleModal(false);

    if (canArchiveProject(project.status_id) && project.status_id !== PROJECT_STATUS_ARCHIVED)
      archiveProject();

    if (project.status_id === PROJECT_STATUS_ARCHIVED) unarchiveProject();
  };

  // Effects

  /**
   * On props.open change update internal state
   */
  useEffect(() => {
    setToggleModal(open);
  }, [open]);

  // Props modal props
  const modalProps = {
    closeIcon: true,
    size: 'mini',
    open: toggleModal,
    onClose: closeModal,
    onOpen: openModal,
    trigger: trigger ? trigger : null,
  };

  return (
    <Modal {...modalProps}>
      <Modal.Content>
        <p>{modalText()}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="cancel" onClick={closeModal}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="delete-confirm" onClick={doAction}>
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

// Prop types
ArchiveModal.propTypes = {
  project: PropTypes.object.isRequired,
  open: function (props, propName, _) {
    if (!props['trigger'] && (props[propName] === undefined || typeof props[propName] !== 'boolean')) {
      return new Error('No trigger found, open prop must be provided');
    }
  },
  trigger: PropTypes.element,
  setLoading: PropTypes.func,
  onClose: PropTypes.func,
};

export default ArchiveModal;
