import styled from 'styled-components';

export const AccordionContainer = styled.div`
  .accordion-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 15px;
    cursor: pointer;

    .header-text {
      font-size: 1.25rem;
      width: 100%;
    }

    .header-icon {
      margin-left: 21px;
    }
  }

  .accordion-content {
    padding: 20px;
  }
`;
