import React from 'react';

import { Icon } from 'components/common/Icon/Icon';
import { Loader, Popup } from 'semantic-ui-react';
import { MenuButton } from './AdminSidebar.styles';
import { classnames } from 'utils/base.utils';

const MenuButtonComponent = ({
  iconName = '',
  isLoading = false,
  onClick = () => {},
  text = '',
  success = false,
  className,
}) => {
  return (
    <Popup
      content={text}
      inverted
      position="left center"
      trigger={
        <MenuButton onClick={onClick} className={classnames(className, { success })}>
          {isLoading ? (
            <Loader inline active size="tiny" />
          ) : (
            <>
              <Icon name={iconName} size={24} />
            </>
          )}
        </MenuButton>
      }
    />
  );
};

export default MenuButtonComponent;
