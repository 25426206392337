import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../common/Icon/Icon';
import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LanguagesWrapper = styled.div`
  display: flex;
`;

const StyledLabel = styled.div`
  margin-right: calc(0.14285714em * 2);
  padding: 6px 12px;
  height: min-content;
  font-size: 14px;
  background: #ededed;
  width: min-content;
  white-space: nowrap;
`;

const TargetLanguages = styled.div`
  display: flex;
  align-items: center;
  margin-left: calc(0.14285714em * 2);
  overflow: hidden;
`;

const TruncatedLines = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ProjectLanguages = ({ project }) => {
  const { t } = useTranslation();

  const targetLanguages = project.target_language
    ? Array.isArray(project.target_language)
      ? project.target_language
      : [project.target_language]
    : null;

  return (
    <FlexWrapper>
      <LanguagesWrapper>
        {!!project.source_language ? (
          <StyledLabel>{t('languages:' + project.source_language.code)}</StyledLabel>
        ) : (
          <StyledLabel>?</StyledLabel>
        )}
        {!!targetLanguages && (
          <>
            <Icon name="arrow-right" />
            <TargetLanguages>
              <TruncatedLines>
                {targetLanguages.map((language) => t('languages:' + language.code)).join(', ')}
              </TruncatedLines>
            </TargetLanguages>
          </>
        )}
      </LanguagesWrapper>
    </FlexWrapper>
  );
};

export default memo(ProjectLanguages);
