import React, { useMemo, useState } from 'react';
import Message from '../common/Message/Message';
import { Input } from '../common/Input/Input';
import { useTranslation } from 'react-i18next';

import { StyledButton, StyledFormError, StyledLink, StyledHeader, StyledSpan } from '../common.styles';
import { StyledCard, InnerContainer, StyledForm, Footer } from 'components/SignIn/SignIn.styles';
import PublicWrapper from '../PublicWrapper/PublicWrapper';

import { postRequestPasswordReset } from '../../services/auth';
import { isValidEmail } from '../../utils/string.utils';

import { sendUserInteraction } from 'utils/tagManager.utils';
import { useRecaptchaV3 } from 'hooks/useRecaptcha';

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false); // my life
  // :'(
  const [message, setMessage] = useState('');

  const isEmailValid = useMemo(() => isValidEmail(email), [email]);
  const { t } = useTranslation();
  const { generateToken } = useRecaptchaV3();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const canSubmit = () => {
    return isValidEmail(email) && !isLoading;
  };

  const handleSubmit = async (e) => {
    sendUserInteraction('clicked Reset password');
    e.preventDefault();

    try {
      setError(null);
      setIsLoading(true);
      const captcha = await generateToken();
      const resetResponse = await postRequestPasswordReset({ email, captcha });
      setSuccess(true);
      setMessage(resetResponse.message);
    } catch (e) {
      if (!e.response) {
        setError(t('common:errors.noConnection'));
      } else if (e.response.status === 404) {
        setError(t('common:passwordReset.errors.invalidEmail'));
      } else if (e.response.status === 422) {
        setError(t('common:signIn.errors.recaptchaInvalid'));
      } else {
        throw e;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PublicWrapper>
      <StyledCard>
        <InnerContainer>
          <StyledHeader>{t('common:passwordReset.resetPassword')}</StyledHeader>
          {error && <Message text={error} type="error" />}
          {success ? (
            <Message text={message} type="info" />
          ) : (
            <StyledForm onSubmit={handleSubmit}>
              <div>
                <Input type="email" label={t('common:signIn.labels.email')} onChange={handleChange} />
                {!isEmailValid && email.length > 0 && (
                  <StyledFormError>{t('common:signUp.errors.email.invalid')}</StyledFormError>
                )}
              </div>
              <StyledButton
                disabled={!canSubmit()}
                loading={isLoading}
                big
                actiontype="submit"
                type="submit"
                marginBottom="8px"
              >
                {t('common:passwordReset.resetPassword')}
              </StyledButton>
            </StyledForm>
          )}
        </InnerContainer>
        <Footer style={{ display: 'flex' }}>
          <StyledSpan>{t('common:passwordReset.noAccount')}</StyledSpan>
          <StyledLink onClick={() => sendUserInteraction('clicked link Sign up')} to="/register">
            {t('common:signUp.signUp')}
          </StyledLink>
        </Footer>
      </StyledCard>
    </PublicWrapper>
  );
};

export default RequestPasswordReset;
