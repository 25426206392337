import React from 'react';
import { Form } from 'semantic-ui-react';
import { CreditCardContainer } from './CreditCardForm.style';

import { CardElement } from '@stripe/react-stripe-js';

const CreditCardForm = ({ postalCode = '' }) => {
  return (
    <CreditCardContainer>
      <Form>
        <Form.Field>
          <div className="custom-input">
            <CardElement data-cy="credit-card-input" options={{ hidePostalCode: true }} />
          </div>
        </Form.Field>
      </Form>
    </CreditCardContainer>
  );
};

export default CreditCardForm;
