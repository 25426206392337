import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListBullet, TaskName, TaskPrice } from './ServiceRows.styles';

import { convertPrice, localiseAndConvertPrice } from '../../../../../utils/string.utils';
import listBulletSrc from './assets/List_bullet.png';

const ServiceRows = ({ currency, service }) => {
  const { i18n } = useTranslation();
  const { tasks } = service;

  return !!service ? (
    <>
      {tasks.map((task) => {
        const { id, net_price, price_per_unit, task_type, unit, quantity } = task;

        const localisedNetPrice = localiseAndConvertPrice({
          currencyData: currency,
          language: i18n.language,
          number: net_price,
        });

        const pricePerUnit = convertPrice({
          currencyData: currency,
          number: price_per_unit,
        });

        const taskDescription = `${task_type.name} - ${quantity} ${unit.display_name} × ${pricePerUnit}`;

        return (
          <React.Fragment key={`task-${id}`}>
            <TaskName>
              <ListBullet src={listBulletSrc} />
              <div>{taskDescription}</div>
            </TaskName>
            <TaskPrice>{localisedNetPrice}</TaskPrice>
          </React.Fragment>
        );
      })}
    </>
  ) : null;
};

export default ServiceRows;
