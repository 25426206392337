import React from 'react';
import { useTranslation } from 'react-i18next';

import { Popup } from 'semantic-ui-react';
import { NoWrapSpan } from './Job.styles';

import { convertPrice, localiseAndConvertPrice } from '../../../../../utils/string.utils';

const PricePerWord = ({ currencyData, job }) => {
  const getSelectedService = () => {
    // only one of the services can be selected at the same time
    if (job.service_1_selected) {
      return 1;
    }
    if (job.service_2_selected) {
      return 2;
    }
    if (job.service_3_selected) {
      return 3;
    }
    if (job.service_4_selected) {
      return 4;
    }
    if (job.service_5_selected) {
      return 5;
    }
    // return null if no service is selected (project is proofread)
    return null;
  };

  const getPrice = () => {
    const selectedService = getSelectedService();
    const price = selectedService ? job[`service_${selectedService}_ppw`] : job.proofread_per_word;

    return price;
  };

  const getConvertedPrice = () => {
    const selectedService = getSelectedService();
    const price = selectedService ? job[`service_${selectedService}_ppw`] : job.proofread_per_word;
    return convertPrice({ currencyData, number: price });
  };

  const convertedPrice = getConvertedPrice();
  const price = getPrice();
  const { i18n } = useTranslation();

  return (
    <Popup
      position="top center"
      content={<NoWrapSpan>{convertedPrice}</NoWrapSpan>}
      trigger={
        <NoWrapSpan>
          {`${localiseAndConvertPrice({
            currencyData,
            number: price,
            language: i18n.language,
          })}`}
        </NoWrapSpan>
      }
    />
  );
};

export default PricePerWord;
