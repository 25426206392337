import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Modal } from 'semantic-ui-react';
import Table from '../../../common/Table/Table';
import Message from '../../../common/Message/Message';
import ModalPopup from '../../../common/ModalPopup/ModalPopup';

import {
  postAssignRevision,
  postAssignTranslator,
  postRemoveRevisor,
  postRemoveTranslater,
} from '../../../../services/project';
import { OverflowScroll } from '../../../common.styles';
import { toast } from 'react-toastify';
import Button from '../../../common/Button/Button';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { toDateTimeLocal } from 'utils/date.utils';
import { getVendors } from 'services/vendors';
import { VENDOR_TASK_TYPE_TRANSLATION } from 'constants/vendors';
import './style.scss';
import Price from 'components/common/Price/Price';
import { Link } from 'react-router-dom';
import { VENDOR_TASK_TYPE_REVISION } from 'constants/vendors';

const TranslatorRow = ({
  swap = false,
  translator,
  onError = () => {},
  onSubmit = () => {},
  date,
  job,
  valid = true,
  taskType,
}) => {
  const [assignmentLoading, setAssignmentLoading] = useState(false);
  const [confirmAssign, setConfirmAssign] = useState(false);

  const { t } = useTranslation();

  const handleAssignClick = async () => {
    sendUserInteraction('assign translator click');
    try {
      setAssignmentLoading(true);
      if (taskType === VENDOR_TASK_TYPE_TRANSLATION) {
        if (swap) {
          await postRemoveTranslater(job.id);
        }

        const resp = await postAssignTranslator(job.id, translator.id, new Date(date));
        if (resp.error) {
          toast.error(t('common:toasts.transaltorAssigned.error'));
          onError(resp.data);
        } else {
          toast.success(t('common:toasts.transaltorAssigned.success'));
          onSubmit(translator, date);
        }
      } else if (taskType === VENDOR_TASK_TYPE_REVISION) {
        if (swap) {
          await postRemoveRevisor(job.id);
        }

        const resp = await postAssignRevision(job.id, translator.id, new Date(date));
        if (resp.error) {
          toast.error(t('common:toasts.reviserAssigned.error'));
          onError(resp.data);
        } else {
          toast.success(t('common:toasts.reviserAssigned.success'));
          onSubmit(translator, date);
        }
      }
    } catch (e) {
      toast.error(t('common:toasts.transaltorAssigned.error'));
      onError();
    } finally {
      setAssignmentLoading(false);
    }
  };

  const handleRemoveClick = async () => {
    sendUserInteraction('assign translator click');
    try {
      setAssignmentLoading(true);
      if (taskType === VENDOR_TASK_TYPE_TRANSLATION) {
        const resp = await postRemoveTranslater(job.id);
        if (resp.error) {
          toast.error(t('common:toasts.translatorRemoved.error', 'Error when removing vendor'));
          onError(resp.data);
        } else {
          toast.success(t('common:toasts.translatorRemoved.success', 'Vendor sucessfuly removed'));
          onSubmit(null, null);
        }
      } else if (taskType === VENDOR_TASK_TYPE_REVISION) {
        const resp = await postRemoveRevisor(job.id);
        if (resp.error) {
          toast.error(t('common:toasts.translatorRemoved.error', 'Error when removing vendor'));
          onError(resp.data);
        } else {
          toast.success(t('common:toasts.translatorRemoved.success', 'Vendor sucessfuly removed'));
          onSubmit(null, null);
        }
      }
    } catch (e) {
      toast.error(t('common:toasts.translatorRemoved.error', 'Error when removing vendor'));
      onError();
    } finally {
      setAssignmentLoading(false);
    }
  };

  const isAssignedRow = useMemo(() => {
    if (taskType === VENDOR_TASK_TYPE_TRANSLATION) {
      return translator.id === job.translator_id;
    } else if (taskType === VENDOR_TASK_TYPE_REVISION) {
      return translator.id === job.revisioner;
    }
  }, [job.revisioner, job.translator_id, taskType, translator.id]);

  const { rate_status, rate, pending_rate } = translator.prices[0];

  return (
    <Table.Row key={translator.id}>
      <Table.Cell>
        <Link to={`/admin/vendor/${translator.id}`} target="_blank" rel="noopener noreferrer">
          {translator.name}
        </Link>
      </Table.Cell>
      <Table.Cell className="email-cell">{translator.email}</Table.Cell>
      <Table.Cell>
        {rate_status ? (
          <Price price={rate} round={false} />
        ) : (
          <>
            Rate pending: <Price price={pending_rate} round={false} />
          </>
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {confirmAssign ? (
          <>
            {isAssignedRow ? (
              <Button loading={assignmentLoading} color="red" icon="check" onClick={handleRemoveClick} />
            ) : (
              <ModalPopup
                basic
                content="Confirm translator assignment"
                trigger={
                  <Button
                    loading={assignmentLoading}
                    disabled={!valid}
                    actiontype="submit"
                    icon="check"
                    onClick={handleAssignClick}
                  />
                }
              />
            )}
            <Button
              icon="times"
              actiontype="cancel"
              disabled={assignmentLoading}
              onClick={() => setConfirmAssign(false)}
            />
          </>
        ) : isAssignedRow ? (
          <Button icon="times" onClick={() => setConfirmAssign(true)} color="red" />
        ) : (
          <Button
            icon="plus"
            disabled={!valid || !rate_status}
            onClick={() => setConfirmAssign(true)}
            actiontype="secondary"
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() + 1);
defaultDate.setHours(13, 0, 0);

const AssignVendorModal = ({
  taskType = VENDOR_TASK_TYPE_TRANSLATION,
  swap = false,
  open = false,
  job,
  onSubmit,
  onClose = () => {},
}) => {
  const [translators, setTranslators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [date, setDate] = useState(toDateTimeLocal(defaultDate));

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      try {
        if (open) {
          setIsLoading(true);
          const { data } = await getVendors({
            source_lang_id: job.source_language_id,
            target_lang_id: job.target_language_id,
            task_type_id: taskType,
            per_page: 9999,
          });
          setTranslators(data);
        }
      } catch (e) {
        setErrorMessage(t('common:admin.project.jobs.translatorError'));
        setError(true);
        throw e;
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, job, t]);

  const handleAssignmentError = (msg) => {
    setError(true);
    if (msg) {
      setErrorMessage(msg);
    }
  };

  const handleVendorSubmit = (translator, date) => {
    onClose();
    onSubmit(translator, date);
  };

  const valid = !!date;

  const translatorRows = translators.map((translator) => {
    return (
      <TranslatorRow
        swap={swap}
        job={job}
        date={date}
        valid={valid}
        key={translator.id}
        translator={translator}
        onError={handleAssignmentError}
        onSubmit={handleVendorSubmit}
        taskType={taskType}
      ></TranslatorRow>
    );
  });

  return (
    <Modal size="large" open={open} onClose={() => onClose(false)}>
      <Modal.Header>{t('common:admin.project.jobs.assignVendor', 'Assign vendor')}</Modal.Header>
      <Modal.Content scrolling>
        <label htmlFor="translation-deadline">
          {t('common:admin.project.jobs.deadlineLabel', 'Deadline date and time')}:{' '}
        </label>
        <input
          id="translation-deadline"
          type="datetime-local"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        ></input>
        {error && <Message text={errorMessage} type="error"></Message>}
        <OverflowScroll height="50vh" style={{ marginTop: '10px' }}>
          <Table width="100%" textAlign="center" className="assign-vendor-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('common:admin.project.jobs.name')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:admin.project.jobs.email')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:admin.project.jobs.rate', 'Rate')}</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">{t('common:admin.project.jobs.assign')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading ? (
                <Table.Row>
                  <Table.Cell colspan="4" textAlign="center">
                    <Loader active inline size="tiny" /> Loading...
                  </Table.Cell>
                </Table.Row>
              ) : translators.length === 0 ? (
                <Table.Row>
                  <Table.Cell colspan="4" textAlign="center">
                    {t('common:admin.project.jobs.empty', 'There are no onboarded vendors for this service')}
                  </Table.Cell>
                </Table.Row>
              ) : (
                translatorRows
              )}
            </Table.Body>
          </Table>
        </OverflowScroll>
      </Modal.Content>
    </Modal>
  );
};

export default AssignVendorModal;
