import styled from 'styled-components';

export const RoleSelectWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 300px;
  margin-bottom: 42px;
  .role-radio {
    .radio-icon {
      display: grid;
      place-content: center;
      margin-bottom: 20px;
      cursor: pointer;
    }

    .radio-control {
    }
  }
`;
