import styled from 'styled-components';

export const CouponInputWrapper = styled.div`
  .coupon-link {
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: underline;
    cursor: pointer;
  }
  .coupon-field {
    width: 100%;
    display: grid;
    grid-template-columns: auto 80px;
    column-gap: 12px;
    button {
      margin-right: 0px !important;
    }
  }
`;
