import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './style.scss';

import Button from 'components/common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import FreeSubscription from './FreeSubscription/FreeSubscription';
import LegacySubscription from './LegacySubscription/LegacySubscription';
import LoadingPane from 'components/common/LoadingPane/LoadingPane';
import Message from 'components/common/Message/Message';
import Subscription from './Subscription/Subscription';
import { Tab } from 'semantic-ui-react';

import { MainContainer } from 'components/common.styles';

import { getBillingDetails } from 'services/billing';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { getTeamsToOrder } from 'utils/catapult.utils';

const Subscriptions = () => {
  const { billingId } = useParams();
  const history = useHistory();
  const user = useSelector((state) => state.userStore.user);
  const { role, teams } = user;
  const subscriptions = useSelector((state) => state.subscriptionsStore.subscriptions);
  const products = useSelector((state) => state.classifiersStore.products);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [billingDetails, setBillingDetails] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // if user does not have any subscriptions, redirect to the catapult landing page
    if (subscriptions.length < 1) {
      // redirect to the catapult landing page. The landing page should handle order permissions etc. in this case
      history.push('/catapult/packages');
      return;
    }

    // if we don't have a billingId, default to the first subscription in subscriptions array
    if (!billingId) {
      const { billing_detail_id } = subscriptions[0];

      // if there is no billing_details_id on subscription it is either free or legacy
      if (!billing_detail_id) {
        history.push(`/catapult/subscriptions/${subscriptions[0]['type']}`);
      } else {
        history.push(`/catapult/subscriptions/${billing_detail_id}`);
      }
    }

    // if we have billingId that is not valid, default to the first subscription in subscriptions array
    if (!!billingId) {
      const subscription = subscriptions.find((sub) => sub.billing_detail_id === parseInt(billingId));

      if (!subscription) {
        const { billing_detail_id } = subscriptions[0];

        if (!billing_detail_id) {
          history.push(`/catapult/subscriptions/${subscriptions[0]['type']}`);
        } else {
          history.push(`/catapult/subscriptions/${billing_detail_id}`);
        }
      }
    }

    // want to run this only on mount
  }, [billingId, history, role, subscriptions]);

  useEffect(() => {
    const fetchBillingDetails = async () => {
      setError(null);
      setIsLoading(true);
      try {
        const newBillingDetails = [];
        await Promise.all(
          subscriptions.map(async (sub) => {
            try {
              if (!!sub.billing_detail_id) {
                const responseBilling = await getBillingDetails(sub.billing_detail_id);
                newBillingDetails.push(responseBilling);
              }
            } catch (e) {
              throw e;
            }
          }),
        );
        setBillingDetails(newBillingDetails);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingDetails();
  }, [subscriptions]);

  const getSubscriptionsPanes = () => {
    return subscriptions.map((subscription, index) => {
      const subscriptionProduct = products.find((productClassifier) => {
        return productClassifier.plans.find((plan) => plan.stripe_id === subscription.stripe_plan);
      });

      const subscriptionTeams = teams.filter(
        (team) => team.billing_detail_id === subscription.billing_detail_id,
      );

      const billing_detail_id = !!subscription.billing_detail_id
        ? subscription.billing_detail_id
        : subscription.type;

      const subBillingDetails = billingDetails.find((billing) => billing.id === billing_detail_id);

      const menuItem = !!subscriptionProduct
        ? `${
            !!subBillingDetails
              ? subBillingDetails?.name
              : t('common:catapult.subscriptionManagement.unnamedBilling')
          } (${subscriptionProduct.name})`
        : `${
            subscription.type === 'free'
              ? t('common:catapult.packages.freePlan')
              : t('common:catapult.packages.legacy')
          }`;

      return {
        billing_detail_id: `${billing_detail_id}`, // casting to string for strict equality
        index: index,
        menuItem: menuItem,
        render: () => (
          <div className="subscription-tab-container">
            {billing_detail_id === 'free' ? (
              <FreeSubscription className="subscription-content" subscriptionData={subscription} />
            ) : billing_detail_id === 'legacy' ? (
              <LegacySubscription />
            ) : (
              <Subscription
                billingId={billingId}
                className="subscription-content"
                subscriptionId={subscription.id}
                subscriptionTeams={subscriptionTeams}
              />
            )}
          </div>
        ),
      };
    });
  };

  const teamsToOrder = useMemo(() => {
    return getTeamsToOrder({ user, subscriptions });
  }, [user, subscriptions]);

  const redirectToOrderPackage = () => {
    if (teamsToOrder.length === 1) {
      history.push(`/catapult/packages?billingId=${teamsToOrder[0]['billing_detail_id']}`);
    } else {
      if (user?.billing_detail) {
        history.push(`/catapult/packages?billingId=${user?.billing_detail.id}`);
      } else {
        history.push(`/catapult/packages`);
      }
    }
  };

  const handleTabChange = (e, { activeIndex }) => {
    sendUserInteraction('subscription overview subscription tab change');
    e.preventDefault();

    const panes = getSubscriptionsPanes();
    const newActivePane = panes.find((pane) => pane.index === activeIndex);

    history.push(`/catapult/subscriptions/${newActivePane.billing_detail_id}`);
  };

  const getActiveIndex = () => {
    const panes = getSubscriptionsPanes();
    const activePane = panes.find((pane) => pane.billing_detail_id === billingId);
    return activePane ? activePane.index : 0;
  };

  return (
    <MainContainer>
      <div className="subscriptions-page">
        {!!isLoading ? (
          <LoadingPane />
        ) : !!error ? (
          <Message message={error} type="error" />
        ) : (
          <div className="subscription-container">
            <div className="heading-1 header-container">
              <h1 className="heading-1">{t('common:catapult.subscriptionManagement.heading')}</h1>
              {teamsToOrder.length > 0 && (
                <Button actiontype="primary" labelPosition="left" onClick={redirectToOrderPackage}>
                  <Icon name="plus-square" />
                  {t(
                    `common:catapult.subscriptionManagement.${
                      billingId === 'free' && subscriptions.length === 1
                        ? 'upgradeSubscription'
                        : 'orderSubscription'
                    }`,
                  )}
                </Button>
              )}
            </div>
            {subscriptions.length > 1 ? (
              <Tab
                activeIndex={getActiveIndex()}
                menu={{ secondary: true, pointing: true }}
                panes={getSubscriptionsPanes()}
                onTabChange={handleTabChange}
              />
            ) : subscriptions.length > 0 ? (
              billingId === 'free' ? (
                <FreeSubscription subscriptionData={subscriptions[0]} />
              ) : billingId === 'legacy' ? (
                <LegacySubscription />
              ) : (
                <Subscription billingId={billingId} subscriptionId={subscriptions[0]['id']} />
              )
            ) : null}
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export default Subscriptions;
