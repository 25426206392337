import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SidbarButtonContainer = styled.div`
  position: absolute;
  right: 58px;
  top: 73px;
  z-index: 2;
`;

export const FiltersSidebarContainer = styled.div``;

export const SidebarPanel = styled(motion.div)`
  width: 0;
  position: fixed;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.aboveAll - 1};
  box-shadow: -2px 0 20px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    height: calc((var(--vh, 1vh) * 100) - var(--sidebar-header-height));
    top: var(--sidebar-header-height);
  `}
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 3;

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    right: ${theme.paddings.mainContainer.mobile.x};

    .button.icon {
      margin-right: 0;
    }
  `}
`;

export const ContentWrapper = styled.div`
  padding: 1rem;
  padding-top: 1.6rem;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  height: 100%;

  .sidebar-field {
    margin-top: 1rem;
  }

  .sidebar-field-group {
    margin-top: 1rem;
    .sidebar-field {
      margin-top: 0.5rem;
    }
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: ${theme.paddings.mainContainer.mobile.x};
  `}
`;
