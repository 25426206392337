import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Message from '../../common/Message/Message';
import { postBillingCreate } from '../../../services/billing';
import { postCompanyOwner, postCreateCompany } from '../../../services/company';
import { postUpdateTeam, postMemberInvite } from '../../../services/teams';
import { isValidEmail } from '../../../utils/string.utils';

import Steps from '../Steps/Steps';
import { CreateCompanyWrapper } from './CreateCompany.style';
import CompanyBilling from './steps/CompanyBilling';
import CompanyInvite from './steps/CompanyInvite';
import CompanyName from './steps/CompanyName';
import CompanyTeam from './steps/CompanyTeam';

import { currencyByIdSelector } from 'store/selectors';

class CreateCompany extends Component {
  state = {
    companyName: '',
    companyValid: false,
    teamName: '',
    teamValid: false,
    emails: [],
    billingDetails: {},
    submitting: false,
    error: null,
    company: null,
    billing: null,
  };

  submitCompany = async (e) => {
    this.setState({ submitting: true });

    const { companyName, teamName, billingDetails, emails, company, billing } = this.state;
    const { t } = this.props;

    let newCompany = { ...company };
    if (company === null) {
      try {
        newCompany = await postCreateCompany({ company_name: companyName, comp_discount: 0 });
        this.setState({ company: newCompany });
      } catch (e) {
        this.setState({ submitting: false, error: t('common:admin.companies.error') });
        toast.error(t('common:toasts.createCompany.error'));
        throw e;
      }
    }

    let newBilling = { ...billing };

    if (!!newBilling.euro_balance) {
      const getCurrencyById = useSelector(currencyByIdSelector);
      const currencyData = getCurrencyById(newBilling.currency_id);

      const euroBalace = newBilling.euro_balance / currencyData.conversion_rate;

      newBilling = {
        ...billing,
        euro_balance: euroBalace,
      };
    }

    if (billing === null) {
      try {
        if (billingDetails.hasOwnProperty('company_vat')) {
          newBilling = await postBillingCreate({
            ...billingDetails,
            company_id: newCompany.id,
            name: 'Default billing',
          });
          this.setState({ billing: newBilling });
        }
      } catch (e) {
        this.setState({ submitting: false, error: t('common:admin.companies.billingError') });
        toast.error(t('common:common:admin.companies.billingError'));
      }
    }

    try {
      await postUpdateTeam({
        team_id: newCompany.teams[0].id,
        name: teamName.length > 0 ? teamName : `${companyName} team`,
        billing_detail_id: newBilling !== null ? newBilling.id : undefined,
      });
    } catch (e) {
      this.setState({ submitting: false, error: t('common:admin.companies.teamError') });
      toast.error(t('common:admin.companies.teamError'));
      throw e;
    }

    let invite = null;
    for (invite of emails) {
      try {
        if (invite.role === 0) {
          await postCompanyOwner({ id: newCompany.id, email: invite.email });
        } else {
          await postMemberInvite({
            team_id: newCompany.teams[0].id,
            email: invite.email,
            role_id: invite.role,
          });
        }
        toast.success(t('common:toasts.inviteMembers.success') + `: ${invite.email}`);
      } catch (e) {
        console.error(e);
        if (e.response.status === 422) {
          this.setState({ error: t('common:team.members.emailInUse') + `: ${invite.email}` });
          toast.error(t('common:team.members.emailInUse') + `: ${invite.email}`);
        } else {
          this.setState({ error: t('common:team.members.inviteError') + `: ${invite.email}` });
          toast.error(t('common:team.members.inviteError') + `: ${invite.email} ${e.response.data.error}`);
        }
      }
    }

    this.setState({
      companyName: '',
      companyValid: false,
      teamName: '',
      teamValid: false,
      emails: [],
      billingDetails: {},
      submitting: false,
    });
    toast.success(this.props.t('common:toasts.createCompany.success'));

    this.props.onSubmit(company);
  };

  onCompanyNameChange = (e) => {
    this.setState({ companyName: e.target.value });
  };

  companyValid = (bool) => {
    this.setState({ companyValid: bool });
  };

  onTeamNameChange = (e) => {
    this.setState({ teamName: e.target.value });
  };

  teamValid = (bool) => {
    this.setState({ teamValid: bool });
  };

  addEmail = (invite) => {
    const newEmails = [...this.state.emails, { ...invite }];

    this.setState({ emails: newEmails });
  };

  removeEmail = (idx) => {
    return () => {
      const newEmails = [...this.state.emails];
      newEmails.splice(idx, 1);
      this.setState({ emails: newEmails });
    };
  };

  onBillingChange = (billingDetails) => {
    this.setState({ billingDetails: { ...billingDetails } });
  };

  billingValid = () => {
    const billingDetails = this.state.billingDetails;
    // field required for company billing details
    if (
      !billingDetails ||
      !billingDetails.company_vat ||
      !billingDetails.line1 ||
      !billingDetails.postal_code ||
      !billingDetails.city ||
      !billingDetails.company_name
    ) {
      return false;
    }

    // fields required for all customers
    if (
      !billingDetails ||
      !billingDetails.country_id ||
      !billingDetails.currency_id ||
      !isValidEmail(billingDetails.user_email)
    ) {
      return false;
    }
    return true;
  };

  render() {
    const { t, firstStepBack, onBack } = this.props;
    const steps = [
      {
        key: 1,
        name: '',
        icon: 'building',
        required: true,
        valid: this.state.companyValid,
        component: (
          <CompanyName
            companyName={this.state.companyName}
            onNameChange={this.onCompanyNameChange}
            validate={this.companyValid}
          />
        ),
        onNext: () => {},
      },
      {
        key: 2,
        name: '',
        icon: 'users-alt',
        valid: this.state.teamValid,
        component: (
          <CompanyTeam
            companyName={this.state.companyName}
            teamName={this.state.teamName}
            onNameChange={this.onTeamNameChange}
            validate={this.teamValid}
          />
        ),
        onNext: () => {},
      },
      {
        key: 3,
        name: '',
        icon: 'dollar-alt',
        valid: this.billingValid(),
        component: (
          <CompanyBilling
            billingDetails={this.state.billingDetails || {}}
            changeBilling={this.onBillingChange}
          />
        ),
        onNext: () => {},
      },
      {
        key: 4,
        name: '',
        icon: 'user',
        component: (
          <CompanyInvite
            addEmail={this.addEmail}
            removeEmail={this.removeEmail}
            onEmailChange={this.handleEmailChange}
            emails={this.state.emails}
          />
        ),
        onNext: () => {},
      },
    ];

    return (
      <CreateCompanyWrapper>
        <h1 className="title">{t('common:admin.companies.createCompany')}</h1>
        <span className="sub-title">{t('common:admin.companies.createCompanyDescription')}</span>
        <Steps
          steps={steps}
          firstStepBack={firstStepBack}
          onBack={onBack}
          onSubmit={this.submitCompany}
          submitting={this.state.submitting}
        />
        {this.state.error !== null && <Message text={this.state.error} type="error" />}
      </CreateCompanyWrapper>
    );
  }
}

export default withTranslation('common')(CreateCompany);
