import React, { useState } from 'react';
import { Prompt } from 'react-router';
import Dropzone from 'react-dropzone';
import Button from '../../common/Button/Button';
import { Icon } from '../../common/Icon/Icon';
import { InputContainer, AvatarImg, StyledInput, StyledButton } from './BasicInformation.styles';
import { TabContainer, Segment, Divider, Description } from '../UserProfile.styles';
import AvatarPlaceHolderSrc from 'assets/avatar_placeholder.png';
import { postProfileUpdate, postSetPicture } from '../../../services/auth';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { sendUserInteraction } from 'utils/tagManager.utils';

const BasicInformation = ({ user, onSubmit, children, wasAdditionalChanged = () => false }) => {
  const { t } = useTranslation();

  const [newName, setNewName] = useState(user.name || '');
  const [newPhoneNumber, setNewPhoneNumber] = useState(user.phone_number || '');
  const [newPicture, setNewPicture] = useState(user.avatar);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUploadPhoto = async (pic) => {
    sendUserInteraction('user profile upload profile photo');
    if (pic.length > 0) setNewPicture(pic[0]);
  };

  const handleUploadRejected = (fileErrors) => {
    fileErrors[0].errors.forEach((error) => {
      if (error.code === 'file-too-large') {
        toast.error(t('common:userInfo.updateProfile.avatarError'));
      } else {
        toast.error(error.message);
      }
    });
  };

  const getImageUrl = () => {
    if (!newPicture) return AvatarPlaceHolderSrc;
    if (typeof newPicture === 'string') return newPicture;
    return URL.createObjectURL(newPicture);
  };

  const handleSaveChanges = async () => {
    sendUserInteraction('user profile save user profile changes');
    // check if all fields are required
    const newUserData = {
      user_id: user.id,
      name: newName,
      website: user.website,
      description: user.description,
      phone_number: newPhoneNumber,
    };
    try {
      setIsSubmitting(true);
      if (wasProfileChanged()) await postProfileUpdate(newUserData);
      if (wasPictureChanged()) await postSetPicture(newPicture, user.id);

      await onSubmit();
      toast.success(t('common:userInfo.updateProfile.updateSuccess'));
    } catch (e) {
      // handle the errors
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  const wasPictureChanged = () => {
    return newPicture !== null && typeof newPicture !== 'string';
  };

  const wasProfileChanged = () => {
    return user.name !== newName || (user.phone_number || '') !== newPhoneNumber;
  };

  const wasChanged = () => {
    return wasPictureChanged() || wasProfileChanged() || wasAdditionalChanged();
  };

  return (
    <TabContainer data-cy="user-basic-information">
      <Prompt when={wasChanged()} message="You have unsaved changes, are you sure you want to leave?" />

      <Segment>
        <div>
          <h3>{t('common:userInfo.updateProfile.yourEmail')}</h3>
          <Description>{t('common:userInfo.updateProfile.emailDescription')}</Description>
        </div>
        <InputContainer>
          <StyledInput
            label={t('common:userInfo.updateProfile.emailAddress')}
            value={user.email}
            disabled
            data-cy="user-profile-edit-email"
          />
        </InputContainer>
      </Segment>
      <Divider />

      <Segment className="user-profile-photo">
        <div>
          <h3>{t('common:userInfo.updateProfile.yourPhoto')}</h3>
          <Description>{t('common:userInfo.updateProfile.photoDescription')}</Description>
        </div>
        <InputContainer className="user-profile-photo__input-container">
          <AvatarImg data-cy="user-profile-avatar-preview" src={getImageUrl()} />

          <Dropzone
            accept={['image/jpeg', 'image/png', 'image/bmp', 'image/gif']}
            onDrop={handleUploadPhoto}
            onDropRejected={handleUploadRejected}
            maxSize={1_000_000}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input data-cy="user-profile-upload-avatar" {...getInputProps()} />
                <StyledButton labelPosition="left">
                  <Icon name="upload" />
                  <span>{t('common:userInfo.updateProfile.uploadImage')}</span>
                </StyledButton>
              </div>
            )}
          </Dropzone>
        </InputContainer>
      </Segment>
      <Divider />

      <Segment>
        <div>
          <h3>{t('common:userInfo.updateProfile.yourName')}</h3>
          <Description>{t('common:userInfo.updateProfile.nameDescription')}</Description>
        </div>
        <InputContainer>
          <StyledInput
            label={t('common:userInfo.updateProfile.yourName')}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onFocus={() => {
              sendUserInteraction('user profile name input focus');
            }}
            data-cy="user-profile-edit-name"
          />
        </InputContainer>
      </Segment>
      <Divider />

      <Segment>
        <div>
          <h3>{t('common:userInfo.updateProfile.yourPhone')}</h3>
          <Description>{t('common:userInfo.updateProfile.phoneDescription')}</Description>
        </div>
        <InputContainer>
          <StyledInput
            label={t('common:userInfo.updateProfile.phoneNumber')}
            value={newPhoneNumber}
            type="tel"
            onChange={(e) => setNewPhoneNumber(e.target.value)}
            onFocus={() => {
              sendUserInteraction('user profile phone input focus');
            }}
            data-cy="user-profile-edit-phone-number"
          />
        </InputContainer>
      </Segment>
      <Divider />
      {children}
      <Segment>
        <div />
        <InputContainer>
          <Button
            big
            labelPosition="left"
            actiontype="primary"
            onClick={handleSaveChanges}
            loading={isSubmitting}
            disabled={!wasChanged() || isSubmitting}
            data-cy="user-profile-edit-save"
            className="user-save-changes-button"
          >
            <Icon name="save" />
            {t('common:userInfo.updateProfile.saveChanges')}
          </Button>
        </InputContainer>
      </Segment>
    </TabContainer>
  );
};

export default BasicInformation;
