import React from 'react';
import { classnames } from 'utils/base.utils';

// Styles
import './style.scss';

const Card = ({
  children,
  height,
  width,
  style,
  className,
  color,
  shadow = false,
  offsetBorder = true,
  ...rest
}) => {
  const cardProps = {
    className: classnames(
      'taia-card',
      {
        shadow,
        'offset-border': offsetBorder && !shadow,
      },
      color,
      className,
    ),
    style: {
      ...style,
      '--card-height': height,
      '--card-width': width,
    },
    ...rest,
  };

  return (
    <article {...cardProps}>
      <div className="content">{children}</div>
    </article>
  );
};

export default Card;
