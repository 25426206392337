import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Table from '../../common/Table/Table';

import { getBillingDetails, getUser } from '../../../services/auth';
import { updateInvitation } from '../../../services/users';
import { setBillingDetails, setUser, removeInvite } from '../../../store/userSlice';
import Button from '../../common/Button/Button';

import { formatAPIDateString } from 'utils/date.utils';

const InviteRow = ({ invite }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancelClick = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await updateInvitation({ acceptInvite: 0, invitationId: invite.id });
      dispatch(removeInvite(invite.id));
      toast.success(t('common:toasts.userInvite.declineSuccess'));
    } catch (e) {
      toast.error(t('common:toasts.userInvite.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirmClick = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await updateInvitation({ acceptInvite: 1, invitationId: invite.id });
      const userInfo = await getUser();
      const billingDetails = await getBillingDetails(userInfo.data.id);
      dispatch(setUser({ user: userInfo.data }));
      dispatch(setBillingDetails({ billingDetails: billingDetails.data }));
      toast.success(t('common:toasts.userInvite.acceptSuccess'));
    } catch (e) {
      toast.error(t('common:toasts.userInvite.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Table.Row>
      <Table.Cell>
        <span>{t('common:userInfo.invites.createdAt')}</span>
        {formatAPIDateString({ dateString: invite.created_at })}
      </Table.Cell>
      <Table.Cell>
        <span>{t('common:userInfo.invites.invitedTo')}</span>
        {invite.invitable_type === 'App\\Models\\TaiaCompany'
          ? `${t('common:userInfo.invites.company', 'Company')}: ${invite.invitable.company_name}`
          : `${t('common:userInfo.invites.team', 'Team')}: ${invite.invitable.name}`}
      </Table.Cell>
      <Table.Cell className="invite-actions" textAlign="right" style={{ minWidth: '110px' }}>
        <Button actiontype="delete-confirm" onClick={handleCancelClick} disabled={isSubmitting}>
          {t('common:userInfo.invites.reject')}
        </Button>
        <Button actiontype="create" onClick={handleConfirmClick} loading={isSubmitting}>
          {t('common:userInfo.invites.accept')}
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default InviteRow;
