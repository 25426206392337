import { Flag } from 'components/common/Flag/Flag';
import FullscreenOverlay from 'components/common/FullscreenOverlay/FullscreenOverlay';
import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'style/theme';
import { resolveSelectedService } from 'utils/jobs.utils';
import { useDropdown } from '../../../common/Dropdown/useDropdown';
import { Icon } from '../../../common/Icon/Icon';
import Price from '../../../common/Price/Price';
import { Container, StyledItem, StyledPopup, Progress } from './ServiceTypeDropdown.styles';

const proofreadServiceTypes = [
  {
    id: 6,
    name: 'common:checkout.orderSummary.services.service6',
    description: 'Document Proofreading',
    popup: {
      text: 'common:projects.projectJobs.proofreadService.description',
    },
  },
];

const translationServiceTypes = [
  {
    id: 1,
    name: 'common:projects.projectJobs.machineTranslation.cardTitle',
    iconName: 'robot',
    description: 'common:projects.projectJobs.machineTranslation.cardSubtitle',
    featured: false,
    featuredText: 'common:projects.projectJobs.machineTranslation.featured',
    featuredColor: 'blue',
    popup: {
      serviceDescription: 'common:projects.projectJobs.machineTranslation.serviceDescription',
      whenToUse: 'common:projects.projectJobs.machineTranslation.whenToUse',
      notToUse: 'common:projects.projectJobs.machineTranslation.notToUse',
      readMoreLink: 'common:projects.projectJobs.machineTranslation.readMoreLink',
      quality: 25,
      speed: 100,
      price: 5,
    },
  },
  // {
  //   id: 2,
  //   name: 'common:projects.projectJobs.mtlr.cardTitle',
  //   iconName: 'channel-add',
  //   description: 'common:projects.projectJobs.mtlr.cardSubtitle',
  //   featured: true,
  //   featuredText: 'common:projects.projectJobs.mtlr.featured',
  //   featuredColor: 'blueLight',
  //   popup: {
  //     serviceDescription: 'common:projects.projectJobs.mtlr.serviceDescription',
  //     whenToUse: 'common:projects.projectJobs.mtlr.whenToUse',
  //     notToUse: 'common:projects.projectJobs.mtlr.notToUse',
  //     readMoreLink: 'common:projects.projectJobs.mtlr.readMoreLink',
  //     quality: 50,
  //     speed: 75,
  //     price: 25,
  //   },
  // },
  {
    id: 3,
    name: 'common:projects.projectJobs.basicTranslation.cardTitle',
    iconName: 'user',
    description: 'common:projects.projectJobs.basicTranslation.cardSubtitle',
    featured: false,
    featuredText: 'common:projects.projectJobs.basicTranslation.featured',
    featuredColor: 'blue',
    popup: {
      serviceDescription: 'common:projects.projectJobs.basicTranslation.serviceDescription',
      whenToUse: 'common:projects.projectJobs.basicTranslation.whenToUse',
      notToUse: 'common:projects.projectJobs.basicTranslation.notToUse',
      readMoreLink: 'common:projects.projectJobs.basicTranslation.readMoreLink',
      quality: 80,
      speed: 50,
      price: 50,
    },
  },
  {
    id: 4,
    name: 'common:projects.projectJobs.translationWithRevision.cardTitle',
    iconName: 'user-check',
    description: 'common:projects.projectJobs.translationWithRevision.cardSubtitle',
    featured: true,
    featuredText: 'common:projects.projectJobs.translationWithRevision.featured',
    featuredColor: 'yellow',
    popup: {
      serviceDescription: 'common:projects.projectJobs.translationWithRevision.serviceDescription',
      whenToUse: 'common:projects.projectJobs.translationWithRevision.whenToUse',
      notToUse: 'common:projects.projectJobs.translationWithRevision.notToUse',
      readMoreLink: 'common:projects.projectJobs.translationWithRevision.readMoreLink',
      quality: 90,
      speed: 35,
      price: 60,
    },
  },
  {
    id: 5,
    name: 'common:projects.projectJobs.TEP.cardTitle',
    description: 'common:projects.projectJobs.TEP.cardSubtitle',
    iconName: 'users-alt',
    featured: false,
    featuredText: 'common:projects.projectJobs.TEP.featured',
    featuredColor: 'blue',
    popup: {
      serviceDescription: 'common:projects.projectJobs.TEP.serviceDescription',
      whenToUse: 'common:projects.projectJobs.TEP.whenToUse',
      notToUse: 'common:projects.projectJobs.TEP.notToUse',
      readMoreLink: 'common:projects.projectJobs.TEP.readMoreLink',
      quality: 100,
      speed: 25,
      price: 75,
    },
  },
];

const Item = ({ item, job, currency, showBanner, ...rest }) => {
  const { t } = useTranslation();
  const { dtp_selected, dtp_price } = job;

  const calculateJobPrice = () => {
    let total = job[`service_${item.id}_price`];
    // parseFloat(getJobPrice(job));

    if (dtp_selected) {
      total += parseFloat(dtp_price);
    }

    return total;
  };

  return (
    <StyledItem {...rest} featuredColor={item.featuredColor}>
      <div className="name">
        <Icon link inline name={item.iconName || 'info-circle'} className="icon-inline" />
        {t(item.name)}
        {item.featured && showBanner && <span className="featured-badge">{t(item.featuredText)}</span>}
      </div>
      <div className="desc">{t(item.description)}</div>
      <Price currency={currency} price={calculateJobPrice()} className="price" />
    </StyledItem>
  );
};

const ItemPopup = ({ serviceType, isActive }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="description">{t(serviceType.popup.serviceDescription)}</div>
      <div className="description">{t(serviceType.popup.whenToUse)}</div>
      <div className="description">{t(serviceType.popup.notToUse)}</div>
      <div className="description-readmore">
        <a
          href={t(serviceType.popup.readMoreLink)}
          className="readmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('common:projects.projectJobs.readMore')}
        </a>
      </div>
      <div className="progress-bars">
        {serviceType.popup?.quality ? (
          <>
            Quality <Progress progress={serviceType.popup.quality} animate={isActive} />
          </>
        ) : null}
        {serviceType.popup?.speed ? (
          <>
            Speed <Progress progress={serviceType.popup.speed} animate={isActive} />
          </>
        ) : null}
        {serviceType.popup?.price ? (
          <>
            Price <Progress progress={serviceType.popup.price} animate={isActive} />
          </>
        ) : null}
      </div>
    </>
  );
};

const ServiceTypeDropdown = ({ onChange, job, isProofread, currency }) => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  const handleJobChange = (newState) => {
    onChange(null, newState.selectedItem);
  };

  const findSelectedService = (job) => {
    return serviceTypes.find((type) => type.id === resolveSelectedService(job));
  };

  const serviceTypes = useMemo(
    () => (isProofread ? proofreadServiceTypes : translationServiceTypes),
    [isProofread],
  );

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    handleCloseDropdown,
    getItemProps,
    getMenuProps,
    getToggleProps,
  } = useDropdown({
    items: serviceTypes,
    initialState: { selectedItem: serviceTypes[2] },
    selectedItem: findSelectedService(job),
    onSelectedItemChange: handleJobChange,
    confirmChoice: true,
  });

  const getClasses = () => {
    const classes = ['dropdown'];
    if (isOpen) classes.push('open');
    return classes.join(' ');
  };

  return (
    <>
      <Container data-cy="order-project-serivce-type-select" className={getClasses()}>
        <div className="dropdown-header" data-cy="dropdown-header" {...getToggleProps()}>
          <Item item={selectedItem} header job={job} currency={currency} showBanner={false} />
          <Icon name="angle-down" />
        </div>

        <div className="dropdown-menu" data-cy="dropdown-menu" {...getMenuProps()}>
          <div className="dropdown-menu__mobile-header">
            <div className="title">
              <h2>Select service type</h2>
              <Flag className="flag" lang={job.target_language} />
              <span>{job.target_language.name}</span>
            </div>
            <button className="close-btn" onClick={handleCloseDropdown}>
              <Icon name="times" size="big" />
            </button>
          </div>
          {serviceTypes.map((serviceType, index) => (
            <StyledPopup
              key={serviceType.id}
              dark
              position={isMobile ? 'bottom-center' : 'right-center'}
              open={isMobile ? false : isOpen ? undefined : false}
              trigger={
                <div
                  data-cy="dropdown-item"
                  data-selected={selectedItem.id === serviceType.id}
                  className={`dropdown-item ${index === highlightedIndex ? 'highlighted' : ''}`}
                  {...getItemProps({ item: serviceType, index })}
                >
                  <Item item={serviceType} job={job} currency={currency} showBanner={true} />
                </div>
              }
            >
              <ItemPopup serviceType={serviceType} isActive={index === highlightedIndex} />
            </StyledPopup>
          ))}
        </div>
      </Container>
      <FullscreenOverlay open={isOpen} />
    </>
  );
};

export default ServiceTypeDropdown;
