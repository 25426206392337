import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BalanceInput from './BalanceInput';
import CurrenciesDropdown from './CurrenciesDropdown';
import VerifiedInput from './VerifiedInput';

import { Container, InputContainer, InputsContainer } from './Inputs.styles';
import { CurrencyContainer } from './PaymentInputs.styles';

import { hasAdminPermissions } from '../../../../utils/user.utils';
import { currencyByIdSelector } from '../../../../store/selectors';
import { withTheme } from 'styled-components';
import { Icon } from '../../Icon/Icon';

const PaymentInputs = ({
  currency_id = null,
  euro_balance = null,
  isCompany = false,
  project_invoices = null,
  updateProperty,
  customer,
  verified = null,
  theme,
}) => {
  const userRole = useSelector((state) => state.userStore.user.role);
  const isAdmin = hasAdminPermissions(userRole);

  const getCurrencyById = useSelector(currencyByIdSelector);
  const currencyData = getCurrencyById(currency_id);

  const currencyLabel = currencyData ? `${currencyData.code} (${currencyData.symbol})` : '';

  const { t } = useTranslation();

  return (
    <Container>
      <Icon name="money-bill" size="large" color={theme.colors.blue} style={{ display: 'block' }} />
      <InputsContainer>
        <CurrencyContainer>
          <InputContainer>
            <CurrenciesDropdown
              currency_id={currency_id}
              updateProperty={updateProperty}
              disabled={customer}
            />
            <div className="currency-reason">{t('common:checkout.billing.form.currencyReason')}</div>
          </InputContainer>

          {isAdmin && (
            <InputContainer>
              <BalanceInput
                currencyLabel={currencyLabel}
                currency_id={currency_id}
                euro_balance={euro_balance}
                updateProperty={updateProperty}
              />
            </InputContainer>
          )}
        </CurrencyContainer>
        {isAdmin && isCompany && (
          <VerifiedInput
            project_invoices={project_invoices}
            updateProperty={updateProperty}
            verified={verified}
          />
        )}
      </InputsContainer>
    </Container>
  );
};

export default withTheme(PaymentInputs);
