import styled from 'styled-components';
import Button from '../../common/Button/Button';
import { Header } from 'semantic-ui-react';
import Card from '../../common/Card/Card';

export const ReturnLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
  opacity: 1;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  overflow: auto;
  height: calc((var(--vh, 1vh) * 100));

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    margin-right: 0;
    height: calc(
      (var(--vh, 1vh) * 100) - 
      var(--sidebar-header-height) - 
      var(--mobile-steps-controls-height) -
      var(--email-activation-resend-banner-height)
    );
  `}
`;

export const MainContentContainer = styled.div`
  padding: 54px;
  width: 100%;

  ${({ theme }) => theme.mediaMinWidth.mobile`
    overflow: auto;
  `}

  ${({ theme }) => theme.mediaMaxWidth.desktop`
    padding: 54px 2.5rem;
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    padding: 0;
    display: flex;
    flex-grow: 1;
  `}
`;

export const StyledButton = styled(Button)`
  display: block !important;
  margin: 2em 0 0 auto !important;
  font-family: 'DM Sans', sans-serif;
`;

export const StyledHeader = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-size: 2.14285714em;
  margin: 3rem 0 2rem;
  font-weight: normal;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  grid-gap: 1.57142857em;

  ${({ theme }) => theme.mediaMaxWidth.HD`
    grid-template-columns: auto 40%;
  `}

  ${({ theme }) => theme.mediaMaxWidth.desktopMedium`
    grid-template-columns: 1fr;
    gap: 4rem 0;
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    gap: 0;
    width: 100%;
  `}
`;

export const QuoteButton = styled(Button)`
  font-family: 'DM Sans', sans-serif;
  font-size: 1.14285714em !important;
  background-color: #e9e9e9 !important;
  border: none !important;
  display: flex !important;
  margin-top: 1.2em !important;
  justify-content: center;
  column-gap: 0.5em;

  &:hover {
    background-color: #e0e0e0 !important;
  }

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    &.button.fluid {
      margin-left: ${theme.paddings.mainContainer.mobile.x};
      margin-right: ${theme.paddings.mainContainer.mobile.x};
      width: calc(100% - (${theme.paddings.mainContainer.mobile.x} * 2));
    }
  `}
`;

export const StyledLabel = styled.div`
  font-size: 11px;
  padding: 1px 10px;
  background-color: #c4c4c4;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
`;

export const StyledHeader2 = styled(Header)`
  text-align: left;
`;

export const StyledSegment = styled(Card)`
  height: 100%;
  flex: 0.5;
  display: flex;
  flex-direction: column;
  .comment {
    display: grid;
    grid-template-rows: 35px 1fr;
    height: 100%;
  }
`;

export const MainContainerOld = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const MainContainer = styled.div`
  display: grid;
  grid-template-areas:
    'details comment  pricing'
    'details delivery pricing'
    'jobs    jobs     pricing';
  grid-template-columns: 3fr 3fr 2fr;
  grid-gap: 18px;

  @media only screen and (max-width: 1312px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'details comment'
      'details delivery'
      'jobs    jobs'
      'pricing pricing';
  }

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'details'
      'comment'
      'delivery'
      'jobs'
      'pricing';
  }

  .grid-details {
    grid-area: details;
  }

  .grid-comment {
    grid-area: comment;
  }

  .grid-delivery {
    grid-area: delivery;
  }

  .grid-pricing {
    grid-area: pricing;
  }

  .grid-jobs {
    grid-area: jobs;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1000px;
  margin-bottom: 1rem;
  @media only screen and (min-width: 1700px) {
    margin-bottom: 0;
    margin-right: 1rem;
  }
`;

export const ControlsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  width: 1000px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderSummaryColumn = styled.div`
  .sticky-container {
    position: sticky;
    // Wrappers top padding + offset 20px (space between header and order summary card)
    top: -34px;

    &:not([not-stuck]) {
      max-height: calc((var(--vh, 1vh) * 100) - 40px - var(--project-order-header-height));
      overflow-y: auto;
    }
  }

  ${({ theme, step, stepsLength }) => theme.mediaMaxWidth.mobile`
    position: ${step === stepsLength ? 'relative' : 'absolute'};
    opacity: ${step === stepsLength ? 1 : 0};
    pointer-events: ${step === stepsLength ? 'all' : 'none'};

    .sticky-container {
      padding-bottom: 2rem
      position: relative;
      top: 0;

      &:not([not-stuck]) {
        max-height: initial;
      }
    }
  `}
`;
