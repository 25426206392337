import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '../../Dropdown/Dropdown';
import AssignBillingModal from '../../../Company/CompanyBilling/CompanyBillingCard/AssignBillingModal';
import { Icon } from '../../Icon/Icon';
import { StyledIcon } from './CompanyDropdown.styles';

import CouponModal from '../CouponModal';
import CompanyEditForm from '../EditForm/CompanyEditForm';

import { sendUserInteraction } from 'utils/tagManager.utils';
import { getBillingSubscriptions } from '../../../../services/subscriptions';

const CompanyDropdown = ({
  billingDetails,
  billingId,
  company_id,
  refetchTeams,
  teams = [],
  updateBillingDetails,
}) => {
  const { t } = useTranslation();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subs = (await getBillingSubscriptions({ billing_id: billingId, active: true })).data;
        setSubscriptions(subs);
      } catch (e) {
        console.error(e);
      }
    };
    fetchSubscriptions();
  }, [billingId]);

  const handleAssignClick = (e) => {
    sendUserInteraction('company billing open assign billing modal');
    e.preventDefault();
    setShowAssignModal(true);
  };

  const handleAssignModalClose = () => {
    setShowAssignModal(false);
  };

  const handleCouponClick = (e) => {
    sendUserInteraction('company billing open assign coupon modal');
    e.preventDefault();
    setShowCouponModal(true);
  };

  const handleCouponModalClose = () => {
    setShowCouponModal(false);
  };

  const handleEditClick = (e) => {
    sendUserInteraction('company billing open edit billing modal');
    e.preventDefault();
    setShowEditForm(true);
  };

  const handleEditModalClose = () => {
    setShowEditForm(false);
  };

  return (
    <>
      <Dropdown
        style={{ width: 'fit-content', marginLeft: 'auto' }}
        direction="left"
        trigger={<StyledIcon name="ellipsis-h" />}
      >
        <Dropdown.Item onClick={handleEditClick} index={0}>
          <Icon name="edit-alt" />
          {t('common:userInfo.billing.labels.editBilling')}
        </Dropdown.Item>
        <Dropdown.Item disabled={teams.length === 0} onClick={handleAssignClick} index={1}>
          <Icon name="file-check-alt" />
          {t('common:company.billing.assignToTeams')}
        </Dropdown.Item>
        <Dropdown.Item onClick={handleCouponClick} index={2}>
          <Icon name="tag-alt" />
          {t('common:userInfo.billing.labels.useCoupon')}
        </Dropdown.Item>
      </Dropdown>
      {showAssignModal && (
        <AssignBillingModal
          billingId={billingId}
          billingName={billingDetails.name}
          subscriptions={subscriptions}
          onClose={handleAssignModalClose}
          refetchCompanyTeams={refetchTeams}
          teams={teams}
        />
      )}
      {showCouponModal && (
        <CouponModal
          billingId={billingId}
          closeModal={handleCouponModalClose}
          updateBilling={updateBillingDetails}
        />
      )}
      {showEditForm && (
        <CompanyEditForm
          closeModal={handleEditModalClose}
          company_id={company_id}
          initialBillingDetails={billingDetails}
          updateBilling={updateBillingDetails}
        />
      )}
    </>
  );
};

export default CompanyDropdown;
