import styled from 'styled-components';

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  .step-padding .step-content .step-number {
    margin-left: 42px;
  }
`;

export const Step = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: 72px;
  border-bottom: 1px solid #e1e1e1;

  .step-content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.colors.greyLight};
    color: rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease-out;
    .step-number {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      margin-right: 12px;
      margin-left: 28px;
      font-size: 18px;
      border: 1px solid;
      border-radius: 50%;
      transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
      background-color: #e1e1e1;
      color: #999999;
      border-color: transparent;
    }
  }

  .step-arrow {
    position: absolute;
    right: -24px;
    z-index: 2;
    polygon {
      stroke: #dcdcdc;
      fill: ${({ theme }) => theme.colors.greyLight};
      stroke-width: 1;
      transition: fill 0.3s ease-out;
    }
    // override left border of triangle
    line {
      stroke: ${({ theme }) => theme.colors.greyLight};
      stroke-width: 2;
      transition: stroke 0.3s ease-out;
    }
  }

  &.active {
    .step-content {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      font-weight: bold;

      .step-number {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.blue};
        border-color: ${({ theme }) => theme.colors.blue};
        font-weight: normal;
      }
    }
    .step-arrow {
      polygon {
        fill: ${({ theme }) => theme.colors.white};
      }
      line {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }
  }

  &.enabled {
    cursor: pointer;
    .step-content > .step-number {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &.clickable {
    cursor: pointer;
  }
`;
