const { default: styled } = require('styled-components');

export const InvitesLayout = styled.div`
  position: relative;
  /* display: grid; */
  flex: 1;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
