import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import qs from 'query-string';
import SignUpForm from 'components/SignUp/SignUpForm';
import { Footer, InnerContainer, StyledCard } from './SignupIsntantQuote.style';
import { StyledHeader } from 'components/common.styles';
import { StyledLink } from 'components/common.styles';
import { StyledSpan } from 'components/common.styles';
import { sendUserInteraction } from 'utils/tagManager.utils';
import MetaTag from 'components/MetaTag/MetaTag';
import Steps from 'components/NewProject/Steps/Steps';

const steps = [
  { number: 1, text: 'Sign up' },
  { number: 2, text: 'Upload File' },
  { number: 3, text: 'Get Quote' },
];
const SignupInstantQuote = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <MetaTag name="robots" content="noindex, nofollow" />
      <StyledCard>
        <Steps activeStep={1} validSteps={[]} setActiveStep={() => {}} steps={steps}></Steps>
        <InnerContainer>
          <StyledHeader>{t('common:instantQuote.header')}</StyledHeader>
          <SignUpForm
            email={qs.parse(search).email}
            code={qs.parse(search).code}
            name={qs.parse(search).name}
          />
        </InnerContainer>
        <Footer>
          <StyledSpan>{t('common:signUp.alreadyHaveAccount')}</StyledSpan>
          <StyledLink onClick={() => sendUserInteraction('clicked link Log in now')} to="/">
            {t('common:signUp.logInNow')}
          </StyledLink>
        </Footer>
      </StyledCard>
    </>
  );
};

export default SignupInstantQuote;
