import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'semantic-ui-react';
import Button from '../../common/Button/Button';
import { postUpdateStatus, postUpdateProjectById } from '../../../services/project';
import { sendUserInteraction } from 'utils/tagManager.utils';

const UpdateStatusCategoryForm = ({ t, project, onSubmit }) => {
  const [newStatus, setNewStatus] = useState(project.status_id);
  const [newCategory, setNewCategory] = useState(project.category_id);
  const [isLoading, setIsLoading] = useState(false);
  const categories = useSelector((state) => state.classifiersStore.categories);
  const categoryOptions = categories.map((category) => ({
    value: category.id,
    key: category.id,
    text: category.name,
  }));
  const statusOptions = [
    /*{ key: 1, value: 1, text: t("common:projects.projectStatus.created", "Created") },*/
    { key: 2, value: 2, text: t('common:projects.projectStatus.draft', 'Draft') },
    { key: 3, value: 3, text: t('common:projects.projectStatus.open', 'Open') },
    { key: 4, value: 4, text: t('common:projects.projectStatus.inProgress', 'In Progress') },
    { key: 5, value: 5, text: t('common:projects.projectStatus.review', 'Review') },
    { key: 51, value: 51, text: t('common:projects.projectStatus.revisionAssigned', 'Revision Assigned') },
    { key: 52, value: 52, text: t('common:projects.projectStatus.revisionAccepted', 'Revision Accepted') },
    { key: 53, value: 53, text: t('common:projects.projectStatus.revisionFinished', 'Revision Finished') },
    { key: 6, value: 6, text: t('common:projects.projectStatus.finished', 'Finished') },
    { key: 20, value: 20, text: t('common:projects.projectStatus.archived', 'Archived') },
    {
      key: 10,
      value: 10,
      text: t('common:projects.projectStatus.selfServiceInProgress', 'Self-service - In progress<'),
    },
    {
      key: 11,
      value: 11,
      text: t('common:projects.projectStatus.selfServiceFinished', 'Self-service - Finished'),
    },
  ];

  useEffect(() => {
    setNewStatus(project.status_id);
  }, [project.status_id]);

  const handleSubmitClick = async () => {
    sendUserInteraction('admin project submit category and status change');
    try {
      setIsLoading(true);
      if (newStatus !== project.status_id) {
        await postUpdateStatus(project.id, newStatus);
        toast.success(t('common:toasts.projectStatus.success'));
      }

      if (newCategory !== project.category_id) {
        await postUpdateProjectById({ comment: project.comment, id: project.id, category_id: newCategory });
        toast.success(t('common:toasts.projectCategory.success'));
      }
      await onSubmit();
    } catch (e) {
      if (newStatus !== project.status_id) {
        toast.error(t('common:toasts.projectStatus.error'));
      }
      if (newCategory !== project.category_id) {
        toast.error(t('common:toasts.projectCategory.error'));
      }
      throw e;
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Form>
      <Form.Dropdown
        selection
        label={t('common:admin.project.projectCategory')}
        options={categoryOptions}
        value={newCategory}
        onChange={(e, { value }) => {
          sendUserInteraction('admin project change project category');
          setNewCategory(value);
        }}
      />
      <Form.Dropdown
        selection
        label={t('common:admin.project.projectStatus')}
        options={statusOptions}
        value={newStatus}
        onChange={(e, { value }) => {
          sendUserInteraction('admin project change project status');
          setNewStatus(value);
        }}
      />
      <Button loading={isLoading} actiontype="submit" onClick={handleSubmitClick}>
        {t('common:update')}
      </Button>
    </Form>
  );
};

export default withTranslation('common')(UpdateStatusCategoryForm);
