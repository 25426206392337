import React from 'react';
import Table from '../../common/Table/Table';
import { Name, TeamName, Email, StyledLabel } from './CompanyBilling.style';

import { localiseAndConvertPrice } from '../../../utils/string.utils';
import { currencyByIdSelector } from '../../../store/selectors';
import CompanyDropdown from '../../common/BillingDetails/CompanyDropdown/CompanyDropdown';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const BillingTableRow = ({ billing, refetchTeams, teams, updateBillingDetails }) => {
  const getCurrencyById = useSelector(currencyByIdSelector);
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  return (
    <Table.Row>
      <Table.Cell>
        <Name>{billing.name || t('common:userInfo.billing.billingDetails')}</Name>
        <TeamName>{billing.company_name}</TeamName>
      </Table.Cell>
      <Table.Cell>{billing.company_vat}</Table.Cell>
      <Table.Cell>
        <Email>{billing.user_email || t('common:userInfo.billing.errors.emailNotSet')}</Email>
      </Table.Cell>
      {/* <Table.Cell>{'verified billing'}</Table.Cell> */}
      <Table.Cell>
        <StyledLabel charged={billing.vat_rate !== null || billing.digital_vat_rate !== null}>
          {billing.vat_rate !== null || billing.digital_vat_rate !== null
            ? t('common:userInfo.billing.labels.vatCharged')
            : t('common:userInfo.billing.labels.vatNotCharged')}
        </StyledLabel>
      </Table.Cell>
      <Table.Cell>
        {billing.euro_balance &&
          localiseAndConvertPrice({
            currencyData: getCurrencyById(billing.currency_id),
            number: billing.euro_balance,
            language: i18n.language,
          })}
      </Table.Cell>
      <Table.Cell>
        <CompanyDropdown
          billingDetails={billing}
          billingId={billing.id}
          company_id={id}
          refetchTeams={refetchTeams}
          teams={teams}
          updateBillingDetails={updateBillingDetails}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default BillingTableRow;
