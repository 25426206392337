import React, { useMemo } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FileLabelWrapper, FileUploadWrapper } from './FileUpload.style';
import { Icon } from 'components/common/Icon/Icon';
import { camelCase } from 'lodash';

const FileLabel = ({ file, onRemove = () => {} }) => {
  return (
    <FileLabelWrapper>
      <div className="file-name">{file.name}</div>
      <div className="remove-icon" onClick={onRemove}>
        <Icon name="times"></Icon>
      </div>
    </FileLabelWrapper>
  );
};

const FileUpload = ({ files = [], onFilesChange = () => {}, error }) => {
  const [text, setText] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  const { t } = useTranslation();
  const textAreaRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleUploadClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleFilesChange = (e) => {
    const fls = [...files, ...e.target.files];
    onFilesChange(fls);
  };

  const handleFilesDrop = (e) => {
    e.preventDefault();

    const fls = [];

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          var file = e.dataTransfer.items[i].getAsFile();
          fls.push(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        fls.push(e.dataTransfer.files[i]);
      }
    }
    onFilesChange([...files, ...fls]);
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const addTextFile = () => {
    const blob = new Blob([text], { type: 'text/plain' });
    const file = new File([blob], `${camelCase(text).substring(0, 7)}.txt`, { type: 'text/plain' });
    const fls = [...files, file];
    setText('');
    onFilesChange(fls);
  };

  const handleAddFile = (e) => {
    if (e.charCode === 13 && e.shiftKey) {
      e.preventDefault();
      addTextFile();
    }
  };

  const fileLabels = useMemo(() => {
    const onFileRemove = (idx) => {
      return () => {
        const fls = [...files];
        fls.splice(idx, 1);
        onFilesChange(fls);
      };
    };
    return files.map((file, i) => <FileLabel key={i} file={file} onRemove={onFileRemove(i)} />);
  }, [files, onFilesChange]);

  return (
    <FileUploadWrapper
      onDrop={handleFilesDrop}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      className={error && 'error'}
    >
      <div className="file-input">
        <input
          type="file"
          ref={fileInputRef}
          multiple
          onChange={handleFilesChange}
          style={{ display: 'none' }}
        />
        <div className="background-icon">
          <div>
            <Icon name="upload" size="big"></Icon>
            {isDragging && <div>{t('common:fileUpload.dragAndDrop')}</div>}
          </div>
        </div>
        <div className="text-file-notice">{t('common:fileUpload.addTextFile')}</div>
        <div className="upload-textarea">
          {!isDragging && (
            <>
              <div
                className={`placeholder ${text.length > 0 ? 'hidden' : ''}`}
                onClick={() => {
                  textAreaRef.current.focus();
                }}
              >
                {t('common:fileUpload.placeholder')}{' '}
                <span className="upload-link" onClick={handleUploadClick}>
                  {t('common:fileUpload.uploadLink')}
                </span>
              </div>
              <textarea
                ref={textAreaRef}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                onKeyPress={handleAddFile}
              />
            </>
          )}
        </div>
      </div>
      <div className="files-section">{fileLabels}</div>
    </FileUploadWrapper>
  );
};

export default FileUpload;
