import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../../common/Button/Button';
import { Modal } from 'semantic-ui-react';
import Table from '../../../common/Table/Table';
import { Popup } from 'components/common/Popup/Popup';

import { revokeInvitation } from '../../../../services/company';

import { formatAPIDateString } from 'utils/date.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

import { TEAM_TRANSLATIONS } from '../../../../constants/roles';

const InviteRow = ({ deleteInvite, invite }) => {
  const { t } = useTranslation();

  const [isConfirming, setIsConfirming] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleRevokeClick = (e) => {
    sendUserInteraction('clicked revoke invitation');
    e.preventDefault();
    setIsConfirming(true);
  };

  const handleCancelClick = (e) => {
    sendUserInteraction('clicked cancel revoke');
    e.preventDefault();
    setIsConfirming(false);
  };

  const handleConfirmClick = async (e) => {
    sendUserInteraction('clicked confirm revoke invite');
    e.preventDefault();
    setIsUpdating(true);
    try {
      await revokeInvitation(invite.id);
      deleteInvite(invite.id);
      toast.success(t('common:toasts.revokeCompanyInvite.success'));
    } catch (e) {
      toast.error(t('common:toasts.revokeCompanyInvite.error'));
    } finally {
      setIsConfirming(false);
      setIsUpdating(false);
    }
  };

  return (
    <Table.Row>
      <Table.Cell>{invite.email}</Table.Cell>
      <Table.Cell>{t(TEAM_TRANSLATIONS[invite.role_id])}</Table.Cell>
      <Table.Cell>{formatAPIDateString({ dateString: invite.created_at, showTime: false })}</Table.Cell>
      <Table.Cell textAlign="right">
        <Popup
          trigger={<Button style={{ border: 'none' }} icon="trash-alt" onClick={handleRevokeClick}></Button>}
          style={{ maxWidth: '255px' }}
          dark
        >
          {t('common:company.invites.revoke')}
        </Popup>

        <Modal size="small" open={isConfirming} onClose={() => setIsConfirming(false)}>
          <Modal.Header> {`${t('common:team.invites.revokeModalHeader')} ${invite.email}`}</Modal.Header>
          <Modal.Content>{t('common:team.invites.revokeIsFinal')}</Modal.Content>
          <Modal.Actions>
            <Button actiontype="secondary" onClick={handleCancelClick} disabled={isUpdating}>
              {t('common:cancel')}
            </Button>
            <Button
              actiontype="primary"
              onClick={handleConfirmClick}
              loading={isUpdating}
              disabled={isUpdating}
            >
              {t('common:confirm')}
            </Button>
          </Modal.Actions>
        </Modal>
      </Table.Cell>
    </Table.Row>
  );
};

export default InviteRow;
