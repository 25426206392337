import styled from 'styled-components';
import { truncateStyles } from '../../common.styles';

export const CompanyBillingWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const Name = styled.div`
  font-size: 18px;
  color: black;
  ${truncateStyles};
  max-width: 24ch;
`;

export const TeamName = styled.div`
  font-size: 14px;
  ${truncateStyles};
  max-width: 24ch;
`;

export const Email = styled.div`
  ${truncateStyles};
  max-width: 24ch;
`;

export const StyledLabel = styled.div`
  width: fit-content;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 1px 10px;
  border-radius: 10px;
  color: #fff;
  background-color: ${({ charged, theme }) => (charged ? '#999999' : theme.colors.grey)};
`;
