import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { List, Header, Modal } from 'semantic-ui-react';
import Button from '../../../common/Button/Button';
import Message from '../../../common/Message/Message';
import { Checkbox } from '../../../common/Checkbox/Checkbox';

import { postUpdateTeam } from '../../../../services/teams';

const AssignBillingModal = ({
  billingId,
  billingName,
  onClose,
  refetchCompanyTeams,
  teams,
  subscriptions = [],
}) => {
  const [isAssigning, setIsAssigning] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const { t } = useTranslation();

  const isTeamSelected = (teamId) => {
    return selectedTeams.some((selectedId) => selectedId === teamId);
  };

  const handleTeamClick = (teamId) => {
    if (isTeamSelected(teamId)) {
      const newSelectedTeams = selectedTeams.filter((selectedId) => selectedId !== teamId);
      setSelectedTeams(newSelectedTeams);
    } else {
      const newSelectedTeams = [...selectedTeams, teamId];
      setSelectedTeams(newSelectedTeams);
    }
  };

  const updateTeamBilling = async (teamId) => {
    const team = teams.find((team) => team.id === teamId);
    const teamName = team.name;
    try {
      await postUpdateTeam({
        name: teamName,
        team_id: teamId,
        billing_detail_id: billingId,
      });
      toast.success(`${teamName} : ${t('common:toasts.updateBilling.success')}`);
    } catch (e) {
      toast.error(`${teamName} : ${t('common:toasts.updateBilling.error')}`);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsAssigning(true);

    await Promise.all(
      selectedTeams.map(async (teamId) => {
        await updateTeamBilling(teamId);
      }),
    );
    await refetchCompanyTeams();
    setIsAssigning(false);
    onClose();
  };

  return (
    <Modal open size="small">
      <Modal.Header as="h2">{billingName}</Modal.Header>
      <Modal.Content>
        <Header as="h4">{`${t('common:company.billing.pickTeams')}:`}</Header>
        <List divided relaxed selection>
          {teams.map((team) => (
            <List.Item key={team.id} onClick={() => handleTeamClick(team.id)} style={{ display: 'flex' }}>
              <Checkbox checked={isTeamSelected(team.id)} value={team.id} style={{ width: '22px' }} />
              <List.Content style={{ marginLeft: '1em' }}>
                <List.Header as="h4">{team.name}</List.Header>
                <List.Description>
                  {`${t('common:company.billing.currentBilling')}: `}
                  {team.billing_detail ? team.billing_detail.name : t('common:company.billing.noBilling')}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
        {subscriptions.length > 0 && (
          <Message type="info" text={t('common:company.billing.hasSubscirption')} />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button actiontype="secondary" disabled={isAssigning} onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button
          actiontype="primary"
          disabled={selectedTeams.length === 0}
          loading={isAssigning}
          onClick={onSubmit}
        >
          {t('common:confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AssignBillingModal;
