import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '../../../../common/Accordion/Accordion';
import Loader from '../../../../common/Loader/Loader';
import Message from '../../../../common/Message/Message';
import Pagination from '../../../../common/Pagination/Pagination';
import { LoaderContainer, PaginationContainer } from './UsageTable.styles';
import SeatsTable from './SeatsTable';
import TimeframesDropdown from './TimeframesDropdown';

import { getUsageHistory, getUsageHistoryTimeframes } from '../../../../../services/subscriptions';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

const PER_PAGE = 10;

const UsageTable = ({ subscriptionId }) => {
  const [activePage, setActivePage] = useLocalStorage('subscription-usage-table-page', 1);
  const [historyError, setHistoryError] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [historyTimeframes, setHistoryTimeFrames] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loadingHistoryData, setLoadingHistoryData] = useState(true); // component should load this data on mount
  const [loadingTimeframes, setLoadingtimeframes] = useState(true); // component should load this data on mount
  const [selectedInterval, setSelectedInterval] = useState({});
  const { t } = useTranslation();
  const [timeframeError, setTimeframeError] = useState(false);

  const hasTimeframes = useMemo(() => historyTimeframes.length > 0, [historyTimeframes]);

  useEffect(() => {
    const fetchTimeFrames = async () => {
      try {
        setTimeframeError(false);
        setLoadingtimeframes(true);
        const newTimeFrames = await getUsageHistoryTimeframes(subscriptionId);
        setHistoryTimeFrames(newTimeFrames);
      } catch (e) {
        setTimeframeError(true);
        setHistoryTimeFrames([]);
        throw e;
      } finally {
        setLoadingtimeframes(false);
      }
    };

    fetchTimeFrames();
  }, [subscriptionId]);

  useEffect(() => {
    if (hasTimeframes) {
      setSelectedInterval(historyTimeframes[0]);
    }
  }, [hasTimeframes, historyTimeframes]);

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        setLoadingHistoryData(true);

        setHistoryError(false);

        const { data, last_page } = await getUsageHistory({
          end_date: selectedInterval.end_date,
          page: activePage,
          per_page: PER_PAGE,
          subscription_id: subscriptionId,
        });

        if (activePage > last_page) {
          setActivePage(last_page);
        }

        setLastPage(last_page);

        setHistoryData(data);
      } catch (e) {
        setHistoryError(true);
        setHistoryData([]);
        throw e;
      } finally {
        setLoadingHistoryData(false);
      }
    };

    fetchHistoryData();
  }, [activePage, selectedInterval, subscriptionId, setActivePage]);

  const handlePaginationClick = (e, { activePage }) => {
    sendUserInteraction('subscription overview page usage table pagination click');
    e.preventDefault();
    setActivePage(activePage);
  };

  return (
    <Accordion title={t('common:catapult.subscription.usageTable.heading')} watch={[...historyData]}>
      {loadingTimeframes ? (
        <LoaderContainer>
          <Loader inline />
        </LoaderContainer>
      ) : timeframeError ? (
        <Message text={t('common:catapult.subscription.usageTable.error')} type="error" />
      ) : hasTimeframes ? (
        <>
          <TimeframesDropdown
            historyTimeframes={historyTimeframes}
            loading={loadingTimeframes}
            selectedInterval={selectedInterval}
            setSelectedInterval={(newInterval) => {
              sendUserInteraction('subscription overview page usage table change time interval');
              setSelectedInterval(newInterval);
            }}
          />
          {historyError ? (
            <Message text={t('common:catapult.subscription.usageTable.error')} type="error" />
          ) : (
            <>
              <SeatsTable loading={loadingHistoryData} seats={historyData} />
              {lastPage > 1 && (
                <PaginationContainer>
                  <Pagination
                    activePage={activePage}
                    loading={loadingHistoryData}
                    totalPages={lastPage}
                    onPageChange={handlePaginationClick}
                  />
                </PaginationContainer>
              )}
            </>
          )}
        </>
      ) : (
        <Message text={t('common:catapult.subscription.usageTable.noData')} type="info" />
      )}
    </Accordion>
  );
};

export default UsageTable;
