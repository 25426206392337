import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Libs
import DayPicker, { DateUtils } from 'react-day-picker';
import Floater from 'react-floater';

// Utils
import { formatDate } from 'utils/date.utils';

// Components
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import Button from '../Button/Button';

// Styles
import './style.scss';
import { ZINDEX } from 'constants/zIndex';

/**
 * Renders date picker panel
 */
const DatePickerPanel = ({ from, to, onDatesChange }) => {
  // State
  const [enteredTo, setEnteredTo] = useState(null);

  // Functions
  const isSelectingFirstDay = (day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day) => {
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }

    if (isSelectingFirstDay(day)) {
      setEnteredTo(null);
      onDatesChange([day, null]);
    } else {
      setEnteredTo(day);
      onDatesChange([from, day]);
    }
  };

  const handleLastMonthClick = () => {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setDate(1);

    const endDate = new Date();
    endDate.setDate(0);

    onDatesChange([startDate, endDate]);

    setEnteredTo(endDate);
  };

  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(day)) {
      setEnteredTo(day);
    }
  };

  const handleResetClick = () => {
    setEnteredTo(null);
    onDatesChange([null, null]);
  };

  return (
    <div>
      <div className="date-picker-reset">
        <Button onClick={handleLastMonthClick}>Last month</Button>
        <Button onClick={handleResetClick}>Reset</Button>
      </div>

      <DayPicker
        showOutsideDays
        className="Range"
        selectedDays={[from, { from: from, to: to }]}
        modifiers={{ start: from, end: enteredTo }}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
      />
      <div>
        {!from && !to && 'Please select the first day.'}
        {from && !to && 'Please select the last day.'}
        {from &&
          to &&
          `Selected from ${formatDate({ dateObject: from, showTime: false })} to
              ${formatDate({ dateObject: to, showTime: false })}`}{' '}
      </div>
    </div>
  );
};

/**
 * Render a date picker range button which opens a floater containing date picker panel
 */
const DatepickerRange = ({ startDate = null, endDate = null, placeholder, onChange = () => {} }) => {
  // State
  const [open, setOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    // Initial start date
    startDate ? new Date(startDate) : null,
    endDate ? new Date(endDate) : null,
  ]);

  // Functions
  const onDatesChange = (dates) => {
    setSelectedDates(dates);
    onChange(dates);
  };

  const callback = (action, props) => {
    setOpen(action === 'open');
  };

  // Effects
  /**
   * Run on startDate and endDate props change to keep internal state in sync
   */
  useEffect(() => {
    setSelectedDates([
      // Initial start date
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
    ]);
  }, [startDate, endDate]);

  // Generate button text
  const buttonText = !!selectedDates[0]
    ? !!selectedDates[1]
      ? 'From ' +
        formatDate({ dateObject: selectedDates[0], showTime: false }) +
        ' to ' +
        formatDate({ dateObject: selectedDates[1], showTime: false })
      : 'From ' + formatDate({ dateObject: selectedDates[0], showTime: false })
    : placeholder;

  return (
    <>
      <Floater
        open={open}
        callback={callback}
        styles={{ options: { zIndex: ZINDEX.bgOverlay + 1 } }}
        placement="auto"
        content={
          <DatePickerPanel onDatesChange={onDatesChange} from={selectedDates[0]} to={selectedDates[1]} />
        }
      >
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {buttonText}
        </Button>
      </Floater>
      {open && <FullscreenOverlay open disabled={false} onClick={() => setOpen(!open)} />}
    </>
  );
};

// Prop types
/**
 * Prop types for range
 */
DatepickerRange.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

/**
 * Prop types for panel
 */
DatePickerPanel.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  onDatesChange: PropTypes.func,
};

export default DatepickerRange;
