import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SubscriptionTeams = ({ teams = [] }) => {
  const { t } = useTranslation();

  return teams.length > 0 ? (
    <div className="teams-list-container">
      <h4 className="heading-4">{t('common:catapult.subscription.enabledTeams')}</h4>
      <ul className="margin-4">
        {teams.map((team) => (
          <li className="text" key={team.id}>
            {team.name}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

SubscriptionTeams.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default SubscriptionTeams;
