import React from 'react';

import { withTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import Coupons from './Coupons/Coupons';

import { MainContainer } from 'components/common.styles';

const AdminCoupons = ({ t }) => {
  return (
    <MainContainer>
      <Header as="h1">{t('common:admin.coupons.promotionalCoupons')}</Header>
      <Coupons />
    </MainContainer>
  );
};

export default withTranslation('common')(AdminCoupons);
