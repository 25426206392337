import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Table from '../../../common/Table/Table';
import { Popup } from '../../../common/Popup/Popup';
import { Icon } from '../../../common/Icon/Icon';
import { StyledLabel, StyledWrapper } from '../TeamInvoices.style';

import { getInvoice } from '../../../../services/project';
import { hasAdminPermissions } from '../../../../utils/user.utils';
import { currencyByIdSelector } from '../../../../store/selectors';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { localisePrice } from 'utils/string.utils';
import { Loader } from 'semantic-ui-react';
import { formatAPIDateString } from 'utils/date.utils';

const InvoiceTableRow = ({ invoice }) => {
  const [isLoading, setIsLoading] = useState(false);

  const getCurrencyById = useSelector(currencyByIdSelector);
  const currency = getCurrencyById(invoice.currency_id);
  // take currency conversion rate directly from the invoice since it might have been updated afterwards
  const user = useSelector((state) => state.userStore.user);
  const { t } = useTranslation();

  const downloadInvoice = async (e) => {
    sendUserInteraction('team invoices invoice download');
    e.preventDefault();
    setIsLoading(true);

    try {
      // get file blob
      const response = await getInvoice(invoice.project_id);
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1];
      const decodedFileName = decodeURIComponent(fileName).replace(/"/g, '').replace(/\+/g, ' ');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, decodedFileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const linkTo = hasAdminPermissions(user.role)
    ? `/admin/project/${invoice.project_id}`
    : `/project/${invoice.project_id}`;

  return (
    <Table.Row>
      <Table.Cell>
        <Link onClick={sendUserInteraction('team invoices project link click')} to={linkTo}>
          #{invoice.project_id}
        </Link>
      </Table.Cell>
      <Table.Cell style={{ color: 'black' }}>{invoice.bill_name}</Table.Cell>
      <Table.Cell>
        {invoice.issued_on === null
          ? t('common:admin.invoices.notIssued')
          : formatAPIDateString({ dateString: invoice.issued_on, dateStringFormat: 'yyyy-MM-dd' })}
      </Table.Cell>
      <Table.Cell>
        {formatAPIDateString({ dateString: invoice.payment_due, dateStringFormat: 'yyyy-MM-dd' })}
      </Table.Cell>
      <Table.Cell>
        {invoice?.net_price
          ? localisePrice({ currencyData: { code: currency.code }, number: invoice.net_price })
          : invoice?.net_price}
      </Table.Cell>
      <Table.Cell>
        {invoice?.net_price
          ? localisePrice({
              currencyData: { code: currency.code },
              number: invoice?.vat_rate ? invoice?.price_with_vat : invoice?.net_price,
            })
          : invoice?.vat_rate
          ? invoice?.price_with_vat
          : invoice?.net_price}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <StyledLabel paid={invoice.project_paid}>
          {invoice.project_paid ? 'Paid' : 'Awaiting payment'}
        </StyledLabel>
      </Table.Cell>
      <Table.Cell textAlign="right">
        {invoice.issued_on && (
          <Popup
            trigger={
              <StyledWrapper>
                {isLoading ? (
                  <Loader size="mini" inline active />
                ) : (
                  <Icon name="import" onClick={downloadInvoice} />
                )}
              </StyledWrapper>
            }
            dark
          >
            {t('common:team.invoices.downloadInvoice')}
          </Popup>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const TeamInvoicesTable = ({ invoices, isCompany = false }) => {
  const { t } = useTranslation();

  const rows = invoices.map((invoice) => <InvoiceTableRow key={invoice.id} invoice={invoice} />);
  return (
    <Table width="100%" selectable marginTop="20px">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.issued')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.paymentDue')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.netPrice')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:team.invoices.labels.grossPrice')}</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">{t('common:team.invoices.labels.status')}</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={8} textAlign="center">
              {isCompany ? t('common:company.invoices.noInvoices') : t('common:team.invoices.noInvoices')}
            </Table.Cell>
          </Table.Row>
        ) : (
          rows
        )}
      </Table.Body>
    </Table>
  );
};

export default TeamInvoicesTable;
