import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start
  position: relative;
  margin: 15px 0;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.greyDark};
  text-align: left;
  box-shadow: ${({ theme }) => theme.shadows.soft};
  border-left: 1px solid;
  padding: 15px;
  border-color: ${({ type, color, theme: { colors } }) =>
    color
      ? color
      : type === 'error'
      ? colors.red
      : type === 'warning'
      ? colors.yellow
      : type === 'info'
      ? colors.blue
      : colors.greyDark};

  .main-icon {
    margin-right: 15px;
    path {
      fill: ${({ color, type, theme }) => (color ? color : type ? '' : theme.greyDark)};
    }
    width: ${({ hasHeader }) => (hasHeader ? '37px' : '29px')};
    height: ${({ hasHeader }) => (hasHeader ? '37px' : '29px')};
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    margin: 16px;
    cursor: pointer;
    stroke: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const TextContainer = styled.div`
  align-self: ${({ hasHeader }) => (hasHeader ? 'flex-start' : 'center')};
  overflow: auto;
  flex-grow: 1;
  width: 100%;
  font-family: 'DM Sans', sans-serif !important;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 24 px;
  li {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const MessageHeader = styled.h2`
  margin: 15px 0;
  font-size: 23px;
  line-height: 37px;
  font-weight: normal;
  font-family: 'DM Sans', sans-serif !important;
`;
