import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../common/Button/Button';
import { Icon } from 'components/common/Icon/Icon';
import ServicePrice from './ServicePrice';
import Table from '../../common/Table/Table';
import TmxDropdown from '../JobsTable/TmxDropdown';
import VendorCell from './VendorCell/VendorCell';

import {
  canToggleDTP,
  getJobPrice,
  getJobStatusTranslationKeys,
  getJobTranslationKey,
  getShownServices,
  isJobProofread,
} from 'utils/jobs.utils';
import { formatAPIDateString } from 'utils/date.utils';

import { taiaColors } from 'style/theme';
import { Loader } from 'semantic-ui-react';
import { getVendorData } from 'services/vendors';
import AssignVendorModal from '../JobsTable/AssignVendorModal/AssignVendorModal';
import { VENDOR_TASK_TYPE_TRANSLATION } from 'constants/vendors';
import { VENDOR_TASK_TYPE_REVISION } from 'constants/vendors';

const ServiceRow = ({ job, projectStatusId, showDTPColumn = false, updateJob, currency }) => {
  const {
    delivery_time,
    dtp_selected,
    id,
    job_url,
    revisioner,
    revision_end,
    target_language,
    translation_end,
    translator_id,
    tmx_url,
  } = job;

  const [translator, setTranslator] = useState(null); //translator_id ? findVendor(translator_id) : null;
  const [reviser, setReviser] = useState(null); //revisioner ? findVendor(revisioner) : null;
  const [reviserOpen, setReviserOpen] = useState(false);
  const [translatorOpen, setTranslatorOpen] = useState(false);
  const [revisionEnd, setRevisionEnd] = useState(revision_end);
  const [translationEnd, setTranslationEnd] = useState(translation_end);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchTranslator = async () => {
      try {
        const { data } = await getVendorData(translator_id);
        setTranslator(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchReviser = async () => {
      try {
        const { data } = await getVendorData(revisioner);
        setReviser(data);
      } catch (error) {
        console.error(error);
      }
    };
    if (translator_id) {
      fetchTranslator();
    }

    if (revisioner) {
      fetchReviser();
    }
  }, [translator_id, revisioner]);

  const shownServices = getShownServices(job);
  const jobTasks = shownServices
    .sort((a, b) => b.id - a.id)
    .reduce((acc, service) => {
      return [...acc, ...service.tasks];
    }, []);

  const handleTranslatorSubmit = (vendor, date) => {
    setTranslator(vendor);
    setTranslationEnd(date);
    updateJob({ ...job, translator_id: vendor ? vendor.id : null });
  };

  const handleReviserSubmit = (vendor, date) => {
    setReviser(vendor);
    setRevisionEnd(date);
    updateJob({ ...job, revisioner: vendor ? vendor.id : null });
  };

  const serviceTranslationKey = getJobTranslationKey(job);
  const jobPrice = getJobPrice(job);
  const statusTranslationKeys = getJobStatusTranslationKeys(job);

  return (
    <>
      <AssignVendorModal
        swap={!!translator}
        open={translatorOpen}
        onOpen={() => setTranslatorOpen(true)}
        onClose={() => setTranslatorOpen(false)}
        job={job}
        onSubmit={handleTranslatorSubmit}
        taskType={VENDOR_TASK_TYPE_TRANSLATION}
      />
      <AssignVendorModal
        swap={!!reviser}
        open={reviserOpen}
        onOpen={() => setReviserOpen(true)}
        onClose={() => setReviserOpen(false)}
        job={job}
        onSubmit={handleReviserSubmit}
        taskType={VENDOR_TASK_TYPE_REVISION}
      />
      <Table.Row key={`job-${id}`}>
        <Table.Cell>{target_language ? target_language.code : null}</Table.Cell>
        <Table.Cell>{t(serviceTranslationKey)}</Table.Cell>
        {showDTPColumn ? (
          <Table.Cell>
            {dtp_selected ? (
              <Icon color={taiaColors.blue} inline name="check" />
            ) : (
              <Icon color={taiaColors.red} inline name="times" />
            )}
          </Table.Cell>
        ) : null}
        <Table.Cell>{!!delivery_time ? formatAPIDateString({ dateString: delivery_time }) : null}</Table.Cell>
        <Table.Cell>
          {statusTranslationKeys.map((key, index) => {
            const needsSeparator =
              statusTranslationKeys.length > 1 && index < statusTranslationKeys.length - 1;

            return <span key={key}>{`${t(key)}${needsSeparator ? ', ' : ''}`}</span>;
          })}
        </Table.Cell>
        <Table.Cell>
          {translator ? (
            <VendorCell
              endDate={translationEnd}
              swap={() => {
                setTranslatorOpen(true);
              }}
              vendor={translator}
            />
          ) : translator_id ? (
            <Loader inline active />
          ) : (
            <Button
              actiontype="primary"
              disabled={projectStatusId < 3}
              fluid
              onClick={() => {
                setTranslatorOpen(true);
              }}
            >
              {t('common:admin.project.jobs.assignTranslator')}
            </Button>
          )}
        </Table.Cell>
        {!isJobProofread(job) ? (
          <Table.Cell>
            {reviser ? (
              <VendorCell endDate={revisionEnd} swap={() => setReviserOpen(true)} vendor={reviser} />
            ) : revisioner ? (
              <Loader inline active />
            ) : (
              <Button
                actiontype="primary"
                disabled={projectStatusId < 3}
                fluid
                onClick={() => setReviserOpen(true)}
              >
                {t('common:admin.project.jobs.assignRevisor')}
              </Button>
            )}
          </Table.Cell>
        ) : null}
        <Table.Cell>
          <TmxDropdown jobId={id} tmxUrl={tmx_url} updateJob={updateJob} />
        </Table.Cell>
        <Table.Cell>
          <ServicePrice currency={currency} price={jobPrice} tasks={jobTasks} />
        </Table.Cell>
        {job_url ? (
          <Table.Cell>
            <a href={job_url} rel="noopener noreferrer" target="_blank">
              <Button actiontype="primary">CAT</Button>
            </a>
          </Table.Cell>
        ) : null}
      </Table.Row>
    </>
  );
};

const ServicesTable = ({ currency, jobs, updateJob, projectStatusId }) => {
  const { t } = useTranslation();

  const showDTPColumn = jobs.reduce((acc, job) => {
    if (!!acc) {
      return acc;
    } else if (canToggleDTP(job)) {
      return true;
    } else {
      return false;
    }
  }, false);

  const showRevisionColumn = jobs.reduce((acc, job) => {
    if (!acc) {
      return acc;
    } else if (isJobProofread(job)) {
      return false;
    } else {
      return true;
    }
  }, true);

  return (
    <Table width="100%" marginTop="0">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>{t('common:admin.project.services.service')}</Table.HeaderCell>
          {showDTPColumn && <Table.HeaderCell>{t('common:admin.project.services.DTP')}</Table.HeaderCell>}
          <Table.HeaderCell>{t('common:admin.project.services.deliveryDate')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.project.services.status')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.project.services.translation')}</Table.HeaderCell>
          {showRevisionColumn && (
            <Table.HeaderCell>{t('common:admin.project.services.revision')}</Table.HeaderCell>
          )}
          <Table.HeaderCell>{t('common:admin.project.services.tmx')}</Table.HeaderCell>
          <Table.HeaderCell>{t('common:admin.project.services.price')}</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {jobs.map((job) => (
          <ServiceRow
            job={job}
            currency={currency}
            updateJob={updateJob}
            showDTPColumn={showDTPColumn}
            projectStatusId={projectStatusId}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default ServicesTable;
