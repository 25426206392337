const from = [
  'À',
  'Á',
  'Â',
  'Ã',
  'Ä',
  'Å',
  'Æ',
  'Ç',
  'È',
  'É',
  'Ê',
  'Ë',
  'Ì',
  'Í',
  'Î',
  'Ï',
  'Ð',
  'Ñ',
  'Ò',
  'Ó',
  'Ô',
  'Õ',
  'Ö',
  'Ø',
  'Ù',
  'Ú',
  'Û',
  'Ü',
  'Ý',
  'ß',
  'à',
  'á',
  'â',
  'ã',
  'ä',
  'å',
  'æ',
  'ç',
  'è',
  'é',
  'ê',
  'ë',
  'ì',
  'í',
  'î',
  'ï',
  'ñ',
  'ò',
  'ó',
  'ô',
  'õ',
  'ö',
  'ø',
  'ù',
  'ú',
  'û',
  'ü',
  'ý',
  'ÿ',
  'Ā',
  'ā',
  'Ă',
  'ă',
  'Ą',
  'ą',
  'Ć',
  'ć',
  'Ĉ',
  'ĉ',
  'Ċ',
  'ċ',
  'Č',
  'č',
  'Ď',
  'ď',
  'Đ',
  'đ',
  'Ē',
  'ē',
  'Ĕ',
  'ĕ',
  'Ė',
  'ė',
  'Ę',
  'ę',
  'Ě',
  'ě',
  'Ĝ',
  'ĝ',
  'Ğ',
  'ğ',
  'Ġ',
  'ġ',
  'Ģ',
  'ģ',
  'Ĥ',
  'ĥ',
  'Ħ',
  'ħ',
  'Ĩ',
  'ĩ',
  'Ī',
  'ī',
  'Ĭ',
  'ĭ',
  'Į',
  'į',
  'İ',
  'ı',
  'Ĳ',
  'ĳ',
  'Ĵ',
  'ĵ',
  'Ķ',
  'ķ',
  'Ĺ',
  'ĺ',
  'Ļ',
  'ļ',
  'Ľ',
  'ľ',
  'Ŀ',
  'ŀ',
  'Ł',
  'ł',
  'Ń',
  'ń',
  'Ņ',
  'ņ',
  'Ň',
  'ň',
  'ŉ',
  'Ō',
  'ō',
  'Ŏ',
  'ŏ',
  'Ő',
  'ő',
  'Œ',
  'œ',
  'Ŕ',
  'ŕ',
  'Ŗ',
  'ŗ',
  'Ř',
  'ř',
  'Ś',
  'ś',
  'Ŝ',
  'ŝ',
  'Ş',
  'ş',
  'Š',
  'š',
  'Ţ',
  'ţ',
  'Ť',
  'ť',
  'Ŧ',
  'ŧ',
  'Ũ',
  'ũ',
  'Ū',
  'ū',
  'Ŭ',
  'ŭ',
  'Ů',
  'ů',
  'Ű',
  'ű',
  'Ų',
  'ų',
  'Ŵ',
  'ŵ',
  'Ŷ',
  'ŷ',
  'Ÿ',
  'Ź',
  'ź',
  'Ż',
  'ż',
  'Ž',
  'ž',
  'ſ',
  'ƒ',
  'Ơ',
  'ơ',
  'Ư',
  'ư',
  'Ǎ',
  'ǎ',
  'Ǐ',
  'ǐ',
  'Ǒ',
  'ǒ',
  'Ǔ',
  'ǔ',
  'Ǖ',
  'ǖ',
  'Ǘ',
  'ǘ',
  'Ǚ',
  'ǚ',
  'Ǜ',
  'ǜ',
  'Ǻ',
  'ǻ',
  'Ǽ',
  'ǽ',
  'Ǿ',
  'ǿ',
];
const to = [
  'A',
  'A',
  'A',
  'A',
  'A',
  'A',
  'AE',
  'C',
  'E',
  'E',
  'E',
  'E',
  'I',
  'I',
  'I',
  'I',
  'D',
  'N',
  'O',
  'O',
  'O',
  'O',
  'O',
  'O',
  'U',
  'U',
  'U',
  'U',
  'Y',
  's',
  'a',
  'a',
  'a',
  'a',
  'a',
  'a',
  'ae',
  'c',
  'e',
  'e',
  'e',
  'e',
  'i',
  'i',
  'i',
  'i',
  'n',
  'o',
  'o',
  'o',
  'o',
  'o',
  'o',
  'u',
  'u',
  'u',
  'u',
  'y',
  'y',
  'A',
  'a',
  'A',
  'a',
  'A',
  'a',
  'C',
  'c',
  'C',
  'c',
  'C',
  'c',
  'C',
  'c',
  'D',
  'd',
  'D',
  'd',
  'E',
  'e',
  'E',
  'e',
  'E',
  'e',
  'E',
  'e',
  'E',
  'e',
  'G',
  'g',
  'G',
  'g',
  'G',
  'g',
  'G',
  'g',
  'H',
  'h',
  'H',
  'h',
  'I',
  'i',
  'I',
  'i',
  'I',
  'i',
  'I',
  'i',
  'I',
  'i',
  'IJ',
  'ij',
  'J',
  'j',
  'K',
  'k',
  'L',
  'l',
  'L',
  'l',
  'L',
  'l',
  'L',
  'l',
  'l',
  'l',
  'N',
  'n',
  'N',
  'n',
  'N',
  'n',
  'n',
  'O',
  'o',
  'O',
  'o',
  'O',
  'o',
  'OE',
  'oe',
  'R',
  'r',
  'R',
  'r',
  'R',
  'r',
  'S',
  's',
  'S',
  's',
  'S',
  's',
  'S',
  's',
  'T',
  't',
  'T',
  't',
  'T',
  't',
  'U',
  'u',
  'U',
  'u',
  'U',
  'u',
  'U',
  'u',
  'U',
  'u',
  'U',
  'u',
  'W',
  'w',
  'Y',
  'y',
  'Y',
  'Z',
  'z',
  'Z',
  'z',
  'Z',
  'z',
  's',
  'f',
  'O',
  'o',
  'U',
  'u',
  'A',
  'a',
  'I',
  'i',
  'O',
  'o',
  'U',
  'u',
  'U',
  'u',
  'U',
  'u',
  'U',
  'u',
  'U',
  'u',
  'A',
  'a',
  'AE',
  'ae',
  'O',
  'o',
];

export const MAX_LENGTH = 125;

const truncate = (string, maxLength) => {
  while (encodeURI(string).length > maxLength) {
    string = string.slice(0, -1);
  }
  return string;
};

const handleDuplicates = (newFile, prevFilenames, maxLength) => {
  let idx = 1;
  let newFilename = newFile;

  while (prevFilenames.has(newFilename)) {
    const identifierLength = `(${idx})`.length;
    let temp = newFile;
    // make sure the final filename is not too long
    while (encodeURI(temp).length + identifierLength - maxLength > 0) {
      temp = temp.slice(0, -1);
    }
    newFilename = `${temp}(${idx++})`;
  }
  return newFilename;
};

const removeAccents = (filename) => {
  return [...filename].reduce((acc, nextChar) => {
    from.forEach((char, idx) => {
      if (nextChar === char) nextChar = to[idx];
    });
    return acc + nextChar;
  }, '');
};

export const normalizeFilename = (filename) => {
  let newFilename = removeAccents(filename);
  newFilename = newFilename.replace(/[<>/:*?|\\"]/g, '_');

  // newFilename = newFilename.replaceAll(/[ &\+,\n(\r\n)]/g, '-');

  // newFilename = newFilename.replaceAll(/[^a-z0-9\-<>\.]/g, '');
  // newFilename = newFilename.replaceAll(/<[^>]*>/g, '');
  //   newFilename = newFilename.replaceAll(/[\-]+/g, '-');
  return newFilename;
};

/**
 * @param {string} filename
 * @returns {{name: string, extension: string}}
 */
export const getNameAndExtension = (filename) => {
  const matches = filename.match(/(?<name>.*)\.(?<extension>[0-9a-z]+$)/i);
  return matches ? matches.groups : { name: filename, extension: '' };
};

export const processFilename = (filename, prevFiles = []) => {
  let { name, extension } = getNameAndExtension(filename);
  const messages = [];
  const maxLength = MAX_LENGTH - extension.length - 1;
  const prevFilenames = new Set(prevFiles.map((file) => getNameAndExtension(file.name).name));

  const normalized = normalizeFilename(name);
  if (normalized !== name) {
    messages.push('contains special characters');
  }
  name = normalized;

  const truncated = truncate(name, maxLength);
  if (truncated !== name) {
    messages.push('is too long');
  }
  name = truncated;

  const deDuplicated = handleDuplicates(name, prevFilenames, maxLength);
  if (deDuplicated !== name) {
    messages.push('filename is duplicated');
  }
  name = deDuplicated;

  const newFilename = `${name}.${extension}`;
  if (newFilename === filename) {
    return [newFilename, null];
  }

  const message = `${filename} was renamed to ${newFilename} (${messages.join(', ')})`;
  return [newFilename, message];
};

/*
protected static function _sanitizeName( $nameString ) {

  $nameString = preg_replace( '/[^\p{L}0-9a-zA-Z_\.\-]/u', "_", $nameString );
  $nameString = preg_replace( '/[_]{2,}/', "_", $nameString );
  $nameString = str_replace( '_.', ".", $nameString );

  // project name validation
  $pattern = '/^[\p{L}\ 0-9a-zA-Z_\.\-]+$/u';

  if ( !preg_match( $pattern, $nameString, $rr ) ) {
      return false;
  }

  return $nameString;

}

*/
