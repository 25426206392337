import React from 'react';
import styled from 'styled-components';

const RequiredSignStyle = styled.span`
  color: ${({ theme }) => theme.colors.red};
`;

const RequiredSign = () => {
  return <RequiredSignStyle>*</RequiredSignStyle>;
};

export default RequiredSign;
