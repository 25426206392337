import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';

// Components
import Button from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import FileUploadPreview from '../FileUploadPreview/FileUploadPreview';
import Message from '../../Message/Message';

// Style
import './style.scss';
import { buildFileUploadError } from '../FileUpload.utils';

const SingleFileUpload = ({
  options,
  onChange = () => {},
  selectedFile = null,
  showPreviews = true,
  name = 'single-file-upload',
}) => {
  // Local state
  const [btnText, setBtnText] = useState('default');
  const [file, setFile] = useState(selectedFile);
  const [rejectedError, setRejectedError] = useState(null);

  // Dropzone hook
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    ...options,
    maxFiles: 1,
    onDropAccepted: (files) => {
      onChange(null, { name, value: files[0] });
      setFile(files[0]);
    },
    disabled: !!file,
  });

  // Methods
  const removeFile = () => {
    onChange(null, { name, value: null });
    setFile(null);
  };

  const updateFile = (file) => {
    setFile(file);
  };

  const preventDragDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e) => {
    preventDragDefaults(e);

    setBtnText('dragenter');
  };

  const onDragLeave = (e) => {
    preventDragDefaults(e);

    setBtnText(file ? 'file' : 'default');
  };

  // Effects
  useEffect(() => {
    setRejectedError(fileRejections.length > 0 ? buildFileUploadError(fileRejections) : null);
  }, [fileRejections]);

  useEffect(() => {
    setBtnText(file ? 'file' : 'default');
  }, [file]);

  // Select file prop change, sync local state
  useEffect(() => {
    setFile(selectedFile);
  }, [selectedFile]);

  // Render variables
  const fileUploadButtonTexts = {
    default: 'Upload file',
    dragenter: 'Drop here',
    file: '1 file selected',
  };

  const uploadButtonProps = {
    as: motion.button,
    layout: true,
    onDragEnter,
    onDragLeave,
    labelPosition: 'right',
    disabled: !!file,
  };

  const presenceProps = {
    initial: {
      height: 0,
      opacity: 0,
    },
    animate: {
      height: 'auto',
      opacity: 1,
    },
    exit: {
      height: 0,
      opacity: 0,
    },
    transition: {
      duration: 0.3,
    },
  };

  return (
    <div className="single-file-upload">
      <div {...getRootProps({ className: 'upload' })}>
        <input {...getInputProps()} />
        <AnimateSharedLayout>
          <Button {...uploadButtonProps}>
            <span>{fileUploadButtonTexts[btnText]}</span>
            <Icon name="upload" />
          </Button>
        </AnimateSharedLayout>
      </div>
      <div className="msg-wrapper">
        <AnimatePresence exitBeforeEnter>
          {showPreviews && !!file && (
            <motion.div key="file-display" {...presenceProps}>
              <FileUploadPreview file={file} onRemove={removeFile} onChange={updateFile} />
            </motion.div>
          )}

          {!!rejectedError && (
            <motion.div key="message-display" {...presenceProps}>
              <Message className="error-message" text={rejectedError} type="error" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SingleFileUpload;
