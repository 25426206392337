import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { postVendor } from 'services/vendors';

const AddVendorContext = createContext(null);

export const AddVendorProvider = ({ steps = [], children = <></> }) => {
  // Current step based on URL querry param
  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);
  const { t } = useTranslation();

  const checkRange = (step) => (step < 0 ? 0 : step > steps.length - 1 ? steps.length - 1 : step);
  const activeStep = query.get('step') ? checkRange(Number(query.get('step'))) : 0;

  // Active step component
  const activeStepComponent = useMemo(() => {
    if (steps.length === 0) {
      return <></>;
    }
    return steps[activeStep].component;
  }, [steps, activeStep]);

  // Vendor data handling
  const initialVendorData = steps.reduce((acc, step) => {
    return (acc = { ...acc, [step.name]: step.initialData });
  }, {});

  const [vendorData, setVendorData] = useState(initialVendorData);
  const [completedSteps, setCompletedSteps] = useState([]);

  const isStepValid = (step) => {
    const data = vendorData[step.name];

    if (Array.isArray(data)) {
      //Language pairs validation
      return data.reduce((acc, entry) => {
        return (
          acc &&
          step.requiredFields.reduce((acc, requiredField) => {
            const field = entry[requiredField];
            if (Array.isArray(field)) {
              return acc && field.length > 0;
            }

            if (typeof field === 'number') {
              return acc && field > 0;
            }

            if (typeof field === 'string') {
              return acc && field?.length > 0;
            }
            return acc && field !== null && field !== undefined;
          }, true)
        );
      }, true);
    } else {
      return step.requiredFields.reduce((acc, requiredField) => {
        const field = data[requiredField];

        if (Array.isArray(field)) {
          return acc && field.length > 0;
        }

        if (typeof field === 'number') {
          return acc && field > 0;
        }
        if (typeof field === 'string') {
          return acc && field?.length > 0;
        }
        return acc && field !== null && field !== undefined;
      }, true);
    }
  };

  useEffect(() => {
    const validSteps = steps.reduce((acc, step, idx) => {
      if (isStepValid(step)) {
        return [...acc, idx];
      }
      return acc;
    }, []);
    setCompletedSteps(validSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData]);

  const stepsValid = steps.length === completedSteps.length;

  const useVendorData = useCallback(
    (name) => {
      return [
        vendorData[name],
        (data) => {
          const newVendorData = { ...vendorData, [name]: data };
          setVendorData(newVendorData);
        },
      ];
    },
    [vendorData],
  );

  const [isSubmiting, setIsSubmiting] = useState(false);

  const submitVendor = async (callback = () => {}) => {
    setIsSubmiting(true);

    try {
      let data = {};
      for (const stepName in vendorData) {
        const stepData = Array.isArray(vendorData[stepName])
          ? { [stepName]: [...vendorData[stepName]] }
          : { ...vendorData[stepName] };
        data = { ...data, ...stepData };
      }

      await postVendor(data);
      await callback();
      // Move steps to finished step
    } catch (error) {
      console.error(error);
      toast.error(t('common:serviceProvider.onboarding.errors.submitVendorData'));
    } finally {
      setIsSubmiting(false);
    }
  };

  const value = {
    steps,
    activeStep,
    activeStepComponent,
    completedSteps,
    vendorData,
    stepsValid,
    useVendorData,
    submitVendor,
    isSubmiting,
  };
  return <AddVendorContext.Provider value={value}>{children}</AddVendorContext.Provider>;
};

export const useAddVendorContext = () => {
  return useContext(AddVendorContext);
};
