import React from 'react';
import { Header, Label, List } from 'semantic-ui-react';
const { withTranslation } = require('react-i18next');

const WordDetails = ({ project, t }) => {
  return (
    <>
      <Header>{t('common:admin.project.words.word')}</Header>
      <List>
        <List.Item>
          <Label color="blue" horizontal>
            {t('common:admin.project.words.totalWords')}:
          </Label>
          {project.total_words}
        </List.Item>
        <List.Item>
          <Label color="blue" horizontal>
            {t('common:admin.project.words.payableWords')}:
          </Label>
          {project.payable_words}
        </List.Item>
        {project.word_manips && (
          <List.Item>
            <Label color="blue" horizontal>
              {t('common:admin.project.words.chagedFrom')}:
            </Label>
            {project.word_manips.project_payable}
          </List.Item>
        )}
      </List>
    </>
  );
};

export default withTranslation('common')(WordDetails);
