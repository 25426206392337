import styled from 'styled-components';
import Button from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';

export const Container = styled.div`
  background-color: #fff;
  padding: 30px 47px;
  @media only screen and (max-width: 600px) {
    padding: 30px 35px;
  }
`;

export const Segment = styled.div`
  display: grid;
  grid-template-columns: 275px 1fr;

  .assign-person {
    .trigger {
      .user-icon {
        height: 4rem;
        width: 4rem;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
`;

export const Divider = styled.hr`
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  border: 0;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const ColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content;
  grid-row-gap: calc(1rem + 7px);
`;

export const Description = styled.p`
  /* color: ${({ theme }) => theme.colors.grey}; */
  color: #999999;
  margin-right: 65px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const StyledInput = styled(Input)`
  width: clamp(300px, 85%, 400px);
  @media only screen and (max-width: 1000px) {
    margin-top: 15px;
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 20px !important;
  @media only screen and (max-width: 1000px) {
    margin: 20px 0 0 !important;
  }
`;
