import httpClient from './httpclient';

export const postCreateCompany = async ({ company_name, comp_discount }) => {
  try {
    const { data } = await httpClient.post(`/auth/company`, {
      company_name: company_name,
      comp_discount: comp_discount,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanies = async ({
  page,
  per_page = 10,
  search,
  sort_by = 'created_at',
  sort_order = 'DESC',
  pm_id = [],
  kam_id = [],
}) => {
  try {
    const { data } = await httpClient.get(`/auth/admin/companies`, {
      params: {
        page,
        per_page,
        search,
        sort_by,
        sort_order,
        pm_id,
        kam_id,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompany = async (companyId) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${companyId}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postUpdateCompany = async ({ discount, user_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/company/update`, { discount, user_id });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postCompanyUpdateById = async ({ companyId, company_name, comp_discount }) => {
  try {
    const { data } = await httpClient.post(`/auth/company/${companyId}/update`, {
      company_name: company_name,
      comp_discount: comp_discount,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const postMakeKnownCompany = async ({ comp_discount, emailAddr, phoneNumber, user_id }) => {
  try {
    const { data } = await httpClient.post(`/auth/admin/makeknowncompany`, {
      comp_discount,
      emailAddr,
      phoneNumber,
      user_id,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyOwners = async ({ id }) => {
  try {
    const { data } = await httpClient.get(`auth/company/${id}/owners`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const postCompanyOwner = async ({ id, email }) => {
  try {
    const { data } = await httpClient.post(`/auth/company/${id}/owners`, { email });
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteCompanyOwner = async ({ id, userId }) => {
  try {
    const { data } = await httpClient.post(`/company/${id}/owners/${userId}/delete`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyInvitations = async (companyId) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${companyId}/pending-invitations`);
    return data;
  } catch (e) {
    throw e;
  }
};

// Endpoint for deleting both company and team invitations
export const revokeInvitation = async (invitationId) => {
  try {
    const { data } = await httpClient.post(`/auth/team-invitation/${invitationId}/delete`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyTeams = async (companyId) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${companyId}/teams`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyMembers = async ({ page, id }) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${id}/members`, {
      params: {
        page: page,
        per_page: 14,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyProjects = async ({
  company_id,
  page,
  per_page,
  search,
  status_id,
  sort_by,
  sort_order,
  team_id,
}) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${company_id}/projects`, {
      params: {
        page,
        per_page,
        search,
        status_id,
        sort_by,
        sort_order,
        team_id,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyTranslationMemories = async ({ id, category_id }) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${id}/translation-memories`, {
      params: {
        category_id,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyTmSettings = async (id) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${id}/tm-setting`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const getCompanyInvoices = async ({
  company_id,
  page,
  per_page = 14,
  project_paid,
  issued_on_from,
  issued_on_to,
  payment_due_from,
  payment_due_to,
}) => {
  try {
    const { data } = await httpClient.get(`/auth/company/${company_id}/invoices`, {
      params: {
        page,
        per_page,
        project_paid,
        issued_on_from,
        issued_on_to,
        payment_due_from,
        payment_due_to,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const assignPmToCompany = async ({ company_id, pm_id }) => {
  try {
    const { data } = await httpClient.patch(`auth/company/${company_id}/project-manager`, {
      pm_id,
    });

    return data;
  } catch (e) {
    throw e;
  }
};

export const assignKAMToCompany = async ({ company_id, kam_id }) => {
  try {
    const { data } = await httpClient.patch(`auth/company/${company_id}/key-account-manager`, {
      kam_id,
    });

    return data;
  } catch (e) {
    throw e;
  }
};
