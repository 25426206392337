import { SelectDropdown } from 'components/common/Dropdown/SelectDropdown';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
const TeamBillingDropdown = ({ disabled = false, user, onChange, teamId }) => {
  const { t } = useTranslation();
  const teamOptions = useMemo(
    () =>
      user.teams.map((team) => {
        return {
          key: team.id,
          text: team.name,
          value: team.id,
        };
      }),
    [user],
  );

  const handleCurrencyChange = (e, { value }) => {
    onChange(value);
  };

  // if we only have a single team there is nothing to select
  return teamOptions.length > 1 ? (
    <SelectDropdown
      label={t('common:checkout.billing.form.teamBilling')}
      name="billing_detail_id"
      disabled={disabled}
      selection
      onChange={handleCurrencyChange}
      options={teamOptions}
      value={teamId}
    />
  ) : null;
};

export default TeamBillingDropdown;
