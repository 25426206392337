import { useState, useEffect } from 'react';

/**
 * @param {string} mediaQueryString a css media query
 * @returns {boolean} does media query match
 */
export const useMediaQuery = (mediaQueryString) => {
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia(mediaQueryString);
    setMatches(mediaQuery.matches);

    const handleChange = (event) => {
      setMatches(event.matches);
    };

    if (!mediaQuery.addEventListener) {
      mediaQuery.addListener(handleChange);
      return () => mediaQuery.removeListener(handleChange);
    } else {
      mediaQuery.addEventListener('change', handleChange);
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, [mediaQueryString]);

  return matches;
};
