import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Divider } from 'semantic-ui-react';
import { SelectDropdown } from '../../../Dropdown/SelectDropdown';
import { Flag } from '../../../Flag/Flag';
import SectionDescription from '../../../SectionDescription/SectionDescription';
import TextArea from 'components/common/TextArea/TextArea';

import { useTranslation } from 'react-i18next';

// Styles
import './style.scss';

const defaultInitialFormData = {
  description: '',
  native_lang_id: '',
  timezone: '',
  country_id: '',
};

const VendorSettingsForm = ({ onChange = () => {}, initialFormData = defaultInitialFormData }) => {
  const [formData, setFormData] = useState(initialFormData);
  const { languages, countries, timezones } = useSelector((state) => state.classifiersStore);

  // Hooks
  const { t } = useTranslation();

  const handleInputChange = (_, { value, name }) => {
    setFormData((c) => ({
      ...c,
      [name]: value,
    }));
  };

  // On form data change, fire the onChange function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(null, formData), [formData]);

  const nativeLanguagesOptions = useMemo(
    () =>
      languages.map((lang) => ({
        key: lang.id,
        value: lang.id,
        text: lang.name,
        icon: <Flag lang={{ code: lang.code }} />,
      })),
    [languages],
  );

  const timezoneOptions = useMemo(
    () =>
      timezones.map((value, idx) => ({
        key: `${value}-${idx}`,
        value,
        text: value,
      })),
    [timezones],
  );

  const countryOptions = useMemo(
    () =>
      countries.map(({ created_at, name, iso_code, id }) => ({
        key: created_at,
        value: id,
        text: name,
        icon: <Flag lang={{ code: iso_code }} />,
      })),
    [countries],
  );

  return (
    <div className="vendor-settings-form">
      <SectionDescription
        title={t('common:serviceProvider.forms.inputs.description.title')}
        description={t('common:serviceProvider.forms.inputs.description.description')}
        content={
          <TextArea
            name="description"
            onChange={handleInputChange}
            value={formData.description}
            placeholder="Description"
          />
        }
      />

      <Divider />

      <SectionDescription
        title={`${t('common:serviceProvider.forms.inputs.nativeLanguage.title')} *`}
        description={t('common:serviceProvider.forms.inputs.nativeLanguage.description')}
        content={
          <SelectDropdown
            name="native_lang_id"
            value={formData.native_lang_id}
            onChange={handleInputChange}
            placeholder="Select a language"
            options={nativeLanguagesOptions}
            selection
            search
            selectedShowIcon
            emptyState="Not found"
            fluid
            canReset={false}
          />
        }
      />

      <Divider />

      <SectionDescription
        title={t('common:serviceProvider.forms.inputs.timezone.title')}
        description={t('common:serviceProvider.forms.inputs.timezone.description')}
        content={
          <SelectDropdown
            name="timezone"
            value={formData.timezone}
            onChange={handleInputChange}
            placeholder="Select timezone"
            options={timezoneOptions}
            selection
            search
            emptyState="Not found"
            fluid
          />
        }
      />

      <Divider />

      <SectionDescription
        title={`${t('common:serviceProvider.forms.inputs.country.title')} *`}
        description={t('common:serviceProvider.forms.inputs.country.description')}
        content={
          <SelectDropdown
            name="country_id"
            value={formData.country_id}
            onChange={handleInputChange}
            placeholder="Select a country"
            options={countryOptions}
            selection
            search
            emptyState="Not found"
            fluid
            selectedShowIcon
            canReset={false}
          />
        }
      />

      <Divider />
    </div>
  );
};

export default VendorSettingsForm;
