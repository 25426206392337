import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Tab } from 'semantic-ui-react';
import AnalyzedProjects from './AnalyzedProjects/AnalyzedProjects';
import AttemptedProjects from './AttemptedProjects/AttemptedProjects';
import DIYProjects from './DIYProjects/DIYProjects';

// Styles
import './style.scss';

const AdminProjects = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);
  const [activeTab, setActiveTab] = useState(query.get('tab') ?? 0);
  const panes = useMemo(
    () => [
      {
        match: 'analyzed',
        menuItem: t('common:admin.projects.tabs.analyzedProjects'),
        render: () => (
          <Tab.Pane as="main">
            <AnalyzedProjects />
          </Tab.Pane>
        ),
      },
      {
        match: 'attempted',
        menuItem: t('common:admin.projects.tabs.attemptedProjects'),
        render: () => (
          <Tab.Pane as="main">
            <AttemptedProjects />
          </Tab.Pane>
        ),
      },
      {
        match: 'diy',
        menuItem: t('common:admin.projects.tabs.diyProjects'),
        render: () => (
          <Tab.Pane as="main">
            <DIYProjects />
          </Tab.Pane>
        ),
      },
    ],
    [t],
  );

  useEffect(() => {
    const activePane = panes.findIndex((pane) => pane.match === query.get('tab'));
    setActiveTab(activePane === -1 ? 0 : activePane);
  }, [query, panes]);

  const handleTabChange = (e, { activeIndex }) => {
    e.preventDefault();
    setActiveTab(activeIndex);
  };

  return (
    <main className="admin-projects">
      <Tab
        activeIndex={activeTab}
        menu={{
          secondary: true,
          pointing: true,
        }}
        panes={panes}
        onTabChange={handleTabChange}
      />
    </main>
  );
};

export default AdminProjects;
