import Button from 'components/common/Button/Button';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const OnboardingContainer = styled.div`
  display: grid;
  justify-items: center;
  padding-bottom: 3rem; // scss spacing-sizes 1, TODO when refactoring to scss use spacing-sizes variable
`;

export const TitleImage = styled.img`
  margin-top: 10%;
  margin-bottom: 30px;
`;

export const OnboardingTitle = styled.h1`
  font-size: 52px;
  line-height: 52px;
`;

export const OnboardingSubTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 38px;
`;

export const OnboardingUseCases = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const UseCaseContent = styled.div`
  display: grid;
  margin-top: -110px;
  height: 550px;
  justify-items: center;

  .use-case-image {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .use-case-title {
    text-align: center;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 17px;
  }

  .use-case-text {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
  }

  .select-button {
    margin-bottom: 20px;
    align-self: flex-end;
  }
`;

export const CustomerTypes = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  margin-bottom: 22px;
`;

export const CustomerType = styled.div`
  height: 118px;
  width: 118px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey};
  border-radius: 4px;
  cursor: pointer;

  display: grid;
  place-items: center;

  &.selected {
    border: 1px solid ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    .icon {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const TypeDescription = styled.div`
  font-size: 16px;

  max-width: 550px;
  margin-bottom: 32px;
`;

export const TypeForm = styled(Form)`
  max-width: 550px !important;
  width: 100%;
  margin-bottom: 32px;
  display: grid;
`;

export const CustemerTypeSubmitButton = styled(Button)`
  justify-self: flex-start;
`;

export const SectionHeader = styled.div`
  margin-top: 20px;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 22px;
`;

export const VendorSection = styled(Form)`
  max-width: 700px !important;
  width: 100%;
  display: grid;
`;

export const VendorRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const VendorSelectButton = styled(Button)`
  margin-top: 22px !important;
`;

export const AddCombination = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`;
