import React, { useEffect, useState } from 'react';
import { getCompanies } from '../../services/company';
import { useDebounce } from '../../hoc/debouncer';
import { Toolbar } from '../AdminCoupons/Coupons/Coupons.style';
import { StyledPagination } from '../common.styles';
import LoadingPane from '../common/LoadingPane/LoadingPane';
import { AdminCompaniesLayout, StyledInput } from './AdminCompanies.style';
import CompaniesTable from './CompaniesTable/CompaniesTable';
import NewCompanyModal from './NewCompanyModal/NewCompanyModal';
import { Icon } from '../common/Icon/Icon';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import FilterSidebar from 'components/common/FilterSidebar/FilterSidebar';
import { MultipleDropdown } from 'components/common/Dropdown/MultipleDropdown';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button/Button';
import UserAvatar from 'components/common/UserAvatar/UserAvatar';
import { useSelector } from 'react-redux';
import Message from 'components/common/Message/Message';

// Styles
import './style.scss';

const AdminCompanies = () => {
  const [, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useLocalStorage('admin-companies-page', 1);
  const [numOfPages, setNumberOfPages] = useState(1);
  const [search, setSearch] = useLocalStorage('admin-companies-search', '');
  const [pmFilter, setPmFilter] = useLocalStorage('admin-companies-pm-filter', []);
  const [kamFilter, setKamFilter] = useLocalStorage('admin-companies-kam-filter', []);

  const adminUsers = useSelector((state) => state.userStore.adminUsers);

  const { debounce } = useDebounce();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCompanies = async (page, search) => {
      debounce(
        'adminCompanies',
        async () => {
          try {
            setIsLoading(true);
            const data = await getCompanies({
              page,
              search,
              per_page: 12,
              pm_id: pmFilter,
              kam_id: kamFilter,
            });

            setCompanies(data.data);

            if (currentPage > data.last_page) {
              setCurrentPage(data.last_page);
            }

            setNumberOfPages(data.last_page);
          } catch (e) {
            setIsError(e);

            console.error(e);
          } finally {
            setIsLoading(false);
          }
        },
        500,
      );
    };

    fetchCompanies(currentPage, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search, pmFilter, kamFilter, debounce]);

  const fetchCompanies = async (page, search) => {
    debounce(
      'adminCompanies',
      async () => {
        try {
          setIsLoading(true);
          const data = await getCompanies({
            page,
            search,
            per_page: 12,
            pm_id: pmFilter,
          });
          const companies = data.data.map((company) => {
            return {
              ...company,
              editing: false,
              isSubmitting: false,
            };
          });

          setCompanies(companies);
          if (currentPage > data.last_page) {
            setCurrentPage(data.last_page);
          }
          setNumberOfPages(data.last_page);
        } catch (e) {
          setIsError(e);

          console.error(e);
        } finally {
          setIsLoading(false);
        }
      },
      500,
    );
  };

  const handleNewCompanySubmit = () => {
    sendUserInteraction('submit new company create');
    fetchCompanies(1, search);
  };

  const handleSearch = (e) => {
    sendUserInteraction('admin companies search change');
    setSearch(e.target.value);
  };

  const handlePaginationChange = (_, { activePage }) => {
    sendUserInteraction('admin companies page change');
    setCurrentPage(activePage);
  };

  const handlePMFilterSelect = (_, { value }) => {
    setPmFilter(value);
  };

  const handleKAMFilterSelect = (_, { value }) => {
    setKamFilter(value);
  };

  const resetFilters = () => {
    setSearch('');
    setPmFilter([]);
    setKamFilter([]);
  };

  const adminUsersOptions = adminUsers.map((adminUser) => ({
    icon: <UserAvatar className="icon" src={adminUser.avatar} />,
    text: adminUser.name,
    value: adminUser.id,
  }));

  const pmOptions = [
    {
      icon: <UserAvatar className="icon" />,
      text: t('common:admin.companies.filtersSidebar.pmDropdown.noPmOption'),
      value: null,
    },
    ...adminUsersOptions,
  ];

  const kamOptions = [
    {
      icon: <UserAvatar className="icon" />,
      text: t('common:admin.companies.filtersSidebar.kamDropdown.noKamOption'),
      value: null,
    },
    ...adminUsersOptions,
  ];

  const filterTrigger = (props) => (
    <Button {...props} icon="filter" color="grey" basic style={{ height: '100%', width: '40px' }} />
  );

  return (
    <AdminCompaniesLayout className="admin-companies-layout">
      <Toolbar>
        {numOfPages > 1 && (
          <StyledPagination
            activePage={currentPage}
            totalPages={numOfPages}
            onPageChange={handlePaginationChange}
          />
        )}
        <NewCompanyModal onSubmit={handleNewCompanySubmit} />
        <FilterSidebar className="filters-sidebar" trigger={filterTrigger}>
          <h3>{t('common:admin.companies.filtersSidebar.title')}:</h3>
          <div className="sidebar-field-group">
            <p>{t('common:admin.companies.filtersSidebar.search.title')}:</p>
            <StyledInput
              icon={<Icon name="search" inElement />}
              placeholder={t('common:admin.companies.filtersSidebar.search.placeholder')}
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className="sidebar-field-group">
            <p>{t('common:admin.companies.filtersSidebar.pmDropdown.title')}:</p>
            <MultipleDropdown
              value={pmFilter}
              className="person-filter-dropdown"
              options={pmOptions}
              onChange={handlePMFilterSelect}
              placeholder={t('common:admin.companies.filtersSidebar.pmDropdown.placeholder')}
              search
            />
          </div>
          <div className="sidebar-field-group">
            <p>{t('common:admin.companies.filtersSidebar.kamDropdown.title')}:</p>
            <MultipleDropdown
              value={kamFilter}
              className="person-filter-dropdown"
              options={kamOptions}
              onChange={handleKAMFilterSelect}
              placeholder={t('common:admin.companies.filtersSidebar.kamDropdown.placeholder')}
              search
            />
          </div>
          <div className="sidebar-field">
            <Button loading={isLoading} onClick={resetFilters}>
              {t('common:admin.companies.filtersSidebar.resetFilters')}
            </Button>
          </div>
        </FilterSidebar>
      </Toolbar>
      <div className="table-wrapper">
        {isLoading && <LoadingPane height="100%" />}
        <CompaniesTable companies={companies} />
        {companies.length === 0 && (
          <Message text={t('common:admin.companies.noCompaniesFound')} type="info" />
        )}
      </div>
    </AdminCompaniesLayout>
  );
};

export default AdminCompanies;
