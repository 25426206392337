import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Icon } from 'components/common/Icon/Icon';
import Button from 'components/common/Button/Button';
import { Modal } from 'semantic-ui-react';
import { Checkbox } from '../Checkbox/Checkbox';

import {
  userStartedVendorOnboarding,
  vendorOnboardingStartedLocalStorageKey,
  validateStepsInLocalStorage,
} from 'components/ServiceProvider/VendorOnboarding/VendorOnboarding.utils';

// Styles
import './style.scss';

const BecomeVendorBanner = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const openModal = () => {
    // If user has started onboarding already, redirect to vendor onboarding
    if (userStartedVendorOnboarding()) {
      // Check valid steps to build a redirect link
      const validSteps = validateStepsInLocalStorage();

      if (validSteps.length === 0) {
        // Redirect to first step
        history.push('/vendor-onboarding');
      } else {
        const nextStep = validSteps[validSteps.length - 1] + 1;

        // Redirect to next step after last valid step
        history.push(`/vendor-onboarding?step=${nextStep + 1}`);
      }
    } else {
      // Show the terms modal
      setModalOpen(true);
    }
  };
  const closeModal = () => setModalOpen(false);
  const handleCheckbox = () => setTermsOfUse((c) => !c);
  const redirectToOnboarding = () => {
    localStorage.setItem(vendorOnboardingStartedLocalStorageKey, 'true');
    history.push('/vendor-onboarding');
  };

  return (
    <div className="become-vendor">
      <h2>{t('common:projects.firstProject.provideService')}</h2>
      <p>{t('common:projects.firstProject.provideServiceDescription')}</p>
      <Button onClick={openModal} labelPosition="left" color="green" big basic>
        <Icon name="english-to-chinese" />
        {t('common:projects.firstProject.becomeVendor')}
      </Button>
      <Modal open={modalOpen} onClose={closeModal} className="become-vendor-modal-confirm">
        <Modal.Content>
          <h2>{t('common:becomeVendorBanner.modal.title')}</h2>
          <p>{t('common:becomeVendorBanner.modal.description')}</p>
          <div className="terms-and-conditions">
            <Checkbox
              checked={termsOfUse}
              onChange={handleCheckbox}
              label={
                <p>
                  {t('common:becomeVendorBanner.modal.terms.haveRead')}{' '}
                  <a href="https://taia.io/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                    {t('common:becomeVendorBanner.modal.terms.link')}
                  </a>
                </p>
              }
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} actiontype="cancel">
            {t('common:cancel')}
          </Button>
          <Button onClick={redirectToOnboarding} actiontype="primary" disabled={!termsOfUse}>
            {t('common:confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default BecomeVendorBanner;
