import Table from 'components/common/Table/Table';
import React from 'react';
import { getVendorExperience } from 'utils/vendors.utils';
import { getVendorRiskText } from 'utils/vendors.utils';
import { getVendorStatusText } from 'utils/vendors.utils';
import LinkTableCell from 'components/common/Table/LinkTableCell';
import { Loader, Popup } from 'semantic-ui-react';
import { getVendorLanguagePairs, getVendorLanguages } from './VendorTable.utils';
import { useTranslation } from 'react-i18next';
import { Flag } from 'components/common/Flag/Flag';
import { LanguageLabel } from 'components/common/ProjectLanguages/ProjectLanguages';
import './style.scss';
import { Icon } from 'components/common/Icon/Icon';
import { SortHeaderCell } from 'components/common.styles';
import { useFiltersContext } from 'providers/FiltersProvider';

const VendorTableRow = ({ vendor = {} }) => {
  const vendorLanguages = getVendorLanguages(vendor);
  const languagePairs = getVendorLanguagePairs(vendor);
  const { t } = useTranslation();

  const langaugeLabels = vendorLanguages.map((lang) => (
    <LanguageLabel key={lang.id}>
      <Flag lang={lang} />
      <span className="language-text">{t('languages:' + lang.code)}</span>
    </LanguageLabel>
  ));

  const pairs = languagePairs.map((pair) => {
    return (
      <div
        className="lang-pair"
        key={`${pair.sourceLanguage.id}-${pair.targetLanguage?.id ? pair.targetLanguage?.id : 'proofreding'}`}
      >
        <Flag lang={pair.sourceLanguage} style={{ marginRight: '5px' }} />
        <b>{pair.sourceLanguage?.code}</b>
        {pair.targetLanguage ? (
          <>
            <Icon name="arrow-right" inline></Icon>
            <Flag lang={pair.targetLanguage} style={{ marginRight: '5px' }} />
            <b>{pair.targetLanguage?.code}</b>
          </>
        ) : null}
        <b>({t('tasks:' + pair.task_type_id)})</b>
      </div>
    );
  });

  const vendorURL = `/admin/vendor/${vendor.id}`;

  return (
    <Table.Row>
      <LinkTableCell unstyled to={vendorURL}>
        {vendor.name}
      </LinkTableCell>
      <LinkTableCell unstyled to={vendorURL}>
        {vendor.email}
      </LinkTableCell>
      <LinkTableCell unstyled to={vendorURL}>
        {getVendorStatusText(vendor.status)}
      </LinkTableCell>
      <LinkTableCell unstyled to={vendorURL}>
        {getVendorRiskText(vendor.risk)}
      </LinkTableCell>
      <LinkTableCell unstyled to={vendorURL}>
        {getVendorExperience(vendor.experience)}
      </LinkTableCell>
      <LinkTableCell unstyled to={vendorURL}>
        <Popup
          content={<>{pairs}</>}
          trigger={<div className="vendor-langs">{langaugeLabels}</div>}
          position="bottom center"
          pinned
          mouseEnterDelay={500}
          mouseLeaveDelay={200}
        />
      </LinkTableCell>
    </Table.Row>
  );
};

const VendorTable = ({ vendors = [], loading = false }) => {
  const { t } = useTranslation();
  const rows = vendors.map((vendor) => <VendorTableRow key={vendor.id} vendor={vendor} />);

  const { toggleSort, getSortIcon } = useFiltersContext();

  return (
    <Table className="vendor-table" width="100%" selectable>
      <Table.Header>
        <Table.Row>
          <SortHeaderCell onClick={() => toggleSort('name')}>
            {t('common:admin.vendors.tableHeader.name', 'Name')}
            <Icon name={getSortIcon('name')} />
          </SortHeaderCell>
          <SortHeaderCell onClick={() => toggleSort('email')}>
            {t('common:admin.vendors.tableHeader.name', 'Email')}
            <Icon name={getSortIcon('email')} />
          </SortHeaderCell>
          <SortHeaderCell onClick={() => toggleSort('status')}>
            {t('common:admin.vendors.tableHeader.status', 'Status')}
            <Icon name={getSortIcon('status')} />
          </SortHeaderCell>
          <SortHeaderCell onClick={() => toggleSort('risk')}>
            {t('common:admin.vendors.tableHeader.risk', 'Risk')}
            <Icon name={getSortIcon('risk')} />
          </SortHeaderCell>
          <SortHeaderCell onClick={() => toggleSort('experience')}>
            {t('common:admin.vendors.tableHeader.experience', 'Experience')}
            <Icon name={getSortIcon('experience')} />
          </SortHeaderCell>
          <Table.HeaderCell>{t('common:admin.vendors.tableHeader.name', 'Languages')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell colspan="6" textAlign="center">
              <Loader active inline size="tiny"></Loader>
            </Table.Cell>
          </Table.Row>
        ) : vendors.length > 0 ? (
          rows
        ) : (
          <Table.Row>
            <Table.Cell colspan="6" textAlign="center">
              No vendors found
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default VendorTable;
