import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  maintenanceError: false,
};

const errorsSlice = createSlice({
  name: 'errorsSlice',
  initialState: { ...initialState },
  reducers: {
    setMaintenanceError(state, action) {
      const newMaintenanceError = action.payload;
      state.maintenanceError = newMaintenanceError;
    },
  },
});

export const { setMaintenanceError } = errorsSlice.actions;
export default errorsSlice.reducer;
