import styled from 'styled-components';

export const ListBullet = styled.img`
  margin-left: 28px;
  margin-bottom: 7px;
  margin-right: 7px;
`;

export const TaskName = styled.div`
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 7px;
`;

export const TaskPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #666;
  padding-right: 34px;
  margin-top: 7px;
`;
