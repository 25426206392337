import React from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '../../../../common/Accordion/Accordion';
import JobRow from './JobRow';

import { JobPrice, JobTitleContainer } from './SelectedServices.styles';

import { getJobPrice, getJobTranslationKey } from '../../../../../utils/jobs.utils';
import { localiseAndConvertPrice } from '../../../../../utils/string.utils';

const SelectedServices = ({ currency, jobs }) => {
  const { i18n, t } = useTranslation();
  return (
    <>
      {jobs.map((job) => {
        const { id, target_language } = job;
        const jobPrice = localiseAndConvertPrice({
          currencyData: currency,
          language: i18n.language,
          number: getJobPrice(job),
        });

        return (
          <Accordion
            key={`job-${id}-accordion`}
            title={
              <JobTitleContainer>
                <span>
                  {target_language.name} ({t(getJobTranslationKey(job))})
                </span>
                <JobPrice>{jobPrice}</JobPrice>
              </JobTitleContainer>
            }
          >
            <JobRow currency={currency} job={job} price={jobPrice} />
          </Accordion>
        );
      })}
    </>
  );
};

export default SelectedServices;
