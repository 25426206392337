import React from 'react';

// Components
import ProgressBarsStacked from 'components/common/ProgressBar/ProgressBarStacked/ProgressBarsStacked';

const JobProgress = ({ job }) => {
  // Get values for translated words and approved words
  const totalValue = {
    label: 'Total words',
    amount: 100,
    color: 'grey',
  };

  const otherValues = [
    {
      label: 'Approved words',
      amount: job.catData.cat_approved_words_percentage,
      color: 'var(--taia-color-green)',
    },
    {
      label: 'Translated words',
      amount: job.catData.cat_translated_words_percentage,
      color: 'var(--taia-color-blue)',
    },
  ];
  return (
    <section className="single-job-progress">
      <h2>Progress</h2>

      <ProgressBarsStacked values={otherValues} total={totalValue} />
    </section>
  );
};

export default JobProgress;
