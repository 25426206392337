import styled from 'styled-components';
import Button from '../common/Button/Button';

export const CreatePackageWrapper = styled.div`
  max-width: 800px;
  padding: ${({ theme }) => theme.paddings.mainContainer.full};
  margin-right: auto;
  margin-left: auto;
`;
export const CreateButton = styled(Button)`
  margin-top: 3em !important;
`;
