import styled from 'styled-components';

export const NewMemberWrapper = styled.div`
  .member-description {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .member-form {
    display: flex;
    margin-bottom: 10px;
  }

  .new-members {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .reminder {
      margin-right: auto;
      margin-left: auto;
    }
  }
`;
