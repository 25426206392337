import React, { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Accordion from '../../../../common/Accordion/Accordion';
import Button from '../../../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import Loader from '../../../../common/Loader/Loader';
import Message from '../../../../common/Message/Message';
import Pagination from '../../../../common/Pagination/Pagination';
import { LoaderContainer, PaginationContainer, TableContainer } from './PaymentsHistory.styles';
import Table from '../../../../common/Table/Table';

import { getBillingPaymentIntents, getBillingPaymentInvoice } from '../../../../../services/subscriptions';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

import { formatAPIDateString } from 'utils/date.utils';

const PaymentsHistory = ({ billingId }) => {
  const [activePage, setActivePage] = useLocalStorage('payments-history-page', 1);
  const [lastPage, setLastPage] = useState(1);
  const [error, setError] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState(true); // component mounts data on mount
  const [payments, setPayments] = useState([]);
  const [loadingInvoiceId, setLoadingInvoiceId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPayments = async () => {
      setLoadingPayments(true);
      setError(false);
      try {
        const paymentsResponse = await getBillingPaymentIntents({
          billing_id: billingId,
          per_page: 10,
          page: activePage,
        });
        const { data, last_page } = paymentsResponse;
        setPayments(data);
        if (activePage > last_page) {
          setActivePage(last_page);
        }
        setLastPage(last_page);
      } catch (e) {
        setError(true);
      } finally {
        setLoadingPayments(false);
      }
    };
    fetchPayments();
  }, [activePage, billingId, setActivePage]);

  const handlePageChange = async (e, { activePage }) => {
    sendUserInteraction('subscriptions overview page payments history page change');
    e.preventDefault();
    setActivePage(activePage);
  };

  const downloadInvoice = async ({ billingId, paymentId }) => {
    sendUserInteraction('subscriptions overview page download invoice click');
    setLoadingInvoiceId(paymentId);

    try {
      // get file blob
      const response = await getBillingPaymentInvoice({
        billing_id: billingId,
        payment_intent_id: paymentId,
      });
      const headers = response.headers;
      const fileName = headers['content-disposition'].split('filename=')[1];
      const decodedFileName = decodeURIComponent(fileName).replace(/"/g, '').replace(/\+/g, ' ');
      const blob = new Blob([response.data], { type: headers['content-type'] });
      // save as file
      FileSaver.saveAs(blob, decodedFileName);
    } catch (e) {
      toast.error(t('common:toasts.downloadFile.error'));
    } finally {
      setLoadingInvoiceId(null);
    }
  };

  return (
    <Accordion title={t('common:catapult.subscription.paymentsTable.heading')} watch={[...payments]}>
      {loadingPayments ? (
        <LoaderContainer>
          <Loader inline />
        </LoaderContainer>
      ) : error ? (
        <Message text={t('common:catapult.subscription.paymentsTable.error')} type="error" />
      ) : (
        <TableContainer>
          <Table width="100%" marginTop="0">
            <Table.Header>
              <Table.Row>
                <th>{t('common:catapult.subscription.paymentsTable.billingName')}</th>
                <th>{t('common:catapult.subscription.paymentsTable.billingPeriod')}</th>
                <th>{t('common:catapult.subscription.paymentsTable.issuedOn')}</th>
                <th>{t('common:catapult.subscription.paymentsTable.paymentDue')}</th>
                <th style={{ textAlign: 'center' }}>
                  {t('common:catapult.subscription.paymentsTable.paid')}
                </th>
                <th />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {payments.map((payment) => {
                const billingId = get(payment, 'billing_detail_id', null);
                const companyName = get(payment, 'billing_detail.company_name', '');
                const periodStart = get(payment, 'period_start', null);
                const periodEnd = get(payment, 'period_end', null);
                const issuedOn = get(payment, 'invoice.issued_on', null);

                return (
                  <Table.Row key={payment.id}>
                    <Table.Cell>{companyName}</Table.Cell>
                    <Table.Cell>{`${formatAPIDateString({
                      dateString: periodStart,
                      showTime: false,
                    })} -  ${formatAPIDateString({ dateString: periodEnd, showTime: false })}`}</Table.Cell>
                    <Table.Cell>
                      {formatAPIDateString({
                        dateString: issuedOn,
                        dateStringFormat: 'yyyy-MM-dd',
                        showTime: false,
                      })}
                    </Table.Cell>
                    <Table.Cell>{formatAPIDateString({ dateString: periodEnd, showTime: false })}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {payment.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="times" />}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Button
                        actiontype="primary"
                        disabled={!payment.paid || !!loadingInvoiceId}
                        loading={payment.id === loadingInvoiceId}
                        onClick={() => downloadInvoice({ billingId, paymentId: payment.id })}
                      >
                        {t('common:catapult.subscription.paymentsTable.download')}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </TableContainer>
      )}
      {lastPage > 1 && (
        <PaginationContainer>
          <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={lastPage} />
        </PaginationContainer>
      )}
    </Accordion>
  );
};

export default PaymentsHistory;
