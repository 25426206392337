import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterSidebar from '../../../common/FilterSidebar/FilterSidebar';
import LinkTableCell from '../../../common/Table/LinkTableCell';
import { LoaderContainer, PaginationContainer, PaidSubscriptionsContainer } from './PaidSubscriptions.styles';
import Loader from '../../../common/Loader/Loader';
import Message from '../../../common/Message/Message';
import Pagination from '../../../common/Pagination/Pagination';
import PaidSubscriptionsFilter from './PaidSubscriptionsFilter';
import SortHeaderCell from '../../../common/Table/SortHeaderCell';
import Table from '../../../common/Table/Table';
import { withDebounce } from '../../../../hoc/debouncer';

import { adminGetCatSubscriptions } from '../../../../services/subscriptions';

import { INITIAL_REQUEST_PARAMS } from './PaidSubscriptions.constants';
import { useLocalStorage } from 'utils/storage.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { formatAPIDateString } from 'utils/date.utils';

const PaidSubscriptions = ({ debounce }) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // component loads
  const [lastPage, setLastPage] = useState(1);
  const [requestParams, setRequestParams] = useLocalStorage(
    'paid-subscriptions-params',
    INITIAL_REQUEST_PARAMS,
  );
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const { t } = useTranslation();

  const getSortDirection = (property) => {
    const { sort_by, sort_order } = requestParams;

    if (sort_by === property) {
      return sort_order;
    }
    return null;
  };

  const handleSortChange = ({ sort_by, sort_order }) => {
    sendUserInteraction(`paid subscriptions sort by: ${sort_by}, sort order: ${sort_order}`);
    const newParams = {
      ...requestParams,
      sort_by,
      sort_order,
    };
    setRequestParams(newParams);
  };

  const handlePageChange = (e, { activePage }) => {
    sendUserInteraction('paid subscriptions page change');
    const newParams = { ...requestParams, page: activePage };
    setRequestParams(newParams);
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      debounce(
        'paidCatSubscriptions',
        async () => {
          try {
            setIsLoading(true);
            setError(false);
            const { data, last_page } = await adminGetCatSubscriptions(requestParams);
            if (requestParams.page > last_page) {
              setRequestParams({ ...requestParams, page: last_page });
            }
            setLastPage(last_page);
            setSubscriptionsData(data);
          } catch (e) {
            setError(true);
          } finally {
            setIsLoading(false);
          }
        },
        500,
      );
    };

    fetchSubscriptions();
  }, [debounce, requestParams, setRequestParams]);

  const updateRequestParams = (newParams) => {
    setRequestParams({ ...newParams });
  };

  return (
    <PaidSubscriptionsContainer>
      <FilterSidebar width="calc(42.6ch + 1rem + 2rem + 14px)">
        <PaidSubscriptionsFilter requestParams={requestParams} updateRequestParams={updateRequestParams} />
      </FilterSidebar>
      {isLoading ? (
        <LoaderContainer>
          <Loader inline />
        </LoaderContainer>
      ) : error ? (
        <Message text={t('common:catapult.admin.paidSubscriptionsTable.error')} type="error" />
      ) : (
        <>
          <Table width="100%" marginTop="0">
            <Table.Header>
              <Table.Row>
                <SortHeaderCell
                  onSortChange={(newSortDirection) =>
                    handleSortChange({
                      sort_by: 'id',
                      sort_order: newSortDirection,
                    })
                  }
                  text={t('common:catapult.admin.paidSubscriptionsTable.id')}
                  sort_order={getSortDirection('id')}
                />
                <SortHeaderCell
                  onSortChange={(newSortDirection) =>
                    handleSortChange({
                      sort_by: 'current_period_end',
                      sort_order: newSortDirection,
                    })
                  }
                  text={t('common:catapult.admin.paidSubscriptionsTable.periodEnd')}
                  sort_order={getSortDirection('current_period_end')}
                />
                <SortHeaderCell
                  onSortChange={(newSortDirection) =>
                    handleSortChange({
                      sort_by: 'stripe_status',
                      sort_order: newSortDirection,
                    })
                  }
                  text={t('common:catapult.admin.paidSubscriptionsTable.subscriptionStatus')}
                  sort_order={getSortDirection('stripe_status')}
                />
                <th>{t('common:catapult.admin.paidSubscriptionsTable.customer')}</th>
                <th>{t('common:catapult.admin.paidSubscriptionsTable.package')}</th>
                <th>{t('common:catapult.admin.paidSubscriptionsTable.plan')}</th>
                <th>{t('common:catapult.admin.paidSubscriptionsTable.billingPeriod')}</th>
                <SortHeaderCell
                  onSortChange={(newSortDirection) =>
                    handleSortChange({
                      sort_by: 'quantity',
                      sort_order: newSortDirection,
                    })
                  }
                  text={t('common:catapult.admin.paidSubscriptionsTable.seatCount')}
                  sort_order={getSortDirection('quantity')}
                />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {subscriptionsData.map((dataRow) => {
                const {
                  billing_detail_id,
                  billing_detail: { company_id, company_name, user_id },
                  current_period_end,
                  id,
                  plan,
                  stripe_status,
                  quantity,
                } = dataRow;

                return (
                  <Table.Row key={id}>
                    <LinkTableCell
                      onClick={() => {
                        sendUserInteraction('paid subscriptions subscription id link click');
                      }}
                      text={id}
                      to={`/admin/catapult/subscriptions/${billing_detail_id}/${id}`}
                    />
                    <Table.Cell>
                      {formatAPIDateString({ dateString: current_period_end, showTime: false })}
                    </Table.Cell>
                    <Table.Cell>{stripe_status}</Table.Cell>
                    <LinkTableCell
                      onClick={() => {
                        sendUserInteraction('paid subscriptions user/company link click');
                      }}
                      text={company_name}
                      to={company_id ? `/company/${company_id}` : `/admin/user/${user_id}`}
                    />
                    <LinkTableCell
                      onClick={() => {
                        sendUserInteraction('paid subscriptions plan link click');
                      }}
                      text={plan?.product.name}
                      to={`/admin/packages/${plan?.product.id}`}
                    />
                    <Table.Cell>{plan?.name}</Table.Cell>
                    <Table.Cell>{plan?.interval}</Table.Cell>
                    <Table.Cell>{quantity}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <PaginationContainer>
            {lastPage > 1 && (
              <Pagination
                totalPages={lastPage}
                onPageChange={handlePageChange}
                activePage={requestParams.page}
              />
            )}
          </PaginationContainer>
        </>
      )}
    </PaidSubscriptionsContainer>
  );
};

export default withDebounce(PaidSubscriptions);
