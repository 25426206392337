import React from 'react';
import { classnames } from 'utils/base.utils';

import './style.scss';

const Placeholder = ({ className, ...rest }) => {
  const classNames = classnames('taia-placeholder', className);

  return <span className={classNames} {...rest} />;
};

export default Placeholder;
