import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Icon } from '../../common/Icon/Icon';
import Button from '../../common/Button/Button';
import BlankDashboardImg from 'assets/taia-graphic-blank-dashboard.svg';
import { sendUserInteraction } from 'utils/tagManager.utils';
import './style.scss';
import { checkAllowedRole } from 'utils/user.utils';
import { VENDOR_ROLES } from 'constants/roles';
import { useSelector } from 'react-redux';
import BecomeVendorBanner from 'components/common/BecomeVendorBanner/BecomeVendorBanner';

// needs translations
const NoProjects = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const user = useSelector((state) => state.userStore.user);
  const handleClick = () => {
    sendUserInteraction('no projects screen create first project click');
    history.push('/projects/create');
  };

  const showBecomeVendorBanner =
    !user.vendor && // User doesn't have vendor model
    checkAllowedRole({ allowedRoles: VENDOR_ROLES, userRole: user.role }); // User has a role which is allowed to start onboarding

  return (
    <div className="no-project">
      <div className="no-project-client">
        <img className="hero-img" src={BlankDashboardImg} alt="" />
        <h1>{t('common:projects.firstProject.title')}</h1>
        <p>{t('common:projects.firstProject.description')}</p>
        <Button onClick={handleClick} labelPosition="left" actiontype="primary" big>
          <Icon name="plus-square" />
          {t('common:projects.firstProject.createFirstProject')}
        </Button>
      </div>
      {showBecomeVendorBanner && <BecomeVendorBanner />}
    </div>
  );
};

export default NoProjects;
