import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 235.28px;
  align-items: center;
  justify-content: center;
`;

export const FlavorText = styled.p`
  text-align: left;
  margin-top: 0 !important;
  margin-bottom: 1em !important;
`;
