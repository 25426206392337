import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import { Modal } from 'semantic-ui-react';

import './style.scss';

const CancelConfirmation = ({ loading = false, onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal className="confirmation-modal" open size="tiny">
      <div className="text">
        {t('common:catapult.subscription.cancelSubscriptionConfirmationDescription')}
      </div>
      <div className="buttons-container">
        <Button actiontype="secondary" loading={loading} onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button actiontype="primary" loading={loading} onClick={onConfirm}>
          {t('common:confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default CancelConfirmation;
