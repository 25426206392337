import React from 'react';
import { useTranslation } from 'react-i18next';

import SignInForm from './SignInForm';
import { Footer, ForgotPasswordLink, InnerContainer, StyledCard } from './SignIn.styles';

import { StyledLink, StyledHeader } from '../common.styles';

import { sendUserInteraction } from 'utils/tagManager.utils';
import RecaptchaDisclaimer from './RecaptchaDisclaimer';

const SignIn = () => {
  const { t } = useTranslation();
  return (
    <StyledCard>
      <InnerContainer>
        <StyledHeader>{`${t('common:signIn.logIn')}`}</StyledHeader>
        <SignInForm />
        <ForgotPasswordLink
          onClick={() => sendUserInteraction('clicked Did you forget your password')}
          to="/password/reset"
        >
          {t('common:signIn.forgotPassword')}
        </ForgotPasswordLink>
        <RecaptchaDisclaimer />
      </InnerContainer>
      <Footer noBackground>
        {t('common:signIn.dontHaveAnAccount')}&nbsp;
        <StyledLink
          data-cy="user-sign-up-link"
          onClick={() => sendUserInteraction('clicked link Sign up now')}
          to="/register"
        >
          {t('common:signIn.signUpNow')}
        </StyledLink>
      </Footer>
    </StyledCard>
  );
};

export default SignIn;
