import React, { createContext, useContext } from 'react';
import styled from 'styled-components';
import { useDropdown } from './useDropdown';
import { Icon } from '../Icon/Icon';
import { MultipleDropdown } from './MultipleDropdown';
import { includes } from './utils';
import { SelectDropdown } from './SelectDropdown';

const StyledContainer = styled.div`
  position: relative;

  .dropdown-header {
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0px;
    z-index: 3;

    min-width: 205px;
    max-height: 288px;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 4px 4px 18px -4px rgba(0, 0, 0, 0.25);
    text-align: left;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    /* overflow-y: auto; */
    font-size: 14px;

    transition: opacity, box-shadow, transform;
    will-change: transform, box-shadow, opacity;
    transition-timing-function: ease-out;
    transition-duration: 150ms;
    transform-origin: top left;
    transform: scale(0.95);
    opacity: 0;
    pointer-events: none;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      right: 15px;
      position: absolute;
      background: white;
      transform: rotate(45deg);
      top: -5px;
      z-index: -1;
    }

    .dropdown-item {
      padding: 14px 20px;
      color: #000;
      font-weight: normal;
      display: flex;
      column-gap: 12px;
      cursor: pointer;
      min-height: min-content;

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }

  &.open {
    .dropdown-menu {
      transform: scale(1);
      opacity: 1;
      pointer-events: all;
    }
  }

  &.left {
    .dropdown-menu {
      left: auto;
      right: 0;
      transform-origin: top right;
    }
  }

  &.custom-trigger {
  }
`;

const dropdownContext = createContext(null);
const useDropdownContext = () => useContext(dropdownContext);

export const DropdownItem = ({ children, item = {}, index, ...rest }) => {
  const { getItemProps, highlightedIndex } = useDropdownContext();

  return (
    <div
      className={`dropdown-item ${index === highlightedIndex ? 'highlighted' : ''}`}
      data-cy="dropdown-item"
      {...getItemProps({ ...rest, item, index })}
    >
      {item.icon && item.icon}
      {children}
    </div>
  );
};

export const DropdownMenu = ({ children, ...rest }) => {
  const { getMenuProps } = useDropdownContext();
  return (
    <div className="dropdown-menu" data-cy="dropdown-menu" {...getMenuProps(rest)}>
      {children}
    </div>
  );
};

const Dropdown = ({
  options = [],
  placeholder = '',
  fluid,
  search,
  label,
  disabled,
  value,
  onChange = () => {},
  trigger,
  className,
  children,
  direction = 'right',
  ...rest
}) => {
  const state = useDropdown({
    items: options,
    initialState: { selectedItem: null },
    selectedItem: value,
    onSelectedItemChange: handleSelectedItemChange,
    filterFunction: includes,
  });
  const { isOpen, selectedItem, getToggleProps, items, expandUpward } = state;

  function handleSelectedItemChange(newState) {
    onChange(null, { value: newState.selectedItem.value });
  }

  const getClasses = () => {
    const classes = [className];
    if (isOpen) classes.push('open');
    if (fluid) classes.push('fluid');
    if (label) classes.push('label');
    if (disabled) classes.push('disabled');
    if (expandUpward) classes.push('upward');
    if (trigger) classes.push('custom-trigger');
    if (direction) classes.push(direction);
    return classes.join(' ');
  };

  return (
    <dropdownContext.Provider value={state}>
      <StyledContainer className={getClasses()} {...rest}>
        <div data-cy="dropdown-header" className="dropdown-header" {...getToggleProps()}>
          {trigger ? (
            trigger
          ) : (
            <>
              {!search && (selectedItem?.text || placeholder)}
              <Icon className="arrow" name="angle-down" />
            </>
          )}
        </div>

        <DropdownMenu>
          {children
            ? children
            : items.length > 0
            ? items.map((item, index) => (
                <DropdownItem key={`item-${item.key}`} item={item} index={index}>
                  {item.text}
                </DropdownItem>
              ))
            : null}
        </DropdownMenu>
      </StyledContainer>
    </dropdownContext.Provider>
  );
};

Dropdown.Item = DropdownItem;
Dropdown.Menu = DropdownMenu;

export { Dropdown, SelectDropdown, MultipleDropdown };
