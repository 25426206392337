import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import Table from '../../Table/Table';
import Message from '../../Message/Message';
import { StyledInput } from '../CreateCompany.style';
import { isValidEmail } from '../../../../utils/string.utils';
import { CreateStepsWrapper } from '../CreateCompany.style';
import Button from '../../Button/Button';
import { TEAM_TRANSLATIONS } from '../../../../constants/roles';

import HelpPopup from '../../../Company/CompanyInvites/InviteUsersModal/HelpPopup';
class CompanyInvite extends Component {
  state = { emailText: '', role: 0 };

  handleAddClick = () => {
    const { emailText, role } = this.state;
    this.setState({ emailText: '' });
    this.props.addEmail({ email: emailText, role: role });
  };

  handleRoleChange = (e, { value }) => {
    this.setState({ role: value });
  };

  render() {
    const { emails, t } = this.props;
    const { emailText, role } = this.state;

    const roleOptions = [
      { key: 0, text: t('common:company.companyManager', 'Company manager'), value: 0 },
      { key: 1, text: t('common:team.members.roles.teamManager'), value: 1 },
      { key: 2, text: t('common:team.members.roles.fullMember'), value: 2 },
      { key: 3, text: t('common:team.members.roles.limitedMember'), value: 3 },
      { key: 4, text: t('common:team.members.roles.billingManager'), value: 4 },
    ];

    return (
      <CreateStepsWrapper>
        <div className="step-title">
          {t('common:admin.companies.invite.inviteManager')}
          <HelpPopup style={{ marginLeft: '1em' }} />
        </div>
        <div className="company-name-form">
          <div className="email-inputs">
            <StyledInput
              value={emailText}
              onChange={(e) => {
                this.setState({ emailText: e.target.value });
              }}
              placeholder={t('common:admin.companies.invite.managerEmail')}
              type="email"
            />
            <Dropdown
              placeholder={t('common:admin.companies.invite.selectRole')}
              selection
              value={role}
              options={roleOptions}
              onChange={this.handleRoleChange}
            />
            <Button
              actiontype="primary"
              onClick={this.handleAddClick}
              icon="plus"
              disabled={!isValidEmail(emailText)}
              style={{ height: 'unset' }}
            />
          </div>
        </div>
        {emails.length > 0 ? (
          <Table width="100%">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('common:team.members.email')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:team.members.role')}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {emails.map((member, idx) => (
                <Table.Row key={idx}>
                  <Table.Cell>{member.email}</Table.Cell>
                  <Table.Cell>{t(TEAM_TRANSLATIONS[member.role])}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button actiontype="delete" icon="trash-alt" onClick={this.props.removeEmail(idx)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Message text={t('common:team.members.addReminder')} type="info" />
        )}
      </CreateStepsWrapper>
    );
  }
}

export default withTranslation('common')(CompanyInvite);
