import UserProfileAdminLink from 'components/common/UserProfileAdminLink/UserProfileAdminLink';
import React from 'react';
import styled from 'styled-components';
import { Avatar } from '../../common';

export const Container = styled.div`
  display: flex;
  color: #999999;
  font-size: 14px;

  .name-and-email {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      font-family: 'DM Sans', sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.black};
      margin: 0;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  height: 60px !important;
  width: 60px !important;
  margin-right: 12px !important;
  border: solid 1px #e4e4e4;
`;

const UserCell = ({ user }) => {
  return (
    <UserProfileAdminLink userId={user.id}>
      <Container>
        <StyledAvatar img={user.avatar} />
        <div className="name-and-email">
          <h4>{user.name}</h4>
          <span>{user.email}</span>
        </div>
      </Container>
    </UserProfileAdminLink>
  );
};

export default UserCell;
