import styled from 'styled-components';

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem;
  align-items: center;
  justify-content: center;
`;

export const UserTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
