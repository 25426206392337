import React, { memo, Component, createRef } from 'react';
import Dropzone from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { camelCase, trim, isEmpty, uniq } from 'lodash';

import UploadGraphics from './assets/file-uploader.png';
import { Icon } from '../Icon/Icon';
import { Radio } from '../Radio/Radio';
import Message from '../../common/Message/Message';

import { StyledLabel } from '../../common.styles';
import {
  AbsoluteButton,
  Browse,
  DisabledHeading,
  DisabledUpload,
  DropzoneText,
  DropzoneWrapper,
  FilesListContainer,
  StyledImage,
  StyledSpan,
  StyledTextArea,
  TextAreaWrapper,
  TypesContainer,
  TypeRadioContainer,
} from './FileDrop.styles';
import { getFileExtension, isFileTypeValid } from '../../../utils/files.utils';
import { connect } from 'react-redux';
import { setUploadFileType, setTextFileLength } from '../../../store/newProjectSlice';
import { sendUserInteraction } from 'utils/tagManager.utils';
import { processFilename } from '../../../utils/filename.utils';
import ProjectFile from './File';
import { MAX_FILES, MAX_FILESIZE } from '../../../constants/files';

class DropFiles extends Component {
  state = {
    fileType: 'file',
    fileTypeError: false,
    fileNotSupported: false,
    fileTooLarge: false,
    text: '',
    invalidExtensions: null,
    invalidFiles: [],
    tooLargeFiles: [],
    renamedFiles: [],
    isDraggingOver: false,
    showFileTypeRecommendation: false,
  };
  filesContainerRef = createRef(null);

  componentDidMount() {
    this.setState({ text: '', fileType: 'file' });
    this.props.dispatch(setUploadFileType('file'));
    this.props.dispatch(setTextFileLength(0));
  }

  componentWillUnmount() {
    const txt = this.state.text || '';

    if (txt.length > 0 && this.state.fileType === 'text' && this.props.addOnUnmount) {
      this.handleAddText();
    }
  }

  addFiles = (addedFiles) => {
    sendUserInteraction('new project: upload new files');
    const { onFilesChange, files } = this.props;
    // first reset the file type error
    this.setState({
      fileTypeError: false,
      fileNotSupported: false,
      fileTooLarge: false,
      invalidExtensions: null,
      invalidFiles: [],
      tooLargeFiles: [],
      renamedFiles: [],
      isDraggingOver: false,
    });

    const validFiles = [];
    const invalidFiles = [];
    const tooLargeFiles = [];
    const renamedFiles = [];

    // handle too many files
    const newFilesLength = addedFiles.length;
    if (files.length + newFilesLength >= MAX_FILES) {
      addedFiles = addedFiles.slice(0, MAX_FILES - files.length);
    }

    let showFileTypeRecommendation = false;

    // check files for valid file types
    addedFiles.forEach((file) => {
      const fileExtension = getFileExtension(file).toLowerCase();
        if (fileExtension === 'pdf' || fileExtension === 'jpg' || fileExtension === 'png') {
          showFileTypeRecommendation = true;
        }
      
      const [filename, message] = processFilename(file.name, this.props.files);
      file = new File([file], filename);

      if (message !== null) {
        renamedFiles.push([filename, message]);
      }

      if (isFileTypeValid(file)) {
        if (file.size > MAX_FILESIZE) {
          tooLargeFiles.push(file);
          this.setState({ fileTooLarge: true });
        } else {
          validFiles.push(file);
        }
      } else {
        invalidFiles.push(file);
        this.setState({ fileNotSupported: getFileExtension(file) });
      }

    });

    if (showFileTypeRecommendation) {
      this.setState({ showFileTypeRecommendation: true });
    } else {
      this.setState({ showFileTypeRecommendation: false });
    }

    if (invalidFiles.length > 0 || tooLargeFiles.length > 0 || renamedFiles.length > 0) {
      const invalidExtensions = uniq(invalidFiles.map((file) => getFileExtension(file))).join(', ');

      this.setState({
        invalidExtensions,
        invalidFiles,
        tooLargeFiles,
        renamedFiles,
      });
    }

    onFilesChange([...files, ...validFiles]);
    this.scrollLatestIntoView();
  };

  handleAddText = () => {
    sendUserInteraction('new project: add text button click');
    const { text = '' } = this.state;
    const blob = new Blob([text], { type: 'text/plain' });

    const filename = `${camelCase(text).substring(0, 7)}.txt`;
    const file = new File([blob], filename);

    this.addFiles([file]);
    this.props.dispatch(setTextFileLength(0));
    this.setState({ text: '' });
  };

  handleRemove = (f) => {
    return () => {
      const newRenamedFiles = this.state.renamedFiles.filter(([filename, _]) => filename !== f.name);
      this.setState({ renamedFiles: newRenamedFiles });
      this.setState({ showFileTypeRecommendation: false });
      this.props.handleRemoveFile(f.name);
      sendUserInteraction('new project: remove file button click');
    };
  };

  handleTextChange = (e) => {
    this.props.dispatch(setTextFileLength(e.target.value.length));
    this.setState({ text: e.target.value });
  };

  handleChangeType = (type) => {
    sendUserInteraction(`new project: change project file input to ${type}`);
    return (e) => {
      this.props.dispatch(setUploadFileType(type));
      this.setState({ fileType: type });
    };
  };

  handleRename = (name, newName) => {
    const { onFilesChange, files } = this.props;
    const newFiles = files.map((file) => {
      if (file.name === name) {
        const otherFiles = files.filter((f) => f.name !== name);
        const [filename, message] = processFilename(newName, otherFiles);
        if (newName !== filename) {
          this.setState({ renamedFiles: [...this.state.renamedFiles, [filename, message]] });
        }
        return new File([file], filename);
      }
      return file;
    });
    onFilesChange(newFiles);
  };

  scrollLatestIntoView = () => {
    if (this.filesContainerRef.current) {
      this.filesContainerRef.current.scrollTop = this.filesContainerRef.current.scrollHeight;
    }
  };

  render() {
    const { files, t } = this.props;
    const {
      fileType,
      text,
      fileNotSupported,
      fileTypeError,
      fileTooLarge,
      renamedFiles,
      isDraggingOver,
      showFileTypeRecommendation
    } = this.state;
    const fileLimitReached = files.length >= MAX_FILES;

    
    return (
      <>
        {fileLimitReached ? (
          <DisabledUpload>
            <DisabledHeading>{t('common:projects.createProject.tooManyFilesHeader')}</DisabledHeading>
            <p>{`${t('common:projects.createProject.toomanyFilesFirstText')} ${MAX_FILES} ${t(
              'common:projects.createProject.tooManyFilesRestText',
            )}`}</p>
          </DisabledUpload>
        ) : (
          <>
            <TypesContainer>
              <TypeRadioContainer onClick={this.handleChangeType('file')}>
                <Icon
                  circular
                  color={fileType === 'file' ? '#1CAAC5' : 'grey'}
                  name="upload-alt"
                  size="big"
                  link
                  style={{
                    margin: '1.5rem 0 1rem 0',
                  }}
                />
                <Radio
                  checked={fileType === 'file'}
                  label={`${t('common:projects.createProject.file')}`}
                  name="radioGroup"
                  value="translation"
                  data-cy="radio-btn-file"
                />
              </TypeRadioContainer>
              <TypeRadioContainer onClick={this.handleChangeType('text')}>
                <Icon
                  circular
                  color={fileType === 'text' ? '#1CAAC5' : 'grey'}
                  name="file-alt"
                  size="big"
                  link
                  style={{
                    margin: '1.5rem 0 1rem 0',
                  }}
                />
                <Radio
                  label={`${t('common:projects.createProject.text')}`}
                  name="radioGroup"
                  value="Text"
                  checked={fileType === 'text'}
                  data-cy="radio-btn-text"
                />
              </TypeRadioContainer>
            </TypesContainer>
            {fileType === 'file' ? (
              <Dropzone
                onDrop={this.addFiles}
                onDragEnter={() => this.setState({ isDraggingOver: true })}
                onDragLeave={() => this.setState({ isDraggingOver: false })}
              >
                {({ getRootProps, getInputProps }) => (
                  <DropzoneWrapper
                    data-cy="dropzone"
                    id="create-new-project-file-upload"
                    {...getRootProps()}
                    isDraggingOver={isDraggingOver}
                  >
                    <div className="absolute" />
                    <StyledImage centered src={UploadGraphics} />
                    <DropzoneText>
                      <StyledSpan>
                        <>
                          {`${t('common:projects.createProject.dragAndDrop')} `}
                          <input {...getInputProps()} />
                          <Browse>{`${t('common:projects.createProject.browse')}`}</Browse>
                        </>
                      </StyledSpan>
                    </DropzoneText>
                  </DropzoneWrapper>
                )}
              </Dropzone>
            ) : (
              <TextAreaWrapper>
                <StyledTextArea
                  data-cy="textarea"
                  onChange={this.handleTextChange}
                  value={text || ''}
                  placeholder={t('common:projects.createProject.typeText')}
                />
                {!isEmpty(trim(text)) && (
                  <AbsoluteButton
                    circular
                    icon="plus"
                    onClick={this.handleAddText}
                    basic
                    color="light-grey"
                  />
                )}
              </TextAreaWrapper>
            )}
          </>
        )}

        {files.length > 0 && (
          <>
            <StyledLabel>{`${t('common:projects.createProject.sourceFiles')}:`}</StyledLabel>
            <FilesListContainer ref={this.filesContainerRef}>
              {files.map((f, index) => (
                <ProjectFile
                  onRemove={this.handleRemove(f)}
                  file={f}
                  onRename={this.handleRename}
                  key={index}
                  files={files}
                />
              ))}
            </FilesListContainer>
          </>
        )}

        {showFileTypeRecommendation && (
          <Message
            text={t(
              'common:projects.createProject.pdfwarning', 'PDFs are a real pain for everyone involved'
            )}
            type="info"
            style={{ minHeight: '48px' }}
          />
        )}
        {!fileNotSupported && fileTypeError && (
          <Message
            text={t(
              'common:projects.createProject.fileError',
              'We process pdf, doc and docx files using a specific process. Please upload other file types in a separate project.',
            )}
            type="error"
            style={{ minHeight: '90px' }}
          />
        )}

        {fileTooLarge && (
          <Message
            text={`${t('common:projects.createProject.fileTooLarge')} ${MAX_FILESIZE / 1_000_000} ${t(
              'common:projects.createProject.mbPerFile',
            )}`}
            type="error"
            style={{ minHeight: '48px' }}
          />
        )}

        {fileNotSupported && (
          <Message
            text={`${t(
              'common:projects.createProject.fileNotSupported',
              'File format not supported.',
            )} (${fileNotSupported})`}
            type="error"
            style={{ minHeight: '48px' }}
          />
        )}
        {renamedFiles.length > 0 && (
          <Message
            header={t('common:projects.createProject.changedFileName', 'Duplicate file name:')}
            text={renamedFiles.map(([_, message]) => message)}
            type="warning"
            style={{ minHeight: '48px' }}
          />
        )}
      </>
    );
  }
}

export default connect()(memo(withTranslation('common')(DropFiles)));
