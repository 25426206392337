import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'semantic-ui-react';
import { StyledDropdownItem } from '../../../common.styles';
import { Icon } from '../../Icon/Icon';
import CouponModal from '../CouponModal';
import UserEditForm from '../EditForm/UserEditForm';

import { sendUserInteraction } from 'utils/tagManager.utils';

const AdminUserDropdown = ({ billingDetails, onSubmit, userId }) => {
  const { t } = useTranslation();

  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleCouponClick = (e) => {
    e.preventDefault();
    sendUserInteraction('admin open set billing coupon modal');
    setShowCouponModal(true);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    sendUserInteraction('admin open set billing details modal');
    setShowEditModal(true);
  };

  const handleCouponModalClose = () => {
    setShowCouponModal(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const updateBillingDetails = async () => {
    await onSubmit();
  };

  return (
    <>
      <Dropdown
        closeOnBlur
        closeOnChange
        closeOnEscape
        direction="left"
        icon={<Icon name="ellipsis-v" inElement />}
      >
        <Dropdown.Menu style={{ marginTop: '.25rem' }}>
          <StyledDropdownItem
            icon={<Icon name="pen" size="small" />}
            onClick={handleEditClick}
            text={t('common:userInfo.billing.labels.editBilling')}
          />
          <StyledDropdownItem
            icon={<Icon name="tag-alt" size="small" />}
            onClick={handleCouponClick}
            text={t('common:userInfo.billing.labels.useCoupon')}
          />
        </Dropdown.Menu>
      </Dropdown>
      {showCouponModal && (
        <CouponModal
          billingId={billingDetails.id}
          closeModal={handleCouponModalClose}
          updateBilling={updateBillingDetails}
        />
      )}
      {showEditModal && (
        <UserEditForm
          closeModal={handleEditModalClose}
          initialBillingDetails={billingDetails}
          updateBilling={updateBillingDetails}
          userId={userId}
        />
      )}
    </>
  );
};

export default AdminUserDropdown;
