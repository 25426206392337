import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';

import Loader from '../../common/Loader/Loader';
import Message from '../../common/Message/Message';

import BillingInfo from './BillingInfo/BillingInfo';
import PaymentsHistory from '../Subscriptions/Subscription/PaymentsHistory/PaymentsHistory';
import SubscriptionDetails from '../Subscriptions/Subscription/SubscriptionDetails/SubscriptionDetails';
import UsersTable from '../Subscriptions/Subscription/UsersTable/UsersTable';
import UsageTable from '../Subscriptions/Subscription/UsageTable/UsageTable';

import { getSubscriptionById } from '../../../services/subscriptions';

import { MainContainer } from 'components/common.styles';

const AdminSubscriptionOverview = () => {
  const { billingId, subscriptionId } = useParams();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // component loads data on mount
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        setError(null);
        setIsLoading(true);
        const newDetails = await getSubscriptionById(subscriptionId);
        setSubscriptionDetails(newDetails);
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, [billingId, subscriptionId]);

  const updateSubscriptionDetails = (newDetails) => {
    setSubscriptionDetails({ ...newDetails });
  };

  const isSubscriptionExpired = useMemo(() => {
    if (!!subscriptionDetails) {
      const { ends_at } = subscriptionDetails;

      if (!!ends_at) {
        const endDate = new Date(ends_at);
        const currentDate = new Date();

        const isExpired = isBefore(endDate, currentDate);

        return isExpired;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [subscriptionDetails]);

  return (
    <MainContainer className="subscriptions-page">
      <div className="subscription-container">
        {isLoading ? (
          <div className="subscription-loader-container">
            <Loader />
          </div>
        ) : error ? (
          <Message text={t('common:catapult.subscription.loadError')} type="error" />
        ) : (
          <>
            <SubscriptionDetails
              subscriptionDetails={subscriptionDetails}
              updateSubscriptionDetails={updateSubscriptionDetails}
              isSubscriptionExpired={isSubscriptionExpired}
            />
            {!isSubscriptionExpired && (
              <UsersTable
                subscriptionDetails={subscriptionDetails}
                updateSubscriptionDetails={updateSubscriptionDetails}
              />
            )}
            <UsageTable subscriptionId={subscriptionId} />
            {!isSubscriptionExpired && <BillingInfo billingId={billingId} />}
            <PaymentsHistory billingId={billingId} />
          </>
        )}
      </div>
    </MainContainer>
  );
};

export default AdminSubscriptionOverview;
