import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { getTeam, getTeamPendingInvites } from '../../services/teams';
import { checkAllowedRole, checkRoles } from '../../utils/user.utils';

import Tab from '../common/Tab/Tab';
import { NameContainer, TeamWrapper } from './Team.style';

import LoadingPane from '../common/LoadingPane/LoadingPane';
import TeamBilling from './TeamBilling/TeamBilling';
import TeamInvites from './TeamInvites/TeamInvites';
import TeamInvoices from './TeamInvoices/TeamInvoices';
import TeamMembers from './TeamMembers/TeamMembers';
import NewTeamMemberModal from './TeamMembers/NewTeamMemberModal/NewTeamMemberModal';

const Team = ({ t, user }) => {
  const [team, setTeam] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isError, setError] = useState(false);

  const { id, tabName } = useParams();
  const history = useHistory();

  const [invites, setInvites] = useState([]);
  const [isLoadingInvites, setIsLoadingInvites] = useState(true);
  const [invitesError, setInvitesError] = useState(false);

  const panes = [
    {
      index: 0,
      key: 0,
      title: t('common:team.teamMembers'),
      link: '/team/:id/members',
      pathToMatch: 'members',
      render: () => <TeamMembers />,
    },
  ];

  //Full members currently aren't allowed to retrieve invites from BE
  if (checkRoles({ user: user, teamId: id, allowedUserRoles: [29, 40], allowedTeamRoles: [1] })) {
    panes.push({
      index: 1,
      key: 1,
      link: '/team/:id/invites',
      title: (
        <>
          {t('common:team.pendingInvites')}
          {!isLoadingInvites && <Tab.Number active={tabName === 'invites'}>{invites.length}</Tab.Number>}
        </>
      ),
      pathToMatch: 'invites',
      render: () => (
        <TeamInvites
          invites={invites}
          setInvites={setInvites}
          isLoading={isLoadingInvites}
          error={invitesError}
        />
      ),
    });
  }

  const canSeeCompanyDetails = checkAllowedRole({ allowedRoles: [29, 40], userRole: user.role });

  //Full members currently aren't allowed to retrieve billing from BE
  if (
    checkRoles({
      user: user,
      teamId: id,
      allowedUserRoles: [29, 40],
      allowedTeamRoles: [1, 4],
    })
  ) {
    panes.push({
      index: 2,
      key: 2,
      title: t('common:team.teamBilling'),
      link: '/team/:id/billing',
      pathToMatch: 'billing',
      render: () => (
        <TeamBilling
          companyId={team.company_id}
          team={team}
          onUpdate={(team) => {
            setTeam({ ...team });
          }}
        />
      ),
    });
    panes.push({
      index: 3,
      key: 3,
      title: t('common:team.teamInvoices'),
      link: '/team/:id/invoices',
      pathToMatch: 'invoices',
      render: () => <TeamInvoices />,
    });
  }

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        setIsLoading(true);
        const data = await getTeam(id);
        setTeam(data);
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTeam();
  }, [canSeeCompanyDetails, id]);

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        setIsLoading(true);
        const data = await getTeamPendingInvites({ team_id: id });
        setInvites(data.data);
      } catch (e) {
        setInvitesError(true);
        throw e;
      } finally {
        setIsLoadingInvites(false);
      }
    };
    fetchInvites();
  }, [id]);

  const getActiveTabIndex = () => {
    const activePane = panes.findIndex((pane) => tabName === pane.pathToMatch);
    return activePane !== -1 ? activePane : 0;
  };

  const handleTabChange = (e, { activeIndex }) => {
    e.preventDefault();
    const pane = { ...panes[activeIndex] };
    const newPath = pane.link.replace(':id', id);
    history.push(newPath);
  };

  const handleAddInvites = (newInvites) => {
    setInvites([...invites, ...newInvites]);
  };

  return isLoading ? (
    <LoadingPane />
  ) : (
    <TeamWrapper>
      <div className="team-header">
        <NameContainer>
          <h2>{team.name}</h2>
          {/* displays new member button for team members and pending invites */}
          {getActiveTabIndex() < 2 &&
            checkRoles({
              user: user,
              teamId: id,
              allowedUserRoles: [29, 40],
              allowedTeamRoles: [1],
            }) && <NewTeamMemberModal addInvites={handleAddInvites} />}
        </NameContainer>
      </div>
      <div className="team-tabs">
        <Tab activeIndex={getActiveTabIndex()} panes={panes} onTabChange={handleTabChange} />
      </div>
    </TeamWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.userStore.user,
});

export default connect(mapStateToProps)(withTranslation('common')(Team));
