import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import CallToActionButton from 'components/common/CallToAction/CallToAction';
import {
  AlreadySubscribed,
  ButtonContainer,
  Container,
  Name,
  OriginalPrice,
  Price,
  PriceContainer,
  SubPrice,
  Words,
} from './ProductCard.styles';

import { checkAllowedRole } from 'utils/user.utils';
import { localiseAndConvertPrice } from '../../../utils/string.utils';
import { sendUserInteraction } from 'utils/tagManager.utils';

const ProductCard = ({
  billingId,
  canOrderSubscription = false,
  currentCurrencyData,
  quantity = 1,
  product,
  subscriptionStatus,
  yearly,
}) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state) => state.userStore.user);

  // name and word limit are taken from the product
  const { name, word_limit } = product;

  const shownPlans = useMemo(() => {
    const getPlan = (interval) => {
      const savedPlan = product?.plans?.find((plan) => {
        return plan.interval === interval && plan.currency_id === currentCurrencyData.id;
      });
      // if we found the monthly plan for the selected currency return it
      if (!!savedPlan) {
        return savedPlan;
      } else {
        // if we did not find the correct monthly plan we need to calculate the pricing from another currencys monthly plan
        // we will always have a base to calculate from since products that do not have both a monthly and yearly interval were filtered out in the parent Component

        // find base plan to calculate from
        const basePlan = product?.plans?.find((plan) => plan.interval === interval && plan.active);

        // recalculate amount to EUR if plan amount is not in EUR
        const finalAmount =
          basePlan.currency_rel.code === 'EUR'
            ? basePlan.amount
            : basePlan.amount / basePlan.conversion_rate.conversion_rate;

        // set new currency data on shown plan
        // return the new mocked shown plan
        return {
          ...basePlan,
          amount: finalAmount,
          calculatedPricing: true,
          conversion_rate: {
            conversion_rate: currentCurrencyData.conversion_rate,
          },
          currency_rel: currentCurrencyData,
        };
      }
    };

    const monthlyPlan = getPlan('month');
    const yearlyPlan = getPlan('year');

    return { monthlyPlan, yearlyPlan };
  }, [currentCurrencyData, product.plans]);

  const handlePackageClick = () => {
    const { id } = !!yearly ? shownPlans.yearlyPlan : shownPlans.monthlyPlan;
    const searchQuery = `?plan=${id}&quantity=${quantity}${!!billingId ? `&billingId=${billingId}` : ''}`;

    sendUserInteraction(`clicked catapult package card, plan ID:${id}`);
    // if user is logged in redirect directly to checkout
    if (!!user) {
      history.push(`/checkout${searchQuery}`);
    } else {
      // if user is not logged in redirect to sign up / login screen for catapult
      history.push(`/catapult-landing${searchQuery}`);
    }
  };

  const calculationQuantity = quantity === '' ? 1 : quantity;

  const monthlyPriceString = useMemo(() => {
    const { amount, currency_rel, conversion_rate } = shownPlans.monthlyPlan;

    return localiseAndConvertPrice({
      currencyData: {
        code: currency_rel.code,
        conversion_rate: conversion_rate.conversion_rate,
      },
      language: i18n.language,
      number: amount * calculationQuantity,
    });
  }, [calculationQuantity, i18n, shownPlans]);

  const yearlyPriceString = useMemo(() => {
    const { amount, currency_rel, conversion_rate } = shownPlans.yearlyPlan;

    return localiseAndConvertPrice({
      currencyData: {
        code: currency_rel.code,
        conversion_rate: conversion_rate.conversion_rate,
      },
      language: i18n.language,
      number: (amount * calculationQuantity) / 12,
    });
  }, [calculationQuantity, i18n, shownPlans]);

  const isPurchased = useMemo(() => {
    const shownPlan = !!yearly ? shownPlans.yearlyPlan : shownPlans.monthlyPlan;

    const isPurchased = shownPlan?.stripe_id === subscriptionStatus.stripeSubscription?.stripe_plan;
    return isPurchased;
  }, [shownPlans, subscriptionStatus, yearly]);

  const actionButtonText = useMemo(() => {
    // if no plan is purchased return getStarted
    if (!subscriptionStatus?.activeSubscription) {
      return t('common:catapult.packages.getStarted');
    }

    // if this plan is purchased, then the only thing we can do is swap number of seats
    if (!!isPurchased) {
      return t('common:catapult.packages.changeSeats');
    }

    // if we have an active subscription and no stripeSubscription everything is an upgrade (free package)
    if (!!subscriptionStatus?.activeSubscription && !subscriptionStatus?.stripeSubscription) {
      return t('common:catapult.packages.upgrade');
    }

    // if we have an active stripe subscription check if current package has more or less words
    if (!!subscriptionStatus?.activeSubscription && !!subscriptionStatus?.stripeSubscription) {
      // if current word limit equals card word limit we are only changing interval
      return subscriptionStatus?.stripeProduct?.word_limit === word_limit
        ? t('common:catapult.packages.changeInterval')
        : subscriptionStatus?.stripeProduct?.word_limit > word_limit
        ? t('common:catapult.packages.downgrade')
        : t('common:catapult.packages.upgrade');
    }

    // default to get started for unhandled cases
    return t('common:catapult.packages.getStarted');
  }, [isPurchased, subscriptionStatus, t, word_limit]);

  const isIndividual = useMemo(() => {
    return checkAllowedRole({ allowedRoles: [10], userRole: user.role });
  }, [user]);

  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      layout
      exit={{ opacity: 0 }}
      $isPurchased={isPurchased}
    >
      <Name>{name}</Name>
      <PriceContainer>
        {!!yearly && <OriginalPrice>{monthlyPriceString}</OriginalPrice>}
        <Price>{!!yearly ? yearlyPriceString : monthlyPriceString}</Price>
        <SubPrice>{!!yearly && t('common:catapult.packages.yearlyDisclaimer')}</SubPrice>
      </PriceContainer>

      <Words>
        <li>{`${word_limit} ${t('common:catapult.packages.words')}`}</li>
      </Words>

      <ButtonContainer $marginTop={yearly ? '24px' : '14px'}>
        {canOrderSubscription ? (
          isPurchased && isIndividual ? (
            <AlreadySubscribed>{t('common:catapult.packages.currentSubscription')}</AlreadySubscribed>
          ) : (
            <CallToActionButton color="blue" onClick={handlePackageClick} text={actionButtonText} />
          )
        ) : (
          <AlreadySubscribed>{t('common:catapult.packages.unableToOrder')}</AlreadySubscribed>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default ProductCard;
