import { VENDOR_STATUS } from 'constants/vendors';
import { VENDOR_EXPERIENCE } from 'constants/vendors';
import { VENDOR_RISK } from 'constants/vendors';
import {
  getCatJobStats,
  postAcceptRevision,
  postAcceptJob,
  postFinishRevision,
  postFinishJob,
  postDeclineJob,
} from 'services/translators';

export const checkIsRevision = ({ job, user }) => {
  if (!!job && !!user) {
    const revisionerInt = parseInt(job.revisioner);
    const vendorId = parseInt(user?.vendor?.id);

    return revisionerInt === vendorId;
  }
  return false;
};

export const checkIsProofread = (job) => {
  if (!!job) {
    const { source_language, target_language } = job;
    if (!!source_language && !!target_language) {
      return source_language.id === target_language.id;
    }
    return false;
  }
  return false;
};

export const getTotalWords = ({ job, isProofread, isRevision }) => {
  if (!!job) {
    if (job.hasOwnProperty('catData')) {
      const { catData } = job;

      if (!!catData) {
        const { cat_total_words, cat_total_payable_words } = catData;
        // proofread translation jobs show total raw words
        // revision jobs show total raw words
        if (isRevision || isProofread) {
          return cat_total_words;
        }
        // translation jobs show total as total payable words
        return cat_total_payable_words;
      }
      return null;
    }
  }
  return null;
};

export const checkCanFinish = ({ job, isRevision }) => {
  if (!!job) {
    const { status_id, revision_status_id } = job;
    if (isRevision) {
      return status_id === 6 && revision_status_id === 5;
    } else {
      return status_id === 5;
    }
  }
  return false;
};

export const checkShowAcceptDeny = ({ job, isRevision }) => {
  if (!!job) {
    const { status_id, revision_status_id } = job;
    if (isRevision) {
      return revision_status_id === 4;
    }
    return status_id === 4;
  }
  return false;
};

export const checkShowFinish = ({ job, isRevision }) => {
  if (!!job) {
    const { status_id, revision_status_id } = job;
    if (isRevision) {
      return revision_status_id === 5;
    }
    return status_id === 5;
  }
  return false;
};

export const acceptJob = async ({ jobID, isRevision, callback }) => {
  try {
    if (isRevision) {
      const newJobData = await postAcceptRevision(jobID);
      const dataWithStats = await getCatJobStats(newJobData);
      callback(dataWithStats);
    } else {
      const newJobData = await postAcceptJob(jobID);
      const dataWithStats = await getCatJobStats(newJobData);
      callback(dataWithStats);
    }
  } catch (e) {
    throw e;
  }
};

export const declineJob = async ({ jobID, isRevision, callback }) => {
  const job_type = isRevision ? 2 : 1;

  try {
    const newJobData = await postDeclineJob({ job_id: jobID, job_type });
    callback(newJobData);
  } catch (e) {
    throw e;
  }
};

export const finishJob = async ({ jobID, isRevision, callback, user }) => {
  try {
    if (isRevision) {
      const newJobData = await postFinishRevision({ job_id: jobID, translator_id: user?.vendor?.id });
      const dataWithStats = await getCatJobStats(newJobData);
      callback(dataWithStats);
    } else {
      const newJobData = await postFinishJob({ job_id: jobID, translator_id: user?.vendor?.id });
      const dataWithStats = await getCatJobStats(newJobData);
      callback(dataWithStats);
    }
  } catch (e) {
    throw e;
  }
};

export const getVendorRiskText = (risk_id) => {
  if (VENDOR_RISK.hasOwnProperty(risk_id)) {
    return VENDOR_RISK[risk_id];
  } else {
    return 'Unknown';
  }
};

export const getVendorStatusText = (status_id) => {
  if (VENDOR_STATUS.hasOwnProperty(status_id)) {
    return VENDOR_STATUS[status_id];
  } else {
    return 'Unknown';
  }
};

export const getVendorExperience = (experience_id) => {
  if (VENDOR_EXPERIENCE.hasOwnProperty(experience_id)) {
    return VENDOR_EXPERIENCE[experience_id];
  } else {
    return 'Unknown';
  }
};

export const getVendorsJobPricing = (job, taskType, user) => {
  const { source_language_id, target_language_id } = job;

  const { vendor } = user;

  return vendor.prices.find(
    (price) =>
      price.task_type_id === taskType &&
      price.source_lang_id === source_language_id &&
      price.target_lang_id === target_language_id,
  );
};
