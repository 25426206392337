import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .list {
    margin: 0px !important;
    width: 50%;
  }
`;
