import React from 'react';

import { SectionHeading, SectionHeadingText } from './BillingCard.styles';
import { Icon } from '../../Icon/Icon';
import { withTheme } from 'styled-components';

const SectionTitle = ({ icon, isValid = true, text, theme }) => (
  <SectionHeading>
    <Icon name={icon} color={isValid ? theme.colors.blue : theme.colors.red} size="large" inElement />
    <SectionHeadingText>{text}</SectionHeadingText>
  </SectionHeading>
);

export default withTheme(SectionTitle);
