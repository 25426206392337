import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, InputContainer } from './Inputs.styles';
import { Input } from 'components/common/Input/Input';

const DescriptionInput = ({ name, updateProperty }) => {
  const { t } = useTranslation();

  const handleTextInputChange = (e) => {
    updateProperty({ name: 'name', value: e.target.value });
  };

  return (
    <Container>
      <InputContainer>
        <Input
          label={t('common:userInfo.billing.labels.description')}
          name="name"
          onChange={handleTextInputChange}
          value={name || ''}
        />
      </InputContainer>
    </Container>
  );
};

export default DescriptionInput;
