import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledCard = styled.div`
  margin: 0 auto;

  ${({ theme }) => theme.mediaMinWidth.desktop`
    max-width: 380px;
  `}

  ${({ theme }) => theme.mediaMaxWidth.mobile`
    max-width: 100%;
    width: 100%;
    padding: ${theme.paddings.mainContainer.mobile.full};
  `}
`;

export const InnerContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  font-size: 18px;
  padding: 21px 0;
  flex-wrap: wrap;
  ${({ theme, noBackground }) => !noBackground && theme.colors.blueLight};
`;

export const StyledForm = styled.form`
  margin: 0 auto;
  width: 100%;

  & > div {
    margin-bottom: 20px;
  }
`;

export const ForgotPasswordLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blue};
  width: 100%;
  text-align: center;
  margin-bottom: 35px;
  line-height: 2.14;
  font-family: 'DM Sans', sans-serif !important;
`;
